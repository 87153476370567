// @ts-nocheck
import network from './network'
import axios from 'axios'
import networkTS from './networkTS'
import { ApplicableApplicable, SortSortOrder, V2FilterFolder } from '@/types/api'

export default {
  getSignatureRender(accountId, userId) {
    return network.get(`/web/v2/message/signature/${accountId}/render/${userId}`)
      .then(response => {
        return response.data
      })
  },
  getSignatureRenderById(id, userId) {
    return network.get(`web/v2/message/signature/renderById/${id}/user/${userId}`)
      .then(response => {
        return response.data
      })
  },
  getSignatures(accountId) {
    return network.get(`/web/v2/message/signature/listByAccountId?accountId=${accountId}`)
      .then(response => {
        return response.data
      })
  },
  setSignatureWithId(accountId, signature, name, id) {
    return network.post('/web/v2/message/signature/setSignature', {
      signature: signature,
      name: name,
      accountId: accountId,
      id: id
    }).then(response => {
      return response.data
    })
  },
  setSignature(accountId, signature) {
    return network.post(`/web/v2/message/signature/${accountId}/set`, {
      signature: signature
    }).then(response => {
      return response.data
    })
  },
  deleteSignature(id) {
    return network.delete(`/web/v2/message/signature/${id}/delete`).then(response => {
      return response.data
    })
  },
  setReferenceIds(ids, referenceIds) {
    return network.post(`web/v2/message/mail/setReferenceIds/${ids.join(',')}`, {
      ids: ids,
      referenceIds: referenceIds
    }).then(response => {
      return response.data
    })
  },
  renderText(contactId, objectId, text, title) {
    return network.post('/web/v2/message/template/renderText', {
      contactId: contactId,
      objectId: objectId,
      text: text,
      title: title
    })
      .then(response => {
        return response.data
      })
  },
  bulkRenderText(entries, text, title) {
    return network.post('/web/v2/message/template/bulkRenderText', {
      entries: entries,
      text: text,
      title: title
    })
      .then(response => {
        return response.data
      })
  },
  getTemplateList(ownerId) {
    return network.get(`/web/v2/message/template/list?ownerId=${ownerId}`)
      .then(response => {
        return response.data
      })
  },
  deleteTemplate(ids) {
    return network.delete(`/web/v2/message/template/${ids}`).then(response => {
      return response.data
    })
  },
  createTemplate(ownerId, template, attachmentMediaIds = [], type = 'TEMPLATE_TYPE_EMAIL') {
    return network.post('/web/v2/message/template/create', {
      message: template.message,
      name: template.name,
      ownerId: ownerId,
      subject: template.subject,
      attachmentMediaIds: attachmentMediaIds,
      type: type
    })
      .then(response => {
        return response.data
      })
  },
  updateTemplate(template, attachmentMediaIds = []) {
    return network.post(`/web/v2/message/template/${template.id}/update`, {
      message: template.message,
      name: template.name,
      id: template.id,
      subject: template.subject,
      attachmentMediaIds: attachmentMediaIds
    })
      .then(response => {
        return response.data
      })
  },
  renderTemplate(templateId, contactId, objectId = '') {
    return network.post(`/web/v2/message/template/${templateId}/render`, {
      contactId: contactId,
      id: templateId,
      objectId: objectId
    })
      .then(response => {
        return response.data
      })
  },
  createDomain(domain, ownerId) {
    return network.post('/web/v2/message/mail/createDomain', {
      domain: domain,
      ownerId: ownerId
    })
      .then(response => {
        return response.data
      })
  },
  getDomainList(ownerId) {
    return network.get(`web/v2/message/mail/listDomains/${ownerId}`)
      .then(response => {
        return response.data
      })
  },
  validateDomain(id) {
    return network.post(`/web/v2/message/mail/validateDomain/${id}`)
      .then(response => {
        return response.data
      })
  },
  deleteDomain(id) {
    return network.delete(`/web/v2/message/mail/deleteDomain/${id}`)
      .then(response => {
        return response.data
      })
  },
  emptyTrash(ids) {
    return network.delete(`/web/v2/message/mail/deleteMails/${ids}`).then(response => {
      return response.data
    })
  },
  deleteMails(ids) {
    return network.post('/web/v2/message/mail/moveToTrash', {
      ids: ids
    }).then(response => {
      return response.data
    })
  },
  restoreMails(ids) {
    return network.post('/web/v2/message/mail/restoreFromTrash', {
      ids: ids
    }).then(response => {
      return response.data
    })
  },
  mailMarkRead(ids, accountId) {
    return networkTS.mailServiceMarkRead({
      accountId: accountId.toString(),
      ids: ids
    }).then(response => {
        return response.data
      })
  },
  mailMarkUnread(ids, accountId) {
    return network.post('/web/v2/message/mail/markUnread', {
      accountId: accountId,
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  listMailsByIds(mailIds) {
    return network.post('/web/v2/message/mail/listMailsByIds', {
      mailIds: mailIds
    })
      .then(response => {
        return response.data
      })
  },
  listDraftsMessage() {
    return network.get('/web/v2/message/mail/listDrafts')
      .then(response => {
        return response.data
      })
  },
  deleteDrafts(ids: string[]) {
    return network.delete(`/web/v2/message/mail/deleteDrafts/${ids}`)
        .then(response => {
          return response.data
        })
  },
  getListMails(
    filterFolder, filterReferenceIdPrefixes, filterUnread, itemsPerPage, mailboxIds, page, query, sortReceivedAt, limitPagination) {
    if (this.cancelToken) {
      this.cancelToken.abort()
    }

    this.cancelToken = new AbortController()
    return network.post('/web/v2/message/mail/listMails', {
      filterFolder: filterFolder,
      filterReferenceIdPrefixes: filterReferenceIdPrefixes,
      filterUnread: filterUnread,
      itemsPerPage: itemsPerPage,
      mailboxIds: mailboxIds,
      page: page,
      query: query,
      sortReceivedAt: sortReceivedAt,
      limitPagination: limitPagination
    }, {
      signal: this.cancelToken.signal
    })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        // If the request was cancelled, don't throw an error
        if (axios.isCancel(error)) {
          console.log('Request cancelled:', error.message)
        } else {
          throw error
        }
      })
  },
  listMailboxesByUserId(userId) {
    return network.get(`/web/v2/message/mail/listMailboxesByUserId/${userId}`)
      .then(response => {
        return response.data
      })
  },
  countUnreadMails(mailboxIds) {
    return network.get(`/web/v2/message/mail/countUnreadMails/${mailboxIds}`)
      .then(response => {
        return response.data
      })
  },
  listMailboxes(ownerId) {
    return network.get(`/web/v2/message/mail/listMailboxesByAccountId/${ownerId}`)
      .then(response => {
        return response.data
      })
  },
  deleteMailboxes(ids) {
    return network.delete(`/web/v2/message/mail/deleteMailboxes/${ids}`)
      .then(response => {
        return response.data
      })
  },
  createMailbox(domain, ownerId) {
    return network.post('/web/v2/message/mail/createMailbox', {
      allowedUserIds: domain.allowedUsers.map(obj => obj.id),
      mainUserId: domain.mainUser,
      name: domain.name,
      outboundEmail: domain.outbound,
      ownerId: ownerId
    })
      .then(response => {
        return response.data
      })
  },
  editMailbox(domain, mailboxId) {
    return network.post(`/web/v2/message/mail/editMailbox/${mailboxId}`, {
      allowedUserIds: domain.allowedUsers.map(obj => obj.id),
      mainUserId: domain.mainUser,
      name: domain.name,
      outboundEmail: domain.outbound,
      signatureId: domain.signatureId,
      id: mailboxId
    })
      .then(response => {
        return response.data
      })
  },
  setAutomaticReply(automaticReply, ids) {
    return network.post('/web/v2/message/mail/setAutomaticReply', {
      automaticReply: automaticReply,
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  listMailsByReferenceIds(referenceIds, filterFolder = 'FILTER_FOLDER_ALL', mailboxIds = [], page = 1, itemsPerPage, query = '') {
    return network.post('/web/v2/message/mail/listMailsByReferenceIds', {
      referenceIds: referenceIds,
      itemsPerPage: itemsPerPage,
      page: page,
      filterFolder: filterFolder,
      mailboxIds: mailboxIds,
      query: query
    }).then(response => {
      return response.data
    })
  },
  getDeliveryAndEngagementData(referenceIds) {
    return network.post('/web/v2/message/mail/getDeliveryAndEngagementData', {
      referenceIds: referenceIds
    }).then(response => {
      return response.data
    })
  },
  bulkSendMail(entries) {
    return network.post('/web/v2/message/mail/bulkSendMail', {
      entries: entries
    })
      .then(response => {
        return response.data
      })
  },
  saveDraft(bodyText, bodyHtml, from, subject, to, id, referenceIds, attachmentMediaIds, mailboxId, timestamp, cc = [], bcc = [], replyToMailId = '', forwardingMailId = '') {
    const params = {
      bodyText: bodyText,
      bodyHtml: bodyHtml,
      from: from,
      subject: subject,
      to: to,
      mailboxId: mailboxId,
      referenceIds: referenceIds.filter(x => x),
      attachmentMediaIds: attachmentMediaIds,
      timestamp: timestamp,
      cc: cc,
      bcc: bcc,
      replyToMailId: replyToMailId,
      forwardingMailId: forwardingMailId
    }
    if (id) {
      params.id = id
    }
    return network.post('/web/v2/message/mail/saveDraft', params)
      .then(response => {
        return response.data
      })
  },
  countDrafts() {
    return network.get('/web/v2/message/mail/countDrafts')
        .then(response => {
          return response.data
        })
  },
  sendMail(bodyText, bodyHtml, from, subject, to, referenceIds, attachmentMediaIds, ownerId, replyToMailId, cc = [], bcc = [], confidential = false) {
    return network.post('/web/v2/message/mail/sendMail', {
      bodyText: bodyText,
      bodyHtml: bodyHtml,
      from: from,
      subject: subject,
      to: to,
      ownerId: ownerId,
      referenceIds: referenceIds.filter(x => x),
      attachmentMediaIds: attachmentMediaIds,
      replyToMailId: replyToMailId,
      cc: cc,
      bcc: bcc,
      confidential: confidential
    })
      .then(response => {
        return response.data
      })
  },
  forwardMail(bodyText, bodyHtml, from, subject, to, referenceIds, attachmentMediaIds, ownerId, forwardingMailId, cc = [], bcc = [], confidential = false) {
    return network.post('/web/v2/message/mail/forward', {
      bodyText: bodyText,
      bodyHtml: bodyHtml,
      from: from,
      subject: subject,
      to: to,
      ownerId: ownerId,
      referenceIds: referenceIds,
      attachmentMediaIds: attachmentMediaIds,
      forwardingMailId: forwardingMailId,
      cc: cc,
      bcc: bcc,
      confidential: confidential
    })
      .then(response => {
        return response.data
      })
  },
  activateMailboxes(ids) {
    return network.post('/web/v2/message/mail/activateMailboxes', {
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  deactivateMailboxes(ids) {
    return network.post('/web/v2/message/mail/deactivateMailboxes', {
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  archiveMails(ids) {
    return network.post('/web/v2/message/mail/archive', {
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  unarchiveMails(ids) {
    return network.post('/web/v2/message/mail/restore', {
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  markAsSpam(ids) {
    return network.post('/web/v2/message/mail/markAsSpam', {
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  markAsNoSpam(ids) {
    return network.post('/web/v2/message/mail/markAsNoSpam', {
      ids: ids
    })
      .then(response => {
        return response.data
      })
  },
  hasDomainDedicatedIp(fromEmail) {
    return network.get(`/web/v2/message/mail/hasDomainDedicatedIp?fromEmail=${fromEmail}`)
      .then(response => {
        return response.data
      })
  },
  listLatestMailsForReferenceIds(referenceIds) {
    return network.post('/web/v2/message/mail/listLatestMailsForReferenceIds', {
      referenceIds: referenceIds
    })
      .then(response => {
        return response.data
      })
  },
  setConfidential(ids, confidential) {
    return network.post('/web/v2/message/mail/setConfidential', {
      ids: ids,
      confidential: confidential
    })
      .then(response => {
        return response.data
      })
  },
  listConfidentialEmails(accountId) {
    return network.get(`/web/v2/message/mail/listConfidentialEmails/${accountId}`)
      .then(response => {
        return response.data
      })
  },
  addConfidentialEmails(accountId, emails) {
    return network.post('/web/v2/message/mail/addConfidentialEmails', {
      accountId: accountId,
      emails: emails
    })
      .then(response => {
        return response.data
      })
  },
  removeConfidentialEmails(accountId, emails) {
    return network.post('/web/v2/message/mail/removeConfidentialEmails', {
      accountId: accountId,
      emails: emails
    })
      .then(response => {
        return response.data
      })
  },
  setMailboxesConfidential(confidential, mailboxIds) {
    return network.post('/web/v2/message/mail/setMailboxesConfidential', {
      confidential: confidential,
      mailboxIds: mailboxIds
    })
      .then(response => {
        return response.data
      })
  },
  listMailIds(filterFolder: V2FilterFolder, filterReferenceIdPrefixes: string[], filterUnread: ApplicableApplicable, mailboxIds: string[], query: string, sortReceivedAt: SortSortOrder) {
    if (this.cancelToken) {
      this.cancelToken.abort()
    }

    this.cancelToken = new AbortController()
    const data = {
      filterFolder: filterFolder,
      filterReferenceIdPrefixes: filterReferenceIdPrefixes,
      filterUnread: filterUnread,
      mailboxIds: mailboxIds,
      query: query,
      sortReceivedAt: sortReceivedAt
    }
    return networkTS.mailServiceListMailIds(data, {
    })
      .then(response => {
        return response.data
      })
      .catch((error) => {
        // If the request was cancelled, don't throw an error
        if (axios.isCancel(error)) {
          console.log('Request cancelled:', error.message)
        } else {
          throw error
        }
      })
  },
  renderPDF(formData) {
    return network.post('/gotenberg/forms/chromium/convert/html', formData, { responseType: 'blob' })
      .then(response => {
        return response.data
      })
  }
}
