<template>
  <section>
    <b-modal
      id="show-reminder-detail"
      ref="show-reminder-detail"
      :modal-class="'has-confirmation-modal'"
      no-close-on-backdrop
      hide-footer>
      <template slot="modal-header">
        <h5 class="mb-0 title-name">
          {{ $t('message.calendar.reminder.title') }}
        </h5>
        <div>
          <coozzy-button
            v-if="hasEdit"
            design="transparent"
            @click="editClicked()">
            <coozzy-edit-icon />
          </coozzy-button>
          <coozzy-button
            design="transparent"
            @click="closeClicked()">
            <img
              class="close-img"
              src="@/assets/icon_close.svg"
              alt="Placeholder">
          </coozzy-button>
        </div>
      </template>
      <div class="row mb-2">
        <div class="col">
          <label class="w-100">{{ $t('message.calendar.reminder.activity.title') }}</label>
          <span class="show-data">{{ reminder.activity === '' || reminder.activity === null || reminder.activity === 'UNDEFINED_REMINDER_ACTIVITY' ? '-' : $t(`message.calendar.reminder.activity.${reminder.activity}`) }}</span>
        </div>
        <div class="col">
          <label class="w-100">{{ $t('message.calendar.reminder.contact') }}</label>
          <router-link
            v-if="reminder.contactId !== null && reminder.contactId !== '' && getReference(reminder.contactId) && getReference(reminder.contactId).value && getContactDetailRoute(reminder.contactId)"
            :to="getContactDetailRoute(reminder.contactId)">
            {{ getReference(reminder.contactId).value.name }}
          </router-link>
          <span
            v-else
            class="show-data">-</span>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-6">
          <label>{{ $t('message.calendar.reminder.subject') }}</label>
          <coozzy-form-input
            id="subject"
            v-model="reminder.subject"
            :is-read-only="true"
            type="text" />
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-6">
              <label class="w-100">{{ $t('message.calendar.reminder.employee') }}</label>
              <span class="show-data">
                <employee-name-text
                  :user-id="reminder.employeeUserId" />
              </span>
            </div>
            <div class="col-6">
              <label class="w-100">{{ $t('message.ticketOverview.ticket.creator') }}</label>
              <span class="show-data">
                <employee-name-text
                  :user-id="reminder.creatorUserId" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col">
          <label class="w-100">{{ $t('message.calendar.reminder.date') }}</label>
          <span class="show-data">{{ reminder.date | timestampToDateTime }}</span>
        </div>
        <div class="col">
          <label class="w-100">{{ $t('message.calendar.reminder.priority.title') }}</label>
          <span class="show-data">{{ reminder.priority === '' || reminder.priority === null ? '-' : $t(`message.calendar.reminder.priority.${reminder.priority}`) }}</span>
        </div>
      </div>
      <div
        v-if="loading"
        class="text-center">
        <coozzy-spinner />
      </div>
      <template
        v-else>
        <div
          v-if="reminder.referenceIds.filter(x => x !== reminder.contactId).length > 0"
          class="row mb-2">
          <div class="col">
            <label class="w-100">{{ $t('message.calendar.reminder.reference') }}</label>
            <!-- <p class="show-data"></p> -->
            <div
              v-for="item in reminder.referenceIds.filter(x => x !== reminder.contactId)"
              :key="item.entity">
              <span
                v-if="getReference(item)">
                <template v-if="getReference(item).deleted === false && getReference(item).value">
                  {{ $t(`message.calendar.reminder.referenceKey.${getReference(item).entity.split('_')[0]}`) }}:
                  <template v-for="(value, index) in getArrayValues(getReference(item).value)">
                    {{ index !== 0 ? ',': '' }}
                    <router-link
                      v-if="value && getReference(item).entity.split('_')[0] === 'obj' && getReferenceDetailPageRoute(getReference(item))"
                      :key="index"
                      :to="getReferenceDetailPageRoute(getReference(item))">
                      {{ getObjectRefDisplayName(getReference(item).value) }}
                    </router-link>
                    <a
                      v-else-if="getReference(item).entity.split('_')[0] === 'mail'"
                      :key="index"
                      class="message-link"
                      @click="showDetailMail(value)">
                      {{ value.subject !== '' ? value.subject : $t('message.calendar.reminder.noSubject') }}
                    </a>
                    <router-link
                      v-else-if="getReferenceDetailPageRoute(getReference(item))"
                      :key="index"
                      :to="getReferenceDetailPageRoute(getReference(item))">
                      {{ value && value.name ? value.name : 'n/a' }}
                    </router-link>
                  </template>
                </template>
                <template v-else-if="getReference(item).deleted === true">
                  {{ $t(`message.calendar.reminder.referenceKey.${getReference(item).entity.split('_')[0]}`) }}:
                  {{ $t('message.generic.deleted') }}
                </template>
                <template v-else>
                  -
                </template>
              </span>
            </div>
          </div>
        </div>
      </template>
      <div class="row mb-2">
        <div class="col-12">
          <coozzy-form-checkbox
            v-model="done"
            :initial="reminder.done !== '0'"
            name="reminderdone">
            {{ $t('message.calendar.reminder.done') }}
          </coozzy-form-checkbox>
        </div>
        <div
          v-if="isChange"
          class="d-block w-100">
          <coozzy-button
            design="green"
            class="float-right mr-3"
            @click="markeAsDone()">
            {{ $t('message.generic.form.save') }}
          </coozzy-button>
        </div>
      </div>
    </b-modal>
  </section>
</template>
<script>

import ContactApi from '@/misc/apis/ContactApi'
import CalendarApi from '@/misc/apis/CalendarApi'
import MessageApi from '@/misc/apis/MessageApi'
import EmployeeNameText from '../../framework/components/misc/EmployeeNameText'
import Vue from 'vue'
import ObjectApi from '@/misc/apis/ObjectApi'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon'
import RequestApi from '@/marketingBasic/api/RequestApi'
import { routeChecks } from '@/mixins/routeChecks'
import { reminder } from '@/mixins/reminder'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { contact } from '@/mixins/contact'
import { mail } from '@/mixins/mail'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'ReminderDetailsModal',
  components: {
    CoozzySpinner,
    CoozzyEditIcon,
    CoozzyFormCheckbox,
    CoozzyFormInput,
    CoozzyButton,
    EmployeeNameText
  },
  mixins: [routeChecks, contact, reminder, mail],
  props: {
    reminderDetail: {
      type: Object,
      default: null
    },
    availableEmployees: {
      type: Array,
      default() {
        return []
      }
    },
    hasEdit: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedMail: null,
      reminder: this.reminderDetail,
      done: this.reminderDetail.done,
      isChange: false,
      listReferences: [],
      loading: false,
      countLoading: 0,
      isFirstLoad: true,
      isEditCliked: false
    }
  },
  computed: mapState('user', ['changedModal']),
  watch: {
    done: {
      deep: true,
      handler: function () {
        if (this.isFirstLoad === false) {
          this.isChange = true
          this.$store.dispatch('user/setChangedModal', true).catch(e => console.log(e))
        }
      }
    },
    countLoading: {
      deep: true,
      handler: function () {
        if (this.countLoading === this.reminder.referenceIds.filter(x => x !== this.reminder.contactId).length) {
          this.loading = false
        }
      }
    },
    changedModal: {
      deep: true,
      handler: function (val) {
        if (this.isChange === true && val === false && this.isEditCliked) {
          this.openReminderModal(this.reminder)
        }
      }
    }
  },
  mounted: function () {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'globalConfirmationModal') {
        this.isEditCliked = false
      }
    })
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'show-reminder-detail') {
        this.reminder = this.reminderDetail
        this.done = this.reminderDetail.done
        this.$nextTick(function () {
          this.isFirstLoad = false
        })
        this.getReferences()
        this.$nextTick(function () {
          this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
        })
      }
    })
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === 'show-reminder-detail') {
        this.listReferences = []
        this.countLoading = 0
        this.loading = false
        this.isFirstLoad = true
      }
    })
  },
  methods: {
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailHasContext',
      'setSubject',
      'setEmailToForwardAsAttachment'
    ]),
    // replyMail(email) {
    //   this.prepareReplyMail(email)
    // },
    // replyAllMail(email) {
    //   this.prepareReplyAll(email)
    // },
    // forwardMail(email) {
    //   this.prepareForwardMail(email)
    // },
    // forwardAsAttachement(mediaList, email) {
    //   this.setSubject('Fwd: ' + email.subject)
    //   this.setSendMailHasContext(true)
    //   this.setSendMailContacts([])
    //   this.setEmailToForwardAsAttachment(mediaList)
    //   this.$nextTick(function () {
    //     if (this.getMailboxesForUserId(this.userId).length === 0) {
    //       this.$nextTick(function () {
    //         Vue.toasted.show(this.$t('message.settings.mailbox.configMailboxToast'), { type: 'error' })
    //       })
    //     } else {
    //       this.callShowSendMailModal()
    //     }
    //   })
    // },
    showDetailMail(mail) {
      this.selectedMail = mail
      this.setMailDetailMailObject(mail)
      this.setMailDetailTicketObject(this.isMailHasReferenceIdTicket(mail))
      this.$nextTick(function () {
        this.callShowDetailMailModal()
      })
    },
    isMailHasReferenceIdTicket(message) {
      return this.listReferences?.find(ref => message.referenceIds.includes(ref.id))
    },
    openReminderModal(reminder) {
      this.$refs['show-reminder-detail'].hide()
      this.$emit('create-reminder-click', reminder)
    },
    show() {
      this.$refs['show-reminder-detail'].show()
    },
    markeAsDone() {
      this.isBusy = true
      if (this.done === false) {
        CalendarApi.setUnDone([this.reminder.id])
          .then(() => {
            this.$store.dispatch('user/setChangedModal', false).catch(e => console.log(e))
            this.$refs['show-reminder-detail'].hide()
            this.isChange = false
            this.reminder.done = '0'
            this.$emit('update-reminder', this.reminder)
          })
          .catch(e => {
            this.isBusy = false
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.updateReminder'), { type: 'error' })
          })
          .finally(() => {
            this.getCountOverdueReminders()
          })
      } else {
        CalendarApi.setDone([this.reminder.id])
          .then(() => {
            this.$store.dispatch('user/setChangedModal', false).catch(e => console.log(e))
            this.$refs['show-reminder-detail'].hide()
            this.isChange = false
            this.reminder.done = '1'
            this.$emit('update-reminder', this.reminder)
          })
          .catch(e => {
            this.isBusy = false
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.updateReminder'), { type: 'error' })
          })
          .finally(() => {
            this.getCountOverdueReminders()
          })
      }
    },
    getReference(referenceId) {
      return this.listReferences.find(element => element.entity === referenceId)
    },
    getReferences() {
      let listReference = []
      const listProperties = []
      const listBuildings = []
      const listObjects = []
      const listTenancies = []
      const listRequests = []
      const listContacts = []
      const listCondominium = []
      const listMails = []
      // Extract all referenceIds
      const referenceIds = this.reminder.referenceIds
      if (referenceIds.length > 0) {
        if (referenceIds.filter(x => x !== this.reminder.contactId).length > 0) {
          this.loading = true
        }
        for (let index = 0; index < referenceIds.length; index++) {
          switch (referenceIds[index].split('_')[0]) {
            case 'prop':
              if (!listProperties.includes(referenceIds[index])) {
                listProperties.push(referenceIds[index])
              }
              break
            case 'building':
              if (!listBuildings.includes(referenceIds[index])) {
                listBuildings.push(referenceIds[index])
              }
              break
            case 'obj':
              if (!listObjects.includes(referenceIds)) {
                listObjects.push(referenceIds[index])
              }
              break
            case 'tenancy':
              if (!listTenancies.includes(referenceIds[index])) {
                listTenancies.push(referenceIds[index])
              }
              break
            case 'condominium':
              if (!listCondominium.includes(referenceIds[index])) {
                listCondominium.push(referenceIds[index])
              }
              break
            case 'request':
              if (!listRequests.includes(referenceIds[index])) {
                listRequests.push(referenceIds[index])
              }
              break
            case 'contact':
              if (!listContacts.includes(referenceIds[index])) {
                listContacts.push(referenceIds[index])
              }
              break
            case 'mail':
              if (!listMails.includes(referenceIds[index])) {
                listMails.push(referenceIds[index])
              }
              break
            default:
              break
          }
        }
      }

      // Store all referenceIds in an object with ID + null value that will be filled by the requests below
      listReference = listReference
        .concat(listProperties)
        .concat(listBuildings)
        .concat(listObjects)
        .concat(listTenancies)
        .concat(listRequests)
        .concat(listContacts)
        .concat(listCondominium)
        .concat(listMails)

      listReference.forEach(element => {
        this.listReferences.push({
          entity: element,
          value: null,
          deleted: false
        })
      })

      this.loadProperties(listProperties)
      this.loadBuildings(listBuildings)
      this.loadObjects(listObjects)
      this.loadTenancies(listTenancies)
      this.loadCondominium(listCondominium)
      this.loadRequests(listRequests)
      this.loadContacts(listContacts)
      this.loadMails(listMails)
    },
    setReferenceValue(value) {
      const index = this.listReferences.findIndex(reference => reference.entity === value.id)
      if (index > -1) {
        this.listReferences[index].value = value
      }
    },
    setEmptyReferenceValuesToDeleted(prefix) {
      for (const reference of this.listReferences) {
        if (reference.entity.startsWith(prefix) && reference.value === null) {
          reference.deleted = true
        }
      }
    },
    loadProperties(propertyIds) {
      if (propertyIds.length > 0) {
        ObjectApi.getPropertyListByIds([...new Set(propertyIds)])
          .then(response => {
            for (const property of response.properties) {
              this.setReferenceValue(property)
            }
            this.countLoading += propertyIds.length
            this.setEmptyReferenceValuesToDeleted('prop_')
          })
      }
    },
    loadBuildings(buildingIds) {
      if (buildingIds.length > 0) {
        ObjectApi.getBuildingsListByIds([...new Set(buildingIds)])
          .then(response => {
            for (const building of response.buildings) {
              this.setReferenceValue(building)
            }
            this.countLoading += buildingIds.length

            this.setEmptyReferenceValuesToDeleted('building_')
          })
      }
    },
    loadObjects(objectIds) {
      if (objectIds.length > 0) {
        ObjectApi.listObjectsByIds([...new Set(objectIds)])
          .then(response => {
            for (const object of response.objects) {
              this.setReferenceValue(object)
            }
            this.countLoading += objectIds.length
            this.setEmptyReferenceValuesToDeleted('obj_')
          })
      }
    },
    loadTenancies(tenancyIds) {
      if (tenancyIds.length > 0) {
        ObjectApi.getTenanciesByIds([...new Set(tenancyIds)])
          .then(tenancyResponse => {
            this.countLoading += tenancyIds.length
            if (tenancyResponse.tenancies.length > 0) {
              let listContact = []
              tenancyResponse.tenancies.forEach(element => {
                listContact = listContact.concat(element.tenant.contactIds)
              })
              ContactApi.contactResolve([...new Set(listContact)])
                .then(contactResponse => {
                  let contactsList = contactResponse.persons
                    .concat(contactResponse.companies)
                    .filter(contact => listContact.includes(contact.id))
                  ObjectApi.listObjectsByIds([...new Set(tenancyResponse.tenancies.map(tenancy => tenancy.objectId))])
                    .then(objectResponse => {
                      for (const tenancy of tenancyResponse.tenancies) {
                        const contactItem = contactsList.filter(y => y.tenancyIds.includes(tenancy.id))
                        const index = this.listReferences.findIndex(reference => tenancy.id === reference.entity)
                        if (index > -1) {
                          let object = {}
                          object = objectResponse.objects.find(obj => obj.id === tenancy.objectId)
                          contactItem.object = object
                          this.listReferences[index].value = contactItem
                        }
                      }
                      this.setEmptyReferenceValuesToDeleted('tenancy_')
                    })
                })
            } else {
              this.setEmptyReferenceValuesToDeleted('tenancy_')
            }
          })
      }
    },
    loadCondominium(condominiums) {
      if (condominiums.length > 0) {
        ObjectApi.getCondominiumByIds([...new Set(condominiums)])
          .then(condominiumResponse => {
            if (condominiumResponse.condominiums.length > 0) {
              let listContact = []
              condominiumResponse.condominiums.forEach(element => {
                listContact = listContact.concat(element.owner.contactIds)
              })
              ContactApi.contactResolve([...new Set(listContact.filter(x => x !== ''))])
                .then(contactResponse => {
                  const contactsList = contactResponse.persons
                    .concat(contactResponse.companies)
                    .filter(contact => listContact.includes(contact.id))
                  ObjectApi.listObjectsByIds([...new Set(condominiumResponse.condominiums.map(condominium => condominium.objectId))])
                    .then(objectResponse => {
                      for (const condominium of condominiumResponse.condominiums) {
                        condominium.owner.contactIds.forEach(element => {
                          const contactItem = contactsList.find(y => y.id === element)
                          const index = this.listReferences.findIndex(reference => condominium.id === reference.entity)
                          if (index > -1) {
                            let object = {}
                            object = objectResponse.objects.find(obj => obj.id === condominium.objectId)
                            contactItem.object = object
                            if (this.listReferences[index].value) {
                              this.listReferences[index].value = this.listReferences[index].value.concat(contactItem)
                            } else {
                              this.listReferences[index].value = [contactItem]
                            }
                          }
                        })
                      }
                      this.countLoading += condominiums.length
                      this.setEmptyReferenceValuesToDeleted('condominium_')
                    })
                })
            } else {
              this.setEmptyReferenceValuesToDeleted('condominium_')
            }
          })
      }
    },
    loadRequests(requestIds) {
      if (requestIds.length > 0) {
        RequestApi.listByIds(requestIds)
          .then(requestResponse => {
            if (requestResponse.requests.length > 0) {
              ObjectApi.listObjectsByIds([...new Set(requestResponse.requests.map(request => request.objectId))])
                .then(objectResponse => {
                  for (const request of requestResponse.requests) {
                    const object = objectResponse.objects.find(obj => obj.id === request.objectId)
                    if (object) {
                      const index = this.listReferences.findIndex(reference => reference.entity === request.id)
                      if (index > -1) {
                        this.listReferences[index].value = object
                        this.countLoading += 1
                      }
                    }
                  }

                  this.setEmptyReferenceValuesToDeleted('request_')
                })
            } else {
              this.countLoading += requestIds.length
              this.setEmptyReferenceValuesToDeleted('request_')
            }
          })
      }
    },
    loadContacts(contactIds) {
      if (contactIds.length > 0) {
        ContactApi.contactResolve([...new Set(contactIds)])
          .then(response => {
            const contacts = response.persons.concat(response.companies).concat(response.companies.map(c => c.contactPersons).flat())
            for (const contact of contacts) {
              this.setReferenceValue(contact)
            }
            this.countLoading += contactIds.filter(x => x !== this.reminder.contactId).length
          })
      }
    },
    loadMails(mailIds) {
      if (mailIds.length > 0) {
        MessageApi.listMailsByIds([...new Set(mailIds)])
          .then(response => {
            this.countLoading += mailIds.length
            for (const mail of response.mails) {
              this.setReferenceValue(mail)
            }
          })
      }
    },
    getObjectRefDisplayName(obj) {
      if (obj.name !== '' && obj.number !== '') {
        return obj.number + ' - ' + obj.name
      } else if (obj.name === '' && obj.number !== '') {
        return obj.number
      } else if (obj.number === '' && obj.name !== '') {
        return obj.name
      } else {
        return 'n/a'
      }
    },
    editClicked() {
      this.isEditCliked = true
      this.$refs['show-reminder-detail'].hide()
      if (!this.isChange) {
        this.openReminderModal(this.reminder)
      }
    },
    closeClicked() {
      this.isEditCliked = false
      this.$refs['show-reminder-detail'].hide()
    },
    getArrayValues (value) {
      if (Array.isArray(value)) {
        return value
      } else {
        return [value]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .close-img{
    width: 15px;
  }
  .message-link {
    color: #20a8d8 !important;
    &:hover {
      color: #0055b7 !important;
      text-decoration: underline !important;
      cursor: pointer;
    }
}
</style>
