<template>
  <section>
    <coozzy-card class="global-card no-top-border table-card">
      <!-- Heading -->
      <div class="row justify-content-between align-items-center">
        <div class="col-md-8">
          <h5>
            {{ componentsCount }} {{ $t('message.onBoarding.investmentPlanning.components') }}
          </h5>
        </div>
        <div
          v-if="isFiltered"
          class="col-12 col-md-2">
          <coozzy-button
            class="w-100 sort"
            @click="initFilter()">
            <coozzy-filter-icon />
            {{ $t('message.generic.clearFilters') }}
          </coozzy-button>
        </div>
        <div
          v-if="isEditor"
          class="col-12 col-md-2">
          <coozzy-button
            design="prop-green"
            class="w-100 sort"
            @click="readOnly ? goToEditComponent() : showCreateComponentModalClicked()">
            {{ $t('message.onBoarding.investmentPlanning.addComponent') }}
          </coozzy-button>
        </div>
      </div>
      <div class="row align-items-center my-2">
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-form-input
            ref="searchText"
            v-model="searchText"
            :placeholder="$t('message.generic.search')"
            @keyup="searchInputText()" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-if="categoryFilter.length > 0"
            v-model="searchCategories"
            :clear-on-select="false"
            :close-on-select="false"
            :custom-label="customLabelCategory"
            :multiple="true"
            :options="categoryFilter"
            :placeholder="$tc('message.onBoarding.devices.filter.category')"
            :preselect-first="false"
            :preserve-search="true"
            :taggable="false"
            :track-by="'value'"
            class="filter-inputs"
            @remove="filterComponentsList"
            @select="filterComponentsList" />
        </div>
      </div>
      <div
        class="row mb-2">
        <div class="col-12 col-md-2 mt-2 mb-md-0">
          <label
            for="propertyValue"
            class="font-weight-normal">{{ $t('message.onBoarding.investmentPlanning.propertyValue') }}</label>
          <coozzy-form-input-numeric
            id="propertyValue"
            ref="propertyValue"
            v-model="localPropertyValue"
            type="number"
            class="mb-2"
            :disabled="true"
            :filter="'formatPrice'"
            :placeholder="$t('message.onBoarding.investmentPlanning.propertyValue')"
            :is-decimal="true" />
        </div>
        <div class="col-12 col-md-2 mt-2 mb-md-0">
          <label
            for="totalBuildingValue"
            class="font-weight-normal">{{ $t('message.onBoarding.investmentPlanning.totalBuildingValue') }}</label>
          <coozzy-form-input-numeric
            id="totalBuildingValue"
            ref="totalBuildingValue"
            v-model="totalBuildingValue"
            type="number"
            class="mb-2"
            :disabled="true"
            :filter="'formatPrice'"
            :placeholder="$t('message.onBoarding.investmentPlanning.totalBuildingValue')"
            :is-decimal="true" />
        </div>
      </div>
      <div class="row align-items-center mb-2">
        <div class="col-md-2 col-sm-6 col-12">
          <coozzy-dropdown
            :text="selectedEntriesText"
            class="w-100"
            design="green"
            size="sm">
            <coozzy-dropdown-item
              :disabled="selectedEntriesGlobal.length === 0"
              @click="bulkEditComponents('installationDate')">
              {{ $t('message.onBoarding.investmentPlanning.actions.enterInstallationDate') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntriesGlobal.length === 0"
              @click="bulkEditComponents('costs')">
              {{ $t('message.onBoarding.investmentPlanning.actions.enterCostComponent') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntriesGlobal.length === 0"
              @click="bulkEditComponents('percentage')">
              {{ $t('message.onBoarding.investmentPlanning.actions.enterDistributionPercent') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntriesGlobal.length === 0"
              @click="bulkEditComponents('measures')">
              {{ $t('message.onBoarding.investmentPlanning.actions.enterMeasures') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntriesGlobal.length === 0"
              @click="bulkEditComponents('plannedReplacement')">
              {{ $t('message.onBoarding.investmentPlanning.actions.enterPlannedReplacement') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntriesGlobal.length === 0"
              @click="bulkEditComponents('futureCostFactor')">
              {{ $t('message.onBoarding.investmentPlanning.actions.enterFutureCostFactor') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntriesGlobal.length === 0"
              @click="bulkEditComponents('plannedCosts')">
              {{ $t('message.onBoarding.investmentPlanning.actions.enterPlannedCosts') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntriesGlobal.length === 0"
              @click="deleteComponentsClicked">
              {{ $t('message.generic.delete') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2 col-sm-6 col-12">
          <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :initial="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              name="selectAllEntries"
              button>
              {{
                selectAllEntries ? $t('message.generic.unselectHiddenEntries', {amount: nbrTotalOfHits}) : $t('message.generic.selectHiddenEntries', {amount: nbrTotalOfHits})
              }}
            </coozzy-form-checkbox>
          </template>
        </div>
        <div
          v-if="isCompanyAdmin === true"
          class="offset-md-5 col-md-1 d-flex justify-content-end align-items-center">
          <div
            @click="openAssembleListModal()">
            <coozzy-filters-icon
              v-b-tooltip="{container: '#rowConfig', title: $t('message.generic.tooltipAssembleList')}" />
          </div>
        </div>
        <div class="col-md-2 col-sm-6 col-12 mt-2 mt-sm-0">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries">
            <option value="5">
              5 {{ $t('message.generic.entries') }}
            </option>
            <option value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option value="15">
              15 {{ $t('message.generic.entries') }}
            </option>
            <option value="20">
              20 {{ $t('message.generic.entries') }}
            </option>
            <option value="all">
              {{ $t('message.manageInterestedParties.filter.allEntries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <div
        v-if="isBusy"
        class="text-center">
        <coozzy-spinner />
      </div>
      <b-table
        v-else
        id="componentsTable"
        ref="componentsTable"
        bordered
        hover
        responsive="true"
        stacked="md"
        select-mode="single"
        class="overflow-auto shadow"
        :fields="fields"
        :items="componentsList"
        :tbody-tr-class="rowClass">
        <template
          #head(checkbox)>
          <coozzy-form-checkbox
            ref="header_checkbox"
            @change="(value) => { handleCheckboxesInsideTable(value) }" />
        </template>
        <template #head(description)>
          {{ $t('message.tableColumnHeadings.description') }}
        </template>
        <template #head(bkp)>
          {{ $t('message.tableColumnHeadings.bkp') }}
        </template>
        <template #head(component)>
          {{ $t('message.tableColumnHeadings.component') }}
        </template>
        <template #head(installation)>
          {{ $t('message.tableColumnHeadings.installation') }}
        </template>
        <template #head(lifeSpan)>
          {{ $t('message.tableColumnHeadings.lifeSpan') }}
        </template>
        <template #head(calculatedReplacement)>
          {{ $t('message.tableColumnHeadings.calculatedReplacement') }}
        </template>
        <template #head(costs)>
          {{ $t('message.tableColumnHeadings.costs') }}
        </template>
        <template #head(percentage)>
          %
        </template>
        <template #head(measures)>
          {{ $t('message.tableColumnHeadings.measures') }}
        </template>
        <template #head(plannedReplacement)>
          {{ $t('message.tableColumnHeadings.plannedReplacement') }}
        </template>
        <template #head(futureCostFactor)>
          {{ $t('message.tableColumnHeadings.futureCostFactor') }}
        </template>
        <template #head(plannedCosts)>
          {{ $t('message.tableColumnHeadings.plannedCosts') }}
        </template>
        <!-- Body -->
        <template
          #cell(checkbox)="data">
          <coozzy-form-checkbox
            v-if="data.item.referenceId.startsWith('prop')"
            :id="'check_' + data.item.id"
            :key="'checkbox_' + data.item.id"
            class="selectComponentCheckbox"
            @change="(value) => { itemSelectedGlobal(data.item, value) }" />
        </template>
        <template #cell(description)="data">
          <template v-if="data.item.referenceId.includes('prop_')">
            {{ $t('message.propertiesView.property') }} {{ getPropertyName() }}
          </template>
          <template v-if="data.item.referenceId.includes('building_')">
            {{ $t('message.onBoardingTabs.building') }}
            {{ data.item.referenceId ? getBuildingName(data.item.referenceId) : '' }}
          </template>
          <template v-if="data.item.referenceId.includes('obj_')">
            {{ $t('message.onBoarding.buildings.objects.titleObject') }}
            {{ data.item.referenceId ? getObjectName(data.item.referenceId) : '' }}
          </template>
        </template>
        <template #cell(bkp)="data">
          <coozzy-form-input
            :id="'bkpH_' + data.item.id"
            v-model="data.item.bkp"
            :is-read-only="readOnly"
            :allow-click-input-on-table="true"
            :disabled="!data.item.referenceId.startsWith('prop')"
            @input="emitChanges(data.item)" />
        </template>
        <template #cell(component)="data">
          <coozzy-form-input
            :id="'type_' + data.item.id"
            v-model="data.item.name"
            :is-read-only="readOnly"
            :allow-click-input-on-table="true"
            :disabled="!data.item.referenceId.startsWith('prop')"
            @input="emitChanges(data.item)" />
        </template>
        <template #cell(installation)="data">
          <coozzy-form-input
            :id="'installation_' + data.item.id"
            v-model="data.item.installationDateString"
            :is-read-only="readOnly"
            :filter="'formatDate'"
            type="date"
            :allow-click-input-on-table="true"
            :disabled="!data.item.referenceId.startsWith('prop')"
            @blur="dateToObject(data.item)" />
        </template>
        <template #cell(lifeSpan)="data">
          <coozzy-form-input
            :id="'lifeSpan_' + data.item.id"
            v-model="data.item.lifeSpan"
            :is-read-only="readOnly"
            type="number"
            :min="0"
            :allow-click-input-on-table="true"
            :disabled="!data.item.referenceId.startsWith('prop')"
            @input="calculateReplacementDate(data.item)" />
        </template>
        <template #cell(calculatedReplacement)="data">
          <coozzy-form-input
            :id="'calculatedReplacement_' + data.item.id"
            v-model="data.item.calculatedReplacement"
            :is-read-only="readOnly"
            type="number"
            :min="getYear(data.item.installationDateString)"
            :allow-click-input-on-table="true"
            :disabled="!data.item.referenceId.startsWith('prop')"
            @input="calculateLifeSpan(data.item)" />
        </template>
        <template #cell(costs)="data">
          <coozzy-form-input-numeric
            :id="'costs_' + data.item.id"
            v-model="data.item.costs"
            type="number"
            :is-read-only="readOnly"
            :filter="'formatPrice'"
            placeholder="CHF"
            :is-decimal="true"
            :allow-click-input-on-table="true"
            :disabled="!data.item.referenceId.startsWith('prop')"
            @input="calculateFieldsRelatedToCost(data.item)" />
        </template>
        <template #cell(percentage)="data">
          <coozzy-form-input
            :id="'percentage_' + data.item.id"
            v-model="data.item.percentage"
            :is-read-only="readOnly"
            type="number"
            :allow-click-input-on-table="true"
            :disabled="!data.item.referenceId.startsWith('prop')"
            @input="calculateCost(data.item)" />
        </template>
        <template #cell(measures)="data">
          <coozzy-form-input
            :id="'measures_' + data.item.id"
            v-model="data.item.note"
            :is-read-only="readOnly"
            :allow-click-input-on-table="true"
            :disabled="!data.item.referenceId.startsWith('prop')"
            @input="emitChanges(data.item)" />
        </template>
        <template #cell(plannedReplacement)="data">
          <coozzy-form-input
            :id="'plannedReplacement_' + data.item.id"
            v-model="data.item.plannedReplacementDateString"
            :is-read-only="readOnly"
            :filter="'formatDate'"
            type="date"
            :allow-click-input-on-table="true"
            :disabled="!data.item.referenceId.startsWith('prop')"
            @blur="dateToObject(data.item)" />
        </template>
        <template #cell(futureCostFactor)="data">
          <coozzy-form-input-numeric
            :id="'futureCostFactor_' + data.item.id"
            v-model="data.item.futurePercentage"
            :is-read-only="readOnly"
            type="number"
            filter="formatNumber"
            :min="0"
            :is-decimal="true"
            :allow-click-input-on-table="true"
            :disabled="!data.item.referenceId.startsWith('prop')"
            @input="calculatePlannedCost(data.item)" />
        </template>
        <template #cell(plannedCosts)="data">
          <coozzy-form-input-numeric
            :id="'plannedCosts_' + data.item.id"
            v-model="data.item.plannedCosts"
            type="number"
            :is-read-only="readOnly"
            :filter="'formatPrice'"
            placeholder="CHF"
            :is-decimal="true"
            :allow-click-input-on-table="true"
            :disabled="!data.item.referenceId.startsWith('prop')"
            @input="calculateFutureCostFactor(data.item)" />
        </template>
      </b-table>
      <b-pagination
        v-if="nbrPerPage !== 'all'"
        v-model="currentPage"
        :per-page="nbrPerPage"
        :total-rows="listComponents.length"
        align="center"
        class="mb-3" />
    </coozzy-card>
    <b-modal
      v-if="showCreateComponentModal"
      :id="'createComponentInvestmentModal'"
      ref="createComponentInvestmentModal"
      :title="$t('message.onBoarding.investmentPlanning.addComponent')"
      hide-footer
      no-close-on-backdrop>
      <template>
        <div class="col p-0">
          <b-table
            id="table-groups"
            ref="table-groups"
            :busy="loadingAddComponent"
            :fields="fieldsAddComponent"
            :items="listPropBuildObj"
            bordered
            class="mb-4"
            hover
            responsive="true"
            stacked="md">
            <!-- Busy state -->
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>
            <template #head(category)>
              {{ $t('message.advertisement.objectCategory.name') }}
            </template>
            <template #head(name)>
              {{ $t('message.onBoarding.devices.description') }}
            </template>
            <!-- Body -->
            <template #cell(checkbox)="data">
              <coozzy-form-checkbox
                :id="data.item.id"
                @change="(value) => { itemSelected(data.item, value) }" />
            </template>
            <template #cell(category)="data">
              <template v-if="data.item.id.startsWith('building_')">
                {{ $t('message.generic.building') }}
              </template>
              <template v-else-if="data.item.id.startsWith('prop_')">
                {{ $t('message.propertiesView.tabs.property') }}
              </template>
              <template v-else-if="data.item.category && data.item.category !== 'UNDEFINED_CATEGORY'">
                {{ $t('message.onBoarding.buildings.objects.category.' + data.item.category.toLowerCase()) }}
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template #cell(name)="data">
              {{ data.item.name }}
            </template>
          </b-table>
        </div>
        <div class="col p-0">
          <coozzy-button
            class="mb-0 border"
            design="transparent"
            size="small"
            @click="hideCreateComponentModal">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            :disabled="selectedEntries.length < 1"
            class="float-right mb-0"
            design="green"
            size="small"
            @click="createComponentClicked()">
            {{ $t('message.generic.add') }}
          </coozzy-button>
          <div class="d-flex float-right mr-3 mt-1">
            <coozzy-form-checkbox
              v-model="optionalValueEnhancingComponent"
              :initial="optionalValueEnhancingComponent"
              class="pr-0 mr-0"
              name="optionalValueEnhancingComponent" />
            <span class="align-self-center">
              {{ $t('message.onBoarding.investmentPlanning.optionalValueEnhancingComponent') }}
              <coozzy-info-circle-icon
                v-b-tooltip.hover.html="$t('message.onBoarding.investmentPlanning.tooltipOptionalValue')" />
            </span>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-if="showDeleteModal"
      :id="'confirmationModalDeleteComponents'"
      ref="confirmationModalDeleteComponents"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.investmentPlanning.deleteModal.title')"
      @close="hideDeleteComponentModal">
      <template>
        <div class="col p-0">
          <p>{{ $t('message.onBoarding.investmentPlanning.deleteModal.text') }}</p>
        </div>
        <div class="col p-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="hideDeleteComponentModal">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deleteComponent()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-if="showEditModal"
      :id="'editComponentsModal'"
      ref="editComponentsModal"
      no-close-on-backdrop
      hide-footer
      :title="titleEditModal"
      @close="hideEditComponentsModal">
      <template>
        <div
          v-if="editAction === 'installationDate'"
          class="col p-0">
          <p>{{ $t('message.onBoarding.investmentPlanning.subTitlesEditModal.enterInstallationDate') }}</p>
          <coozzy-form-input
            v-model="newInstallationDate"
            type="date" />
        </div>
        <div
          v-if="editAction === 'costs'"
          class="col p-0">
          <p>{{ $t('message.onBoarding.investmentPlanning.subTitlesEditModal.enterCostComponent') }}</p>
          <coozzy-form-input
            v-model="newCostComponent"
            type="number" />
        </div>
        <div
          v-if="editAction === 'percentage'"
          class="col p-0">
          <p>{{ $t('message.onBoarding.investmentPlanning.subTitlesEditModal.enterDistributionPercent') }}</p>
          <coozzy-form-input
            v-model="newPercentage"
            type="number" />
        </div>
        <div
          v-if="editAction === 'measures'"
          class="col p-0">
          <p>{{ $t('message.onBoarding.investmentPlanning.subTitlesEditModal.enterMeasures') }}</p>
          <coozzy-form-input
            v-model="newMeasures" />
        </div>
        <div
          v-if="editAction === 'plannedReplacement'"
          class="col p-0">
          <p>{{ $t('message.onBoarding.investmentPlanning.subTitlesEditModal.enterPlannedReplacement') }}</p>
          <coozzy-form-input
            v-model="newPlannedReplacementDate"
            type="date" />
        </div>
        <div
          v-if="editAction === 'futureCostFactor'"
          class="col p-0">
          <p>{{ $t('message.onBoarding.investmentPlanning.subTitlesEditModal.enterFutureCostFactor') }}</p>
          <coozzy-form-input-numeric
            v-model="newFuturePercentage"
            type="number"
            filter="formatNumber"
            :min="0"
            :is-decimal="true" />
        </div>
        <div
          v-if="editAction === 'plannedCosts'"
          class="col p-0">
          <p>{{ $t('message.onBoarding.investmentPlanning.subTitlesEditModal.enterPlannedCosts') }}</p>
          <coozzy-form-input-numeric
            v-model="newPlannedCosts"
            type="number"
            filter="formatNumber"
            :min="0"
            :is-decimal="true" />
        </div>
        <div class="col p-0 mt-3">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="hideEditComponentsModal">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="applyEditChanges()">
            {{ $t('message.generic.form.applyAdd') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <assemble-list-modal
      ref="assembleListModal"
      modal-id="assembleListModal"
      :fields="selectedFields"
      :default-fields="dataFields"
      @save-fields="saveFields" />
  </section>
</template>

<script>
import CoozzyFormCheckbox from '../../framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyFormSelect from '../../framework/components/form/select/CoozzyFormSelect'
import { routeChecks } from '@/mixins/routeChecks'
import { tables } from '@/mixins/tables'
import { user } from '@/mixins/user'
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import debounce from 'debounce'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem.vue'
import { componentUtils } from '@/mixins/componentUtils'
import { dateUtils } from '@/mixins/dateUtils'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzyFilterIcon from '@/framework/components/icons/CoozzyFilterIcon.vue'
import { helper } from '@/types/helper'
import ObjectApi from '@/misc/apis/ObjectApi'
import Vue from 'vue'
import InvestPlanApi from '@/misc/apis/InvestPlanApi'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown.vue'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect.vue'
import CoozzyFormInputNumeric from '@/framework/components/form/input/CoozzyFormInputNumeric.vue'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'
import CoozzyFiltersIcon from '@/framework/components/icons/CoozzyFiltersIcon.vue'
import SearchApi from '@/misc/apis/SearchApi'
import AssembleListModal from '@/contact/components/AssembleListModal.vue'

export default {
  name: 'InvestPlanTab',
  components: {
    AssembleListModal,
    CoozzyFiltersIcon,
    CoozzyInfoCircleIcon,
    CoozzyFormInputNumeric,
    CoozzyMultiselect,
    CoozzyDropdown,
    CoozzyFilterIcon,
    CoozzyButton,
    CoozzyDropdownItem,
    CoozzySpinner,
    CoozzyFormInput,
    CoozzyCard,
    CoozzyFormSelect,
    CoozzyFormCheckbox
  },
  mixins: [routeChecks, tables, user, componentUtils, dateUtils, helper],
  props: {
    property: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    addNewComponent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isBusy: true,
      selectedEntries: [],
      nbrPerPage: 5,
      currentPage: 1,
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        }
      ],
      dataFields: [
        {
          key: 'description',
          label: this.$t('message.tableColumnHeadings.description'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'bkp',
          label: this.$t('message.tableColumnHeadings.bkp'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'component',
          label: this.$t('message.tableColumnHeadings.component'),
          thClass: 'align-middle width-15',
          tdClass: 'align-middle width-15',
          selected: true
        },
        {
          key: 'installation',
          label: this.$t('message.tableColumnHeadings.installation'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'lifeSpan',
          label: this.$t('message.tableColumnHeadings.lifeSpan'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'calculatedReplacement',
          label: this.$t('message.tableColumnHeadings.calculatedReplacement'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'costs',
          label: this.$t('message.tableColumnHeadings.costs'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'percentage',
          label: this.$t('message.tableColumnHeadings.percentage'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'measures',
          label: this.$t('message.tableColumnHeadings.measures'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'plannedReplacement',
          label: this.$t('message.tableColumnHeadings.plannedReplacement'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'futureCostFactor',
          label: this.$t('message.tableColumnHeadings.measures'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'plannedCosts',
          label: this.$t('message.tableColumnHeadings.measures'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        }
      ],
      selectedFields: [],
      selectedEntriesGlobal: [],
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      listComponents: [],
      buildingsList: [],
      searchText: '',
      debouncedFilterComponentsList: null,
      closed: false,
      typeSuggestInputProps: {
        id: 'autosuggest__input',
        placeholder: this.$t('message.onBoarding.devices.searchType'),
        class: 'custom-select allowClickInputOnTable form-control'
      },
      objectsList: [],
      fieldsAddComponent: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'category',
          label: this.$t('message.advertisement.objectCategory.name'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'name',
          label: this.$t('message.onBoarding.devices.description'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      loadingAddComponent: false,
      showCreateComponentModal: false,
      allComponents: [],
      filteredSuggestions: [],
      defaultComponents: [],
      shouldShowOnTop: false,
      listSharedObjects: [],
      showDeleteModal: false,
      showEditModal: false,
      editAction: '',
      newInstallationDate: '',
      newCostComponent: 0,
      newPercentage: 0,
      newMeasures: '',
      searchCategories: [],
      listBuildingsPercentage: [],
      listColoredItems: [],
      optionalValueEnhancingComponent: false,
      newPlannedReplacementDate: '',
      newFuturePercentage: '',
      newPlannedCosts: ''
    }
  },
  computed: {
    titleEditModal() {
      switch (this.editAction) {
        case 'installationDate':
          return this.$t('message.onBoarding.investmentPlanning.titlesEditModal.titleInstallationDate')
        case 'costs':
          return this.$t('message.onBoarding.investmentPlanning.titlesEditModal.titleCostComponent')
        case 'percentage':
          return this.$t('message.onBoarding.investmentPlanning.titlesEditModal.titleDistributionPercent')
        case 'measures':
          return this.$t('message.onBoarding.investmentPlanning.titlesEditModal.titleMeasures')
        case 'plannedReplacement':
          return this.$t('message.onBoarding.investmentPlanning.titlesEditModal.titlePlannedReplacement')
        case 'futureCostFactor':
          return this.$t('message.onBoarding.investmentPlanning.titlesEditModal.titleFutureCostFactor')
        case 'plannedCosts':
          return this.$t('message.onBoarding.investmentPlanning.titlesEditModal.titlePlannedCosts')
        default:
          return ''
      }
    },
    localPropertyValue: {
      get() {
        const totalPropertyComponents = this.listComponents.reduce((sum, component) => {
          if (component.referenceId.includes('prop_') && component.optionalValueEnhancingComponent === false) {
            return sum + (parseFloat(component.costs) || 0)
          }
          return sum
        }, 0)
        return this.totalBuildingValue + totalPropertyComponents
      },
      set(value) {
        this.$emit('property-value-changed', value)
      }
    },
    totalBuildingValue() {
      return this.buildingsList.reduce((sum, building) => {
        return sum + (parseInt(building.buildingValue) || 0)
      }, 0)
    },
    listPropBuildObj() {
      const list = this.property ? [this.property] : []
      return list.concat(this.buildingsList).concat(this.objectsList)
    },
    componentsCount() {
      return this.listComponents !== null ? this.listComponents.length : 0
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntriesGlobal.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    componentsList: {
      get() {
        let list = []
        if (this.listComponents && this.listComponents.length > 0) {
          list = this.listComponents
        }
        if (this.nbrPerPage !== 'all') {
          list = list.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
        }
        return list
      },
      set(value) {
        this.listComponents = value
      }
    },
    isFiltered() {
      return this.searchText !== ''
    },
    categoryFilter() {
      const counts = []
      if (this.allComponents.length === 0) {
        return counts
      }
      counts.push({
        count: this.allComponents.filter(x => x.referenceId.includes('prop_')).length,
        value: 'property'
      })
      counts.push({
        count: this.allComponents.filter(x => x.referenceId.includes('building_')).length,
        value: 'building'
      })
      const current = this
      this.allComponents.map(obj => this.findObjectById(obj.referenceId) && this.findObjectById(obj.referenceId).category).forEach(function (x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== 'UNDEFINED_CATEGORY' && x) {
          const sameNetwork = current.allComponents.map(obj => current.findObjectById(obj.referenceId) && current.findObjectById(obj.referenceId).category).filter(z => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    },
    nbrTotalOfHits() {
      return this.listComponents.length
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.listComponents.map(x => x.id || x.internalId) : this.selectedEntriesGlobal.map(obj => obj.id || obj.internalId)
    }
  },
  watch: {
    currentPage: function () {
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    nbrPerPage: function (newVal) {
      localStorage.setItem('investmentPlanningTab-nbrPerPage', newVal)
    }
  },
  created() {
    // Initialize the debounced function in the created lifecycle hook
    this.debouncedFilterComponentsList = debounce(() => {
      this.filterComponentsList()
    }, 300)
  },
  mounted: function () {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'createComponentInvestmentModal') {
        if (this.$route.query && this.$route.query.addNewComponent) {
          const query = Object.assign({}, this.$route.query)
          delete query.addNewComponent
          this.$router.replace({ query })
        }
      }
    })
    this.loadData()
    this.nbrPerPage = localStorage.getItem('investmentPlanningTab-nbrPerPage') ? localStorage.getItem('investmentPlanningTab-nbrPerPage') === 'all' ? 'all' : parseInt(localStorage.getItem('investmentPlanningTab-nbrPerPage')) : 5
    SearchApi.loadSettings(this.accountId).then(response => {
      const settings = response.tableSettings
      let hasOldList = false
      if (settings.length > 0) {
        settings.forEach(setting => {
          if (setting.columns.length > 0 && setting.table === 'investmentPlanningProperty') {
            hasOldList = true
            this.loadFields(setting)
          }
        })
      }
      if (hasOldList === false) {
        this.fields.push(...this.dataFields.filter(x => x.selected))
        this.selectedFields = JSON.parse(JSON.stringify(this.dataFields))
      }
    }).catch(e => {
      console.log(e)
    })
  },
  methods: {
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting)
      this.fields = result?.fields
      this.selectedFields = result?.selectedFields
      this.$refs.componentsTable?.refresh()
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      SearchApi.saveSettings(this.accountId, columns, 'investmentPlanningProperty').then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal')
      })
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    calculatePlannedCost(item) {
      const componentIndex = this.listComponents.findIndex(component => component.id && item.id ? component.id === item.id : component.internalId === item.internalId)
      if (componentIndex !== -1) {
        item.plannedCosts = item.futurePercentage && item.futurePercentage !== 0 ? Math.round(((parseFloat(item.costs) * (parseFloat(item.futurePercentage) / 100)) + parseFloat(item.costs)) * 20) / 20 : item.costs
        this.$refs.componentsTable?.refresh()
        this.emitChanges(item)
      }
    },
    calculateFutureCostFactor(item) {
      const componentIndex = this.listComponents.findIndex(component => component.id && item.id ? component.id === item.id : component.internalId === item.internalId)
      if (componentIndex !== -1) {
        item.futurePercentage = item.plannedCosts ? Math.round((((item.plannedCosts - parseFloat(item.costs)) / parseFloat(item.costs)) * 100) * 20) / 20 : null
        this.$refs.componentsTable?.refresh()
        this.emitChanges(item)
      }
    },
    customLabelCategory(item) {
      if (item.value === 'property') {
        return this.$t('message.propertiesView.tabs.property') + ' (' + item.count + ')'
      }
      if (item.value === 'building') {
        return this.$t('message.generic.building') + ' (' + item.count + ')'
      }
      return this.$t('message.onBoarding.buildings.objects.category.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    hideEditComponentsModal() {
      this.$refs.editComponentsModal.hide()
      this.showEditModal = false
      this.editAction = ''
    },
    bulkEditComponents(type) {
      this.editAction = type
      this.showEditModal = true
      this.$nextTick(() => {
        this.$refs.editComponentsModal.show()
      })
    },
    async applyEditChanges() {
      if (this.editAction === 'installationDate') {
        this.selectedEntriesIds.forEach(id => {
          this.listComponents.forEach(component => {
            if (component.id && id ? component.id === id : component.internalId === id) {
              component.installationDate = this.toObject(this.newInstallationDate)
              component.installationDateString = this.newInstallationDate
              component.modified = true
              this.calculateReplacementDate(component)
            }
          })
        })
      } else if (this.editAction === 'costs') {
        this.selectedEntriesIds.forEach(id => {
          this.listComponents.forEach(component => {
            if (component.id && id ? component.id === id : component.internalId === id) {
              component.costs = this.newCostComponent
              component.modified = true
              this.calculateFieldsRelatedToCost(component)
            }
          })
        })
      } else if (this.editAction === 'percentage') {
        this.selectedEntriesIds.forEach(id => {
          this.listComponents.forEach(component => {
            if (component.id && id ? component.id === id : component.internalId === id) {
              component.percentage = this.newPercentage
              component.costs = Math.round(((this.totalBuildingValue * this.newPercentage) / 100) * 20) / 20
              component.modified = true
            }
          })
        })
      } else if (this.editAction === 'measures') {
        this.selectedEntriesIds.forEach(id => {
          this.listComponents.forEach(component => {
            if (component.id && id ? component.id === id : component.internalId === id) {
              component.note = this.newMeasures
              component.modified = true
            }
          })
        })
      } else if (this.editAction === 'plannedReplacement') {
        this.selectedEntriesIds.forEach(id => {
          this.listComponents.forEach(component => {
            if (component.id && id ? component.id === id : component.internalId === id) {
              component.plannedReplacementDate = this.toObject(this.newPlannedReplacementDate)
              component.plannedReplacementDateString = this.newPlannedReplacementDate
              component.modified = true
            }
          })
        })
      } else if (this.editAction === 'futureCostFactor') {
        this.selectedEntriesIds.forEach(id => {
          this.listComponents.forEach(component => {
            if (component.id && id ? component.id === id : component.internalId === id) {
              component.futurePercentage = this.newFuturePercentage
              component.plannedCosts = component.futurePercentage ? Math.round(((parseFloat(component.costs) * (parseFloat(component.futurePercentage) / 100)) + parseFloat(component.costs)) * 20) / 20 : null
              component.modified = true
            }
          })
        })
      } else if (this.editAction === 'plannedCosts') {
        this.selectedEntriesIds.forEach(id => {
          this.listComponents.forEach(component => {
            if (component.id && id ? component.id === id : component.internalId === id) {
              component.plannedCosts = this.newPlannedCosts
              component.futurePercentage = component.plannedCosts ? Math.round((((component.plannedCosts - parseFloat(component.costs)) / parseFloat(component.costs)) * 100) * 20) / 20 : null
              component.modified = true
            }
          })
        })
      }
      if (this.readOnly) {
        await this.saveData()
      } else {
        this.emitChanges()
      }
      this.hideEditComponentsModal()
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    showCreateComponentModalClicked() {
      this.showCreateComponentModal = true
      this.optionalValueEnhancingComponent = false
      this.$nextTick(() => {
        this.$refs.createComponentInvestmentModal.show()
      })
    },
    hideCreateComponentModal() {
      this.$refs.createComponentInvestmentModal.hide()
      this.optionalValueEnhancingComponent = false
      this.showCreateComponentModal = false
      this.selectedEntries = []
    },
    createComponentClicked() {
      this.loadingAddComponent = true
      this.selectedEntries.forEach(entry => {
        if (entry.includes('prop')) {
          this.addNewPropertyComponent()
        } else if (entry.includes('building')) {
          this.addNewBuildingComponent(entry)
        } else {
          this.addNewObjectComponent(entry)
        }
      })
      this.loadingAddComponent = false
      this.hideCreateComponentModal()
      this.emitChanges()
    },
    addNewPropertyComponent() {
      const internalId = Date.now() + Math.floor(Math.random() * 1000)
      const component = JSON.parse(JSON.stringify(helper.emptyComponent))
      component.internalId = JSON.parse(JSON.stringify(internalId))
      component.ownerId = this.property.ownerId
      component.referenceId = this.property.id
      component.optionalValueEnhancingComponent = this.optionalValueEnhancingComponent

      if (this.optionalValueEnhancingComponent) {
        this.listComponents.push(component)
      } else {
        this.listComponents.unshift(component)
      }
    },
    addNewBuildingComponent(buildingId) {
      const internalId = Date.now() + Math.floor(Math.random() * 1000)
      const component = JSON.parse(JSON.stringify(helper.emptyComponent))
      component.internalId = JSON.parse(JSON.stringify(internalId))
      component.ownerId = this.buildingsList.find(b => b.id === buildingId).ownerId
      component.referenceId = buildingId
      component.optionalValueEnhancingComponent = this.optionalValueEnhancingComponent

      if (this.optionalValueEnhancingComponent) {
        this.listComponents.push(component)
      } else {
        this.listComponents.unshift(component)
      }
    },
    addNewObjectComponent(objectId) {
      const internalId = Date.now() + Math.floor(Math.random() * 1000)
      const component = JSON.parse(JSON.stringify(helper.emptyComponent))
      component.internalId = JSON.parse(JSON.stringify(internalId))
      component.ownerId = this.objectsList.find(b => b.id === objectId).ownerId
      component.referenceId = objectId
      component.optionalValueEnhancingComponent = this.optionalValueEnhancingComponent

      if (this.optionalValueEnhancingComponent) {
        this.listComponents.push(component)
      } else {
        this.listComponents.unshift(component)
      }
    },
    goToEditComponent() {
      this.$router.push({
        name: this.isOwnerModule ? 'OwnerPropertyEditingView' : this.isAssetModule ? 'AssetPropertyEditingView' : this.isAccountingModule ? 'AccountingPropertyEditingView' : 'AdminPropertyEditingView',
        params: { id: this.property.id },
        query: {
          view: 'investmentPlanning',
          addNewComponent: true
        }
      })
    },
    initFilter() {
      this.searchText = ''
    },
    dateToObject(item) {
      const componentIndex = this.listComponents.findIndex(component => component.id && item.id ? component.id === item.id : component.internalId === item.internalId)
      if (componentIndex !== -1) {
        this.listComponents[componentIndex].installationDate = this.toObject(this.listComponents[componentIndex].installationDateString)
        this.listComponents[componentIndex].plannedReplacementDate = this.toObject(this.listComponents[componentIndex].plannedReplacementDateString)
        this.calculateReplacementDate(item)
      }
    },
    async loadData() {
      this.isBusy = true
      await ObjectApi.getBuildingsListByIds(this.property.buildingIds).then(response => {
        if (response.buildings) {
          this.buildingsList = response.buildings
        }
      })
      let objectIds = []
      this.buildingsList.forEach(building => {
        objectIds = objectIds.concat(building.objectIds)
      })
      await ObjectApi.listObjectsByIds(objectIds).then(response => {
        const list = response.objects
        // sort the list by category translated
        this.objectsList = list.sort((a, b) => {
          return this.$t('message.onBoarding.buildings.objects.category.' + a.category.toLowerCase()) > this.$t('message.onBoarding.buildings.objects.category.' + b.category.toLowerCase()) ? 1 : -1
        })
      })
      this.loadComponents()
    },
    async loadComponents(firstLoading = true) {
      let refIds = []
      if (this.property) {
        refIds.push(this.property.id)
        if (this.property.buildingIds.length > 0) {
          refIds = refIds.concat(this.property.buildingIds)
        }
      }
      this.buildingsList.forEach(building => {
        refIds = refIds.concat(building.objectIds)
      })
      try {
        const response = await InvestPlanApi.listComponentsByReferenceIds(refIds)
        const componentsResponse = response.components
        let components = []
        if (componentsResponse.length === 0 || (firstLoading && !componentsResponse.some(component => component.referenceId.includes('prop_')))) {
          components = await this.getDefaultComponents()
        }
        components = JSON.parse(JSON.stringify(componentsResponse.concat(components)))
        const order = ['prop_', 'building_', 'obj_']
        const getOrderIndex = (id) => order.findIndex(prefix => id.startsWith(prefix))

        // Group components by referenceId
        const groupedComponents = components.reduce((acc, component) => {
          const key = component.referenceId
          if (!acc[key]) {
            acc[key] = []
          }
          acc[key].push(component)
          return acc
        }, {})

        // Sort each group by bkp
        for (const key in groupedComponents) {
          groupedComponents[key].sort((a, b) => {
            if (a.bkp === '' && b.bkp !== '') return 1
            if (a.bkp !== '' && b.bkp === '') return -1
            return a.bkp.localeCompare(b.bkp)
          })
        }

        // Separate objects from properties and buildings
        const objects = Object.values(groupedComponents).flat().filter(component => component.referenceId.startsWith('obj_'))
        const properties = Object.values(groupedComponents).flat().filter(component => component.referenceId.startsWith('prop_'))
        const buildings = Object.values(groupedComponents).flat().filter(component => component.referenceId.startsWith('building_'))

        // Sort the objects by number (numeric sorting)
        objects.sort((a, b) => {
          const objA = this.objectsList.find(obj => obj.id === a.referenceId)
          const objB = this.objectsList.find(obj => obj.id === b.referenceId)

          const numA = parseInt(objA.number)
          const numB = parseInt(objB.number)

          if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB
          } else if (!isNaN(numA)) {
            return -1
          } else if (!isNaN(numB)) {
            return 1
          } else {
            return objA.number.localeCompare(objB.number)
          }
        })
        const list = [...properties, ...buildings, ...objects]
        // Now merge back the sorted objects with the sorted properties and buildings
        this.listComponents = list.sort((a, b) => {
            const isOptionalA = a.optionalValueEnhancingComponent
            const isOptionalB = b.optionalValueEnhancingComponent

            // First, sort by optionalValueEnhancingComponent and referenceId
            if (isOptionalA && isOptionalB) {
              return getOrderIndex(a.referenceId) - getOrderIndex(b.referenceId)
            }

            if (isOptionalA) {
              return 1
            }
            if (isOptionalB) {
              return -1
            }

            // Then, apply existing sorting based on referenceId
            return getOrderIndex(a.referenceId) - getOrderIndex(b.referenceId)
          })
        this.listComponents.forEach(component => {
          component.installationDateString = this.$options.filters.objectToDateInput(component.installationDate)
          component.plannedReplacementDateString = this.$options.filters.objectToDateInput(component.plannedReplacementDate)
          component.calculatedReplacement = this.fillOutCalculatedReplacement(component)
          if (component.installationDate) {
            component.lifeSpan = this.fillOutLifeSpan(component)
          }
        })
      } catch (e) {
        console.log(e)
      }
      this.getListColoredItems()
      this.allComponents = JSON.parse(JSON.stringify(this.listComponents))
      this.isBusy = false
      if (this.addNewComponent && !this.readOnly) {
        this.showCreateComponentModalClicked()
      }
    },
    getListColoredItems() {
      this.listColoredItems = this.listComponents.reduce((acc, component) => {
        const referenceId = component.referenceId
        if (!acc[referenceId]) {
          acc[referenceId] = []
        }
        acc[referenceId].push(component.id || component.internalId)
        return acc
      }, {})
    },
    rowClass(item, type) {
      if (type === 'row') {
        let indexColor = -1
        if (item.optionalValueEnhancingComponent) {
          return 'optionalValue'
        } else {
          Object.entries(this.listColoredItems).forEach(([key, value], index) => {
            const indexComp = value.find((comp) => comp === item.id || comp === item.internalId)
            if (indexComp) {
              indexColor = index
            }
          })
          return indexColor % 2 === 0 ? '' : 'dark_line'
        }
      }
      return ''
    },
    async getDefaultComponents() {
      const ownerId = this.property.ownerId
      const parkingSpaceExists = this.objectsList.some(obj => obj.category === 'PARKING_SPACE')
      this.typeComponentsProperty.forEach(type => {
        if (type !== 'COMPONENT_TYPE_UNDERGROUND_GARAGE' || (type === 'COMPONENT_TYPE_UNDERGROUND_GARAGE' && parkingSpaceExists)) {
          const internalId = Date.now() + Math.floor(Math.random() * 1000)
          const component = JSON.parse(JSON.stringify(helper.emptyComponent))
          component.internalId = JSON.parse(JSON.stringify('p_' + internalId))
          component.ownerId = ownerId
          component.referenceId = this.property.id
          component.type = type
          component.name = this.$t('message.onBoarding.investmentPlanning.types.' + type)
          component.bkp = this.getBkpComponent(type)
          component.lifeSpan = this.getLifeSpanComponent(type)
          component.percentage = this.getPercentageComponent(type)
          component.installationDateString = ''
          component.plannedReplacementDateString = ''
          component.calculatedReplacement = ''
          component.optionalValueEnhancingComponent = false
          this.defaultComponents.push(component)
        }
      })
      return this.defaultComponents
    },
    calculateReplacementDate(item) {
      const componentIndex = this.listComponents.findIndex(component => component.id && item.id ? component.id === item.id : component.internalId === item.internalId)
      if (componentIndex !== -1) {
        this.listComponents[componentIndex].calculatedReplacement = this.fillOutCalculatedReplacement(this.listComponents[componentIndex])
        this.$refs.componentsTable?.refresh()
        this.emitChanges(item)
      }
    },
    calculateLifeSpan(item) {
      const componentIndex = this.listComponents.findIndex(component => component.id && item.id ? component.id === item.id : component.internalId === item.internalId)
      if (componentIndex !== -1) {
        this.listComponents[componentIndex].lifeSpan = this.fillOutLifeSpan(this.listComponents[componentIndex])
        this.$refs.componentsTable?.refresh()
        this.emitChanges(item)
      }
    },
    calculateCost(item) {
      const componentIndex = this.listComponents.findIndex(component => component.id && item.id ? component.id === item.id : component.internalId === item.internalId)
      if (componentIndex !== -1) {
        if (item.optionalValueEnhancingComponent === false) {
          item.costs = Math.round((this.totalBuildingValue * (parseFloat(item.percentage) / 100)) * 20) / 20
          this.$refs.componentsTable?.refresh()
        }
        this.emitChanges(item)
      }
    },
    calculateFieldsRelatedToCost(item) {
      const componentIndex = this.listComponents.findIndex(component => component.id && item.id ? component.id === item.id : component.internalId === item.internalId)
      if (componentIndex !== -1) {
        if (item.optionalValueEnhancingComponent === false) {
          item.percentage = Math.round(((parseFloat(item.costs) * 100) / this.totalBuildingValue) * 20) / 20
          this.$refs.componentsTable?.refresh()
        }
        item.futurePercentage = item.plannedCosts ? Math.round((((item.plannedCosts - parseFloat(item.costs)) / parseFloat(item.costs)) * 100) * 20) / 20 : null
        item.plannedCosts = item.futurePercentage && item.futurePercentage !== 0 ? Math.round(((parseFloat(item.costs) * (parseFloat(item.futurePercentage) / 100)) + parseFloat(item.costs)) * 20) / 20 : item.costs
        this.emitChanges(item)
      }
    },
    findObjectById(id) {
      return this.objectsList.find(obj => obj.id === id)
    },
    findBuildingById(id) {
      return this.buildingsList.find(building => building.id === id)
    },
    filterComponentsList() {
      this.$nextTick(function () {
        this.listComponents = this.allComponents.filter(item => {
          const nameSearch = item.name ? item.name.toLowerCase().includes(this.searchText.toLowerCase()) : null
          const bkpHSearch = item.bkp ? item.bkp.includes(this.searchText.toLowerCase()) : null
          const categoryMapped = this.searchCategories.map(x => x.value)
          let cdtCategory
          if (item.referenceId.includes('prop_')) {
            cdtCategory = categoryMapped.includes('property') || this.searchCategories.length === 0
          } else if (item.referenceId.includes('building_')) {
            cdtCategory = categoryMapped.includes('building') || this.searchCategories.length === 0
          } else {
            cdtCategory = categoryMapped.includes(this.findObjectById(item.referenceId).category) || this.searchCategories.length === 0
          }
          let cdtNameObj
          if (item.referenceId.includes('prop_')) {
            cdtNameObj = this.property.name ? (this.property.name.toLowerCase()).includes(this.searchText.toLowerCase()) : null
          } else if (item.referenceId.includes('building_')) {
            cdtNameObj = this.findBuildingById(item.referenceId).name ? (this.findBuildingById(item.referenceId).name.toLowerCase()).includes(this.searchText.toLowerCase()) : null
          } else {
            cdtNameObj = this.findObjectById(item.referenceId).name ? (this.findObjectById(item.referenceId).name.toLowerCase()).includes(this.searchText.toLowerCase()) : null
          }
          return (bkpHSearch || nameSearch || cdtNameObj) && cdtCategory
        })
      })
    },
    searchInputText() {
      this.debouncedFilterComponentsList()
    },
    fillOutLifeSpan(item) {
      if (item.calculatedReplacement) {
        let date = new Date(item.installationDateString)
        return item.calculatedReplacement - date.getFullYear()
      }
      return ''
    },
    getYear(date) {
      if (date) {
        let d = new Date(this.$options.filters.objectToDateInput(date))
        return d.getFullYear()
      }
    },
    fillOutCalculatedReplacement(item) {
      if (item.installationDateString) {
        let date = new Date(item.installationDateString)
        return date.getFullYear() + (item.lifeSpan ? parseInt(item.lifeSpan) : 0)
      }
      return ''
    },
    getPropertyName() {
      return this.property ? this.property.name : ''
    },
    getBuildingName(id) {
      return (this.buildingsList.length > 0 && this.buildingsList.find(x => x.id === id)) ? this.buildingsList.find(x => x.id === id).name : ''
    },
    getObjectName(id) {
      return (this.objectsList.length > 0 && this.objectsList.find(x => x.id === id)) ? this.objectsList.find(x => x.id === id).name : ''
    },
    itemSelected(item, checked) {
      if (checked) {
        this.selectedEntries.push(item.id)
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry !== item.id)
      }
    },
    itemSelectedGlobal(item, checked) {
      if (checked) {
        this.selectedEntriesGlobal.push(item)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntriesGlobal = this.selectedEntriesGlobal.filter(entry => item.internalId && entry.internalId ? entry.internalId !== item.internalId : entry.id && item.id ? entry.id !== item.id : false)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs.componentsTable) {
        const allCheckboxesComponents = this.$refs.componentsTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
        this.selectAllVisibleEntries = value
      }
    },
    emitChanges(component) {
      if (component) {
        const index = this.listComponents.findIndex(c => c.id === component.id)
        if (index !== -1) {
          this.listComponents[index].modified = true
        }
      }
      this.$emit('change-component', this.listComponents)
    },
    async saveData() {
      this.isBusy = true
      // this.$emit('set-loading', true)
      const promises = []
      const listComponentToCreate = []
      const listComponentToEdit = []
      for (const component of this.listComponents) {
        if (component.internalId) {
          listComponentToCreate.push(component)
        } else if (component.modified) {
          listComponentToEdit.push(component)
        }
      }
      if (listComponentToCreate.length > 0) {
        promises.push(InvestPlanApi.createComponentBulk(listComponentToCreate))
      }
      if (listComponentToEdit.length > 0) {
        promises.push(InvestPlanApi.updateComponentBulk(listComponentToEdit))
      }
      return Promise.all(promises).then(() => {
        Vue.toasted.show(this.$tc('message.successMessages.componentSaved'), { type: 'success' })
        this.loadComponents(false)
      })
    },
    hideDeleteComponentModal() {
      this.$refs.confirmationModalDeleteComponents.hide()
      this.showDeleteModal = false
    },
    deleteComponentsClicked() {
      this.showDeleteModal = true
      this.$nextTick(() => {
        this.$refs.confirmationModalDeleteComponents.show()
      })
    },
    deleteComponent() {
      const promises = []
      // separate new components and existing components
      const { internalIds, ids } = this.selectedEntriesIds.reduce((acc, id) => {
        if (typeof id === 'string' && id.startsWith('comp_')) {
          acc.ids.push(id)
        } else {
          acc.internalIds.push(id)
        }
        return acc
      }, { internalIds: [], ids: [] })

      if (internalIds.length > 0) {
        this.listComponents = this.listComponents.filter(component => !internalIds.includes(component.internalId))
      }

      if (ids.length > 0) {
        ids.forEach(id => {
          promises.push(InvestPlanApi.deleteComponent(id))
        })
        Promise.all(promises).then(() => {
          this.hideDeleteComponentModal()
          this.loadComponents(false)
        })
      } else {
        this.hideDeleteComponentModal()
      }
      this.$emit('change-component', this.listComponents)
      this.handleCheckboxesInsideTable(false)
      this.selectedEntriesGlobal = []
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.selectComponentCheckbox) > .custom-control-input {
  z-index: 10;
}

.suggestion-container-bottom {
  position: absolute !important;
  background-color: white;
  z-index: 1000;
  border: 1px solid #ddd;
  max-height: 200px;
  overflow-y: auto;
  top: 100%;
  left: 0;
  right: 0;
}

.suggestion-container-top {
  position: absolute !important;
  background-color: white;
  z-index: 1000;
  border: 1px solid #ddd;
  max-height: 200px;
  overflow-y: auto;
  bottom: 100%;
  left: 0;
  right: 0;
}
:deep(.dark_line) {
  background-color: #eee;
  td {
    background-color: #eee !important;
  }
}
:deep(.width-8) {
  width: 8%;
}
:deep(.width-15) {
  width: 15%;
}
:deep(.width-20) {
  width: 20%;
}
:deep(.optionalValue) {
  background-color: #FFF9E6;
  td {
    background-color: #FFF9E6 !important;
  }
}
</style>
