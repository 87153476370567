<template>
  <section>
    <div>
      <coozzy-page-title
        :title="$t('message.navigation.reminder')"
        :count="reminders.length"
        :count-text="$t('message.navigation.reminder')"
        :action-text="$t('message.calendar.reminder.create')"
        :clear-filter="isFiltered"
        @click="createReminderClicked(null)"
        @clear-filter="initFilter()" />
      <!-- Filter -->
      <div class="row align-items-center">
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-form-select
            v-model="searchPriority"
            name="searchPriority">
            <option :value="null">
              {{ $t('message.calendar.reminder.priority.title') }}
            </option>
            <option value="LOW_REMINDER_PRIORITY">
              {{ $t('message.calendar.reminder.priority.LOW_REMINDER_PRIORITY') }}
            </option>
            <option value="NORMAL_REMINDER_PRIORITY">
              {{ $t('message.calendar.reminder.priority.NORMAL_REMINDER_PRIORITY') }}
            </option>
            <option value="HIGH_REMINDER_PRIORITY">
              {{ $t('message.calendar.reminder.priority.HIGH_REMINDER_PRIORITY') }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col-12 col-md-10">
          <coozzy-assignee-multiselect
            v-model="searchEmployee"
            :options="availableEmployees"
            :multiple="true"
            :placeholder=" $t('message.calendar.reminder.employee')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :preselect-first="false"
            group-values="employees"
            group-label="employee"
            :group-select="true"
            :taggable="false"
            label="label"
            track-by="label" />
        </div>
      </div>
      <div class="row mt-2 align-items-center">
        <div class="col-md-2">
          <coozzy-dropdown
            design="green"
            size="sm"
            class="w-100"
            :text="selectedEntriesText">
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="showConfirmationModal">
              {{ $t('message.calendar.reminder.markAsDone') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-space-divider />
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="openChangeModal('date')">
              {{ $t('message.calendar.reminder.changeDate') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="openChangeModal('priority')">
              {{ $t('message.calendar.reminder.changePriority') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="openChangeModal('employee')">
              {{ $t('message.calendar.reminder.changeEmployee') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2 col-sm-6 col-12">
          <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :initial="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              name="selectAllEntries"
              button>
              {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: nbrTotalOfHits }) : $t('message.generic.selectHiddenEntries', { amount: nbrTotalOfHits }) }}
            </coozzy-form-checkbox>
          </template>
        </div>
        <div class="col-md-2 col-sm-6 col-12 offset-md-6 mt-2 mt-sm-0">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries float-right">
            <option :value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option :value="50">
              50 {{ $t('message.generic.entries') }}
            </option>
            <option :value="100">
              100 {{ $t('message.generic.entries') }}
            </option>
            <option :value="500">
              500 {{ $t('message.generic.entries') }}
            </option>
            <option :value="1000">
              1000 {{ $t('message.generic.entries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <b-table
            ref="remindersTable"
            bordered
            hover
            responsive="true"
            stacked="md"
            selectable
            select-mode="single"
            :busy="isBusy"
            :fields="fields"
            :items="reminders"
            :current-page="currentPage"
            :per-page="nbrPerPage"
            :tbody-tr-class="getRowClass"
            @row-clicked="onRowClicked">
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>

            <!-- Headings -->
            <template #head(checkbox)>
              <coozzy-form-checkbox
                ref="header_checkbox"
                @change="(value) => { handleCheckboxesInsideTable(value) }" />
            </template>
            <template #head(date)>
              {{ $t('message.calendar.reminder.date') }}
            </template>
            <template #head(priority)>
              {{ $t('message.calendar.reminder.priority.title') }}
            </template>
            <template #head(employee)>
              {{ $t('message.calendar.reminder.employee') }}
            </template>
            <template #head(contact)>
              {{ $t('message.calendar.reminder.contact') }}
            </template>
            <template #head(reference)>
              {{ $t('message.calendar.reminder.reference') }}
            </template>
            <template #head(subject)>
              {{ $t('message.calendar.reminder.subject') }}
            </template>
            <template #head(activity)>
              {{ $t('message.calendar.reminder.activity.title') }}
            </template>
            <template #head(creator)>
              {{ $t('message.ticketOverview.ticket.creator') }}
            </template>

            <!-- Columns -->
            <template
              #cell(checkbox)="data">
              <coozzy-form-checkbox
                :id="'check_' + data.item.id"
                @change="(value) => { itemSelected(data.item, value) }" />
            </template>
            <template
              #cell(date)="data">
              <span :class="[data.item.done !== '0' ? 'text-gray' : '']">
                {{ data.item.date | timestampToDateTime }}
              </span>
            </template>
            <template
              #cell(priority)="data">
              <template
                v-if="data.item.priority === 'HIGH_REMINDER_PRIORITY'">
                <img
                  :src="`/img/priority/prio-HIGH.svg`"
                  :alt="$t('message.calendar.reminder.priority.' + data.item.priority)"
                  class="ml-3"
                  height="15px">
              </template>
              <template
                v-if="data.item.priority === 'NORMAL_REMINDER_PRIORITY'">
                <img
                  :src="`/img/priority/prio-DEFAULT.svg`"
                  :alt="$t('message.calendar.reminder.priority.' + data.item.priority)"
                  class="ml-3"
                  height="15px">
              </template>
              <template
                v-if="data.item.priority === 'LOW_REMINDER_PRIORITY'">
                <img
                  :src="`/img/priority/prio-LOW.svg`"
                  :alt="$t('message.calendar.reminder.priority.' + data.item.priority)"
                  class="ml-3"
                  height="15px">
              </template>
            </template>
            <template
              #cell(employee)="data">
              <span :class="[data.item.done !== '0' ? 'text-gray' : '']">
                <employee-name-text
                  :user-id="data.item.employeeUserId" />
              </span>
            </template>
            <template
              #cell(creator)="data">
              <span :class="[data.item.done !== '0' ? 'text-gray' : '']">
                <employee-name-text
                  :user-id="data.item.creatorUserId" />
              </span>
            </template>
            <template
              #cell(contact)="data">
              <router-link
                v-if="data.item.contactId !== null && data.item.contactId !== '' && getContactDetailRoute(data.item.contactId)"
                :to="getContactDetailRoute(data.item.contactId)"
                :class="[data.item.done !== '0' ? 'text-gray' : '']">
                {{ getContactName(data.item.contactId) }}
              </router-link>
              <span
                v-else
                :class="[data.item.done !== '0' ? 'text-gray' : '']">
                {{ getContactName(data.item.contactId) }}
              </span>
            </template>
            <template
              #cell(reference)="data">
              <div
                :class="[data.item.done !== '0' ? 'text-gray' : '']">
                <template v-if="data.item.referenceIds.length > 0">
                  <div
                    v-for="item in data.item.referenceIds"
                    :key="item.entity">
                    <span
                      v-if="getReference(item)">
                      <template v-if="getReference(item).deleted === false && getReference(item).value">
                        {{ $t(`message.calendar.reminder.referenceKey.${getReference(item).entity.split('_')[0]}`) }}:
                        <template v-for="(value, index) in getArrayValues(getReference(item).value)">
                          {{ index !== 0 ? ',': '' }}
                          <router-link
                            v-if="value && getReference(item).entity.split('_')[0] === 'obj' && getReferenceDetailPageRoute(getReference(item))"
                            :key="index"
                            :to="getReferenceDetailPageRoute(getReference(item))"
                            :class="[data.item.done !== '0' ? 'text-gray' : '']">
                            {{ getObjectRefDisplayName(getReference(item).value) }}
                          </router-link>
                          <a
                            v-else-if="getReference(item).entity.split('_')[0] === 'mail'"
                            :key="index + '-mail'"
                            class="message-link"
                            :class="[data.item.done !== '0' ? 'text-gray' : '']"
                            @click="showDetailMail(value)">
                            {{ value.subject !== '' ? value.subject : $t('message.calendar.reminder.noSubject') }}
                          </a>
                          <router-link
                            v-else-if="getReferenceDetailPageRoute(getReference(item))"
                            :key="index + '-ref'"
                            :to="getReferenceDetailPageRoute(getReference(item))"
                            :class="[data.item.done !== '0' ? 'text-gray' : '']">
                            {{ value && value.name ? value.name : 'n/a' }}
                          </router-link>
                        </template>
                      </template>
                      <template v-else-if="getReference(item).deleted === true">
                        {{ $t(`message.calendar.reminder.referenceKey.${getReference(item).entity.split('_')[0]}`) }}:
                        {{ $t('message.generic.deleted') }}
                      </template>
                    </span>
                  </div>
                </template>
                <template v-else>
                  -
                </template>
              </div>
            </template>
            <template
              #cell(subject)="data">
              <span :class="[data.item.done !== '0' ? 'text-gray' : '']">
                {{ data.item.subject | displayOptionalValue }}
              </span>
            </template>
            <template
              #cell(activity)="data">
              <span :class="[data.item.done !== '0' ? 'text-gray' : '']">
                {{
                  data.item.activity !== 'UNDEFINED_REMINDER_ACTIVITY' && data.item.activity !== '' ? $t('message.calendar.reminder.activity.' + data.item.activity) : '-'
                }}
              </span>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            align="center"
            class="mt-3 mb-3"
            :total-rows="reminders.length"
            :per-page="nbrPerPage" />
        </div>
      </div>

      <b-modal
        id="confirmationModalReminder"
        ref="confirmationModalReminder"
        no-close-on-backdrop
        hide-footer
        :title="$t('message.calendar.reminder.confirmation.title')">
        <div class="col p-0">
          <p>{{ $t('message.calendar.reminder.confirmation.text') }}</p>
        </div>
        <div class="col p-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('confirmationModalReminder')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="updateDoneStatus()">
            {{ $t('message.generic.confirm') }}
          </coozzy-button>
        </div>
      </b-modal>
      <!-- change modal -->
      <b-modal
        id="changeModal"
        ref="changeModal"
        no-close-on-backdrop
        hide-footer
        :title="$t('message.calendar.reminder.changeValue')">
        <div
          v-if="changeType === 'date'"
          class="col-12 mb-3 p-0">
          <div class="row">
            <div class="col-6 col-md-4 mb-3 mb-md-0">
              <label>{{ $t('message.calendar.event.date') }}*</label>
              <coozzy-form-input
                id="startDate"
                ref="startDate"
                v-model="reminderDate"
                type="date"
                :state="$v.reminderDate.$dirty && $v.reminderDate.$error ? false : null" />
            </div>
            <div class="col-6 col-md-2 mb-3 mb-md-0">
              <label>{{ $t('message.calendar.event.time') }}</label>
              <coozzy-form-select
                id="startTime"
                v-model="reminderTime">
                <option
                  v-for="t in timeList"
                  :key="'start' + t"
                  :value="t">
                  {{ t }}
                </option>
              </coozzy-form-select>
            </div>
          </div>
        </div>
        <div
          v-if="changeType === 'priority'"
          class="col mb-3 p-0">
          <label for="reminderPriority">{{ $t('message.calendar.reminder.priority.title') }}</label>
          <coozzy-form-select
            id="reminderPriority"
            v-model="reminderPriority"
            name="priority">
            <option value="LOW_REMINDER_PRIORITY">
              {{ $t('message.calendar.reminder.priority.LOW_REMINDER_PRIORITY') }}
            </option>
            <option value="NORMAL_REMINDER_PRIORITY">
              {{ $t('message.calendar.reminder.priority.NORMAL_REMINDER_PRIORITY') }}
            </option>
            <option value="HIGH_REMINDER_PRIORITY">
              {{ $t('message.calendar.reminder.priority.HIGH_REMINDER_PRIORITY') }}
            </option>
          </coozzy-form-select>
        </div>
        <div
          v-if="changeType === 'employee'"
          class="col mb-3 p-0">
          <label>{{ $t('message.calendar.reminder.employee') }}</label>
          <coozzy-form-select
            v-model="reminderEmployees">
            <option value="" />
            <option
              v-for="employee in activeEmployeeList"
              :key="employee.id"
              :value="employee.id">
              <employee-name-text :user-id="employee.id" />
            </option>
          </coozzy-form-select>
        </div>
        <div class="col mt-2 p-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('changeModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="saveChangeModal">
            {{ $t('message.generic.form.save') }}
          </coozzy-button>
        </div>
      </b-modal>
      <reminder-creation-modal
        v-if="isReady"
        ref="modal-creation"
        :key="remainderForEdit !== null ? 'modal-creation' + remainderForEdit.id : 'modal-creation'"
        :reminder-detail="remainderForEdit"
        :available-employees="availableEmployees"
        :available-contacts="reminderContacts"
        :source="'remindersView'"
        @new-reminder="reminderUpdated()"
        @update-reminder="reminderUpdated()" />

      <reminder-details-modal
        v-if="selectedReminder !== null"
        ref="detail-reminder-modal"
        :key="selectedReminder.id"
        :reminder-detail="selectedReminder"
        :available-employees="availableEmployees"
        @create-reminder-click="createReminderClicked"
        @update-reminder="reminderUpdated()" />

<!--      <message-details-modal-->
<!--        v-if="selectedMail"-->
<!--        :ref="'mail-detail-modal'"-->
<!--        :key="selectedMail.objectID"-->
<!--        :email="selectedMail"-->
<!--        @reply-mail="replyMail"-->
<!--        @reply-all-mail="replyAllMail"-->
<!--        @forward-mail="forwardMail"-->
<!--        @forward-as-attachement="forwardAsAttachement" />-->
    </div>
  </section>
</template>

<script>

import ObjectApi from '@/misc/apis/ObjectApi'
import ContactApi from '@/misc/apis/ContactApi'
import MessageApi from '@/misc/apis/MessageApi'
import CalendarApi from '@/misc/apis/CalendarApi'
import EmployeeNameText from '../../framework/components/misc/EmployeeNameText'
import ReminderCreationModal from './ReminderCreationModal'
import ReminderDetailsModal from './ReminderDetailsModal'
import CoozzyFormSelect from '../../framework/components/form/select/CoozzyFormSelect'
import CoozzyAssigneeMultiselect from '../../framework/components/multiselect/CoozzyAssigneeMultiselect'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import CoozzyDropdown from '../../framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '../../framework/components/dropdown/CoozzyDropdownItem'
import CoozzyDropdownSpaceDivider from '../../framework/components/dropdown/CoozzyDropdownSpaceDivider'
import CoozzyFormCheckbox from '../../framework/components/form/checkbox/CoozzyFormCheckbox'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import { tables } from '@/mixins/tables'
import { contact } from '@/mixins/contact'
import { mail } from '@/mixins/mail'
import { reminder } from '@/mixins/reminder'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import RequestApi from '@/marketingBasic/api/RequestApi'
import { routeChecks } from '@/mixins/routeChecks'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'

export default {
  name: 'RemindersView',
  components: {
    CoozzyFormInput,
    CoozzyPageTitle,
    CoozzySpinner,
    CoozzyFormCheckbox,
    ReminderCreationModal,
    ReminderDetailsModal,
    CoozzyDropdownSpaceDivider,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyButton,
    CoozzyAssigneeMultiselect,
    CoozzyFormSelect,
    EmployeeNameText
  },
  mixins: [routeChecks, tables, mail, reminder, contact],
  metaInfo() {
    return {
      title: this.$t('message.navigation.reminder')
    }
  },
  data() {
    return {
      selectedMail: null,
      selectedEntries: [],
      searchEmployee: [],
      reminderContacts: [],
      listReferences: [],
      searchPriority: null,
      nbrPerPage: 50,
      reminders: [],
      currentPage: 1,
      isBusy: false,
      reminderEmployees: null,
      reminderPriority: null,
      reminderDate: '',
      reminderTime: '',
      reminderTimestamp: Math.trunc(Date.now() / 1000),
      changeType: null,
      selectedReminder: null,
      remainderForEdit: null,
      isReady: false,
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'date',
          label: this.$t('message.calendar.reminder.date'),
          tdClass: 'align-middle'
        },
        {
          key: 'priority',
          label: this.$t('message.calendar.reminder.priority.title'),
          tdClass: 'align-middle'
        },
        {
          key: 'activity',
          label: this.$t('message.calendar.reminder.activity.title'),
          tdClass: 'align-middle'
        },
        {
          key: 'subject',
          label: this.$t('message.calendar.reminder.subject'),
          tdClass: 'align-middle'
        },
        {
          key: 'reference',
          label: this.$t('message.calendar.reminder.reference'),
          tdClass: 'align-middle'
        },
        {
          key: 'contact',
          label: this.$t('message.calendar.reminder.contact'),
          tdClass: 'align-middle'
        },
        {
          key: 'employee',
          label: this.$t('message.calendar.reminder.employee'),
          tdClass: 'align-middle'
        },
        {
          key: 'creator',
          label: this.$t('message.ticketOverview.ticket.creator'),
          tdClass: 'align-middle'
        }
      ],
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: [],
      timeList: []
    }
  },
  computed: {
    nbrTotalOfHits() {
      return this.reminders.length
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.allEntries.map(x => x.id) : this.selectedEntries.map(x => x.id)
    },
    employeeList() {
      if (this.getEmployees().length > 0) {
        return this.getEmployees()
      }
      return []
    },
    activeEmployeeList() {
      return this.employeeList.filter(x => !x.blocked && !x.deleted)
    },
    availableEmployees() {
      const array = []
      if (this.employeeList.length !== 0) {
        this.employeeList.forEach((emp) => {
          if (typeof (emp) !== 'undefined') {
            array.push({
              label: (emp && emp.profile && emp.profile.firstName ? emp.profile.firstName.substr(0, 1) + '. ' : '') + emp.profile.lastName + (emp.deleted ? ` (${this.$t('message.generic.deleted')})` : ''),
              value: emp.id,
              active: !emp.blocked
            })
          }
        })
      }
      return [{
        employee: this.$t('message.search.filterAll'),
        employees: array.sort(function(a, b) {
          if (a.active === false && b.active !== false) {
            return 1
          }
          if (b.active === false && a.active !== false) {
            return -1
          }
          if (a.label.toLowerCase() < b.label.toLowerCase()) { return -1 }
          if (a.label.toLowerCase() > b.label.toLowerCase()) { return 1 }
          return 0
        })
      }]
    },
    isFiltered() {
      return this.searchEmployee.length > 0 || this.searchPriority !== null
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    }
  },
  watch: {
    currentPage: function () {
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    nbrPerPage: function () {
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    reminderDate: function (val) {
      if (val !== '' && this.reminderTime !== '') {
        const date = val + ' ' + this.reminderTime
        this.reminderTimestamp = Date.parse(date) / 1000
      } else {
        this.reminderTimestamp = Math.trunc(Date.now() / 1000)
      }
    },
    reminderTime: function (val) {
      if (val !== '' && this.reminderDate !== '') {
        const date = this.reminderDate + ' ' + val
        this.reminderTimestamp = Date.parse(date) / 1000
      } else {
        this.reminderTimestamp = Math.trunc(Date.now() / 1000)
      }
    },
    searchPriority: function () {
      this.getFilteredReminder()
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    searchEmployee: function () {
      this.getFilteredReminder()
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        this.allEntries = this.reminders
      }
    }
  },
  mounted: function () {
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'show-reminder-detail') {
        if (this.$refs.remindersTable) {
          this.$refs.remindersTable.clearSelected()
        }
      }
      if (modalId === 'changeModal') {
        this.reminderTime = ''
        this.reminderDate = ''
      }
    })
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'changeModal') {
        if (this.$refs.changeModal) {
          const selectedEntriesDate = this.selectedEntries.length === 1 ? this.selectedEntries[0].date : null
          const date = selectedEntriesDate ? new Date(selectedEntriesDate * 1000) : new Date()
          let minutes = Math.ceil(date.getMinutes() / 15) * 15
          minutes = (minutes === 0 || minutes === 60 ? '00' : minutes)
          const hours = date.getMinutes() > 45 ? date.getHours() + 1 : date.getHours()
          this.reminderTime = `${hours.toString().padStart(2, '0')}:${minutes}`
          this.reminderDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
        }
      }
    })
    this.loadEmployees()
    this.isReady = true
    const profile = this.$store.getters['user/getCurrentUser']
    this.searchEmployee.push({
      label: profile['https://coozzy.ch/firstName'].substr(0, 1) + '. ' + profile['https://coozzy.ch/lastName'],
      value: this.$store.getters['user/getUserId']
    })
    this.getListTimes()
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailHasContext',
      'setSubject',
      'setEmailToForwardAsAttachment'
    ]),
    getListTimes() {
      let hours, minutes
      const list = []
      for (let i = 0; i <= 1425; i += 15) {
        hours = Math.floor(i / 60)
        minutes = i % 60
        if (minutes < 10) {
          minutes = '0' + minutes // adding leading zero
        }
        if (hours < 10) {
          hours = '0' + hours // adding leading zero
        }
        if (hours === 24) {
          hours = '00'
        }
        list.push(hours + ':' + minutes)
      }
      this.timeList = list
    },
    showConfirmationModal() {
      const markAsDone = this.reminders.filter(x => this.selectedEntriesIds.includes(x.id)).filter(x => x.done === '0')
      const markAsUndone = this.reminders.filter(x => this.selectedEntriesIds.includes(x.id)).filter(x => x.done !== '0')
      if (markAsDone.length > 0) {
        this.$refs.confirmationModalReminder.show()
      } else if (markAsUndone.length > 0) {
        this.updateDoneStatus()
      }
    },
    // replyMail(email) {
    //   this.prepareReplyMail(email)
    // },
    // replyAllMail(email) {
    //   this.prepareReplyAll(email)
    // },
    // forwardMail(email) {
    //   this.prepareForwardMail(email)
    // },
    // forwardAsAttachement(mediaList, email) {
    //   this.setSubject('Fwd: ' + email.subject)
    //   this.setSendMailHasContext(true)
    //   this.setSendMailContacts([])
    //   this.setEmailToForwardAsAttachment(mediaList)
    //   this.$nextTick(function () {
    //     if (this.getMailboxesForUserId(this.userId).length === 0) {
    //       this.$nextTick(function () {
    //         Vue.toasted.show(this.$t('message.settings.mailbox.configMailboxToast'), { type: 'error' })
    //       })
    //     } else {
    //       this.callShowSendMailModal()
    //     }
    //   })
    // },
    showDetailMail(mail) {
      this.selectedMail = null
      ContactApi.contactResolve(mail.referenceIds)
        .then(response => {
          this.selectedMail = mail
          this.selectedMail.referencesContact = response.persons.concat(response.companies).map(
            x => {
              return {
                contactId: x.id,
                email: x.email,
                name: x.name
              }
            }
          )
          this.setMailDetailMailObject(mail)
          this.$nextTick(function () {
            this.callShowDetailMailModal()
          })
        })
    },
    getArrayValues (value) {
      if (Array.isArray(value)) {
        return value
      } else {
        return [value]
      }
    },
    initFilter() {
      this.searchEmployee = []
      this.searchPriority = null
      this.getFilteredReminder()
    },
    onRowClicked(item, index, event) {
      if (event.srcElement.classList.contains('custom-checkbox') || event.srcElement.classList.contains('checkbox-cell')) {
        this.itemSelected(item, !document.getElementById('check_' + item.id).checked)
        document.getElementById('check_' + item.id).checked = !document.getElementById('check_' + item.id).checked
      } else {
        this.showDetail(item)
      }
    },
    redirectToDetailPage(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerContactDetailsView',
            params: { id: contactId }
          })
        } else if (this.isMarketingModule) {
          this.$router.push({
            name: 'MarketingContactDetailsView',
            params: { id: contactId }
          })
        } else {
          this.$router.push({
            name: 'AdminContactDetailsView',
            params: { id: contactId }
          })
        }
      }
    },
    createReminderClicked(reminder = null) {
      if (reminder !== null) {
        this.remainderForEdit = reminder
      } else {
        this.remainderForEdit = null
      }
      this.$nextTick(function () {
        this.$refs['modal-creation']?.show()
      })
    },
    dateToTimestamp() {
      this.reminderTimestamp = this.toTimestamp(this.reminderDate)
    },
    getContactName(contactId) {
      if (!contactId) {
        return '-'
      }

      const contact = (this.reminderContacts || []).find(function (contact) {
        return contact.id === contactId
      })
      return contact ? contact.name : '-'
    },
    reminderUpdated() {
      this.getFilteredReminder()
      this.getCountOverdueReminders()
    },
    getFilteredReminder() {
      this.isBusy = true
      this.selectedReminder = null
      this.selectedEntries = []
      this.listReferences = []
      let listReference = []
      const listEmployees = this.searchEmployee.map(v => v.value)
      CalendarApi.getReminders(this.$store.getters['user/getAccountId'], listEmployees, this.searchPriority)
        .then(response => {
          this.reminders = response.reminders
          const listProperties = []
          const listBuildings = []
          const listObjects = []
          const listTenancies = []
          const listCondominium = []
          const listRequests = []
          const listContacts = []
          const listMails = []

          // Extract all referenceIds
          for (const referenceIds of this.reminders.map(reminder => reminder.referenceIds)) {
            if (referenceIds.length > 0) {
              for (let index = 0; index < referenceIds.length; index++) {
                switch (referenceIds[index].split('_')[0]) {
                  case 'prop':
                    if (!listProperties.includes(referenceIds[index])) {
                      listProperties.push(referenceIds[index])
                    }
                    break
                  case 'building':
                    if (!listBuildings.includes(referenceIds[index])) {
                      listBuildings.push(referenceIds[index])
                    }
                    break
                  case 'obj':
                    if (!listObjects.includes(referenceIds)) {
                      listObjects.push(referenceIds[index])
                    }
                    break
                  case 'tenancy':
                    if (!listTenancies.includes(referenceIds[index])) {
                      listTenancies.push(referenceIds[index])
                    }
                    break
                  case 'condominium':
                    if (!listCondominium.includes(referenceIds[index])) {
                      listCondominium.push(referenceIds[index])
                    }
                    break
                  case 'request':
                    if (!listRequests.includes(referenceIds[index])) {
                      listRequests.push(referenceIds[index])
                    }
                    break
                  case 'contact':
                    if (!listContacts.includes(referenceIds[index])) {
                      listContacts.push(referenceIds[index])
                    }
                    break
                  case 'mail':
                    if (!listMails.includes(referenceIds[index])) {
                      listMails.push(referenceIds[index])
                    }
                    break
                  default:
                    break
                }
              }
            }
          }

          // Store all referenceIds in an object with ID + null value that will be filled by the requests below
          listReference = listReference
            .concat(listProperties)
            .concat(listBuildings)
            .concat(listObjects)
            .concat(listTenancies)
            .concat(listRequests)
            .concat(listContacts)
            .concat(listMails)
            .concat(listCondominium)
          listReference.forEach(element => {
            this.listReferences.push({
              entity: element,
              value: null,
              deleted: false
            })
          })

          this.loadProperties(listProperties)
          this.loadBuildings(listBuildings)
          this.loadObjects(listObjects)
          this.loadTenancies(listTenancies)
          this.loadCondominium(listCondominium)
          this.loadRequests(listRequests)
          this.loadContacts(listContacts)
          this.loadMails(listMails)

          if (this.reminders.length > 0) {
            ContactApi.contactResolve([...new Set(response.reminders.map(reminder => reminder.contactId).filter((val) => val !== ''))])
              .then(response => {
                this.reminderContacts = response.persons.concat(response.companies).concat(response.companies.map(c => c.contactPersons).flat())
                this.isBusy = false
              })
              .catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
              })
          } else {
            this.isBusy = false
          }
        })
        .catch(e => {
          console.log(e)
          this.isBusy = false
          Vue.toasted.show(this.$t('message.loadingErrors.reminders'), { type: 'error' })
        }).finally(() => {
          if (this.$refs.remindersTable) {
            this.$refs.remindersTable.refresh()
          }
        })
    },
    setReferenceValue(value) {
      const index = this.listReferences.findIndex(reference => reference.entity === value.id)
      if (index > -1) {
        this.listReferences[index].value = value
      }
    },
    setEmptyReferenceValuesToDeleted(prefix) {
      for (const reference of this.listReferences) {
        if (reference.entity.startsWith(prefix) && reference.value === null) {
          reference.deleted = true
        }
      }
    },
    loadProperties(propertyIds) {
      if (propertyIds.length > 0) {
        ObjectApi.getPropertyListByIds([...new Set(propertyIds)])
          .then(response => {
            for (const property of response.properties) {
              this.setReferenceValue(property)
            }

            this.setEmptyReferenceValuesToDeleted('prop_')
          })
      }
    },
    loadBuildings(buildingIds) {
      if (buildingIds.length > 0) {
        ObjectApi.getBuildingsListByIds([...new Set(buildingIds)])
          .then(response => {
            for (const building of response.buildings) {
              this.setReferenceValue(building)
            }

            this.setEmptyReferenceValuesToDeleted('building_')
          })
      }
    },
    loadObjects(objectIds) {
      if (objectIds.length > 0) {
        ObjectApi.listObjectsByIds([...new Set(objectIds)])
          .then(response => {
            for (const object of response.objects) {
              this.setReferenceValue(object)
            }

            this.setEmptyReferenceValuesToDeleted('obj_')
          })
      }
    },
    loadTenancies(tenancyIds) {
      if (tenancyIds.length > 0) {
        ObjectApi.getTenanciesByIds([...new Set(tenancyIds)])
          .then(tenancyResponse => {
            if (tenancyResponse.tenancies.length > 0) {
              let listContact = []
              tenancyResponse.tenancies.forEach(element => {
                listContact = listContact.concat(element.tenant.contactIds)
              })
              ContactApi.contactResolve([...new Set(listContact)])
                .then(contactResponse => {
                  let contactsList = contactResponse.persons
                    .concat(contactResponse.companies)
                    .filter(contact => listContact.includes(contact.id))
                  ObjectApi.listObjectsByIds([...new Set(tenancyResponse.tenancies.map(tenancy => tenancy.objectId))])
                    .then(objectResponse => {
                      for (const tenancy of tenancyResponse.tenancies) {
                        const contactItem = contactsList.filter(y => y.tenancyIds.includes(tenancy.id))
                        const index = this.listReferences.findIndex(reference => tenancy.id === reference.entity)
                        if (index > -1) {
                          let object = {}
                          object = objectResponse.objects.find(obj => obj.id === tenancy.objectId)
                          contactItem.object = object
                          this.listReferences[index].value = contactItem
                        }
                      }
                      this.setEmptyReferenceValuesToDeleted('tenancy_')
                    })
                })
            } else {
              this.setEmptyReferenceValuesToDeleted('tenancy_')
            }
          })
      }
    },
    loadCondominium(condominiums) {
      if (condominiums.length > 0) {
        ObjectApi.getCondominiumByIds([...new Set(condominiums)])
          .then(condominiumResponse => {
            if (condominiumResponse.condominiums.length > 0) {
              let listContact = []
              condominiumResponse.condominiums.forEach(element => {
                listContact = listContact.concat(element.owner.contactIds)
              })
              ContactApi.contactResolve([...new Set(listContact.filter(x => x !== ''))])
                .then(contactResponse => {
                  const contactsList = contactResponse.persons
                    .concat(contactResponse.companies)
                    .filter(contact => listContact.includes(contact.id))
                  ObjectApi.listObjectsByIds([...new Set(condominiumResponse.condominiums.map(condominium => condominium.objectId))])
                    .then(objectResponse => {
                      for (const condominium of condominiumResponse.condominiums) {
                        condominium.owner.contactIds.forEach(element => {
                          const contactItem = contactsList.find(y => y.id === element)
                          const index = this.listReferences.findIndex(reference => condominium.id === reference.entity)
                          if (index > -1) {
                            contactItem.object = objectResponse.objects.find(obj => obj.id === condominium.objectId) || {}
                            if (this.listReferences[index].value) {
                              this.listReferences[index].value = this.listReferences[index].value.concat(contactItem)
                            } else {
                              this.listReferences[index].value = [contactItem]
                            }
                          }
                        })
                      }
                      this.setEmptyReferenceValuesToDeleted('condominium_')
                    })
                })
            } else {
              this.setEmptyReferenceValuesToDeleted('condominium_')
            }
          })
      }
    },
    loadRequests(requestIds) {
      if (requestIds.length > 0) {
        RequestApi.listByIds(requestIds)
          .then(requestResponse => {
            if (requestResponse.requests.length > 0) {
              ObjectApi.listObjectsByIds([...new Set(requestResponse.requests.map(request => request.objectId))])
                .then(objectResponse => {
                  for (const request of requestResponse.requests) {
                    const object = objectResponse.objects.find(obj => obj.id === request.objectId)
                    if (object) {
                      const index = this.listReferences.findIndex(reference => reference.entity === request.id)
                      if (index > -1) {
                        this.listReferences[index].value = object
                      }
                    }
                  }

                  this.setEmptyReferenceValuesToDeleted('request_')
                })
            } else {
              this.setEmptyReferenceValuesToDeleted('request_')
            }
          })
      }
    },
    loadContacts(contactIds) {
      if (contactIds.length > 0) {
        ContactApi.contactResolve([...new Set(contactIds)])
          .then(response => {
            const contactsLoaded = response.persons.concat(response.companies).concat(response.companies.map(c => c.contactPersons).flat())
            for (const contact of contactsLoaded) {
              this.setReferenceValue(contact)
            }
          })
      }
    },
    loadMails(mailIds) {
      if (mailIds.length > 0) {
        MessageApi.listMailsByIds([...new Set(mailIds)])
          .then(response => {
            ContactApi.contactResolve(
              response.mails.map(mail => mail.referenceIds).flat().filter(id => id.includes('contact_'))
            ).then(response => {
              this.usedContacts = response.persons.concat(response.companies)
              this.setContacts(this.usedContacts)
            }).catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
            }).finally(() => {
              for (const mail of response.mails) {
                this.setReferenceValue(mail)
              }
            })
          })
      }
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs.remindersTable) {
        const allCheckboxesComponents = this.$refs.remindersTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
        this.selectAllVisibleEntries = value
        if (value === false) {
          this.selectAllEntries = false
        }
      }
    },
    itemSelected(item, checked) {
      if (checked && !this.selectedEntriesIds.includes(item.id)) {
        this.selectedEntries.push(item)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.id !== item.id)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    updateDoneStatus() {
      this.isBusy = true
      const markAsDone = this.reminders.filter(x => this.selectedEntriesIds.includes(x.id)).filter(x => x.done === '0').map(x => x.id)
      const markAsUndone = this.reminders.filter(x => this.selectedEntriesIds.includes(x.id)).filter(x => x.done !== '0').map(x => x.id)
      const promises = []
      if (markAsDone.length > 0) {
        promises.push(CalendarApi.setDone(markAsDone))
      }
      if (markAsUndone.length > 0) {
        promises.push(CalendarApi.setUnDone(markAsUndone))
      }
      Promise.all(promises)
        .then(() => {
            this.handleCheckboxesInsideTable(false)
            this.$refs.header_checkbox.checked = false
            this.getFilteredReminder()
            this.getCountOverdueReminders()
            this.$refs.confirmationModalReminder?.hide()
            this.isBusy = false
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.updateReminder'), { type: 'error' })
        })
        .finally(() => {
          this.isBusy = false
        })
    },
    openChangeModal(type) {
      this.changeType = type
      if (this.selectedEntries.length === 1 && this.changeType === 'priority') {
        this.reminderPriority = this.selectedEntries[0].priority
      } else {
        this.reminderPriority = null
      }
      this.$refs.changeModal?.show()
    },
    showDetail(item) {
      this.selectedReminder = item
      this.$nextTick(function () {
        this.$refs['detail-reminder-modal']?.show()
      })
    },
    getReference(referenceId) {
      return this.listReferences.find(element => element.entity === referenceId)
    },
    saveChangeModal() {
      this.isBusy = true
      this.selectedReminder = null
      this.remainderForEdit = null
      if (this.changeType === 'date') {
        this.$v.$touch()
        if (!this.$v.$invalid) {
          CalendarApi.updateDate(this.selectedEntriesIds, this.reminderTimestamp)
            .then(() => {
              this.handleCheckboxesInsideTable(false)
              this.$refs.header_checkbox.checked = false
              this.getFilteredReminder()
              this.isBusy = false
              this.$refs.changeModal.hide()
            })
            .catch(e => {
              this.isBusy = false
              console.log(e)
              Vue.toasted.show(this.$t('message.savingErrors.updateReminder'), { type: 'error' })
            })
        }
      } else if (this.changeType === 'priority') {
        CalendarApi.updatePriority(this.selectedEntriesIds, this.reminderPriority)
          .then(() => {
            this.getFilteredReminder()
            this.isBusy = false
            this.handleCheckboxesInsideTable(false)
            this.$refs.header_checkbox.checked = false
            this.$refs.changeModal.hide()
          })
          .catch(e => {
            this.isBusy = false
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.updateReminder'), { type: 'error' })
          })
      } else {
        CalendarApi.updateEmployee(this.selectedEntriesIds, this.reminderEmployees)
          .then(() => {
            this.getFilteredReminder()
            this.isBusy = false
            this.handleCheckboxesInsideTable(false)
            this.$refs.header_checkbox.checked = false
            this.$refs.changeModal.hide()
          })
          .catch(e => {
            this.isBusy = false
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.updateReminder'), { type: 'error' })
          })
      }
      this.$nextTick(() => {
        this.getCountOverdueReminders()
      })
    },
    getObjectRefDisplayName(obj) {
      if (obj.name !== '' && obj.number !== '') {
        return obj.number + ' - ' + obj.name
      } else if (obj.name === '' && obj.number !== '') {
        return obj.number
      } else if (obj.number === '' && obj.name !== '') {
        return obj.name
      } else {
        return 'n/a'
      }
    },
    getRowClass(item) {
      return item?.done !== '0' ? 'dark-row' : ''
    }
  },
  validations: {
    reminderDate: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.red-border .mx-datepicker) {
  border: 1px solid $color-red !important;
}
.message-link {
  color: #20a8d8 !important;
  &:hover {
    color: #0055b7 !important;
    text-decoration: underline !important;
    cursor: pointer;
  }
}
:deep(.multiselect) {
  width: fit-content;
  min-width: 22%;

  .multiselect__tags {
    width: fit-content;
    min-width: 100%;
  }
}
:deep(tr.dark-row:nth-child(odd) td) {
  background-color: #33333312;
  color: #fff;
}
.text-red {
  color: $color-red-dark;
}

.text-gray {
  color: $color-text-grey-light !important;
}
</style>
