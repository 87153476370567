<template>
  <section>
    <coozzy-card class="global-card no-top-border table-card">
      <div class="row justify-content-between pb-1">
        <div class="col pl-0">
          <h5 class="ml-3 pt-1">
            {{ devicesCount }} {{ $t('message.onBoarding.devices.title') }}
          </h5>
        </div>
        <div
          v-if="isFiltered"
          class="col-12 col-md-2">
          <coozzy-button
            class="w-100 sort"
            @click="initFilter()">
            <coozzy-filter-icon />
            {{ $t('message.generic.clearFilters') }}
          </coozzy-button>
        </div>
        <div
          v-if="isEditor"
          class="col-12 col-md-2">
          <coozzy-button
            class="w-100"
            design="prop-green"
            @click="readOnly ? goToEditDevice() : $bvModal.show('createDeviceModal')">
            {{ $t('message.onBoarding.devices.add') }}
          </coozzy-button>
        </div>
      </div>
      <div class="row align-items-center my-2">
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-form-input
            ref="searchText"
            v-model="searchText"
            :placeholder="$t('message.generic.search')"
            @keyup="searchInputText()" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchCategories"
            :clear-on-select="false"
            :close-on-select="false"
            :custom-label="customLabelCategory"
            :multiple="true"
            :options="categoryFilter"
            :placeholder="$t('message.onBoarding.devices.filter.category')"
            :preselect-first="false"
            :preserve-search="true"
            :taggable="false"
            :track-by="'value'"
            class="filter-inputs"
            @remove="filterDevicesList"
            @select="filterDevicesList" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchInstallation"
            :clear-on-select="true"
            :close-on-select="true"
            :custom-label="customLabelInstallation"
            :multiple="false"
            :options="installationData"
            :placeholder="$t('message.onBoarding.devices.filter.deviceStatus')"
            :preselect-first="false"
            :preserve-search="true"
            :taggable="false"
            :track-by="'label'"
            class="filter-inputs"
            @remove="filterDevicesList"
            @select="filterDevicesList" />
        </div>
      </div>
      <div class="row align-items-center">
        <div
          class="col-md-2">
          <coozzy-dropdown
            :text="selectedEntriesText"
            class="w-100 sort"
            design="green"
            size="sm">
            <coozzy-dropdown-item
              v-if="isTicketingEnabled && (isEditor || isTicketEditor || isTicketCreator) && (isOwnerModule || isAssetModule)"
              :disabled="selectedEntriesGlobal.length === 0"
              @click="createTicket">
              {{ $t('message.ticketOverview.createTicket') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown
              v-if="!isOwnerModule && !isAssetModule && isTicketingEnabled && (isEditor || isTicketEditor || isTicketCreator)"
              :class="selectedEntriesGlobal.length > 0 ? 'enabled' : ''"
              :text="$t('message.ticketOverview.createTicket')"
              class="w-100 ticket-dropdown-bulk-action"
              design="transparent"
              dropright
              size="sm"
              :disabled="true"
              @click.prevent>
              <coozzy-dropdown-item
                :disabled="selectedEntriesGlobal.length === 0"
                @click="navigateToCreateOrder()">
                {{ $t('message.ticketOverview.createOrder') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                :disabled="selectedEntriesGlobal.length === 0"
                @click="createTicket">
                {{ $t('message.ticketOverview.createTask') }}
              </coozzy-dropdown-item>
            </coozzy-dropdown>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2 col-sm-6 col-12">
          <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              :initial="selectAllEntries"
              button
              name="selectAllEntries">
              {{
                selectAllEntries ? $t('message.generic.unselectHiddenEntries', {amount: nbrTotalOfHits}) : $t('message.generic.selectHiddenEntries', {amount: nbrTotalOfHits})
              }}
            </coozzy-form-checkbox>
          </template>
        </div>
        <div
          v-if="isCompanyAdmin === true"
          class="offset-md-5 col-md-1 d-flex justify-content-end align-items-center">
          <div
            @click="openAssembleListModal()">
            <coozzy-filters-icon
              v-b-tooltip="{container: '#rowConfig', title: $t('message.generic.tooltipAssembleList')}" />
          </div>
        </div>
        <div
          :class="isCompanyAdmin !== true ? 'offset-md-8' : ''"
          class="col-md-2 col-sm-6 col-12 mt-2 mt-sm-0">
          <coozzy-form-select
            v-model="nbrPerPage"
            class="select-entries float-right"
            name="numberEntries">
            <option value="5">
              5 {{ $t('message.generic.entries') }}
            </option>
            <option value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option value="15">
              15 {{ $t('message.generic.entries') }}
            </option>
            <option value="20">
              20 {{ $t('message.generic.entries') }}
            </option>
            <option value="all">
              {{ $t('message.manageInterestedParties.filter.allEntries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <div
        v-if="isDeviceLoading"
        class="text-center">
        <coozzy-spinner />
      </div>
      <b-table
        v-else
        ref="deviceTable"
        :busy="isDeviceLoading"
        :fields="fields"
        :items="deviceList"
        :table-class="elementSelected !== null && elementSelected !== '' ? 'b-table-selecting' : ''"
        bordered
        class="overflow-auto shadow"
        hover
        responsive="true"
        select-mode="single"
        selectable
        stacked="md"
        @row-clicked="changeSelectedDevice">
        <template
          #head(checkbox)>
          <coozzy-form-checkbox
            ref="header_checkbox"
            @change="(value) => { handleCheckboxesInsideTable(value) }" />
        </template>
        <template #head(description)>
          {{ $t('message.tableColumnHeadings.description') }}
        </template>
        <template #head(componentsDevices)>
          <coozzy-sort-dropdown
            ref="type_sort"
            :text="$t('message.onBoarding.devices.type')"
            initial-sorting="desc"
            sorting-field="type"
            @updateSorting="updateSorting" />
        </template>
        <template #head(brand)>
          {{ $t('message.tableColumnHeadings.brand') }}
        </template>
        <template #head(model)>
          {{ $t('message.tableColumnHeadings.model') }}
        </template>
        <template #head(acquisitionCosts)>
          <coozzy-sort-dropdown
            ref="acquisitionCosts_sort"
            :text="$t('message.tableColumnHeadings.acquisitionCosts')"
            initial-sorting="desc"
            sorting-field="acquisitionCosts"
            @updateSorting="updateSorting" />
        </template>
        <template #head(installation)>
          <coozzy-sort-dropdown
            ref="installation_sort"
            :text="$t('message.tableColumnHeadings.installation')"
            initial-sorting="desc"
            sorting-field="installation"
            @updateSorting="updateSorting" />
        </template>
        <template #head(uninstallation)>
          <coozzy-sort-dropdown
            ref="uninstallation_sort"
            :text="$t('message.tableColumnHeadings.uninstallation')"
            initial-sorting="desc"
            sorting-field="uninstallation"
            @updateSorting="updateSorting" />
        </template>
        <template #head(warrantyExpiration)>
          <coozzy-sort-dropdown
            ref="warrantyExpiration_sort"
            :text="$t('message.tableColumnHeadings.warrantyExpiration')"
            initial-sorting="desc"
            sorting-field="warrantyExpiration"
            @updateSorting="updateSorting" />
        </template>
        <template #head(nextMaintenance)>
          <coozzy-sort-dropdown
            ref="nextMaintenance_sort"
            :text="$t('message.tableColumnHeadings.nextMaintenance')"
            initial-sorting="desc"
            sorting-field="nextMaintenance"
            @updateSorting="updateSorting" />
        </template>
        <template #head(manufacturer)>
          {{ $t('message.tableColumnHeadings.manufacturer') }}
        </template>
        <template #head(maintenanceCosts)>
          <coozzy-sort-dropdown
            ref="maintenanceCosts_sort"
            :text="$t('message.tableColumnHeadings.maintenanceCosts')"
            initial-sorting="desc"
            sorting-field="maintenanceCosts"
            @updateSorting="updateSorting" />
        </template>
        <template #head(serialNumber)>
          {{ $t('message.tableColumnHeadings.serialNumber') }}
        </template>
        <template #head(unit)>
          {{ $t('message.tableColumnHeadings.unit') }}
        </template>
        <template #head(amount)>
          {{ $t('message.tableColumnHeadings.amount') }}
        </template>
        <template #head(lifeSpan)>
          {{ $t('message.tableColumnHeadings.lifeSpan') }}
        </template>
        <template #head(calculatedReplacement)>
          {{ $t('message.tableColumnHeadings.calculatedReplacement') }}
        </template>
        <template #head(task)>
          {{ $t('message.tableColumnHeadings.task') }}
        </template>
        <template #head(order)>
          {{ $t('message.tableColumnHeadings.order') }}
        </template>
        <template #head(tickets)>
          {{ $t('message.tableColumnHeadings.tickets') }}
        </template>
        <!-- Body -->
        <template
          #cell(checkbox)="data">
          <coozzy-form-checkbox
            :id="'check_' + data.item.id"
            @change="(value) => { itemSelectedGlobal(data.item, value) }" />
        </template>
        <template #cell(description)="data">
          <template v-if="data.item.referenceId.includes('prop_')">
            {{ $t('message.propertiesView.property') }}
          </template>
          <template v-if="data.item.referenceId.includes('building_')">
            {{ $t('message.onBoardingTabs.building') }} {{ getBuildingName(data.item.referenceId) }}
          </template>
        </template>
        <template #cell(componentsDevices)="data">
          <template v-if="data.item.type && data.item.type !== 'DEVICE_TYPE_UNDEFINED'">
            {{
              $t('message.onBoarding.devices.typeValues.' + data.item.type).length > 50 ? $t('message.onBoarding.devices.typeValues.' + data.item.type).slice(0, 50) + '...' : $t('message.onBoarding.devices.typeValues.' + data.item.type)
            }}
          </template>
          <template v-else>
            -
          </template>
        </template>
        <template #cell(brand)="data">
          {{ data.item.brand | displayOptionalValue }}
        </template>
        <template #cell(acquisitionCosts)="data">
          {{ data.item.acquisitionCosts | formatPrice }}
        </template>
        <template #cell(model)="data">
          {{ data.item.model | displayOptionalValue }}
        </template>
        <template #cell(installation)="data">
          {{ data.item.installation | objectToDate }}
        </template>
        <template #cell(uninstallation)="data">
          {{ data.item.uninstallation | objectToDate }}
        </template>
        <template #cell(warrantyExpiration)="data">
          {{ data.item.warrantyEnd | objectToDate }}
        </template>
        <template #cell(nextMaintenance)="data">
          {{ data.item.maintenance.nextMaintenance | objectToDate }}
        </template>
        <template #cell(manufacturer)="data">
          {{ data.item.manufacturer }}
        </template>
        <template #cell(serialNumber)="data">
          {{ data.item.serialNumber }}
        </template>
        <template #cell(unit)="data">
          {{ $t('message.onBoarding.devices.unitOptions.' + data.item.unit) }}
        </template>
        <template #cell(amount)="data">
          {{ data.item.amount | displayOptionalValue }}
        </template>
        <template #cell(maintenanceCosts)="data">
          {{ data.item.maintenance.costs.amount | formatPrice }}
        </template>
        <template #cell(lifeSpan)="data">
          {{ data.item.lifeSpan }}
        </template>
        <template #cell(calculatedReplacement)="data">
          {{ fillOutCalculatedReplacement(data.item) | displayOptionalValue }}
        </template>
        <template #cell(order)="data">
          <span
            @click="redirectToActivitiesView(data.item.id, 'TICKET_TYPE_ORDER')"
            @click.middle="redirectMiddleToActivitiesView(data.item.id, 'TICKET_TYPE_ORDER')">
            {{ amountTickets(data.item.id, 'orders') | displayOptionalValue }}
          </span>
        </template>
        <template #cell(task)="data">
          <span
            @click="redirectToActivitiesView(data.item.id, 'TICKET_TYPE_TASK')"
            @click.middle="redirectMiddleToActivitiesView(data.item.id, 'TICKET_TYPE_TASK')">
            {{ amountTickets(data.item.id, 'tasks') | displayOptionalValue }}
          </span>
        </template>
        <template #cell(tickets)="data">
          <span
            @click="redirectToActivitiesView(data.item.id)"
            @click.middle="redirectMiddleToActivitiesView(data.item.id)">
            {{ amountTickets(data.item.id, 'orders') + amountTickets(data.item.id, 'tasks') | displayOptionalValue }}
          </span>
        </template>
      </b-table>

      <b-pagination
        v-if="nbrPerPage !== 'all' && !isDeviceLoading"
        v-model="currentPage"
        :per-page="nbrPerPage"
        :total-rows="listDevices ? listDevices.length : 0"
        align="center"
        class="mt-3 mb-3" />
    </coozzy-card>
    <div
      v-if="selectedDevice && !isDeviceLoading">
      <coozzy-card class="global-card">
        <div class="row">
          <div class="col">
            <h5>
              <span
                v-if="getObjectDetailRoute(selectedDevice)">
                <router-link
                  :to="getObjectDetailRoute(selectedDevice)"
                  @click.native="forceNavigation">
                  {{
                    selectedDevice.referenceId.includes('building') ? $t('message.manageBuilding.building') : selectedDevice.referenceId.includes('obj') ? $t('message.onBoarding.buildings.objects.titleObject') : $t('message.propertiesView.tabs.property')
                  }}
                </router-link>
                - {{ deviceTitle }}
              </span>
              <span v-else>
                {{
                  selectedDevice.referenceId.includes('building') ? $t('message.manageBuilding.building') : selectedDevice.referenceId.includes('obj') ? $t('message.onBoarding.buildings.objects.titleObject') : $t('message.propertiesView.tabs.property')
                }} - {{ deviceTitle }}
              </span>
              <a
                class="lh-1 link-activity "
                tabindex="0"
                @click="openActivities(selectedDevice)"
                @click.middle="openActivitiesMiddle(selectedDevice)">
                <img
                  :src="`/img/ticket/new-window.svg`"
                  alt="type"
                  class="ml-3 mb-1"
                  height="15px">
                {{ $t('message.ticketOverview.activities.title') }}
              </a>
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <device
              :key="`device-${selectedDevice.id || selectedDevice.internalId}`"
              ref="device"
              :add-new-document="selectedDevice.id && deviceIdNewDocument !== '' && deviceIdNewDocument === selectedDevice.id"
              :device="selectedDevice"
              :duplicate-button="false"
              :from-local="true"
              :index="-1"
              :owner-id="property?.ownerId"
              :property-id="property.id"
              :read-only="readOnly"
              :target="selectedDevice.referenceId.includes('building') ? 'building' : selectedDevice.referenceId.includes('object') ? 'object' : 'property'"
              @removeConfirmed="removeDevice()"
              @duplicate-device="duplicateDevice"
              @device-update="updateDevice"
              @selected-device-for-history="openActivities" />
          </div>
        </div>
      </coozzy-card>
    </div>
    <b-modal
      :id="'createDeviceModal'"
      ref="createDeviceModal"
      :title="$t('message.onBoarding.devices.add')"
      hide-footer
      no-close-on-backdrop>
      <template>
        <div class="col p-0">
          <b-table
            id="table-groups"
            ref="table-groups"
            :busy="isBusy"
            :fields="fieldsAddDevice"
            :items="listBuildingWithProperties"
            bordered
            class="mb-4"
            hover
            responsive="true"
            stacked="md">
            <!-- Busy state -->
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>
            <template #head(category)>
              {{ $t('message.advertisement.objectCategory.name') }}
            </template>
            <template #head(name)>
              {{ $t('message.onBoarding.devices.description') }}
            </template>
            <!-- Body -->
            <template #cell(checkbox)="data">
              <coozzy-form-checkbox
                :id="data.item.id"
                :disabled="selectedEntries.length > 0 && !selectedEntries.includes(data.item.id)"
                @change="(value) => { itemSelected(data.item, value) }" />
            </template>
            <template #cell(category)="data">
              <template v-if="data.item.category && data.item.category === 'building'">
                {{ $t('message.generic.building') }}
              </template>
              <template v-else-if="data.item.category && data.item.category === 'property'">
                {{ $t('message.propertiesView.tabs.property') }}
              </template>
              <template v-else-if="data.item.category && data.item.category !== 'UNDEFINED_CATEGORY'">
                {{ $t('message.onBoarding.buildings.objects.category.' + data.item.category.toLowerCase()) }}
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template #cell(name)="data">
              {{ data.item.name }}
            </template>
          </b-table>
        </div>
        <div class="col p-0">
          <coozzy-button
            class="mb-0 border"
            design="transparent"
            size="small"
            @click="$bvModal.hide('createDeviceModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            :disabled="selectedEntries.length < 1"
            class="float-right mb-0"
            design="green"
            size="small"
            @click="createDeviceClicked()">
            {{ $t('message.generic.add') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      ref="modal-confirmation"
      :title="$t('message.generic.duplicate')"
      hide-footer
      no-close-on-backdrop>
      <div class="row mb-2">
        <div class="col">
          <p class="mb-3">
            {{ $t('message.onBoarding.devices.duplicatePopup') }}
          </p>
          <coozzy-form-input
            v-model="duplicateCount"
            :name="$t('message.generic.duplicate')"
            class="mb-3" />
          <coozzy-button
            design="green"
            size="normal"
            @click="duplicateConfirmation">
            {{ $t('message.generic.duplicate') }}
          </coozzy-button>
          <coozzy-button
            class="float-right mr-3"
            design="red"
            size="normal"
            @click="closeModal">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
        </div>
      </div>
    </b-modal>
    <assemble-list-modal
      ref="assembleListModal"
      :default-fields="dataFields"
      :fields="selectedFields"
      modal-id="assembleListModal"
      @save-fields="saveFields" />
  </section>
</template>

<script>
import Device from '@/building/tabs/components/Device'
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import CoozzyFormInput from '../../framework/components/form/input/CoozzyFormInput'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzySortDropdown from '@/framework/components/dropdown/CoozzySortDropdown'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import { tables } from '@/mixins/tables'
import { user } from '@/mixins/user'
import debounce from 'debounce'
import CoozzyFilterIcon from '@/framework/components/icons/CoozzyFilterIcon'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import { routeChecks } from '@/mixins/routeChecks'
import { dateUtils } from '@/mixins/dateUtils'
import ObjectApi from '@/misc/apis/ObjectApi'
import SearchApi from '@/misc/apis/SearchApi'
import Vue from 'vue'
import AssembleListModal from '@/contact/components/AssembleListModal'
import TicketApi from '@/misc/apis/TicketApi'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown.vue'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem.vue'
import CoozzyFiltersIcon from '@/framework/components/icons/CoozzyFiltersIcon.vue'

export default {
  name: 'DevicesTab',
  components: {
    CoozzyFiltersIcon,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyFormCheckbox,
    CoozzySpinner,
    CoozzyFilterIcon,
    CoozzySortDropdown,
    CoozzyFormSelect,
    CoozzyMultiselect,
    CoozzyButton,
    CoozzyFormInput,
    CoozzyCard,
    Device,
    AssembleListModal
  },
  mixins: [dateUtils, tables, routeChecks, user],
  props: {
    property: {
      type: Object,
      default: null
    },
    elementSelected: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    addNewItem: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      listPropertyDevices: [],
      listBuildingDevices: [],
      listBuildings: [],
      removedDevices: [],
      nbrPerPage: 5,
      currentPage: 1,
      duplicateCount: 0,
      selectedDevice: null,
      deviceToDuplicate: null,
      isBusy: false,
      isDeviceLoading: false,
      isListPropertyDeviceLoaded: false,
      isListBuildingDeviceLoaded: false,
      sorting: null,
      sortValue: '',
      sortField: '',
      searchText: '',
      selectedEntries: [],
      selectedEntriesGlobal: [],
      fieldsAddDevice: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'category',
          label: this.$t('message.advertisement.objectCategory.name'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'name',
          label: this.$t('message.onBoarding.devices.description'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        }
      ],
      dataFields: [
        {
          key: 'description',
          label: this.$t('message.tableColumnHeadings.description'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'componentsDevices',
          label: this.$t('message.tableColumnHeadings.componentsDevices'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'brand',
          label: this.$t('message.tableColumnHeadings.brand'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'model',
          label: this.$t('message.tableColumnHeadings.model'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'installation',
          label: this.$t('message.tableColumnHeadings.installation'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'uninstallation',
          label: this.$t('message.tableColumnHeadings.uninstallation'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'warrantyExpiration',
          label: this.$t('message.tableColumnHeadings.warrantyExpiration'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'nextMaintenance',
          label: this.$t('message.tableColumnHeadings.nextMaintenance'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'maintenanceCosts',
          label: this.$t('message.tableColumnHeadings.maintenanceCosts'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'acquisitionCosts',
          label: this.$t('message.tableColumnHeadings.acquisitionCosts'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'manufacturer',
          label: this.$t('message.tableColumnHeadings.manufacturer'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'serialNumber',
          label: this.$t('message.tableColumnHeadings.serialNumber'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'unit',
          label: this.$t('message.tableColumnHeadings.unit'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'amount',
          label: this.$t('message.tableColumnHeadings.amount'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'lifeSpan',
          label: this.$t('message.tableColumnHeadings.lifeSpan'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'calculatedReplacement',
          label: this.$t('message.tableColumnHeadings.calculatedReplacement'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'task',
          label: this.$t('message.tableColumnHeadings.task'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'order',
          label: this.$t('message.tableColumnHeadings.order'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'tickets',
          label: this.$t('message.tableColumnHeadings.tickets'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        }
      ],
      tickets: [],
      searchFloor: [],
      searchRooms: [],
      searchAssignee: [],
      searchCategories: [],
      searchInstallation: null,
      listDevices: [],
      selectedFields: [],
      deviceIdNewDocument: '',
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: [],
      debouncedFilterDevicesList: null
    }
  },
  computed: {
    nbrTotalOfHits() {
      return this.listDevices.length
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntriesGlobal.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    selectedEntriesGlobalIds() {
      return this.selectAllEntries ? this.allEntries.map(x => x.id) : this.selectedEntriesGlobal.map(x => x.id)
    },
    devicesCount() {
      return this.listDevices !== null ? this.listDevices.length : 0
    },
    isFiltered() {
      return this.searchText !== '' || this.searchInstallation !== null || this.searchCategories.length > 0 || this.searchRooms.length > 0 || this.searchFloor.length > 0 || this.searchAssignee.length > 0
    },
    isBuildingCategory() {
      return this.searchCategories.map(x => x.value).includes('building')
    },
    listBuildingWithProperties() {
      const list = []
      const property = this.property
      if (property) {
        list.push({ name: property.name, type: 'property', id: property.id, category: 'property' })
      }
      this.listBuildings.forEach(element => {
        list.push({ name: element.name, type: 'building', id: element.id, category: 'building' })
      })
      return list
    },
    installationData() {
      return [
        { label: 'installed', value: this.$t('message.onBoarding.devices.filter.installed') },
        { label: 'uninstalled', value: this.$t('message.onBoarding.devices.filter.uninstalled') }
      ]
    },
    categoryFilter() {
      const counts = []
      const current = this
      counts.push({
        count: current.alldevice.filter(x => x.referenceId.includes('building_')).length,
        value: 'building'
      })
      counts.push({
        count: current.alldevice.filter(x => x.referenceId.includes('prop_')).length,
        value: 'property'
      })
      return counts
    },
    alldevice() {
      this.$emit('all-devices', this.listBuildingDevices.concat(this.listPropertyDevices))
      return this.listBuildingDevices.concat(this.listPropertyDevices)
    },
    deviceList: {
      get() {
        let list = []
        if (this.listDevices && this.listDevices.length > 0) {
          list = this.listDevices
        }
        if (this.nbrPerPage !== 'all') {
          list = list.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
        }
        return list
      },
      set(value) {
        this.listDevices = value
      }
    },
    deviceTitle() {
      let str = ''

      if (this.selectedDevice.type && this.selectedDevice.type !== 'DEVICE_TYPE_UNDEFINED') {
        str = this.$t('message.onBoarding.devices.typeValues.' + this.selectedDevice.type)
      }
      return str
    }
  },
  watch: {
    currentPage: function () {
      this.selectedEntries = []
      this.selectedEntriesGlobal = []
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    alldevice: function (newVal) {
      if (newVal.length > 0) {
        this.filterDevicesList()
      }
    },
    nbrPerPage: function (newVal) {
      localStorage.setItem('createDevicesTab-nbrPerPage', newVal)
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        this.allEntries = this.listDevices
      }
    }
  },
  created() {
    // Initialize the debounced function in the created lifecycle hook
    this.debouncedFilterDevicesList = debounce(() => {
      this.filterDevicesList()
    }, 300)
  },
  async mounted() {
    if (localStorage.getItem('deviceIdNewDocument')) {
      this.deviceIdNewDocument = localStorage.getItem('deviceIdNewDocument')
    }
    this.$watch(vm => [vm.isListBuildingDeviceLoaded, vm.isListPropertyDeviceLoaded], val => {
      if (val[0] && val[1]) {
        this.loadAmountTickets()
      }
    }, {
      immediate: true, // run immediately
      deep: true // detects changes inside objects. not needed here, but maybe in other cases
    })
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'createDeviceModal') {
        if (this.$route.query && this.$route.query.addNewItem) {
          const query = Object.assign({}, this.$route.query)
          delete query.addNewItem
          this.$router.replace({ query })
        }
      }
    })
    this.nbrPerPage = localStorage.getItem('createDevicesTab-nbrPerPage') ? localStorage.getItem('createDevicesTab-nbrPerPage') === 'all' ? 'all' : parseInt(localStorage.getItem('createDevicesTab-nbrPerPage')) : 5
    this.searchInstallation = { label: 'installed', value: 'Installed' }
    this.loadDevices()
    this.$nextTick(function () {
      this.$refs.searchText?.setFocusInput()
      if (this.elementSelected || (this.addNewItem && this.readOnly)) {
        setTimeout(() => {
          const selectedDevice = this.elementSelected ? this.elementSelected : this.deviceList[this.deviceList.length - 1].id
          this.changeSelectedDevice(this.listDevices.find(dev => dev.id === selectedDevice))
          const indexOfDevice = this.listDevices.map(function (e) {
            return e.id
          }).indexOf(selectedDevice)
          if (indexOfDevice !== -1) {
            this.currentPage = (Math.floor((indexOfDevice) / this.nbrPerPage)) + 1
            this.listDevices[indexOfDevice]._rowVariant = 'b-table-row-selected table-active'
          }
        }, 1000)
      }
      if (this.addNewItem && !this.readOnly) {
        setTimeout(() => {
          this.$bvModal.show('createDeviceModal')
        }, 1000)
      }
    })
    this.dataFields = this.dataFields.filter(x => x.selected).concat(this.dataFields.filter(x => !x.selected))
    SearchApi.loadSettings(this.accountId).then(response => {
      const settings = response.tableSettings
      let hasOldList = false
      if (settings.length > 0) {
        settings.forEach(setting => {
          if (setting.columns.length > 0 && setting.table === 'componentsDeviceProperty') {
            hasOldList = true
            this.loadFields(setting)
          }
        })
      }
      if (hasOldList === false) {
        this.fields.push(...this.dataFields.filter(x => x.selected))
        this.selectedFields = JSON.parse(JSON.stringify(this.dataFields))
      }
    }).catch(e => {
      console.log(e)
    })
  },
  methods: {
    loadAmountTickets() {
      TicketApi.countTickets(this.alldevice.map(x => x.id)).then(response => {
        this.tickets = response.entries
      })
    },
    loadDevices() {
      this.isDeviceLoading = true
      this.isListPropertyDeviceLoaded = false
      this.isListBuildingDeviceLoaded = false
      ObjectApi.getDevicesByReferenceIds([this.property.id])
          .then(response => {
            this.listPropertyDevices = response.devices
          })
          .finally(() => {
            this.isListPropertyDeviceLoaded = true
          })
      ObjectApi.getDevicesByReferenceIds(this.property.buildingIds)
          .then(response => {
            this.listBuildingDevices = response.devices.sort((a) => (a.uninstallation === null ? -1 : 1))
          })
          .finally(() => {
            this.isListBuildingDeviceLoaded = true
          })
      ObjectApi.getBuildingsListByIds(this.property.buildingIds).then(response => {
        this.listBuildings = response.buildings
      })
      this.$nextTick(function () {
        this.filterDevicesList()
        this.isDeviceLoading = false
      })
    },
    fillOutCalculatedReplacement(item) {
      if (item.installation) {
        let date = new Date(this.$options.filters.objectToDateInput(item.installation))
        return date.getFullYear() + parseInt(item.lifeSpan)
      }
      return ''
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      SearchApi.saveSettings(this.accountId, columns, 'componentsDeviceProperty').then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal')
      })
    },
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting)
      this.fields = result?.fields
      this.selectedFields = result?.selectedFields
      this.$refs.deviceTable?.refresh()
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    forceNavigation() {
      this.$router.go()
    },
    getObjectDetailRoute(device) {
      if (device !== null && device !== undefined) {
        if (device.referenceId.startsWith('building')) {
          if (this.isOwnerModule) {
            return {
              name: 'OwnerBuildingDetailsView',
              params: { id: device.referenceId },
              query: {
                view: 'building'
              }
            }
          } else if (this.isAdminModule) {
            return {
              name: 'AdminBuildingDetailsView',
              params: { id: device.referenceId },
              query: {
                view: 'building'
              }
            }
          } else if (this.isAssetModule) {
            return {
              name: 'AssetBuildingDetailsView',
              params: { id: device.referenceId },
              query: {
                view: 'building'
              }
            }
          } else if (this.isAccountingModule) {
            return {
              name: 'AccountingBuildingDetailsView',
              params: { id: device.referenceId },
              query: {
                view: 'building'
              }
            }
          }
        } else if (device.referenceId.startsWith('prop')) {
          if (this.isOwnerModule) {
            return {
              name: 'OwnerPropertyDetailsView',
              params: { id: device.referenceId },
              query: {
                view: 'property'
              }
            }
          } else if (this.isAdminModule) {
            return {
              name: 'AdminPropertyDetailsView',
              params: { id: device.referenceId },
              query: {
                view: 'property'
              }
            }
          } else if (this.isAssetModule) {
            return {
              name: 'AssetPropertyDetailsView',
              params: { id: device.referenceId },
              query: {
                view: 'property'
              }
            }
          } else if (this.isAccountingModule) {
            return {
              name: 'AccountingPropertyDetailsView',
              params: { id: device.referenceId },
              query: {
                view: 'property'
              }
            }
          }
        }
      }
    },
    setFocus() {
      this.$refs.searchText?.setFocusInput()
    },
    createTicket() {
      const defaultAssignee = this.property.ownerId
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerTicketCreation',
          query: { fromBulk: true, ids: this.selectedEntriesGlobalIds, defaultAssignee: defaultAssignee }
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminTicketCreation',
          query: { fromBulk: true, ids: this.selectedEntriesGlobalIds, defaultAssignee: defaultAssignee }
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetTicketCreation',
          query: { fromBulk: true, ids: this.selectedEntriesGlobalIds, defaultAssignee: defaultAssignee }
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingTicketCreation',
          query: { fromBulk: true, ids: this.selectedEntriesGlobalIds, defaultAssignee: defaultAssignee }
        })
      }
    },
    goToEditDevice() {
      this.$router.push({
        name: this.isOwnerModule ? 'OwnerPropertyEditingView' : this.isAssetModule ? 'AssetPropertyEditingView' : this.isAccountingModule ? 'AccountingPropertyEditingView' : 'AdminPropertyEditingView',
        params: { id: this.property.id },
        query: {
          view: 'devices',
          elementSelected: this.elementSelected,
          addNewItem: true
        }
      })
    },
    updateDevice(device) {
      if (device.referenceId.includes('building_')) {
        const index = this.listBuildingDevices.findIndex(d => (device.id && d.id && device.id === d.id) || (device.internalId && d.internalId && device.internalId === d.internalId))
        if (index !== -1) {
          this.$set(this.listBuildingDevices, index, device)
        }
      } else if (device.referenceId.includes('prop_')) {
        const index = this.listPropertyDevices.findIndex(d => (device.id && d.id && device.id === d.id) || (device.internalId && d.internalId && device.internalId === d.internalId))
        if (index !== -1) {
          this.$set(this.listPropertyDevices, index, device)
        }
      }
      this.$emit('change-device', device)
    },
    navigateToCreateOrder() {
      const currentRoute = {
        name: this.$router.currentRoute.name,
        params: this.$router.currentRoute.params,
        query: this.$router.currentRoute.query
      }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerCreateOrderView',
          query: {
            buildingIds: this.property.buildingIds.toString(),
            deviceIds: this.selectedEntriesGlobalIds.toString(),
            source: 'device'
          }
        }, () => {
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminCreateOrderView',
          query: {
            buildingIds: this.property.buildingIds.toString(),
            deviceIds: this.selectedEntriesGlobalIds.toString(),
            source: 'device'
          }
        }, () => {
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetCreateOrderView',
          query: {
            buildingIds: this.property.buildingIds.toString(),
            deviceIds: this.selectedEntriesGlobalIds.toString(),
            source: 'device'
          }
        }, () => {
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingCreateOrderView',
          query: {
            buildingIds: this.property.buildingIds.toString(),
            deviceIds: this.selectedEntriesGlobalIds.toString(),
            source: 'device'
          }
        }, () => {
        })
      }
    },
    itemSelectedGlobal(item, checked) {
      if (checked) {
        this.selectedEntriesGlobal.push(item)
        if (this.selectedEntriesGlobal.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntriesGlobal = this.selectedEntriesGlobal.filter(entry => entry.id !== item.id)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    async saveData() {
      if (this.isInvalid()) {
        return
      }
      this.isDeviceLoading = true
      // this.$emit('set-loading', true)
      const promises = []
      // Create or update building devices
      for (const device of this.listBuildingDevices) {
        if (device.internalId) {
          promises.push(ObjectApi.createDevice(device))
        } else if (device.modified) {
          promises.push(ObjectApi.updateDevice(device))
        }
      }

      // Create or update property devices
      for (const device of this.listPropertyDevices) {
        if (device.internalId) {
          promises.push(ObjectApi.createDevice(device))
        } else if (device.modified) {
          promises.push(ObjectApi.updateDevice(device))
        }
      }
      // Delete devices that were removed and already created
      for (const removedDevice of this.removedDevices) {
        if (removedDevice.id) {
          promises.push(ObjectApi.deleteDevice(removedDevice.id))
        }
      }
      Promise.all(promises).then((response) => {
        if (this.removedDevices.length > 0) {
          Vue.toasted.show(this.$t('message.successMessages.deviceDeleted'), { type: 'success' })
        } else {
          Vue.toasted.show(this.$t('message.successMessages.deviceSaved'), { type: 'success' })
        }
        this.loadDevices()
        this.isDeviceLoading = false
        this.removedDevices = []
        if (response.length > 0) {
          const device = response[0].device
          this.$emit('mont-device-tab', device)
        }
      }).finally(() => {
      })
    },
    getBuildingName(id) {
      return (this.listBuildings.length > 0 && this.listBuildings.find(x => x.id === id)) ? this.listBuildings.find(x => x.id === id).name : ''
    },
    customLabelCategory(item) {
      if (item.value === 'building') {
        return this.$t('message.generic.building') + ' (' + item.count + ')'
      }
      if (item.value === 'property') {
        return this.$t('message.propertiesView.tabs.property') + ' (' + item.count + ')'
      }
    },
    customLabel(item) {
      if (!item.value) {
        return this.$t('message.onBoarding.devices.undefined') + ' (' + item.count + ')'
      }
      return item.value + ' (' + item.count + ')'
    },
    customLabelInstallation(item) {
      if (!item.value) {
        return '-'
      }
      return this.$t('message.onBoarding.devices.filter.' + item.label.toLowerCase())
    },
    customLabelFloorRoom(item) {
      return item.value + ' (' + item.count + ')'
    },
    amountTickets(id, type) {
      if (this.tickets.find(e => e.referenceId === id)) {
        return this.tickets.find(e => e.referenceId === id)[type] || 0
      }
      return 0
    },
    initFilter() {
      this.searchText = ''
      this.searchCategories = []
      this.searchRooms = []
      this.searchFloor = []
      this.searchAssignee = []
      this.searchInstallation = null
      this.filterDevicesList()
    },
    openActivities(device) {
      if (device !== null && device !== undefined) {
        this.selectedDevice = device
        this.$emit('selected-device-for-history', device)
        // if (this.isOwnerModule) {
        //   this.$router.replace({
        //     name: 'OwnerPropertyDetailsView',
        //     params: { id: this.property.id },
        //     query: {
        //       view: 'history',
        //       elementSelected: device.id
        //     }
        //   })
        // } else if (this.isAdminModule) {
        //   this.$router.replace({
        //     name: 'AdminPropertyDetailsView',
        //     params: { id: this.property.id },
        //     query: {
        //       view: 'history',
        //       elementSelected: device.id
        //     }
        //   })
        // } else if (this.isAssetModule) {
        //   this.$router.replace({
        //     name: 'AssetPropertyDetailsView',
        //     params: { id: this.property.id },
        //     query: {
        //       view: 'history',
        //       elementSelected: device.id
        //     }
        //   })
        // } else if (this.isAccountingModule) {
        //   this.$router.replace({
        //     name: 'AccountingPropertyDetailsView',
        //     params: { id: this.property.id },
        //     query: {
        //       view: 'history',
        //       elementSelected: device.id
        //     }
        //   })
        // }
      }
    },
    openActivitiesMiddle(device) {
      let routeData = ''
      if (device !== null && device !== undefined) {
        if (this.isOwnerModule) {
          routeData = this.$router.resolve({
            name: 'OwnerPropertyDetailsView',
            params: { id: this.property.id },
            query: {
              view: 'history',
              elementSelected: device.id
            }
          })
        } else if (this.isAdminModule) {
          routeData = this.$router.resolve({
            name: 'AdminPropertyDetailsView',
            params: { id: this.property.id },
            query: {
              view: 'history',
              elementSelected: device.id
            }
          })
        } else if (this.isAssetModule) {
          routeData = this.$router.resolve({
            name: 'AssetPropertyDetailsView',
            params: { id: this.property.id },
            query: {
              view: 'history',
              elementSelected: device.id
            }
          })
        } else if (this.isAccountingModule) {
          routeData = this.$router.resolve({
            name: 'AccountingPropertyDetailsView',
            params: { id: this.property.id },
            query: {
              view: 'history',
              elementSelected: device.id
            }
          })
        }
      }
      window.open(routeData.href, '_blank')
    },
    redirectToActivitiesView(id, type = '') {
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerActivitiesTicketView',
          params: { id: id },
          query: { view: 'tickets', defaultType: type }
        }, () => {
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminActivitiesTicketView',
          params: { id: id },
          query: { view: 'tickets', defaultType: type }
        }, () => {
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetActivitiesTicketView',
          params: { id: id },
          query: { view: 'tickets', defaultType: type }
        }, () => {
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingActivitiesTicketView',
          params: { id: id },
          query: { view: 'tickets', defaultType: type }
        }, () => {
        })
      }
    },
    redirectMiddleToActivitiesView(id, type = '') {
      let routeData = ''
      if (this.isOwnerModule) {
        routeData = this.$router.resolve({
          name: 'OwnerActivitiesTicketView',
          params: { id: id },
          query: { view: 'tickets', defaultType: type }
        }, () => {
        })
      } else if (this.isAdminModule) {
        routeData = this.$router.resolve({
          name: 'AdminActivitiesTicketView',
          params: { id: id },
          query: { view: 'tickets', defaultType: type }
        }, () => {
        })
      } else if (this.isAssetModule) {
        routeData = this.$router.resolve({
          name: 'AssetActivitiesTicketView',
          params: { id: id },
          query: { view: 'tickets', defaultType: type }
        }, () => {
        })
      } else if (this.isAccountingModule) {
        routeData = this.$router.resolve({
          name: 'AccountingActivitiesTicketView',
          params: { id: id },
          query: { view: 'tickets', defaultType: type }
        }, () => {
        })
      }
      window.open(routeData.href, '_blank')
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs.deviceTable) {
        const allCheckboxesComponents = this.$refs.deviceTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
        this.selectAllVisibleEntries = value
        if (value === false) {
          this.selectAllEntries = false
        }
      }
    },
    getNetworkPartnersObject(objectId) {
      const shares = this.shares.filter(share => {
        return share.resourceIds.includes(objectId)
      })

      if (shares.length > 0 && this.networkPartners.length !== 0) {
        const partners = this.networkPartners.filter(partner => {
          return shares.map(share => share.targetAccountId).includes(partner.id)
        })
        return partners.map(obj => ({ ...obj, objectId: objectId }))
      }
      return []
    },
    changeSelectedDevice(item) {
      for (let i = 0; i < this.deviceList.length; i++) {
        if (this.deviceList[i]._rowVariant === 'b-table-row-selected table-active') {
          this.deviceList[i]._rowVariant = ''
        }
      }
      this.selectedDevice = item
      if (item) {
        this.$emit('selected-device', item)
      }
    },
    closeModal() {
      this.$refs['modal-confirmation'].hide()
    },
    duplicateConfirmation() {
      if (!isNaN(this.duplicateCount)) {
        for (let index = 0; index < this.duplicateCount; index++) {
          const clone = Object.assign({}, this.deviceToDuplicate)
          clone.internalId = clone.internalId + index
          clone.serialNumber = ''
        }
      }
      this.$refs['modal-confirmation'].hide()
    },
    duplicateDevice() {
      // TODO
    },
    filterDevices(key, order = 'asc') {
      const current = this
      return function innerSort(a, b) {
        let varA
        let varB
        if (key === 'installation') {
          varA = current.dateObjectToTimeStamp(a[key])
          varB = current.dateObjectToTimeStamp(b[key])
        } else if (key === 'uninstallation') {
          varA = current.dateObjectToTimeStamp(a[key])
          varB = current.dateObjectToTimeStamp(b[key])
        } else if (key === 'warrantyExpiration') {
          varA = current.dateObjectToTimeStamp(a.warrantyEnd)
          varB = current.dateObjectToTimeStamp(b.warrantyEnd)
        } else if (key === 'nextMaintenance') {
          varA = current.dateObjectToTimeStamp(a.maintenance.nextMaintenance)
          varB = current.dateObjectToTimeStamp(b.maintenance.nextMaintenance)
        } else if (key === 'maintenanceCosts') {
          varA = a.maintenance.costs.amount
          varB = b.maintenance.costs.amount
        } else if (key === 'type') {
          varA = current.$t('message.onBoarding.devices.typeValues.' + a[key])
          varB = current.$t('message.onBoarding.devices.typeValues.' + b[key])
        } else {
          varA = (typeof a[key] === 'string')
              ? a[key].toUpperCase()
              : a[key]
          varB = (typeof b[key] === 'string')
              ? b[key].toUpperCase()
              : b[key]
        }
        let comparison = 0
        if (varA > varB) {
          comparison = 1
        } else if (varA < varB) {
          comparison = -1
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        )
      }
    },
    filterDevicesList() {
      this.$nextTick(function () {
        this.deviceList = this.alldevice.filter(item => {
          const typeMaped = this.$t('message.onBoarding.devices.typeValues.' + item.type)
          const brandSearch = item.brand ? item.brand.toLowerCase().includes(this.searchText.toLowerCase()) : null
          const modelSearch = item.model ? item.model.toLowerCase().includes(this.searchText.toLowerCase()) : null
          const systemSearch = item.system ? item.system.toLowerCase().includes(this.searchText.toLowerCase()) : null
          const cdtSearch = typeMaped ? typeMaped.toLowerCase().includes(this.searchText.toLowerCase()) : null
          const categoryMapped = this.searchCategories.map(x => x.value)
          let cdtCategory
          if (item.referenceId.includes('prop_')) {
            cdtCategory = categoryMapped.includes('property') || this.searchCategories.length === 0
          } else {
            cdtCategory = categoryMapped.includes('building') || this.searchCategories.length === 0
          }
          const installed = item.uninstallation === null
          const uninstalled = item.uninstallation !== null
          if (this.searchInstallation && this.searchInstallation.label === 'installed') {
            return (cdtSearch || brandSearch || modelSearch || systemSearch) && cdtCategory && installed
          } else if (this.searchInstallation && this.searchInstallation.label === 'uninstalled') {
            return (cdtSearch || brandSearch || modelSearch || systemSearch) && cdtCategory && uninstalled
          } else {
            return (cdtSearch || brandSearch || modelSearch || systemSearch) && cdtCategory
          }
        })
      })
    },
    addNewPropertyDevice() {
      const internalId = new Date().getTime()
      const device = {
        internalId: JSON.parse(JSON.stringify(internalId)),
        ownerId: this.property.ownerId,
        referenceId: this.property.id,
        installation: null,
        uninstallation: null,
        measureUntil: null,
        measure: 'DEVICE_MEASURE_UNDEFINED',
        type: 'DEVICE_TYPE_UNDEFINED',
        system: 'DEVICE_SYSTEM_UNDEFINED',
        facilityPlant: 'DEVICE_FACILITY_PLANT_UNDEFINED',
        bkpH: '',
        lifeSpan: -1,
        amount: -1,
        unit: 'DEVICE_UNIT_UNDEFINED',
        acquisitionCosts: -1,
        cataloguePrice: -1,
        maintenance: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastMaintenance: null,
          nextMaintenance: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        inspection: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastOfficialControl: null,
          nextOfficialControl: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        revision: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastOfficialRevision: null,
          nextOfficialRevision: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        condition: 'DEVICE_CONDITION_UNDEFINED',
        note: '',
        documentIds: [],
        images: [],
        isNewItem: true,
        lockingKey: {
          combinedKeys: false,
          combinedKeysNote: '',
          description: '',
          lockingPlanNumber: ''
        }
      }
      this.listPropertyDevices.push(device)
    },
    addNewBuildingDevice(selectedEntry) {
      const internalId = new Date().getTime()
      const device = {
        internalId: JSON.parse(JSON.stringify(internalId)),
        ownerId: this.listBuildings.find(b => b.id === selectedEntry).ownerId,
        referenceId: selectedEntry,
        installation: null,
        uninstallation: null,
        measure: 'DEVICE_MEASURE_UNDEFINED',
        type: 'DEVICE_TYPE_UNDEFINED',
        system: 'DEVICE_SYSTEM_UNDEFINED',
        facilityPlant: 'DEVICE_FACILITY_PLANT_UNDEFINED',
        bkpH: '',
        lifeSpan: -1,
        amount: -1,
        acquisitionCosts: null,
        unit: 'DEVICE_UNIT_UNDEFINED',
        measureUntil: null,
        cataloguePrice: -1,
        maintenance: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastMaintenance: null,
          nextMaintenance: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        inspection: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastOfficialControl: null,
          nextOfficialControl: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        revision: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastOfficialRevision: null,
          nextOfficialRevision: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        condition: 'DEVICE_CONDITION_UNDEFINED',
        note: '',
        documentIds: [],
        images: [],
        isNewItem: true,
        lockingKey: {
          combinedKeys: false,
          combinedKeysNote: '',
          description: '',
          lockingPlanNumber: ''
        }
      }
      this.listBuildingDevices.push(device)
    },
    createDeviceClicked() {
      this.isBusy = true
      if (this.selectedEntries[0].includes('prop')) {
        this.addNewPropertyDevice()
      } else {
        this.addNewBuildingDevice(this.selectedEntries[0])
      }
      this.$nextTick(function () {
        const max = this.alldevice.filter(x => x.internalId).reduce(function (prev, current) {
          return prev.internalId > current.internalId ? prev : current
        })
        this.selectedDevice = max
        this.$emit('selected-device', max)
        this.$bvModal.hide('createDeviceModal')
        this.clearRowVariants()
        const i = this.alldevice.findIndex(x => x.internalId && x.internalId === max.internalId)
        // this.alldevice[i]._rowVariant = 'b-table-row-selected table-active'
        if (this.$refs['table-groups']) {
          this.$refs['table-groups'].refresh()
        }
        this.$nextTick(function () {
          this.currentPage = (Math.floor(i / this.nbrPerPage)) + 1
          if (this.$refs.deviceTable) {
            const index = this.deviceList.findIndex(x => x.internalId && x.internalId === max.internalId)
            if (index !== -1) {
              // we need setTimeout because the current is not yet changed
              setTimeout(() => {
                this.$refs.deviceTable.selectRow(index)
              }, 100)
            }
          }
        })
        this.selectedEntries = []
        this.isBusy = false
        this.$emit('change-device', true)
      })
    },
    clearRowVariants() {
      for (const device of this.deviceList) {
        device._rowVariant = ''
      }
    },
    itemSelected(item, checked) {
      if (checked) {
        this.selectedEntries.push(item.id)
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry !== item.id)
      }
    },
    searchInputText() {
      this.debouncedFilterDevicesList()
    },
    removeDevice() {
      if (this.selectedDevice.referenceId.includes('building_')) {
        const index = this.listBuildingDevices.findIndex(d => this.selectedDevice.id === d.id)
        if (index !== -1) {
          this.$set(this.listBuildingDevices, index, this.selectedDevice)
          this.$delete(this.listBuildingDevices, index)
        }
      } else if (this.selectedDevice.referenceId.includes('prop_')) {
        const index = this.listPropertyDevices.findIndex(d => this.selectedDevice.id === d.id)
        if (index !== -1) {
          this.$set(this.listPropertyDevices, index, this.selectedDevice)
          this.$delete(this.listPropertyDevices, index)
        }
      }
      this.$emit('delete-device', this.selectedDevice)
      this.removedDevices.push(this.selectedDevice)
      this.selectedDevice = null
    },
    updateSorting(value, field) {
      if (field !== 'acquisitionCosts' && this.$refs.acquisitionCosts_sort) {
        this.$refs.acquisitionCosts_sort.direction = ''
      }
      if (field !== 'installation' && this.$refs.installation_sort) {
        this.$refs.installation_sort.direction = ''
      }
      if (field !== 'uninstallation' && this.$refs.uninstallation_sort) {
        this.$refs.uninstallation_sort.direction = ''
      }
      if (field !== 'warrantyExpiration' && this.$refs.warrantyExpiration_sort) {
        this.$refs.warrantyExpiration_sort.direction = ''
      }
      if (field !== 'nextMaintenance' && this.$refs.nextMaintenance_sort) {
        this.$refs.nextMaintenance_sort.direction = ''
      }
      if (field !== 'maintenanceCosts' && this.$refs.maintenanceCosts_sort) {
        this.$refs.maintenanceCosts_sort.direction = ''
      }
      if (field !== 'type' && this.$refs.type_sort) {
        this.$refs.type_sort.direction = ''
      }
      this.sortValue = value
      this.sortField = field
      this.$nextTick(function () {
        if (this.sortValue !== '' && this.sortField !== '') {
          this.listDevices = this.alldevice.sort(this.filterDevices(this.sortField, this.sortValue))
          if (this.elementSelected) {
            const index = this.listDevices.findIndex(x => x.id && x.id === this.elementSelected)
            this.currentPage = (Math.floor(index / this.nbrPerPage)) + 1
            setTimeout(() => {
              const indexPerPage = index % this.nbrPerPage
              this.$refs.deviceTable?.selectRow(indexPerPage)
            }, 100)
          }
        }
      })
    },
    isInvalid() {
      let valid = true
      if (this.$refs.device && this.$refs.device.isInvalid()) {
        if (!this.$refs.device.isCollapseOpen()) {
          this.showManyToast([(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.createBuilding.device') + this.$t('message.savingErrors.validationError2'))])
        }
        valid = false
      }
      return !valid
    }
  }

}
</script>
<style lang="scss" scoped>
</style>
