<template>
  <div>
    <b-modal
      :id="modalId"
      :ref="modalId"
      modal-class="has-confirmation-modal"
      no-close-on-backdrop>
      <template #modal-header>
        <h5
          class="mb-0 d-inline modal-title">
          {{ modalTitle }}
        </h5>
        <coozzy-button
          design="transparent"
          class="float-right close"
          @click="hideModal()" />
      </template>
      <div
        v-if="showSearch && forOwnerId"
        class="row">
        <div
          class="col-12 mb-3">
          <ais-instant-search
            v-if="searchClient"
            :search-client="searchClient"
            index-name="contact">
            <!-- eslint-disable vue/attribute-hyphenation -->
            <ais-configure
              :hitsPerPage="10"
              :filters="filterQuery" />
            <!-- eslint-enable vue/attribute-hyphenation -->
            <ais-autocomplete>
              <template slot-scope="{ indices, refine }">
                <vue-autosuggest
                  v-model="searchQuery"
                  :suggestions="indicesToSuggestions(indices)"
                  :input-props="{
                    placeholder: $t('message.generic.search'),
                    class: 'custom-select inp',
                    id: 'autosuggest__input'
                  }"
                  @input="onSuggestionInput(refine)"
                  @selected="onSuggestionSelected">
                  <template
                    slot-scope="{ suggestion }">
                    <strong v-if="suggestion.item.email !== ''">
                      {{ suggestion.item.firstName }} {{ suggestion.item.lastName }} - {{ suggestion.item.email }}
                      <span v-if="showNumericId === true"> - ({{ suggestion.item.numericId }})</span>
                    </strong>
                    <strong v-else>
                      {{ suggestion.item.firstName }} {{ suggestion.item.lastName }}<span v-if="showNumericId === true"> - ({{ suggestion.item.numericId }})</span>
                    </strong>
                  </template>
                </vue-autosuggest>
              </template>
            </ais-autocomplete>
          </ais-instant-search>
        </div>
      </div>
      <!-- Start row: Select owner of contact -->
      <div
        v-if="!isOwnerModule && !forOwnerId"
        class="row">
        <div class="col-12 mb-3">
          <label
            v-if="contactToEdit && isAddressBook"
            for="forAccount"
            class="mb-0">
            {{ $t('message.contact.createdFor') + '*' }}
            <coozzy-info-circle-icon
              v-if="!canBeChangedOwner"
              v-b-tooltip.hover.html="$t('message.contact.createdForTooltip')"
              class="ml-1" />
          </label>
          <label
            v-else-if="!contactToEdit"
            for="forAccount"
            class="mb-0">
            {{ $t('message.contact.forAccount') + '*' }}
          </label>
          <coozzy-form-select
            id="forAccount"
            v-model="contact.ownerId"
            :disabled="!contact.active || (contactToEdit && !canBeChangedOwner)"
            :state="$v.contact.ownerId && $v.contact.ownerId.$dirty && $v.contact.ownerId.$error ? false : null">
            <option :value="null">
              -
            </option>
            <option :value="accountId">
              {{ $t('message.contact.forYourself') }}
            </option>
            <option
              v-for="(stateValue, stateIndex) in ownerAccounts"
              :key="stateIndex"
              :value="stateValue.id">
              <template v-if="stateValue.company">
                {{ stateValue.company.name }}
                <span
                  v-if="stateValue.company && stateValue.company.address">
                  <span class="ml-2 mr-2">-</span>
                  <address-text
                    :one-line="true"
                    :address="stateValue.company.address" />
                </span>
              </template>
              <template v-else-if="stateValue.individual">
                {{ stateValue.individual.lastName + ' ' + stateValue.individual.firstName }}
                <span
                  v-if="stateValue.individual && stateValue.individual.address">
                  <span class="ml-2 mr-2">-</span>
                  <address-text
                    :one-line="true"
                    :address="stateValue.individual.address" />
                </span>
              </template>
              <template v-else>
                n/a
              </template>
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <!-- End row: Select owner of contact -->
      <!-- Start row: Quick fill out -->
      <div
        v-if="!contactToEdit && !restrictNewContactTypeTo"
        class="row">
        <div class="col-6 col-md-3 mb-2">
          <coozzy-form-radio
            v-model="contactType"
            :disabled="!contact.active"
            :default-model="contactType"
            :select-value="'company'"
            name="contactType">
            {{ $t('message.generic.company') }}
          </coozzy-form-radio>
        </div>
        <div class="col-6 col-md-3 mb-2">
          <coozzy-form-radio
            v-model="contactType"
            :disabled="!contact.active"
            :default-model="contactType"
            :select-value="'private'"
            name="contactType">
            {{ $t('message.generic.private') }}
          </coozzy-form-radio>
        </div>
      </div>
      <!-- End row: Quick fill out -->
      <!-- Start row: Request sources -->
      <div
        v-if="showRequestSources"
        class="row">
        <div class="col-6 col-md-3 mb-2">
          <label
            for="source"
            class="mb-0">
            {{ $t('message.contact.source') + '*' }}
          </label>
          <coozzy-form-select
            id="source"
            v-model="request.source"
            :disabled="!contact.active"
            :state="$v.request.source.$dirty && $v.request.source.$error ? false : null">
            <option value="UNDEFINED_REQUEST_SOURCE" />
            <option value="PHONE_REQUEST_SOURCE">
              {{ $t('message.contact.sources.PHONE_REQUEST_SOURCE') }}
            </option>
            <option value="MAIL_REQUEST_SOURCE">
              {{ $t('message.contact.sources.MAIL_REQUEST_SOURCE') }}
            </option>
            <option value="WEBSITE_REQUEST_SOURCE">
              {{ $t('message.contact.sources.WEBSITE_REQUEST_SOURCE') }}
            </option>
            <option value="MARKETPLACE_REQUEST_SOURCE">
              {{ $t('message.contact.sources.MARKETPLACE_REQUEST_SOURCE') }}
            </option>
            <option value="PERSONAL_REQUEST_SOURCE">
              {{ $t('message.contact.sources.PERSONAL_REQUEST_SOURCE') }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col-6 col-md-3 mb-2">
          <label
            for="marketplaceName"
            class="mb-0">
            {{ $t('message.contact.marketplace.title') + '*' }}
          </label>
          <coozzy-form-select
            id="marketplaceName"
            v-model="request.marketplaceName"
            :disabled="!contact.active"
            :state="$v.request.marketplaceName.$dirty && $v.request.marketplaceName.$error ? false : null">
            <option value="immoscout">
              Immoscout24
            </option>
            <option value="homegate">
              Homegate
            </option>
            <option value="newHome">
              NewHome
            </option>
            <option value="comparis">
              Comparis
            </option>
            <option value="tutti">
              Tutti
            </option>
            <option value="flatfox">
              Flatfox
            </option>
            <option value="own_website">
              {{ $t('message.contact.marketplace.ownWebsite') }}
            </option>
            <option value="print">
              {{ $t('message.contact.marketplace.print') }}
            </option>
            <option value="instagram">
              Instagram
            </option>
            <option value="facebook">
              Facebook
            </option>
            <option value="poster">
              {{ $t('message.contact.marketplace.poster') }}
            </option>
            <option value="Other">
              {{ $t('message.contact.marketplace.other') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <!-- End row: Request sources -->
      <!-- Start row: Quick fill out -->
      <div
        v-if="isNewContact"
        class="row">
        <div class="col-12">
          <label> {{ $t('message.generic.automaticAddressToFill') }} </label>
          <coozzy-form-textarea
            ref="addressToFill"
            v-model="addressToFill"
            :disabled="!contact.active"
            :value-text-area="addressToFill"
            :initial="addressToFill"
            :text="addressToFill"
            :resizable="false"
            :show-label="false"
            :rows="4">
            {{ addressToFill }}
          </coozzy-form-textarea>
          <coozzy-button
            size="normal"
            class="float-right mt-1"
            :disabled="loading"
            @click="fillInAddress()">
            {{ $t('message.generic.fill') }}
          </coozzy-button>
        </div>
      </div>
      <!-- End row: Quick fill out -->
      <!-- Start row: Email and roles -->
      <div class="row">
        <div
          class="col-12 mb-2"
          :class="showRolesInput ? 'col-md-6' : ''">
          <coozzy-form-input
            v-model="contact.email"
            :disabled="!contact.active"
            :state="$v.contact.email && $v.contact.email.$dirty && $v.contact.email.$error ? false : null"
            :name="$t('message.generic.email') + (fromDigitalDeposit ? '*' : '')" />
        </div>
        <div
          v-if="showRolesInput"
          class="col-12 col-md-6 mb-2">
          <coozzy-multiselect
            v-model="roles"
            :disabled="!contact.active"
            :options="translatedRolesList"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :placeholder="$t('message.generic.roles')"
            :preselect-first="false"
            :taggable="false"
            label="label"
            track-by="label">
            {{ $t('message.generic.roles') }}
          </coozzy-multiselect>
        </div>
      </div>
      <!-- End row: Email and roles -->
      <!-- Start row: Private gender and title -->
      <div
        v-if="isPrivateContact"
        class="row">
        <div class="col-4 col-md-4 mb-2">
          <label
            for="preferredLanguage"
            class="mb-0">
            {{ $t('message.contact.preferredLanguage') }}
          </label>
          <coozzy-form-select
            id="preferredLanguage"
            v-model="contact.preferredLanguage"
            :disabled="!contact.active">
            <option value="PREFERRED_LANGUAGE_GERMAN">
              {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_GERMAN') }}
            </option>
            <option value="PREFERRED_LANGUAGE_ENGLISH">
              {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_ENGLISH') }}
            </option>
            <option value="PREFERRED_LANGUAGE_ITALIAN">
              {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_ITALIAN') }}
            </option>
            <option value="PREFERRED_LANGUAGE_FRENCH">
              {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_FRENCH') }}
            </option>
          </coozzy-form-select>
        </div>
        <div
          class="col-12 col-md-4 mb-2">
          <label
            for="title"
            class="mb-0">
            {{ $t('message.generic.title.title') + (fromDigitalDeposit ? '*' : '') }}
          </label>
          <coozzy-form-select
            id="title"
            v-model="contact.gender"
            :disabled="!contact.active"
            :state="$v.contact.gender && $v.contact.gender.$dirty && $v.contact.gender.$error ? false : null">
            <option value="UNDEFINED_GENDER" />
            <option value="MALE">
              {{ $t('message.generic.title.mr') }}
            </option>
            <option value="FEMALE">
              {{ $t('message.generic.title.mrs') }}
            </option>
            <option value="OTHER">
              {{ $t('message.generic.title.divers') }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col-4 col-md-4 mb-2">
          <coozzy-form-input
            v-model="contact.title"
            :disabled="!contact.active"
            :limit="fiftyLength"
            :name="$t('message.generic.contactTitle')"
            @input="checkLenght('title')" />
        </div>
      </div>
      <!-- End row: Private gender and title -->
      <!-- Start row: Names (+ industries on company) -->
      <div
        v-if="isPrivateContact"
        class="row">
        <div class="col-12 col-md-4 mb-2">
          <coozzy-form-input
            v-model="contact.firstName"
            :disabled="!contact.active"
            :limit="firstNameLength"
            :state="$v.contact.firstName && $v.contact.firstName.$dirty && $v.contact.firstName.$error ? false : null"
            :name="$t('message.generic.firstName') + '*'"
            @input="checkLenght('firstname')" />
        </div>
        <div class="col-12 col-md-4 mb-2">
          <coozzy-form-input
            v-model="contact.lastName"
            :disabled="!contact.active"
            :limit="lastNameLength"
            :state="$v.contact.lastName && $v.contact.lastName.$dirty && $v.contact.lastName.$error ? false : null"
            :name="$t('message.generic.lastName') + '*'"
            @input="checkLenght('lastname')" />
        </div>
        <div class="col-12 col-md-4 mb-2">
          <coozzy-form-input
            v-model="contact.birthName"
            :disabled="!contact.active"
            :name="$t('message.generic.birthName')" />
        </div>
      </div>
      <div
        v-if="isCompanyContact"
        class="row">
        <div
          class="col-12 mb-2"
          :class="hasIndustry ? 'col-md-6' : ''">
          <coozzy-form-input
            v-model="contact.name"
            :disabled="!contact.active"
            :state="$v.contact.name.$dirty && $v.contact.name.$error ? false : null"
            :name="$t('message.generic.companyName') + '*'" />
        </div>
        <div
          v-if="hasIndustry"
          class="col-12 col-md-6 mb-2">
          <coozzy-multiselect
            v-model="industries"
            :disabled="!contact.active"
            :options="translatedIndustriesList(true)"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :placeholder="$t('message.generic.industries')"
            :preselect-first="false"
            :taggable="false"
            label="label"
            track-by="label">
            {{ $t('message.generic.industries') }}
          </coozzy-multiselect>
        </div>
      </div>
      <!-- End row: Names (+ industries on company) -->
      <!-- Start row: Address 1 -->
      <div
        v-if="contact.address"
        class="row">
        <div class="col-12">
          <coozzy-form-input
            v-model="contact.address.careOf"
            :disabled="!contact.active"
            class="mb-2"
            :name="$t('message.generic.addressAddition')" />
        </div>
      </div>
      <!-- End row: Address 1 -->
      <!-- Start row: Address 2 -->
      <div class="row">
        <div class="col-sm-12 col-md-4">
          <coozzy-form-input
            v-model="contact.address.street"
            :disabled="!contact.active"
            class="mb-2"
            :state="$v.contact.address && $v.contact.address.street && $v.contact.address.street.$dirty && $v.contact.address.street.$error ? false : null"
            :name="$t('message.generic.street') + (fromDigitalDeposit ? '*' : '')" />
        </div>
        <div class="col-sm-12 col-md-4">
          <coozzy-form-input
            v-model="contact.address.streetNumber"
            :disabled="!contact.active"
            class="mb-2"
            :state="$v.contact.address && $v.contact.address.streetNumber && $v.contact.address.streetNumber.$dirty && $v.contact.address.streetNumber.$error ? false : null"
            :name="$t('message.generic.no') + (fromDigitalDeposit ? '*' : '')" />
        </div>
        <div class="col-sm-12 col-md-4">
          <coozzy-form-input
            v-model="contact.address.mailbox"
            :disabled="!contact.active"
            class="mb-2"
            :name="$t('message.generic.postOfficeBox')"
            :placeholder="$t('message.generic.postOfficeBoxPlaceholder')" />
        </div>
      </div>
      <!-- End row: Address 2 -->
      <!-- Start row: Address 3 -->
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <coozzy-form-input
            v-model="contact.address.zip"
            :disabled="!contact.active"
            :type="(contact.address.country === 'CH' || contact.address.country === 'DE') ? 'number': 'text'"
            :limit="zipLength"
            :state="isAddressRequired && $v.contact.address && $v.contact.address.zip && $v.contact.address.zip.$dirty && $v.contact.address.zip.$error ? false : null"
            :name="$t('message.generic.zip') + (isAddressRequired ? '*' : '')"
            @input="getCityFromZip"
            @keydown.tab="isCitiesSuggested ? tabClicked() : ''" />
        </div>
        <div class="col-sm-12 col-md-6">
          <coozzy-form-input
            v-if="!isCitiesSuggested"
            v-model="contact.address.city"
            :disabled="!contact.active"
            :state="isAddressRequired && $v.contact.address && $v.contact.address.city && $v.contact.address.city.$dirty && $v.contact.address.city.$error ? false : null"
            class="mb-2"
            :name="$t('message.generic.city') + (isAddressRequired ? '*' : '')" />
          <template v-else>
            <label for="city">{{ $t('message.generic.city') }}*</label>
            <coozzy-form-select
              id="city"
              v-model="citySelect"
              :state="isAddressRequired && $v.contact.address && $v.contact.address.city && $v.contact.address.city.$dirty && $v.contact.address.city.$error ? false : null"
              @change="cityChanged">
              <option
                v-for="(item, typeIndex) in citiesValue"
                :key="typeIndex"
                :value="item"
                :selected="typeIndex === 0">
                {{ item.city }}
              </option>
            </coozzy-form-select>
          </template>
        </div>
      </div>
      <!-- End row: Address 3 -->
      <!-- Start row: Address 4 -->
      <div class="row">
        <div class="col-sm-6 col-md-6 mb-2">
          <coozzy-form-country-select
            v-model="contact.address.country"
            :disabled="!contact.active"
            :state="isAddressRequired && $v.contact.address && $v.contact.address.country && $v.contact.address.country.$dirty && $v.contact.address.country.$error ? false : null"
            :initial-value="contact.address.country"
            :required="isAddressRequired" />
        </div>
        <div class="col-sm-6 col-md-6 mb-2">
          <coozzy-form-region-select
            v-if="contact.address"
            :key="contact.address.state"
            v-model="contact.address.state"
            :disabled="!contact.active"
            :initial-value="contact.address.state"
            :state="isAddressRequired && $v.contact.address && $v.contact.address.state && $v.contact.address.state.$dirty && $v.contact.address.state.$error ? false : null"
            :country="contact.address.country"
            :required="isAddressRequired" />
        </div>
      </div>
      <!-- End row: Address 4 -->
      <!-- Start row: Phone numbers 1 -->
      <div
        v-if="isPrivateContact"
        class="row">
        <div class="col-12 col-md-6 mb-2">
          <coozzy-form-input
            v-model="contact.phone.mobile"
            :disabled="!contact.active"
            :limit="fiftyLength"
            type="tel"
            :state="$v.contact.phone && $v.contact.phone.$dirty && $v.contact.phone.$error ? false : null"
            :name="$t('message.generic.phone.mobile') + (fromDigitalDeposit ? '*' : '')" />
        </div>
        <div class="col-12 col-md-6 mb-2">
          <coozzy-form-input
            v-model="contact.phone.work"
            :disabled="!contact.active"
            :limit="fiftyLength"
            type="tel"
            :state="$v.contact.phone && $v.contact.phone.$dirty && $v.contact.phone.$error ? false : null"
            :name="$t('message.generic.phone.work')" />
        </div>
      </div>
      <div
        v-if="isCompanyContact"
        class="row">
        <div class="col-12 col-md-4 mb-2">
          <coozzy-form-input
            v-model="contact.phone.work"
            :disabled="!contact.active"
            type="tel"
            :limit="fiftyLength"
            :name="$t('message.generic.phone.work')" />
        </div>
        <div class="col-12 col-md-4 mb-2">
          <coozzy-form-input
            v-model="contact.phone.mobile"
            :disabled="!contact.active"
            type="tel"
            :limit="fiftyLength"
            :name="$t('message.generic.phone.mobile')" />
        </div>
        <div class="col-12 col-md-4 mb-2">
          <coozzy-form-input
            v-model="contact.phone.fax"
            :disabled="!contact.active"
            type="tel"
            :limit="fiftyLength"
            :name="$t('message.generic.phone.fax')" />
        </div>
      </div>
      <!-- End row: Phone numbers 1 -->
      <!-- Start row: Phone numbers / company website / vatNumber 2 -->
      <div
        v-if="isPrivateContact"
        class="row">
        <div class="col-12 col-md-6 mb-2">
          <coozzy-form-input
            v-model="contact.phone.home1"
            :disabled="!contact.active"
            type="tel"
            :limit="fiftyLength"
            :state="$v.contact.phone && $v.contact.phone.$dirty && $v.contact.phone.$error ? false : null"
            :name="$t('message.generic.phone.home1')" />
        </div>
        <div class="col-12 col-md-6 mb-2">
          <coozzy-form-input
            v-model="contact.phone.home2"
            :disabled="!contact.active"
            type="tel"
            :limit="fiftyLength"
            :state="$v.contact.phone && $v.contact.phone.$dirty && $v.contact.phone.$error ? false : null"
            :name="$t('message.generic.phone.home2')" />
        </div>
      </div>
      <div
        v-if="isCompanyContact"
        class="row">
        <div class="col-4 mb-2">
          <coozzy-form-input
            v-model="contact.vatNumber"
            :limit="20"
            :disabled="!contact.active"
            :name="$t('message.generic.vatNumber')" />
        </div>
        <div class="col-4 mb-2">
          <coozzy-form-input
            v-model="contact.website"
            :disabled="!contact.active"
            :limit="65"
            :state="$v.contact.website && $v.contact.website.$dirty && $v.contact.website.$error ? false : null"
            :name="$t('message.generic.website')" />
        </div>
        <div class="col-4  mb-2">
          <label
            for="preferredLanguageCompany"
            class="mb-0">
            {{ $t('message.contact.preferredLanguage') }}
          </label>
          <coozzy-form-select
            id="preferredLanguageCompany"
            v-model="contact.preferredLanguage"
            :disabled="!contact.active">
            <option value="PREFERRED_LANGUAGE_GERMAN">
              {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_GERMAN') }}
            </option>
            <option value="PREFERRED_LANGUAGE_ENGLISH">
              {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_ENGLISH') }}
            </option>
            <option value="PREFERRED_LANGUAGE_ITALIAN">
              {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_ITALIAN') }}
            </option>
            <option value="PREFERRED_LANGUAGE_FRENCH">
              {{ $t('message.contact.preferredLanguageValue.PREFERRED_LANGUAGE_FRENCH') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <!-- End row: Phone numbers 2 -->
      <!-- Start row: Private misc data -->
      <div
        v-if="isPrivateContact"
        class="row">
        <div class="col-sm-12 col-md-4 mb-2">
          <label for="civilStatus">{{ $t('message.applicationForm.civilStatus.title') + (fromDigitalDeposit ? '*' : '') }} </label>
          <coozzy-form-select
            id="civilStatus"
            v-model="contact.civilStatus"
            :state="$v.contact.civilStatus && $v.contact.civilStatus.$dirty && $v.contact.civilStatus.$error ? false : null"
            :disabled="!contact.active">
            <option value="UNDEFINED_CIVIL_STATUS">
              -
            </option>
            <option value="CIVIL_STATUS_SINGLE">
              {{ $t('message.applicationForm.civilStatus.single') }}
            </option>
            <option value="CIVIL_STATUS_MARRIED">
              {{ $t('message.applicationForm.civilStatus.married') }}
            </option>
            <option value="CIVIL_STATUS_DIVORCED">
              {{ $t('message.applicationForm.civilStatus.divorced') }}
            </option>
            <option value="CIVIL_STATUS_WIDOWED">
              {{ $t('message.applicationForm.civilStatus.widowed') }}
            </option>
            <option value="CIVIL_STATUS_SEPARATED">
              {{ $t('message.applicationForm.civilStatus.separated') }}
            </option>
            <option value="CIVIL_STATUS_REGISTERED_PARTNERSHIP">
              {{ $t('message.applicationForm.civilStatus.registeredPartnership') }}
            </option>
            <option value="CIVIL_STATUS_DISSOLVED_PARTNERSHIP">
              {{ $t('message.applicationForm.civilStatus.dissolvedPartnership') }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col-sm-12 col-md-4 mb-2">
          <coozzy-form-input
            v-model="contact.birthplace"
            :disabled="!contact.active"
            :name="$t('message.generic.citizenship')" />
        </div>
        <div class="col-sm-12 col-md-4 mb-2">
          <coozzy-form-country-citizenship-select
            v-model="contact.nationality"
            :disabled="!contact.active"
            :label="$t('message.generic.nationality.title')"
            :required="fromDigitalDeposit"
            :from-digital-deposit="fromDigitalDeposit"
            :state="$v.contact.nationality && $v.contact.nationality.$dirty && $v.contact.nationality.$error ? false : null"
            :initial-value="contact.nationality" />
        </div>
      </div>
      <!-- End row: Private misc data -->
      <!-- Start row: Residence info for non Swiss people -->
      <div
        v-if="isPrivateContact && isNationalitySet && !isSwiss"
        class="row">
        <div class="col-sm-12 col-md-6 mb-2">
          <label for="residenceStatus">{{ $t('message.generic.residenceStatus') }}</label>
          <coozzy-form-select
            id="residenceStatus"
            v-model="contact.residenceStatus"
            :disabled="!contact.active">
            <option value="UNDEFINED_RESIDENCE_STATUS">
              -
            </option>
            <option value="RESIDENCE_STATUS_B_STAY">
              {{ $t('message.generic.residenceStatusB') }}
            </option>
            <option value="RESIDENCE_STATUS_C_SETTLE">
              {{ $t('message.generic.residenceStatusC') }}
            </option>
            <option value="RESIDENCE_STATUS_CI_STAY_EMPLOYMENT">
              {{ $t('message.generic.residenceStatusCi') }}
            </option>
            <option value="RESIDENCE_STATUS_G_BORDER_CROSSER">
              {{ $t('message.generic.residenceStatusG') }}
            </option>
            <option value="RESIDENCE_STATUS_L_SHORT_STAY">
              {{ $t('message.generic.residenceStatusL') }}
            </option>
            <option value="RESIDENCE_STATUS_F_PRELIMINARY_FOREIGNER">
              {{ $t('message.generic.residenceStatusF') }}
            </option>
            <option value="RESIDENCE_STATUS_N_SEEKING_ASYLUM">
              {{ $t('message.generic.residenceStatusN') }}
            </option>
            <option value="RESIDENCE_STATUS_S_PROTECTION_NEEDED">
              {{ $t('message.generic.residenceStatusS') }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col-sm-12 col-md-6 mb-2">
          <coozzy-form-input
            v-model="dates.residenceStatusValidUntil"
            :disabled="!contact.active"
            type="date"
            :name="$t('message.generic.validUntil')"
            @blur="dateToObject()" />
        </div>
      </div>
      <!-- End row: Residence info for non Swiss people -->
      <!-- Start row: Fax + birthday + date of death -->
      <div
        v-if="isPrivateContact"
        class="row">
        <div class="col-12 col-md-6 mb-2">
          <coozzy-form-input
            v-model="contact.phone.fax"
            :disabled="!contact.active"
            type="tel"
            :name="$t('message.generic.phone.fax')" />
        </div>
        <div
          :class="contactToEdit ? 'col-md-3' : 'col-md-6'"
          class="col-12 mb-2">
          <coozzy-form-input
            :key="randomId"
            v-model="dates.dateOfBirth"
            :disabled="!contact.active"
            :state="$v.contact.dateOfBirth && $v.contact.dateOfBirth.$dirty && $v.contact.dateOfBirth.$error ? false : null"
            type="date"
            :name="$t('message.generic.birthday') + (fromDigitalDeposit ? '*' : '')"
            @blur="dateToObject()" />
        </div>
        <div
          v-if="contactToEdit"
          class="col-12 col-md-3 mb-2">
          <coozzy-form-input
            :key="randomId"
            v-model="dates.dateOfDeath"
            :disabled="!contact.active"
            type="date"
            :name="$t('message.generic.dateOfDeath')"
            @blur="dateToObject()" />
        </div>
      </div>
      <!-- End row: Fax + birthday -->
      <!-- Start row: employment -->
      <div
        v-if="isPrivateContact && contact.employment"
        class="row">
        <div class="col-12 col-md-6 mb-2">
          <coozzy-form-input
            v-model="contact.employment.companyName"
            :disabled="!contact.active"
            :name="$t('message.generic.companyName')" />
        </div>
        <div class="col-12 col-md-6 mb-2">
          <coozzy-form-input
            v-model="contact.employment.companyPosition"
            :disabled="!contact.active"
            :name="$t('message.generic.jobTitle')" />
        </div>
      </div>
      <!-- End row: employment -->
      <!-- Start row: Industry + private website -->
      <div
        v-if="isPrivateContact"
        class="row">
        <div
          v-if="hasIndustry"
          class="col-12 col-md-6 mb-2">
          <coozzy-multiselect
            v-model="industries"
            :disabled="!contact.active"
            :options="translatedIndustriesList(true)"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :placeholder="$t('message.generic.industries')"
            :preselect-first="false"
            :taggable="false"
            label="label"
            track-by="label">
            {{ $t('message.generic.industries') }}
          </coozzy-multiselect>
        </div>
        <div
          class="col-md-6  mb-2">
          <coozzy-form-input
            v-model="contact.website"
            :disabled="!contact.active"
            :limit="65"
            :state="$v.contact.website && $v.contact.website.$dirty && $v.contact.website.$error ? false : null"
            :name="$t('message.generic.website')" />
        </div>
        <div
          class="col-md-6  mb-2">
          <coozzy-form-input
            v-model="contact.ahv"
            :disabled="!contact.active"
            :limit="50"
            :state="$v.contact.ahv && $v.contact.ahv.$dirty && $v.contact.ahv.$error ? false : null"
            :name="$t('message.generic.ahvNbr')" />
        </div>
      </div>
      <div
        v-if="isPrivateContact"
        class="row">
        <div
          class="col-md-6 mb-2">
          <coozzy-form-input
            v-model="workEmail"
            :disabled="!contact.active"
            :state="useWorkEmail === true && $v.workEmail.$dirty && $v.workEmail.$error ? false : null"
            :name="$t('message.generic.businessEmail') + (useWorkEmail ? '*' : '')" />
        </div>
        <div
          class="col-md-6 alignUseEmail">
          <coozzy-form-checkbox
            v-model="useWorkEmail"
            :initial="useWorkEmail"
            :disabled="!contact.active"
            class="">
            {{
              $t('message.generic.useEmail')
            }}
            <coozzy-info-circle-icon
              v-b-tooltip.hover.html="$t('message.contact.msgBusinessEmail')"
              class="ml-1" />
          </coozzy-form-checkbox>
        </div>
      </div>
      <!-- End row: Industry + private website -->
      <!-- Start row Salutation -->
      <div class="row">
        <div
          class="mb-2"
          :class="isCompanyContact ? 'col-4' : 'col-6'">
          <label for="preferredCommunicationChannelCompany">{{ $t('message.contact.preferredCommunicationChannel') }} </label>
          <coozzy-form-select
            id="preferredCommunicationChannelCompany"
            v-model="contact.preferredCommunicationChannel"
            :disabled="!contact.active">
            <option value="PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED">
              -
            </option>
            <option value="PREFERRED_COMMUNICATION_CHANNEL_EMAIL">
              {{ $t('message.contact.preferredCommunicationChannelType.email') }}
            </option>
            <option value="PREFERRED_COMMUNICATION_CHANNEL_LETTER">
              {{ $t('message.contact.preferredCommunicationChannelType.letter') }}
            </option>
          </coozzy-form-select>
        </div>
        <div
          v-if="isCompanyContact"
          class="col-4 mb-2">
          <coozzy-multiselect
            v-model="notificationPreferences"
            :disabled="!contact.active"
            :options="translatedNotificationPreferencesList"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :placeholder="$t('message.contact.notificationPreferences.title')"
            :preselect-first="false"
            :taggable="false"
            label="label"
            track-by="label">
            {{ $t('message.contact.notificationPreferences.title') }}
          </coozzy-multiselect>
        </div>
        <div
          v-if="!checkDateOfDeath"
          class="mb-2"
          :class="isCompanyContact ? 'col-4' : 'col-6'">
          <coozzy-form-input
            v-model="contact.salutationName"
            :disabled="!contact.active"
            :name="$t('message.generic.salutation')"
            :placeholder="salutationPlaceholder"
            :tooltip-text="salutationTooltip" />
        </div>
      </div>
      <!-- End row salutation -->
      <!-- Start row: Tags -->
      <div class="row">
        <div class="col-12 mb-2">
          <label> {{ $t('message.generic.tags') }} </label>
          <coozzy-form-tag
            v-model="contact.tags"
            :initial="contact.tags"
            :disabled="!contact.active"
            :suggestions="tagsList"
            @suggestionselected="suggestionSelected"
            @suggestion-removed="suggestionRemoved" />
        </div>
      </div>
      <!-- end row: Tags -->
      <!-- Start row: Notes -->
      <div class="row">
        <div class="col-12 mb-2">
          <label> {{ $t('message.generic.notes') }} </label>
          <coozzy-form-textarea
            ref="note"
            :key="randomId"
            v-model="contact.note"
            :disabled="!contact.active"
            :value-text-area="contact.note"
            :initial="contact.note"
            :text="contact.note"
            :resizable="false"
            :show-label="false"
            :rows="3" />
        </div>
      </div>
      <!-- end row: Notes -->
      <!-- Start footer -->
      <div
        slot="modal-footer"
        class="w-100 float-right">
        <coozzy-button
          size="normal"
          design="green"
          class="float-right"
          :disabled="loading || !contact.active"
          @click="saveClicked">
          {{ isNewContact ? $t('message.contact.actions.add') : $t('message.generic.form.save') }}
        </coozzy-button>
        <coozzy-form-checkbox
          v-if="contactToEdit === null && showAddAnother && addAnotherCheckbox"
          v-model="takeOverAddress"
          :initial="takeOverAddress"
          :disabled="!addAnotherCheckbox"
          class="float-right pr-3 mt-3 mr-0 display-none-xs font-weight-lighter">
          {{ $t('message.generic.takeOverAddress') }}
          <coozzy-info-circle-icon
            v-if="contactToEdit === null && showAddAnother"
            v-b-tooltip.hover.html="$t('message.generic.takeOverAddressToolTip')" />
        </coozzy-form-checkbox>
        <coozzy-form-checkbox
          v-if="contactToEdit === null && showAddAnother"
          v-model="addAnotherCheckbox"
          :initial="addAnotherCheckbox"
          class="float-right pr-3 mt-3 mr-0 display-none-xs font-weight-lighter"
          @change="(value) => { !value ? takeOverAddress = false : takeOverAddress = takeOverAddress}">
          {{ $t('message.generic.createAnother') }}
        </coozzy-form-checkbox>
        <coozzy-button
          size="normal"
          class="float-left"
          :disabled="loading"
          @click="hideModal()">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          v-if="contactToEdit !== null && enableDelete"
          design="red"
          size="normal"
          class="float-left ml-1"
          :disabled="loading || (contactToEdit && contactToEdit.tenancyIds && contactToEdit.tenancyIds.length > 0)"
          @click="$bvModal.show('delete-contact-confirmation-modal')">
          {{ contactToEdit.active ? $t('message.generic.inactivate') : $t('message.generic.activate') }}
          <coozzy-info-circle-icon
            v-if="contactToEdit && contactToEdit.tenancyIds && contactToEdit.tenancyIds.length > 0"
            v-b-tooltip.hover.html="$t('message.deleteErrors.inactivatecontactisActiveTenancyTooltip')"
            class="ml-1" />
        </coozzy-button>
      </div>
      <!-- End footer -->
    </b-modal>
    <b-modal
      id="delete-contact-confirmation-modal"
      ref="delete-contact-confirmation-modal"
      no-close-on-backdrop
      hide-footer
      :title="contact.active ? $t('message.employeesSettings.deactivateContactConfirmation') : $t('message.employeesSettings.activateContactConfirmation')">
      <div class="col p-0">
        <p v-if="contact.active">
          {{ $t('message.employeesSettings.deactivateContactText') }}
        </p>
        <p v-else>
          {{ $t('message.employeesSettings.activeUserText') }}
        </p>
      </div>
      <div class="col p-0">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('delete-contact-confirmation-modal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          v-if="contact.active"
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="loading"
          @click="deactivateContact()">
          {{ $t('message.generic.inactivate') }}
        </coozzy-button>
        <coozzy-button
          v-else
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="loading"
          @click="activateContact()">
          {{ $t('message.generic.activate') }}
        </coozzy-button>
      </div>
    </b-modal>

    <b-modal
      id="global-confirmation-modal-1"
      ref="global-confirmation-modal-1"
      no-close-on-backdrop
      :title="$t('message.onBoardingTabs.confirmModal.confirmationTitle')"
      hide-footer>
      <p>{{ $t('message.onBoardingTabs.confirmModal.confirmationText') }}</p>
      <div class="col">
        <coozzy-button
          size="small"
          class="border mb-0"
          design="transparent"
          @click="$bvModal.hide('global-confirmation-modal-1')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="closeCurrentModal">
          {{ $t('message.onBoardingTabs.confirmModal.confirmationButton') }}
        </coozzy-button>
      </div>
    </b-modal>
    <b-modal
      id="duplicated-contact-confirmation-modal"
      ref="duplicated-contact-confirmation-modal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.contact.duplicatedContact.title')">
      <p>
        {{ $t('message.contact.duplicatedContact.text') }}
      </p>
      <div class="col-12">
        <a
          v-for="(item, index) in duplicateContacts"
          :key="index"
          class="text-decoration-none"
          @click="redirectToContactDetail(item.id)">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-12 mt-3">
                  <span class="font-weight-bold">{{ item.numericId }} - {{ item.name }}</span>
                  <p class="mb-0">{{ item.address.street }} {{ item.address.streetNumber }}</p>
                  <p class="mb-0">{{ item.address.zip }} {{ item.address.city }}</p>
                  <p class="mb-0">{{ item.email }} </p>
                  <p
                    v-for="(x, index2) in item.phoneNumbers"
                    :key="index2"
                    class="mb-0">
                    {{ x.number }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col p-0 mt-2">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('duplicated-contact-confirmation-modal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          @click="createContact()">
          {{ $t('message.generic.form.save') }}
        </coozzy-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import { VueAutosuggest } from 'vue-autosuggest'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyFormRadio from '@/framework/components/form/radio/CoozzyFormRadio'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import { user } from '@/mixins/user'
import { algolia } from '@/mixins/algolia'
import { routeChecks } from '@/mixins/routeChecks'
import { string } from '@/mixins/string'
import { email, required, maxLength } from 'vuelidate/lib/validators'
import ContactApi from '@/misc/apis/ContactApi'
import ShareApi from '@/misc/apis/ShareApi'
import UserApi from '@/misc/apis/UserApi'
import CoozzyMultiselect from '../../framework/components/multiselect/CoozzyMultiselect'
import { contact } from '@/mixins/contact'
import CoozzyFormTag from '@/framework/components/form/input/CoozzyFormTag'
import CoozzyFormCountrySelect from '@/framework/components/form/select/CoozzyFormCountrySelect'
import CoozzyFormCountryCitizenshipSelect from '@/framework/components/form/select/CoozzyFormCountryCitizenshipSelect'
import CoozzyFormRegionSelect from '@/framework/components/form/select/CoozzyFormRegionSelect'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import { validation, isZipValid } from '@/mixins/validation'
import TicketApi from '@/misc/apis/TicketApi'
import AddressText from '@/framework/components/misc/AddressText'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'
import { address } from '@/mixins/address'
// Custom validator to check for 'UNDEFINED_GENDER'
const notUndefinedGender = (value) => value !== 'UNDEFINED_GENDER'

// Custom validator to check for 'UNDEFINED_CIVIL_STATUS'
const notUndefinedCivilStatus = (value) => value !== 'UNDEFINED_CIVIL_STATUS'

// Custom validator to check that phoneNumbers array is not empty and each object has required fields
const validPhoneNumbers = (phone) => {
  return phone.mobile !== '' || phone.work !== '' || phone.home1 !== '' || phone.home2 !== ''
}
// Custom validator to check for 'FOREIGN_CITIZENSHIP'
const notForeignCitizenship = (value) => value !== 'FOREIGN_CITIZENSHIP'

export default {
  name: 'CreateContactModal',
  components: {
    CoozzyInfoCircleIcon,
    AddressText,
    CoozzyFormTag,
    CoozzyFormCheckbox,
    CoozzyFormRegionSelect,
    CoozzyFormCountrySelect,
    CoozzyMultiselect,
    CoozzyFormInput,
    CoozzyButton,
    CoozzyFormTextarea,
    CoozzyFormRadio,
    CoozzyFormSelect,
    VueAutosuggest,
    CoozzyFormCountryCitizenshipSelect
  },
  mixins: [contact, user, algolia, routeChecks, validation, string, address],
  props: {
    modalId: {
      type: String,
      default: 'create-contact-modal'
    },
    forOwnerId: {
      type: Number,
      default: null
    },
    overrideTitle: {
      type: String,
      default: null
    },
    fromHandover: {
      type: String,
      default: ''
    },
    fromDigitalDeposit: {
      type: Boolean,
      default: false
    },
    contactToEdit: {
      type: Object,
      default: null
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    showNumericId: {
      type: Boolean,
      default: false
    },
    showRolesInput: {
      type: Boolean,
      default: true
    },
    setInterestedPartyRole: {
      type: Boolean,
      default: false
    },
    setTenantRole: {
      type: Boolean,
      default: false
    },
    showRequestSources: {
      type: Boolean,
      default: false
    },
    defaultRequestSource: {
      type: String,
      default: 'UNDEFINED_REQUEST_SOURCE'
    },
    showAddAnother: {
      type: Boolean,
      default: false
    },
    enableDelete: {
      type: Boolean,
      default: false
    },
    restrictNewContactTypeTo: {
      type: String,
      default: null
    },
    initialEmail: {
      type: String,
      default: ''
    },
    contactToHide: {
      type: Array,
      default: () => {
        return []
      }
    },
    listBuildings: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      isUsedOnTicket: false,
      salutationPlaceholder: this.renderSalutationPlaceholder(),
      salutationTooltip: this.renderSalutationTooltip(),
      searchQuery: '',
      loadingFinish: false,
      debounceTimeout: null,
      isCitiesSuggested: false,
      citiesValue: [],
      duplicateContacts: [],
      dates: {
        dateOfBirth: null,
        dateOfDeath: null,
        residenceStatusValidUntil: null
      },
      loading: false,
      rolesList: [
        'LANDLORD',
        'TENANT',
        'CARETAKER',
        'SERVICE_PROVIDER',
        'BANK',
        'INSURANCE',
        'REAL_ESTATE_MANAGER',
        'CRAFTSMAN',
        'AUTHORITY',
        'ACCOUNTANT',
        'MARKETER',
        'CONDOMINIUM_OWNER',
        'SUB_TENANT',
        'REPRESENTATION',
        'COOPERATIVE',
        'SALE',
        'EXTERNAL_DEBTOR',
        'GUARANTOR',
        'REVISOR',
        'DELEGATE',
        'BOARD'
      ],
      contactType: 'private',
      randomId: Date.now(),
      isInitialSetup: false,
      isNewContact: true,
      contact: {
        active: true,
        title: '',
        gender: 'UNDEFINED_GENDER',
        firstName: '',
        lastName: '',
        birthName: '',
        name: '',
        ahv: '',
        email: this.initialEmail,
        phone: {
          home1: '',
          home2: '',
          work: '',
          mobile: '',
          fax: ''
        },
        employment: {
          companyName: '',
          companyPosition: ''
        },
        dateOfBirth: null,
        dateOfDeath: null,
        website: '',
        salutationName: '',
        vatNumber: '',
        types: [],
        address: {
          city: '',
          state: '',
          street: '',
          streetNumber: '',
          zip: '',
          streetNo: '',
          country: '',
          careOf: '',
          mailbox: ''
        },
        ownerId: null,
        industries: [],
        note: '',
        tags: [],
        civilStatus: null,
        birthplace: '',
        nationality: '',
        residenceStatus: null,
        residenceStatusValidUntil: null,
        preferredCommunicationChannel: 'PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED',
        preferredLanguage: 'PREFERRED_LANGUAGE_UNDEFINED',
        workEmail: {
          email: '',
          use: false
        },
        includeInSerialEmails: false,
        portalAccess: false
      },
      request: {
        source: this.defaultRequestSource,
        marketplaceName: ''
      },
      initContact: null,
      ownerAccounts: [],
      addressToFill: '',
      addAnotherCheckbox: false,
      takeOverAddress: false,
      citySelect: null,
      tagsList: [],
      firstNameLength: 50,
      fiftyLength: 50,
      lastNameLength: 100,
      notificationPreferences: [],
      includeInSerialEmails: false,
      portalAccess: false
    }
  },
  computed: {
    canBeChangedOwner() {
      return this.contactToEdit?.tenancyIds?.length === 0 && this.contactToEdit?.condominiumIds?.length === 0 && this.listBuildings.length === 0 && !this.isUsedOnTicket
    },
    checkDateOfDeath() {
      // check if date of death is set and valid
      return !!(this.contact.dateOfDeath && this.contact.dateOfDeath.year && this.contact.dateOfDeath.month && this.contact.dateOfDeath.day)
    },
    zipLength() {
      const country = this.contact.address.country
      if (country === 'CH') {
        return 4
      } else if (country === 'DE') {
        return 5
      } else {
        return 15
      }
    },
    modalTitle() {
      if (this.overrideTitle) {
        return this.overrideTitle
      } else if (this.isNewContact) {
        return this.$t('message.contact.newContact')
      } else {
        return this.$t('message.contact.editContact')
      }
    },
    roles: {
      get() {
        return this.contact.types
? [...new Set(this.contact.types)].filter(x => x !== 'INTERESTED_PARTY').map(type => {
          return {
            label: this.$t('message.contact.types.' + type.toUpperCase()),
            value: type
          }
        })
: []
      },
      set(value) {
        this.contact.types = value.map(type => {
          return type.value
        })
      }
    },
    industries: {
      get() {
        return this.contact.industries.filter(x => x !== 'INDUSTRY_UNDEFINED_VALUE').map(industry => {
          return {
            label: this.$t('message.contact.industries.' + industry),
            value: industry
          }
        })
      },
      set(value) {
        this.contact.industries = value.map(industry => {
          return industry.value
        })
      }
    },
    translatedRolesList() {
      const array = []
      let rolesList = this.rolesList
      if (this.isPrivateContact) {
        rolesList = this.rolesList.filter(x => !['BANK', 'AUTHORITY', 'INSURANCE'].includes(x))
      }
      rolesList.forEach(role => {
        array.push({
          label: this.$t('message.contact.types.' + role.toUpperCase()),
          value: role
        })
      })
      array.sort((a, b) => {
        if (a.label.toLowerCase() < b.label.toLowerCase()) {
          return -1
        }
        if (a.label.toLowerCase() > b.label.toLowerCase()) {
          return 1
        }
        return 0
      })
      return array
    },
    translatedNotificationPreferencesList() {
      const array = []
      let listNotificationPreferences = ['includeInSerialEmail', 'portalAccess']
      listNotificationPreferences.forEach(item => {
        array.push({
          label: this.$t('message.contact.notificationPreferences.' + item),
          value: item
        })
      })
      return array
    },
    isPrivateContact() {
      return this.contactType === 'private'
    },
    isCompanyContact() {
      return this.contactType === 'company'
    },
    hasIndustry() {
      return this.contact.types.filter(type => ['SERVICE_PROVIDER', 'CRAFTSMAN'].includes(type)).length > 0
    },
    isInsertTenancy() {
      return this.$route.name === 'AdminAddTenancyView' || this.$route.name === 'AccountingAddTenancyView' || this.$route.name === 'MarketingAddTenancyView'
    },
    isAddressBook() {
      return ['ContactOverview', 'ContactDetailsView'].some(str => this.$route.name.includes(str))
    },
    isAddressRequired() {
      // We need an address if we're in process of adding a tenancy
      if (this.$route.name === 'AdminAddTenancyView' || this.$route.name === 'AccountingAddTenancyView' || this.$route.name === 'MarketingAddTenancyView') {
        return true
      }

      const typesRequiringAddress = this.contact.types
? this.contact.types.filter(type => {
        return [
          'TENANT',
          'CARETAKER',
          'SERVICE_PROVIDER',
          'BANK',
          'INSURANCE',
          'REAL_ESTATE_MANAGER',
          'CRAFTSMAN',
          'AUTHORITY',
          'ACCOUNTANT',
          'MARKETER',
          'CONDOMINIUM_OWNER',
          'REPRESENTATION',
          'EXTERNAL_DEBTOR'
        ].includes(type)
      })
: []
      if (typesRequiringAddress.length > 0) {
        return true
      }

      return this.contact.address && (this.contact.address.careOf !== '' ||
        this.contact.address.street !== '' ||
        this.contact.address.streetNumber !== '' ||
        this.contact.address.zip !== '' ||
        this.contact.address.city !== '' ||
        this.contact.address.state !== '')
    },
    isNationalitySet() {
      return this.contact.nationality !== ''
    },
    isSwiss() {
      return this.contact.nationality === 'CH'
    },
    residenceStatusValidUntil: {
      get() {
        if (this.contact.residenceStatusValidUntil) {
          return `${this.contact.residenceStatusValidUntil.year}-${this.contact.residenceStatusValidUntil.month.toString().padStart(2, '0')}-${this.contact.residenceStatusValidUntil.day.toString().padStart(2, '0')}`
        }

        return null
      },
      set(value) {
        this.contact.residenceStatusValidUntil = value
      }
    },
    dateOfBirth: {
      get() {
        if (this.contact.dateOfBirth) {
          return `${this.contact.dateOfBirth.year}-${this.contact.dateOfBirth.month.toString().padStart(2, '0')}-${this.contact.dateOfBirth.day.toString().padStart(2, '0')}`
        }

        return null
      },
      set(value) {
        this.contact.dateOfBirth = value
      }
    },
    dateOfDeath: {
      get() {
        if (this.contact.dateOfDeath) {
          return `${this.contact.dateOfDeath.year}-${this.contact.dateOfDeath.month.toString().padStart(2, '0')}-${this.contact.dateOfDeath.day.toString().padStart(2, '0')}`
        }

        return null
      },
      set(value) {
        this.contact.dateOfDeath = value
      }
    },
    useWorkEmail: {
      get() {
        return this.contact.workEmail?.use || false
      },
      set(value) {
        if (this.contact.workEmail) {
          this.contact.workEmail.use = value
        }
      }
    },
    workEmail: {
      get() {
        return this.contact.workEmail?.email
      },
      set(value) {
        if (this.contact.workEmail) {
          this.contact.workEmail.email = value
        }
      }
    },
    filterQuery() {
      let query = 'isOwner:false AND owner.id=' + this.forOwnerId + ' AND isEmployee:false '

      if (this.isAdminModule || this.isAccountingModule || this.isOwnerModule) {
        query += ' AND isOnlyInterestedParty:false'
      }

      return query
    }
  },
  watch: {
    checkDateOfDeath: {
      handler(newVal) {
        if (newVal) {
          this.contact.salutationName = ''
        }
      },
      deep: true
    },
    notificationPreferences: {
      handler(value) {
        this.includeInSerialEmails = !!value.find(item => item.value === 'includeInSerialEmail')
        this.portalAccess = !!value.find(item => item.value === 'portalAccess')
        this.$nextTick(() => {
          this.contact.includeInSerialEmails = this.includeInSerialEmails
          this.contact.portalAccess = this.portalAccess
        })
      }
    },
    contact: {
      handler(newVal) {
        if (this.loadingFinish) {
          if (!this.isInitialSetup) {
            if (this.initContact !== JSON.stringify(newVal)) {
              this.$store.dispatch('user/setChangedModal', true)
            } else {
              this.$store.dispatch('user/setChangedModal', false)
            }
          }
        } else {
          this.isInitialSetup = false
          this.initContact = JSON.stringify(newVal)
        }
      },
      deep: true
    },
    'contact.nationality': {
      handler(newVal, oldVal) {
        if (oldVal !== newVal && newVal === 'CH' && this.loadingFinish) {
          this.contact.residenceStatus = 'UNDEFINED_RESIDENCE_STATUS'
          this.residenceStatusValidUntil = null
        }
      },
      deep: true
    },
    'contact.address.country': {
      handler(oldVal, newVal) {
        if (oldVal !== newVal && this.loadingFinish) {
          this.contact.address.zip = ''
        }
      },
      deep: true
    },
    'contact.address.zip': {
      handler(newVal, oldVal) {
        if (newVal === '' && oldVal !== '' && this.loadingFinish) {
          this.contact.address.country = this.contact.address.country !== 'CH' ? this.contact.address.country : 'CH'
          this.contact.address.state = ''
          this.contact.address.city = ''
        }
      },
      deep: true
    },
    'contact.address.state': {
      handler(newVal, oldVal) {
        if (newVal === '' && oldVal !== '' && this.loadingFinish) {
          this.contact.address.state = oldVal
        }
      },
      deep: true
    },
    'contact.preferredLanguage': {
      handler() {
        this.renderSalutationName()
      },
      deep: true
    },
    'contact.gender': {
      handler() {
        this.renderSalutationName()
      },
      deep: true
    },
    'contact.lastName': {
      handler() {
        this.renderSalutationName()
      },
      deep: true
    },
    'contact.firstName': {
      handler() {
        this.renderSalutationName()
      },
      deep: true
    },
    contactType: {
      handler() {
        this.renderSalutationName()
      }
    }
  },
  mounted() {
    this.loadInstantSearch()
    this.loadOwnerAccounts()
    this.getDatesFromObject()
    this.loadTags()
    if (this.contactToEdit) {
    TicketApi.ticketServiceIsContactInUse(this.contactToEdit.id)
      .then(response => {
        this.isUsedOnTicket = response.inUse
      })
      .catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.ticket'), { type: 'error' })
      })
    }
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === this.modalId) {
        this.$nextTick(() => {
          this.$store.dispatch('user/setChangedModal', false)
          this.reset()
          if (this.restrictNewContactTypeTo) {
            this.contactType = this.restrictNewContactTypeTo
          }
          if (this.contactToEdit) {
            const emptyNationality = this.fromDigitalDeposit && this.contactToEdit.nationality === 'FOREIGN_CITIZENSHIP'
            this.setContactToEdit(this.contactToEdit, emptyNationality)
            this.$nextTick(() => {
              this.$store.dispatch('user/setChangedModal', false)
              this.includeInSerialEmails = this.contactToEdit.includeInSerialEmails
              this.portalAccess = this.contactToEdit.portalAccess
              this.setValuesNotificationPreferences()
            })
          } else {
            this.setUserpreferredLanguage()
            this.loadingFinish = true
            this.$nextTick(() => {
              this.includeInSerialEmails = true
              this.portalAccess = true
              this.setValuesNotificationPreferences()
            })
          }
        })
      }
    })
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === this.modalId) {
        this.$nextTick(() => {
          this.$emit('modal-hide', true)
        })
      }
      if (modalId === 'duplicated-contact-confirmation-modal') {
        this.loading = false
      }
    })
  },
  methods: {
    setValuesNotificationPreferences() {
      if (this.includeInSerialEmails) {
        this.notificationPreferences.push({
          label: this.$t('message.contact.notificationPreferences.includeInSerialEmail'),
          value: 'includeInSerialEmail'
        })
      }
      if (this.portalAccess) {
        this.notificationPreferences.push({
          label: this.$t('message.contact.notificationPreferences.portalAccess'),
          value: 'portalAccess'
        })
      }
    },
    suggestionSelected(item) {
      this.contact.tags.push(item)
    },
    suggestionRemoved(item) {
      this.contact.tags = this.contact.tags.filter(x => x !== item)
    },
    checkLenght(field) {
      if (field === 'firstname' && this.contact.firstName.length > this.firstNameLength) {
        this.$nextTick(() => {
          this.contact.firstName = this.contact.firstName.substring(0, this.firstNameLength)
        })
      }
      if (field === 'lastname' && this.contact.lastName.length > this.lastNameLength) {
        this.$nextTick(() => {
          this.contact.lastName = this.contact.lastName.substring(0, this.lastNameLength)
        })
      }
      if (field === 'title' && this.contact.title.length > this.fiftyLength) {
        this.$nextTick(() => {
          this.contact.title = this.contact.title.substring(0, this.fiftyLength)
        })
      }
    },
    cityChanged(event) {
      this.contact.address.city = event.city
      this.contact.address.state = event.state
    },
    tabClicked() {
      if (this.citiesValue.length > 0) {
        this.citySelect = this.citiesValue[0]
        this.contact.address.city = this.citiesValue[0].city
        this.contact.address.state = this.citiesValue[0].state
      }
    },
    async getCityFromZip() {
      if (this.contact.address.zip.length > 3 && this.contact.address.country === 'CH') {
        const zipData = await this.getZipInfo(this.contact.address.zip)
        if (zipData) {
          this.contact.address.state = zipData.state
          if (zipData.cities.length > 1) {
            this.isCitiesSuggested = true
            this.citiesValue = zipData.cities
          } else {
            this.isCitiesSuggested = false
            this.$nextTick(() => {
              this.contact.address.city = zipData.cities[0].city
            })
          }
        }
      }
    },
    indicesToSuggestions(indices) {
      const contactToHide = this.contactToHide.flatMap(x => x.id)
      return indices.map(({ hits }) => ({
        data: hits.filter(hit => {
          const tenancyCdt = !(hit.isOnlyInterestedParty && this.isInsertTenancy)
          return hit.active && !contactToHide.includes(hit.objectID) && tenancyCdt
        })
      }))
    },
    onSuggestionInput(refine) {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }

      this.resetContact(false)
      this.debounceTimeout = setTimeout(() => {
        this.debounceTimeout = null
        refine(this.searchQuery)
      }, 300)
    },
    onSuggestionSelected(suggestion) {
      if (suggestion) {
        const selectedContactId = suggestion.item.objectID
        ContactApi.contactResolve([selectedContactId])
          .then(response => {
            let selectedContact
            if (response.persons.length > 0) {
              selectedContact = response.persons.find(p => p.id === selectedContactId)
            }
            if (response.companies.length > 0 && !selectedContact) {
              selectedContact = response.companies.find(c => c.id === selectedContactId)
            }

            if (selectedContact) {
              this.setContactToEdit(selectedContact)
            }
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
          })
      }
    },
    loadOwnerAccounts() {
      ShareApi.listByTargetAccountId(this.accountId)
        .then(response => {
          const listSourceIds = []
          response.shares.forEach(share => {
            if (!listSourceIds.includes(share.sourceAccountId)) {
              listSourceIds.push(share.sourceAccountId)
            }
          })
          if (listSourceIds.length > 0) {
            UserApi.listAccountsByIds(listSourceIds)
              .then(response => {
                // on the view, we show company.name if company !== null or we show individual.name if individual !== null, we need to sort by this name
                this.ownerAccounts = response.accounts.sort((a, b) => {
                  const nameA = a.company ? a.company.name : a.individual.name || ''
                  const nameB = b.company ? b.company.name : b.individual.name || ''
                  if (nameA.toLowerCase() < nameB.toLowerCase()) {
                    return -1
                  }
                  if (nameA.toLowerCase() > nameB.toLowerCase()) {
                    return 1
                  }
                  return 0
                })
              })
          }
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.owners'), { type: 'error' })
        })
    },
    setContactToEdit(contact, emptyNationality = false) {
      this.loadingFinish = false
      this.$nextTick(() => {
        const copy = JSON.parse(JSON.stringify(contact))

        const phoneNumbers = copy && copy.phoneNumbers ? copy.phoneNumbers : null
        if (phoneNumbers) {
          const homeNumbersCount = phoneNumbers.filter(element => element.type === 'HOME').length

          copy.phone = {
            home1: this.getPhoneNumbers(phoneNumbers, 'HOME') !== '-' ? this.getPhoneNumbers(phoneNumbers, 'HOME') : '',
            home2: homeNumbersCount >= 2 ? phoneNumbers.filter(phone => phone.type === 'HOME')[1].number : '',
            mobile: this.getPhoneNumbers(phoneNumbers, 'MOBILE') !== '-' ? this.getPhoneNumbers(phoneNumbers, 'MOBILE') : '',
            work: this.getPhoneNumbers(phoneNumbers, 'WORK') !== '-' ? this.getPhoneNumbers(phoneNumbers, 'WORK') : '',
            fax: this.getPhoneNumbers(phoneNumbers, 'FAX') !== '-' ? this.getPhoneNumbers(phoneNumbers, 'FAX') : ''
          }
        }

        this.isNewContact = false
        this.contactType = copy.contactPersons ? 'company' : 'private'
        if (!copy.workEmail) {
          copy.workEmail = {
            use: false,
            email: ''
          }
        }
        this.contact = copy
        if (this.contact && this.contact.objectID && ('owner' in this.contact) && !this.contact.ownerId) {
          this.contact.id = this.contact.objectID
          this.contact.ownerId = this.contact.owner.id
          if (this.contact.gender) {
            this.contact.gender = this.contact.gender.toUpperCase()
          }
          this.contact.types = this.contact.types.map(x => x.toUpperCase())
        }
        if (emptyNationality) {
          this.contact.nationality = ''
        }
        this.getDatesFromObject()
        this.$nextTick(() => {
          this.loadingFinish = true
          this.contact.address = copy.address || {}
        })
      })
    },
    fillInAddress() {
      let a = this.addressToFill
      let dateOfBirth, website, email, mobilePhone, stationaryPhone, title, firstLineOfName, secondLineOfName,
        firstName, lastName, street, numberStreet, zipcode, city, splitDate
      if (a) a = a.replace(/\t/g, '\n').replace('\n\n', '\n').split('\n')
      for (let index = 0; index < a.length; index++) {
        a[index] = a[index].replace(/(^\s+|\s+$)/g, '')
      }
      if (a && a[0]) {
        for (let index = 0; index < a.length; index++) {
          if (a[index].search(/[A-Za-z]/) !== -1 && a[index].search(/\d+/g) === -1 && a[index].search(' ') !== -1) {
            if (a[index].startsWith('Herr ') || a[index].startsWith('Frau ')) {
              if (a[index].startsWith('Herr')) {
                a[index] = a[index].replace('Herr ', '').replace(/(^\s+|\s+$)/g, '')
                this.contact.gender = 'MALE'
              }
              if (a[index].startsWith('Frau')) {
                a[index] = a[index].replace('Frau ', '').replace(/(^\s+|\s+$)/g, '')
                this.contact.gender = 'FEMALE'
              }
              if (a[index].search('.') !== -1) {
                title = a[index].slice(0, (a[index].lastIndexOf('.') + 1))
                a[index] = a[index].replace(title, '').replace(/(^\s+|\s+$)/g, '')
              }
            }
            if (firstLineOfName) {
              secondLineOfName = a[index]
            } else {
              firstLineOfName = a[index]
            }
          }
          if (a[index].search(/^[A-Za-z]/) !== -1 && a[index].search(/\d+/g) !== -1) {
            street = a[index].slice(0, a[index].search(/\d/)).replace(/(^\s+|\s+$)/g, '')
            numberStreet = a[index].replace(street, '').replace(/(^\s+|\s+$)/g, '')
          } else if (a[index].search(/[A-Za-z]/) !== -1 && a[index].search(/^\d{4}/g) !== -1) {
            zipcode = a[index].slice(0, a[index].search(' '))
            city = a[index].replace(zipcode, '').replace(/(^\s+|\s+$)/g, '')
          } else if (a[index].search('@') !== -1) {
            email = a[index]
          } else if (a[index].search(/.{1,2}\..{1,2}\...../) !== -1) {
            dateOfBirth = a[index]
          } else if (a[index].search(/\.[a-zA-Z]+/g) !== -1 && a[index].search(' ') === -1) {
            website = a[index]
          } else if (a[index].search(/\d+/g) !== -1 && a[index].search(/[A-Za-z]/) === -1) {
            if (a[index].startsWith('0')) {
              a[index] = a[index].replace('0', '+41')
            }
            if (a[index].startsWith('+4174') || a[index].startsWith('+4175') || a[index].startsWith('+4176') || a[index].startsWith('+4177') || a[index].startsWith('+4178') || a[index].startsWith('+4179')) {
              mobilePhone = a[index]
            } else {
              stationaryPhone = a[index]
            }
          }
        }
        if (street) this.contact.address.street = street
        if (numberStreet) this.contact.address.streetNumber = numberStreet
        if (zipcode) this.contact.address.zip = zipcode
        if (city) this.contact.address.city = city
        if (website) this.contact.website = website
        if (email) this.contact.email = email
        if (mobilePhone) this.contact.phone.mobile = mobilePhone

        this.getCityFromZip()
          .then(() => {
            if (this.citiesValue?.length) {
              if (this.citiesValue.some(e => e.city === city)) {
                const index = this.citiesValue.findIndex(e => e.city === city)
                this.citySelect = this.citiesValue[index]
                this.contact.address.state = this.citiesValue[index].state
              } else {
                let highestSimilarity = 0.2
                let highestIndex = -1
                const that = this
                this.citiesValue.forEach(function (value, i) {
                  const similarity = that.similarity(city, that.citiesValue[i].city)
                  if (similarity > highestSimilarity) {
                    highestSimilarity = similarity
                    highestIndex = i
                  }
                })
                if (highestIndex > -1) {
                  this.citySelect = this.citiesValue[highestIndex]
                  this.contact.address.state = this.citiesValue[highestIndex].state
                }
              }
            }
          })

        if (this.contactType === 'private') {
          this.contact.title = title
          if (dateOfBirth) {
            splitDate = dateOfBirth.split('.')
            if (splitDate.length === 3) {
              this.contact.dateOfBirth = {
                year: splitDate[2],
                month: ('0' + splitDate[1]).slice(-2),
                day: ('0' + splitDate[0]).slice(-2)
              }
            }
          }
          if (stationaryPhone) this.contact.phone.home1 = stationaryPhone
          if (firstLineOfName) {
            firstName = firstLineOfName.slice(0, firstLineOfName.search(' '))
            lastName = firstLineOfName.replace(firstName, '').replace(/^\s+/g, '')
          }
          if (firstName && !this.contact.firstName) this.contact.firstName = firstName
          if (lastName && !this.contact.lastName) this.contact.lastName = lastName
        } else {
          if (!this.contact.name) {
            secondLineOfName ? this.contact.name = firstLineOfName + ' ' + secondLineOfName : this.contact.name = firstLineOfName
          }
          if (stationaryPhone) this.contact.phone.work = stationaryPhone
        }
      } else {
        Vue.toasted.show(this.$t('message.loadingErrors.noaddressToFill'), { type: 'error' })
      }
    },
    saveClicked() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.loading = true
        if (this.isNewContact) {
          this.checkDuplicates()
        } else {
          this.updateContact()
        }
      } else {
        setTimeout(() => {
          const domRect = this.$refs[this.modalId].$refs.content.querySelector('select.is-invalid') || this.$refs[this.modalId].$refs.content.querySelector('input.is-invalid')
          if (domRect) {
            window.scrollTo(
              domRect.left + document.documentElement.scrollLeft,
              domRect.top + document.documentElement.scrollTop - 200
            )
            domRect.focus()
          }
        }, 1000)
      }
    },
    prepareRolesBeforeSaving() {
      if (!this.contact.types.includes('INTERESTED_PARTY') && this.setInterestedPartyRole) {
        this.isInitialSetup = true
        this.contact.types.push('INTERESTED_PARTY')
      }
      if (!this.contact.types.includes('TENANT') && this.setTenantRole) {
        this.isInitialSetup = true
        this.contact.types.push('TENANT')
      }
    },
    redirectToContactDetail(id) {
      let routeData = null
      if (this.isOwnerModule) {
        routeData = this.$router.resolve({ name: 'OwnerContactDetailsView', params: { id: id } })
      } else if (this.isAdminModule) {
        routeData = this.$router.resolve({ name: 'AdminContactDetailsView', params: { id: id } })
      } else if (this.isAssetModule) {
        routeData = this.$router.resolve({ name: 'AssetContactDetailsView', params: { id: id } })
      } else if (this.isAccountingModule) {
        routeData = this.$router.resolve({ name: 'AccountingContactDetailsView', params: { id: id } })
      } else {
        routeData = this.$router.resolve({ name: 'MarketingContactDetailsView', params: { id: id } })
      }
      window.open(routeData.href, '_blank')
    },
    checkDuplicates() {
      const phones = []
      const phoneData = this.contact.phone

      Object.keys(phoneData).forEach(function (key) {
        phones.push(phoneData[key])
      })
      ContactApi.checkDuplicates(([this.contact.email].concat(this.contact.workEmail.email)).filter(function (e) { return e }), this.contact.firstName, this.contactType === 'company', this.contactType === 'company' ? this.contact.name : this.contact.lastName, this.contact.ownerId, this.contact.address, phones.filter(function (e) { return e }))
        .then(response => {
          const allContacts = response.persons.concat(response.companies)
          if (allContacts.length > 0) {
            this.duplicateContacts = allContacts
            this.$refs['duplicated-contact-confirmation-modal'].show()
          } else {
            this.createContact()
          }
        })
    },
    createContact() {
      let addAnotherCheckboxRestore = false
      let takeOverAddressRestore = false
      addAnotherCheckboxRestore = this.addAnotherCheckbox
      takeOverAddressRestore = this.takeOverAddress
      this.prepareRolesBeforeSaving()
      this.$bvModal.hide('duplicated-contact-confirmation-modal')

      ContactApi.addContact(this.contact, this.contact.ownerId, this.contactType)
        .then(response => {
          const contact = response.person ? response.person : response.company
          this.$emit(
            'contact-created',
            {
              contact: contact,
              addingAnother: this.addAnotherCheckbox,
              takeOverAddress: this.takeOverAddress,
              requestData: this.showRequestSources
                ? {
                  source: this.request.source,
                  marketplaceName: this.request.marketplaceName
                }
                : undefined
            }
          )
          this.reset(false, this.addAnotherCheckbox, this.takeOverAddress)
          if (this.addAnotherCheckbox === false && this.takeOverAddress === false) {
            this.hideModal()
          }
        })
        .catch(e => {
          console.log(e)
          this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
          Vue.toasted.show(this.$t('message.savingErrors.addContact'), { type: 'error' })
        })
        .finally(() => {
          if (addAnotherCheckboxRestore) {
            this.addAnotherCheckbox = true
          }
          if (takeOverAddressRestore) {
            this.takeOverAddress = true
          }
          this.loading = false
        })
    },
    updateContact() {
      this.prepareRolesBeforeSaving()
      if (this.contact && this.contact.lastName && this.contact.firstName && this.contact.name !== (this.contact.lastName + ' ' + this.contact.firstName)) {
        this.contact.name = this.contact.lastName + ' ' + this.contact.firstName
      }
      ContactApi.editContact(this.contact)
        .then(response => {
          const contact = response.person ? response.person : response.company
          this.$emit(
            'contact-updated',
            {
              contact: contact,
              addingAnother: this.addAnotherCheckbox,
              takeOverAddress: this.takeOverAddress,
              requestData: this.showRequestSources
                ? {
                  source: this.request.source,
                  marketplaceName: this.request.marketplaceName
                }
                : undefined
            }
          )
          this.reset()
          this.$store.dispatch('user/setChangedModal', false)
          this.$nextTick(() => {
            this.hideModal()
          })
        })
        .catch(e => {
          console.log(e)
          if (e.response && e.response.data && e.response.data.code === 9) {
            Vue.toasted.show(this.$t('message.savingErrors.cantEditEmployee'), { type: 'error' })
          } else {
            Vue.toasted.show(this.$t('message.savingErrors.editContact'), { type: 'error' })
          }
          this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
        })
        .finally(() => {
          this.loading = false
        })
    },
    dateToObject() {
      if (this.dates.dateOfBirth !== null && this.dates.dateOfBirth !== '') {
        const dateOfBirth = new Date(this.dates.dateOfBirth)
        this.dateOfBirth = {
          year: dateOfBirth.getFullYear(),
          month: dateOfBirth.getMonth() + 1,
          day: dateOfBirth.getDate()
        }
      } else {
        this.dateOfBirth = null
      }
      if (this.dates.dateOfDeath !== null && this.dates.dateOfDeath !== '') {
        const dateOfDeath = new Date(this.dates.dateOfDeath)
        this.dateOfDeath = {
          year: dateOfDeath.getFullYear(),
          month: dateOfDeath.getMonth() + 1,
          day: dateOfDeath.getDate()
        }
      } else {
        this.dateOfDeath = null
      }
      if (this.dates.residenceStatusValidUntil !== null && this.dates.residenceStatusValidUntil !== '') {
        const residenceStatusValidUntil = new Date(this.dates.residenceStatusValidUntil)
        this.residenceStatusValidUntil = {
          year: residenceStatusValidUntil.getFullYear(),
          month: residenceStatusValidUntil.getMonth() + 1,
          day: residenceStatusValidUntil.getDate()
        }
      } else {
        this.residenceStatusValidUntil = null
      }
    },
    hide() {
      this.$refs[this.modalId].hide()
    },
    show() {
      this.$refs[this.modalId].show()
    },
    getDatesFromObject: function () {
      this.dates.dateOfBirth = this.contact.dateOfBirth ? `${this.contact.dateOfBirth.year}-${this.contact.dateOfBirth.month.toString().padStart(2, '0')}-${this.contact.dateOfBirth.day.toString().padStart(2, '0')}` : null
      this.dates.dateOfDeath = this.contact.dateOfDeath ? `${this.contact.dateOfDeath.year}-${this.contact.dateOfDeath.month.toString().padStart(2, '0')}-${this.contact.dateOfDeath.day.toString().padStart(2, '0')}` : null
      this.dates.residenceStatusValidUntil = this.contact.residenceStatusValidUntil ? `${this.contact.residenceStatusValidUntil.year}-${this.contact.residenceStatusValidUntil.month.toString().padStart(2, '0')}-${this.contact.residenceStatusValidUntil.day.toString().padStart(2, '0')}` : null
    },
    deactivateContact() {
      this.loading = true
      ContactApi.deactivateContact([this.contactToEdit.id])
        .then(() => {
          setTimeout(() => {
            this.hideConfirmationModal()
            this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
            this.hideModal()
            this.loading = false
            this.contactToEdit.active = false
            Vue.toasted.show(this.$t('message.successMessages.contactInactivated'), { type: 'success' })
          }, 2000)
        })
        .catch(e => {
          console.log(e)
          if (e.response.status === 400 && e.response.data.code === 9) {
            Vue.toasted.show(this.$t('message.deleteErrors.contactisActiveTenancy'), { type: 'error' })
          } else {
            Vue.toasted.show(this.$t('message.deleteErrors.contact'), { type: 'error' })
          }
          this.hideConfirmationModal()
          this.loading = false
        })
    },
    activateContact() {
      this.loading = true
      ContactApi.activateContact([this.contactToEdit.id])
        .then(() => {
          setTimeout(() => {
            this.hideConfirmationModal()
            this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
            this.hideModal()
            this.loading = false
            this.contactToEdit.active = true
            Vue.toasted.show(this.$t('message.successMessages.contactActivated'), { type: 'success' })
          }, 2000)
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.contact'), { type: 'error' })
          this.hideConfirmationModal()
          this.loading = false
        })
    },
    hideModal() {
      if (this.$store.getters['user/hasChangedModal'] && this.fromHandover !== '') {
        this.$bvModal.show('global-confirmation-modal-1')
      } else {
        this.$bvModal.hide(this.modalId)
      }
    },
    closeCurrentModal() {
      this.$bvModal.hide('global-confirmation-modal-1')
      this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
      this.$bvModal.hide(this.modalId)
    },
    hideConfirmationModal() {
      this.$bvModal.hide('delete-contact-confirmation-modal')
    },
    reset(resetType = true, addingAnother = false, takeOverAddress = false) {
      this.$store.dispatch('user/setChangedModal', false)
      this.searchQuery = ''
      this.request.source = this.defaultRequestSource
      this.request.marketplaceName = ''
      this.addressToFill = ''
      this.resetContact(resetType, addingAnother, takeOverAddress)
    },
    resetContact(resetType = true, addingAnother = false, takeOverAddress = false) {
      let previousAddedContact
      if (takeOverAddress || addingAnother) {
        previousAddedContact = this.contact
      }

      if (this.$v) {
        this.$v.$reset()
      }

      if (resetType) {
        this.contactType = 'private'
      }

      // On reset we set isInitialSetup twice. Once before resetting the contact object and once after
      this.isInitialSetup = true
      this.isNewContact = true
      this.contact = {
        active: true,
        title: '',
        gender: 'UNDEFINED_GENDER',
        firstName: '',
        lastName: '',
        birthName: '',
        name: '',
        ahv: '',
        email: this.initialEmail,
        phone: {
          home1: '',
          home2: '',
          work: '',
          mobile: '',
          fax: ''
        },
        employment: {
          companyName: '',
          companyPosition: ''
        },
        dateOfBirth: null,
        dateOfDeath: null,
        website: '',
        salutationName: '',
        vatNumber: '',
        types: [],
        address: {
          city: '',
          state: '',
          street: '',
          streetNumber: '',
          zip: '',
          streetNo: '',
          country: 'CH',
          careOf: '',
          mailbox: ''
        },
        ownerId: this.forOwnerId ? this.forOwnerId : null,
        industries: [],
        note: '',
        tags: [],
        civilStatus: null,
        birthplace: '',
        nationality: '',
        residenceStatus: null,
        residenceStatusValidUntil: null,
        preferredCommunicationChannel: 'PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED',
        preferredLanguage: 'PREFERRED_LANGUAGE_UNDEFINED',
        workEmail: {
          email: '',
          use: false
        }
      }

      this.dates = {
        dateOfBirth: null,
        dateOfDeath: null,
        residenceStatusValidUntil: null
      }
      this.randomId = Date.now()
      this.isInitialSetup = true

      if (addingAnother) {
        this.contact.ownerId = previousAddedContact.ownerId
        this.setUserpreferredLanguage()
      }
      const isCitiesSuggested = this.isCitiesSuggested
      const citiesValue = this.citiesValue

      this.citiesValue = []
      this.isCitiesSuggested = false

      if (addingAnother && takeOverAddress && previousAddedContact.address) {
        this.contact.address.street = previousAddedContact.address.street
        this.contact.address.streetNumber = previousAddedContact.address.streetNumber
        this.contact.address.zip = previousAddedContact.address.zip
        this.contact.address.city = previousAddedContact.address.city
        this.contact.address.country = previousAddedContact.address.country
        this.contact.address.state = previousAddedContact.address.state
        this.contact.address.streetNumber = previousAddedContact.address.streetNumber
        if (isCitiesSuggested) {
          this.isCitiesSuggested = isCitiesSuggested
          this.citiesValue = citiesValue
        }
      }
      if (addingAnother && !takeOverAddress) {
        this.citySelect = null
      }
    },
    renderSalutationName() {
      let salutationPrefix = ''
      let salutationFinal = ''
      if ((this.contact.lastName && this.contact.lastName !== '') || (this.contact.gender && this.contact.gender !== 'UNDEFINED_GENDER')) {
        if (this.contactType === 'private') {
          if (this.contact.gender === 'MALE') {
            salutationPrefix = this.$t('message.contact.salutation.prefixIndividualMale')
            salutationFinal = salutationPrefix + ' ' + this.contact.lastName
          } else if (this.contact.gender === 'FEMALE') {
            salutationPrefix = this.$t('message.contact.salutation.prefixIndividualFemale')
            salutationFinal = salutationPrefix + ' ' + this.contact.lastName
          } else {
            salutationPrefix = this.$t('message.contact.salutation.prefixIndividualOther')
            salutationFinal = salutationPrefix + ' ' + (this.contact.firstName !== '' ? this.contact.firstName + ' ' : '') + this.contact.lastName
          }
        }
      }
      const addExampleStringToPlaceholder = !(this.contact.lastName || (this.contact.gender && this.contact.gender !== 'UNDEFINED_GENDER'))
      this.salutationTooltip = this.renderSalutationTooltip(this.isPrivateContact, salutationFinal)
      this.salutationPlaceholder = this.renderSalutationPlaceholder(this.isPrivateContact, salutationFinal, addExampleStringToPlaceholder)
    },
    loadTags() {
      ContactApi.getUsedTags(this.accountId)
        .then(response => {
          this.tagsList = response.tags
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        })
    },
    setUserpreferredLanguage() {
      const userPreferredLanguage = this.currentLanguage
      if (userPreferredLanguage === 'en') {
        this.contact.preferredLanguage = 'PREFERRED_LANGUAGE_ENGLISH'
      } else if (userPreferredLanguage === 'it') {
        this.contact.preferredLanguage = 'PREFERRED_LANGUAGE_ITALIAN'
      } else if (userPreferredLanguage === 'fr') {
        this.contact.preferredLanguage = 'PREFERRED_LANGUAGE_FRENCH'
      } else {
        this.contact.preferredLanguage = 'PREFERRED_LANGUAGE_GERMAN'
      }
    }
  },
  validations() {
    const validations = {
      contact: {
        ownerId: {
          required
        },
        email: {
          email
        }
      }
    }

    if (this.isAddressRequired) {
      validations.contact.address = {
        state: {
          required
        },
        city: {
          required
        },
        zip: {
          required,
          isZipValid: isZipValid(this.contact.address)
        },
        country: {
          required
        }
      }
    }

    if (this.isPrivateContact) {
      // Through external platforms it can happen that already existing contacts don't have their name split up
      // into first and lastName, but only a name field. For UX purpose we don't force the user to split this up
      // manually
      if (this.contactToEdit && this.contact.firstName === '' && this.contact.lastName === '') {
        validations.contact.name = {
          required
        }
      } else {
        validations.contact.firstName = {
          required
        }
        validations.contact.lastName = {
          required
        }
      }
      if (this.useWorkEmail === true) {
        validations.workEmail = {
          required,
          email
        }
      }
    }

    if (this.isCompanyContact) {
      validations.contact.name = {
        required
      }
      validations.contact.vatNumber = {
        maxLength: maxLength(20)
      }
    }
    validations.contact.website = {
      maxLength: maxLength(65)
    }
    validations.contact.ahv = {
      maxLength: maxLength(50)
    }
    if (this.showRequestSources) {
      validations.request = {
        source: {
          sourceRequired: (value) => {
            return value !== 'UNDEFINED_REQUEST_SOURCE' && value !== ''
          }
        },
        marketplaceName: {
          required
        }
      }
    }
    if (this.fromDigitalDeposit) {
      validations.contact = {
        firstName: {
          required
        },
        lastName: {
          required
        },
        email: {
          required
        },
        nationality: {
          required,
          notForeignCitizenship
        },
        dateOfBirth: {
          required
        },
        gender: {
          required,
          notUndefinedGender
        },
        civilStatus: {
          required,
          notUndefinedCivilStatus
        },
        phone: {
          required,
          validPhoneNumbers
        },
        address: {
          street: {
            required
          },
          streetNumber: {
            required
          }
        }
      }
    }

    return validations
  }
}
</script>

<style lang="scss" scoped>
:deep(.multiselect--disabled .multiselect__tags) {
  background-color: #ced4da;
  opacity: 1;
  border: 1px solid #ced4da;
}
.alignUseEmail {
  align-self: center;
  .custom-checkbox  {
    margin-top: 20px;
  }
}
</style>
