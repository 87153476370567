import { render, staticRenderFns } from "./AdminMarketingObjectsView.vue?vue&type=template&id=65d8f27e&scoped=true"
import script from "./AdminMarketingObjectsView.vue?vue&type=script&lang=js"
export * from "./AdminMarketingObjectsView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65d8f27e",
  null
  
)

export default component.exports