<template>
  <section :class="[readOnly ? 'is-disabled' : '']">
    <coozzy-card class="global-card no-top-border">
      <building-basic
        ref="building-basic"
        :building="building"
        :read-only="readOnly" />
    </coozzy-card>
    <coozzy-card class="global-card">
      <characteristics
        ref="characteristics"
        :building="building"
        :title="$t('message.onBoarding.createBuilding.features.adminBase')"
        :read-only="readOnly"
        :expand-by-default="false"
        @value-updated="buildingCharacteristicsUpdated" />
    </coozzy-card>
    <coozzy-card
      v-if="!isJanitor"
      class="global-card">
      <building-distances
        ref="building-distances"
        :building="building"
        :read-only="readOnly"
        :expand-by-default="false"
        @value-updated="buildingDistanceUpdated" />
    </coozzy-card>
    <coozzy-card class="global-card">
      <div
        id="rooms"
        v-b-toggle.collapse-room
        class="collapse-title">
        <h5 class="cursor-pointer">
          {{ $t('message.onBoarding.room.title') }} ({{ sortedBuildingRooms.length }})
        </h5>
      </div>
      <div class="col-12 p-0">
        <b-collapse
          id="collapse-room"
          :visible="openedCollapses.includes('collapse-room') || expandRoomId !== ''">
          <div
            v-if="sortedBuildingRooms.length === 0 && readOnly"
            class="row mt-3">
            <div class="col-12">
              <h6>
                {{ $t('message.nonExistent.room') }}
              </h6>
            </div>
          </div>
          <template>
            <div
              v-for="(room, roomIndex) in sortedBuildingRooms"
              :id="`room-i-${roomIndex}`"
              :key="`room-${room.id || room.internalId}`"
              class="row">
              <room
                :id="`room_${room.id || room.internalId}`"
                :key="`room-${room.id || room.internalId}`"
                ref="room"
                :index="roomIndex"
                :target="'building'"
                :read-only="readOnly"
                :room="room"
                :add-new-document="room.id && roomIdNewDocument !== '' && roomIdNewDocument === room.id"
                :expand-by-default="('isNewItem' in room) && ((roomIndex + 1) === sortedBuildingRooms.length) || expandRoomId === room.id" />
            </div>
          </template>
          <div class="row mt-3">
            <div class="col-2 mt-1">
              <coozzy-button
                class="w-100"
                design="prop-green"
                @click="addBuildingRoom">
                {{ $t('message.onBoarding.room.add') }}
              </coozzy-button>
            </div>
          </div>
        </b-collapse>
      </div>
    </coozzy-card>

    <coozzy-card class="global-card">
      <div
        id="devices"
        v-b-toggle.collapse-device
        class="collapse-title">
        <h5 class="cursor-pointer">
          {{ $t('message.onBoarding.createBuilding.device') }} ({{ buildingDevices.length }})
        </h5>
      </div>
      <div class="col-12 p-0">
        <b-collapse
          id="collapse-device"
          :visible="openedCollapses.includes('collapse-device')"
          @show="renderDevicesAnimation=true"
          @shown="renderDevices=true"
          @hidden="renderDevices = false">
          <devices-by-room-tab
            :target="'building'"
            :devices="buildingDevices"
            :rooms="buildingRooms"
            :read-only="readOnly"
            :render-devices="renderDevices"
            :loading="renderDevicesAnimation"
            :owner-id="building?.ownerId"
            @duplicateDevice="duplicateDevice"
            @addDevice="addNewBuildingDevice" />
        </b-collapse>
      </div>
    </coozzy-card>
    <coozzy-card class="global-card">
      <div
        v-b-toggle.collapse-energySource
        class="collapse-title">
        <h5 class="cursor-pointer">
          {{ $t('message.onBoarding.createBuilding.energySource') }} ({{ buildingEnergySources.length }})
        </h5>
      </div>
      <div class="col-12 p-0">
        <b-collapse
          id="collapse-energySource"
          :visible="openedCollapses.includes('collapse-energySource')">
          <div
            v-if="buildingEnergySources.length === 0 && readOnly"
            class="row mt-3">
            <div class="col-12">
              <h6>
                {{ $t('message.nonExistent.energySource') }}
              </h6>
            </div>
          </div>
          <div
            v-for="(item, index) in buildingEnergySources"
            :id="`energySource-building-${ index }`"
            :key="`energySource-building-${ index }`"
            class="row">
            <energy-source
              ref="energy-source"
              :key="`energySource-building-${index}`"
              :read-only="readOnly"
              :index="index"
              :energy-source="item"
              :target="'building'"
              :expand-by-default="('isNewItem' in item) && ((index + 1) === buildingEnergySources.length)" />
          </div>
          <div class="row mt-3">
            <div class="col-2 mt-1">
              <coozzy-button
                class="w-100"
                design="prop-green"
                @click="addEnergySource">
                {{ $t('message.onBoarding.buildings.energySource.add') }}
              </coozzy-button>
            </div>
          </div>
        </b-collapse>
      </div>
    </coozzy-card>
    <coozzy-card
      v-if="!isJanitor"
      class="global-card">
      <div
        v-b-toggle.collapse-insurance
        class="collapse-title">
        <h5 class="cursor-pointer">
          {{ $t('message.onBoarding.createBuilding.insurance') }} ({{ insurances.length }})
        </h5>
      </div>
      <div class="col-12 p-0">
        <b-collapse
          id="collapse-insurance"
          :visible="openedCollapses.includes('collapse-insurance') || expandBuildingInsuranceId !== ''">
          <div
            v-if="insurances.length === 0 && readOnly"
            class="row mt-3">
            <div class="col-12">
              <h6>
                {{ $t('message.nonExistent.insurance') }}
              </h6>
            </div>
          </div>
          <div
            v-for="(item, index) in insurances"
            :key="`insurances-${item.id || item.internalId}`"
            class="row">
            <building-insurance
              :id="`building_insurances_${item.id || item.internalId}`"
              ref="building-insurance"
              :key="`insurance-${item.id || item.internalId}`"
              :index="index"
              :read-only="readOnly"
              :insurance="item"
              :expand-by-default="('isNewItem' in item) && ((index + 1) === insurances.length) || expandBuildingInsuranceId === item.id"
              :add-new-document="item.id && insuranceIdNewDocument !== '' && insuranceIdNewDocument === item.id" />
          </div>
          <div class="row mt-3">
            <div class="col-2 mt-1">
              <coozzy-button
                class="w-100"
                design="prop-green"
                @click="addInsurance">
                {{ $t('message.onBoarding.buildings.insurances.add') }}
              </coozzy-button>
            </div>
          </div>
        </b-collapse>
      </div>
    </coozzy-card>
    <coozzy-card
      v-if="!isJanitor"
      class="global-card">
      <div
        v-b-toggle.collapse-mortgages
        class="collapse-title">
        <h5 class="cursor-pointer">
          {{ $t('message.onBoarding.createBuilding.mortgage') }} ({{ buildingMortgages.length }})
        </h5>
      </div>
      <div class="col-12 p-0">
        <b-collapse
          id="collapse-mortgages"
          :visible="openedCollapses.includes('collapse-mortgages') || expandBuildingMortgageId !== ''">
          <div
            v-if="buildingMortgages.length === 0 && readOnly"
            class="row mt-3">
            <div class="col-12">
              <h6>
                {{ $t('message.nonExistent.mortgage') }}
              </h6>
            </div>
          </div>
          <div
            v-for="(item, index) in buildingMortgages"
            :id="`buildingMortgages-${index}`"
            :key="`buildingMortgages-${item.id || item.internalId}`"
            class="row">
            <building-mortgage
              :id="'building_mortgage_' + item.id || item.internalId"
              ref="building-mortgage"
              :key="`mortgage-${item.id || item.internalId}`"
              :index="index"
              :read-only="readOnly"
              :mortgage="item"
              :target="'building'"
              :add-new-document="item.id && mortgageIdNewDocument !== '' && mortgageIdNewDocument === item.id"
              :expand-by-default="('isNewItem' in item) && ((index + 1) === buildingMortgages.length) || expandBuildingMortgageId === item.id" />
          </div>
          <div class="row mt-3">
            <div class="col-2 mt-1">
              <coozzy-button
                class="w-100"
                design="prop-green"
                @click="addNewMortgageBtn">
                {{ $t('message.onBoarding.mortgage.newMortgage') }}
              </coozzy-button>
            </div>
          </div>
        </b-collapse>
      </div>
    </coozzy-card>
    <coozzy-card
      v-if="!isJanitor"
      class="global-card">
      <div
        v-b-toggle.collapse-document
        class="collapse-title">
        <h5 class="cursor-pointer">
          {{ $t('message.onBoarding.createBuilding.generalBuilding') }} ({{ building.documentIds.length }})
        </h5>
      </div>
      <div class="col-12 p-0">
        <b-collapse
          id="collapse-document"
          :visible="openedCollapses.includes('collapse-document')">
          <span
            v-if="filteredBuildingDocuments.length > 0"
            class="link downloadAllDocuments"
            @click="downloadAllDocuments($event)">{{ $t('message.marketingMessages.mail.downloadAll') }} <coozzy-file-download-icon /></span>
          <div
            class="row mt-2">
            <div class="col-12">
              <documents-list
                :documents-list="filteredBuildingDocuments"
                :is-read-only="readOnly"
                @version-added="versionAdded"
                @document-deleted="deleteDocument" />
            </div>
          </div>
          <div class="row mt-3">
            <add-document-modal
              :suffix="'generalBuilding'"
              :owner-id="building.ownerId"
              @document-created="documentCreated" />
            <div class="col-2 mt-1">
              <coozzy-button
                class="w-100 d-block"
                design="prop-green"
                @click="readOnly ? goToEditMode() : addDocumentClicked('generalBuilding')">
                {{ $t('message.manageDocuments.addDocument') }}
              </coozzy-button>
            </div>
          </div>
        </b-collapse>
      </div>
    </coozzy-card>
    <coozzy-card
      v-if="!isJanitor"
      class="global-card">
      <sia-areas
        ref="sia-areas"
        :read-only="readOnly"
        :expand-by-default="false" />
    </coozzy-card>
  </section>
</template>

<script>
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import BuildingInsurance from './components/BuildingInsurance'
import BuildingMortgage from './components/BuildingMortgage'
import BuildingBasic from './components/BuildingBasic'
import SiaAreas from './components/SiaAreas'
import Characteristics from './components/Characteristics'
import AddDocumentModal from '@/properties/components/AddDocumentModal'
import { onboarding } from '@/mixins/onboarding'
import { toastError } from '@/mixins/toastError'
import { user } from '@/mixins/user'
import BuildingDistances from './components/BuildingDistances'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import EnergySource from './components/EnergySource'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import Room from './components/Room.vue'
import DocumentsList from '@/properties/components/DocumentsList.vue'
import { routeChecks } from '@/mixins/routeChecks'
import CoozzyFileDownloadIcon from '@/framework/components/icons/CoozzyFileDownloadIcon.vue'
import { media } from '@/mixins/media'
import DevicesByRoomTab from './components/DevicesByRoomTab.vue'

export default {
  name: 'CreateBuildingTab',
  components: {
    CoozzyFileDownloadIcon,
    DocumentsList,
    EnergySource,
    CoozzyButton,
    BuildingDistances,
    CoozzyCard,
    BuildingBasic,
    SiaAreas,
    Characteristics,
    BuildingInsurance,
    BuildingMortgage,
    AddDocumentModal,
    Room,
    DevicesByRoomTab
  },
  mixins: [onboarding, toastError, user, routeChecks, media],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    expandRoomId: {
      type: String,
      default: ''
    },
    expandBuildingMortgageId: {
      type: String,
      default: ''
    },
    expandBuildingInsuranceId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      renderDevices: false,
      renderDevicesAnimation: true,
      mediaUpload: null,
      mediaUploadProcessing: false,
      availableConstructionTypes: ['OLD_BUILDING', 'NEW_BUILDING', 'HIGH_RISE_BUILDING', 'MIDDLE_TERRACED_HOUSE', 'RAISED_GROUND_FLOOR', 'CORNER_BUILDING', 'MINERGIE_CONSTRUCTION', 'MINERGIE_CERTIFIED', 'LISTED_BUILDING'],
      duplicateCount: 0,
      documentName: '',
      documentType: '',
      firstCollapse: true,
      availableTypes: [
        // Changes need to be implemented also in mobile apps
        'OTHER',
        'APPLICATION_FORM',
        'HANDOVER_PROTOCOL',
        'CONTRACT',
        'BUILDING_PLAN',
        'INVOICE',
        'HOUSE_REGULATION',
        'INSTRUCTION_MANUAL',
        'CREDIT_CHECK',
        'OWNERS_MEETING',
        'PAY_IN_SLIP',
        'ENERGY_CERTIFICATE',
        'WARRANTY_CERTIFICATE',
        'BANK_STATEMENT',
        'ADDITIONAL_COSTS_BILL',
        'SEPA_DIRECT_DEBIT_MANDATE',
        'AGREEMENT',
        'DEPOSIT_CONFIRMATION',
        'BUILDING_INSURANCE_POLICY',
        'KEY_LIST',
        'TERMINATION_DOC_TYPE',
        'RENT_ADJUSTMENT_DOC_TYPE',
        'REFERENCES_DOC_TYPE',
        'MAINTENANCE_CONTRACT_DOC_TYPE',
        'REGULATIONS',
        'REGULATIONS_FOR_USE_AND_MANAGEMENT',
        'ANNUAL_FINANCIAL_STATEMENT',
        'PURCHASE_CONTRACT',
        'LAND_REGISTER',
        'CONDOMINIUM_OWNERSHIP',
        'RENEWAL_FOND',
        'MORTGAGE',
        'ORDER',
        'OFFER',
        'IDENTITY',
        'EXTRACT_FROM_DEBT_COLLECTION_REGISTER',
        'BANK_ACCOUNT_DETAILS',
        'SITUATION_PLAN',
        'CONSTRUCTION_LAW_AGREEMENT',
        'EASEMENT_AGREEMENT',
        'CADASTRAL_ESTIMATE',
        'LETTER',
        'TRADE_REGISTER_EXCERPT',
        'PAYMENT_REMINDER',
        'PROTOCOL',
        'COURT_DOC_TYPE',
        'HONORARIUM',
        'BUDGET',
        'OFFICIAL_ESTIMATE',
        'BUILDING_DESCRIPTION',
        'PERMITS',
        'LEGAL_MATTERS'
      ],
      roomIdNewDocument: '',
      mortgageIdNewDocument: '',
      insuranceIdNewDocument: ''
    }
  },
  computed: {
    translatedAvailableTypes() {
      const array = this.availableTypes
      array.sort((a, b) => {
        if (this.$t(`message.manageDocuments.type.${a.toLowerCase()}`) < this.$t(`message.manageDocuments.type.${b.toLowerCase()}`)) {
          return -1
        }
        if (this.$t(`message.manageDocuments.type.${a.toLowerCase()}`) > this.$t(`message.manageDocuments.type.${b.toLowerCase()}`)) {
          return 1
        }
        return 0
      })
      return array
    },
    buildingArea: {
      get() {
        return this.building.buildingArea !== -1 ? this.building.buildingArea : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField',
          { field: 'buildingArea', value: value, dataType: 'number' })
      }
    },
    buildingMediaUrl() {
      for (const thumbnail of this.buildingImageMedia.media.thumbnails) {
        if (thumbnail.type === 1) {
          return thumbnail.url
        }
      }

      return this.buildingImageMedia.media.url
    },
    buildingZone: {
      get() {
        return this.building.buildingZone
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField', { field: 'buildingZone', value: value })
      }
    },
    constructionTypes: {
      get() {
        return this.building.constructionTypes.filter(con => con !== 'UNDEFINED_CONSTRUCTION_TYPE').map(conTyp => {
          return {
            label: this.$t('message.onBoarding.buildings.constructionTypes.' + conTyp),
            value: conTyp
          }
        })
      },
      set(value) {
        const conTypValues = value.map(v => v.value)
        this.$store.dispatch('onboarding/updateBuildingField', { field: 'constructionTypes', value: conTypValues })
      }
    },
    constructionYear: {
      get() {
        return this.building.constructionYear !== -1 ? this.building.constructionYear : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField',
          { field: 'constructionYear', value: value, dataType: 'number' })
      }
    },
    egid: {
      get() {
        return this.building.egid
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField', { field: 'egid', value: value })
      }
    },
    filteredBuildingDocuments() {
      return this.buildingDocuments.filter(doc => {
        const cdtName = (doc.name?.toLowerCase()).includes(this.documentName.toLowerCase())
        const cdtType = this.documentType === '' || doc.type === this.documentType
        return cdtName && cdtType
      }).sort((a, b) => (a.name > b.name ? 1 : -1))
    },
    floors: {
      get() {
        return this.building.floors !== -1 ? this.building.floors : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField',
          { field: 'floors', value: value, dataType: 'number' })
      }
    },
    getConstructionTypes() {
      const array = []
      this.building.constructionTypes.forEach(conTyp => {
        array.push(this.$t('message.onBoarding.buildings.constructionTypes.' + conTyp))
      })
      return array.join(', ')
    },
    plotIds: {
      get() {
        return this.building.plotIds
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField', { field: 'plotIds', value: value })
      }
    },
    name: {
      get() {
        return this.building.name
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField', { field: 'name', value: value })
      }
    },
    plotArea: {
      get() {
        return this.building.plotArea !== -1 ? this.building.plotArea : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField',
          { field: 'plotArea', value: value, dataType: 'number' })
      }
    },
    renovationYear: {
      get() {
        return this.building.renovationYear !== -1 ? this.building.renovationYear : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField',
          { field: 'renovationYear', value: value, dataType: 'number' })
      }
    },
    translatedConstructionTypes() {
      const array = []
      this.availableConstructionTypes.forEach(conTyp => {
        array.push({
          label: this.$t('message.onBoarding.buildings.constructionTypes.' + conTyp),
          value: conTyp
        })
      })
      return array
    },
    valueNumberOfFloors() {
      const array = []
      for (let index = 1; index < 21; index += 0.5) {
        array.push(index)
      }
      return array
    },
    volume: {
      get() {
        return this.building.volume !== -1 ? this.building.volume : null
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField',
          { field: 'volume', value: value, dataType: 'number' })
      }
    },
    sortedBuildingRooms() {
      return [...this.buildingRooms].sort((a, b) => a.order - b.order)
    }
  },
  watch: {
    openedCollapses: function (newVal) {
      this.renderDevices = newVal.includes('collapse-device')
    },
    renderDevices: function (newVal) {
      if (newVal === true) {
        this.$nextTick(function () {
          this.renderDevicesAnimation = false
        })
      }
    }
  },
  mounted() {
    if (localStorage.getItem('roomIdNewDocument')) {
      this.roomIdNewDocument = localStorage.getItem('roomIdNewDocument')
    }
    if (localStorage.getItem('insuranceIdNewDocument')) {
      this.insuranceIdNewDocument = localStorage.getItem('insuranceIdNewDocument')
    }
    if (localStorage.getItem('mortgageBuildingIdNewDocument')) {
      this.mortgageIdNewDocument = localStorage.getItem('mortgageBuildingIdNewDocument')
    }
    if (localStorage.getItem('addGeneralBuildingDocument') === 'true') {
      this.addDocumentClicked('generalBuilding')
    }
    this.renderDevices = this.openedCollapses.includes('collapse-device')
    if (this.expandRoomId !== '' || this.roomIdNewDocument !== '') {
      const roomId = this.expandRoomId !== '' ? this.expandRoomId : this.roomIdNewDocument
      setTimeout(() => {
        const element = document.getElementById('room_' + roomId)
        element.scrollIntoView(true)
        window.scrollBy(0, -50)
      }, 700)
    }
    if (this.expandBuildingMortgageId !== '') {
      setTimeout(() => {
        const mortgageId = 'building_mortgage_' + this.expandBuildingMortgageId
        const element = document.getElementById(mortgageId)
        element.scrollIntoView(true)
        window.scrollBy(0, -50)
      }, 700)
    }
    if (this.expandBuildingInsuranceId !== '') {
      setTimeout(() => {
        const mortgageId = 'building_insurances_' + this.expandBuildingInsuranceId
        const element = document.getElementById(mortgageId)
        element.scrollIntoView(true)
        window.scrollBy(0, -50)
      }, 700)
    }
  },
  methods: {
    downloadAllDocuments(e) {
      e.preventDefault()
      const idsAndNames = this.filteredBuildingDocuments.map(x => ({ id: x.versions[x.versions.length - 1].mediaId, mediumName: x.name }))
      const nameOfMergedFile = this.$tc('message.onBoarding.createBuilding.generalBuilding') + ' - ' + this.building.name
      this.mergeDownloadAllDocuments(idsAndNames, nameOfMergedFile)
    },
    goToEditMode() {
      localStorage.setItem('addGeneralBuildingDocument', 'true')
      this.$router.push({
        name: this.moduleRoutePrefix + 'OnboardingView',
        params: { id: this.$route.params.id },
        query: {
          view: 'building'
        }
      })
    },
    buildingDistanceUpdated(payload) {
      this.$store.dispatch('onboarding/updateBuildingDistances', payload)
    },
    buildingCharacteristicsUpdated(payload) {
      this.$store.dispatch('onboarding/updateCharacteristics', payload)
    },
    addBuildingRoom() {
      this.addNewBuildingRoom()
      this.$nextTick(() => {
        const indexDevice = (this.buildingRooms.length - 1)
        const domRect = this.$el.querySelector('#room-i-' + indexDevice)
        if (domRect) {
          domRect.scrollIntoView(true)
        }
      })
    },
    addInsurance() {
      this.addNewInsurance()
      setTimeout(() => {
        this.$nextTick(() => {
          const indexInsurance = this.insurances.length - 1
          const domRect = this.$el.querySelector('#insurance-' + indexInsurance)
          domRect.scrollIntoView(true)
          this.$el.querySelector('#collapse-insurance-' + indexInsurance + ' select').focus()
        })
      }, 300)
    },
    addEnergySource() {
      this.addNewEnergySource(this.building.id)
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.$el) {
            const indexEnergySource = this.buildingEnergySources.length - 1
            const domRect = this.$el.querySelector('#energySource-' + indexEnergySource)
            if (domRect) {
              domRect.scrollIntoView(true)
              this.$el.querySelector('#energySource-' + indexEnergySource + ' select')?.focus()
            }
          }
        }, 300)
      })
    },
    addNewMortgageBtn() {
      this.addNewMortgage(this.building.id)
      this.$nextTick(() => {
        setTimeout(() => {
          const indexBuildingMortgages = this.buildingMortgages.length - 1
          const domRect = this.$el.querySelector('#buildingMortgages-' + indexBuildingMortgages)
          domRect.scrollIntoView(true)
          this.$el.querySelector('#buildingMortgages-' + indexBuildingMortgages + ' input').focus()
        }, 300)
      })
    },
    addDocumentClicked(suffix = '') {
      localStorage.removeItem('addGeneralBuildingDocument')
      this.$bvModal.show('add-document-modal' + suffix)
    },
    closeModal() {
      this.$refs['modal-confirmation'].hide()
    },
    documentCreated(document) {
      // Update building in vuex store with new documentId
      const docs = this.building.documentIds
      docs.push(document.id)
      this.$store.dispatch('onboarding/updateBuildingField', { field: 'documentIds', value: docs })

      // Add whole new document to vuex store to display it
      this.buildingDocuments.push(document)
    },
    deleteDocument(document) {
      this.building.documentIds.forEach((doc, index) => {
        if (doc === document.id) {
          this.building.documentIds.splice(index, 1)
        }
        const indexToDelete = this.buildingDocuments.findIndex(d => d.id === document.id)
        if (indexToDelete !== -1) {
          this.$delete(this.buildingDocuments, indexToDelete)
        }
      }, this)
      this.$store.dispatch('onboarding/updateBuildingField', { field: 'documentIds', value: this.building.documentIds })
      this.activateChanged()
    },
    duplicateDevice() {
      this.$nextTick(() => {
        this.$emit('has-change', true)
        const indexDevice = this.buildingDevices.length - 1
        const domRect = this.$el.querySelector('#device-building-' + indexDevice)
        if (domRect) {
          domRect.scrollIntoView(true)
          this.$el.querySelector('#collapse-device-building-' + indexDevice + ' input').focus()
        }
      })
    },
    isInvalid() {
      let valid = true
      const errorArray = []
      if (this.$refs['energy-source']) {
        this.$refs['energy-source'].forEach(element => {
          if (element.isInvalid()) {
            if (!element.isCollapseOpen()) {
              errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.createBuilding.energySource') + this.$t('message.savingErrors.validationError2'))
            }
            valid = false
          }
        })
      }
      if (this.$refs['building-insurance']) {
        this.$refs['building-insurance'].forEach(element => {
          if (element.isInvalid()) {
            if (!element.isCollapseOpen()) {
              errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.createBuilding.insurance') + this.$t('message.savingErrors.validationError2'))
            }
            valid = false
          }
        })
      }
      if (this.$refs['building-mortgage']) {
        this.$refs['building-mortgage'].forEach(element => {
          if (element.isInvalid()) {
            if (!element.isCollapseOpen()) {
              errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.createBuilding.mortgage') + this.$t('message.savingErrors.validationError2'))
            }
            valid = false
          }
        })
      }
      if (this.$refs.device) {
        this.$refs.device.forEach(element => {
          if (element.isInvalid()) {
            if (!element.isCollapseOpen()) {
              errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.createBuilding.device') + this.$t('message.savingErrors.validationError2'))
            }
            valid = false
          }
        })
      }
      if (this.$refs['sia-areas'].isInvalid()) {
        if (!this.$refs['sia-areas'].isCollapseOpen()) {
          errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.buildings.objects.areas.title') + this.$t('message.savingErrors.validationError2'))
        }
        valid = false
      }
      if (this.$refs['building-distances'].isInvalid()) {
        if (!this.$refs['building-distances'].isCollapseOpen()) {
          errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.createBuilding.distances') + this.$t('message.savingErrors.validationError2'))
        }
        valid = false
      }
      if (this.$refs['building-basic'].isInvalid()) {
        if (!this.$refs['building-basic'].isCollapseOpen()) {
          errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.createBuilding.baseData') + this.$t('message.savingErrors.validationError2'))
        }
        valid = false
      }
      if (errorArray.length > 0) {
        this.showManyToast(errorArray)
      }
      return !valid
    },
    removeDevice(index) {
      this.$delete(this.building.devices, index)
    },
    searchDocumentName() {
      // TODO
    },
    updateDevice(item) {
      this.$set(this.buildings[0].devices, item.index, item.device)
    },
    versionAdded(updatedDocument) {
      this.buildingDocuments.forEach((doc, index) => {
        if (doc.id === updatedDocument.id) {
          this.buildingDocuments.splice(index, 1, updatedDocument)
        }
      }, this)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
