<template>
  <section class="container">
    <!-- Title -->
    <div
      v-if="!isApplicationSent"
      class="row">
      <div class="col">
        <coozzy-page-title
          class="mt-4"
          :title="$t('message.applicationForm.title')" />
        <p>
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="$t('message.applicationForm.subTitle')" />
          <!-- eslint-enable vue/no-v-html -->
        </p>
      </div>
    </div>
    <div
      v-else
      class="row justify-content-between align-items-center call-action mt-4">
      <div class="col-md-10 col-sm-6 col-10 offset-md-1">
        <div class="row">
          <img
            width="50px"
            height="35px"
            :class="'col-12 col-md-2 mb-3 pr-0 mt-2'"
            src="@/assets/icon/like.svg"
            :alt="$t('message.marketingObjects.status.rented')">
          <h1
            class="col-12 col-md-10 mb-3 pl-0">
            {{ $t('message.applicationForm.titleSuccess') }}
          </h1>
          <p class="col-md-10 col-sm-6 col-10 offset-md-2 pl-0">
            <!-- eslint-disable vue/no-v-html -->
            <span v-html="$t('message.applicationForm.subTitleSuccess')" />
          <!-- eslint-enable vue/no-v-html -->
          </p>
        </div>
      </div>
    </div>
    <template>
      <div
        v-if="objectId && !isApplicationSent"
        class="row">
        <coozzy-card class="col pl-0 pr-0 mb-3 margin15">
          <application-form-object-view :default-object-id="objectId" />
        </coozzy-card>
        <application-form
          :object-id="objectId"
          :sending="true"
          @application-sent="applicationSent" />
      </div>
    </template>
  </section>
</template>

<script>
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import ApplicationForm from '@/marketingBasic/components/applicationForm/ApplicationForm'
import ApplicationFormObjectView from '@/marketingBasic/components/applicationForm/ApplicationFormObjectView'
import CoozzyCard from '@/framework/components/card/CoozzyCard'

export default {
  name: 'ApplicationFormLandingPageView',
  components: {
    CoozzyCard,
    ApplicationForm,
    CoozzyPageTitle,
    ApplicationFormObjectView
  },
  metaInfo() {
    return {
      title: this.$t('message.applicationForm.title')
    }
  },
  props: {
    objectId: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      isApplicationSent: false
    }
  },
  methods: {
    applicationSent(value) {
      this.isApplicationSent = value
    }
  }
}
</script>
<style lang="scss" scoped>
.margin15 {
  margin: 0 15px;
}
</style>
