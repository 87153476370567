<template>
  <div class="row">
    <div class="col-12">
      <h5>{{ $t('message.applicationForm.documents') }}</h5>
    </div>
    <div
      v-if="object && !object.acceptsCreditTrust && object.category !== 'PARKING_SPACE' && object.category !== 'SECONDARY_ROOM'"
      class="col-12 col-md-6 mb-2">
      <div>
        <div class="d-inline-block">
          <b>{{ $t('message.applicationForm.debtEnforcementRegister') }}</b><br>
          <span class="maxFileSize">max. 50MB</span>
        </div>
        <coozzy-button
          class="float-right"
          design="green"
          @click="uploadDocumentClicked('debtUpload')">
          {{ $t('message.manageDocuments.addDocument') }}
        </coozzy-button>
      </div>
      <coozzy-spinner
        v-if="debtUploadProcessing"
        class="align-self-center" />
      <div
        v-for="item in debtEnforcementRegisterMedia"
        :key="item.id"
        class="media-block col">
        <div
          class="file-block p-1 my-2">
          <a
            :href="item.url"
            data-v-09485260=""
            target="_blank">
            {{ item.filename }}
          </a>
          <coozzy-button
            class="delete-icon"
            design="transparent"
            @click="deleteMedia(item.id, 'debtEnforcementRegisterMediaIds')">
            <coozzy-delete-icon />
          </coozzy-button>
        </div>
      </div>
      <coozzy-form-file-input
        ref="debtUpload"
        v-model="debtUpload"
        accept="text/plain, text/csv, application/pdf, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
        class="d-none"
        @change="addFile($event, 'debtEnforcementRegisterMediaIds')" />
    </div>
    <div class="col-12 col-md-6 mb-2">
      <div>
        <div class="d-inline-block">
          <b>{{ $t('message.applicationForm.idCard') }}</b><br>
          <span class="maxFileSize">max. 50MB</span>
        </div>
        <coozzy-button
          class="float-right"
          design="green"
          @click="uploadDocumentClicked('idCardUpload')">
          {{ $t('message.manageDocuments.addDocument') }}
        </coozzy-button>
      </div>
      <coozzy-spinner
        v-if="idCardUploadProcessing"
        class="align-self-center" />
      <div
        v-for="item in idCardMedia"
        :key="item.id"
        class="media-block col">
        <div
          class="file-block p-1 my-2">
          <a
            :href="item.url"
            target="_blank">
            {{ item.filename }}
          </a>
          <coozzy-button
            class="delete-icon"
            design="transparent"
            @click="deleteMedia(item.id, 'idCardMediaIds')">
            <coozzy-delete-icon />
          </coozzy-button>
        </div>
      </div>
      <coozzy-form-file-input
        ref="idCardUpload"
        v-model="idCardUpload"
        accept="text/plain, text/csv, application/pdf, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
        class="d-none"
        @change="addFile($event, 'idCardMediaIds')" />
    </div>
    <div class="col-12 col-md-6">
      <div>
        <div class="d-inline-block">
          <b>{{ $t('message.applicationForm.media') }}</b><br>
          <span class="maxFileSize">max. 50MB</span>
        </div>
        <coozzy-button
          class="float-right"
          design="green"
          @click="uploadDocumentClicked('mediaUpload')">
          {{ $t('message.manageDocuments.addDocument') }}
        </coozzy-button>
      </div>
      <coozzy-spinner
        v-if="mediaUploadProcessing"
        class="align-self-center" />
      <div
        v-for="item in media"
        :key="item.id"
        class="media-block col">
        <div
          class="file-block p-1 my-2">
          <a
            :href="item.url"
            target="_blank">
            {{ item.filename }}
          </a>
          <coozzy-button
            class="delete-icon"
            design="transparent"
            @click="deleteMedia(item.id, 'mediaIds')">
            <coozzy-delete-icon />
          </coozzy-button>
        </div>
      </div>
      <coozzy-form-file-input
        ref="mediaUpload"
        v-model="mediaUpload"
        accept="text/plain, text/csv, application/pdf, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
        class="d-none"
        @change="addFile($event, 'mediaIds')" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import MediaApi from '../../../misc/apis/MediaApi'
import CoozzySpinner from '../../../framework/components/misc/CoozzySpinner'
import Vue from 'vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput'
import { media } from '@/mixins/media'

export default {
  name: 'ApplicationFormIndividualDocuments',
  components: {
    CoozzyFormFileInput,
    CoozzyDeleteIcon,
    CoozzyButton,
    CoozzySpinner
  },
  mixins: [media],
  props: {
    individualId: {
      type: String,
      required: true
    },
    object: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      debtEnforcementRegisterMedia: [],
      idCardMedia: [],
      media: [],
      mediaUpload: null,
      mediaUploadProcessing: false,
      idCardUpload: null,
      idCardUploadProcessing: false,
      debtUpload: null,
      debtUploadProcessing: false
    }
  },
  computed: {
    ...mapGetters('applicationForm', ['getIndividualByInternalId']),
    individual() {
      return this.getIndividualByInternalId(this.individualId)
    },
    debtEnforcementRegisterMediaIds: {
      get() {
        return this.individual.debtEnforcementRegisterMediaIds ? this.individual.debtEnforcementRegisterMediaIds : []
      },
      set(value) {
        const ind = this.individual
        ind.debtEnforcementRegisterMediaIds = value
        this.updateIndividual(ind)
      }
    },
    idCardMediaIds: {
      get() {
        return this.individual.idCardMediaIds ? this.individual.idCardMediaIds : []
      },
      set(value) {
        const ind = this.individual
        ind.idCardMediaIds = value
        this.updateIndividual(ind)
      }
    },
    mediaIds: {
      get() {
        return this.individual.mediaIds ? this.individual.mediaIds : []
      },
      set(value) {
        const ind = this.individual
        ind.mediaIds = value
        this.updateIndividual(ind)
      }
    }
  },
  // watch: {
  //   mediaUpload: function (newValue) {
  //     if (newValue !== null) {
  //       this.mediaUploadProcessing = true
  //       this.documentCreated(newValue, 'mediaIds')
  //     }
  //   },
  //   idCardUpload: function (newValue) {
  //     if (newValue !== null) {
  //       this.idCardUploadProcessing = true
  //       this.documentCreated(newValue, 'idCardMediaIds')
  //     }
  //   },
  //   debtUpload: function (newValue) {
  //     if (newValue !== null) {
  //       this.debtUploadProcessing = true
  //       this.documentCreated(newValue, 'debtEnforcementRegisterMediaIds')
  //     }
  //   }
  // },
  mounted() {
    // Load media
    const promises = []
    if (this.debtEnforcementRegisterMediaIds.length > 0) {
      this.debtEnforcementRegisterMediaIds.map(x => promises.push(MediaApi.getMediaByID(x)))
    }
    if (this.idCardMediaIds.length > 0) {
      this.idCardMediaIds.map(x => promises.push(MediaApi.getMediaByID(x)))
    }
    if (this.mediaIds.length > 0) {
      this.mediaIds.map(x => promises.push(MediaApi.getMediaByID(x)))
    }
    Promise.all(promises)
      .then(response => {
        for (let index = 0; index < response.length; index++) {
          if (this.debtEnforcementRegisterMediaIds.find(x => x === response[index].id)) {
            this.debtEnforcementRegisterMedia.push(response[index])
          }
          if (this.idCardMediaIds.find(x => x === response[index].id)) {
            this.idCardMedia.push(response[index])
          }
          if (this.mediaIds.find(x => x === response[index].id)) {
            this.media.push(response[index])
          }
        }
      })
      .catch(e => {
        console.log(e)
      })
  },
  methods: {
    ...mapMutations('applicationForm', ['updateIndividual']),
    uploadDocumentClicked(ref) {
      if (!this.readOnly) {
        const btn = this.$refs[ref].$el.children
        btn[0].click()
      }
    },
    async addFile(event, source) {
      let file = null
      if (source === 'debtEnforcementRegisterMediaIds') {
        this.debtUploadProcessing = true
      } else if (source === 'idCardUpload') {
        this.idCardUploadProcessing = true
      } else if (source === 'mediaIds') {
        this.mediaUploadProcessing = true
      }
      const files = await this.getFilesFromEvent(event)
      if (files.length > 0) {
        file = files[0]
        await this.documentCreated(file, source)
      }

      if (source === 'debtEnforcementRegisterMediaIds') {
        this.debtUploadProcessing = false
      } else if (source === 'idCardUpload') {
        this.idCardUploadProcessing = false
      } else if (source === 'mediaIds') {
        this.mediaUploadProcessing = false
      }
    },
    async documentCreated(document, suffix) {
      const docSizeMB = document.size / (1024 * 1024)
      if (docSizeMB > 50) {
        this.mediaUploadProcessing = false
        this.debtUploadProcessing = false
        this.idCardUploadProcessing = false
        Vue.toasted.show(this.$tc('message.uploadingErrors.maxFileSize'), { type: 'error' })
      } else {
        const selectedFile = document ? [document] : []
        const response = await this.uploadMedia(selectedFile)
        if (response.length > 0) {
          const media = response[0]
          const docs = this[suffix] ? this[suffix] : []
          docs.push(media.id)
          this[suffix] = docs
          const noIdSuffix = suffix.replace('Ids', '')
          this[noIdSuffix].push(media)
          this.mediaUpload = null
          this.debtUpload = null
          this.idCardUpload = null
        }
        this.mediaUploadProcessing = false
        this.debtUploadProcessing = false
        this.idCardUploadProcessing = false
      }
    },
    deleteMedia(id, suffix) {
      this[suffix] = this[suffix].filter(e => e !== id)
      const noIdSuffix = suffix.replace('Ids', '')
      this[noIdSuffix] = this[noIdSuffix].filter(el => el.id !== id)
    }
  }
}
</script>

<style lang="scss" scoped>
.maxFileSize {
  font-size: 0.875rem;
}
</style>
