import networkTS from '@/misc/apis/networkTS'
import { InvestPlanCreateBulkEntry, InvestPlanEnableEntry, ObjectinvestPlanUpdateBulkEntry } from '@/types/api'

export default {
  async hasInvestPlanEnabled(buildingIds: string[]) {
    return networkTS.investPlanServiceHasEnabled({ buildingIds: buildingIds })
      .then((response) => {
        return response.data
      })
  },
  async enableInvestPlan(entries: InvestPlanEnableEntry[]) {
    return networkTS.investPlanServiceEnable({ entries: entries })
      .then((response) => {
        return response.data
      })
  },
  async createComponentBulk(entries: InvestPlanCreateBulkEntry[]) {
    return networkTS.investPlanServiceCreateBulk({ entries: entries })
      .then(response => {
        return response.data
      })
  },
  async deleteComponent(id: string) {
    return networkTS.investPlanServiceDelete(id)
      .then(response => {
        return response.data
      })
  },
  async listComponentsByReferenceIds(ids: string[]) {
    return networkTS.investPlanServiceListByReferenceIds({ ids: ids })
      .then(response => {
        return response.data
      })
  },
  async updateComponentBulk(entries: ObjectinvestPlanUpdateBulkEntry[]) {
    return networkTS.investPlanServiceUpdateBulk({ entries: entries })
      .then(response => {
        return response.data
      })
  }
}
