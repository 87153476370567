<template>
  <div class="col">
    <div
      slot="table-busy"
      class="text-center text-danger my-2" />
    <div
      class="row">
      <div class="col-md-5">
        <h3>
          {{ $t('message.processHandover.rooms.selectedRooms') }}
          <coozzy-info-circle-icon
            v-b-tooltip.hover.html="$t('message.processHandover.rooms.selectedRoomsTooltip')"
            class="ml-2 mb-2 tooltip-message" />
        </h3>
        <coozzy-card
          class="list-group w-100 p-0">
          <draggable
            v-model="selectedRooms"
            @end="updateOrder">
            <div
              v-for="room in selectedRooms"
              :key="room.id"
              class="list-group-item">
              <coozzy-form-input
                v-if="editingRoom && editingRoom.id === room.id"
                v-model="room.name"
                @blur="finishEdit(room)"
                @keyup.enter="finishEdit(room)" />
              <span v-else>{{ room.name }}</span>
              <coozzy-button
                v-if="!(editingRoom && editingRoom.id === room.id)"
                design="transparent"
                class="p-0 ml-1 mb-2  float-right"
                @click="showDeleteConfirmation(room, $event)">
                <coozzy-delete-icon />
              </coozzy-button>
              <coozzy-button
                v-if="editingRoom && editingRoom.id === room.id"
                design="transparent"
                class="p-0 mb-2 ml-2 save-icon"
                @click="finishEdit(room)">
                <coozzy-save-icon />
              </coozzy-button>
              <coozzy-button
                v-else
                design="transparent"
                class="p-0 mb-2 ml-2 float-right"
                @click="editRoom(room)">
                <coozzy-edit-icon />
              </coozzy-button>
            </div>
          </draggable>
        </coozzy-card>
        <coozzy-button
          class="col-sm-12 col-lg-4 col-md-4 float-md-right mt-3"
          design="prop-green"
          @click="addRoom">
          {{ $t('message.processHandover.rooms.addRoom') }}
        </coozzy-button>
        <b-modal
          :id="'confirmationModalRoom_' + object.id"
          :ref="'confirmationModalRoom_' + object.id"
          no-close-on-backdrop
          hide-footer
          :title="$t('message.processHandover.confirmationDeletion.title')">
          <template>
            <div class="col p-0">
              <p>{{ $t('message.processHandover.confirmationDeletion.roomBody') }}</p>
            </div>
            <div class="col p-0">
              <coozzy-button
                size="small"
                class="mb-0 border"
                design="transparent"
                @click="$bvModal.hide('confirmationModalRoom_' + object.id)">
                {{ $t('message.generic.cancel') }}
              </coozzy-button>
              <coozzy-button
                size="small"
                class="float-right mb-0"
                design="green"
                @click="deleteRoom">
                {{ $t('message.generic.delete') }}
              </coozzy-button>
            </div>
          </template>
        </b-modal>
      </div>
      <div class="col-md-5 offset-2">
        <h3>
          {{ $t('message.processHandover.rooms.availableRooms') }}
          <coozzy-info-circle-icon
            v-b-tooltip.hover.html="$t('message.processHandover.rooms.availableRoomsTooltip')"
            class="ml-2 mb-2 tooltip-message" />
        </h3>
        <coozzy-card
          class="list-group w-100 p-0">
          <div
            v-for="room in availableRooms"
            :key="room.id"
            class="list-group-item">
            <span>{{ room.name }}</span>
            <coozzy-button
              design="prop-green"
              class="p-0 ml-1 float-right px-2 add-to-available"
              @click="addRoomMethod(room)">
              +
            </coozzy-button>
          </div>
        </coozzy-card>
      </div>
    </div>
  </div>
</template>

<script>

import { formatting } from '@/mixins/formatting'
import { obj } from '@/mixins/object'
import { onboarding } from '@/mixins/onboarding'
import { routeChecks } from '@/mixins/routeChecks'
import { algolia } from '@/mixins/algolia'
import { mapActions, mapGetters } from 'vuex'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon.vue'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import CoozzySaveIcon from '@/framework/components/icons/CoozzySaveIcon.vue'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'
import draggable from 'vuedraggable'

export default {
  name: 'HandoverRoomsStep',
  components: {
    CoozzyCard,
    CoozzyInfoCircleIcon,
    CoozzySaveIcon,
    CoozzyFormInput,
    CoozzyDeleteIcon,
    CoozzyButton,
    CoozzyEditIcon,
    draggable
  },
  mixins: [formatting, obj, onboarding, routeChecks, algolia],
  props: {
    object: {
      type: Object,
      required: true,
      default: () => {
        return null
      }
    },
    selfRooms: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    },
    preSelectedRooms: {
      type: Array,
      required: false,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      deletedRooms: [],
      rooms: [],
      availableRooms: [],
      selectedRooms: [],
      selectedEntries: [],
      filteredObjects: [],
      usedContacts: [],
      selectedRoom: null,
      editingRoom: null
    }
  },
  computed: {
    employeeContacts() {
      let empContacts = this.contacts.filter(c => c.types.includes('EMPLOYEE'))
      if (this.getEmployees().length > 0) {
        empContacts = empContacts.filter(contact => {
          const matchingEmployee = this.getEmployees()
            .filter(employee => !employee.blocked)
            .find(employee => employee?.email?.toLowerCase()?.trim() === contact?.email?.toLowerCase()?.trim())

          return matchingEmployee !== undefined && matchingEmployee !== null
        })
      }
      return empContacts
    }
  },
  watch: {
    selectedRooms(newVal) {
      this.$emit('selected-rooms', newVal, this.object, 'rooms')
    },
    deletedRooms(newVal) {
      this.$emit('deleted-rooms', newVal, this.object)
    },
    selfRooms(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal) || JSON.stringify(newVal) !== JSON.stringify(this.rooms)) {
        this.rooms = newVal
        this.updateSelectedRoom()
      }
    }
  },
  mounted() {
    if (this.object.category === 'APARTMENT' || this.object.category === 'HOUSE') {
      this.availableRooms = [
        {
          id: 'kitchen',
          name: this.$t('message.processHandover.rooms.kitchen'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        },
        {
          id: 'wetRoom',
          name: this.$t('message.processHandover.rooms.wetRoom'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        },
        {
          id: 'room',
          name: this.$t('message.processHandover.rooms.room'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        },
        {
          id: 'corridor_entrance',
          name: this.$t('message.processHandover.rooms.corridor_entrance'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        },
        {
          id: 'reduit_storage',
          name: this.$t('message.processHandover.rooms.reduit_storage'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        },
        {
          id: 'balcony_seatingArea',
          name: this.$t('message.processHandover.rooms.balcony_seatingArea'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        },
        {
          id: 'cellar',
          name: this.$t('message.processHandover.rooms.cellar'),
          dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
        }
      ]
    } else if (this.object.category === 'PARKING_SPACE') {
      this.availableRooms = [{
        id: 'parking',
        name: this.$t('message.processHandover.rooms.parking'),
        dimensions: {
          area: null,
          height: null,
          length: null,
          volume: null,
          width: null
        },
        documentIds: [],
        imageMediaIds: [],
        note: '',
        ownerId: this.object.ownerId,
        referenceId: this.object.id
      }]
    } else if (this.object.category === 'SECONDARY_ROOM') {
      this.availableRooms = [{
        id: 'secondaryRoom',
        name: this.$t('message.processHandover.rooms.secondaryRoom'),
        dimensions: {
            area: null,
            height: null,
            length: null,
            volume: null,
            width: null
          },
          documentIds: [],
          imageMediaIds: [],
          note: '',
          ownerId: this.object.ownerId,
          referenceId: this.object.id
      }]
    }
    this.$emit('selected-rooms', this.selectedRooms, this.object, 'rooms')
    this.updateSelectedRoom()
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    updateOrder() {
      // Update the order attribute based on the new arrangement
      this.selectedRooms.forEach((room, index) => {
        room.order = index + 1
        if (room.id.startsWith('room_')) {
          room.edited = true
        }
      })
    },
    updateSelectedRoom() {
      let list = []
      this.preSelectedRooms.forEach(room => {
        list.push(room)
      })
      this.rooms.forEach(room => {
        list.push(room)
      })
      // remove duplicates
      list = list.filter((room, index, self) =>
          index === self.findIndex((t) => (
            t.id === room.id
          ))
      )
      this.selectedRooms = this.sortRooms(list)
    },
    sortRooms(list) {
      // Sort rooms by the `order` attribute, keeping -1 at the end
      return list.sort((a, b) => {
        if (a.order === -1) return 1
        if (b.order === -1) return -1
        return a.order - b.order
      })
    },
    addRoomMethod(room) {
      this.selectedRoom = room
      this.$nextTick(() => {
        this.moveSelectedRoomToSelected(room)
      })
    },
    moveSelectedRoomToSelected(room) {
      room = JSON.parse(JSON.stringify(room))
      room.id = `temproom_${Date.now()}`
      this.selectedRooms.push(room)
      this.clearSelection()
    },
    showDeleteConfirmation(room, e) {
      e.preventDefault()
      this.selectedRoom = room
      this.$bvModal.show('confirmationModalRoom_' + this.object.id)
    },
    clearSelection() {
      this.selectedRoom = null
    },
    addRoom() {
      const newRoom = {
        id: Date.now(),
        dimensions: {
          area: null,
          height: null,
          length: null,
          volume: null,
          width: null
        },
        documentIds: [],
        imageMediaIds: [],
        name: this.$t('message.onBoarding.devices.room'),
        note: '',
        ownerId: this.object.ownerId,
        referenceId: this.object.id
      }
      this.selectedRooms.push(newRoom)
    },
    updateRooms(event) {
      // Optional: handle any updates after drag-and-drop
    },
    editRoom(room) {
      this.editingRoom = { ...room }
    },
    finishEdit(room) {
      if (room) {
        if (typeof room.id === 'string' && room.id.startsWith('room_')) {
          room.edited = true
        }
        let roomIndex = this.selectedRooms.findIndex(
          (r) => r.id === room.id
        )
        if (roomIndex !== -1) {
          this.$set(this.selectedRooms, roomIndex, room)
        }
      }
      this.editingRoom = null
    },
    deleteRoom() {
      this.selectedRooms = this.selectedRooms.filter(r => this.selectedRoom.id !== r.id)
      this.deletedRooms.push(this.selectedRoom.id)
      this.$bvModal.hide('confirmationModalRoom_' + this.object.id)
      this.clearSelection()
    }
  }
}
</script>

<style lang="scss" scoped>
.save-icon {
  position: absolute;
  right: 20px;
  top: 12px;
  z-index: 9;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card {
  width: 40%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.arrows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.arrows button {
  margin: 5px;
}

.list-group {
  min-height: 200px;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 10px;
  background-color: #fff;
}

.list-group-item {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: #f5f5f5;
  min-height: 46px;
}

.list-group-item.selected {
  background-color: #d3e2ff;
  border-color: #007bff;
}
.tooltip-message {
  width: 15px;
}
</style>
