var render = function render(){var _vm=this,_c=_vm._self._c;return _c('coozzy-card',{attrs:{"title":_vm.$t('message.settings.signature.title')}},[(_vm.loading)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('coozzy-spinner')],1)]):[_vm._l((_vm.signatures.length),function(index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-8 mb-2"},[_c('coozzy-form-input',{attrs:{"name":_vm.$tc('message.generic.name')},model:{value:(_vm.signatures[index-1].name),callback:function ($$v) {_vm.$set(_vm.signatures[index-1], "name", $$v)},expression:"signatures[index-1].name"}})],1),_c('div',{staticClass:"col-sm-12 col-md-8"},[_c('editor',{ref:"editor-tiny",refInFor:true,attrs:{"api-key":_vm.editorApi,"init":{
            toolbar: 'undo redo | bold italic underline forecolor | fontsize | numlist bullist link table | image | code | fullscreen print',
            plugins: 'fullscreen lists code image table link',
            height: '370',
            menubar: '',
            language: _vm.currentLanguage,
            elementpath: false,
            automatic_uploads: true,
            images_upload_handler: _vm.uploadImage,
            license_key: 'gpl',
            content_style: _vm.contentStyle,
            font_formats: _vm.fontFormats,
            branding: false,
            remove_trailing_brs: false,
            browser_spellcheck: true,
            table_toolbar: '',
            font_size_formats: _vm.fontsizeFormats,
            forced_root_block: 'div',
            verify_html: false,
            setup: function(editor) {
              editor.on('NodeChange', function(e) {
                const brs = editor.getBody().getElementsByTagName('br');

                Array.from(brs).forEach(br => {
                  const parent = br.parentNode;

                  const prevSibling = br.previousSibling;
                  if (prevSibling && prevSibling.nodeName === 'SPAN' &&
                    prevSibling.getAttribute('data-mce-type') === 'format-caret') {
                    const formatSpan = prevSibling.getElementsByTagName('span')[0];
                    if (formatSpan && formatSpan.style.fontSize) {
                      const fontSize = formatSpan.style.fontSize;
                      parent.style.fontSize = fontSize;
                      formatSpan.setAttribute('data-mce-preserved', 'true');
                    }
                  }

                  if (parent && (!parent.style.fontSize || parent.style.fontSize === '')) {
                    const defaultFontSize = editor.getBody().style.fontSize || '10pt';
                    parent.style.fontSize = defaultFontSize;
                  }
                });
              });
            }
          }},model:{value:(_vm.signatures[index-1].signature),callback:function ($$v) {_vm.$set(_vm.signatures[index-1], "signature", $$v)},expression:"signatures[index-1].signature"}})],1),_c('div',{staticClass:"col-sm-12 col-md-4"},[_c('h5',[_vm._v(_vm._s(_vm.$t('message.settings.availablePlaceholders')))]),_vm._l((_vm.availablePlaceholders),function(placeholder){return _c('div',{key:placeholder},[_vm._v(" "+_vm._s(placeholder)+" ")])})],2),(index > 1)?_c('div',{staticClass:"col-sm-12 col-md-8"},[_c('coozzy-button',{staticClass:"float-right mr-3 mt-3",attrs:{"design":"red"},on:{"click":function($event){return _vm.deleteNewSignature(index-1)}}},[_vm._v(" "+_vm._s(_vm.$t('message.settings.signature.deleteNewSignature'))+" ")])],1):_vm._e()])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('coozzy-button',{staticClass:"float-right mt-3",attrs:{"design":"primary"},on:{"click":_vm.saveClicked}},[_vm._v(" "+_vm._s(_vm.$t('message.generic.form.save'))+" ")]),_c('coozzy-button',{staticClass:"float-right mr-3 mt-3",attrs:{"design":"prop-green"},on:{"click":_vm.addNewSignature}},[_vm._v(" "+_vm._s(_vm.$t('message.settings.signature.addNewSignature'))+" ")])],1)])]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }