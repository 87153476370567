<template>
  <div class="row">
    <div class="col-12">
      <h5>{{ $t('message.applicationForm.documents') }}</h5>
    </div>
    <div class="col-12 col-md-6">
      <div>
        <div class="d-inline-block">
          <b>{{ $t('message.applicationForm.debtEnforcementRegister') }}</b><br>
          <span class="maxFileSize">max. 50MB</span>
        </div>
        <coozzy-button
          class="float-right"
          design="green"
          @click="uploadDocumentClicked('debtUpload')">
          {{ $t('message.manageDocuments.addDocument') }}
        </coozzy-button>
      </div>
      <coozzy-spinner
        v-if="debtUploadProcessing"
        class="align-self-center" />
      <div
        v-for="item in debtEnforcementRegisterMedia"
        :key="item.id"
        class="media-block col">
        <div
          class="file-block p-1 my-2">
          <a
            :href="item.url"
            data-v-09485260=""
            target="_blank">
            {{ item.filename }}
          </a>
          <coozzy-button
            class="delete-icon"
            design="transparent"
            @click="deleteMedia(item.id, 'debtEnforcementRegisterMediaIds')">
            <coozzy-delete-icon />
          </coozzy-button>
        </div>
      </div>
      <coozzy-form-file-input
        ref="debtUpload"
        v-model="debtUpload"
        accept="text/plain, text/csv, application/pdf, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
        class="d-none"
        @change="addFile($event, 'debtEnforcementRegisterMediaIds')" />
    </div>
    <div class="col-12 col-md-6">
      <div>
        <div class="d-inline-block">
          <b>{{ $t('message.applicationForm.tradeRegisterExcerptMedia') }}</b><br>
          <span class="maxFileSize">max. 50MB</span>
        </div>
        <coozzy-button
          class="float-right"
          design="green"
          @click="uploadDocumentClicked('tradeRegisterExcerptUpload')">
          {{ $t('message.manageDocuments.addDocument') }}
        </coozzy-button>
      </div>
      <coozzy-spinner
        v-if="tradeRegisterExcerptProcessing"
        class="align-self-center" />
      <div
        v-for="item in tradeRegisterExcerptMedia"
        :key="item.id"
        class="media-block col">
        <div
          class="file-block p-1 my-2">
          <a
            :href="item.url"
            data-v-09485260=""
            target="_blank">
            {{ item.filename }}
          </a>
          <coozzy-button
            class="delete-icon"
            design="transparent"
            @click="deleteMedia(item.id, 'tradeRegisterExcerptMediaIds')">
            <coozzy-delete-icon />
          </coozzy-button>
        </div>
      </div>
      <coozzy-form-file-input
        ref="tradeRegisterExcerptUpload"
        v-model="tradeRegisterExcerptUpload"
        accept="text/plain, text/csv, application/pdf, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
        class="d-none"
        @change="addFile($event, 'tradeRegisterExcerptMediaIds')" />
    </div>
    <div class="col-12 col-md-6 mt-2">
      <div>
        <div class="d-inline-block">
          <b>{{ $t('message.applicationForm.idCard') }}</b><br>
          <span class="maxFileSize">max. 50MB</span>
        </div>
        <coozzy-button
          class="float-right"
          design="green"
          @click="uploadDocumentClicked('idCardUpload')">
          {{ $t('message.manageDocuments.addDocument') }}
        </coozzy-button>
      </div>
      <coozzy-spinner
        v-if="idCardUploadProcessing"
        class="align-self-center" />
      <div
        v-for="item in idCardMedia"
        :key="item.id"
        class="media-block col">
        <div
          class="file-block p-1 my-2">
          <a
            :href="item.url"
            data-v-09485260=""
            target="_blank">
            {{ item.filename }}
          </a>
          <coozzy-button
            class="delete-icon"
            design="transparent"
            @click="deleteMedia(item.id, 'idCardMedia')">
            <coozzy-delete-icon />
          </coozzy-button>
        </div>
      </div>
      <coozzy-form-file-input
        ref="idCardUpload"
        v-model="idCardUpload"
        accept="text/plain, text/csv, application/pdf, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
        class="d-none"
        @change="addFile($event, 'idCardMediaIds')" />
    </div>
    <div class="col-12 col-md-6 mt-2">
      <div>
        <div class="d-inline-block">
          <b>{{ $t('message.applicationForm.media') }}</b><br>
          <span class="maxFileSize">max. 50MB</span>
        </div>
        <coozzy-button
          class="float-right"
          design="green"
          @click="uploadDocumentClicked('mediaUpload')">
          {{ $t('message.manageDocuments.addDocument') }}
        </coozzy-button>
      </div>
      <coozzy-spinner
        v-if="mediaUploadProcessing"
        class="align-self-center" />
      <div
        v-for="item in media"
        :key="item.id"
        class="media-block col">
        <div
          class="file-block p-1 my-2">
          <a
            :href="item.url"
            data-v-09485260=""
            target="_blank">
            {{ item.filename }}
          </a>
          <coozzy-button
            class="delete-icon"
            design="transparent"
            @click="deleteMedia(item.id, 'mediaIds')">
            <coozzy-delete-icon />
          </coozzy-button>
        </div>
      </div>
      <coozzy-form-file-input
        ref="mediaUpload"
        v-model="mediaUpload"
        accept="text/plain, text/csv, application/pdf, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
        class="d-none"
        @change="addFile($event, 'mediaIds')" />
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import MediaApi from '../../../misc/apis/MediaApi'
import CoozzySpinner from '../../../framework/components/misc/CoozzySpinner'
import Vue from 'vue'
import { media } from '@/mixins/media'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon.vue'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput.vue'

export default {
  name: 'ApplicationFormCompanyDocuments',
  components: {
    CoozzyFormFileInput,
    CoozzyDeleteIcon,
    CoozzyButton,
    CoozzySpinner
  },
  mixins: [media],
  props: {
    company: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      debtEnforcementRegisterMedia: [],
      idCardMedia: [],
      tradeRegisterExcerptMedia: [],
      media: [],
      mediaUpload: null,
      mediaUploadProcessing: false,
      tradeRegisterExcerptUpload: null,
      tradeRegisterExcerptProcessing: false,
      idCardUpload: null,
      idCardUploadProcessing: false,
      debtUpload: null,
      debtUploadProcessing: false
    }
  },
  computed: {
    debtEnforcementRegisterMediaIds: {
      get() {
        return this.company.debtEnforcementRegisterMediaIds ? this.company.debtEnforcementRegisterMediaIds : []
      },
      set(value) {
        const com = this.company
        com.debtEnforcementRegisterMediaIds = value
        this.updateCompany(com)
      }
    },
    idCardMediaIds: {
      get() {
        return this.company.idCardMediaIds ? this.company.idCardMediaIds : []
      },
      set(value) {
        const com = this.company
        com.idCardMediaIds = value
        this.updateCompany(com)
      }
    },
    tradeRegisterExcerptMediaIds: {
      get() {
        return this.company.tradeRegisterExcerptMediaIds ? this.company.tradeRegisterExcerptMediaIds : []
      },
      set(value) {
        const com = this.company
        com.tradeRegisterExcerptMediaIds = value
        this.updateCompany(com)
      }
    },
    mediaIds: {
      get() {
        return this.company.mediaIds ? this.company.mediaIds : []
      },
      set(value) {
        const com = this.company
        com.mediaIds = value
        this.updateCompany(com)
      }
    }
  },
  methods: {
    ...mapMutations('applicationForm', ['updateCompany']),
    uploadDocumentClicked(ref) {
      if (!this.readOnly) {
        const btn = this.$refs[ref].$el.children
        btn[0].click()
      }
    },
    async addFile(event, source) {
      let file = null
      if (source === 'debtEnforcementRegisterMediaIds') {
        this.debtUploadProcessing = true
      } else if (source === 'idCardUpload') {
        this.idCardUploadProcessing = true
      } else if (source === 'mediaIds') {
        this.mediaUploadProcessing = true
      } else if (source === 'tradeRegisterExcerptMediaIds') {
        this.tradeRegisterExcerptProcessing = true
      }
      const files = await this.getFilesFromEvent(event)
      if (files.length > 0) {
        file = files[0]
        await this.documentCreated(file, source)
      }
      if (source === 'debtEnforcementRegisterMediaIds') {
        this.debtUploadProcessing = false
      } else if (source === 'idCardUpload') {
        this.idCardUploadProcessing = false
      } else if (source === 'mediaIds') {
        this.mediaUploadProcessing = false
      } else if (source === 'tradeRegisterExcerptMediaIds') {
        this.tradeRegisterExcerptProcessing = false
      }
    },
    async documentCreated(document, suffix) {
      const docSizeMB = document.size / (1024 * 1024)
      if (docSizeMB > 50) {
        this.mediaUploadProcessing = false
        this.debtUploadProcessing = false
        this.idCardUploadProcessing = false
        this.tradeRegisterExcerptProcessing = false
        Vue.toasted.show(this.$tc('message.uploadingErrors.maxFileSize'), { type: 'error' })
      } else {
        const selectedFile = document ? [document] : []
        const response = await this.uploadMedia(selectedFile)
        if (response.length > 0) {
          const media = response[0]
          const docs = this[suffix] ? this[suffix] : []
          docs.push(media.id)
          this[suffix] = docs
          const noIdSuffix = suffix.replace('Ids', '')
          this[noIdSuffix].push(media)
          this.mediaUpload = null
          this.debtUpload = null
          this.tradeRegisterExcerptUpload = null
          this.idCardUpload = null
        }
        this.mediaUploadProcessing = false
        this.debtUploadProcessing = false
        this.idCardUploadProcessing = false
        this.tradeRegisterExcerptProcessing = false
      }
    },
    deleteMedia(id, suffix) {
      this[suffix] = this[suffix].filter(e => e !== id)
      const noIdSuffix = suffix.replace('Ids', '')
      this[noIdSuffix] = this[noIdSuffix].filter(el => el.id !== id)
    }
  }
}
</script>

<style lang="scss" scoped>
.maxFileSize {
  font-size: 0.875rem;
}
</style>
