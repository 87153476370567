<template>
  <section>
    <div>
      <!-- Heading -->
      <coozzy-page-title
        :action-text="$tc('message.deepSign.digitalSignature')"
        :clear-filter="checkFilter"
        :count="signRequestsCount"
        :count-text="$tc('message.deepSign.title')"
        :title="$tc('message.deepSign.title')"
        @click="showDigitalSignModalEmpty"
        @clear-filter="clickOnClear" />
      <coozzy-card>
        <!-- Filter -->
        <div class="row mb-2">
          <div class="col-12 col-md mb-2 mb-md-0">
            <coozzy-form-input
              v-model="searchText"
              :placeholder="$tc('message.generic.search')"
              @keyup="filterSignDeepList" />
          </div>
          <div class="col-12 col-md-3 mb-2 mb-md-0">
            <coozzy-multiselect
              v-if="listContacts.length > 0"
              v-model="searchSigner"
              :clear-on-select="false"
              :close-on-select="false"
              :custom-label="customLabelSigner"
              :multiple="true"
              :options="availableSigner"
              :placeholder="$tc('message.tableColumnHeadings.signee')"
              :preselect-first="false"
              :preserve-search="true"
              :taggable="false"
              :track-by="'label'"
              @remove="filterSignDeepList"
              @select="filterSignDeepList" />
          </div>
          <div class="col-12 col-md-3 mb-2 mb-md-0">
            <coozzy-multiselect
              v-model="searchStatus"
              :clear-on-select="false"
              :close-on-select="false"
              :custom-label="customLabelStatus"
              :multiple="true"
              :options="availableStatus"
              :placeholder="$tc('message.generic.currentStatus')"
              :preselect-first="false"
              :preserve-search="true"
              :taggable="false"
              class="filter-inputs"
              @remove="filterSignDeepList"
              @select="filterSignDeepList" />
          </div>
          <div class="col-12 col-md-3 mb-2 mb-md-0">
            <coozzy-multiselect
              v-model="searchType"
              :clear-on-select="false"
              :close-on-select="false"
              :custom-label="customLabelType"
              :multiple="true"
              :options="availableType"
              :placeholder="$tc('message.deepSign.documentType')"
              :preselect-first="false"
              :preserve-search="true"
              :taggable="false"
              :track-by="'value'"
              @remove="filterSignDeepList"
              @select="filterSignDeepList" />
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-12 col-md-3 offset-md-9">
            <coozzy-form-select
              v-model="nbrPerPage"
              class="select-entries"
              name="numberEntries">
              <option value="5">
                5 {{ $t('message.generic.entries') }}
              </option>
              <option value="10">
                10 {{ $t('message.generic.entries') }}
              </option>
              <option value="15">
                15 {{ $t('message.generic.entries') }}
              </option>
              <option value="20">
                20 {{ $t('message.generic.entries') }}
              </option>
            </coozzy-form-select>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <b-table
              ref="deepSignTable"
              :busy="isBusy"
              :fields="fields"
              :items="filteredSignRequests"
              bordered
              hover
              selectable
              responsive="true"
              select-mode="single"
              stacked="md"
              @row-hovered="mouseEnterEvents"
              @row-unhovered="mouseLeaveEvents"
              @row-clicked="onRowClicked">
              <!-- Busy state -->
              <div
                slot="table-busy"
                class="text-center text-danger my-2">
                <coozzy-spinner />
              </div>

              <!-- Headings -->
              <template #head(created)>
                {{ $t('message.deepSign.created') }}
              </template>
              <template #head(contacts)>
                {{ $t('message.deepSign.contacts') }}
              </template>
              <template #head(contractNumber)>
                {{ $t('message.tableColumnHeadings.contractNumber') }}
              </template>
              <template #head(documentType)>
                {{ $t('message.deepSign.documentType') }}
              </template>
              <template #head(signers)>
                {{ $t('message.deepSign.signers') }}
              </template>
              <template #head(status)>
                {{ $t('message.deepSign.status.title') }}
              </template>

              <!-- Data cells -->
              <template
                #cell(created)="data">
                {{ data.item.created / 1000 | timestampToDate }}
              </template>
              <!-- name column -->
              <template
                #cell(contacts)="data">
                <template v-for="item in data.item.signers">
                  <p
                    :key="item.contactId"
                    class="mb-0">
                    <a
                      class="link contactName"
                      @click="redirectToDetailPage(item.contactId)"
                      @click.middle="redirectToDetailPage(item.contactId, true)"
                      @mousedown.middle.prevent.stop>
                      {{ getSignerName(item) }}
                    </a>
                  </p>
                </template>
              </template>
              <template
                #cell(contractNumber)="data">
                <a
                  v-if="!isMarketingModule"
                  class="link contractNumber"
                  @click="navigateToDetail(data.item)"
                  @click.middle="navigateToDetailMiddle(data.item)"
                  @mousedown.middle.prevent.stop>
                  {{ getTenancyContractNumber(data.item) }}
                </a>
                <span v-else>
                  {{ !data.item.cancelled ? getTenancyContractNumber(data.item) : '-' }}
                </span>
              </template>
              <template
                #cell(documentType)="data">
                {{
                  data.item.documentType ? $t('message.manageDocuments.type.' + data.item.documentType.toLowerCase()) : '-'
                }}
              </template>
              <!-- signers name  -->
              <template
                #cell(signers)="data">
                <div
                  id="link-button"
                  class="fieldToShow d-inline"
                  @mouseleave="showPopover = false"
                  @mousemove="onMouseMove($event)">
                  {{ getSignedSigners(data.item).length }}/{{ data.item.signers.length }}
                </div>
              </template>
              <!-- contactPerson Phone -->
              <template
                #cell(status)="data">
                {{ $t('message.deepSign.status.' + getStatus(data.item)) }}
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :per-page="nbrPerPage"
              :total-rows="signRequestsCount"
              align="center"
              class="mb-3" />
            <div
              v-if="showPopover"
              id="eventPopover"
              :style="{ left: page.left + 'px', top: page.top + 'px' }"
              class="eventPopover">
              <div
                v-if="selectedPopOverEvent"
                class="row">
                <div class="col employees-calendar">
                  <div class="row">
                    <div
                      v-for="item in selectedPopOverEvent.signers"
                      :key="item.contactId"
                      class="col-12">
                      {{ getSignerName(item) }}
                      <img
                        v-if="!item.signed"
                        :alt="'hourglass'"
                        height="15px"
                        src="@/assets/icon/hourglass.svg"
                        width="15px">
                      <coozzy-success-icon v-if="item.signed" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </coozzy-card>

      <digital-signature-modal
        v-if="showDigitalSignature"
        ref="digitalSignatureModal"
        :contacts="listContacts"
        :read-only="readOnlyDigitSign"
        :signature-request="selectedSignRequest"
        :tenancy="tenancySignRequest"
        @hide="hideDigitalSignatureModal"
        @cancel-signature="signatureRequestCanceled"
        @signature-added="signatureAdded" />
    </div>
  </section>
</template>

<script lang="ts">
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle.vue'
// import { required } from 'vuelidate/lib/validators'
import DeepSignApi from '@/misc/apis/DeepSignApi'
import Vue from 'vue'
import { responsivity } from '@/mixins/responsivity'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import ContactApi from '@/misc/apis/ContactApi'
import CoozzySuccessIcon from '@/framework/components/icons/CoozzySuccessIcon.vue'
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect.vue'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import UserApi from '@/misc/apis/UserApi'
import DigitalSignatureModal from '@/user/DigitalSignatureModal.vue'
import ObjectApi from '@/misc/apis/ObjectApi'
import { routeChecks } from '@/mixins/routeChecks'
import {
  Accountv2Account,
  BuildingBuilding,
  CommondeepsignSigner,
  CommondeepsignSignRequest,
  Contactcontactv2Company,
  ObjecttenancyTenancy, Useraccountv2ListByIdsResponse, V2Person
} from '@/types/api'

type itemFilter = {
  value: string,
  label: string,
  count?: number
}

export default Vue.extend({
  name: 'DeepSignOverview',
  components: {
    DigitalSignatureModal,
    CoozzyFormSelect,
    CoozzyMultiselect,
    CoozzyFormInput,
    CoozzyCard,
    CoozzyPageTitle,
    CoozzySuccessIcon,
    CoozzySpinner
  },
  mixins: [responsivity, routeChecks],
  data() {
    return {
      accounts: [] as Accountv2Account[],
      showDigitalSignature: false as boolean,
      showPopover: false as boolean,
      selectedPopOverEvent: null as CommondeepsignSignRequest,
      listContacts: [] as (Contactcontactv2Company | V2Person)[],
      documentsType: ['handover_protocol', 'maintenance_contract_doc_type', 'regulations', 'regulations_for_use_and_management', 'other', 'contract', 'agreement', 'key_list', 'letter'],
      page: {
        left: 0,
        top: 0
      },
      currentPage: 1 as number,
      isBusy: false as boolean,
      signRequests: [] as CommondeepsignSignRequest[],
      searchStatus: [] as string[],
      searchText: '' as string,
      searchSigner: [] as itemFilter[],
      searchType: [] as itemFilter[],
      availableStatus: [
        'cancelled',
        'complete',
        'incomplete'
      ] as string[],
      nbrPerPage: 5 as number,
      fields: [
        {
          key: 'created',
          label: this.$t('message.deepSign.created'),
          thClass: 'th-active',
          tdClass: 'align-middle border-right-0'
        },
        {
          key: 'contacts',
          label: this.$t('message.deepSign.contacts'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'contractNumber',
          label: this.$t('message.tableColumnHeadings.contractNumber'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'documentType',
          label: this.$t('message.deepSign.documentType'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'Signers',
          label: this.$t('message.deepSign.Signers'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'status',
          label: this.$t('message.deepSign.status.title'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      selectedSignRequest: null as CommondeepsignSignRequest,
      tenancySignRequest: null as ObjecttenancyTenancy,
      readOnlyDigitSign: false as boolean,
      listTenancies: [] as ObjecttenancyTenancy[],
      listBuilding: [] as BuildingBuilding[],
      filteredRequests: [] as CommondeepsignSignRequest[]
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.navigation.deepSign')
    }
  },
  computed: {
    signRequestsCount(): number {
      return this.filteredRequests.length || 0
    },
    filteredSignRequests(): CommondeepsignSignRequest[] {
      return this.filteredRequests.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
    },
    availableType(): itemFilter[] {
      const types = []
      const current = this
      for (let i = 0; i < this.documentsType.length; i++) {
        types.push({
          value: this.documentsType[i],
          label: current.$t('message.manageDocuments.type.' + this.documentsType[i])
        })
      }
      return types
    },
    availableSigner(): itemFilter[] {
      if (this.signRequests.length > 0) {
        let creatorList: CommondeepsignSignRequest[] = []
        const current = this
        let unassigned = 0
        const counts: itemFilter[] = []
        this.signRequests.forEach((element: CommondeepsignSignRequest) => {
          if (element.signers.length > 0) {
            creatorList.push(element)
          } else {
            unassigned += 1
          }
        })
        creatorList = [].concat.apply([], creatorList)
        creatorList.forEach(function (item) {
          item.signers.forEach(function (x: CommondeepsignSigner) {
            const existingCount = counts.filter(e => e.value === x.contactId)
            if (existingCount.length === 0) {
              const sameSigner = current.signRequests.filter((z: CommondeepsignSignRequest) => x && z.signers && z.signers.map(m => m.contactId).includes(x.contactId))
              const contact = current.listContacts.find((y: Contactcontactv2Company | V2Person) => y.id === x.contactId)
              if (contact) {
                if (sameSigner.length > 1) {
                  counts.push({
                    count: sameSigner.length,
                    value: x.contactId,
                    label: contact?.name || x.contactId
                  })
                } else {
                  counts.push({
                    count: 1,
                    value: x.contactId,
                    label: contact?.name || x.contactId
                  })
                }
              }
            }
          })
        })
        if (unassigned !== 0) {
          counts.push({
            count: unassigned,
            value: null,
            label: null
          })
        }
        return counts
      }
      return []
    },
    checkFilter(): boolean {
      return this.searchStatus.length > 0 || this.searchText !== '' || this.searchSigner.length > 0 || this.searchType.length > 0
    }
  },
  watch: {},
  mounted: function () {
    this.loadSignRequests()
  },
  methods: {
    getSignedSigners(item: CommondeepsignSignRequest): CommondeepsignSigner[] {
      return item.signers.filter((signer: CommondeepsignSigner) => signer.signed)
    },
    loadSignRequests() {
      this.isBusy = true
      DeepSignApi.listSignRequests(this.$store.getters['user/getAccountId'])
        .then(response => {
          this.signRequests = response.signRequests
          let contactIds: string[] = []
          let tenancyIds: string[] = []
          response.signRequests.map((signRequest) => {
            contactIds = contactIds.concat(signRequest.signers.map((signer) => {
              return signer.contactId
            }))
            const tenancyId = signRequest.attachToReferenceIds.find((ref) => ref.startsWith('tenancy_'))
            if (tenancyId) {
              tenancyIds.push(tenancyId)
            }
            return true
          })
          const uniqueIds = [...new Set(tenancyIds)]
          this.loadTenanciesByIds(uniqueIds)
          const ids = this.signRequests.map((signRequest: CommondeepsignSignRequest) => {
            return signRequest.issuer.accountId
          })
          UserApi.listAccountsByIds([...new Set(ids)])
            .then((response: Useraccountv2ListByIdsResponse) => {
              this.accounts = response.accounts
            })
            .catch(e => {
              console.error(e)
              Vue.toasted.show('Partner konnten nicht geladen werden', { type: 'error' })
            })
          ContactApi.contactResolve([...new Set(contactIds)])
            .then(responseCon => {
              this.listContacts = this.listContacts.concat((responseCon.persons as (Contactcontactv2Company | V2Person)[]).concat(responseCon.companies))
            })
            .catch(e => {
              console.error(e)
            })
          this.filterSignDeepList()
        })
        .catch(e => {
          console.error(e)
          Vue.toasted.show('Die Buchhaltungsstatistiken konnten nicht geladen werden', { type: 'error' })
        })
        .finally(() => {
          this.isBusy = false
        })
    },
    loadTenanciesByIds(tenancyIds: string[]) {
      ObjectApi.getTenanciesByIds(tenancyIds)
        .then(response => {
          this.listTenancies = response.tenancies
          const objectIds = this.listTenancies.map((tenancy: ObjecttenancyTenancy) => {
            return tenancy.objectId
          })
          if (objectIds.length > 0) {
            this.loadBuildings(objectIds)
          }
        })
        .catch(e => {
          console.error(e)
        })
    },
    loadBuildings(objectIds: string[]) {
      ObjectApi.getBuildingByObjectIds(objectIds).then(response => {
        this.listBuilding = response.buildings
      })
    },
    mouseLeaveEvents() {
      this.selectedPopOverEvent = null
    },
    mouseEnterEvents(event: CommondeepsignSignRequest) {
      this.selectedPopOverEvent = event
    },
    onMouseMove(e: any) {
      this.showPopover = this.hasSomeParentTheClass(e.target, 'fieldToShow')
      if (this.showPopover && document.getElementById('eventPopover')) {
        this.page.left = e.pageX - 200
        this.page.top = e.pageY - 220
      }
    },
    getStatus(item: CommondeepsignSignRequest) {
      if (item.cancelled) {
        return 'cancelled'
      } else if (item.signers.every((x) => x.signed) || item.completed) {
        return 'complete'
      } else if (item.signers.some(signer => !signer.signed)) {
        return 'incomplete'
      }
    },
    getSignerName(signer: CommondeepsignSigner): string {
      const contact = this.listContacts.find(c => c.id === signer.contactId || ('contactPersons' in c && c.contactPersons.some(cp => cp.id === signer.contactId)))
      if (contact && signer.contactId === contact.id) {
        return contact.name
      } else if (contact && 'contactPersons' in contact && contact.contactPersons.some(cp => cp.id === signer.contactId)) {
        const contactPerson = contact.contactPersons.find(cp => cp.id === signer.contactId)
        if (contactPerson) {
          return contactPerson.name
        }
      }
      return ''
    },
    filterSignDeepList() {
      this.$nextTick(function () {
        this.filteredRequests = this.signRequests.filter((item: CommondeepsignSignRequest) => {
          const contactFirstnameLastNameMaped = item.signers.map(x => this.getContactFirstnameLastname(x.contactId)).toString()
          const contactNameMaped = item.signers.map(x => this.getContactname(x.contactId)).toString()
          const cdtSearch = contactFirstnameLastNameMaped?.toLowerCase().includes(this.searchText?.toLowerCase()) || contactNameMaped?.toLowerCase().includes(this.searchText?.toLowerCase()) || this.getTenancyContractNumber(item).includes(this.searchText?.toLowerCase())
          const cdtSigner = this.searchSigner.some((r: itemFilter) => item.signers.map(b => b.contactId).indexOf(r.value) >= 0) || this.searchSigner.length === 0
          const cdtDocumentType = this.searchType.map((typ: itemFilter) => typ.value).includes(item.documentType?.toLowerCase()) || this.searchType.length === 0
          const cdtStatus = (this.searchStatus.includes(this.getStatus(item)) !== false) || this.searchStatus.length === 0
          return cdtSearch && cdtStatus && cdtDocumentType && cdtSigner
        })
      })
    },
    getContactFirstnameLastname(contactId: string) {
      const contact = this.listContacts.find(x => x.id === contactId)
      return contact ? contact.firstName + ' ' + contact.lastName : ''
    },
    getContactname(contactId: string) {
      const contact = this.listContacts.find(x => x.id === contactId)
      return contact ? contact.name : ''
    },
    customLabelStatus(status: string) {
      return this.$t('message.deepSign.status.' + status)
    },
    customLabelType(item: itemFilter) {
      return item.label
    },
    customLabelSigner(item: itemFilter) {
      if (item.value === null) {
        return `${this.$t('message.generic.unassigned')} (${item.count})`
      }
      return `${item.label} (${item.count})`
    },
    clickOnClear() {
      this.searchStatus = []
      this.searchText = ''
      this.searchSigner = []
      this.searchType = []
      this.filterSignDeepList()
    },
    redirectToDetailPage(contactId: string, newTab: boolean = false) {
      let name = ''
      if (this.isOwnerModule) {
        name = 'OwnerContactDetailsView'
      } else if (this.isAssetModule) {
        name = 'AssetContactDetailsView'
      } else if (this.isAdminModule) {
        name = 'AdminContactDetailsView'
      } else if (this.isAccountingModule) {
        name = 'AccountingContactDetailsView'
      } else {
        name = 'MarketingContactDetailsView'
      }
      if (newTab) {
        const routerHref = this.$router.resolve({
          name: name,
          params: { id: contactId }
        })
        window.open(routerHref.href, '_blank')
      } else {
        this.$router.push({
          name: name,
          params: { id: contactId }
        })
      }
    },
    hideDigitalSignatureModal() {
      this.showDigitalSignature = false
      this.readOnlyDigitSign = false
      this.selectedSignRequest = null
      this.tenancySignRequest = null
    },
    showDigitalSignModalEmpty() {
      this.readOnlyDigitSign = false
      this.show()
    },
    show() {
      this.showDigitalSignature = true
      this.$nextTick(() => {
        this.$refs.digitalSignatureModal?.show()
      })
    },
    onRowClicked(item: CommondeepsignSignRequest, index: number, event: any) {
      if (event.target.classList.contains('contactName') || event.target.classList.contains('contactName') || event.target.classList.contains('contractNumber') || event.target.classList.contains('contractNumber')) {
        event.preventDefault()
      } else {
        this.selectedSignRequest = item
        this.readOnlyDigitSign = true
        const tenancyId = item.attachToReferenceIds.find((ref) => ref.startsWith('tenancy_')) || null
        if (tenancyId) {
          this.tenancySignRequest = this.listTenancies.find((x: ObjecttenancyTenancy) => x.id === tenancyId)
        }
        this.show()
      }
    },
    signatureRequestCanceled(id: string) {
      this.signRequests.map((item: CommondeepsignSignRequest) => {
        if (item.id === id) {
          item.cancelled = true
        }
        return true
      })
    },
    signatureAdded(item: CommondeepsignSignRequest) {
      this.signRequests.unshift(item)
      this.loadSignRequests()
    },
    getTenancyContractNumber(request: CommondeepsignSignRequest) {
      if (request.attachToReferenceIds.length > 0) {
        const tenancyId = request.attachToReferenceIds.find((ref) => ref.startsWith('tenancy_')) || null
        if (tenancyId) {
          const tenancy = this.listTenancies.find((x: ObjecttenancyTenancy) => x.id === tenancyId)
          if (tenancy) {
            return tenancy.numericId
          }
        }
      }
      return '-'
    },
    navigateToDetail(item: CommondeepsignSignRequest) {
      if (item.attachToReferenceIds.length > 0) {
        const tenancyId = item.attachToReferenceIds.find((ref) => ref.startsWith('tenancy_')) || null
        if (tenancyId) {
          const tenancy = this.listTenancies.find((x: ObjecttenancyTenancy) => x.id === tenancyId)
          const building = this.listBuilding.find((x: BuildingBuilding) => x.objectIds.includes(tenancy.objectId))
          if (building) {
            let name = ''
            if (this.isOwnerModule) {
              name = 'OwnerBuildingDetailsView'
            } else if (this.isAssetModule) {
              name = 'AssetBuildingDetailsView'
            } else if (this.isAdminModule) {
              name = 'AdminBuildingDetailsView'
            } else if (this.isAccountingModule) {
              name = 'AccountingBuildingDetailsView'
            }
            this.$router.push({
              name: name,
              params: { id: building.id },
              query: {
                view: 'tenants',
                elementSelected: tenancyId
              }
            })
          }
        }
      }
    },
    navigateToDetailMiddle(item: CommondeepsignSignRequest) {
      if (item.attachToReferenceIds.length > 0) {
        const tenancyId = item.attachToReferenceIds.find((ref) => ref.startsWith('tenancy_')) || null
        if (tenancyId) {
          const tenancy = this.listTenancies.find((x: ObjecttenancyTenancy) => x.id === tenancyId)
          const building = this.listBuilding.find((x: BuildingBuilding) => x.objectIds.includes(tenancy.objectId))
          if (building) {
            let name = ''
            if (this.isOwnerModule) {
              name = 'OwnerBuildingDetailsView'
            } else if (this.isAssetModule) {
              name = 'AssetBuildingDetailsView'
            } else if (this.isAdminModule) {
              name = 'AdminBuildingDetailsView'
            } else if (this.isAccountingModule) {
              name = 'AccountingBuildingDetailsView'
            }
            const route = this.$router.resolve({
              name: name,
              params: { id: building.id },
              query: {
                view: 'tenants',
                elementSelected: tenancyId
              }
            })
            window.open(route.href, '_blank')
          }
        }
      }
    }
  }
})
</script>
<style lang="scss" scoped>

.eventPopover {
  position: absolute;
  z-index: 1000;
  pointer-events: none;

  .employees-calendar {
    border-radius: 5px;
    background: #15445f;
    opacity: 0.8;
    color: white;
    font-size: 14px;
    max-width: 200px;

    h4 {
      font-size: 20px;
    }
  }
}
</style>
