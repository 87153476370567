<template>
  <div class="col">
    <div
      slot="table-busy"
      class="text-center text-danger my-2" />
    <div
      class="row">
      <div class="col-md-5">
        <h3>
          {{ $t('message.processHandover.keys.existingKeys') }}
          <coozzy-info-circle-icon
            v-b-tooltip.hover.html="$t('message.processHandover.keys.existingKeysTooltip')"
            class="ml-2 mb-2 tooltip-message" />
        </h3>
        <p class="mb-0">
          {{ $t('message.processHandover.keys.existingKeysSubtitle') }}
        </p>
        <div
          class="list-group">
          <div
            v-for="device in allKeys"
            :key="(device.id || device.internalId) + '-' + device.type"
            :class="['list-group-item']">
            {{ (device.type === 'DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER' ? $t('message.processHandover.keys.listEntryTitleWireless') : $t('message.processHandover.keys.listEntryTitleKey')) + (device.lockingKey.description !== '' ? ' "' + device.lockingKey.description + '"' : '') }}
            <coozzy-button
              design="transparent"
              class="p-0 ml-1 mb-2 float-right"
              @click="showDeleteConfirmation(device, $event)">
              <coozzy-delete-icon />
            </coozzy-button>
            <coozzy-button
              design="transparent"
              class="p-0 mb-2 float-right ml-2"
              @click="editDevice(device, device.roomId ? findRoomById(device.roomId) : null)">
              <coozzy-edit-icon />
            </coozzy-button>
          </div>
        </div>
        <coozzy-button
          class="col-sm-12 col-lg-4 col-md-4 float-md-right mt-3"
          design="prop-green"
          @click="addKey">
          {{ $t('message.processHandover.keys.addKey') }}
        </coozzy-button>
        <b-modal
          :id="'editDevice'"
          ref="editDevice"
          :key="internalKey"
          no-close-on-backdrop
          :title="$t('message.onBoarding.buildings.objects.renovation.editDevice.title')"
          hide-footer>
          <div class="col">
            <device
              v-if="deviceToEdit"
              ref="object-device"
              :target="'object'"
              :from-handover="true"
              :from-key-step="true"
              :handover-rooms="preSelectedRooms"
              :device="deviceToEdit"
              :expand-by-default="true"
              :owner-id="object?.ownerId"
              @device-update="deviceUpdated" />
          </div>
          <div class="col mt-2">
            <coozzy-button
              size="small"
              class="float-left mb-0 border"
              design="transparent"
              @click="$bvModal.hide('editDevice')">
              {{ $t('message.generic.cancel') }}
            </coozzy-button>
            <coozzy-button
              design="green"
              class="float-right ml-3"
              @click="saveDevice()">
              {{ $t('message.generic.form.save') }}
            </coozzy-button>
          </div>
        </b-modal>
      </div>
      <div class="col-md-5 offset-2">
        <h3>
          {{ $t('message.processHandover.keys.suggestionsKeys.title') }}
          <coozzy-info-circle-icon
            v-b-tooltip.hover.html="$t('message.processHandover.keys.suggestionsKeysTooltip')"
            class="ml-2 mb-2 tooltip-message" />
        </h3>
        <p class="mb-0">
          {{ $t('message.processHandover.keys.suggestionsKeysSubtitle') }}
        </p>
        <coozzy-card class="list-group w-100 p-0">
          <div
            v-for="keyItem in computedAvailableKeys"
            :key="keyItem.id"
            class="list-group-item">
            <span>
              {{ $t('message.processHandover.keys.listEntryTitleKey') + (keyItem.value !== '' ? ' "' + $t(keyItem.value) + '"' : '') }}
            </span>
            <coozzy-button
              design="prop-green"
              class="p-0 ml-1 float-right px-2 add-to-available"
              @click="addKeyMethod(keyItem)">
              +
            </coozzy-button>
          </div>
        </coozzy-card>
        <b-modal
          :id="'confirmationModalDeleteKey_' + object.id"
          :ref="'confirmationModalDeleteKey_' + object.id"
          no-close-on-backdrop
          hide-footer
          :title="$t('message.processHandover.confirmationDeletion.title')">
          <template>
            <div class="col p-0">
              <p>{{ $t('message.processHandover.confirmationDeletion.body') }}</p>
            </div>
            <div class="col p-0">
              <coozzy-button
                size="small"
                class="mb-0 border"
                design="transparent"
                @click="$bvModal.hide('confirmationModalDeleteKey_' + object.id)">
                {{ $t('message.generic.cancel') }}
              </coozzy-button>
              <coozzy-button
                size="small"
                class="float-right mb-0"
                design="green"
                @click="deleteOneKey()">
                {{ $t('message.generic.delete') }}
              </coozzy-button>
            </div>
          </template>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { deviceUtils } from '@/mixins/deviceUtils'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon.vue'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'
import Device from '@/building/tabs/components/Device.vue'
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'

export default {
  name: 'HandoverKeysStep',
  components: {
    CoozzyCard,
    Device,
    CoozzyInfoCircleIcon,
    CoozzyDeleteIcon,
    CoozzyButton,
    CoozzyEditIcon
  },
  mixins: [deviceUtils],
  props: {
    object: {
      type: Object,
      required: true,
      default: () => null
    },
    preSelectedRooms: {
      type: Array,
      default: () => []
    },
    initKeys: {
      type: Array,
      default: () => []
    },
    devicesDeleted: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedDevice: null,
      deviceToEdit: null,
      internalKey: 0,
      selectedKeys: [],
      deletedKeys: [],
      keys: [],
      availableKeys: [
        { internalId: 1, name: 'basement', category: 1, value: 'message.processHandover.keys.suggestionsKeys.basement', type: 'DEVICE_TYPE_LOCKING_KEY', lockingKey: { combinedKeys: false, combinedKeysNote: '', description: this.$t('message.processHandover.keys.suggestionsKeys.basement'), lockingPlanNumber: '' } },
        { internalId: 2, name: 'attic', category: 1, value: 'message.processHandover.keys.suggestionsKeys.attic', type: 'DEVICE_TYPE_LOCKING_KEY', lockingKey: { combinedKeys: false, combinedKeysNote: '', description: this.$t('message.processHandover.keys.suggestionsKeys.attic'), lockingPlanNumber: '' } },
        { internalId: 3, name: 'washingCard', category: 1, value: 'message.processHandover.keys.suggestionsKeys.washingCard', type: 'DEVICE_TYPE_LOCKING_KEY', lockingKey: { combinedKeys: false, combinedKeysNote: '', description: this.$t('message.processHandover.keys.suggestionsKeys.washingCard'), lockingPlanNumber: '' } },
        { internalId: 4, name: 'letterbox', category: 1, value: 'message.processHandover.keys.suggestionsKeys.letterbox', type: 'DEVICE_TYPE_LOCKING_KEY', lockingKey: { combinedKeys: false, combinedKeysNote: '', description: this.$t('message.processHandover.keys.suggestionsKeys.letterbox'), lockingPlanNumber: '' } },
        { internalId: 5, name: 'flatKey', category: 1, value: 'message.processHandover.keys.suggestionsKeys.flatKey', type: 'DEVICE_TYPE_LOCKING_KEY', lockingKey: { combinedKeys: false, combinedKeysNote: '', description: this.$t('message.processHandover.keys.suggestionsKeys.flatKey'), lockingPlanNumber: '' } },
        { internalId: 6, name: 'garage', category: 2, value: 'message.processHandover.keys.suggestionsKeys.garage', type: 'DEVICE_TYPE_LOCKING_KEY', lockingKey: { combinedKeys: false, combinedKeysNote: '', description: this.$t('message.processHandover.keys.suggestionsKeys.garage'), lockingPlanNumber: '' } },
        { internalId: 7, name: 'handTransmitter', category: 2, value: 'message.processHandover.keys.suggestionsKeys.handTransmitter', type: 'DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER', lockingKey: { combinedKeys: false, combinedKeysNote: '', description: this.$t('message.processHandover.keys.suggestionsKeys.handTransmitter'), lockingPlanNumber: '' } }
      ]
    }
  },
  computed: {
    allKeys() {
      let allKeys = [...this.selectedKeys]

      if (this.preSelectedRooms && this.preSelectedRooms.length > 0) {
        this.preSelectedRooms.forEach(room => {
          if (room.devices) {
            const roomKeys = room.devices.filter(device =>
              (device.type === 'DEVICE_TYPE_LOCKING_KEY' ||
              device.type === 'DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER') &&
              !this.deletedKeys.includes(device.id)
            )
            allKeys = [...allKeys, ...roomKeys]
          }
        })
      }

      return allKeys.filter((key, index, self) =>
        index === self.findIndex((t) => (
          t.id === key.id
        ))
      )
    },
    computedAvailableKeys() {
      if (['APARTMENT', 'HOUSE', 'COMMERCIAL', 'SECONDARY_ROOM', 'GASTRONOMY'].includes(this.object.category)) {
        return this.availableKeys.filter(k => k.category === 1).sort((a, b) => this.$t(a.value).localeCompare(this.$t(b.value)))
      } else if (this.object.category === 'PARKING_SPACE') {
        return this.availableKeys.filter(k => k.category === 2).sort((a, b) => this.$t(a.value).localeCompare(this.$t(b.value)))
      }
      return []
    }
  },
  watch: {
    initKeys(newVal, oldVal) {
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal) || JSON.stringify(newVal) !== JSON.stringify(this.keys)) {
        this.keys = newVal
        this.updateSelectedKeys()
      }
    },
    selectedKeys(newVal) {
      this.$emit('update-keys', newVal, this.object, 'keys')
    },
    deletedKeys(newVal) {
      this.$emit('add-init-key')
      this.$emit('deleted-keys', newVal, this.object)
    },
    devicesDeleted(newVal) {
      this.deletedKeys = this.deletedKeys.concat(newVal)
      // remove duplicates
      this.deletedKeys = this.deletedKeys.filter((key, index, self) =>
          index === self.findIndex((t) => (
            t === key
          ))
      )
    }
  },
  mounted() {
    this.addPropertiesToDevices()
    this.updateSelectedKeys()
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),

    getRoomName(roomId) {
      const room = this.findRoomById(roomId)
      return room ? room.name : 'Unknown Room'
    },
    findRoomById(roomId) {
      if (!this.preSelectedRooms) return null
      return this.preSelectedRooms.find(room => room.id === roomId)
    },
    updateSelectedKeys() {
      this.initKeys.forEach(key => {
        if (key.id?.startsWith('device_') &&
            (key.type === 'DEVICE_TYPE_LOCKING_KEY' || key.type === 'DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER')) {
          this.selectedKeys.push(key)
        }
      })
      this.keys.forEach(key => {
        if (key.id?.startsWith('device_') &&
            (key.type === 'DEVICE_TYPE_LOCKING_KEY' || key.type === 'DEVICE_TYPE_LOCKING_WIRELESS_TRANSMITTER')) {
          this.selectedKeys.push(key)
        }
      })
      // remove deleted keys
      this.selectedKeys = this.selectedKeys.filter(key => !this.deletedKeys.includes(key.id))
      // remove duplicates
      this.selectedKeys = this.selectedKeys.filter((key, index, self) =>
          index === self.findIndex((t) => (
            t.id === key.id
          ))
      )
    },
    saveDevice() {
      if (!this.deviceToEdit) {
        this.$refs.editDevice.hide()
        return
      }

      const deviceCopy = JSON.parse(JSON.stringify(this.deviceToEdit))
      deviceCopy.edited = true

      if (deviceCopy.roomId) {
        const room = this.findRoomById(deviceCopy.roomId)
        if (room) {
          const updatedRooms = [...this.preSelectedRooms]
          const roomIndex = updatedRooms.findIndex(r => r.id === room.id)
          if (roomIndex !== -1) {
            const deviceIndex = updatedRooms[roomIndex].devices.findIndex(d => d.id === deviceCopy.id)
            if (deviceIndex !== -1) {
              updatedRooms[roomIndex].devices[deviceIndex] = deviceCopy
              this.$emit('selected-rooms', { object: this.object.id, rooms: updatedRooms }, this.object, 'device')
            }
          }
        }
      } else {
        const deviceIndex = this.selectedKeys.findIndex(d => d.id === deviceCopy.id)

        if (deviceIndex > -1) {
          this.$set(this.selectedKeys, deviceIndex, deviceCopy)
          this.$emit('update-keys', this.selectedKeys, this.object, 'keys')
        } else {
          this.selectedKeys.push(deviceCopy)
          this.$emit('update-keys', this.selectedKeys, this.object, 'keys')
        }
      }

      this.deviceToEdit = null
      this.$refs.editDevice.hide()
    },
    addKeyMethod(device) {
      device = JSON.parse(JSON.stringify(device))
      device.id = Date.now() + '_' + device.name + '_' + Math.random().toString(36).substring(2, 6)
      this.selectedKeys.push(device)
    },
    addKey() {
      let device = {
        id: Date.now() + '_DEVICE_TYPE_LOCKING_KEY_' + Math.random().toString(36).substring(2, 6),
        ownerId: this.object.ownerId,
        numericId: 99999,
        brand: '',
        referenceId: this.object.id,
        installation: null,
        uninstallation: null,
        measureUntil: null,
        measure: 'DEVICE_MEASURE_UNDEFINED',
        type: 'DEVICE_TYPE_LOCKING_KEY',
        system: 'DEVICE_SYSTEM_UNDEFINED',
        facilityPlant: 'DEVICE_FACILITY_PLANT_UNDEFINED',
        bkpH: '',
        lifeSpan: -1,
        amount: -1,
        value: '',
        acquisitionCosts: -1,
        unit: 'DEVICE_UNIT_UNDEFINED',
        cataloguePrice: -1,
        maintenance: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastMaintenance: null,
          nextMaintenance: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        inspection: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastOfficialControl: null,
          nextOfficialControl: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        revision: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastOfficialRevision: null,
          nextOfficialRevision: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        condition: 'DEVICE_CONDITION_UNDEFINED',
        note: '',
        documentIds: [],
        images: [],
        isNewItem: true,
        lockingKey: { combinedKeys: false, combinedKeysNote: '', description: '', lockingPlanNumber: '' }
      }
      this.selectedKeys.push(device)
    },
    deviceUpdated(device) {
      device.edited = true
      this.deviceToEdit = device
    },
    editDevice(device) {
      this.deviceToEdit = { ...device }
      this.internalKey++
      this.$nextTick(() => {
        this.$refs.editDevice.show()
      })
    },
    showDeleteConfirmation(device, e) {
      e.preventDefault()
      this.selectedDevice = device
      this.$bvModal.show('confirmationModalDeleteKey_' + this.object.id)
    },
    deleteOneKey() {
      this.$emit('add-init-key')
      this.deletedKeys.push(this.selectedDevice.id || this.selectedDevice.internalId)

      if (this.selectedDevice.roomId) {
        const updatedRooms = [...this.preSelectedRooms]
        const roomIndex = updatedRooms.findIndex(r => r.id === this.selectedDevice.roomId)
        if (roomIndex !== -1) {
          updatedRooms[roomIndex].devices = updatedRooms[roomIndex].devices.filter(
            d => d.id !== this.selectedDevice.id
          )
          this.$emit('selected-rooms', { object: this.object.id, rooms: updatedRooms }, this.object, 'device')
        }
      } else {
        this.selectedKeys = this.selectedKeys.filter(d => d.id !== this.selectedDevice.id)
        this.$emit('update-keys', this.selectedKeys, this.object, 'keys')
      }

      this.$bvModal.hide('confirmationModalDeleteKey_' + this.object.id)
    },
    addPropertiesToDevices() {
      this.availableKeys.forEach(device => {
        Object.assign(device, {
          id: Date.now() + '_' + device.name + '_' + Math.random().toString(36).substring(2, 6),
          ownerId: this.object.ownerId,
          numericId: 99999,
          brand: '',
          referenceId: this.object.id,
          installation: null,
          uninstallation: null,
          measureUntil: null,
          measure: 'DEVICE_MEASURE_UNDEFINED',
          type: device.type,
          system: this.availableSuggestions.find(suggestion => suggestion.type === device.type)?.system || 'DEVICE_SYSTEM_UNDEFINED',
          facilityPlant: this.availableSuggestions.find(suggestion => suggestion.type === device.type)?.facilityPlant || 'DEVICE_FACILITY_PLANT_UNDEFINED',
          bkpH: this.availableSuggestions.find(suggestion => suggestion.type === device.type)?.bkp || '',
          lifeSpan: -1,
          amount: -1,
          value: device.value,
          acquisitionCosts: -1,
          unit: 'DEVICE_UNIT_UNDEFINED',
          cataloguePrice: -1,
          maintenance: {
            contact: {
              userReference: '',
              accountId: -1
            },
            costs: {
              currency: 'CHF',
              interval: 'UNDEFINED_INTERVAL'
            },
            lastMaintenance: null,
            nextMaintenance: null,
            subscriptionStart: null,
            subscriptionEnd: null,
            note: ''
          },
          inspection: {
            contact: {
              userReference: '',
              accountId: -1
            },
            costs: {
              currency: 'CHF',
              interval: 'UNDEFINED_INTERVAL'
            },
            lastOfficialControl: null,
            nextOfficialControl: null,
            subscriptionStart: null,
            subscriptionEnd: null,
            note: ''
          },
          revision: {
            contact: {
              userReference: '',
              accountId: -1
            },
            costs: {
              currency: 'CHF',
              interval: 'UNDEFINED_INTERVAL'
            },
            lastOfficialRevision: null,
            nextOfficialRevision: null,
            subscriptionStart: null,
            subscriptionEnd: null,
            note: ''
          },
          condition: 'DEVICE_CONDITION_UNDEFINED',
          note: '',
          documentIds: [],
          images: [],
          isNewItem: true,
          lockingKey: device.lockingKey
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep #editDevice .modal-dialog {
  max-width: 80% !important;
}
.save-icon {
  position: absolute;
  right: 20px;
  top: 12px;
  z-index: 9;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card {
  width: 40%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.arrows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.arrows button {
  margin: 5px;
}

.list-group {
  min-height: 200px;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 10px;
  background-color: #fff;
}

.list-group-item {
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
  background-color: #f5f5f5;
}

.list-group-item.selected {
  background-color: #d3e2ff;
  border-color: #007bff;
}
.tooltip-message {
  width: 15px;
}
</style>
