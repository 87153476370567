<template>
  <section
    id="buildingObjectList">
    <div v-if="!onlyTable">
      <div
        id="createObjectRow"
        class="row justify-content-between pb-1">
        <div
          class="col-12 pl-0"
          :class="'col-md-8'">
          <h5 class="ml-3 pt-1">
            {{ filteredObjects.length }} {{ $t('message.onBoarding.buildings.objects.titleObjects' ) }}
          </h5>
        </div>
        <div
          v-if="isFiltered"
          class="col-12 col-md-2">
          <coozzy-button
            class="w-100 sort"
            @click="initFilter()">
            <coozzy-filter-icon /> {{ $t('message.generic.clearFilters') }}
          </coozzy-button>
        </div>
        <div
          v-if="isEditor && ((!isAccountingModule && !isAdminModule) || isAllowedToCreateObject)"
          class="col-12 col-md-2">
          <coozzy-button
            design="prop-green"
            class="w-100 sort"
            @click="createObjectClicked">
            {{ $t('message.onBoarding.buildings.objects.create') }}
            <span
              class="ml-1">
              <coozzy-info-circle-icon
                v-b-tooltip="{container: '#createObjectRow', title: $t('message.onBoarding.createObjectView.createObjectTooltip')}"
                class="text-white" />
            </span>
          </coozzy-button>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 col-md mb-2 mb-md-0">
          <coozzy-form-input
            ref="searchText"
            v-model="searchText"
            :placeholder="$t('message.generic.search')"
            @keyup="searchInputText()" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchStatus"
            class="filter-inputs"
            :options="sortedFilterValues(availableStatusOptions,customLabelStatus, 'value')"
            :multiple="true"
            :placeholder="$t('message.generic.currentStatus')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelStatus"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterObjectList"
            @remove="filterObjectList" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchFutureStatus"
            class="filter-inputs"
            :options="sortedFilterValues(availableFutureStatusOptions,customLabelFutureStatus, 'value')"
            :multiple="true"
            :placeholder="$t('message.generic.futureStatus')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelFutureStatus"
            :preselect-first="false"
            :taggable="false"
            :track-by="'value'"
            @select="filterObjectList"
            @remove="filterObjectList" />
        </div>
        <div
          v-if="property && property.type !== 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP'"
          class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchMarketingStatus"
            class="filter-inputs"
            :options="sortedFilterValues(availableMarketingStatusOptions, customLabelMarketingStatus, 'value')"
            :multiple="true"
            :placeholder="$t('message.generic.marketingStatus')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelMarketingStatus"
            :preselect-first="false"
            :taggable="false"
            :track-by="'value'"
            @select="filterObjectList"
            @remove="filterObjectList" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchCategories"
            class="filter-inputs"
            :options="sortedFilterValues(categoryFilter, customLabelCategory, 'value')"
            :multiple="true"
            :placeholder="$t('message.onBoarding.buildings.objects.category.title')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelCategory"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterObjectList"
            @remove="filterObjectList" />
        </div>
        <div
          class="col-12 col-md-2 mb-2">
          <coozzy-multiselect
            v-model="searchSubcategory"
            class="filter-inputs"
            :options="filterSubcategory"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.subCategory')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelSubCategory"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterObjectList"
            @remove="filterObjectList" />
        </div>
        <div
          class="col-12 col-md-2 mb-2 mb-md-0"
          :class="property && property.type !== 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP' ? 'mt-2' : 'mb-2'">
          <coozzy-multiselect
            v-model="searchFloor"
            class="filter-inputs"
            :options="floorFilter"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.floor')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelFloor"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterObjectList"
            @remove="filterObjectList" />
        </div>
        <div
          class="col-12 col-md-2 mt-2">
          <coozzy-multiselect
            v-model="searchRooms"
            class="filter-inputs"
            :options="roomsFilter"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.rooms')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabel"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterObjectList"
            @remove="filterObjectList" />
        </div>
        <div class="col-12 col-md-2 mt-2">
          <coozzy-assignee-multiselect
            v-model="searchAssignee"
            class="filter-inputs"
            :options="sortedFilterValues(assigneeFilter, customLabelAssignee, 'value')"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.assignee')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelAssignee"
            :track-by="'id'"
            :preselect-first="false"
            :taggable="false"
            @select="filterObjectList"
            @remove="filterObjectList" />
        </div>
        <div
          v-if="($route.path.startsWith('/owner') || $route.path.startsWith('/admin'))"
          class="col-12 col-md-2 mt-2">
          <coozzy-multiselect
            v-model="searchNetworkPartnerAsset"
            class="filter-inputs"
            :options="sortedFilterValues(networkFilterAsset, customLabelNetworkPartner, 'name')"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.asset')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelNetworkPartner"
            :track-by="'id'"
            :preselect-first="false"
            :taggable="false"
            @select="filterObjectList"
            @remove="filterObjectList" />
        </div>
        <div
          v-if="($route.path.startsWith('/owner') || $route.path.startsWith('/asset')) && property && property.type !== 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP'"
          class="col-12 col-md-2 mt-2">
          <coozzy-multiselect
            v-model="searchNetworkPartnerAdministration"
            class="filter-inputs"
            :options="sortedFilterValues(networkFilterAdministration, customLabelNetworkPartner, 'name')"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.administration')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelNetworkPartner"
            :track-by="'id'"
            :preselect-first="false"
            :taggable="false"
            @select="filterObjectList"
            @remove="filterObjectList" />
        </div>
        <div
          v-if="($route.path.startsWith('/admin') || $route.path.startsWith('/owner') || $route.path.startsWith('/asset')) && property && property.type !== 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP'"
          class="col-12 col-md-2 mt-2">
          <coozzy-multiselect
            v-model="searchNetworkPartnerMarketing"
            class="filter-inputs"
            :options="sortedFilterValues(networkFilterMarketing, customLabelNetworkPartner, 'name')"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.marketing')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelNetworkPartner"
            :track-by="'id'"
            :preselect-first="false"
            :taggable="false"
            @select="filterObjectList"
            @remove="filterObjectList" />
        </div>
        <div
          class="col-12 col-md-2 mt-2">
          <coozzy-multiselect
            v-model="searchPreferredCommunicationChannel"
            class="filter-inputs"
            :options="filterPreferredCommunicationChannel"
            :multiple="true"
            :placeholder="$t('message.contact.preferredCommunicationChannel')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelPreferredCommunicationChannel"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterObjectList"
            @remove="filterObjectList" />
        </div>
        <div
          class="col-12 col-md-2 mt-2">
          <coozzy-multiselect
            v-model="searchTags"
            class="filter-inputs"
            :options="filterTags"
            :multiple="true"
            :placeholder="$t('message.generic.tags')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabel"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterObjectList"
            @remove="filterObjectList" />
        </div>
        <div class="col-12 col-md-2 mt-2">
          <div
            id="date-picker-container">
            <coozzy-form-input
              v-model="searchDebitUntilFilterLabel"
              :placeholder="$t('message.tableColumnHeadings.debitUntil')" />
            <date-picker
              id="date-picker-input"
              v-model="searchDebitUntilFilter"
              :default-value="defaultCalendar"
              popup-class="date-picker-calendar"
              value-type="format"
              format="M/YYYY"
              title-format="M"
              :multiple="true"
              type="month"
              :lang="getLang"
              @open="filterObjectList"
              @input="filterObjectList"
              @calendar-change="dateChanged">
              <div
                slot="footer">
                <coozzy-form-checkbox
                  v-model="noDebitUntil"
                  :initial="noDebitUntil"
                  :class="'p-0 w-100'"
                  name="noDebitUntil"
                  button
                  @change="filterObjectList">
                  {{ $t('message.tableColumnHeadings.noDebitUntil.title') }}
                  <coozzy-info-circle-icon
                    v-b-tooltip.hover.html="$t('message.tableColumnHeadings.noDebitUntil.tooltip')"
                    class="ml-1" />
                </coozzy-form-checkbox>
              </div>
            </date-picker>
          </div>
        </div>
      </div>
      <div
        id="rowConfig"
        class="row mb-2 align-items-center">
        <div class="col-md-2 col-sm-6 col-12">
          <coozzy-dropdown
            design="green"
            size="sm"
            class="w-100 sort"
            :text="selectedEntriesText">
            <coozzy-dropdown-item
              v-if="isTicketingEnabled && (isEditor || isTicketEditor) && (isOwnerModule || isAssetModule)"
              :disabled="selectedEntries.length === 0"
              @click="createTicket('TICKET_TYPE_TASK')">
              {{ $t('message.ticketOverview.createTicket') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown
              v-if="!isOwnerModule && !isAssetModule && isTicketingEnabled && (isEditor || isTicketEditor)"
              design="transparent"
              size="sm"
              class="w-100 ticket-dropdown-bulk-action"
              :class="selectedEntries.length > 0 ? 'enabled' : ''"
              :disabled="true"
              :text="$t('message.ticketOverview.createTicket')"
              dropright
              @click.prevent>
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="navigateToCreateOrder()">
                {{ $t('message.ticketOverview.createOrder') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="createTicket('TICKET_TYPE_TASK')">
                {{ $t('message.ticketOverview.createTask') }}
              </coozzy-dropdown-item>
            </coozzy-dropdown>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="showActivity()">
              {{ $t('message.contact.activity.create') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="openReminderModal()">
              {{ $t('message.calendar.reminder.create') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="serialEmail()">
              {{ selectedEntries.length === 1 ? $t('message.generic.oneEmail') : $t('message.generic.serialEmail') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="serialLetter()">
              {{ selectedEntries.length === 1 ? $t('message.generic.oneSerialLetter') : $t('message.generic.serialLetter') }}
            </coozzy-dropdown-item>
            <template
              v-if="isAdmin && (isOwnerModule || isAssetModule)">
              <div
                class="row m-0"
                :class="selectedEntries.length === 0 || (hasSelectedEntriesAssetSet && isOwnerModule) ? 'coozzyDropdownItemTooltip' : ''">
                <coozzy-dropdown-item
                  class="col pr-0 pl-0"
                  :disabled="selectedEntries.length === 0 || (hasSelectedEntriesAssetSet && isOwnerModule)"
                  @click="openSetClientModal('MANAGEMENT_SCOPE')">
                  {{ $t('message.managementObjectsConfig.bulkActions.setAdministrationClient') }}
                </coozzy-dropdown-item>
                <div class="pl-0">
                  <coozzy-info-circle-icon
                    v-if="hasSelectedEntriesAssetSet && isOwnerModule"
                    v-b-tooltip.hover.html="$t('message.managementObjectsConfig.bulkActions.tooltip.administrationAction')"
                    class="mt-2" />
                </div>
              </div>
              <div
                v-if="property && property.type !== 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP'"
                class="row m-0"
                :class="selectedEntries.length === 0 || (hasSelectedEntriesAssetSet && isOwnerModule) ? 'coozzyDropdownItemTooltip' : ''">
                <coozzy-dropdown-item
                  class="col pr-0 pl-0"
                  :disabled="selectedEntries.length === 0 || (hasSelectedEntriesAssetSet && isOwnerModule)"
                  @click="openSetClientModal('MARKETING_SCOPE')">
                  {{ $t('message.managementObjectsConfig.bulkActions.setMarketingClient') }}
                </coozzy-dropdown-item>
                <div class="pl-0">
                  <coozzy-info-circle-icon
                    v-if="hasSelectedEntriesAssetSet && isOwnerModule"
                    v-b-tooltip.hover.html="$t('message.managementObjectsConfig.bulkActions.tooltip.marketingAction')"
                    class="mt-2" />
                </div>
              </div>
            </template>
            <coozzy-dropdown-space-divider
              v-if="isCompanyAdmin" />
            <div
              v-if="isCompanyAdmin"
              class="row m-0"
              :class="selectedEntries.length === 0 || isTenanciesActivated > 0 ? 'coozzyDropdownItemTooltip' : ''">
              <coozzy-dropdown-item
                class="col pr-0 pl-0"
                :disabled="selectedEntries.length === 0 || isTenanciesActivated > 0"
                @click="selectedEntries.length !== 0 ? openConfirmationModal() : ''">
                {{ $t('message.generic.delete') }}
              </coozzy-dropdown-item>
              <div class="pl-0">
                <coozzy-info-circle-icon
                  v-if="isTenanciesActivated > 0"
                  v-b-tooltip.hover.html="$t('message.deleteErrors.deleteActiveTenancyTooltip')"
                  class="mt-2" />
              </div>
            </div>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2 col-sm-6 col-12">
          <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :initial="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              name="selectAllEntries"
              button>
              {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: nbrTotalOfHits }) : $t('message.generic.selectHiddenEntries', { amount: nbrTotalOfHits }) }}
            </coozzy-form-checkbox>
          </template>
        </div>
        <div
          v-if="isCompanyAdmin === true"
          class="offset-md-5 col-md-1 d-flex justify-content-end align-items-center">
          <div
            @click="openAssembleListModal()">
            <coozzy-filters-icon
              v-b-tooltip="{container: '#rowConfig', title: $t('message.generic.tooltipAssembleList')}" />
          </div>
        </div>
        <div
          class="col-md-2 col-sm-6 col-12 mt-2 mt-sm-0"
          :class="isCompanyAdmin !== true ? 'offset-md-8' : ''">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries float-right">
            <option value="5">
              5 {{ $t('message.generic.entries') }}
            </option>
            <option value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option value="15">
              15 {{ $t('message.generic.entries') }}
            </option>
            <option value="20">
              20 {{ $t('message.generic.entries') }}
            </option>
            <option value="all">
              {{ $t('message.manageInterestedParties.filter.allEntries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
    </div>
    <b-table
      id="table-groups"
      ref="table-groups"
      hover
      bordered
      responsive="true"
      stacked="md"
      selectable
      select-mode="single"
      class="mb-4 overflow-auto shadow"
      :table-class="elementSelected ? 'b-table-selecting' : ''"
      :busy="isBusy"
      :fields="fields"
      :items="objectsPage"
      @row-clicked="objectClicked"
      @row-middle-clicked="onMiddlelicked">
      <!-- Busy state -->
      <div
        slot="table-busy"
        class="text-center text-danger my-2">
        <coozzy-spinner />
      </div>

      <!-- Headings -->
      <template #head(checkbox)>
        <coozzy-form-checkbox
          ref="header_checkbox"
          @change="(value) => { handleCheckboxesInsideTable(value) }" />
      </template>
      <template #head(status)>
        {{ $t('message.tableColumnHeadings.status') }}
      </template>
      <template #head(numericId)>
        {{ $t('message.tableColumnHeadings.numericId') }}
      </template>
      <template #head(objectNumber)>
        {{ $t('message.tableColumnHeadings.objectNumber') }}
      </template>
      <template #head(address)>
        {{ $t('message.tableColumnHeadings.address') }}
      </template>
      <template #head(objects)>
        {{ $t('message.tableColumnHeadings.objects') }}
      </template>
      <template #head(type)>
        {{ $t('message.tableColumnHeadings.type') }}
      </template>
      <template #head(floor)>
        {{ $t('message.tableColumnHeadings.floor') }}
      </template>
      <template #head(rooms)>
        {{ $t('message.tableColumnHeadings.rooms') }}
      </template>
      <template #head(area)>
        {{ $t('message.tableColumnHeadings.area') }}
      </template>
      <template #head(position)>
        {{ $t('message.tableColumnHeadings.position') }}
      </template>
      <template #head(condominiumOwner)>
        {{ $t('message.tableColumnHeadings.condominiumOwner') }}
      </template>
      <template #head(tenant)>
        {{ $t('message.tableColumnHeadings.tenant') }}
      </template>
      <template #head(startOfContract)>
        {{ $t('message.tableColumnHeadings.startOfContract') }}
      </template>
      <template #head(endOfContract)>
        {{ $t('message.tableColumnHeadings.endOfContract') }}
      </template>
      <template #head(debitUntil)>
        {{ $t('message.tableColumnHeadings.debitUntil') }}
      </template>
      <template #head(subCategory)>
        {{ $t('message.tableColumnHeadings.subCategory') }}
      </template>
      <template #head(ownershipStart)>
        {{ $t('message.tableColumnHeadings.ownershipStart') }}
      </template>
      <template #head(ownershipEnd)>
        {{ $t('message.tableColumnHeadings.ownershipEnd') }}
      </template>
      <template #head(subtenant)>
        {{ $t('message.tableColumnHeadings.subtenant') }}
      </template>
      <template #head(valueQuota)>
        {{ $t('message.tableColumnHeadings.valueQuota') }}
        <coozzy-info-circle-icon
          v-b-tooltip.hover.html="$t('message.tableColumnHeadings.totalValueQuota') + ' ' + formatValueQuota(totalPropertyValueQuota)"
          class="mt-2" />
      </template>
      <template #head(netRent)>
        {{ $t('message.tableColumnHeadings.netRent') }}
      </template>
      <template #head(grossRent)>
        {{ $t('message.tableColumnHeadings.grossRent') }}
      </template>
      <template #head(assignee)>
        {{ $t('message.tableColumnHeadings.assignee') }}
      </template>
      <template #head(partner)>
        {{ $t('message.tableColumnHeadings.partner') }}
      </template>
      <template #head(m2PerYear)>
        {{ $t('message.tableColumnHeadings.m2PerYear') }}
      </template>
      <template #head(marketNetRent)>
        {{ $t('message.tableColumnHeadings.marketNetRent') }}
      </template>
      <template #head(targetNetRent)>
        {{ $t('message.tableColumnHeadings.targetNetRent') }}
      </template>
      <template #head(marketGrossRent)>
        {{ $t('message.tableColumnHeadings.marketGrossRent') }}
      </template>
      <template #head(targetGrossRent)>
        {{ $t('message.tableColumnHeadings.targetGrossRent') }}
      </template>
      <template #head(roommate)>
        {{ $t('message.tableColumnHeadings.roommate') }}
      </template>
      <template #head(HeatingAdditionalCosts)>
        {{ $t('message.tableColumnHeadings.HeatingAdditionalCosts') }}
      </template>
      <!-- Body -->
      <template #cell(checkbox)="data">
        <coozzy-form-checkbox
          :id="'check_' + data.item.id"
          @change="(value) => { itemSelected(data.item, value) }" />
      </template>
      <template #cell(status)="data">
        <div
          class="color-box mr-2 mt-2"
          :class="getFullStatusColorClass(data.item)" />
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="getFullStatusText(data.item, true)" />
        <!-- eslint-enable vue/no-v-html -->
      </template>
      <template #cell(numericId)="data">
        {{ data.item.numericId | displayOptionalValue }}
      </template>
      <template #cell(valueQuota)="data">
        {{ getQuotaWithTotal(data.item) | displayOptionalValue }}
      </template>
      <template #cell(condominiumOwner)="data">
        <div v-if="data.item.condominiumOwnership && getCurrentCondominium(data.item.id)">
          <router-link
            v-for="(contactId, tenantIndex) in getCurrentCondominium(data.item.id).owner.contactIds"
            :key="contactId + tenantIndex"
            :to="redirectToDetailPage(contactId)">
            {{ tenantIndex === 0 ? getContactName(contactId) : ', ' + getContactName(contactId) }}
          </router-link>
        </div>
        <span v-else>-</span>
      </template>
      <template #cell(objectNumber)="data">
        {{ data.item.number | displayOptionalValue }}
      </template>
      <template #cell(objects)="data">
        {{ data.item.name | displayOptionalValue }}
      </template>
      <template #cell(address)="data">
        <address-text :address="data.item.address" />
      </template>
      <template #cell(type)="data">
        <template v-if="data.item.category !== 'UNDEFINED_CATEGORY'">
          {{ $t('message.onBoarding.buildings.objects.category.' + data.item.category.toLowerCase()) }}
        </template>
        <template v-else>
          -
        </template>
      </template>
      <template #cell(floor)="data">
        <div class="text-center">
          {{ formatFloor(data.item.characteristics ? data.item.characteristics.decimalFloor : '') }}
        </div>
      </template>
      <template #cell(rooms)="data">
        <div class="text-center">
          {{ data.item.characteristics.rooms | displayOptionalValue }}
        </div>
      </template>
      <template
        #cell(area)="data">
        {{ getArea(data.item.characteristics.areas) | formatArea }}
      </template>
      <template
        #cell(position)="data">
        {{ data.item.positions?.length > 0 ? $t('message.onBoarding.buildings.positionTypes.' + data.item.positions[0]) : '-' }}
      </template>
      <template #cell(partner)="data">
        <span
          v-if="!isOwnerModule"
          v-b-tooltip="{container: '#buildingObjectList', title: $t('message.generic.accountId') + ownerAccount.id}"
          class="d-block">
          <img
            height="20px"
            src="/img/propbase-icon.png"
            alt="prop-base">
          <template v-if="ownerAccount && ownerAccount.company">
            {{ ownerAccount.company.name }}
          </template>
          <template v-if="ownerAccount && ownerAccount.individual">
            {{ ownerAccount.individual.firstName.substr(0, 1) }}. {{ ownerAccount.individual.lastName }}
          </template>
        </span>
        <span
          v-if="!$route.path.startsWith('/asset') && getNetworkPartners(data.item.id).length > 0 && getNetworkPartners(data.item.id).find(element => element && element.scope ==='ASSET_SCOPE')"
          v-b-tooltip="{container: '#buildingObjectList', title: $t('message.generic.accountId') + getNetworkPartners(data.item.id).find(element => element && element.scope ==='ASSET_SCOPE').id}"
          class="d-block">
          <img
            height="20px"
            src="/img/assetbase-icon.png"
            alt="asset-base">
          <template v-if="getNetworkPartners(data.item.id).find(element => element.scope ==='ASSET_SCOPE').company">
            {{ getNetworkPartners(data.item.id).find(element => element.scope ==='ASSET_SCOPE').company.name }}
          </template>
          <template v-if="getNetworkPartners(data.item.id).find(element => element.scope ==='ASSET_SCOPE').individual">
            {{ getNetworkPartners(data.item.id).find(element => element.scope ==='ASSET_SCOPE').individual.firstName.substr(0, 1) }}. {{ getNetworkPartners(data.item.id).find(element => element && element.scope ==='ASSET_SCOPE').individual.lastName }}
          </template>
        </span>
        <span
          v-if="!$route.path.startsWith('/admin') && getNetworkPartners(data.item.id).length > 0 && getNetworkPartners(data.item.id).find(element => element && element.scope ==='MANAGEMENT_SCOPE')"
          v-b-tooltip="{container: '#buildingObjectList', title: $t('message.generic.accountId') + getNetworkPartners(data.item.id).find(element => element && element.scope ==='MANAGEMENT_SCOPE').id}"
          class="d-block">
          <img
            height="20px"
            src="/img/adminbase-icon.png"
            alt="admine-base">
          <template v-if="getNetworkPartners(data.item.id).find(x => x && x.scope ==='MANAGEMENT_SCOPE').company">
            {{ getNetworkPartners(data.item.id).find(x => x && x.scope ==='MANAGEMENT_SCOPE').company.name }}
          </template>
          <template v-if="getNetworkPartners(data.item.id).find(x => x && x.scope ==='MANAGEMENT_SCOPE').individual">
            {{ getNetworkPartners(data.item.id).find(x => x && x.scope ==='MANAGEMENT_SCOPE').individual.firstName.substr(0, 1) }}. {{ getNetworkPartners(data.item.id).find(x => x && x.scope && x.scope ==='MARKETING_SCOPE').individual.lastName }}
          </template>
        </span>
        <span
          v-if="!$route.path.startsWith('/accounting') && getNetworkPartners(data.item.id).length > 0 && getNetworkPartners(data.item.id).find(element => element && element.scope ==='ACCOUNTING_SCOPE')"
          v-b-tooltip="{container: '#buildingObjectList', title: $t('message.generic.accountId') + getNetworkPartners(data.item.id).find(element => element && element.scope ==='ACCOUNTING_SCOPE').id}"
          class="d-block">
          <img
            height="20px"
            src="/img/financebase-icon.png"
            alt="finance-base">
          <template v-if="getNetworkPartners(data.item.id).find(element => element && element.scope ==='ACCOUNTING_SCOPE') && getNetworkPartners(data.item.id).find(element => element && element.scope ==='ACCOUNTING_SCOPE').company">
            {{ getNetworkPartners(data.item.id).find(element => element.scope ==='ACCOUNTING_SCOPE').company.name }}
          </template>
          <template v-if="getNetworkPartners(data.item.id).find(element => element && element.scope ==='ACCOUNTING_SCOPE') && getNetworkPartners(data.item.id).find(element => element && element.scope ==='ACCOUNTING_SCOPE').individual">
            {{ getNetworkPartners(data.item.id).find(element => element.scope ==='ACCOUNTING_SCOPE').individual.firstName.substr(0, 1) }}. {{ getNetworkPartners(data.item.id).find(element => x.scope ==='ACCOUNTING_SCOPE').individual.lastName }}
          </template>
        </span>
        <span
          v-if="getNetworkPartners(data.item.id).length > 0 && getNetworkPartners(data.item.id).find(element => element.scope ==='MARKETING_SCOPE')"
          v-b-tooltip="{container: '#buildingObjectList', title: $t('message.generic.accountId') + getNetworkPartners(data.item.id).find(element => element.scope ==='MARKETING_SCOPE').id}"
          class="d-block">
          <img
            height="20px"
            src="/img/marketbase-icon.png"
            alt="market-base">
          <template v-if="getNetworkPartners(data.item.id).find(x => x && x.scope && x.scope ==='MARKETING_SCOPE').company">
            {{ getNetworkPartners(data.item.id).find(x => x && x.scope && x.scope ==='MARKETING_SCOPE').company.name }}
          </template>
          <template v-if="getNetworkPartners(data.item.id).find(element => element.scope ==='MARKETING_SCOPE').individual">
            {{ getNetworkPartners(data.item.id).find(element => element.scope ==='MARKETING_SCOPE').individual.firstName.substr(0, 1) }}. {{ getNetworkPartners(data.item.id).find(element => element.scope ==='MARKETING_SCOPE').individual.lastName }}
          </template>
        </span>
      </template>
      <template #cell(tenant)="data">
        <div v-if="!data.item.condominiumOwnership && getSortedContactIdsCurrentTenancy(data.item.id)">
          <router-link
            v-for="(contactId, tenantIndex) in getSortedContactIdsCurrentTenancy(data.item.id)"
            :key="contactId"
            :to="redirectToDetailPage(contactId)">
            {{ tenantIndex === 0 ? getContactName(contactId) : ', ' + getContactName(contactId) }}
          </router-link>
        </div>
        <div v-else-if="data.item.condominiumOwnership && getCurrentCondominiumTenancy(data.item.id) && showTenant(getCurrentCondominiumTenancy(data.item.id).endingAt)">
          <router-link
            v-for="(contactId, tenantIndex) in getCurrentCondominiumTenancy(data.item.id).contactIds"
            :key="contactId + tenantIndex"
            :to="redirectToDetailPage(contactId)">
            {{ tenantIndex === 0 ? getContactName(contactId) : ', ' + getContactName(contactId) }}
          </router-link>
        </div>
        <span v-else>-</span>
      </template>
      <template #cell(startOfContract)="data">
        <span
          v-if="getCurrentTenancy(data.item.id)">
          {{ getCurrentTenancy(data.item.id).startingAt | objectToDate }}
        </span>
        <span v-else>-</span>
      </template>
      <template #cell(endOfContract)="data">
        <span
          v-if="getCurrentTenancy(data.item.id)">
          {{ getCurrentTenancy(data.item.id).endingAt | objectToDate }}
        </span>
        <span v-else>-</span>
      </template>
      <template #cell(debitUntil)="data">
        <template v-if="data.item.debitUntil">
          {{ data.item.debitUntil | objectToDate }}
        </template>
        <template v-else>
          -
        </template>
      </template>
      <template
        #cell(HeatingAdditionalCosts)="data">
        <span class="float-right">
          {{ getHeatingAdditionalCosts(data.item.id) | formatPrice('CHF') }}
        </span>
      </template>
      <template #cell(subCategory)="data">
        <template v-if="data.item.subCategories.length > 0">
          <template v-for="(subCat, subCatIndex) in data.item.subCategories">
            <span
              v-if="subCat !== 'UNDEFINED_SUB_CATEGORY'"
              :key="subCatIndex">
              {{ $t(`message.onBoarding.buildings.objects.subCategory.` + subCat?.toLowerCase()) }}
            </span>
            <template v-else>
              -
            </template>
          </template>
        </template>
        <template v-else>
          -
        </template>
      </template>
      <template #cell(ownershipStart)="data">
        <span
          v-if="getCurrentCondominium(data.item.id)">
          {{ getCurrentCondominium(data.item.id).startingAt | objectToDate }}
        </span>
        <span v-else>-</span>
      </template>
      <template #cell(ownershipEnd)="data">
        <span
          v-if="getCurrentCondominium(data.item.id)">
          {{ getCurrentCondominium(data.item.id).endingAt | objectToDate }}
        </span>
        <span v-else>-</span>
      </template>
      <template #cell(subtenant)="data">
        <div v-if="!data.item.condominiumOwnership && getCurrentTenancy(data.item.id) && getCurrentTenancy(data.item.id).subTenants?.length > 0">
          <template
            v-for="(subtenant, subtenantIndex) in getCurrentTenancy(data.item.id).subTenants">
            <template
              v-if="subtenant.contactIds.length > 0">
              <router-link
                v-for="(contactId, tenantIndex) in subtenant.contactIds"
                :key="contactId"
                :to="redirectToDetailPage(contactId)">
                {{ tenantIndex === 0 ? getContactName(contactId) : ', ' + getContactName(contactId) }}
                <span v-if="tenantIndex === subtenant.contactIds.length - 1 && subtenantIndex < getCurrentTenancy(data.item.id).subTenants?.length - 1">,</span>
              </router-link>
            </template>
          </template>
        </div>
        <span v-else>-</span>
      </template>
      <template
        #cell(m2PerYear)="data">
        <div class="text-nowrap">
          <template v-if="getNetRentM2(data.item.id) !== 0">
            <span>{{ getNetRentM2(data.item.id) | formatPrice }}</span>
          </template>
          <template v-else>
            -
          </template>
        </div>
      </template>
      <template
        #cell(marketNetRent)="data">
        <div class="text-nowrap">
          <template v-if="data.item.pricing && data.item.pricing.marketNetRent">
            <span>{{ data.item.pricing.marketNetRent | formatPrice }}</span>
          </template>
          <template v-else>
            -
          </template>
        </div>
      </template>
      <template
        #cell(targetNetRent)="data">
        <div class="text-nowrap">
          <template v-if="data.item.pricing && data.item.pricing.targetMarketNetRent">
            <span>{{ data.item.pricing.targetMarketNetRent | formatPrice }}</span>
          </template>
          <template v-else>
            -
          </template>
        </div>
      </template>
      <template
        #cell(marketGrossRent)="data">
        <div class="text-nowrap">
          <template v-if="data.item.pricing && data.item.pricing.marketGrossRent">
            <span>{{ data.item.pricing.marketGrossRent | formatPrice }}</span>
          </template>
          <template v-else>
            -
          </template>
        </div>
      </template>
      <template
        #cell(targetGrossRent)="data">
        <div class="text-nowrap">
          <template v-if="data.item.pricing && data.item.pricing.targetMarketGrossRent">
            <span>{{ data.item.pricing.targetMarketGrossRent | formatPrice }}</span>
          </template>
          <template v-else>
            -
          </template>
        </div>
      </template>
      <template #cell(netRent)="data">
        <div class="text-nowrap">
          <span>{{ getColdRentPerMonth(data.item.id) | formatPrice('CHF') }}
            <coozzy-info-circle-icon
              v-if="getCurrentTenancy(data.item.id) && (getCurrentTenancy(data.item.id).taxable || getCurrentTenancy(data.item.id).opted)"
              v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.inclTax')"
              class="ml-1" />
          </span>
        </div>
      </template>
      <template #cell(grossRent)="data">
        <span class="text-nowrap">{{ getTotalRent(data.item.id) | formatPrice('CHF') }}</span>
        <coozzy-info-circle-icon
          v-if="getCurrentTenancy(data.item.id) && (getCurrentTenancy(data.item.id).taxable || getCurrentTenancy(data.item.id).opted)"
          v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.inclTax')"
          class="ml-1" />
      </template>
      <template #cell(assignee)="data">
        <span>
          <span v-if="isOwnerModule"> {{ data.item.contacts && data.item.contacts.contactIdAssigneeOwner !== '' ? getContactName(data.item.contacts.contactIdAssigneeOwner) : '-' }} </span>
          <span v-if="isAssetModule"> {{ data.item.contacts && data.item.contacts.contactIdAssigneeAsset !== '' ? getContactName(data.item.contacts.contactIdAssigneeAsset) : '-' }} </span>
          <span v-if="isAccountingModule"> {{ data.item.contacts && data.item.contacts.contactIdAssigneeAccounting !== '' ? getContactName(data.item.contacts.contactIdAssigneeAccounting) : '-' }} </span>
          <span v-if="isAdminModule">{{ data.item.contacts && data.item.contacts.contactIdAssigneeAdministration !== '' ? getContactName(data.item.contacts.contactIdAssigneeAdministration) : '-' }} </span>
        </span>
      </template>
      <template #cell(roommate)="data">
        <div v-if="getCurrentCondominium(data.item.id)?.owner?.roommateContactIds?.length > 0">
          <router-link
            v-for="(roommateContactId, roommateIndex) in getCurrentCondominium(data.item.id).owner.roommateContactIds"
            :key="roommateContactId + roommateIndex"
            :to="redirectToDetailPage(roommateContactId)">
            {{ roommateIndex === 0 ? getContactName(roommateContactId) : ', ' + getContactName(roommateContactId) }}
          </router-link>
        </div>
        <span v-else>-</span>
      </template>
      <!-- footer total -->
      <template
        v-if="property && property.type !== 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP' && fieldsIncludeCalculatedRent()"
        #custom-foot>
        <!-- Total -->
        <tr>
          <th
            v-for="field in fields"
            :key="field.key"
            class="shadow-none pb-1 pl-2 pr-2 pt-2"
            :class="field.key === 'status' ? 'text-left' : 'text-right'">
            <template v-if="field.key === 'status'">
              {{ $t('message.generic.total') }}
            </template>
            <template v-else-if="field.key === 'netRent'">
              {{ totalNetRent.sum | formatPrice }}
            </template>
            <template v-else-if="field.key === 'grossRent'">
              {{ totalGrossRent.sum | formatPrice }}
            </template>
            <template v-else-if="field.key === 'marketNetRent'">
              {{ totalMarketNetRent.sum | formatPrice }}
            </template>
            <template v-else-if="field.key === 'targetNetRent'">
              {{ totalTargetNetRent.sum | formatPrice }}
            </template>
            <template v-else-if="field.key === 'marketGrossRent'">
              {{ totalMarketGrossRent.sum | formatPrice }}
            </template>
            <template v-else-if="field.key === 'targetGrossRent'">
              {{ totalTargetGrossRent.sum | formatPrice }}
            </template>
            <template v-else>
              <span />
            </template>
          </th>
        </tr>
        <!-- Total without vacancy -->
        <tr>
          <th
            v-for="field in fields"
            :key="field.key"
            class="shadow-none pl-2 pr-2 p-1"
            :class="field.key === 'status' ? 'text-left alignFoot' : 'text-right'">
            <template v-if="field.key === 'status'">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="$t('message.generic.totalWithoutVacancy')" />
            </template>
            <template v-else-if="field.key === 'netRent'">
              {{ totalNetRent.tenancy | formatPrice }}
            </template>
            <template v-else-if="field.key === 'grossRent'">
              {{ totalGrossRent.tenancy | formatPrice }}
            </template>
            <template v-else-if="field.key === 'marketNetRent'">
              {{ totalMarketNetRent.tenancy | formatPrice }}
            </template>
            <template v-else-if="field.key === 'targetNetRent'">
              {{ totalTargetNetRent.tenancy | formatPrice }}
            </template>
            <template v-else-if="field.key === 'marketGrossRent'">
              {{ totalMarketGrossRent.tenancy | formatPrice }}
            </template>
            <template v-else-if="field.key === 'targetGrossRent'">
              {{ totalTargetGrossRent.tenancy | formatPrice }}
            </template>
            <template v-else>
              <span />
            </template>
          </th>
        </tr>
        <!-- Total vacancy -->
        <tr>
          <th
            v-for="field in fields"
            :key="field.key"
            class="pt-1 pl-2 pr-2 pb-2"
            :class="field.key === 'status' ? 'text-left alignFoot' : 'text-right'">
            <template v-if="field.key === 'status'">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <span v-html="$t('message.generic.totalVacancy')" />
            </template>
            <template v-else-if="field.key === 'netRent'">
              {{ totalNetRent.vacancy | formatPrice }} ({{ ((totalNetRent.vacancy / totalNetRent.sum) * 100) || 0 | formatPercent }})
            </template>
            <template v-else-if="field.key === 'grossRent'">
              {{ totalGrossRent.vacancy | formatPrice }} ({{ ((totalGrossRent.vacancy / totalGrossRent.sum) * 100) || 0 | formatPercent }})
            </template>
            <template v-else-if="field.key === 'marketNetRent'">
              {{ totalMarketNetRent.vacancy | formatPrice }}
            </template>
            <template v-else-if="field.key === 'targetNetRent'">
              {{ totalTargetNetRent.vacancy | formatPrice }}
            </template>
            <template v-else-if="field.key === 'marketGrossRent'">
              {{ totalMarketGrossRent.vacancy | formatPrice }}
            </template>
            <template v-else-if="field.key === 'targetGrossRent'">
              {{ totalTargetGrossRent.vacancy | formatPrice }}
            </template>
            <template v-else>
              <span />
            </template>
          </th>
        </tr>
      </template>
      <template #foot(HeatingAdditionalCosts)>
        <span />
      </template>
    </b-table>
    <b-table
      id="responsiveFooterTable"
      stacked
      :items="responsiveTableItems">
      <template #head(total)>
        {{ $t('message.generic.totalWithoutVacancy') }}
      </template>
      <template #head(netRent)>
        {{ $t('message.onBoarding.buildings.objects.pricing.coldRent') }}
      </template>
      <template #head(grossRent)>
        {{ $t('message.onBoarding.buildings.objects.pricing.totalRent') }}
      </template>
      <template
        #cell(netRent)>
        {{ totalNetRent.sum | formatPrice }}
      </template>
      <template
        #cell(grossRent)>
        {{ totalGrossRent.sum | formatPrice }}
      </template>
    </b-table>
    <b-pagination
      v-if="filteredObjects.length > 0 && !onlyTable"
      v-model="currentPage"
      align="center"
      class="mt-3 mb-3"
      :total-rows="filteredObjects.length"
      :per-page="nbrPerPage === 'all' ? filteredObjects.length : nbrPerPage" />
    <reminder-creation-modal
      v-if="selectForReminder"
      ref="reminder-creation"
      :source="'object'"
      :references-ids="selectedEntriesIds"
      @new-reminder="clearCheckbox" />
    <b-modal
      :id="'addObjectModal'"
      ref="addObjectModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.managementOverview.createObject')">
      <div
        v-for="(object, indexObj) in listNewObjects"
        :key="indexObj"
        class="row details-row-bg-1 py-01rem">
        <div class="col-4">
          <label for="category">{{ $t('message.onBoarding.buildings.objects.category.title') }}{{ '*' }}</label>
          <coozzy-form-select
            id="category"
            v-model="object.category"
            :state="$v.listNewObjects.$each[indexObj].category.$dirty && $v.listNewObjects.$each[indexObj].category.$error ? false : null">
            <option value="UNDEFINED_CATEGORY" />
            <option
              v-for="(categoryValue, categoryIndex) in availableCategories"
              :key="categoryIndex"
              :value="categoryValue">
              {{ $t(`message.onBoarding.buildings.objects.category.${categoryValue.toLowerCase()}`) }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col-4">
          <coozzy-form-input
            v-model="object.amount"
            type="number"
            min="0"
            :is-integer="true"
            :name="$t('message.propertiesView.objectCount') + '*'"
            :state="$v.listNewObjects.$each[indexObj].amount.$dirty && $v.listNewObjects.$each[indexObj].amount.$error ? false : null" />
        </div>
        <div
          v-if="listNewObjects.length > 1"
          class="col-auto">
          <coozzy-button
            class="w-100 mt-4"
            design="red"
            @click="removeObject(indexObj)">
            {{ $t('message.advertisementManagementOverview.deletion.confirmation.title') }}
          </coozzy-button>
        </div>
      </div>
      <div class="row">
        <div class="col-auto">
          <coozzy-button
            class="w-100 mt-2"
            design="prop-green"
            @click="addObject()">
            {{ $t('message.onBoarding.buildings.objects.add') }}
          </coozzy-button>
        </div>
      </div>
      <div class="row">
        <div class="col mt-3">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('addObjectModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="createObjects()">
            {{ $t('message.generic.form.save') }}
          </coozzy-button>
        </div>
      </div>
    </b-modal>
    <b-modal
      :id="'confirmationModal'"
      ref="confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.advertisementManagementOverview.deletion.confirmation.title')">
      <div
        v-if="loadingDeletion"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <template v-else>
        <div class="col p-0">
          <p>{{ $t('message.advertisementManagementOverview.deletion.confirmation.text') }}</p>
        </div>
        <div class="col p-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('confirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deleteObject()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      :id="'shareconfirmationModal'"
      ref="shareconfirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="scope === 'MARKETING_SCOPE' ? $t('message.managementObjectsConfig.modal.titleSetMarketingClient') : scope === 'ASSET_SCOPE' ? $t('message.managementObjectsConfig.modal.titleSetAssetManager') : $t('message.managementObjectsConfig.modal.titleSetAdministrationClient')">
      <div
        v-if="loading"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <template v-else>
        <div class="col">
          <p>{{ scope === 'MARKETING_SCOPE' ? $t('message.managementObjectsConfig.modal.bodySetMarketingClient', { SELECTED_OBJECT_COUNT: selectedEntries.length , SELECTED_MARKETING_NAME: selectedClientName }) : scope === 'ASSET_SCOPE' ? $t('message.managementObjectsConfig.modal.bodySetAssetManager', { SELECTED_OBJECT_COUNT: selectedEntries.length , SELECTED_ASSET_NAME: selectedClientName }) : $t('message.managementObjectsConfig.modal.bodySetAdministrationClient', { SELECTED_OBJECT_COUNT: selectedEntries.length , SELECTED_ADMINISTRATION_NAME: selectedClientName }) }}</p>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('shareconfirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            :disabled="loading"
            @click="saveShares()">
            {{ $t('message.managementObjectsConfig.modal.confirmationBtn') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <assemble-list-modal
      ref="assembleListModal"
      modal-id="assembleListModal"
      :fields="selectedFields"
      :default-fields="dataFields"
      @save-fields="saveFields" />
    <set-advert-relations
      ref="setClientModal"
      :title="scope === 'MARKETING_SCOPE' ? $t('message.managementObjectsConfig.bulkActions.setMarketingClient') : scope === 'ASSET_SCOPE' ? $t('message.managementObjectsConfig.bulkActions.setAssetManager') : $t('message.managementObjectsConfig.bulkActions.setAdministrationClient')"
      :element-list="filtredNetworkPartner()"
      type="networkPartner"
      @save="setClient" />
    <activate-condominium-modal
      v-if="selectedEntries.length > 0"
      ref="activateCondominiumModal"
      :selected-entries-ids="idsOfCurrentCondominiums"
      @condo-activated="condominiumActivated" />
    <create-activity-modal
      ref="activity-creation"
      :key="keyActivity"
      :ids="selectedEntriesIds"
      @activity-created="clearCheckbox" />
  </section>
</template>

<script>

import { onboarding } from '@/mixins/onboarding'
import { formatting } from '@/mixins/formatting'
import ActivateCondominiumModal from '@/addTenancy/ActivateCondominiumModal'
import { user } from '@/mixins/user'
import AddressText from '@/framework/components/misc/AddressText'
import ObjectApi from '../../../misc/apis/ObjectApi'
import ShareApi from '../../../misc/apis/ShareApi'
import ContactApi from '@/misc/apis/ContactApi'
import SetAdvertRelations from '@/marketingBasic/components/advertisements/management/SetAdvertRelations.vue'
import CoozzyFormSelect from '../../../framework/components/form/select/CoozzyFormSelect'
import CoozzyButton from '../../../framework/components/button/CoozzyButton'
import CoozzySpinner from '../../../framework/components/misc/CoozzySpinner'
import CoozzyMultiselect from '../../../framework/components/multiselect/CoozzyMultiselect'
import CoozzyAssigneeMultiselect from '../../../framework/components/multiselect/CoozzyAssigneeMultiselect'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import Vue from 'vue'
import debounce from 'debounce'
import { tables } from '@/mixins/tables'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem'
import CoozzyDropdownSpaceDivider from '@/framework/components/dropdown/CoozzyDropdownSpaceDivider'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyFilterIcon from '@/framework/components/icons/CoozzyFilterIcon'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { routeChecks } from '@/mixins/routeChecks'
import { obj } from '@/mixins/object'
import { validation } from '@/mixins/validation'
import { required } from 'vuelidate/lib/validators'
import { algolia } from '@/mixins/algolia'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon'
import { mail } from '@/mixins/mail'
import { dateUtils } from '@/mixins/dateUtils'
import CoozzyFiltersIcon from '@/framework/components/icons/CoozzyFiltersIcon'
import AssembleListModal from '@/contact/components/AssembleListModal'
import SearchApi from '@/misc/apis/SearchApi'
import store from '@/store/store'
import CreateActivityModal from '@/contact/components/CreateActivityModal'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/de'
import { share } from '@/mixins/share'
import { v4 as uuidv4 } from 'uuid'
import { referenceInterestRate } from '@/mixins/referenceInterestRate'
export default {
  name: 'BuildingObjectList',
  components: {
    CoozzyFilterIcon,
    ReminderCreationModal,
    ActivateCondominiumModal,
    CoozzyFormCheckbox,
    AddressText,
    CoozzyDropdownSpaceDivider,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyFormInput,
    CoozzyMultiselect,
    CoozzyAssigneeMultiselect,
    CoozzyFormSelect,
    CoozzySpinner,
    CoozzyButton,
    SetAdvertRelations,
    CoozzyInfoCircleIcon,
    CoozzyFiltersIcon,
    AssembleListModal,
    CreateActivityModal,
    DatePicker
  },
  mixins: [user, onboarding, obj, formatting, tables, routeChecks, validation, algolia, mail, dateUtils, share, referenceInterestRate],
  props: {
    elementSelected: {
      type: String,
      default: ''
    },
    objectFilters: {
      type: String,
      default: ''
    },
    objectIds: {
      type: Array,
      default: () => []
    },
    onlyTable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      availableCategories: ['APARTMENT', 'HOUSE', 'COMMERCIAL', 'GASTRONOMY', 'PARKING_SPACE', 'GARDEN', 'SECONDARY_ROOM', 'PLOT', 'AGRICULTURE'],
      listNewObjects: [{
        administrationType: 'UNDEFINED_ADMINISTRATION_TYPE',
        category: '',
        amount: null
      }],
      nbrPerPage: 5,
      currentPage: 1,
      isBusy: false,
      selectForReminder: false,
      selectedEntries: [],
      scope: null,
      filteredObjects: [],
      searchText: '',
      searchCategories: [],
      searchRooms: [],
      searchFloor: [],
      searchZip: [],
      searchNetworkPartner: [],
      searchNetworkPartnerAsset: [],
      searchNetworkPartnerMarketing: [],
      searchNetworkPartnerAdministration: [],
      searchPreferredCommunicationChannel: [],
      searchTags: [],
      searchSubcategory: [],
      searchAssignee: [],
      searchStatus: [],
      searchFutureStatus: [],
      searchMarketingStatus: [],
      sortedObjectList: [],
      loading: false,
      loadingDeletion: false,
      isLoaded: false,
      selectedClient: null,
      typeOfModule: null,
      keyActivity: 0,
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        }
      ],
      dataFields: [
        {
          key: 'status',
          label: this.$t('message.tableColumnHeadings.status'),
          thClass: 'align-middle alignFoot',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'objects',
          label: this.$t('message.tableColumnHeadings.objects'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'objectNumber',
          label: this.$t('message.tableColumnHeadings.objectNumber'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'numericId',
          label: this.$t('message.tableColumnHeadings.numericId'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'address',
          label: this.$t('message.tableColumnHeadings.address'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'type',
          label: this.$t('message.tableColumnHeadings.type'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'floor',
          label: this.$t('message.tableColumnHeadings.floor'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'rooms',
          label: this.$t('message.tableColumnHeadings.rooms'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'area',
          label: this.$t('message.tableColumnHeadings.area'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'position',
          label: this.$t('message.tableColumnHeadings.position'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'condominiumOwner',
          label: this.$t('message.tableColumnHeadings.condominiumOwner'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'valueQuota',
          label: this.$t('message.tableColumnHeadings.valueQuota'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'tenant',
          label: this.$t('message.tableColumnHeadings.tenant'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'netRent',
          label: this.$t('message.tableColumnHeadings.netRent'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'grossRent',
          label: this.$t('message.tableColumnHeadings.grossRent'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'assignee',
          label: this.$t('message.tableColumnHeadings.assignee'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'partner',
          label: this.$t('message.tableColumnHeadings.partner'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'startOfContract',
          label: this.$t('message.tableColumnHeadings.startOfContract'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'endOfContract',
          label: this.$t('message.tableColumnHeadings.endOfContract'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'ownershipStart',
          label: this.$t('message.tableColumnHeadings.ownershipStart'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'ownershipEnd',
          label: this.$t('message.tableColumnHeadings.ownershipEnd'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'subtenant',
          label: this.$t('message.tableColumnHeadings.subtenant'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'm2PerYear',
          label: this.$t('message.tableColumnHeadings.m2PerYear'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'marketNetRent',
          label: this.$t('message.tableColumnHeadings.marketNetRent'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'targetNetRent',
          label: this.$t('message.tableColumnHeadings.targetNetRent'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'marketGrossRent',
          label: this.$t('message.tableColumnHeadings.marketGrossRent'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'targetGrossRent',
          label: this.$t('message.tableColumnHeadings.targetGrossRent'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right'
        },
        {
          key: 'roommate',
          label: this.$t('message.tableColumnHeadings.roommate'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'debitUntil',
          label: this.$t('message.tableColumnHeadings.debitUntil'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'subCategory',
          label: this.$t('message.tableColumnHeadings.subCategory'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'HeatingAdditionalCosts',
          label: this.$t('message.tableColumnHeadings.HeatingAdditionalCosts'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        }
      ],
      selectedFields: [],
      responsiveTableItems: [
        { totalWithoutVacancy: '', netRent: this.totalNetRent, grossRent: this.totalGrossRent }
      ],
      searchDebitUntilFilter: [],
      searchDebitUntilFilterLabel: '',
      noDebitUntil: false,
      defaultCalendar: new Date(),
      tax: [
        { start: null, end: '31-12-1994', value: '0' },
        { start: '01-01-1995', end: '31-12-1998', value: '6.5' },
        { start: '01-01-1999', end: '31-12-2000', value: '7.5' },
        { start: '01-01-2001', end: '31-12-2010', value: '7.6' },
        { start: '01-01-2011', end: '31-12-2017', value: '8.0' },
        { start: '01-01-2018', end: '31-12-2023', value: '7.7' },
        { start: '01-01-2024', end: null, value: '8.1' }
      ],
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: [],
      debouncedFilterObjectsList: null
    }
  },
  computed: {
    ...mapState('onboarding', ['errors']),
    nbrTotalOfHits() {
      return this.filteredObjects.length
    },
    getLang() {
      return this.$store.getters['internationalization/getCurrentLanguage']
    },
    isFiltered() {
      return this.searchText !== '' || this.searchDebitUntilFilter.length > 0 || this.searchCategories.length > 0 || this.searchRooms.length > 0 || this.searchFloor.length > 0 || this.searchZip.length > 0 || this.searchNetworkPartnerAsset.length > 0 || this.searchNetworkPartnerMarketing.length > 0 || this.searchNetworkPartnerAdministration.length > 0 || this.searchAssignee.length > 0 || this.searchStatus.length > 0 || this.searchFutureStatus.length > 0 || this.searchMarketingStatus.length > 0 || this.searchPreferredCommunicationChannel.length > 0 || this.searchTags.length > 0
    },
    notAllStatusNotActivated() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.length !== this.ObjectWithCondominiums.length || this.ObjectWithCondominiums.filter(x => this.getStatus(x) !== 'not_activated').length > 0
    },
    tenants() {
      let list = []
      if (this.condominiumOwnership.length > 0) {
        this.condominiumOwnership.forEach(element => {
          if (element && element.tenants.length > 0) {
            element.tenants.forEach(element => {
              if (element.contactIds.length > 0) {
                element.contactIds.forEach(id => {
                  list = list.concat(id)
                })
              }
            })
          }
        })
      }
      return list
    },
    isAllowedToCreateObject() {
      const managementPartner = []
      const marketingPartner = []
      const filteredObjects = this.filteredObjects
      filteredObjects.forEach(object => {
        if (this.getNetworkPartners(object.id).find(element => element.scope === 'MANAGEMENT_SCOPE')) {
          managementPartner.push(this.getNetworkPartners(object.id).find(element => element.scope === 'MANAGEMENT_SCOPE').id)
        }
        if (this.getNetworkPartners(object.id).find(element => element.scope === 'MARKETING_SCOPE')) {
          marketingPartner.push(this.getNetworkPartners(object.id).find(element => element.scope === 'MARKETING_SCOPE').id)
        }
      })
      return managementPartner.every((val, i, arr) => val === arr[0]) && marketingPartner.every((val, i, arr) => val === arr[0])
    },
    availableStatusOptions() {
      const counts = []
      const current = this
      this.objects.forEach(element => {
        const objStatus = this.getStatus(element)
        const existingCount = counts.filter(e => e.value === objStatus)
        if (existingCount.length === 0) {
          const sameStatus = current.objects.map(obj => current.getStatus(obj)).filter(z => z === objStatus)
          if (sameStatus.length > 1) {
            counts.push({
              count: sameStatus.length,
              value: objStatus
            })
          } else {
            counts.push({
              count: 1,
              value: objStatus
            })
          }
        }
      })
      return counts
    },
    availableFutureStatusOptions() {
      const counts = []
      const current = this
      this.objects.forEach(element => {
        const futureStatus = this.getFutureStatus(element)
        if (futureStatus) {
          const existingCount = counts.filter(e => e.value === futureStatus)
          if (existingCount.length === 0) {
            const sameStatus = current.objects.map(obj => current.getFutureStatus(obj)).filter(z => z === futureStatus)
            if (sameStatus.length > 1) {
              counts.push({
                count: sameStatus.length,
                value: futureStatus
              })
            } else {
              counts.push({
                count: 1,
                value: futureStatus
              })
            }
          }
        }
      })
      return counts
    },
    availableMarketingStatusOptions() {
      const counts = []
      const current = this
      this.objects.forEach(element => {
        const marketingStatus = this.getMarketingStatus(element)
        const existingCount = counts.filter(e => e.value === marketingStatus)
        if (existingCount.length === 0) {
          const sameStatus = current.objects.map(obj => current.getMarketingStatus(obj)).filter(z => z === marketingStatus)
          if (sameStatus.length > 1) {
            counts.push({
              count: sameStatus.length,
              value: marketingStatus
            })
          } else {
            counts.push({
              count: 1,
              value: marketingStatus
            })
          }
        }
      })
      return counts
    },
    property() {
      return this.properties.find(x => x.buildingIds.includes(this.building.id))
    },
    selectedClientName() {
      if (this.selectedClient && this.selectedClient.company) {
        return this.selectedClient.company.name
      } else if (this.selectedClient && this.selectedClient.individual) {
        return this.selectedClient.individual.firstName.substr(0, 1) + '.' + this.selectedClient.individual.lastName
      }
      return ''
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.allEntries.map(x => x.id) : this.selectedEntries.map(x => x.id)
    },
    hasSelectedEntriesAssetSet() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.filter(e => this.getNetworkPartnersObject(e.id).filter(element => element.scope === 'ASSET_SCOPE').length > 0).length > 0
    },
    assigneeFilter() {
      const counts = []
      let unassigned = 0

      const assigneeList = this.objects.filter(element => {
        if (element.contacts && element.contacts[this.typeOfModule] !== null && element.contacts[this.typeOfModule] !== '') {
          return true
        } else {
          unassigned += 1
          return false
        }
      }).flatMap(element => element.contacts[this.typeOfModule])

      assigneeList.forEach(assignee => {
        const existingCount = counts.filter(e => e.value === assignee)
        if (existingCount.length === 0) {
          const sameAssignee = assigneeList.filter(a => a === assignee)
          if (sameAssignee.length > 1) {
            counts.push({
              count: sameAssignee.length,
              value: assignee,
              label: this.getContactName(assignee),
              active: !(this.assigneeContacts.find(x => x.id === assignee)?.blocked)
            })
          } else {
            counts.push({
              count: 1,
              value: assignee,
              label: this.getContactName(assignee),
              active: !(this.assigneeContacts.find(x => x.id === assignee)?.blocked)
            })
          }
        }
      })
      if (unassigned !== 0) {
        counts.push({
          count: unassigned,
          value: '',
          label: this.$t('message.generic.unassigned'),
          active: true
        })
      }
      return counts
    },
    categoryFilter() {
      const counts = []
      const current = this
      current.objects.map(obj => obj.category).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== 'UNDEFINED_CATEGORY') {
          const sameNetwork = current.filteredObjects.map(obj => obj.category).filter(z => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    },
    filteredEmployeeContacts() {
      let empContacts = this.employeeContacts.filter(c => c.types.includes('EMPLOYEE'))
      if (this.getEmployees().length > 0) {
        empContacts = empContacts.filter(contact => {
          const matchingEmployee = this.getEmployees()
            .filter(employee => !employee.blocked)
            .find(employee => employee?.email?.toLowerCase()?.trim() === contact?.email?.toLowerCase()?.trim())

          return matchingEmployee !== undefined && matchingEmployee !== null
        })
      }
      return empContacts
    },
    floorFilter() {
      let counts = []
      const current = this
      current.objects.map(obj => obj.characteristics.decimalFloor).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== -10000) {
          const sameNetwork = current.objects.map(obj => obj.characteristics.decimalFloor).filter(z => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      if (counts.length > 0) {
        counts = this.sortedLocalFloor(counts)
      }
      return counts
    },
    networkFilterAsset() {
      let networksList = []
      const counts = []
      const listObject = []
      this.objects.forEach(element => {
        const list = this.getNetworkPartnersObject(element.id).filter(element => element.scope === 'ASSET_SCOPE')
        if (!list.length) {
          listObject.push(element.id)
        }
        networksList.push(list)
      })
      networksList = [].concat.apply([], networksList)
      networksList.forEach(function(x) {
        const existingCount = counts.filter(e => e.id === x.id)
        if (existingCount.length === 0) {
          const sameNetwork = networksList.filter(n => n.id === x.id)
          if (sameNetwork.length > 1) {
            x.objectIds = sameNetwork.map(obj => obj.objectId)
          } else {
            x.objectIds = [x.objectId]
          }
          counts.push(x)
        }
      })
      const countOfUnassigned = parseInt(this.objects.length) * 2 - networksList.length
      if (countOfUnassigned > 0) {
        const obj = { name: 'unassigned nicht zugeordnet non assegnato', objectIds: listObject }
        counts.push(obj)
      }
      return counts.filter(x => x.objectIds.length > 0)
    },
    networkFilterMarketing() {
      let networksList = []
      const counts = []
      const listObject = []
      this.objects.forEach(element => {
        const list = this.getNetworkPartnersObject(element.id).filter(element => element.scope === 'MARKETING_SCOPE')
        if (!list.length) {
          listObject.push(element.id)
        }
        networksList.push(list)
      })
      networksList = [].concat.apply([], networksList)
      networksList.forEach(function(x) {
        const existingCount = counts.filter(e => e.id === x.id)
        if (existingCount.length === 0) {
          const sameNetwork = networksList.filter(n => n.id === x.id)
          if (sameNetwork.length > 1) {
            x.objectIds = sameNetwork.map(obj => obj.objectId)
          } else {
            x.objectIds = [x.objectId]
          }
          counts.push(x)
        }
      })
      const countOfUnassigned = parseInt(this.objects.length) * 2 - networksList.length
      if (countOfUnassigned > 0) {
        const obj = { name: 'unassigned nicht zugeordnet non assegnato', objectIds: listObject }
        counts.push(obj)
      }
      return counts.filter(x => x.objectIds.length > 0)
    },
    filterPreferredCommunicationChannel() {
      let channelList = []
      const counts = []
      this.objects.forEach(element => {
        if (this.getObjectPreferedChannel(element) && this.getObjectPreferedChannel(element) !== 'PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED') {
          channelList.push(this.getObjectPreferedChannel(element))
        }
      })
      channelList = [].concat.apply([], channelList)
      channelList.forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== -1) {
          const sameNetwork = channelList.filter(z => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    },
    filterTags() {
      const counts = []
      const current = this
      current.sortedObjectList.flatMap(obj => obj.tags).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x.length > 0) {
          const sameNetwork = current.sortedObjectList.flatMap(obj => obj.tags).filter(z => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    },
    filterSubcategory() {
      const counts = []
      const current = this
      const list = current.sortedObjectList.filter(obj => obj.subCategories?.length > 0 && obj.subCategories[0] !== 'UNDEFINED_SUB_CATEGORY')
      list.forEach(function(obj) {
        const subCat = obj.subCategories[0]
        const existingCount = counts.filter(e => e.value === subCat)
        if (existingCount.length === 0 && subCat) {
          const sameNetwork = list.filter(z => z.subCategories[0] === subCat)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: subCat
            })
          } else {
            counts.push({
              count: 1,
              value: subCat
            })
          }
        }
      })
      return counts
    },
    networkFilterAdministration() {
      let networksList = []
      const counts = []
      const listObject = []
      this.objects.forEach(element => {
        const list = this.getNetworkPartnersObject(element.id).filter(element => element.scope === 'MANAGEMENT_SCOPE')
        if (!list.length) {
          listObject.push(element.id)
        }
        networksList.push(list)
      })
      networksList = [].concat.apply([], networksList)
      networksList.forEach(function(x) {
        const existingCount = counts.filter(e => e.id === x.id)
        if (existingCount.length === 0) {
          const sameNetwork = networksList.filter(n => n.id === x.id)
          if (sameNetwork.length > 1) {
            x.objectIds = sameNetwork.map(obj => obj.objectId)
          } else {
            x.objectIds = [x.objectId]
          }
          counts.push(x)
        }
      })
      const countOfUnassigned = parseInt(this.objects.length) * 2 - networksList.length
      if (countOfUnassigned > 0) {
        const obj = { name: 'unassigned nicht zugeordnet non assegnato', objectIds: listObject }
        counts.push(obj)
      }
      return counts.filter(x => x.objectIds.length > 0)
    },
    objectsPage() {
      if (this.nbrPerPage === 'all') {
        return this.filteredObjects
      } else {
        return this.filteredObjects.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
      }
    },
    roomsFilter() {
      const counts = []
      const current = this
      current.objects.map(obj => obj.characteristics.rooms).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== -1) {
          const sameNetwork = current.objects.map(obj => obj.characteristics.rooms).filter(z => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    isTenanciesActivated() {
      return this.tenancies.filter(x => this.selectedEntriesIds.includes(x.objectId) && x.activated).length
    },
    ObjectWithoutTenancies() {
      return this.selectedEntries.filter(data => !data.condominiumOwnership && (!this.getCurrentTenancy(data.id) || !(this.getCurrentTenancy(data.id).tenant && this.getCurrentTenancy(data.id).tenant.contactIds.length > 0)))
    },
    ObjectWithTenancies() {
      return this.selectedEntries.filter(data => !data.condominiumOwnership && this.getCurrentTenancy(data.id) && this.getCurrentTenancy(data.id).tenant && this.getCurrentTenancy(data.id).tenant.contactIds.length > 0)
    },
    ObjectWithCondominiums() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.filter(data => data.condominiumOwnership && this.getCurrentCondominium(data.id) && this.getCurrentCondominium(data.id).owner && this.getCurrentCondominium(data.id).owner.contactIds.length > 0)
    },
    idsOfCurrentCondominiums() {
      const arrayIds = []
      this.ObjectWithCondominiums.forEach(element => {
        arrayIds.push(this.getCurrentCondominium(element.id).id)
      })
      return arrayIds
    },
    totalNetRent() {
      let tenancy = 0
      let vacancy = 0
      for (let i = 0; i < this.objectsPage.length; i++) {
        const status = this.getStatus(this.objectsPage[i])
        if (status !== 'vacant' && status !== 'vacant_not_activated') {
          const coldRent = this.getColdRentPerMonth(this.objectsPage[i].id) || 0
          tenancy += coldRent
        } else {
          const coldRent = this.getColdRentPerMonth(this.objectsPage[i].id) || 0
          vacancy += coldRent
        }
      }
      return {
        tenancy: tenancy,
        vacancy: vacancy,
        sum: tenancy + vacancy
      }
    },
    totalGrossRent() {
      let tenancy = 0
      let vacancy = 0
      for (let i = 0; i < this.objectsPage.length; i++) {
        const status = this.getStatus(this.objectsPage[i])
        if (status !== 'vacant' && status !== 'vacant_not_activated') {
          const grossRent = this.getTotalRent(this.objectsPage[i].id) || 0
          tenancy += grossRent
        } else {
          const grossRent = this.getTotalRent(this.objectsPage[i].id) || 0
          vacancy += grossRent
        }
      }
      return {
        tenancy: tenancy,
        vacancy: vacancy,
        sum: tenancy + vacancy
      }
    },
    totalMarketNetRent() {
      let tenancy = 0
      let vacancy = 0
      for (let i = 0; i < this.objectsPage.length; i++) {
        const status = this.getStatus(this.objectsPage[i])
        if (status !== 'vacant' && status !== 'vacant_not_activated') {
          const marketNetRent = this.objectsPage[i].pricing?.marketNetRent > 0 ? this.objectsPage[i].pricing?.marketNetRent : 0
          tenancy += marketNetRent
        } else {
          const marketNetRent = this.objectsPage[i].pricing?.marketNetRent > 0 ? this.objectsPage[i].pricing?.marketNetRent : 0
          vacancy += marketNetRent
        }
      }
      return {
        tenancy: tenancy,
        vacancy: vacancy,
        sum: tenancy + vacancy
      }
    },
    totalTargetNetRent() {
      let tenancy = 0
      let vacancy = 0
      for (let i = 0; i < this.objectsPage.length; i++) {
        const status = this.getStatus(this.objectsPage[i])
        if (status !== 'vacant' && status !== 'vacant_not_activated') {
          const targetNetRent = this.objectsPage[i].pricing?.targetMarketNetRent > 0 ? this.objectsPage[i].pricing?.targetMarketNetRent : 0
          tenancy += targetNetRent
        } else {
          const targetNetRent = this.objectsPage[i].pricing?.targetMarketNetRent > 0 ? this.objectsPage[i].pricing?.targetMarketNetRent : 0
          vacancy += targetNetRent
        }
      }
      return {
        tenancy: tenancy,
        vacancy: vacancy,
        sum: tenancy + vacancy
      }
    },
    totalMarketGrossRent() {
      let tenancy = 0
      let vacancy = 0
      for (let i = 0; i < this.objectsPage.length; i++) {
        const status = this.getStatus(this.objectsPage[i])
        if (status !== 'vacant' && status !== 'vacant_not_activated') {
          const marketGrossRent = this.objectsPage[i].pricing?.marketGrossRent > 0 ? this.objectsPage[i].pricing?.marketGrossRent : 0
          tenancy += marketGrossRent
        } else {
          const marketGrossRent = this.objectsPage[i].pricing?.marketGrossRent > 0 ? this.objectsPage[i].pricing?.marketGrossRent : 0
          vacancy += marketGrossRent
        }
      }
      return {
        tenancy: tenancy,
        vacancy: vacancy,
        sum: tenancy + vacancy
      }
    },
    totalTargetGrossRent() {
      let tenancy = 0
      let vacancy = 0
      for (let i = 0; i < this.objectsPage.length; i++) {
        const status = this.getStatus(this.objectsPage[i])
        if (status !== 'vacant' && status !== 'vacant_not_activated') {
          const targetGrossRent = this.objectsPage[i].pricing?.targetMarketGrossRent > 0 ? this.objectsPage[i].pricing?.targetMarketGrossRent : 0
          tenancy += targetGrossRent
        } else {
          const targetGrossRent = this.objectsPage[i].pricing?.targetMarketGrossRent > 0 ? this.objectsPage[i].pricing?.targetMarketGrossRent : 0
          vacancy += targetGrossRent
        }
      }
      return { tenancy: tenancy, vacancy: vacancy, sum: tenancy + vacancy }
    },
    isAdmin() {
      if (this.isOwnerModule || this.isAssetModule) {
        return this.$store.getters['user/getCurrentUser']['https://coozzy.ch/roles'].includes('company_admin')
      }
      return true
    },
    totalPropertyValueQuota() {
      const listBuildings = this.$store.getters['onboarding/getPropertyBuildings']
      if (listBuildings.length > 0) {
        return listBuildings.reduce((accumulator, object) => {
          return accumulator + object.valueQuota
        }, 0)
      }
      return 0
    }
  },
  watch: {
    searchDebitUntilFilter: function (newVal) {
      if (newVal.length > 0) {
        const dateArray = newVal[0].split('/')
        this.defaultCalendar = new Date(dateArray[1], dateArray[0] - 1, 1)
      }
      if (this.isLoaded) {
        this.searchDebitUntilFilterLabel = newVal.join(', ')
        this.$emit('change-filter', this.allFilters(), 'objectFilters')
      }
    },
    objectsPage: function (newVal) {
      let contactIds = []
      newVal.forEach(element => {
        if (element.contacts) {
          contactIds.push(element.contacts.contactIdAssigneeOwner)
          contactIds.push(element.contacts.contactIdAssigneeAsset)
          contactIds.push(element.contacts.contactIdAssigneeAccounting)
          contactIds.push(element.contacts.contactIdAssigneeAdministration)
        }
        if (!element.condominiumOwnership) {
          const currentTenancy = this.getCurrentTenancy(element.id)
          if (currentTenancy) {
            contactIds = contactIds.concat(currentTenancy.tenant.contactIds)
            if (currentTenancy.subTenants?.length > 0) {
              currentTenancy.subTenants.forEach(element => {
                if (element.contactIds.length > 0) {
                  contactIds = contactIds.concat(element.contactIds)
                }
              })
            }
          }
        } else {
          let list = []
          if (this.tenants) {
            list = this.tenants
          }
          const currentCondominium = this.getCurrentCondominium(element.id)
          if (currentCondominium && currentCondominium.owner) {
            contactIds = contactIds.concat(currentCondominium.owner.contactIds).concat(list)
            contactIds = contactIds.concat(currentCondominium.owner.roommateContactIds).concat(list)
          }
        }
      })
      this.$nextTick(function () {
        this.availableContact([...new Set(contactIds.filter(x => x !== null && x !== ''))])
      })
    },
    nbrPerPage: function (newVal) {
      localStorage.setItem('buildingObject-nbrPerPage', newVal)
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    currentPage: function () {
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    searchStatus: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'objectFilters')
      }
    },
    searchFutureStatus: function () {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'objectFilters')
      }
    },
    searchMarketingStatus: function () {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'objectFilters')
      }
    },
    searchAssignee: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'objectFilters')
      }
    },
    searchNetworkPartnerAsset: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'objectFilters')
      }
    },
    searchNetworkPartnerMarketing: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'objectFilters')
      }
    },
    searchNetworkPartnerAdministration: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'objectFilters')
      }
    },
    searchZip: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'objectFilters')
      }
    },
    searchFloor: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'objectFilters')
      }
    },
    searchRooms: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'objectFilters')
      }
    },
    searchCategories: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'objectFilters')
      }
    },
    searchText: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'objectFilters')
      }
    },
    errors: function (newVal) {
      if (newVal.length > 0 && this.errors[0].objectId) {
        this.selectObject(this.errors[0].objectId, true, this.objects)
      }
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        this.allEntries = this.filteredObjects
      }
    }
  },
  created() {
    // Initialize the debounced function in the created lifecycle hook
    this.debouncedFilterObjectsList = debounce(() => {
      this.filterObjectList()
    }, 300)
  },
  mounted: function () {
    this.$refs.searchText?.setFocusInput()
    this.loadInstantSearchInternal()
    if (this.isJanitor) {
      this.dataFields = this.dataFields.filter(x => x.key !== 'netRent' && x.key !== 'grossRent' && x.key !== 'm2PerYear' && x.key !== 'marketNetRent' && x.key !== 'targetNetRent' && x.key !== 'marketGrossRent' && x.key !== 'targetGrossRent')
    }
    if (this.isOwnerModule) {
      this.typeOfModule = 'contactIdAssigneeOwner'
    } else if (this.isAssetModule) {
      this.typeOfModule = 'contactIdAssigneeAsset'
    } else if (this.isAccountingModule) {
      this.typeOfModule = 'contactIdAssigneeAccounting'
    } else if (this.isAdminModule) {
      this.typeOfModule = 'contactIdAssigneeAdministration'
    }
    this.loadEmployees()

    if (this.objectFilters !== '') {
      const objFilter = JSON.parse(this.objectFilters)
      this.searchStatus = objFilter.searchStatus
      this.searchFutureStatus = objFilter.searchFutureStatus
      this.searchMarketingStatus = objFilter.searchMarketingStatus
      this.searchAssignee = objFilter.searchAssignee
      this.searchNetworkPartnerAsset = objFilter.searchNetworkPartnerAsset
      this.searchNetworkPartnerMarketing = objFilter.searchNetworkPartnerMarketing
      this.searchNetworkPartnerAdministration = objFilter.searchNetworkPartnerAdministration
      this.searchFloor = objFilter.searchFloor
      this.searchRooms = objFilter.searchRooms
      this.searchCategories = objFilter.searchCategories
      this.searchText = objFilter.searchText
    }
    this.listNewObjects[0].administrationType = this.property?.type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP' ? 'CONDOMINIUM_OWNERSHIP' : 'LETTING'
    this.filterObjectList()
    this.$nextTick(function () {
      this.filterMountedObject()
    })
    this.nbrPerPage = localStorage.getItem('buildingObject-nbrPerPage') ? localStorage.getItem('buildingObject-nbrPerPage') === 'all' ? localStorage.getItem('buildingObject-nbrPerPage') : parseInt(localStorage.getItem('buildingObject-nbrPerPage')) : 5
    this.isLoaded = true
    this.getFieldsFromApi()
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    ...mapMutations('onboarding', ['setObjectOwnerContacts']),
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setUseBcc',
      'setSendMailSecondaryTitle',
      'setSendMailContactsWithoutEmail',
      'setRenderWithObjectId',
      'setSendMailContactsWithObject',
      'setShowAllPlaceholders'
    ]),
    dateChanged(e) {
      this.defaultCalendar = e
    },
    dateToTimestamp(list, type) {
      const timeStampList = []
      if (type === 'start' && list.length > 0) {
        list.forEach(item => {
          const dateArray = item.split('/')
          const date = new Date(dateArray[1], dateArray[0] - 1, 1, 0, 0, 0)
          timeStampList.push(date.getTime() / 1000)
        })
        timeStampList.sort(function(x, y) {
          return x - y
        })
        return timeStampList[0]
      }
      if (type === 'end' && list.length > 0) {
        list.forEach(item => {
          const dateArray = item.split('/')
          const date = new Date(dateArray[1], dateArray[0], 0, 23, 59, 59)
          timeStampList.push((date.getTime() / 1000))
        })
        timeStampList.sort(function(x, y) {
          return y - x
        })
        return timeStampList[0]
      }
      return 0
    },
    fieldsIncludeCalculatedRent() {
      const items = this.fields.filter(x => x.key === 'netRent' || x.key === 'grossRent')
      return items?.length > 0
    },
    getQuotaWithTotal(item) {
      if (item?.valueQuota !== null && item?.valueQuota >= 0 && this.building?.valueQuotaWithTotal) {
        const quota = item.valueQuota % 1 === 0 ? item.valueQuota : parseFloat(item.valueQuota)
        const totalQuota = this.building.valueQuotaWithTotal % 1 === 0 ? this.building.valueQuotaWithTotal : parseFloat(this.building.valueQuotaWithTotal)
        return this.formatValueQuota(quota) + ' / ' + this.formatValueQuota(totalQuota)
      }
      return '-'
    },
    getFieldsFromApi() {
      if (this.property && this.property.type !== 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP') {
        // Load fields for Rental Objects
        SearchApi.loadSettings(this.accountId).then(response => {
          const settings = response.tableSettings
          let hasOldList = false
          if (settings.length > 0) {
            settings.forEach(setting => {
              if (setting.columns.length > 0 && setting.table === 'object_details') {
                hasOldList = true
                this.loadFields(setting)
              }
            })
          }
          if (hasOldList === false) {
            this.dataFields = this.dataFields.filter(x => x.key !== 'condominiumOwner' && x.key !== 'valueQuota' && x.key !== 'ownershipStart' && x.key !== 'ownershipEnd' && x.key !== 'roommate')
            this.fields.push(...this.dataFields.filter(x => x.selected))
            this.selectedFields = JSON.parse(JSON.stringify(this.dataFields))
          }
        }).catch(e => {
          console.log(e)
        })
      } else {
        // Load fields for Condominiums
        SearchApi.loadSettings(this.accountId).then(response => {
          const settings = response.tableSettings
          let hasOldList = false
          if (settings.length > 0) {
            settings.forEach(setting => {
              if (setting.columns.length > 0 && setting.table === 'condominium_details') {
                hasOldList = true
                this.loadFields(setting)
              }
            })
          }
          if (hasOldList === false) {
            this.dataFields = this.dataFields.filter(x => x.key !== 'netRent' && x.key !== 'grossRent' && x.key !== 'm2PerYear' && x.key !== 'marketNetRent' && x.key !== 'marketGrossRent' && x.key !== 'targetNetRent' && x.key !== 'targetGrossRent' && x.key !== 'startOfContract' && x.key !== 'endOfContract' && x.key !== 'subtenant' && x.key !== 'HeatingAdditionalCosts')
            this.fields.push(...this.dataFields.filter(x => x.selected))
            this.selectedFields = JSON.parse(JSON.stringify(this.dataFields))
          }
        }).catch(e => {
          console.log(e)
        })
      }
    },
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting)
      this.fields = result?.fields
      let list = result?.selectedFields

      if (this.property && this.property.type !== 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP') {
        // Filter Rental Objects Fields
        this.fields = this.fields.filter(obj => obj.key !== 'condominiumOwner' && obj.key !== 'valueQuota' && obj.key !== 'ownershipStart' && obj.key !== 'ownershipEnd' && obj.key !== 'roommate')
        list = list.filter(x => x.key !== 'condominiumOwner' && x.key !== 'valueQuota' && x.key !== 'ownershipStart' && x.key !== 'ownershipEnd' && x.key !== 'roommate')
        this.dataFields = this.dataFields.filter(x => x.key !== 'condominiumOwner' && x.key !== 'valueQuota' && x.key !== 'ownershipStart' && x.key !== 'ownershipEnd' && x.key !== 'roommate')
      } else {
        // Filter Condominiums Fields
        this.fields = this.fields.filter(obj => obj.key !== 'netRent' && obj.key !== 'grossRent' && obj.key !== 'm2PerYear' && obj.key !== 'marketNetRent' && obj.key !== 'marketGrossRent' && obj.key !== 'targetNetRent' && obj.key !== 'targetGrossRent' && obj.key !== 'startOfContract' && obj.key !== 'endOfContract' && obj.key !== 'subtenant' && obj.key !== 'HeatingAdditionalCosts')
        list = list.filter(x => x.key !== 'netRent' && x.key !== 'grossRent' && x.key !== 'm2PerYear' && x.key !== 'marketNetRent' && x.key !== 'marketGrossRent' && x.key !== 'targetNetRent' && x.key !== 'targetGrossRent' && x.key !== 'startOfContract' && x.key !== 'endOfContract' && x.key !== 'subtenant' && x.key !== 'HeatingAdditionalCosts')
        this.dataFields = this.dataFields.filter(x => x.key !== 'netRent' && x.key !== 'grossRent' && x.key !== 'm2PerYear' && x.key !== 'marketNetRent' && x.key !== 'marketGrossRent' && x.key !== 'targetNetRent' && x.key !== 'targetGrossRent' && x.key !== 'startOfContract' && x.key !== 'endOfContract' && x.key !== 'subtenant' && x.key !== 'HeatingAdditionalCosts')
      }
      this.selectedFields = list
      this.$refs.objectsTable?.refresh()
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      if (this.property && this.property.type !== 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP') {
        // Save Rental Objects Fields
        SearchApi.saveSettings(this.accountId, columns, 'object_details').then(response => {
          const setting = response.tableSettings
          if (setting && setting.columns.length > 0) {
            this.loadFields(setting)
          }
          this.$bvModal.hide('assembleListModal')
        })
      } else {
        // Save Condominiums Fields
        SearchApi.saveSettings(this.accountId, columns, 'condominium_details').then(response => {
          const setting = response.tableSettings
          if (setting && setting.columns.length > 0) {
            this.loadFields(setting)
          }
          this.$bvModal.hide('assembleListModal')
        })
      }
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    createTicket(type) {
      const defaultAssignee = this.selectedEntries.every((val, i, arr) => this.getNetworkPartners(val.id).length > 0 && this.getNetworkPartners(arr[0].id).length > 0 && this.getNetworkPartners(val.id).find(element => element.scope === 'ASSET_SCOPE') && this.getNetworkPartners(arr[0].id).find(element => element.scope === 'ASSET_SCOPE') && this.getNetworkPartners(val.id).find(element => element.scope === 'ASSET_SCOPE').id === this.getNetworkPartners(arr[0].id).find(element => element.scope === 'ASSET_SCOPE').id)
        ? this.getNetworkPartners(this.selectedEntries[0].id).find(element => element.scope === 'ASSET_SCOPE').id
: this.selectedEntries.every((val, i, arr) => this.getNetworkPartners(val.id).length > 0 && this.getNetworkPartners(arr[0].id).length > 0 && this.getNetworkPartners(val.id).find(element => element.scope === 'MANAGEMENT_SCOPE') && this.getNetworkPartners(arr[0].id).find(element => element.scope === 'MANAGEMENT_SCOPE') && this.getNetworkPartners(val.id).find(element => element.scope === 'MANAGEMENT_SCOPE').id === this.getNetworkPartners(arr[0].id).find(element => element.scope === 'MANAGEMENT_SCOPE').id) ? this.getNetworkPartners(this.selectedEntries[0].id).find(element => element.scope === 'MANAGEMENT_SCOPE').id : ''
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      }
    },
    navigateToCreateOrder() {
      const currentRoute = { name: this.$router.currentRoute.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      const objectIds = this.selectedEntriesIds.join(',')
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerCreateOrderView', query: { objectIds: objectIds, source: 'object' } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminCreateOrderView', query: { objectIds: objectIds, source: 'object' } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetCreateOrderView', query: { objectIds: objectIds, source: 'object' } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingCreateOrderView', query: { objectIds: objectIds, source: 'object' } }, () => {})
      }
    },
    showTenant(endContract) {
      if (endContract !== null) {
        const now = new Date().getTime()
        const today = now - (now % 86400000)
        const endDate = new Date(this.$options.filters.objectToDateInput(endContract)).getTime()
        return endDate >= today
      }
      return true
    },
    sortedLocalFloor(items) {
      if (items) {
        const posFloor = []
        const negFloor = []
        const floorZero = []
        for (const item of items) {
          if (parseInt(item.value) > 0) {
            posFloor.push(item)
          }
          if (parseInt(item.value) < 0) {
            negFloor.push(item)
          }
          if (parseInt(item.value) === 0) {
            floorZero.push(item)
          }
        }
        posFloor.sort(function(a, b) {
          return parseInt(a.value) - parseInt(b.value)
        })
        negFloor.sort(function(a, b) {
          return parseInt(b.value) - parseInt(a.value)
        })
        return floorZero.concat(posFloor).concat(negFloor)
      }
      return 0
    },
    clearAllSelectedObject() {
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    serialLetter() {
      let nameOfRoute = ''
      let source = 'tenancy'
      if (this.isOwnerModule) {
        nameOfRoute = 'OwnerSerialLetterProcessView'
      } else if (this.isAdminModule) {
        nameOfRoute = 'AdminSerialLetterProcessView'
      } else if (this.isAssetModule) {
        nameOfRoute = 'AssetSerialLetterProcessView'
      } else {
        nameOfRoute = 'AccountingSerialLetterProcessView'
      }
      if (this.property && this.property.type !== 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP') {
        source = 'tenancy'
      } else {
        source = 'condominium'
      }
      const uniqKey = uuidv4()
      localStorage.setItem(uniqKey, this.selectedEntriesIds)
      this.$nextTick(() => {
        this.$router.push({
          name: nameOfRoute,
          query: {
            source: source,
            uuid: uniqKey,
            previousRoute: this.$route.fullPath
          }
        })
      })
    },
    async serialEmail() {
      const list = []
      this.setShowAllPlaceholders(true)
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      listEntries.forEach((element) => {
        if (element.condominiumOwnership) {
          element.condominium = this.getCurrentCondominium(element.id) || null
        } else {
          element.tenants = this.getCurrentTenancy(element.id) || null
        }
        list.push(element)
      })
      await this.prepareSerialEmail(list)
      this.loading = false
    },
    filterMountedObject() {
      const selfScope = this.isAdminModule ? 'MANAGEMENT_SCOPE' : this.isAccountingModule ? 'ACCOUNTING_SCOPE' : this.isAssetModule ? 'ASSET_SCOPE' : 'MARKETING_SCOPE'
      if (!this.elementSelected) {
        this.sortedObjectList = this.filterObjectWithScope(this.objects, selfScope)
        this.filteredObjects = this.sortedObjectList
      } else if (this.isBuildingObjectsFiltered.length === 0) {
        this.sortedObjectList = this.filterObjectWithScope(this.objects, selfScope)
        this.filterObjectList()
        this.$nextTick(function () {
          this.selectObject(this.elementSelected, false, this.filteredObjects)
        })
      } else {
        this.sortedObjectList = this.filterObjectWithScope(this.objects.filter(x => this.isBuildingObjectsFiltered.map(y => y.id === x.id)), selfScope)
        this.filteredObjects = this.sortedObjectList
        this.$nextTick(function () {
          this.selectObject(this.elementSelected, false, this.filteredObjects)
        })
      }
    },
    allFilters() {
      return JSON.stringify({
        searchStatus: this.searchStatus,
        searchFutureStatus: this.searchFutureStatus,
        searchMarketingStatus: this.searchMarketingStatus,
        searchAssignee: this.searchAssignee,
        searchNetworkPartnerAsset: this.searchNetworkPartnerAsset,
        searchNetworkPartnerMarketing: this.searchNetworkPartnerMarketing,
        searchNetworkPartnerAdministration: this.searchNetworkPartnerAdministration,
        searchFloor: this.searchFloor,
        searchRooms: this.searchRooms,
        searchCategories: this.searchCategories,
        searchText: this.searchText
      })
    },
    selectObject(objectId, emit = true, listobject) {
      if (objectId) {
        this.clearRowVariants()
        for (let i = 0; i < listobject.length; i++) {
          if (listobject[i].id === objectId) {
            this.currentPage = (Math.floor(i / this.nbrPerPage)) + 1
            listobject[i]._rowVariant = 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary'
            if (this.$refs['table-groups']) {
              this.$refs['table-groups'].refresh()
            }

            if (emit) {
              this.$emit('selected-object', listobject[i], i)
            }
          }
        }
      }
    },
    clearRowVariants() {
      for (const object of this.objects) {
        if (object._rowVariant === 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary') {
          object._rowVariant = ''
        }
      }
    },
    clearCheckbox() {
      this.selectedEntries = []
      this.$nextTick(() => {
        if (this.$refs.header_checkbox) {
          this.$refs.header_checkbox.checked = false
        }
      })
      this.handleCheckboxesInsideTable(false)
    },
    createObjectClicked() {
      this.$bvModal.show('addObjectModal')
    },
    async createObjects () {
      this.$v.listNewObjects.$touch()
      if (!this.$v.listNewObjects.$invalid) {
        this.isBusy = true
        // list objects before adding new objects
        const objectsListBeforeAddNew = this.objects

        // calculate the amount of added objects
        const countNewObjects = this.listNewObjects.map(x => x.amount).reduce(function(a, b) {
          return parseInt(a) + parseInt(b)
        })
        let tryNumber = 0
        this.addNewObject(this.listNewObjects)
          .then(async () => {
            this.$bvModal.hide('addObjectModal')
          })
          .catch(e => {
            console.log(e)
            this.isBusy = false
          })
          .finally(async () => {
            this.$bvModal.hide('addObjectModal')
            const interval = setInterval(async () => {
              tryNumber++
              if (objectsListBeforeAddNew.length + countNewObjects !== this.objects.length || tryNumber < 10) {
                clearInterval(interval)
                await this.sleep(1000)
                await this.refreshObjectsAsync()
                this.isBusy = false
                const selfScope = this.isAdminModule ? 'MANAGEMENT_SCOPE' : this.isAccountingModule ? 'ACCOUNTING_SCOPE' : this.isAssetModule ? 'ASSET_SCOPE' : 'MARKETING_SCOPE'
                this.sortedObjectList = this.filterObjectWithScope(this.objects, selfScope)
                this.filterObjectList()
              } else {
                clearInterval(interval)
                this.clearCheckbox()
                this.$v.$reset()
                this.listNewObjects = [{
                  category: '',
                  amount: null
                }]
                this.objects.forEach(element => {
                  element._rowVariant = ''
                })
                this.currentPage = 1
                if (this.objects.length > 0) {
                  this.objects[0]._rowVariant = 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary'

                  const newestLowestNumericId = Math.min.apply(Math, this.objects.filter(a => !objectsListBeforeAddNew.map(b => b.id).includes(a.id)).map(function (o) {
                    return o.numericId
                  }))
                  const index = this.objects.findIndex(function (o) {
                    return o.numericId === newestLowestNumericId.toString()
                  })

                  if (index > -1) {
                    this.selectObject(this.objects[index].id, true, this.objects)
                  }
                }
                this.isBusy = false
                const selfScope = this.isAdminModule ? 'MANAGEMENT_SCOPE' : this.isAccountingModule ? 'ACCOUNTING_SCOPE' : this.isAssetModule ? 'ASSET_SCOPE' : 'MARKETING_SCOPE'
                this.sortedObjectList = this.filterObjectWithScope(this.objects, selfScope)
                this.filterObjectList()
              }
            }, 1000)
          })
      } else {
        Vue.toasted.show(this.$t('message.savingErrors.mandatoryMissing').toString(), { type: 'error' })
      }
    },
    customLabel(item) {
      return item.value + ' (' + item.count + ')'
    },
    customLabelAssignee(item) {
      if (item.value === '') {
        return `${this.$t('message.generic.unassigned')} (${item.count})`
      }
      const name = this.getContactName(item.value)
      return `${name} (${item.count})`
    },
    customLabelCategory(item) {
      return this.$t('message.onBoarding.buildings.objects.category.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    customLabelSubCategory(item) {
      return this.$t('message.onBoarding.buildings.objects.subCategory.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    customLabelNetworkPartner(item) {
      if (item.name === 'unassigned nicht zugeordnet non assegnato') {
        return '-' + ' (' + item.objectIds.length + ')'
      }
      return item.company
? item.company.name + ' (' + item.objectIds.length + ')'
        : (item.individual ? item.individual.firstName.substr(0, 1) + '. ' + item.individual.lastName : '-')
    },
    customLabelPreferredCommunicationChannel(item) {
      if (item.value === 'PREFERRED_COMMUNICATION_CHANNEL_EMAIL') {
        return this.$t('message.contact.preferredCommunicationChannelType.email') + ' (' + item.count + ')'
      }
      if (item.value === 'PREFERRED_COMMUNICATION_CHANNEL_LETTER') {
        return this.$t('message.contact.preferredCommunicationChannelType.letter') + ' (' + item.count + ')'
      }
      return item.value
    },
    customLabelStatus(status) {
      return this.$t('message.onBoarding.buildings.objects.status.' + status.value) + ' (' + status.count + ')'
    },
    customLabelFloor(item) {
      return this.formatFloor(item.value) + ' (' + item.count + ')'
    },
    customLabelFutureStatus(status) {
      if (status.value === 'future') {
        return this.$t('message.onBoarding.buildings.objects.futureStatus.' + status.value.toLowerCase() + 'Rented') + ' (' + status.count + ')'
      }
      return this.$t('message.onBoarding.buildings.objects.futureStatus.' + status.value) + ' (' + status.count + ')'
    },
    customLabelMarketingStatus(status) {
      return this.$t('message.onBoarding.buildings.objects.inMarketingStatus.' + status.value) + ' (' + status.count + ')'
    },
    deleteObject() {
      let objects = this.selectAllEntries ? this.filteredObjects : this.selectedEntries
      this.removeObjects(objects)
        .then(() => {
          this.clearCheckbox()
          this.$store.dispatch('onboarding/setIsBuildingObjectsFiltered', [])
          this.filterMountedObject()
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.object').toString(), { type: 'error' })
        })
        .finally(() => {
          if (this.$refs.confirmationModal) {
            this.$refs.confirmationModal.hide()
          }
        })
    },
    filterObjectList() {
      const t = this
      this.$nextTick(function () {
        this.filteredObjects = this.sortedObjectList.filter(item => {
          const floorMapped = this.searchFloor.map(x => x.value)
          const cdtFloor = floorMapped.includes(item.characteristics.decimalFloor) || this.searchFloor.length === 0
          const roomMapped = this.searchRooms.map(x => x.value)
          const cdtRooms = roomMapped.includes(item.characteristics.rooms) || this.searchRooms.length === 0
          const tagsMapped = this.searchTags.map(x => x.value)
          const cdtTags = tagsMapped.some(t => item.tags.includes(t)) || this.searchTags.length === 0
          const categoryMapped = this.searchCategories.map(x => x.value)
          const cdtCategory = categoryMapped.includes(item.category) || this.searchCategories.length === 0
          const currentTenancy = this.getCurrentTenancy(item.id)
          const currentCondominium = this.getCurrentCondominium(item.id)
          let cdtSearch
          if (currentTenancy) {
            const currentTenancyTenant = currentTenancy.tenant.contactIds.map((element) => this.getContactName(element))
            const cdtSearchTenant = currentTenancyTenant.map((element) => element.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1)
            cdtSearch = item.name.toLowerCase().includes(this.searchText.toLowerCase()) || cdtSearchTenant.includes(true) || item.numericId.toString().includes(this.searchText.toLowerCase()) || item.number.toString().includes(this.searchText.toLowerCase())
          } else if (currentCondominium) {
            const currentTenancyTenant = currentCondominium.owner.contactIds.map((element) => this.getContactName(element))
            const cdtSearchTenant = currentTenancyTenant.map((element) => element.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1)
            cdtSearch = item.name.toLowerCase().includes(this.searchText.toLowerCase()) || cdtSearchTenant.includes(true) || item.numericId.toString().includes(this.searchText.toLowerCase()) || item.number.toString().includes(this.searchText.toLowerCase())
          } else {
            cdtSearch = item.name.toLowerCase().includes(this.searchText.toLowerCase()) || item.numericId.toString().includes(this.searchText.toLowerCase()) || item.number.toString().includes(this.searchText.toLowerCase())
          }

          const status = this.getStatus(item)
          const cdtStatus = (this.searchStatus.map(x => x.value).includes(status)) || this.searchStatus.length === 0
          const futureStatus = this.getFutureStatus(item)
          const cdtFutureStatus = (this.searchFutureStatus.map(x => x.value).includes(futureStatus)) || this.searchFutureStatus.length === 0
          const marketingStatus = this.getMarketingStatus(item)
          const cdtMarketingStatus = (this.searchMarketingStatus.map(x => x.value).includes(marketingStatus)) || this.searchMarketingStatus.length === 0

          const networkAssetMapped = [].concat.apply([], this.searchNetworkPartnerAsset.map(x => x.objectIds))
          const cdtNetworkAsset = networkAssetMapped.includes(item.id) || this.searchNetworkPartnerAsset.length === 0
          const networkMarketingMapped = [].concat.apply([], this.searchNetworkPartnerMarketing.map(x => x.objectIds))
          const cdtNetworkMarketing = networkMarketingMapped.includes(item.id) || this.searchNetworkPartnerMarketing.length === 0
          const networkAdministrationMapped = [].concat.apply([], this.searchNetworkPartnerAdministration.map(x => x.objectIds))
          const cdtNetworkAdministration = networkAdministrationMapped.includes(item.id) || this.searchNetworkPartnerAdministration.length === 0
          const assigneeMapped = [].concat.apply([null], this.searchAssignee.map(x => x.value))
          const cdtAssignee = ((item.contacts && assigneeMapped.includes(item.contacts[this.typeOfModule])) || (!item.contacts && assigneeMapped.includes(''))) || this.searchAssignee.length === 0
          const cdtChannel = (this.searchPreferredCommunicationChannel.map(x => x.value).includes(this.getObjectPreferedChannel(item))) || this.searchPreferredCommunicationChannel.length === 0
          let cdtDebitUntil = this.searchDebitUntilFilter.length === 0
          if (this.searchDebitUntilFilter.length === 0 && this.noDebitUntil) {
            cdtDebitUntil = item.debitUntil === null
          }
          if (this.searchDebitUntilFilter.length > 0 && this.noDebitUntil) {
            cdtDebitUntil = (this.dateObjectToTimeStamp(item.debitUntil) / 1000) <= this.dateToTimestamp(this.searchDebitUntilFilter, 'start') && (!item.inactiveBy || (this.dateObjectToTimeStamp(item.inactiveBy) / 1000) >= this.dateToTimestamp(this.searchDebitUntilFilter, 'start'))
          }
          if (this.searchDebitUntilFilter.length > 0 && !this.noDebitUntil) {
            cdtDebitUntil = (this.dateObjectToTimeStamp(item.debitUntil) / 1000) >= this.dateToTimestamp(this.searchDebitUntilFilter, 'start') && (this.dateObjectToTimeStamp(item.debitUntil) / 1000) <= this.dateToTimestamp(this.searchDebitUntilFilter, 'end') && (!item.inactiveBy || (this.dateObjectToTimeStamp(item.inactiveBy) / 1000) >= this.dateToTimestamp(this.searchDebitUntilFilter, 'end'))
          }
          const cdtSubCategory = this.searchSubcategory.some(t => item.subCategories.includes(t.value)) || this.searchSubcategory.length === 0
          this.clearAllSelectedObject()
          return cdtSubCategory && cdtDebitUntil && cdtChannel && cdtFloor && cdtRooms && cdtCategory && cdtStatus && cdtFutureStatus && cdtMarketingStatus && cdtNetworkAsset && cdtNetworkMarketing && cdtNetworkAdministration && cdtSearch && cdtAssignee && cdtTags
        }).sort(function(a, b) {
          if (a.inactiveBy !== null && new Date(t.$options.filters.objectToDateInput(a.inactiveBy)) <= new Date()) return 0
          if (b.inactiveBy === null) return 1
          return -1
        })
      })
    },
    showActivateCondominiumModal() {
      this.$refs.activateCondominiumModal.show()
    },
    condominiumActivated() {
      this.clearCheckbox()
      setTimeout(() => {
        this.filterObjectList()
      }, 2000)
    },
    getCurrentCondominiumTenancy(objectId) {
      // We got an object from our backend
      const startOfDayTimestamp = this.getStartOfDayTimestamp()
      if (this.getCurrentCondominium(objectId)) {
        return this.getCurrentCondominium(objectId).tenants
          .find(t => new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 <= startOfDayTimestamp)
      }
      return []
    },
    getAdjustedRentValues(tenancy) {
      let result = tenancy.rent
      const current = this
      const pastAdjustments = tenancy.rent.adjustments.filter(obj => parseInt(obj.byDate) !== -1 && new Date(current.$options.filters.objectToDateInput(obj.byDate)) < Date.now())
      if (pastAdjustments.length > 0) {
        pastAdjustments.sort(function (a, b) {
          return new Date(current.$options.filters.objectToDateInput(b.byDate)) - new Date(current.$options.filters.objectToDateInput(a.byDate))
        })
        result = pastAdjustments[0]
      }
      return result
    },
    getContactName(contactId) {
      const contact = this.objectOwnerContacts.concat(this.contacts).concat(this.employeeContacts).find(function (contact) {
        return contact?.id === contactId || contact?.objectID === contactId
      })
      return contact ? contact.name : '-'
    },
    getNetRent(objectId) {
      const tenancy = this.getCurrentTenancy(objectId)
      if (!tenancy) {
        return null
      }
      const adjustedRentValues = this.getAdjustedRentValues(tenancy)
      const total = adjustedRentValues.coldRent !== 0 && adjustedRentValues.coldRent !== null && adjustedRentValues.coldRent !== -1 ? adjustedRentValues.coldRent : 0
      const dueDates = adjustedRentValues.dueDates.length > 0 ? adjustedRentValues.dueDates.length : 1
      return total !== 0 ? (total * dueDates) / 12 : 0
    },
    getNetRentM2(objectId) {
      const tenancy = this.getCurrentTenancy(objectId)
      if (!tenancy) {
        return null
      }
      const adjustedRentValues = this.getAdjustedRentValues(tenancy)
      return adjustedRentValues.coldRentM2PerYear !== 0 && adjustedRentValues.coldRentM2PerYear !== null && adjustedRentValues.coldRentM2PerYear !== -1 ? Math.round(adjustedRentValues.coldRentM2PerYear) : 0
    },
    getHeatingAdditionalCosts(objectId) {
      const tenancy = this.getCurrentTenancy(objectId)
      if (!tenancy) {
        return null
      }
      const adjustedRentValues = this.getAdjustedRentValues(tenancy)
      return adjustedRentValues.additionalCosts || 0
    },
    getNetworkPartners(objectId) {
      const shares = this.shares.filter(share => {
        return share.resourceIds.includes(objectId)
      })

      if (shares.length > 0 && this.networkPartners.length !== 0) {
        const partners = this.networkPartners.filter(partner => {
          return shares.map(share => share.targetAccountId).includes(partner.id)
        })
        const networkPartner = []
        partners.forEach(element => {
          const cnd = shares.filter(share => share.targetAccountId === element.id)
          if (cnd.length > 0) {
            for (let index = 0; index < cnd.length; index++) {
              element.scope = cnd[index].scope
              networkPartner.push({ ...element })
            }
          }
        })
        return networkPartner
      }

      return []
    },
    getNetworkPartnersObject(objectId) {
      const shares = this.shares.filter(share => {
        return share.resourceIds.includes(objectId)
      })
      if (shares.length > 0 && this.networkPartners.length !== 0) {
        const partners = []
        for (let i = 0; i < this.networkPartners.length; i++) {
          const sharesTemp = shares.filter(share => share.targetAccountId === this.networkPartners[i].id)
          if (sharesTemp.length) {
            for (let j = 0; j < sharesTemp.length; j++) {
              const element = { ...this.networkPartners[i], ...sharesTemp[j] }
              partners.push(element)
            }
          }
        }
        return partners.map(obj => ({ ...obj, objectId: objectId }))
      }
      return []
    },
    getTotalRent(objectId) {
      const tenancy = this.getCurrentTenancy(objectId)
      if (!tenancy) {
        return null
      }
      const adjustedRentValues = this.getAdjustedRentValues(tenancy)
      const dueDates = adjustedRentValues.dueDates.length > 0 ? adjustedRentValues.dueDates.length : 1
      let total = 0
      if (tenancy.taxable || tenancy.opted) {
        if (adjustedRentValues) {
          total = this.calculateRentWithTax(adjustedRentValues, 'grossRent')
        }
      } else {
        // Subtract rent reduction if necessary
        const rentReductionComponent = adjustedRentValues.additionalValues?.find(e => e.type === 'ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION')
        let rentReduction = 0
        if (rentReductionComponent) {
          if (rentReductionComponent.value > 0) {
            rentReduction = rentReductionComponent.value * -1
          } else if (rentReductionComponent.value < -1) {
            rentReduction = rentReductionComponent.value
          }
        }

        total = (adjustedRentValues.coldRent !== -1 ? adjustedRentValues.coldRent : 0) +
          (adjustedRentValues.additionalCosts !== -1 ? adjustedRentValues.additionalCosts : 0) +
          rentReduction
        total = total !== 0 ? (total * dueDates) / 12 : 0
      }
      return total
    },
    getColdRentPerMonth(objectId) {
      const tenancy = this.getCurrentTenancy(objectId)
      if (!tenancy) {
        return null
      }
      const adjustedRentValues = this.getAdjustedRentValues(tenancy)
      let total = 0
      if (tenancy.taxable || tenancy.opted) {
        if (adjustedRentValues) {
          total = this.calculateRentWithTax(adjustedRentValues, 'netRent')
        }
      } else {
        total = (adjustedRentValues.coldRentPerMonth !== -1 ? adjustedRentValues.coldRentPerMonth : ((adjustedRentValues.coldRent) * (adjustedRentValues.dueDates.length)) / 12)
      }

      return total !== 0 ? total : 0
    },
    handleCheckboxesInsideTable(value) {
      this.$nextTick(() => {
        if (this.$refs['table-groups'] && this.$refs['table-groups'].$children[1]) {
          const allCheckboxesComponents = this.$refs['table-groups'].$children[1].$children
          this.handleCheckbox(allCheckboxesComponents, value)
          this.selectAllVisibleEntries = value
          if (value === false) {
            this.selectAllEntries = false
          }
        }
      })
    },
    getArea(area) {
      return area.effectiveArea !== -1 ? area.effectiveArea : area.livingArea ? area.livingArea : ''
    },
    itemSelected(item, checked) {
      if (checked) {
        this.selectedEntries.push(item)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.id !== item.id)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    livingArea(object) {
      return object.characteristics && object.characteristics.areas && object.characteristics.areas.livingArea !== -1
        ? object.characteristics.areas.livingArea + 'm2'
        : null
    },
    objectClicked(object, index) {
      this.$store.dispatch('onboarding/setOpenedCollapses')
      this.clearRowVariants()
      this.$emit('selected-object', object, index)
    },
    onMiddlelicked(item, index, event) {
      const element = event.srcElement
      if (element.tagName !== 'A') {
        if (item) {
          let route = null
          if (this.isOwnerModule) {
            route = this.$router.resolve({
              name: 'OwnerBuildingDetailsView',
              params: { id: item.buildingId },
              query: {
                view: 'objects',
                elementSelected: item.id
              }
            })
          } else if (this.isAdminModule) {
            route = this.$router.resolve({
              name: 'AdminBuildingDetailsView',
              params: { id: item.buildingId },
              query: {
                view: 'objects',
                elementSelected: item.id
              }
            })
          } else if (this.isAssetModule) {
            route = this.$router.resolve({
              name: 'AssetBuildingDetailsView',
              params: { id: item.buildingId },
              query: {
                view: 'objects',
                elementSelected: item.id
              }
            })
          } else if (this.isAccountingModule) {
            route = this.$router.resolve({
              name: 'AccountingBuildingDetailsView',
              params: { id: item.buildingId },
              query: {
                view: 'objects',
                elementSelected: item.id
              }
            })
          }
          window.open(route.href)
        }
      }
    },
    openConfirmationModal() {
      this.$refs.confirmationModal.show()
    },
    openReminderModal() {
      this.selectForReminder = true
      this.$nextTick(function () {
        this.$refs['reminder-creation'].show()
      })
    },
    openSetClientModal(type) {
      if (type === 'MANAGEMENT_SCOPE') {
        this.scope = 'MANAGEMENT_SCOPE'
      } else if (type === 'MARKETING_SCOPE') {
        this.scope = 'MARKETING_SCOPE'
      }

      this.$refs.setClientModal.setModalShow = true
    },
    redirectToDetailPage(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          return { name: 'OwnerContactDetailsView', params: { id: contactId } }
        } else if (this.isAssetModule) {
          return { name: 'AssetContactDetailsView', params: { id: contactId } }
        } else if (this.isAccountingModule) {
          return { name: 'AccountingContactDetailsView', params: { id: contactId } }
        } else {
          return { name: 'AdminContactDetailsView', params: { id: contactId } }
        }
      }
    },
    redirectToNetworkDetailPage(id) {
      if (id !== null) {
        if (this.isOwnerModule) {
          this.$router.push({ name: 'OwnerNetworkPartnerDetailsView', params: { id: id } })
        } else if (this.isAssetModule) {
          this.$router.push({ name: 'AssetNetworkPartnerDetailsView', params: { id: id } })
        } else if (this.isAccountingModule) {
          this.$router.push({ name: 'AccountingNetworkPartnerDetailsView', params: { id: id } })
        } else {
          this.$router.push({ name: 'AdminNetworkPartnerDetailsView', params: { id: id } })
        }
      }
    },
    searchInputText() {
      this.debouncedFilterObjectsList()
    },
    initFilter() {
      this.searchText = ''
      this.searchCategories = []
      this.searchRooms = []
      this.searchFloor = []
      this.searchZip = []
      this.searchNetworkPartnerAsset = []
      this.searchNetworkPartnerMarketing = []
      this.searchNetworkPartnerAdministration = []
      this.searchAssignee = []
      this.searchStatus = []
      this.searchDebitUntilFilter = []
      this.searchFutureStatus = []
      this.searchMarketingStatus = []
      this.searchPreferredCommunicationChannel = []
      this.filterObjectList()
    },
    addObject() {
      const object = {
        administrationType: this.property && this.property?.type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP' ? 'CONDOMINIUM_OWNERSHIP' : 'LETTING',
        category: ''
      }
      this.listNewObjects.push(object)
    },
    removeObject(indexObj) {
      this.listNewObjects.splice(indexObj, 1)
    },
    filterObjectWithScope(objects, selfScope) {
      const t = this
      const filteredObjects = objects.filter(obj => {
        const accountId = parseInt(store.getters['user/getAccountId'])
        return parseInt(obj.ownerId) === accountId ||
          t.shares
            .filter(share => {
              return share.scope !== 'MARKETING_SCOPE' && share.scope === selfScope &&
                parseInt(share.targetAccountId) === parseInt(accountId) &&
                share.resourceIds.includes(obj.id)
            })
            .map(share => parseInt(share.targetAccountId))
            .includes(accountId)
      })

      return this.sortObjectsByPriority(filteredObjects)
    },
    filtredNetworkPartner() {
      if (this.scope) {
        if (this.scope === 'MANAGEMENT_SCOPE') {
          return this.networkPartners.filter(x => x.subType.includes('LANDLORD'))
        } else if (this.scope === 'MARKETING_SCOPE') {
          return this.networkPartners.filter(x => x.subType.includes('MARKETING'))
        }
      }
      return this.networkPartners
    },
    setClient(newClient) {
      this.selectedClient = null
      if (this.selectedClient === undefined || this.selectedClient === null) {
        this.$refs.setClientModal.setModalShow = false
        this.$refs.setClientModal.setModalDisabled = false
        this.selectedClient = newClient
        this.$nextTick(() => {
          this.$refs.shareconfirmationModal.show()
        })
      }
    },
    saveShares() {
      this.$refs.setClientModal.setModalDisabled = true
      if (!this.selectedEntries.every((val, i, arr) => val.ownerId === arr[0].ownerId)) {
        const listByOwner = this.regroupObjectsByOwner(this.selectedEntries)
        for (let index = 0; index < listByOwner.length; index++) {
          if (this.selectedClient === undefined || this.selectedClient === null) {
            ShareApi.shareUnset([this.scope], listByOwner[index].owner, listByOwner[index].objects)
              .then(() => {
                this.refreshObjects()
                this.$refs.setClientModal.setModalShow = false
                this.$refs.setClientModal.setModalDisabled = false
                this.clearCheckbox()
                this.$refs.shareconfirmationModal.hide()
                Vue.toasted.show(this.$t('message.successMessages.networkPartnerUpdated'), { type: 'success' })
              })
              .catch(e => {
                console.log(e)
                this.$refs.setClientModal.setModalDisabled = false
                Vue.toasted.show(this.$t('message.savingErrors.unsetNetworkPartner'), { type: 'error' })
              })
          } else {
            ShareApi.shareSet(this.scope, listByOwner[index].owner, listByOwner[index].objects, this.selectedClient.id)
              .then(() => {
                this.refreshObjects()
                this.$refs.setClientModal.setModalShow = false
                this.$refs.setClientModal.setModalDisabled = false
                this.clearCheckbox()
                this.$refs.shareconfirmationModal.hide()
                Vue.toasted.show(this.$t('message.successMessages.networkPartnerUpdated'), { type: 'success' })
              })
              .catch(e => {
                console.log(e)
                this.$refs.setClientModal.setModalDisabled = false
                Vue.toasted.show(this.$t('message.savingErrors.setNetworkPartner'), { type: 'error' })
              })
          }
        }
      } else {
        if (this.selectedClient === undefined || this.selectedClient === null) {
          ShareApi.shareUnset([this.scope], this.selectedEntries[0].ownerId, this.selectedEntriesIds)
            .then(() => {
              this.refreshObjects()
              this.$refs.setClientModal.setModalShow = false
              this.$refs.setClientModal.setModalDisabled = false
              this.clearCheckbox()
              this.$refs.shareconfirmationModal.hide()
              Vue.toasted.show(this.$t('message.successMessages.networkPartnerUpdated'), { type: 'success' })
            })
            .catch(e => {
              console.log(e)
              this.$refs.setClientModal.setModalDisabled = false
              Vue.toasted.show(this.$t('message.savingErrors.unsetNetworkPartner'), { type: 'error' })
            })
        } else {
          ShareApi.shareSet(this.scope, this.selectedEntries[0].ownerId, this.selectedEntriesIds, this.selectedClient.id)
            .then(() => {
              this.refreshObjects()
              this.$refs.setClientModal.setModalShow = false
              this.$refs.setClientModal.setModalDisabled = false
              this.clearCheckbox()
              this.$refs.shareconfirmationModal.hide()
              Vue.toasted.show(this.$t('message.successMessages.networkPartnerUpdated'), { type: 'success' })
            })
            .catch(e => {
              console.log(e)
              this.$refs.setClientModal.setModalDisabled = false
              Vue.toasted.show(this.$t('message.savingErrors.setNetworkPartner'), { type: 'error' })
            })
        }
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    availableContact(search) {
      ContactApi.contactResolve(search)
        .then(response => {
          const contacts = response.persons.concat(response.companies)
          const usedContacts = this.objectOwnerContacts.concat(contacts)
          this.setObjectOwnerContacts(usedContacts)
        })
        .catch(e => {
          console.log(e)
        })
    },
    showActivity() {
      this.keyActivity = Math.random().toString(36).substr(2, 9)
      this.$nextTick(() => {
        this.$refs['activity-creation'].show()
      })
    },
    getObjectPreferedChannel(object) {
      let channelName = 'PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED'
      if (this.objectOwnerContacts.length > 0) {
        if (this.property && this.property.type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP' && this.getCurrentCondominium(object.id) && this.getCurrentCondominium(object.id).owner.contactIds.length > 0) {
          const contactId = this.getCurrentCondominium(object.id).owner.contactIds[0]
          channelName = this.objectOwnerContacts.find(x => contactId === x.id)?.preferredCommunicationChannel
        } else if (this.property && this.property.type !== 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP' && this.getCurrentTenancy(object.id) && this.getCurrentTenancy(object.id).tenant.contactIds.length > 0) {
          const contactId = this.getCurrentTenancy(object.id).tenant.contactIds[0]
          channelName = this.objectOwnerContacts.find(x => contactId === x.id)?.preferredCommunicationChannel
        }
      }
      return channelName
    }
  },
  validations: {
    listNewObjects: {
      $each: {
        category: {
          required
        },
        amount: {
          required
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @media (min-width: 768px) {
    #responsiveFooterTable {
      display: none;
    }
  }
  :deep(tfoot tr) {
    position: relative;
  }
  :deep(tfoot .alignFoot) {
    height: 100%;
    position: absolute;
    box-shadow: none;
    display: flex;
    align-items: center;
  }
  :deep(#date-picker-container) {
    position: relative;
    #date-picker-input {
      height: 30px;
      position: absolute;
      width: 100%;
      opacity: 0;
      top:0;
      z-index: 1;
    }
  }
  .tooltip.b-tooltip {
    z-index: 2003;
  }
</style>
