<template>
  <section
    id="allTenancies">
    <div>
      <ais-instant-search
        v-if="searchClient"
        ref="instant-search"
        :search-client="searchClient"
        :index-name="algoliaIndex"
        :routing="routing">
        <app-refresh ref="app-refresh" />
        <ais-stats>
          <template #default="{ nbHits }">
            <coozzy-page-title
              :title="$t('message.navigation.management.tenants')"
              :count="nbHits"
              :count-text="$t('message.navigation.management.tenant')"
              :clear-filter="checkfilter"
              @clear-filter="clickOnClear" />
          </template>
        </ais-stats>
        <!-- Filter -->
        <div class="row align-items-center">
          <div class="col-12 col-md mb-2 mb-md-0">
            <ais-search-box>
              <template #default="{ currentRefinement }">
                <debounced-algolia-search-box
                  ref="debouncedAlgolia"
                  :default-value="currentRefinement"
                  :delay="300" />
              </template>
            </ais-search-box>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="property.name"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('searchPropertyName', items) }}
                <coozzy-multiselect
                  v-model="searchPropertyName"
                  :options="items"
                  :multiple="true"
                  :placeholder="$t('message.propertiesView.tabs.property')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabel"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="refine($event.value)"
                  @remove="refine($event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="building.name"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('searchBuildingName', items) }}
                <coozzy-multiselect
                  v-model="searchBuildingName"
                  :options="items"
                  :multiple="true"
                  :placeholder="$t('message.onBoardingTabs.building')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabel"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="refine($event.value)"
                  @remove="refine($event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="obj.address.zip"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('searchZip', items) }}
                <coozzy-multiselect
                  v-model="searchZip"
                  :options="sortedFilterValues(items, customLabel)"
                  :multiple="true"
                  :placeholder="$t('message.generic.zip')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabel"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="refine($event.value)"
                  @remove="refine($event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="obj.address.city"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('searchCity', items) }}
                <coozzy-multiselect
                  v-model="searchCity"
                  :options="items"
                  :multiple="true"
                  :placeholder="$t('message.generic.city')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabel"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="refine($event.value)"
                  @remove="refine($event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="status"
              :limit="100">
              <template #default="{items, refine}">
                {{ defaultRefining('searchStatus', items) }}
                <coozzy-multiselect
                  v-model="searchStatus"
                  :options="sortedFilterValues(items, customLabelStatus)"
                  :multiple="true"
                  :placeholder="$t('message.generic.currentStatus')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabelStatus"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @select="refine($event.value)"
                  @remove="refine($event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="obj.category"
              :limit="100">
              <template #default="{items, refine}">
                {{ defaultRefining('searchCategories', items) }}
                <coozzy-multiselect
                  v-model="searchCategories"
                  :options="sortedFilterValues(items, customLabelCategory)"
                  :multiple="true"
                  :placeholder="$t('message.onBoarding.buildings.objects.category.title')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabelCategory"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @select="refine($event.value)"
                  @remove="refine($event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="obj.floor"
              :limit="100">
              <template #default="{items, refine}">
                {{ defaultRefining('searchFloor', items) }}
                <coozzy-multiselect
                  v-model="searchFloor"
                  :options="sortedFloor(items)"
                  :multiple="true"
                  :placeholder="$t('message.tableColumnHeadings.floor')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabelFloor"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @select="refineWithNegativeNumbers(refine, $event.value)"
                  @remove="refineWithNegativeNumbers(refine, $event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="obj.rooms"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('searchRoom', items) }}
                <coozzy-multiselect
                  v-model="searchRoom"
                  :options="sortedFilterValues(items, customLabel)"
                  :multiple="true"
                  :placeholder="$t('message.tableColumnHeadings.rooms')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabel"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="refine($event.value)"
                  @remove="refine($event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mb-2 mt-0 mt-md-1 mb-md-0">
            <coozzy-form-select v-model="searchVacancy">
              <option value="tenanciesVacancies">
                {{ $t('message.onBoarding.tenants.tenanciesVacancies') }}
              </option>
              <option value="tenancies">
                {{ $t('message.onBoarding.tenants.tenancies') }}
              </option>
              <option value="vacancies">
                {{ $t('message.onBoarding.tenants.vacancies') }}
              </option>
            </coozzy-form-select>
          </div>
          <div class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
            <coozzy-form-select v-model="searchClosed">
              <option value="ALL">
                {{ $t('message.generic.all') }}
              </option>
              <option value="ACTIVE">
                {{ $t('message.onBoarding.buildings.objects.tenancies.status.current') }}
              </option>
              <option value="CLOSED">
                {{ $t('message.onBoarding.buildings.objects.tenancies.status.closed') }}
              </option>
            </coozzy-form-select>
          </div>
          <div class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="obj.assignees.owner.name"
              :limit="100">
              <template #default="{items, refine, searchForItems}">
                {{ defaultRefining('searchOwnerAssignee', items) }}
                <coozzy-assignee-multiselect
                  v-model="searchOwnerAssignee"
                  :options="sortedFilterValues(getAssigneeData(items), customLabelAssignee)"
                  :multiple="true"
                  :placeholder="$t('message.tableColumnHeadings.adminAssignee')"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabelAssignee"
                  :track-by="'value'"
                  label="name"
                  group-values="employees"
                  group-label="employee"
                  :group-select="true"
                  :preselect-first="false"
                  :taggable="false"
                  @search-change="searchForItems($event)"
                  @select="$event.value ? refine($event.value) : ''"
                  @remove="refine($event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="startingAtFilter"
              :limit="100">
              <div
                slot-scope="{items, refine}"
                class="date-picker-container">
                {{ defaultRefining('searchStartingAtFilter', items) }}
                <date-picker
                  id="date-picker-input2"
                  v-model="searchStartingAtFilter"
                  value-type="format"
                  format="M/YYYY"
                  title-format="M"
                  :placeholder="$t('message.onBoarding.buildings.objects.tenancies.startAt')"
                  :multiple="true"
                  :clearable="true"
                  :editable="false"
                  range-separator=", "
                  type="month"
                  :lang="getLang"
                  @input="datepickerStartingListener(refine)"
                  @clear="datepickerStartingListener(refine)" />
              </div>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mt-0 mt-md-1 mb-2 mb-md-0">
            <ais-refinement-list
              attribute="endingAtFilter"
              :limit="100">
              <div
                slot-scope="{items, refine}"
                class="date-picker-container">
                {{ defaultRefining('searchEndingAtFilter', items) }}
                <date-picker
                  id="date-picker-input"
                  v-model="searchEndingAtFilter"
                  value-type="format"
                  format="M/YYYY"
                  title-format="M"
                  :placeholder="$t('message.onBoarding.buildings.objects.tenancies.endAt')"
                  :multiple="true"
                  :clearable="true"
                  :editable="false"
                  range-separator=", "
                  type="month"
                  :lang="getLang"
                  @input="datepickerListener(refine)"
                  @clear="datepickerListener(refine)" />
              </div>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mt-0 mt-md-2">
            <ais-refinement-list
              attribute="preferredCommunicationChannel"
              :limit="100">
              <template
                #default="{items, refine}">
                {{ defaultRefining('searchPreferredCommunicationChannel', items) }}
                <coozzy-multiselect
                  v-model="searchPreferredCommunicationChannel"
                  :options="sortedFilterValues(sortOptions(items), customLabel)"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabelChannel"
                  :placeholder="$t('message.contact.preferredCommunicationChannel')"
                  :track-by="'value'"
                  label="name"
                  :preselect-first="false"
                  :taggable="false"
                  @select="refine($event.value)"
                  @remove="refine($event.value)" />
              </template>
            </ais-refinement-list>
          </div>
          <div class="col-12 col-md-2 mt-0 mt-md-2">
            <ais-refinement-list
              attribute="obj.tags"
              :limit="100">
              <template #default="{items, refine}">
                {{ defaultRefining('searchTags', items) }}
                <coozzy-multiselect
                  v-model="searchTags"
                  :options="sortedFilterValues(items, customLabelTags)"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  :custom-label="customLabelTags"
                  :placeholder="$t('message.generic.tags')"
                  :track-by="'value'"
                  :preselect-first="false"
                  :taggable="false"
                  @select="refine($event.value)"
                  @remove="refine($event.value)" />
              </template>
            </ais-refinement-list>
          </div>
        </div>
        <!-- eslint-disable vue/attribute-hyphenation -->
        <ais-configure
          :hitsPerPage="nbrPerPage"
          :filters="filterQuery" />
        <!-- eslint-enable vue/attribute-hyphenation -->
        <div
          id="rowConfig"
          class="row mt-2 align-items-center">
          <div class="col-md-2">
            <coozzy-dropdown
              design="green"
              size="sm"
              class="w-100"
              :text="selectedEntriesText">
              <coozzy-dropdown-item
                v-if="isTicketingEnabled && (isEditor || isTicketEditor)"
                :disabled="selectedEntries.length === 0"
                @click="createTicket('TICKET_TYPE_TASK')">
                {{ $t('message.ticketOverview.createTicket') }}
              </coozzy-dropdown-item>
              <!-- <coozzy-dropdown-item
                v-if="isTicketingEnabled && (isEditor || isTicketEditor)"
                :disabled="selectedEntries.length === 0"
                @click="navigateToCreateOrder()">
                {{ $t('message.ticketOverview.createOrder') }}
              </coozzy-dropdown-item> -->
              <coozzy-dropdown-item
                v-if="isEditor"
                :disabled="selectedEntries.length === 0"
                @click="showActivity()">
                {{ $t('message.contact.activity.create') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="$refs['reminder-creation'].show()">
                {{ $t('message.calendar.reminder.create') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="isEditor"
                :disabled="selectedEntries.length === 0"
                @click="serialEmail()">
                {{ selectedEntries.length === 1 ? $t('message.generic.oneEmail') : $t('message.generic.serialEmail') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                v-if="isEditor"
                :disabled="selectedEntries.length === 0"
                @click="serialLetter()">
                {{ selectedEntries.length === 1 ? $t('message.generic.oneSerialLetter') : $t('message.generic.serialLetter') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-space-divider v-if="isEditor" />
              <div
                v-if="isEditor"
                class="row m-0"
                :class="selectedEntries.length === 0 || notAllStatusNotActivated ? 'coozzyDropdownItemTooltip' : ''">
                <coozzy-dropdown-item
                  :disabled="selectedEntries.length === 0 || notAllStatusNotActivated"
                  @click="showActivateTenancyModal()">
                  {{ $t('message.manageBuilding.bulkAction.activateTenancy') }}
                </coozzy-dropdown-item>
                <div class="pl-0">
                  <coozzy-info-circle-icon
                    v-if="notAllStatusNotActivated"
                    v-b-tooltip.hover.html="$t('message.deleteErrors.activateTenancyTooltip')"
                    class="mt-2" />
                </div>
              </div>
              <coozzy-dropdown-space-divider v-if="isEditor" />
              <div
                v-if="isEditor"
                class="row m-0"
                :class="selectedEntries.length === 0 || isTenanciesActivated > 0 ? 'coozzyDropdownItemTooltip' : ''">
                <coozzy-dropdown-item
                  class="col pr-0 pl-0"
                  :disabled="selectedEntries.length === 0 || isTenanciesActivated > 0"
                  @click="selectedEntries.length !== 0 ? openConfirmationModal() : ''">
                  {{ $t('message.generic.delete') }}
                </coozzy-dropdown-item>
                <div class="pl-0">
                  <coozzy-info-circle-icon
                    v-if="isTenanciesActivated > 0"
                    v-b-tooltip.hover.html="$t('message.deleteErrors.deleteActiveTenancyTooltip')"
                    class="mt-2" />
                </div>
              </div>
            </coozzy-dropdown>
          </div>
          <div class="col-md-2">
            <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
              <coozzy-form-checkbox
                v-model="selectAllEntries"
                :initial="selectAllEntries"
                :class="'p-0 w-100 btn-height-auto'"
                name="selectAllEntries"
                button>
                {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: nbrTotalOfHits }) : $t('message.generic.selectHiddenEntries', { amount: nbrTotalOfHits }) }}
              </coozzy-form-checkbox>
            </template>
            <ais-clear-refinements :included-attributes="['query', 'status', 'obj.address.zip', 'obj.category', 'obj.floor', 'obj.rooms', 'obj.assignees.owner.name', 'endingAtFilter', 'startingAtFilter', 'preferredCommunicationChannel', 'obj.tags', 'obj.address.city', 'property.name', 'building.name']">
              <template #default="{ canRefine, refine }">
                {{ setCanRefine(canRefine) }}
                <a
                  v-if="canRefine || searchClosed !== 'ACTIVE'"
                  ref="clearClick"
                  @click="initFilter(refine)" />
                <a
                  v-if="canRefine"
                  ref="clearEndDateClick"
                  @click="initFilterEndDate(refine)" />
                <a
                  v-if="canRefine"
                  ref="clearStartDateClick"
                  @click="initFilterStartDate(refine)" />
              </template>
            </ais-clear-refinements>
          </div>
          <div
            v-if="isCompanyAdmin === true"
            class="offset-md-5 col-md-1 d-flex justify-content-end align-items-center">
            <div
              @click="openAssembleListModal()">
              <coozzy-filters-icon
                v-b-tooltip="{container: '#rowConfig', title: $t('message.generic.tooltipAssembleList')}" />
            </div>
          </div>
          <div
            class="col-md-2 col-sm-6 col-12 mt-2 mt-sm-0"
            :class="isCompanyAdmin !== true ? 'offset-md-6' : ''">
            <coozzy-form-select
              v-model="nbrPerPage"
              name="numberEntries"
              class="select-entries float-right">
              <option value="10">
                10 {{ $t('message.generic.entries') }}
              </option>
              <option value="50">
                50 {{ $t('message.generic.entries') }}
              </option>
              <option value="100">
                100 {{ $t('message.generic.entries') }}
              </option>
              <option value="500">
                500 {{ $t('message.generic.entries') }}
              </option>
              <option value="1000">
                1000 {{ $t('message.generic.entries') }}
              </option>
            </coozzy-form-select>
          </div>
        </div>
        <ais-state-results>
          <template #default="{ hits, results }">
            {{ getState(results) }}
            <b-table
              ref="tenanciesTable"
              class="mt-2"
              bordered
              hover
              responsive="true"
              stacked="md"
              selectable
              select-mode="single"
              :fields="fields"
              :items="hits"
              @row-clicked="navigateToDetail"
              @row-middle-clicked="onMiddlelicked">
              <!-- Headings -->
              <template
                #head(checkbox)>
                <coozzy-form-checkbox
                  ref="header_checkbox"
                  @change="(value) => { handleCheckboxesInsideTable(value) }" />
              </template>
              <template #head(objects)>
                {{ $t('message.tableColumnHeadings.objects') }}
              </template>
              <template
                #head(tenants)>
                {{ $t('message.tableColumnHeadings.tenant') }}
              </template>
              <template #head(subtenant)>
                {{ $t('message.tableColumnHeadings.subtenant') }}
              </template>
              <template #head(contractNumber)>
                {{ $t('message.tableColumnHeadings.contractNumber') }}
              </template>
              <template #head(tenancieObjectId)>
                {{ $t('message.tableColumnHeadings.tenancieObjectId') }}
              </template>
              <template #head(tenanciePartner)>
                {{ $t('message.tableColumnHeadings.tenanciePartner') }}
              </template>
              <template
                #head(address)>
                {{ $t('message.tableColumnHeadings.address') }}
              </template>
              <template
                #head(type)>
                {{ $t('message.tableColumnHeadings.type' ) }}
              </template>
              <template
                #head(floor)>
                {{ $t('message.tableColumnHeadings.floor') }}
              </template>
              <template
                #head(rooms)>
                {{ $t('message.tableColumnHeadings.rooms') }}
              </template>
              <template
                #head(livingArea)>
                {{ $t('message.tableColumnHeadings.area') }}
              </template>
              <template
                #head(netRent)>
                <span>{{ $t('message.tableColumnHeadings.netRent') }}</span>
              </template>
              <template
                #head(grossRent)>
                <span>{{ $t('message.tableColumnHeadings.grossRent') }}</span>
              </template>
              <template
                #head(startAt)>
                {{ $t('message.onBoarding.buildings.objects.tenancies.startAt') }}
              </template>
              <template
                #head(endAt)>
                {{ $t('message.onBoarding.buildings.objects.tenancies.endAt') }}
              </template>
              <template #head(assignee)>
                {{ $t('message.tableColumnHeadings.assignee') }}
              </template>
              <template #head(m2PerYear)>
                {{ $t('message.tableColumnHeadings.m2PerYear') }}
              </template>
              <template #head(marketNetRent)>
                {{ $t('message.tableColumnHeadings.marketNetRent') }}
              </template>
              <template #head(targetNetRent)>
                {{ $t('message.tableColumnHeadings.targetNetRent') }}
              </template>
              <template #head(marketGrossRent)>
                {{ $t('message.tableColumnHeadings.marketGrossRent') }}
              </template>
              <template #head(targetGrossRent)>
                {{ $t('message.tableColumnHeadings.targetGrossRent') }}
              </template>
              <template #head(subCategory)>
                {{ $t('message.tableColumnHeadings.subCategory') }}
              </template>
              <template #head(HeatingAdditionalCosts)>
                {{ $t('message.tableColumnHeadings.HeatingAdditionalCosts') }}
              </template>
              <!-- Columns -->
              <template #cell(objects)="data">
                {{ data.item.obj.name }}
              </template>
              <template
                #cell(checkbox)="data">
                <coozzy-form-checkbox
                  :id="'check_' + data.item.objectID"
                  @change="(value) => { itemSelected(data.item, value) }" />
              </template>
              <template
                #cell(address)="data">
                <address-text
                  v-if="data.item.obj"
                  :address="data.item.obj.address" />
              </template>
              <template
                #cell(status)="data">
                <div
                  class="color-box mr-2"
                  :class="data.item.status.replace('_offline','').replace('_online','')" />
                {{ $t(`message.onBoarding.buildings.objects.tenancies.status.${data.item.status}`) }}
                {{ getUnjustifiedDate(data.item) }}
              </template>
              <template #cell(contractNumber)="data">
                <div
                  class="color-box mr-2" />
                {{ data.item.numericId }}
              </template>
              <template #cell(tenancieObjectId)="data">
                {{ data.item.obj.numericId }}
              </template>
              <template #cell(tenanciePartner)="data">
                <span
                  v-b-tooltip="{container: '#allTenancies', title: $t('message.generic.accountId') + data.item.owner.id}"
                  class="d-block">
                  <img
                    height="20px"
                    src="/img/propbase-icon.png"
                    alt="prop-base">
                  <template v-if="data.item.obj.networkPartnerAsset">
                    {{ data.item.owner.name.replace(` - ${data.item.obj.networkPartnerAsset.name}`, '') }}
                  </template>
                  <template v-else>
                    {{ data.item.owner.name }}
                  </template>
                </span>
                <span
                  v-if="data.item.obj.networkPartnerAsset && data.item.obj.networkPartnerAsset.name !== 'Unassigned Nicht zugeordnet Non assegnato'"
                  v-b-tooltip="{container: '#allTenancies', title: $t('message.generic.accountId') + data.item.obj.networkPartnerAsset.accountId}"
                  class="d-block">
                  <img
                    height="20px"
                    src="/img/assetbase-icon.png"
                    alt="asset-base">
                  {{ data.item.obj.networkPartnerAsset.name }}
                </span>
                <span
                  v-if="data.item.obj.networkPartners.length > 0 && data.item.obj.networkPartners.find(element => element.scope ==='accounting')"
                  v-b-tooltip="{container: '#allTenancies', title: $t('message.generic.accountId') + data.item.obj.networkPartners.find(element => element.scope ==='accounting').accountId}"
                  class="d-block">
                  <img
                    height="20px"
                    src="/img/financebase-icon.png"
                    alt="finance-base">
                  {{ data.item.obj.networkPartners.find(element => element.scope === 'accounting').name }}
                </span>
                <span
                  v-if="data.item.obj.networkPartners.length > 0 && data.item.obj.networkPartners.find(element => element.scope ==='marketing')"
                  v-b-tooltip="{container: '#allTenancies', title: $t('message.generic.accountId') + data.item.obj.networkPartners.find(element => element.scope ==='marketing').accountId}"
                  class="d-block">
                  <img
                    height="20px"
                    src="/img/marketbase-icon.png"
                    alt="market-base">
                  {{ data.item.obj.networkPartners.find(element => element.scope === 'marketing').name }}
                </span>
              </template>
              <template
                #cell(type)="data">
                <template v-if="data.item.obj && data.item.obj.category && data.item.obj.category !== 'UNDEFINED_CATEGORY'">
                  {{ $t('message.onBoarding.buildings.objects.category.' + data.item.obj.category.toLowerCase()) }}
                </template>
                <template v-else>
                  -
                </template>
              </template>
              <template
                #cell(floor)="data">
                {{ data.item.obj && data.item.obj.hasOwnProperty('floor') ? formatFloor(data.item.obj.floor) : '-' }}
              </template>
              <template
                #cell(rooms)="data">
                {{ (data.item.obj && data.item.obj.rooms? data.item.obj.rooms : '') | displayOptionalValue }}
              </template>
              <template #cell(livingArea)="data">
                <div v-if="data.item.obj && ['apartment', 'house'].includes(data.item.obj.category) ">
                  {{ data.item.obj.livingArea ? data.item.obj.livingArea : '' | formatArea }}
                </div>
                <div v-else>
                  {{ data.item.obj.effectiveArea ? data.item.obj.effectiveArea : '' | formatArea }}
                </div>
              </template>
              <template
                #cell(tenants)="data">
                <template v-if="data.item.vacancy">
                  {{ $t('message.onBoarding.buildings.objects.tenancies.vacancy') }}
                </template>
                <template v-else-if="data.item.tenants.length === 0 || data.item.tenants.map(n => n.name).join(', ') === ''">
                  -
                </template>
                <template v-else>
                  <router-link
                    v-for="(tenant, tenantIndex) in data.item.tenants"
                    :key="tenantIndex"
                    :to="{ name: 'OwnerContactDetailsView', params: { id: tenant.contactId } }">
                    {{ tenant.name }}<span v-if="tenantIndex < data.item.tenants.length - 1">,</span><br>
                  </router-link>
                </template>
              </template>
              <template #cell(subtenant)="data">
                <template v-if="data.item.subTenants && data.item.subTenants.length > 0">
                  <template
                    v-for="(subtenant, indexSubTenant) in data.item.subTenants">
                    <template
                      v-if="subtenant.tenant.length > 0">
                      <router-link
                        v-for="(tenant, indexTenant) in subtenant.tenant"
                        :key="tenant.contactId"
                        :to="{ name: 'OwnerContactDetailsView', params: { id: tenant.contactId } }">
                        {{ tenant.name }}<span v-if="indexTenant < subtenant.tenant.length - 1 || indexSubTenant < data.item.subTenants.length - 1">,</span><br>
                      </router-link>
                    </template>
                  </template>
                </template>
                <template v-else>
                  -
                </template>
              </template>
              <template #cell(netRent)="data">
                {{ data.item.coldRentPerMonth | formatPrice }}
                <coozzy-info-circle-icon
                  v-if="data.item.taxable || data.item.opted"
                  v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.inclTax')"
                  class="ml-1" />
              </template>
              <template
                #cell(m2PerYear)="data">
                <div class="text-nowrap">
                  <template v-if="data.item.coldRentM2PerYear">
                    <span>{{ Math.round(data.item.coldRentM2PerYear) | formatPrice }}</span>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
              </template>
              <template
                #cell(marketNetRent)="data">
                <div class="text-nowrap">
                  <template v-if="data.item.obj && data.item.obj.pricing && data.item.obj.pricing.marketNetRent">
                    <span>{{ data.item.obj.pricing.marketNetRent | formatPrice }}</span>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
              </template>
              <template
                #cell(targetNetRent)="data">
                <div class="text-nowrap">
                  <template v-if="data.item.obj && data.item.obj.pricing && data.item.obj.pricing.targetNetRent">
                    <span>{{ data.item.obj.pricing.targetNetRent | formatPrice }}</span>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
              </template>
              <template
                #cell(marketGrossRent)="data">
                <div class="text-nowrap">
                  <template v-if="data.item.obj && data.item.obj.pricing && data.item.obj.pricing.marketGrossRent">
                    <span>{{ data.item.obj.pricing.marketGrossRent | formatPrice }}</span>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
              </template>
              <template
                #cell(targetGrossRent)="data">
                <div class="text-nowrap">
                  <template v-if="data.item.obj && data.item.obj.pricing && data.item.obj.pricing.targetGrossRent">
                    <span>{{ data.item.obj.pricing.targetGrossRent | formatPrice }}</span>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
              </template>
              <template
                #cell(HeatingAdditionalCosts)="data">
                <div class="text-nowrap">
                  <template v-if="data.item.additionalCosts">
                    <span>{{ data.item.additionalCosts| formatPrice }}</span>
                  </template>
                  <template v-else>
                    -
                  </template>
                </div>
              </template>
              <template #cell(subCategory)="data">
                <template v-if="data.item.obj?.subCategories?.length > 0">
                  <template v-for="(subCat, subCatIndex) in data.item.obj.subCategories">
                    <span
                      v-if="subCat !== 'UNDEFINED_SUB_CATEGORY'"
                      :key="subCatIndex">
                      {{ $t(`message.onBoarding.buildings.objects.subCategory.` + subCat?.toLowerCase()) }}
                    </span>
                    <template v-else>
                      -
                    </template>
                  </template>
                </template>
                <template v-else>
                  -
                </template>
              </template>
              <template #cell(grossRent)="data">
                {{ data.item.grossRentPerMonth | formatPrice }}
                <coozzy-info-circle-icon
                  v-if="data.item.taxable || data.item.opted"
                  v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.inclTax')"
                  class="ml-1" />
              </template>
              <template #cell(startAt)="data">
                {{ data.item.startingAt | objectToDate }}
              </template>
              <template #cell(endAt)="data">
                {{ data.item.endingAt | objectToDate }}
              </template>
              <template #cell(assignee)="data">
                {{
                  data.item.obj.assignees && data.item.obj.assignees.owner && data.item.obj.assignees.owner.name && data.item.obj.assignees.owner.name !== 'Unassigned Nicht zugeordnet Non assegnato' ? data.item.obj.assignees.owner.name : '-'
                }}
              </template>
            </b-table>
          </template>
        </ais-state-results>
        <pagination-algolia
          ref="paginator"
          @changed-page="clearCheckbox()" />
      </ais-instant-search>
    </div>
    <b-modal
      :id="'tenancy-confirmationModal'"
      ref="tenancy-confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.confirmation')">
      <div
        v-if="loadingDeletion"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <template
        v-else>
        <div class="col">
          <p>{{ $t('message.onBoarding.deleteModals.bodyTenancy') }}</p>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('tenancy-confirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deleteTenancies()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <assemble-list-modal
      ref="assembleListModal"
      modal-id="assembleListModal"
      :fields="selectedFields"
      :default-fields="dataFields"
      @save-fields="saveFields" />
    <reminder-creation-modal
      ref="reminder-creation"
      :references-ids="selectedEntriesIds"
      :source="'tenancies'"
      @new-reminder="clearCheckbox" />
    <create-activity-modal
      ref="activity-creation"
      :references-ids="selectedEntriesContactIds"
      :ids="selectedEntriesIds"
      @activity-created="clearCheckbox" />
    <activate-tenancy-modal
      v-if="selectedEntriesIds.length > 0"
      ref="activateTenancyModal"
      :owner-id="selectedEntries[0].owner.id"
      :selected-entries-ids="selectedEntriesIds"
      :is-same-tenant="false"
      :tenancies="tenanciesList"
      :objects="objectsList"
      :contacts="contactsList"
      :last-tenancy="lastTenancy"
      @tenancy-activated="tenancyActivated" />
  </section>
</template>

<script>
import { formatting } from '@/mixins/formatting'
import { algolia } from '@/mixins/algolia'
import ObjectApi from '../misc/apis/ObjectApi'
import AddressText from '../framework/components/misc/AddressText'
import CoozzyButton from '../framework/components/button/CoozzyButton'
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import CreateActivityModal from '@/contact/components/CreateActivityModal'
import ActivateTenancyModal from '@/addTenancy/ActivateTenancyModal'
import AppRefresh from '../framework/components/RefreshAlgolia'
import DebouncedAlgoliaSearchBox from '../framework/components/DebouncedAlgoliaSearchBox'
import PaginationAlgolia from '../framework/components/PaginationAlgolia'
import CoozzyAssigneeMultiselect from '../framework/components/multiselect/CoozzyAssigneeMultiselect'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem'
import CoozzyDropdownSpaceDivider from '@/framework/components/dropdown/CoozzyDropdownSpaceDivider'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import { tables } from '@/mixins/tables'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/de'
import { mail } from '@/mixins/mail'
import CoozzyFiltersIcon from '@/framework/components/icons/CoozzyFiltersIcon'
import AssembleListModal from '@/contact/components/AssembleListModal'
import SearchApi from '@/misc/apis/SearchApi'
import { routeChecks } from '@/mixins/routeChecks'
import ContactApi from '@/misc/apis/ContactApi'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'OwnerTenanciesView',
  components: {
    DatePicker,
    CoozzySpinner,
    CoozzyFormCheckbox,
    CoozzyFormSelect,
    CoozzyDropdownSpaceDivider,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyMultiselect,
    CoozzyAssigneeMultiselect,
    CoozzyPageTitle,
    DebouncedAlgoliaSearchBox,
    PaginationAlgolia,
    CreateActivityModal,
    AppRefresh,
    ReminderCreationModal,
    CoozzyButton,
    AddressText,
    CoozzyInfoCircleIcon,
    CoozzyFiltersIcon,
    AssembleListModal,
    ActivateTenancyModal
  },
  mixins: [formatting, algolia, tables, mail, routeChecks],
  metaInfo() {
    return {
      title: this.$t('message.navigation.management.tenants')
    }
  },
  data() {
    return {
      showFirst: false,
      loadingDeletion: false,
      keyActivity: 0,
      algoliaIndex: 'tenancy',
      searchCategories: [],
      searchZip: [],
      searchStatus: [],
      searchFloor: [],
      searchRoom: [],
      searchClosed: 'ACTIVE',
      searchVacancy: 'tenanciesVacancies',
      searchOwnerAssignee: [],
      selectedEntries: [],
      checkfilter: false,
      searchEndingAtFilter: [],
      defaultDateEndFilter: [],
      searchStartingAtFilter: [],
      defaultStartDateFilter: [],
      searchPreferredCommunicationChannel: [],
      searchPropertyName: [],
      searchBuildingName: [],
      searchCity: [],
      searchTags: [],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        }
      ],
      dataFields: [
        {
          key: 'tenants',
          label: this.$t('message.tableColumnHeadings.tenant'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'status',
          label: this.$t('message.tableColumnHeadings.status'),
          thClass: 'align-middle',
          tdClass: 'align-middle status-td-icon',
          selected: true
        },
        {
          key: 'contractNumber',
          label: this.$t('message.tableColumnHeadings.contractNumber'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'address',
          label: this.$t('message.tableColumnHeadings.address'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'type',
          label: this.$t('message.tableColumnHeadings.type'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'floor',
          label: this.$t('message.tableColumnHeadings.floor'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'rooms',
          label: this.$t('message.tableColumnHeadings.rooms'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'livingArea',
          label: this.$t('message.tableColumnHeadings.area'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'netRent',
          label: this.$t('message.tableColumnHeadings.netRent'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'grossRent',
          label: this.$t('message.tableColumnHeadings.grossRent'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'startAt',
          label: this.$t('message.onBoarding.buildings.objects.tenancies.startAt'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'endAt',
          label: this.$t('message.onBoarding.buildings.objects.tenancies.endAt'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'objects',
          label: this.$t('message.tableColumnHeadings.objects'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'assignee',
          label: this.$t('message.tableColumnHeadings.assignee'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'tenanciePartner',
          label: this.$t('message.tableColumnHeadings.tenanciePartner'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'tenancieObjectId',
          label: this.$t('message.tableColumnHeadings.tenancieObjectId'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'subtenant',
          label: this.$t('message.tableColumnHeadings.subtenant'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'm2PerYear',
          label: this.$t('message.tableColumnHeadings.m2PerYear'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'marketNetRent',
          label: this.$t('message.tableColumnHeadings.marketNetRent'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'targetNetRent',
          label: this.$t('message.tableColumnHeadings.targetNetRent'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'marketGrossRent',
          label: this.$t('message.tableColumnHeadings.marketGrossRent'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'targetGrossRent',
          label: this.$t('message.tableColumnHeadings.targetGrossRent'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'subCategory',
          label: this.$t('message.tableColumnHeadings.subCategory'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'HeatingAdditionalCosts',
          label: this.$t('message.tableColumnHeadings.HeatingAdditionalCosts'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        }
      ],
      selectedFields: [],
      tenanciesList: [],
      contactsList: [],
      objectsList: [],
      lastTenancy: [],
      paramsAlgolia: {
        query: '',
        params: '',
        index: ''
      },
      nbrTotalOfHits: 0,
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: []
    }
  },
  computed: {
    employeeList() {
      if (this.getEmployees().length > 0) {
        return this.getEmployees()
      }
      return []
    },
    filterQuery() {
      let query = ''

      if (this.searchClosed === 'ACTIVE') {
        query += 'closed:false'
      } else if (this.searchClosed === 'CLOSED') {
        query += 'closed:true'
      }

      if (this.searchVacancy === 'tenancies') {
        if (query === '') {
          query += 'vacancy:false'
        } else {
          query += ' AND vacancy:false'
        }
      } else if (this.searchVacancy === 'vacancies') {
        if (query === '') {
          query += 'vacancy:true'
        } else {
          query += ' AND vacancy:true'
        }
      }

      return query
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.allEntries.map(x => x.objectID) : this.selectedEntries.map(obj => obj.objectID)
    },
    selectedEntriesContactIds() {
      const listEntries = this.selectAllEntries ? this.allEntries.map(x => x.tenants) : this.selectedEntries.map(x => x.tenants)
      const merged = [].concat.apply([], listEntries)
      return merged.map(x => x.contactId)
    },
    isTenanciesActivated() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.filter(x => x.status === 'current' || x.status === 'terminated').length
    },
    notAllStatusNotActivated() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.length > 0 && listEntries.filter(x => x.status !== 'not_activated').length > 0
    },
    getLang() {
      return this.$store.getters['internationalization/getCurrentLanguage']
    }
  },
  watch: {
    checkfilter: function () {
      this.clearCheckbox()
    },
    nbrPerPage: function () {
      if (this.showFirst) {
        this.$nextTick(() => {
          if (this.$refs.paginator) {
            this.$refs.paginator.refresh()
          }
        })
      } else {
        this.$nextTick(() => {
          if (this.$refs.paginator) {
            this.$refs.paginator.redirect(this.$route.query.page)
          }
        })
      }
      this.showFirst = true
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        // check if we already call algolia with the same params
        if (this.nbrTotalOfHits !== this.allEntries.length) {
          this.allEntries = await this.getAllAvailableDataByIndex(this.paramsAlgolia)
        }
      }
    }
  },
  mounted() {
    this.loadInstantSearch()
    SearchApi.loadSettings(this.accountId).then(response => {
      const settings = response.tableSettings
      let hasOldList = false
      if (settings.length > 0) {
        settings.forEach(setting => {
          if (setting.columns.length > 0 && setting.table === 'tenancy_overview') {
            hasOldList = true
            this.loadFields(setting)
          }
        })
      }
      if (hasOldList === false) {
        this.fields.push(...this.dataFields.filter(x => x.selected))
        this.selectedFields = JSON.parse(JSON.stringify(this.dataFields))
      }
    }).catch(e => {
      console.log(e)
    }).finally(() => {
      setTimeout(() => {
        this.$refs.debouncedAlgolia?.setFocusInput()
        if (this.query !== '') {
          this.$refs.debouncedAlgolia?.setValue(this.query)
        }
      }, 300)
    })
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setSendMailSecondaryTitle',
      'setUseBcc',
      'setSendMailContactsWithoutEmail',
      'setRenderWithObjectId',
      'setSendMailContactsWithObject',
      'setShowAllPlaceholders'
    ]),
    getState(result) {
      this.nbrTotalOfHits = result.nbHits
      const params = this.editAlgoliaParams(result.params)
      this.paramsAlgolia = {
        query: result.query,
        params: params,
        index: result.index,
        attributesToRetrieve: ['objectID', 'tenants', 'obj', 'status', 'endingAt']
      }
      return ''
    },
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting)
      this.fields = result?.fields
      this.selectedFields = result?.selectedFields
      this.$refs.objectsTable?.refresh()
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      SearchApi.saveSettings(this.accountId, columns, 'tenancy_overview').then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal')
      })
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    createTicket(type) {
      const defaultAssignee = this.selectedEntries.every((val, i, arr) => val.owner.id === arr[0].owner.id)
        ? this.selectedEntries[0].owner.id
: ''
      this.$router.push({ name: 'OwnerTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
    },
    navigateToCreateOrder() {
      const currentRoute = { name: this.$router.currentRoute.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      const tenancyIds = this.selectedEntriesIds.join(',')
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerCreateOrderView', query: { tenancyIds: tenancyIds, source: 'tenancy' } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminCreateOrderView', query: { tenancyIds: tenancyIds, source: 'tenancy' } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetCreateOrderView', query: { tenancyIds: tenancyIds, source: 'tenancy' } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingCreateOrderView', query: { tenancyIds: tenancyIds, source: 'tenancy' } }, () => {})
      }
    },
    datepickerListener(refine) {
      if (this.searchEndingAtFilter && this.defaultDateEndFilter) {
        const difference1 = this.searchEndingAtFilter.filter(x => !this.defaultDateEndFilter.includes(x))
        const difference2 = this.defaultDateEndFilter.filter(x => !this.searchEndingAtFilter.includes(x))
        const difference = difference1.concat(difference2)
        if (difference.length > 0) {
          refine(difference[0])
        }
        this.defaultDateEndFilter = this.searchEndingAtFilter
      }
      if (!this.searchEndingAtFilter) {
        this.$refs.clearEndDateClick?.click()
      }
    },
    datepickerStartingListener(refine) {
      if (this.searchStartingAtFilter && this.defaultStartDateFilter) {
        const difference1 = this.searchStartingAtFilter.filter(x => !this.defaultStartDateFilter.includes(x))
        const difference2 = this.defaultStartDateFilter.filter(x => !this.searchStartingAtFilter.includes(x))
        const difference = difference1.concat(difference2)
        if (difference.length > 0) {
          refine(difference[0])
        }
        this.defaultStartDateFilter = this.searchStartingAtFilter
      }
      if (!this.searchStartingAtFilter) {
        this.$refs.clearStartDateClick?.click()
      }
    },
    getUnjustifiedDate(item) {
      const UnjustifiedObj = item.unjustifiedUseUntil || item.unjustifiedUseFrom ? { until: item.unjustifiedUseUntil ? item.unjustifiedUseUntil : null, from: item.unjustifiedUseFrom ? item.unjustifiedUseFrom : null } : null
      return (UnjustifiedObj && UnjustifiedObj.until !== null && UnjustifiedObj.until !== undefined
? '(' + this.$t('message.enterTermination.unjustifiedUse.statusWithEnd', {
        startDate: this.$options.filters.objectToDate(UnjustifiedObj.from),
        endDate: this.$options.filters.objectToDate(UnjustifiedObj.until)
      }) + ')'
: UnjustifiedObj && (UnjustifiedObj.until === null || UnjustifiedObj.until === undefined)
? '(' + this.$t('message.enterTermination.unjustifiedUse.statusWithoutEnd', {
        startDate: this.$options.filters.objectToDate(UnjustifiedObj.from)
      }) + ')'
: '')
    },
    async serialEmail() {
      this.setShowAllPlaceholders(true)
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      await this.prepareSerialEmail(listEntries)
      this.loading = false
    },
    serialLetter() {
      const uniqKey = uuidv4()
      localStorage.setItem(uniqKey, this.selectedEntriesIds)
      this.$nextTick(() => {
        this.$router.push({
          name: 'OwnerSerialLetterProcessView',
          query: {
            source: 'tenancy',
            uuid: uniqKey,
            previousRoute: this.$route.fullPath
          }
        })
      })
    },
    initFilterEndDate(refine) {
      this.defaultDateEndFilter = []
      this.searchEndingAtFilter = []
      refine()
    },
    initFilterStartDate(refine) {
      this.defaultStartDateFilter = []
      this.searchStartingAtFilter = []
      refine()
    },
    initFilter(refine) {
      this.defaultDateEndFilter = []
      this.defaultStartDateFilter = []

      this.searchEndingAtFilter = []
      this.searchCategories = []
      this.searchRoom = []
      this.searchFloor = []
      this.searchZip = []
      this.searchStatus = []
      this.searchClosed = 'ACTIVE'
      this.searchVacancy = 'tenanciesVacancies'
      this.searchOwnerAssignee = []
      this.searchPreferredCommunicationChannel = []
      this.searchTags = []
      this.searchPropertyName = []
      this.searchBuildingName = []
      this.searchCity = []
      refine()
    },
    clickOnClear() {
      this.$refs.clearClick.click()
    },
    setCanRefine(canRefine) {
      this.checkfilter = canRefine || this.searchClosed !== 'ACTIVE'
      return ''
    },
    clearCheckbox() {
      this.selectedEntries = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    customLabelFloor(item) {
      return this.formatFloor(item.label) + ' (' + item.count + ')'
    },
    customLabelCategory(item) {
      return this.$t('message.onBoarding.buildings.objects.category.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    customLabel(item) {
      return item.value + ' (' + item.count + ')'
    },
    customLabelChannel(item) {
      if (item.value === 'PREFERRED_COMMUNICATION_CHANNEL_EMAIL') {
        return this.$t('message.contact.preferredCommunicationChannelType.email') + ' (' + item.count + ')'
      }
      if (item.value === 'PREFERRED_COMMUNICATION_CHANNEL_LETTER') {
        return this.$t('message.contact.preferredCommunicationChannelType.letter') + ' (' + item.count + ')'
      }
      if (item.value === 'PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED') {
        return '- (' + item.count + ')'
      }
      return item.value
    },
    customLabelTags(item) {
      return item.label + ' (' + item.count + ')'
    },
    customLabelStatus(item) {
      return this.$t('message.onBoarding.buildings.objects.tenancies.status.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs.tenanciesTable) {
        const allCheckboxesComponents = this.$refs.tenanciesTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
        this.selectAllVisibleEntries = value
        if (value === false) {
          this.selectAllEntries = false
        }
      }
    },
    itemSelected(item, checked) {
      if (checked) {
        this.selectedEntries.push(item)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.objectID !== item.objectID)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    showActivity() {
      this.keyActivity = Math.random().toString(36).substr(2, 9)
      this.$nextTick(() => {
        this.$refs['activity-creation'].show()
      })
    },
    openConfirmationModal() {
      this.$refs['tenancy-confirmationModal'].show()
    },
    deleteTenancies() {
      const promises = []
      const current = this
      for (let index = 0; index < this.selectedEntriesIds.length; index++) {
        promises.push(ObjectApi.deleteTenancy(this.selectedEntriesIds[index]))
      }
      this.loadingDeletion = true
      Promise.all(promises)
        .then(() => {
          setTimeout(() => {
            this.loadingDeletion = false
            this.$refs['app-refresh'].refresh()
            this.selectedEntries = []
            this.handleCheckboxesInsideTable(false)
            Vue.toasted.show(this.$t('message.successMessages.tenancies'), { type: 'success' })
            current.$refs['tenancy-confirmationModal'].hide()
          }, 2000)
        })
        .catch(e => {
          this.loadingDeletion = false
          current.$refs['tenancy-confirmationModal'].hide()
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.tenancies'), { type: 'error' })
        })
        .then(() => {
        })
    },
    removeDuplicatedIds(items) {
      const uniqueIds = []
      const list = items.filter(element => {
        const isDuplicate = uniqueIds.filter(y => y.id === element.id)
        if (isDuplicate.length === 0) {
          uniqueIds.push(element)
          return true
        }
        return false
      })
      return list
    },
    showActivateTenancyModal() {
      // this.isSameTenant = this.selectedEntries.map(x => x.tenants[0]?.contactId).every(
      //   function(value, _, array) {
      //     return array[0] === value
      //   }
      // )
      if (this.selectedEntriesIds.length > 0) {
        this.tenanciesList = []
        const promises = []
        const listVacancy = []
        ObjectApi.getTenanciesByIds(this.selectedEntriesIds).then((response) => {
          if (response && response.tenancies.length > 0) {
            response.tenancies.forEach(element => {
              if (!element.vacancy) {
                promises.push(ObjectApi.getTenanciesByTenant(element.ownerId, element.tenant.billingContactId))
              } else {
                listVacancy.push(element)
              }
            })
            Promise.all(promises)
              .then((response) => {
                if (response.length > 0) {
                  response.forEach(element => {
                    if (element.tenancies) {
                      this.tenanciesList = this.tenanciesList.concat(element.tenancies).filter(x => x.status === 'TENANCY_STATUS_NOT_ACTIVATED')
                    }
                  })
                  let contactIds = []
                  const objectsIds = []
                  const promises2 = []
                  this.tenanciesList = this.removeDuplicatedIds(this.tenanciesList)
                  this.tenanciesList.forEach(element => {
                    objectsIds.push(element.objectId)
                    contactIds = contactIds.concat(element.tenant?.contactIds)
                  })
                  if (listVacancy.length > 0) {
                    listVacancy.forEach(element => {
                      objectsIds.push(element.objectId)
                      contactIds = contactIds.concat(element.tenant?.contactIds)
                    })
                  }
                  if (objectsIds.length > 0) {
                    promises2.push(ObjectApi.listObjectsByIds(objectsIds))
                    promises2.push(ObjectApi.getTenancies(objectsIds))
                  }
                  if (contactIds.length > 0) {
                    promises2.push(ContactApi.contactResolve(contactIds))
                  }
                  Promise.all(promises2)
                    .then(response => {
                      if (response.length > 0) {
                        response.forEach(element => {
                          if (element.objects) {
                            this.objectsList = this.objects.concat(element.objects)
                          }
                          if (element.tenancies) {
                            const list = element.tenancies
                            this.lastTenancy = []
                            const t = this
                            this.tenanciesList.forEach(element => {
                              let tenant = null
                              if (list.find(x => x.status === 'TENANCY_STATUS_CURRENT' && x.endingAt !== null && x.objectId === element.objectId && x.id !== element.id)) {
                                tenant = list.find(x => x.status === 'TENANCY_STATUS_CURRENT' && x.endingAt !== null && x.objectId === element.objectId && x.id !== element.id) || null
                              } else {
                                tenant = list.filter(x => x.endingAt !== null && x.objectId === element.objectId && x.id !== element.id).sort(function (a, b) {
                                  return new Date(t.$options.filters.objectToDateInput(b.endingAt)).getTime() - new Date(t.$options.filters.objectToDateInput(a.endingAt)).getTime()
                                })[0] || null
                              }
                              if (tenant !== null && tenant.vacancy === false) {
                                this.lastTenancy.push(tenant)
                              }
                            })
                          }
                          if (element.persons || element.companies) {
                            this.contactsList = element.persons.concat(element.companies)
                          }
                        })
                        this.tenanciesList = this.tenanciesList.concat(listVacancy)
                        this.$refs.activateTenancyModal.show()
                      }
                    })
                    .catch(e => {
                      console.log(e)
                      Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
                    })
                } else if (listVacancy.length > 0) {
                  this.tenanciesList = listVacancy
                  this.$refs.activateTenancyModal.show()
                }
              })
              .catch(e => {
                console.log(e)
              })
          }
        })
      }
    },
    tenancyActivated() {
      this.refreshPage()
    },
    refreshPage() {
      setTimeout(() => {
        this.$refs['app-refresh'].refresh()
        this.selectedEntries = []
        this.handleCheckboxesInsideTable(false)
        if (this.$refs.header_checkbox) {
          this.$refs.header_checkbox.checked = false
        }
      }, 2000)
    },
    navigateToDetail(item) {
      ObjectApi.getBuildingByObjectId(item.obj.id).then(response => {
        this.$router.push({
          name: 'OwnerBuildingDetailsView',
          params: { id: response.building.id },
          query: { view: 'tenants', elementSelected: item.objectID }
        })
      }).catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
      })
    },
    onMiddlelicked(item, index, event) {
      const element = event.srcElement
      if (element.tagName !== 'A') {
        ObjectApi.getBuildingByObjectId(item.obj.id).then(response => {
          const route = this.$router.resolve({
            name: 'OwnerBuildingDetailsView',
            params: { id: response.building.id },
            query: {
              view: 'tenants',
              elementSelected: item.objectID
            }
          })
          window.open(route.href)
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
        })
      }
    },
    getAssigneeData(items) {
      items.forEach(element => {
        const emp = this.employeeList.find(x => (x.profile.lastName + ' ' + x.profile.firstName) === element.label)
        if (emp) {
          element.active = !emp.blocked
        }
      })
      return [{
        employee: this.$t('message.search.filterAll'),
        employees: items
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.date-picker-container) {
    .mx-input {
      display: block;
      width: 100%;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.2rem;
      font-weight: 400;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      height: 30px !important;
      box-shadow: 1px 1px 6px #ccc;
    }
    .mx-input:hover, .mx-input:focus {
      border-color: #ccc !important;
    }
    .mx-datepicker {
      width: 100%;
    }
    .mx-icon-calendar {
      display: none;
    }
  }
</style>
