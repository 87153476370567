<template>
  <section class="w-100">
    <div class="row mt-3">
      <div class="col-12 col-md-6">
        <coozzy-form-search-input
          v-model="documentName"
          class="search-interested-block mb-2 basic"
          :placeholder="$t('message.generic.search')"
          name="name-document" />
      </div>
      <div class="col-12 col-md-6">
        <coozzy-form-select
          v-model="documentType"
          class="mb-2 basic">
          <option value="">
            {{ $t('message.manageDocuments.type.no_type') }}
          </option>
          <option
            v-for="(item, index) in translatedAvailableTypes"
            :key="index"
            :value="item">
            {{ $t(`message.manageDocuments.type.${item.toLowerCase()}`) }}
          </option>
        </coozzy-form-select>
      </div>
    </div>
    <div
      v-if="documentsList.length === 0 && isReadOnly"
      class="row mt-3">
      <div class="col-12">
        <h6>
          {{ $t('message.manageDocuments.noDocuments') }}
        </h6>
      </div>
    </div>
    <div
      v-if="documentsList.length > 0"
      class="row mt-2">
      <div class="col-12">
        <document
          v-for="doc in filteredDocuments"
          :key="doc.id"
          :document="doc"
          :is-read-only="isReadOnly"
          @version-added="versionAdded"
          @delete-document="deleteDocument" />
      </div>
    </div>
  </section>
</template>

<script>

import Document from '@/properties/components/Document'
import CoozzyFormSearchInput from '@/framework/components/form/input/CoozzyFormSearchInput.vue'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
export default {
  name: 'DocumentsList',
  components: {
    CoozzyFormSelect,
    Document,
    CoozzyFormSearchInput
  },
  props: {
    documentsList: {
      type: Array,
      default() {
        return []
      }
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      documentName: '',
      documentType: '',
      availableTypes: [
        // Changes need to be implemented also in mobile apps
        'OTHER',
        'APPLICATION_FORM',
        'HANDOVER_PROTOCOL',
        'CONTRACT',
        'BUILDING_PLAN',
        'INVOICE',
        'HOUSE_REGULATION',
        'INSTRUCTION_MANUAL',
        'CREDIT_CHECK',
        'OWNERS_MEETING',
        'PAY_IN_SLIP',
        'ENERGY_CERTIFICATE',
        'WARRANTY_CERTIFICATE',
        'BANK_STATEMENT',
        'ADDITIONAL_COSTS_BILL',
        'SEPA_DIRECT_DEBIT_MANDATE',
        'AGREEMENT',
        'DEPOSIT_CONFIRMATION',
        'BUILDING_INSURANCE_POLICY',
        'KEY_LIST',
        'TERMINATION_DOC_TYPE',
        'RENT_ADJUSTMENT_DOC_TYPE',
        'REFERENCES_DOC_TYPE',
        'MAINTENANCE_CONTRACT_DOC_TYPE',
        'REGULATIONS',
        'REGULATIONS_FOR_USE_AND_MANAGEMENT',
        'ANNUAL_FINANCIAL_STATEMENT',
        'PURCHASE_CONTRACT',
        'LAND_REGISTER',
        'CONDOMINIUM_OWNERSHIP',
        'RENEWAL_FOND',
        'MORTGAGE',
        'ORDER',
        'OFFER',
        'IDENTITY',
        'EXTRACT_FROM_DEBT_COLLECTION_REGISTER',
        'BANK_ACCOUNT_DETAILS',
        'SITUATION_PLAN',
        'CONSTRUCTION_LAW_AGREEMENT',
        'EASEMENT_AGREEMENT',
        'CADASTRAL_ESTIMATE',
        'LETTER',
        'TRADE_REGISTER_EXCERPT',
        'PAYMENT_REMINDER',
        'PROTOCOL',
        'COURT_DOC_TYPE',
        'HONORARIUM',
        'BUDGET',
        'OFFICIAL_ESTIMATE',
        'BUILDING_DESCRIPTION',
        'PERMITS',
        'LEGAL_MATTERS'
      ]
    }
  },
  computed: {
    translatedAvailableTypes() {
      if (this.documentsList.length === 0) {
        return []
      }
      const array = [...new Set(this.documentsList.map(doc => doc.type))]
      array.sort((a, b) => {
        if (this.$t(`message.manageDocuments.type.${a.toLowerCase()}`) < this.$t(`message.manageDocuments.type.${b.toLowerCase()}`)) {
          return -1
        }
        if (this.$t(`message.manageDocuments.type.${a.toLowerCase()}`) > this.$t(`message.manageDocuments.type.${b.toLowerCase()}`)) {
          return 1
        }
        return 0
      })
      return array
    },
    filteredDocuments() {
      return this.documentsList.filter(doc => {
        const cdtName = (doc.name.toLowerCase()).includes(this.documentName.toLowerCase())
        const cdtType = this.documentType === '' || doc.type === this.documentType
        return cdtName && cdtType
      }).sort((a, b) => {
        const type1 = this.$t(`message.manageDocuments.type.${a.type?.toLowerCase()}`)?.toLowerCase()
        const type2 = this.$t(`message.manageDocuments.type.${b.type?.toLowerCase()}`)?.toLowerCase()
        if (type1 < type2) {
          return -1
        }
        if (type1 > type2) {
          return 1
        }
        if (a.name?.toLowerCase() < b.name?.toLowerCase()) {
          return -1
        }
        if (a.name?.toLowerCase() > b.name?.toLowerCase()) {
          return 1
        }
        return 0
      })
    }
  },
  watch: {
  },
  mounted() {},
  methods: {
    versionAdded(updatedDocument) {
      this.$emit('version-added', updatedDocument)
    },
    deleteDocument(document) {
      this.$emit('document-deleted', document)
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.search-interested-block button.small) {
    display: inline-block !important;
    max-height: calc(1.5em + 0.7rem);
    padding-top: 0.15rem;
  }
</style>
