<template>
  <div class="col-12">
    <div class="row">
      <!-- Decision for application form type -->
      <div
        v-if="!accountId && !isBusy"
        class="col-12">
        <coozzy-card
          class="text-center">
          <coozzy-button
            class="mr-3 full-opa"
            :class="applicationFormType === 'individual' ? 'primary' : ''"
            :disabled="applicationFormType === 'individual'"
            @click="setApplicationFormType('individual')">
            {{ $t('message.generic.private') }}
          </coozzy-button>
          <coozzy-button
            class="full-opa"
            :class="applicationFormType === 'company' ? 'primary' : ''"
            :disabled="applicationFormType === 'company'"
            @click="setApplicationFormType('company')">
            {{ $t('message.generic.company') }}
          </coozzy-button>
        </coozzy-card>
      </div>

      <!-- Individual application form -->
      <div
        v-if="applicationFormType === 'individual'"
        class="col-12">
        <coozzy-card>
          <application-form-individual
            ref="individual"
            :is-busy="isBusy"
            :individual-id="individuals[0].internalId"
            :sending="sending"
            :object="object"
            @address-changed="isAddressChanged" />
        </coozzy-card>
        <coozzy-card v-if="!isBusy">
          <application-form-individual-misc
            v-if="object"
            ref="individual-misc"
            :is-main-individual="true"
            :individual-id="individuals[0].internalId"
            :object="object"
            :sending="sending" />
        </coozzy-card>
        <coozzy-card v-if="!isBusy && object && object.acceptsCreditTrust && object.category !== 'PARKING_SPACE' && object.category !== 'SECONDARY_ROOM'">
          <application-form-individual-credit-trust
            v-if="object && object.acceptsCreditTrust"
            ref="individual-credit-trust"
            :individual-id="individuals[0].internalId"
            @order-certificate="orderCertificate" />
        </coozzy-card>
        <coozzy-card v-if="!isBusy">
          <application-form-individual-documents
            :individual-id="individuals[0].internalId"
            :object="object"
            :is-busy="isBusy" />
        </coozzy-card>
        <template v-if="individuals.length > 1 && !isBusy">
          <coozzy-card
            v-for="(individual, individualIndex) of individuals.slice(1)"
            :id="`individual-${individualIndex}`"
            :ref="`individual-${individualIndex}`"
            :key="individual.internalId">
            <div class="col">
              <h4 class="display_ib">
                {{ $t('message.applicationForm.additionalTenant') }}
              </h4>
              <coozzy-button
                design="transparent"
                class="float-right shadow-none"
                @click="deleteIndividual(individual)">
                <coozzy-delete-icon />
              </coozzy-button>
            </div>
            <hr>
            <application-form-individual
              v-if="!isBusy"
              ref="individual-tenants"
              :individual-id="individual.internalId"
              :sending="sending"
              :object="object"
              :changed-address="addressIsChanged" />
            <hr>
            <application-form-individual-credit-trust
              v-if="object && object.acceptsCreditTrust"
              ref="sub-individual-credit-trust"
              :individual-id="individual.internalId"
              @order-certificate="orderCertificate" />
            <hr>
            <application-form-individual-misc
              v-if="object"
              ref="sub-individual-misc"
              :key="individualIndex"
              :is-main-individual="false"
              :object="object"
              :individual-id="individual.internalId"
              :sending="sending" />
            <hr>
            <application-form-individual-documents
              :individual-id="individual.internalId"
              :is-busy="isBusy" />
          </coozzy-card>
        </template>
        <coozzy-card v-if="!isBusy">
          <coozzy-button
            class="my-3"
            design="primary"
            @click="addIndividual">
            {{ $t('message.applicationForm.addTenant') }}
          </coozzy-button>
        </coozzy-card>
      </div>

      <!-- Company application form -->
      <div
        v-else-if="applicationFormType === 'company'"
        class="col-12">
        <coozzy-card>
          <application-form-company
            ref="company"
            :company="company"
            :is-busy="isBusy"
            :sending="sending"
            :object="object" />
        </coozzy-card>
        <coozzy-card v-if="!isBusy">
          <application-form-company-misc
            v-if="object"
            ref="company-misc"
            :company="company"
            :is-busy="isBusy"
            :object="object"
            :sending="sending" />
        </coozzy-card>
        <coozzy-card v-if="!isBusy">
          <application-form-company-documents :company="company" />
        </coozzy-card>
      </div>
      <div class="col-12">
        <template v-if="!isBusy">
          <application-form-preferred-deposit
            v-if="applicationFormType && sending && object?.deposit?.depositNeeded && (object?.deposit?.bankGuaranteeAccepted || object?.deposit?.bankDepotAccepted || object?.deposit?.cashPaymentAccepted || object?.deposit?.collectiveInsuranceAccepted || object?.deposit?.cooperativeShareAccepted || object?.deposit?.insuranceAccepted)"
            ref="preferred-deposit"
            :object="object"
            :is-busy="isBusy"
            :company="company"
            :marketing-account="marketingAccount"
            :individual="individuals[0]"
            :sending="sending" />
        </template>
      </div>

      <div class="col-12">
        <template v-if="!isBusy">
          <application-form-legal-notice
            v-if="applicationFormType && sending"
            ref="legal"
            :object-id="objectId" />
        </template>
        <span style="line-height: 28px;">{{ $t('message.applicationForm.anyQuestion', {
          phoneNumberMarketerAccount: object?.marketingAssignee?.phone ? object.marketingAssignee.phone : marketingAccount && marketingAccount.company ? marketingAccount.company.phoneNumber : '',
          emailMarketerAccount: object?.marketingAssignee?.email ? object.marketingAssignee.email : marketingAccount && marketingAccount.company ? marketingAccount.company.email : '' }) }}</span>
        <coozzy-button
          design="primary"
          class="float-right px-5 mb-3"
          @click="save">
          <template v-if="sending">
            {{ $t('message.generic.form.send') }}
          </template>
          <template v-else>
            {{ $t('message.generic.form.save') }}
          </template>
        </coozzy-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import ApplicationFormIndividual from '@/marketingBasic/components/applicationForm/ApplicationFormIndividual.vue'
import ApplicationFormCompany from '@/marketingBasic/components/applicationForm/ApplicationFormCompany.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import ApplicationFormIndividualMisc
  from '@/marketingBasic/components/applicationForm/ApplicationFormIndividualMisc.vue'
import ApplicationFormCompanyMisc from '@/marketingBasic/components/applicationForm/ApplicationFormCompanyMisc.vue'
import RequestApi from '@/marketingBasic/api/RequestApi'
import ObjectApi from '@/misc/apis/ObjectApi'
import UserApi from '@/misc/apis/UserApi'
import ApplicationFormCompanyDocuments
  from '@/marketingBasic/components/applicationForm/ApplicationFormCompanyDocuments.vue'
import ApplicationFormIndividualDocuments
  from '@/marketingBasic/components/applicationForm/ApplicationFormIndividualDocuments.vue'
import { user } from '@/mixins/user'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon.vue'
import ApplicationFormLegalNotice from '@/marketingBasic/components/applicationForm/ApplicationFormLegalNotice.vue'
import ApplicationFormPreferredDeposit
  from '@/marketingBasic/components/applicationForm/ApplicationFormPreferredDeposit.vue'
import * as Sentry from '@sentry/vue'
import ApplicationFormIndividualCreditTrust
  from '@/marketingBasic/components/applicationForm/ApplicationFormIndividualCreditTrust.vue'
import axios from 'axios'

export default {
  name: 'ApplicationForm',
  components: {
    ApplicationFormIndividualCreditTrust,
    ApplicationFormLegalNotice,
    ApplicationFormPreferredDeposit,
    ApplicationFormIndividualMisc,
    ApplicationFormCompanyMisc,
    ApplicationFormIndividual,
    ApplicationFormCompany,
    CoozzyButton,
    CoozzyCard,
    CoozzyDeleteIcon,
    ApplicationFormCompanyDocuments,
    ApplicationFormIndividualDocuments
  },
  mixins: [user],
  props: {
    sending: {
      type: Boolean,
      default: false
    },
    objectId: {
      type: String,
      default: ''
    },
    requestId: {
      type: String,
      default: () => undefined
    }
  },
  data() {
    return {
      addressIsChanged: '',
      isBusy: false,
      marketingAccount: null,
      object: null
    }
  },
  computed: {
    ...mapState('applicationForm', ['applicationFormType', 'individuals', 'company', 'viewingDone', 'desiredStartOfContract']),
    ...mapGetters('applicationForm', ['getPreferredLanguage'])
  },
  mounted() {
    if (this.objectId) {
      ObjectApi.getMarketingObject(this.objectId)
        .then(response => {
          this.object = response
          if (this.object.marketingAccountId !== '-1') {
            UserApi.getAccount(this.object.marketingAccountId).then(response => {
              this.marketingAccount = response.account
            }).catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.user'), { type: 'error' })
            })
          }
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
        })
    }
    if (this.userEmail) {
      this.isBusy = true
      RequestApi.getApplicationFormByEmail(this.userEmail)
        .then(response => {
          this.loadApplicationForm(response.application)
        })
        .catch(() => {
        })
        .finally(() => {
          this.isBusy = false
        })
    }
  },
  methods: {
    ...mapActions('applicationForm', ['setApplicationFormType', 'loadApplicationForm', 'addDefaultIndividual']),
    ...mapMutations('applicationForm', ['removeIndividual']),
    addIndividual() {
      this.addDefaultIndividual()
      this.$nextTick(() => {
        const indexIndividual = this.individuals.length - 2
        const domRect = this.$el.querySelector('#individual-' + indexIndividual)
        domRect.scrollIntoView(true)
      })
    },
    deleteIndividual(individual) {
      this.removeIndividual(individual)
    },
    isAddressChanged(val) {
      this.addressIsChanged = val
    },
    orderCertificate(individualId) {
      if (this.$refs.individual) {
        if (this.$refs.individual.individualId === individualId) {
          if (!this.$refs.individual.isInvalid()) {
            if (this.$refs['individual-credit-trust']) {
              this.$refs['individual-credit-trust'].orderCertificate()
            }
          }
        }
        if (this.$refs['individual-tenants']) {
          this.$refs['individual-tenants'].forEach(element => {
            if (element.individualId === individualId) {
              if (!element.isInvalid()) {
                this.$refs['sub-individual-credit-trust'].forEach(subCreditTrustComponent => {
                  if (subCreditTrustComponent.individualId === individualId) {
                    subCreditTrustComponent.orderCertificate()
                  }
                })
              }
            }
          })
        }
      }
    },
    save() {
      let valid = true
      if (this.sending) {
        if (this.$refs.individual) {
          if (this.$refs.individual && this.$refs.individual.isInvalid()) {
            valid = false
          }
          if (this.$refs['individual-misc'] && this.$refs['individual-misc'].isInvalid()) {
            valid = false
          }
          if (this.$refs['individual-tenants']) {
            this.$refs['individual-tenants'].forEach(element => {
              if (element.isInvalid()) {
                valid = false
              }
            })
          }
          if (this.$refs['sub-individual-misc']) {
            this.$refs['sub-individual-misc'].forEach(element => {
              if (element.isInvalid()) {
                valid = false
              }
            })
          }
        } else {
          if (this.$refs.company && this.$refs.company.isInvalid()) {
            valid = false
          }
          if (this.$refs['company-misc'] && this.$refs['company-misc'].isInvalid()) {
            valid = false
          }
        }

        if (this.$refs.legal) {
          if (this.$refs.legal.isInvalid()) {
            valid = false
          }
        }
      } else {
        if (this.$refs.individual) {
          if (!this.individuals[0].email) {
            this.individuals[0].email = this.userEmail
          }
        } else {
          if (this.$refs.company && this.$refs.company.$refs.employment.isInvalidEmail()) {
            valid = false
          }
        }
      }
      if (this.individuals.length > 0) {
        Sentry.setUser({ email: this.individuals[0].email })
        Sentry.setExtra('individual', this.individuals[0])
        Sentry.setExtra('individualAddress', this.individuals[0]?.address)
        Sentry.setExtra('individualHousegold', this.individuals[0]?.household)
        Sentry.setExtra('individualEmployment', this.individuals[0]?.employment)
        Sentry.setExtra('individualTenancy', this.individuals[0]?.tenancy)
        Sentry.setExtra('individualInsurance', this.individuals[0]?.insurance)
        Sentry.setExtra('individualLegal', this.individuals[0]?.legal)
      } else if (this.company && this.company.contactPerson) {
        Sentry.setUser({ email: this.company.contactPerson.email })
        Sentry.setExtra('company', this.company)
        Sentry.setExtra('companyAddress', this.company?.address)
        Sentry.setExtra('companyContactPerson', this.company?.contactPerson)
        Sentry.setExtra('companyInsurance', this.company?.insurance)
        Sentry.setExtra('companyLegal', this.company?.legal)
        Sentry.setExtra('companyTenancy', this.company?.tenancy)
      }

      if (!valid) {
        Sentry.captureMessage('application form data invalid')
        Vue.toasted.show(this.$t('message.savingErrors.requiredApplicationForm'), { type: 'error' })
        return
      }

      this.isBusy = true
      RequestApi.saveApplication(this.individuals, this.company)
        .then(response => {
          if (this.sending) {
            const applicationId = response.application.id
            const marketingLanguage = this.object?.marketingLanguage || 'PREFERRED_LANGUAGE_UNDEFINED'
            RequestApi.sendApplicationForm(applicationId, this.objectId, this.requestId, this.viewingDone, this.desiredStartOfContract, marketingLanguage)
              .then(() => {
                this.isBusy = false
                this.$emit('application-sent', true)
              })
              .catch(e => {
                if (axios.isAxiosError(e)) {
                  const errorData = e.response ? JSON.stringify(e.response.data) : 'No response data'
                  Sentry.captureEvent({
                    message: 'Failed to send application form',
                    extra: {
                      errorData: errorData
                    },
                    level: 'error'
                  })
                } else {
                  Sentry.captureException(e)
                  console.log(e)
                }
                this.isBusy = false
                Vue.toasted.show(this.$t('message.savingErrors.sendingApplicationForm'), { type: 'error' })
              })
          } else {
            this.isBusy = false
          }
        })
        .catch(e => {
          if (axios.isAxiosError(e)) {
            const errorData = e.response ? JSON.stringify(e.response.data) : 'No response data'
            Sentry.captureEvent({
              message: 'Failed to create application form',
              extra: {
                errorData: errorData
              },
              level: 'error'
            })
          } else {
            Sentry.captureException(e)
            console.log(e)
          }
          this.isBusy = false
          Vue.toasted.show(this.$t('message.savingErrors.savingApplicationForm'), { type: 'error' })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.display_ib {
  display: inline-block;
}
.full-opa{
  opacity: 1 !important;
}

</style>
