<template>
  <div>
    <b class="row">
      <div
        v-if="!searchClient"
        class="col-12 text-center">
        <coozzy-spinner />
      </div>
      <template v-else>
        <b-tabs
          v-model="tabIndex"
          no-key-nav
          class="col-12 col-md-12 mb-3">
          <b-tab
            :title="$t('message.advertisementManagementOverview.menu.contacts')"
            class="p-4">
            <order-contacts-tab
              ref="orderContactsTab"
              :buildings="buildings"
              :objects="objects"
              :craftsman-contacts="craftsmanContacts"
              :janitor-contacts="janitorContacts"
              :source="source"
              :service-provider-contacts="serviceProviderContacts"
              @recipient-changed="recipientChanged"
              @load-new-added-contacts="loadNewAddedContacts" />
          </b-tab>
          <b-tab
            :title="$t('message.navigation.directory')"
            class="p-4">
            <div class="row">
              <div class="col-12 col-md-3">
                <label>
                  {{ $t('message.createOrder.selectReceiver') }}
                </label>
                <ais-instant-search
                  :search-client="searchClient"
                  :routing="routing"
                  index-name="contact">
                  <!-- eslint-disable vue/attribute-hyphenation -->
                  <ais-configure
                    :hitsPerPage="10"
                    :filters="'active:true AND isEmployee:false AND isOnlyInterestedParty:false'" />
                  <!-- eslint-disable vue/attribute-hyphenation -->
                  <ais-autocomplete>
                    <template slot-scope="{ indices, refine }">
                      <vue-autosuggest
                        ref="recipientAutosuggest"
                        v-model="query"
                        :suggestions="indicesToSuggestions(indices)"
                        :input-props="inputProps"
                        @input="onChange(refine)"
                        @selected="onRecipientSelect">
                        <template slot-scope="{ suggestion }">
                          <strong>{{ suggestion.item.firstName }} {{ suggestion.item.lastName }}</strong>
                          <br>
                          <address-text
                            :show-short-country="true"
                            :hide-if-empty="false"
                            :address="suggestion.item.address" />
                          <template v-if="!isOwnerModule">
                            <br>
                            {{ suggestion.item.owner?.name || '' }}
                          </template>
                        </template>
                      </vue-autosuggest>
                    </template>
                  </ais-autocomplete>
                </ais-instant-search>
              </div>
              <div class="col-12 col-md-3">
                <label class="d-block invisible">
                  {{ $t('message.createOrder.unselectReceiver') }}
                </label>
                <coozzy-button
                  v-if="recipientContact"
                  @click="clearRecipientContact">
                  {{ $t('message.createOrder.unselectReceiver') }}
                </coozzy-button>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </template>
    </b>
    <div
      v-if="recipientContact"
      class="row mt-3">
      <div class="col-sm-12 col-md-5">
        <coozzy-detail-card
          :title="!recipientContact.numericId ? $t('message.contact.title') : $t('message.contact.title') + ' ' + recipientContact.numericId + (recipientContact && recipientContact.owner && recipientContact.owner.name ? ' - ' + recipientContact.owner.name : '')"
          :is-editable="true"
          @onEdit="editContactClicked()">
          <contact-detail-card
            :ref="'contact-detail-card'"
            :contact="recipientContactfromApi || recipientContact"
            :type="'self'" />
        </coozzy-detail-card>
      </div>
      <div
        v-if="recipientContact.contactPersons"
        class="col-sm-12 col-md-7">
        <coozzy-alert variant="info">
          {{ $t('message.createOrder.selectContactPersonDescription') }}
        </coozzy-alert>
        <coozzy-detail-card
          :title="$t('message.tableColumnHeadings.contactPersons') + ' (' + recipientContact.contactPersons.length +')'">
          <div class="col p-0">
            <b-table
              v-if="recipientContact.contactPersons.length > 0"
              ref="contactPersonTable"
              bordered
              hover
              responsive="true"
              stacked="md"
              class="m-0"
              select-mode="single"
              :fields="fieldsContactPersons"
              :items="recipientContact.contactPersons">
              <!-- Headings -->
              <template #head(name)>
                {{ $t('message.generic.name') }}
              </template>
              <template #head(phoneNumber)>
                {{ $t('message.generic.phoneNumber') }}
              </template>
              <template #head(email)>
                {{ $t('message.generic.email') }}
              </template>
              <template #head(action)>
                {{ '' }}
              </template>
              <template #head(edit)>
                {{ '' }}
              </template>

              <!-- Columns -->
              <template
                #cell(name)="data">
                <div
                  class="contact-person-name"
                  :class="[data.item.dead === true || !data.item.active ? 'text-gray' : '']">
                  <span>
                    <template v-if="data.item.gender === 'male'">
                      {{ $t('message.generic.title.mr') }}
                    </template>
                    <template v-if="data.item.gender === 'female'">
                      {{ $t('message.generic.title.mrs') }}
                    </template>
                    <template v-if="data.item.gender === 'other'">
                      {{ $t('message.generic.title.divers') }}
                    </template>
                  </span>
                  <span
                    v-if="data.item.title && data.item.title !== ''">{{ data.item.title }} </span>
                  <div class="font-weight-bold">
                    {{ data.item.name | displayOptionalValue }}
                  </div>
                  <div v-if="data.item.companyPosition !== ''">
                    {{ data.item.companyPosition }}
                  </div>
                </div>
              </template>
              <template
                #cell(phoneNumber)="data">
                <div
                  v-if="getPhoneNumbers(data.item.phoneNumbers, 'work') !== '-'"
                  class="row"
                  :class="[data.item.dead === true || !data.item.active ? 'text-gray' : '']">
                  <div class="col-1">
                    <coozzy-phone-icon />
                  </div>
                  <div class="col">
                    <a :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'work')">
                      {{ getPhoneNumbers(data.item.phoneNumbers, 'work') }}
                    </a>
                  </div>
                </div>
                <div
                  v-if="getPhoneNumbers(data.item.phoneNumbers, 'mobile') !== '-'"
                  class="row"
                  :class="[data.item.dead === true || !data.item.active ? 'text-gray' : '']">
                  <div class="col-1">
                    <coozzy-mobile-alt-icon />
                  </div>
                  <div class="col">
                    <a :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'mobile')">
                      {{ getPhoneNumbers(data.item.phoneNumbers, 'mobile') }}
                    </a>
                  </div>
                </div>
              </template>
              <template
                #cell(email)="data">
                <div
                  class="row"
                  :class="[data.item.dead === true || !data.item.active ? 'text-gray' : '']">
                  <div class="col-1">
                    <img
                      class="email-icon-img"
                      src="@/assets/icon/arroba.svg"
                      alt="Placeholder">
                  </div>
                  <div class="col">
                    <template v-if="data.item.email !== ''">
                      <a
                        class="link"
                        @click="sendEmailModal(data.item)">{{ data.item.email }}</a>
                    </template>
                    <template v-else>
                      {{ data.item.email | displayOptionalValue }}
                    </template>
                  </div>
                </div>
              </template>
              <template
                #cell(action)="data">
                <coozzy-button
                  v-if="data.item.id !== recipientContactPerson?.id"
                  class="col"
                  design="transparent"
                  :disabled="!data.item.active"
                  @click="onRecipientContactPersonSelect(data.item)">
                  {{ $t('message.generic.select') }}
                </coozzy-button>
                <coozzy-button
                  v-else
                  class="col mb-0"
                  design="alert"
                  :disabled="!data.item.active"
                  @click="onRecipientContactPersonSelect(null)">
                  {{ $t('message.generic.unselect') }}
                </coozzy-button>
              </template>

              <template
                #cell(edit)="data">
                <coozzy-button
                  class="col-1 p-0"
                  design="transparent"
                  @click="showPerson(data.item)">
                  <coozzy-edit-icon />
                </coozzy-button>
              </template>
            </b-table>
          </div>
        </coozzy-detail-card>
        <coozzy-button
          class="col-3 mb-0"
          design="prop-green"
          @click="openContactModal()">
          {{ $t('message.generic.addContactPerson') }}
        </coozzy-button>
      </div>
    </div>
    <contact-person-creation
      ref="contact-person-modal"
      :key="selectedPerson !== null ? 'modal-person'+selectedPerson.id : 'modal-person'"
      :company-contact="recipientContact"
      :contact-type="'company'"
      :selected-contact="selectedPerson"
      @new-contact-person="contactPersonCreated"
      @edit-contact-person="contactPersonEdited"
      @delete-contact-person="contactPersonDeleted" />
    <create-contact-modal
      :key="recipientContact ? recipientContact.id : null"
      ref="create-contact-modal-receip"
      :for-owner-id="isOwnerModule ? accountId : null"
      :show-search="false"
      :show-request-sources="false"
      :set-interested-party-role="false"
      :show-add-another="true"
      :contact-to-edit="recipientContact"
      @contact-updated="contactUpdated" />
  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import AddressText from '@/framework/components/misc/AddressText'
import CoozzyDetailCard from '@/framework/components/card/CoozzyDetailCard'
import ContactDetailCard from '@/contact/components/ContactDetailCard'
import CoozzyPhoneIcon from '@/framework/components/icons/CoozzyPhoneIcon'
import CoozzyMobileAltIcon from '@/framework/components/icons/CoozzyMobileAltIcon'
import ContactApi from '@/misc/apis/ContactApi'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import { algolia } from '@/mixins/algolia'
import { routeChecks } from '@/mixins/routeChecks'
import { contact } from '@/mixins/contact'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { user } from '@/mixins/user'
import CoozzyAlert from '@/framework/components/alert/CoozzyAlert'
import OrderContactsTab from '@/order/components/OrderContactsTab.vue'
import ContactPersonCreation from '@/contact/components/ContactPersonCreation'
import CreateContactModal from '@/contact/components/CreateContactModal'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon.vue'

export default {
  name: 'OrderRecipientTab',
  components: {
    CoozzyEditIcon,
    CoozzyAlert,
    CoozzyButton,
    CoozzyMobileAltIcon,
    CoozzyPhoneIcon,
    ContactDetailCard,
    CoozzyDetailCard,
    AddressText,
    CoozzySpinner,
    VueAutosuggest,
    OrderContactsTab,
    ContactPersonCreation,
    CreateContactModal
  },
  mixins: [user, algolia, contact, routeChecks],
  props: {
    buildings: {
      type: Array,
      default: () => []
    },
    objects: {
      type: Array,
      default: () => []
    },
    craftsmanContacts: {
      type: Array,
      default: () => []
    },
    janitorContacts: {
      type: Array,
      default: () => []
    },
    serviceProviderContacts: {
      type: Array,
      default: () => []
    },
    source: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      debounceTimeout: null,
      query: '',
      keyContactCard: '',
      recipientContact: null,
      recipientContactfromApi: null,
      recipientContactPerson: null,
      fieldsContactPersons: [
        {
          key: 'name',
          label: this.$t('message.generic.name'),
          tdClass: 'align-middle p-md-0'
        },
        {
          key: 'phoneNumber',
          label: this.$t('message.generic.phoneNumber'),
          tdClass: 'align-middle phone-cell'
        },
        {
          key: 'email',
          label: this.$t('message.generic.email'),
          tdClass: 'align-middle'
        },
        {
          key: 'action',
          label: '',
          tdClass: 'align-middle'
        },
        {
          key: 'edit',
          label: '',
          tdClass: 'align-middle'
        }
      ],
      selectedPerson: null,
      tabIndex: 0
    }
  },
  computed: {
    ...mapGetters('message', ['getSentMails', 'getMailboxesForUserId']),
    contacts() {
      return this.craftsmanContacts.concat(this.janitorContacts).concat(this.serviceProviderContacts)
    }
  },
  watch: {
    recipientContact: function () {
      this.emitRecipientChange()
    },
    recipientContactPerson: function () {
      this.emitRecipientChange()
    },
    contacts: function (value) {
      if (value.length === 0) {
        this.tabIndex = 1
      } else {
        this.tabIndex = 0
      }
    }
  },
  mounted: function () {
    this.loadInstantSearch(false)
    this.loadMailboxesForUserId(this.userId)
  },
  methods: {
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailOnlyCurrentContact',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply'
    ]),
    ...mapActions('message', ['loadMailboxesForUserId']),
    contactUpdated(data) {
      const owner = this.recipientContact.owner
      if (data.contact && (this.recipientContact.id === data.contact.id || this.recipientContact.objectID === data.contact.id)) {
        this.recipientContact = data.contact
        this.recipientContactfromApi = data.contact
        this.recipientContact.owner = owner
        this.preparePhoneNumberObject()
      }
    },
    editContactClicked() {
      this.$nextTick(function () {
        this.$refs['create-contact-modal-receip'].show()
      })
    },
    preparePhoneNumberObject() {
      this.recipientContactfromApi.phone = {}
      if (this.recipientContactfromApi.phoneNumbers) {
        this.recipientContactfromApi.phone.home1 = this.recipientContactfromApi.phoneNumbers.find(element => element.type === 'HOME') ? this.recipientContactfromApi.phoneNumbers.find(element => element.type === 'HOME').number : ''
        this.recipientContactfromApi.phone.work = this.recipientContactfromApi.phoneNumbers.find(element => element.type === 'WORK') ? this.recipientContactfromApi.phoneNumbers.find(element => element.type === 'WORK').number : ''
        this.recipientContactfromApi.phone.mobile = this.recipientContactfromApi.phoneNumbers.find(element => element.type === 'MOBILE') ? this.recipientContactfromApi.phoneNumbers.find(element => element.type === 'MOBILE').number : ''
        this.recipientContactfromApi.phone.fax = this.recipientContactfromApi.phoneNumbers.find(element => element.type === 'FAX') ? this.recipientContactfromApi.phoneNumbers.find(element => element.type === 'FAX').number : ''
      }
      this.$nextTick(() => {
        this.$emit('contact-updated', this.recipientContactfromApi)
        this.$refs.orderContactsTab.refreshTable()
      })
    },
    contactPersonCreated(item) {
      this.recipientContact.contactPersons.push(item)
    },

    contactPersonEdited(item) {
      this.selectedPerson = null
      for (let index = 0; index < this.recipientContact.contactPersons.length; index++) {
        if (this.recipientContact.contactPersons[index].id === item.id) {
          this.recipientContact.contactPersons[index] = item
          break
        }
      }
      this.$refs.contactPersonTable.refresh()
    },
    contactPersonDeleted(id) {
      for (let index = 0; index < this.recipientContact.contactPersons.length; index++) {
        if (this.recipientContact.contactPersons[index].id === id) {
          this.recipientContact.contactPersons.splice(index, 1)
        }
      }
    },
    showPerson(item) {
      this.selectedPerson = item
      this.$nextTick(function () {
        this.$refs['contact-person-modal'].show()
      })
    },
    openContactModal() {
      this.selectedPerson = null
      this.$nextTick(function () {
        this.$refs['contact-person-modal']?.show()
      })
    },
    loadNewAddedContacts(contactIds) {
      this.$emit('load-new-added-contacts', contactIds)
    },
    sendEmailModal(contact) {
      this.emailToContact = {
        id: contact.id,
        name: contact.name,
        email: contact.email
      }
      this.setSendMailContacts([this.emailToContact])
      this.setSendMailPreselectedContact(this.emailToContact)
      this.setSendMailEmailToReply(null)
      this.setSendMailOnlyCurrentContact(true)
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    indicesToSuggestions(indices) {
      return indices.map(({ hits }) => ({
        data: hits
      }))
    },
    onChange(refine) {
      if (this.query !== '') {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.debounceTimeout = null
        refine(this.query)
      }, 300)
    },
    onRecipientSelect(selected) {
      if (selected) {
        this.$refs.orderContactsTab?.selectContact(null)
        this.query = ''
        this.$refs.recipientAutosuggest.internalValue = ''
        this.$nextTick(() => {
          this.recipientContactfromApi = null
          this.recipientContact = selected.item
          this.recipientContactPerson = null
          ContactApi.contactResolve([this.recipientContact.objectID || this.recipientContact.id])
            .then(responseCon => {
              this.recipientContactfromApi = responseCon.persons.concat(responseCon.companies).find(x => x.id === selected.item.objectID)
            })
        })
      }
    },
    recipientChanged(contact) {
      if (contact !== null) {
        this.query = ''
        this.$refs.recipientAutosuggest.internalValue = ''
      }
      this.recipientContact = contact
      this.recipientContactPerson = null
      this.recipientContactfromApi = contact
    },
    onRecipientContactPersonSelect(contactPerson) {
      this.recipientContactPerson = contactPerson
    },
    clearRecipientContact() {
      this.$refs.orderContactsTab?.selectContact(null)
      this.recipientContact = null
      this.recipientContactPerson = null
      this.query = ''
      this.$refs.recipientAutosuggest.internalValue = ''
    },
    emitRecipientChange() {
      if (this.recipientContactPerson) {
        this.$emit('recipient-changed', this.recipientContactPerson.id, this.recipientContactPerson)
      } else {
        this.$emit('recipient-changed', this.recipientContact?.objectID ? this.recipientContact?.objectID : this.recipientContact?.id ? this.recipientContact?.id : '', this.recipientContact)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  left: 47%;
}

:deep(table.table) {
  border-radius: 0;
  box-shadow: none;

  tbody > .b-table-row-selected td {
    color: inherit !important;
  }

  tbody > tr:hover td {
    background-color: rgba(0, 0, 0, 0.03) !important;
  }

  @media (min-width: 768px) {
    tbody > tr td {
      background-color: $color-bg-white !important;
    }
  }

  .contact-person-name {
    padding: 0.4rem 0.75rem;
  }
}

</style>
