<template>
  <section :class="calculateClassHideMailsFromOtherFolders">
    <div>
      <coozzy-page-title
        :title="$t('message.marketingMessages.draft')"
        :count="messageCount"
        :count-text="$t('message.marketingMessages.messages')" />
      <!-- Filter -->
      <div class="row mt-2 align-items-center">
        <div class="col-md-2">
          <coozzy-dropdown
            design="green"
            size="sm"
            class="w-100"
            :text="selectedEntriesText">
            <coozzy-dropdown-item
              v-if="filterFolder !== 'FILTER_FOLDER_TRASH'"
              :disabled="selectedEntries.length === 0"
              @click="selectedEntries.length !== 0 ? deleteDraft() : ''">
              {{ $t('message.generic.delete') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2 col-sm-6 col-12">
          <template v-if="selectAllVisibleEntries && messageCount > nbrPerPage && messageCount <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :initial="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              name="selectAllEntries"
              button>
              {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: messageCount }) : $t('message.generic.selectHiddenEntries', { amount: messageCount }) }}
            </coozzy-form-checkbox>
          </template>
        </div>
        <div class="col-md-2 col-sm-6 col-12 offset-md-6 mt-2 mt-sm-0">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries float-right">
            <option :value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option :value="50">
              50 {{ $t('message.generic.entries') }}
            </option>
            <option :value="100">
              100 {{ $t('message.generic.entries') }}
            </option>
            <option :value="500">
              500 {{ $t('message.generic.entries') }}
            </option>
            <option :value="1000">
              1000 {{ $t('message.generic.entries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <div
        :style="shortContent ? 'height:0px;' : '' "
        class="row mt-2">
        <div class="col">
          <div @mousemove="onMouseMove($event)">
            <b-table
              ref="messagesTable"
              class="mt-2"
              bordered
              hover
              responsive="true"
              stacked="md"
              selectable
              select-mode="single"
              :busy="isBusy"
              :fields="fields"
              :items="messagesPage"
              @row-clicked="onRowClicked"
              @row-middle-clicked="onRowMiddleClicked"
              @row-hovered="onRowHovered"
              @row-unhovered="onRowUnHovered">
              <!-- Busy state -->
              <div
                slot="table-busy"
                class="text-center text-danger my-2">
                <coozzy-spinner />
              </div>

              <!-- Headings -->
              <template #head(icons) />
              <template #head(checkbox)>
                <coozzy-form-checkbox
                  ref="header_checkbox"
                  :disabled="disabledCheckbox"
                  @change="(value) => { handleCheckboxesInsideTable(value) }" />
              </template>
              <template #head(date)>
                {{ $t('message.tableColumnHeadings.date') }}
              </template>
              <template #head(from)>
                {{ $t('message.tableColumnHeadings.from') }}
              </template>
              <template #head(to)>
                {{ $t('message.tableColumnHeadings.to') }}
              </template>
              <template #head(subject)>
                {{ $t('message.tableColumnHeadings.subject') }}
              </template>
              <template #head(message)>
                {{ $t('message.tableColumnHeadings.message') }}
              </template>

              <!-- Data -->
              <template #cell(checkbox)="data">
                <!-- data needs to be used in order to work properly -->
                <coozzy-form-checkbox
                  :id="'check_' + data.item.id"
                  :disabled="disabledCheckbox"
                  @change="(value) => { itemSelected(data.item.id, value) }" />
              </template>
              <template #cell(date)="data">
                <content-loader
                  v-if="getChangingStatusMails.includes(data.item.id)"
                  style="width: 100px;"
                  width="50"
                  height="24">
                  <rect
                    x="0"
                    y="10"
                    rx="3"
                    ry="3"
                    width="50"
                    height="5" />
                </content-loader>
                <span v-else>
                  {{ (data.item.timestamp / 1000) | timestampToDateTime }}</span>
              </template>
              <template #cell(from)="data">
                <content-loader
                  v-if="getChangingStatusMails.includes(data.item.id)"
                  style="width: 100px;"
                  width="50"
                  height="24">
                  <rect
                    x="0"
                    y="10"
                    rx="3"
                    ry="3"
                    width="50"
                    height="5" />
                </content-loader>
                <template v-else-if="data.item.mailboxId !== '' && mailboxesForCurrentUser.find(obj => obj.id === data.item.mailboxId)">
                  <span>{{ mailboxesForCurrentUser.find(obj => obj.id === data.item.mailboxId).outboundEmail }}</span>
                </template>
              </template>

              <template #cell(to)="data">
                <content-loader
                  v-if="getChangingStatusMails.includes(data.item.id)"
                  style="width: 100px;"
                  width="50"
                  height="24">
                  <rect
                    x="0"
                    y="10"
                    rx="3"
                    ry="3"
                    width="50"
                    height="5" />
                </content-loader>
                <template v-else-if="data.item.to.length > 0">
                  <span>
                    {{ data.item.to[0].name }}<br>
                    {{ data.item.to[0].email }}
                  </span>
                  <span v-if="getRecipientCount(data.item) > 1">+{{ getRecipientCount(data.item) - 1 }}</span>
                </template>
                <template v-else>
                  <template v-if="data.item.to.length === 0 && data.item.cc.length > 0">
                    <template v-if="data.item.cc.length > 0 ">
                      <span>
                        {{ data.item.cc[0].name }}<br>
                        {{ data.item.cc[0].email }}
                      </span>
                      <span v-if="getRecipientCount(data.item) > 1">+{{ getRecipientCount(data.item) - 1 }}</span>
                    </template>
                  </template>
                  <template v-else>
                    <span>{{ data.item.to.length > 0 ? data.item.to[0].name : '-' }}</span><br>
                    <span>{{ data.item.to.length > 0 ? data.item.to[0].email : '-' }} </span>
                    <span v-if="getRecipientCount(data.item) > 1">+{{ getRecipientCount(data.item) - 1 }}</span>
                  </template>
                </template>
              </template>

              <template #cell(subject)="data">
                <content-loader
                  v-if="getChangingStatusMails.includes(data.item.id)"
                  style="width: 100px;"
                  width="50"
                  height="24">
                  <rect
                    x="0"
                    y="10"
                    rx="3"
                    ry="3"
                    width="50"
                    height="5" />
                </content-loader>
                <p
                  v-else
                  class="fieldToShow">
                  <coozzy-caret-down-icon
                    class="d-inline" />
                  {{ data.item.subject | displayOptionalValue }}
                  <coozzy-paper-clip-icon v-if="data.item.attachmentMediaIds.length > 0" />
                </p>
              </template>

              <template #cell(message)="data">
                <content-loader
                  v-if="getChangingStatusMails.includes(data.item.id)"
                  style="width: 100px;"
                  width="50"
                  height="24">
                  <rect
                    x="0"
                    y="10"
                    rx="3"
                    ry="3"
                    width="50"
                    height="5" />
                </content-loader>
                <p
                  v-else
                  class="text-msg mb-0 fieldToShow">
                  {{ data.item ? data.item.bodyText || '' : '' }}
                </p>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              align="center"
              class="mb-3"
              :total-rows="messageCount"
              :per-page="nbrPerPage"
              @change="paginateTo" />
          </div>
        </div>
      </div>
    </div>
    <!--    <message-details-modal-->
    <!--      v-if="selectedMail"-->
    <!--      ref="mail-detail-modal"-->
    <!--      :key="selectedMail.id + '_' + dynamicId"-->
    <!--      :email="selectedMail"-->
    <!--      :ticket="isMailHasReferenceIdTicket(selectedMail)"-->
    <!--      @restore-mail="mailRestored"-->
    <!--      @reply-mail="replyMail"-->
    <!--      @reply-all-mail="replyAllMail"-->
    <!--      @forward-mail="forwardMail"-->
    <!--      @archive-mail="mailArchived"-->
    <!--      @refresh-list="refreshList"-->
    <!--      @refresh-reminders="refreshReminders"-->
    <!--      @delete-mail="mailDeleted"-->
    <!--      @print-message="printMessage"-->
    <!--      @forward-as-attachement="forwardAsAttachement" />-->
    <b-modal
      :id="'confirmationModal'"
      ref="confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.marketingMessages.confirmModal.confirmationTitle')">
      <div
        v-if="disabledDeleteBtn"
        class="text-center">
        <coozzy-spinner />
      </div>
      <div
        v-else
        class="col p-0">
        <p>{{ textConfirmation }}</p>
      </div>
      <div class="col p-0">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('confirmationModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="disabledDeleteBtn"
          @click="deleteMails()">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
    <div
      v-if="showPopover"
      id="eventPopover"
      class="eventPopover"
      :style="{ left: page.left + 'px', top: page.top + 'px'}">
      <div
        v-if="selectedPopOverMessage"
        class="row">
        <div class="employees-calendar shadow py-3 px-4 mt-3">
          <div class="row">
            <div class="col-12 mb-1">
              <p><b>{{ selectedPopOverMessage.subject }}</b></p>
              <!-- eslint-disable vue/no-v-html -->
              <span
                style="white-space: pre-line"
                v-html="selectedPopOverMessage.bodyText" />
              <!-- eslint-enable vue/no-v-html -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <reminder-creation-modal
      v-if="reminderForEdit !== null"
      ref="reminder-creation"
      :key="reminderForEdit ? 'modal-creation'+reminderForEdit.id : 'modal-creation'"
      :reminder-detail="reminderForEdit"
      :source="'message'"
      :references-ids="[selectedMail.id || selectedMail.objectID]"
      @update-reminder="refreshReminders()" />
    <reminder-details-modal
      v-if="selectedReminder !== null"
      ref="detail-reminder-modal"
      :key="selectedReminder.id"
      :reminder-detail="selectedReminder"
      @create-reminder-click="createReminderClicked"
      @update-reminder="refreshReminders()" />
  </section>
</template>

<script>
import Vue from 'vue'
import MessageApi from '../misc/apis/MessageApi'
import ContactApi from '../misc/apis/ContactApi'
import TicketApi from '../misc/apis/TicketApi'
import CoozzyFormCheckbox from '../framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyButton from '../framework/components/button/CoozzyButton'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyCaretDownIcon from '@/framework/components/icons/CoozzyCaretDownIcon'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import CoozzyPaperClipIcon from '../framework/components/icons/CoozzyPaperClipIcon'
import { user } from '@/mixins/user'
import { contact } from '@/mixins/contact'
import { mail } from '@/mixins/mail'
import { tables } from '@/mixins/tables'
import { responsivity } from '@/mixins/responsivity'
import { ContentLoader } from 'vue-content-loader'
import CalendarApi from '@/misc/apis/CalendarApi'
import ReminderDetailsModal from '@/calendar/components/ReminderDetailsModal'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown.vue'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem.vue'

export default {
  name: 'MessagesDraftView',
  components: {
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyPageTitle,
    CoozzySpinner,
    CoozzyCaretDownIcon,
    CoozzyFormSelect,
    CoozzyButton,
    CoozzyFormCheckbox,
    CoozzyPaperClipIcon,
    ContentLoader,
    ReminderDetailsModal,
    ReminderCreationModal
  },
  mixins: [user, contact, mail, tables, responsivity],
  props: {
    f: {
      type: String,
      default: null
    },
    m: {
      type: String,
      default: null
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.navigation.messages')
    }
  },
  data() {
    return {
      abortController: null,
      shortContent: false,
      isBusy: false,
      querySearch: '',
      checkfilter: false,
      filterFolder: 'FILTER_FOLDER_INBOUND',
      filterMailboxes: [],
      disabledDeleteBtn: false,
      actionRead: null,
      emailToReply: null,
      textConfirmation: null,
      selectedMail: null,
      selectedEntries: [],
      messageList: [],
      listTickets: [],
      usedContacts: [],
      nbrPerPage: 10,
      currentPage: 1,
      messageCount: 0,
      appRefreshAction: '',
      loading: false,
      showPopover: false,
      selectedPopOverMessage: null,
      page: {
        left: 0,
        top: 0
      },
      isWaiting: null,
      fields: [
        {
          key: 'checkbox',
          label: '',
          th: 'th-checkbox',
          tdClass: 'align-middle checkbox-cell checkbox-cell'
        },
        {
          key: 'date',
          label: this.$t('message.tableColumnHeadings.date'),
          tdClass: 'align-middle text-left',
          thClass: 'align-middle text-left'
        },
        {
          key: 'from',
          label: this.$t('message.tableColumnHeadings.from'),
          tdClass: 'align-middle',
          thClass: 'align-middle'
        },
        {
          key: 'to',
          label: this.$t('message.tableColumnHeadings.to'),
          tdClass: 'align-middle',
          thClass: 'align-middle'
        },
        {
          key: 'subject',
          label: this.$t('message.tableColumnHeadings.subject'),
          thClass: 'align-middle',
          tdClass: 'fieldToShow'
        },
        {
          key: 'message',
          label: this.$t('message.tableColumnHeadings.message'),
          thClass: 'align-middle message-text',
          tdClass: 'fieldToShow'
        }
      ],
      disabledCheckbox: false,
      mailIdDeletedForClosingModal: [],
      filterUnreadMsg: false,
      reminders: [],
      selectedReminder: null,
      reminderForEdit: null,
      dynamicId: '',
      debounceTimeout: null,
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: []
    }
  },
  computed: {
    ...mapGetters('message', ['getSentMails', 'getMailboxesForUserId', 'getChangingStatusMails']),
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.messageCount : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.allEntries.map(x => x.id) : this.selectedEntries
    },
    calculateClassHideMailsFromOtherFolders() {
      if (this.filterFolder === 'FILTER_FOLDER_INBOUND' || this.filterFolder === 'FILTER_FOLDER_OUTBOUND' || this.filterFolder === 'FILTER_FOLDER_ALL') {
        return 'other'
      }
      if (this.filterFolder === 'FILTER_FOLDER_ARCHIVED') {
        return 'archive'
      }
      if (this.filterFolder === 'FILTER_FOLDER_TRASH') {
        return 'trash'
      }
      return ''
    },
    messagesPage() {
      if (this.nbrPerPage === 'all') {
        return this.messageList
      } else {
        return this.messageList.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
      }
    }
  },
  watch: {
    getSentMails: function (newVal) {
      if (newVal.length > 0) {
        this.loadMailboxesForUserId(this.userId)
        if (this.mailboxesForCurrentUser.length > 0) {
          this.filterMessages(1)
        }
      }
    },
    messageList: function () {
      this.getReminders()
      this.getTickets()
    },
    checkfilter: function () {
      this.clearCheckbox()
    },
    filterUnreadMsg() {
      this.filterMessages(1)
      this.checkfilter = true
    },
    mailboxesForCurrentUser: function () {
      // Restore mailboxes filter with only that mailboxes that are actually allowed by the user
      if (this.m && this.mailboxesForCurrentUser && this.mailboxesForCurrentUser.length) {
        const mailboxIds = this.m.split(',')
        this.filterMailboxes = this.mailboxesForCurrentUser.filter(box => mailboxIds.includes(box.id))
      }
      this.filterMessages(1)
    },
    filterFolder: function (newVal, oldVal) {
      this.checkfilter = true
      this.clearCheckbox()
      this.filterMessages(1)
      if (newVal !== oldVal && this.$route.query.f !== newVal) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            f: newVal,
            m: this.m
          }
        })
      }
    },
    filterMailboxes: function (newVal, oldVal) {
      this.checkfilter = true
      this.clearCheckbox()
      this.filterMessages(1)

      const newString = newVal.map(e => e.id).join(',')
      const oldString = oldVal.map(e => e.id).join(',')
      if (newString !== oldString && this.$route.query.m !== newString) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            f: this.f,
            m: newString
          }
        })
      }
    },
    currentPage: function () {
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    nbrPerPage: function () {
      this.filterMessages(1)
      this.checkfilter = true
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        this.allEntries = await this.filterAllMessages()
      }
    }
  },
  mounted: function () {
    this.resetStatusMails()
    this.$nextTick(function () {
      this.filterMessages(1)
    })
  },
  methods: {
    ...mapMutations('message', [
      'setSendMailContacts',
      'setEmailToForwardAsAttachment',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setSendMailEmailToForward',
      'setSendMailCC',
      'setSendMailOnlyCurrentContact',
      'setSendMailAttachmentMediaIds',
      'setSendMailIsDraft',
      'setSendMailBodyHtml',
      'setSendMailId',
      'setSendMailBCC',
      'setSendMailHasContext',
      'setMailsDraftLoadedAt',
      'addReadMail',
      'addUnreadMail',
      'setChangingStatusMails',
      'resetStatusMails',
      'setSubject'
    ]),
    ...mapActions('message', ['loadMailboxesForUserId']),
    getReminders() {
      CalendarApi.getRemindersByReferenceIds(this.messageList.map(mail => mail.id), this.$store.getters['user/getAccountId'], true)
        .then(response => {
          this.reminders = response.reminders.filter(x => x.referenceIds.find(y => y && y.includes('mail_')))
        })
    },
    getTickets() {
      const ticketReferenceIds = this.messageList.map(mail => mail.referenceIds).flat().filter(x => x && x.includes('ticket_'))
      if (ticketReferenceIds.length > 0) {
        TicketApi.getTicketByIds(ticketReferenceIds)
          .then(response => {
            this.listTickets = response.tickets
          })
      }
    },
    isMailHasReferenceIdTicket(message) {
      return this.listTickets?.find(ticket => message.referenceIds.includes(ticket.id))
    },
    refreshReminders() {
      this.getReminders()
      this.reminderForEdit = null
      this.$bvModal?.hide('modal-detail-mail')
    },
    createReminderClicked(reminder = null) {
      if (reminder !== null) {
        this.reminderForEdit = reminder
      } else {
        this.reminderForEdit = null
      }
      this.$nextTick(function () {
        this.$refs['reminder-creation']?.show()
      })
    },
    hasReminder(id) {
      let item = this.reminders.filter(x => x.referenceIds.find(y => y && y.includes(id)))
      if (item.length > 0) {
        item = item.sort((a, b) => (a.date < b.date ? 1 : -1))
        if (item[0].done === '0') {
          return 'progress'
        } else {
          return 'finish'
        }
      }
      return null
    },
    getReminder(id) {
      let item = this.reminders.filter(x => x.referenceIds.find(y => y && y.includes(id)))
      item = item.sort((a, b) => (a.date < b.date ? 1 : -1))
      if (item.length > 0) {
        return item[0]
      }
      return null
    },
    forwardToReminder(id) {
      this.selectedReminder = this.getReminder(id)
      this.$nextTick(function () {
        this.$refs['detail-reminder-modal']?.show()
      })
    },
    searchInputText() {
      this.checkfilter = true
      this.clearCheckbox()
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout)
      }
      this.debounceTimeout = setTimeout(() => {
        this.debounceTimeout = null
        this.filterMessages(1)
      }, 300)
    },
    getMessageText(bodyText) {
      if (bodyText) {
        return this.getTooltipCorrectString(bodyText).trim()
      }
    },
    clearCheckbox() {
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    async sendEmailModal(mail) {
      if (mail.replyToMailId !== '' || mail.forwardingMailId !== '') {
        const ids = []
        if (mail.replyToMailId !== '') {
          ids.push(mail.replyToMailId)
        } else if (mail.forwardingMailId !== '') {
          ids.push(mail.forwardingMailId)
        }
        if (ids.length > 0) {
          const emailResponse = await MessageApi.listMailsByIds(ids)
          if (emailResponse?.mails.length > 0) {
            const email = emailResponse.mails[0]
            if (mail.replyToMailId !== '') {
              this.setSendMailEmailToReply(email)
            } else {
              this.setSendMailEmailToForward(email)
            }
          }
        }
      }
      this.setSendMailCC(mail.cc)
      this.setSendMailBCC(mail.bcc)
      this.setSendMailContacts(mail.to)
      this.setSubject(mail.subject)
      this.setSendMailIsDraft(true)
      this.setSendMailBodyHtml(mail.bodyHtml)
      this.setSendMailId(mail.id)
      this.setSendMailReferenceIds(mail.referenceIds)
      this.setSendMailAttachmentMediaIds(mail.attachmentMediaIds)
      this.$nextTick(function () {
        if (this.getMailboxesForUserId(this.userId).length === 0) {
          this.$nextTick(function () {
            Vue.toasted.show(this.$t('message.settings.mailbox.configMailboxToast'), { type: 'error' })
          })
        } else {
          this.callShowSendMailModal()
        }
      })
    },
    forwardAsAttachement(mediaList, email) {
      this.setSubject('Fwd: ' + email.subject)
      this.setSendMailHasContext(true)
      this.setSendMailContacts([])
      this.setEmailToForwardAsAttachment(mediaList)
      this.$nextTick(function () {
        if (this.getMailboxesForUserId(this.userId).length === 0) {
          this.$nextTick(function () {
            Vue.toasted.show(this.$t('message.settings.mailbox.configMailboxToast'), { type: 'error' })
          })
        } else {
          this.callShowSendMailModal()
        }
      })
    },
    deleteDraft() {
      this.actionRead = 'delete'
      this.textConfirmation = this.$t('message.marketingMessages.confirmModal.confirmationTextDelete')
      this.$refs.confirmationModal?.show()
    },
    async filterMessages(page = 1) {
      this.selectedEntries = []
      this.isBusy = true

      // Cancel the previous request, if there is one
      if (this.abortController) {
        this.abortController.abort()
      }

      // Create a new AbortController
      this.abortController = new AbortController()

      try {
        this.messageCount = 0
        this.messageList = []
        const response = await MessageApi.listDraftsMessage()

        // If the request was cancelled, don't update the messages
        if (this.abortController.signal.aborted) {
          return
        }
        if (response) {
          this.messageCount = response.drafts.length
          this.messageList = response.drafts
          if (this.$refs.header_checkbox) {
            this.$refs.header_checkbox.checked = false
          }
          this.loadContacts()
          this.setMailsDraftLoadedAt(Date.now())
          this.isBusy = false
          this.$parent.$refs['coozzy-header'].countDraftMails(true)
          this.refreshDone()
          this.$nextTick(() => {
            this.currentPage = page
          })
        }
      } catch (error) {
        console.log(error)
        if (error.name === 'AbortError') {
          console.log('Request cancelled:', error.message)
          this.isBusy = true
        } else {
          console.error(error)
          this.isBusy = false
          this.refreshDone()
        }
        Vue.toasted.show(this.$t('message.loadingErrors.message'), { type: 'error' })
      }
    },
    loadContacts() {
      ContactApi.contactResolve(
        this.messageList.map(mail => mail.referenceIds).flat().filter(id => id.includes('contact_'))
      ).then(response => {
        this.usedContacts = response.persons.concat(response.companies)
        this.setContacts(this.usedContacts)
      }).catch(e => {
        console.log(e)
        Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
      }).finally(() => {
        this.isBusy = false
      })
    },
    async deleteMails() {
      this.disabledDeleteBtn = true
      this.setChangingStatusMails(this.selectedEntriesIds)
      this.$refs.confirmationModal.hide()
      this.disabledCheckbox = true
      MessageApi.deleteDrafts(this.selectedEntriesIds)
        .then(async () => {
          this.mailIdDeletedForClosingModal = this.mailIdDeletedForClosingModal.concat(this.selectedEntriesIds)
          this.appRefreshAction = 'delete'
          let page = this.currentPage
          if (this.messageList.length === this.selectedEntriesIds.length) {
            page = this.currentPage > 1 ? this.currentPage - 1 : this.currentPage
          }
          this.filterMessages(page)
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.mail'), { type: 'error' })
        })
    },
    restoreMails() {
      this.disabledDeleteBtn = true
      this.setChangingStatusMails(this.selectedEntries)
      this.$refs.confirmationModal.hide()
      this.disabledCheckbox = true
      if (this.filterFolder === 'FILTER_FOLDER_ARCHIVED') {
        MessageApi.unarchiveMails(this.selectedEntries)
          .then(async () => {
            this.appRefreshAction = 'restore'
            this.filterMessages()
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.deleteErrors.mail'), { type: 'error' })
          })
      } else {
        MessageApi.restoreMails(this.selectedEntries)
          .then(async () => {
            this.appRefreshAction = 'restore'
            this.filterMessages()
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.deleteErrors.mail'), { type: 'error' })
          })
      }
    },
    async emptyTrash() {
      this.setChangingStatusMails(this.selectedEntries)
      this.$refs.confirmationModal.hide()
      this.disabledCheckbox = true
      this.disabledDeleteBtn = true
      MessageApi.emptyTrash(this.selectedEntries)
        .then(async () => {
          this.appRefreshAction = 'emptyTrash'
          this.filterMessages()
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.mail'), { type: 'error' })
        })
    },
    async mailDeleted(id) {
      this.appRefreshAction = 'delete'
      this.disabledCheckbox = true
      this.mailIdDeletedForClosingModal.push(id)
      let page = this.currentPage
      if (this.messageList.length === 1) {
        page = this.currentPage > 1 ? this.currentPage - 1 : this.currentPage
      }
      this.filterMessages(page)
    },
    async mailArchived() {
      this.appRefreshAction = 'archive'
      this.disabledCheckbox = true
      let page = this.currentPage
      if (this.messageList.length === 1) {
        page = this.currentPage > 1 ? this.currentPage - 1 : this.currentPage
      }
      this.filterMessages(page)
    },
    async mailRestored() {
      this.appRefreshAction = 'restore'
      this.disabledCheckbox = true
      let page = this.currentPage
      if (this.messageList.length === 1) {
        page = this.currentPage > 1 ? this.currentPage - 1 : this.currentPage
      }
      this.filterMessages(page)
    },
    async refreshList() {
      let page = this.currentPage
      if (this.messageList.length === 1) {
        page = this.currentPage > 1 ? this.currentPage - 1 : this.currentPage
      }
      this.filterMessages(page)
    },
    async filterAllMessages() {
      // Cancel the previous request, if there is one
      if (this.abortController) {
        this.abortController.abort()
      }
      // Create a new AbortController
      this.abortController = new AbortController()
      try {
        const response = await MessageApi.listDraftsMessage()
        // If the request was cancelled, don't update the messages
        if (this.abortController.signal.aborted) {
          return
        }
        if (response) {
          return response.drafts
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs.messagesTable) {
        const allCheckboxesComponents = this.$refs.messagesTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
        this.selectAllVisibleEntries = value
        if (value === false) {
          this.selectAllEntries = false
        }
      }
    },
    itemSelected(id, checked) {
      if (checked && !this.selectedEntries.includes(id)) {
        this.selectedEntries.push(id)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry !== id)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    onRowClicked(item, index, event) {
      this.$refs.messagesTable.clearSelected()
      this.dynamicId = Math.random().toString(36).substr(2, 9)
      if (event.srcElement.classList.contains('custom-checkbox') || event.srcElement.classList.contains('checkbox-cell')) {
        this.itemSelected(item.id, !document.getElementById('check_' + item.id).checked)
        document.getElementById('check_' + item.id).checked = !document.getElementById('check_' + item.id).checked
      } else if (event.target && event.target.parentElement && event.target.parentElement._prevClass && event.target.parentElement._prevClass.includes('reminderIcon')) {
        this.selectedMail = item
        return false
      } else {
        this.sendEmailModal(item)
      }
    },
    onRowMiddleClicked(item, index, event) {
      this.dynamicId = Math.random().toString(36).substr(2, 9)
      if (event.srcElement.classList.contains('icon-link-ticket')) {
        this.openMiddleDetail(this.isMailHasReferenceIdTicket(item))
      }
    },
    onRowHovered(item) {
      this.selectedPopOverMessage = item
    },
    onRowUnHovered() {
      this.selectedPopOverMessage = null
    },
    onMouseMove(e) {
      this.showPopover = this.hasSomeParentTheClass(e.srcElement, 'fieldToShow')
      if (this.showPopover && document.getElementById('eventPopover')) {
        const tooltipWidth = document.getElementById('eventPopover').clientWidth
        const tooltipHeight = document.getElementById('eventPopover').clientHeight
        const windowWidth = window.innerWidth
        const windowHeight = document.body.clientHeight
        if ((windowWidth - 100) >= (tooltipWidth + e.pageX)) {
          this.page.left = e.pageX
        } else {
          this.page.left = e.pageX - tooltipWidth
        }
        if ((windowHeight - 100) >= (tooltipHeight + e.pageY)) {
          this.page.top = e.pageY
        } else {
          this.page.top = e.pageY - tooltipHeight
        }
      }
    },
    paginateTo(page) {
      this.filterMessages(page)
    },
    replyMail(email) {
      this.prepareReplyMail(email)
    },
    replyAllMail(email) {
      this.prepareReplyAll(email)
    },
    forwardMail(email) {
      this.prepareForwardMail(email)
    },
    printMessage(shortContent) {
      this.shortContent = shortContent
    },
    showDetail(mail) {
      this.selectedMail = mail
      this.setMailDetailMailObject(mail)
      this.setMailDetailTicketObject(this.isMailHasReferenceIdTicket(mail))
      this.$nextTick(function () {
        this.callShowDetailMailModal()
      })
    },
    clickOnClear() {
      this.querySearch = ''
      this.filterFolder = 'FILTER_FOLDER_ALL'
      this.filterMailboxes = []
      this.filterUnreadMsg = []
      this.$nextTick(function () {
        this.checkfilter = false
      })
    },
    refreshDone() {
      if (this.appRefreshAction === 'delete') {
        if (this.$refs['mail-detail-modal'] && this.mailIdDeletedForClosingModal.includes(this.$refs['mail-detail-modal'].email?.id)) {
          this.mailIdDeletedForClosingModal = []
          this.$nextTick(function () {
            // this.$refs['mail-detail-modal'].loading = false
            this.$refs['mail-detail-modal'].hide()
          })
        }
        Vue.toasted.show(this.$t('message.successMessages.deleteMail'), { type: 'success' })
      } else if (this.appRefreshAction === 'restore') {
        if (this.filterFolder === 'FILTER_FOLDER_ARCHIVED') {
          Vue.toasted.show(this.$t('message.successMessages.restoreMailFromArchive'), { type: 'success' })
        } else {
          Vue.toasted.show(this.$t('message.successMessages.restoreMail'), { type: 'success' })
        }
      } else if (this.appRefreshAction === 'emptyTrash') {
        Vue.toasted.show(this.$t('message.successMessages.emptyTrashMail'), { type: 'success' })
      } else if (this.appRefreshAction === 'archive') {
        Vue.toasted.show(this.$t('message.successMessages.archiveMail'), { type: 'success' })
      }

      this.disabledCheckbox = false
      this.disabledDeleteBtn = false

      if (this.$refs.confirmationModal) {
        this.$refs.confirmationModal.hide()
      }

      this.clearCheckbox()
      this.resetStatusMails()

      this.appRefreshAction = ''
    }
  }
}
</script>

<style scoped lang="scss">
:deep(.message-text) {
  width: 330px;
}
:deep(.adjustWidth) {
  max-width: 90px;
  min-width: 85px;
  padding: 0 !important;
    > div {
    max-width: 90px;
    }
}
p.text-msg {
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  line-break: anywhere;
}

.other :deep(.archivedMail) {
  display: none;
}

.other :deep(.trashedMail) {
  display: none;
}

.archive :deep(.trashedMail) {
  display: none;
}

.archive :deep(.otherMail) {
  display: none;
}

.trash :deep(.archivedMail) {
  display: none;
}

.trash :deep(.otherMail) {
  display: none;
}

:deep(.deletedMail) {
  display: none;
}
.eventPopover {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
  width: 270px !important;
  .employees-calendar{
    width: 100%;
    border-radius: 25px;
    background: #ededed;
    color: black;
    font-size: 14px;
    max-width: 500px;
    h4 {
      font-size: 20px;
    }
    span {
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 10;
      -webkit-line-clamp: 10; /* number of lines to show */
      -webkit-box-orient: vertical;
    }
  }
}
</style>
