<template>
  <section>
    <b-modal
      :id="'modal-detail-mail'"
      ref="modal-detail-mail"
      :modal-class="[hideModal ? 'd-none' : '', 'has-confirmation-modal', minimized ? 'minimized-block' : 'full-block', minus ? 'minus-block' : '']"
      no-close-on-backdrop>
      <template #modal-header>
        <h5 class="modal-title">
          {{ minus ? (email.subject.length > 25 ? email.subject.substring(0, 25) + '...' : email.subject) : $t('message.marketingMessages.detailEmail') }}
        </h5>
        <div class="d-flex">
          <a
            v-if="ticket && !isTicketAlreadyOpen"
            class="d-inline linkTicket mr-3 align-self-center"
            @click="routeToDetail(ticket)"
            @click.middle="openMiddleDetail(ticket)">
            <span
              :id="'tooltip-title-' + ticket.key">
              <img
                height="20px"
                class="mr-1"
                :src="`/img/ticket/${ticket.type.split('TICKET_TYPE_')[1].toLowerCase()}.svg`"
                alt="type">{{ $t('message.ticketOverview.ticket.type.' + ticket.type) }} {{ ticket.key }}
            </span>
            <b-tooltip
              :target="'tooltip-title-' + ticket.key"
              triggers="hover">
              {{ ticket.title }}
            </b-tooltip>
          </a>
          <coozzy-form-checkbox
            v-if="!minus"
            v-model="confidentialEmail"
            :disabled="!canChangeMailConfidential"
            :initial="confidentialEmail"
            class="mr-4 pr-0 align-self-center"
            name="confidentialEmail"
            @change="(value) => { markAsConfidential(value) }">
            {{ $t('message.settings.confidentialEmails.confidential') }}
          </coozzy-form-checkbox>
          <coozzy-button
            design="transparent"
            @click="minusEvent">
            <coozzy-minus-icon name="minus" />
          </coozzy-button>
          <coozzy-button
            v-if="!minimized"
            design="transparent"
            @click="changeMinimized">
            <coozzy-minimize-icon name="minimize" />
          </coozzy-button>
          <coozzy-button
            v-if="minimized"
            class="ml-2"
            design="transparent"
            size="small"
            @click="changeMinimized">
            <coozzy-full-screen-icon name="full-screen" />
          </coozzy-button>
          <b-button
            class="close ml-2"
            size="sm"
            @click="hide()">
            x
          </b-button>
        </div>
      </template>
      <div
        v-if="loading"
        class="text-center">
        <coozzy-spinner />
      </div>
      <message-detail-content
        v-show="!loading"
        ref="messageDetailContent"
        :email="email"
        :contact="contact"
        :ticket="ticket"
        :source="'modal'"
        :minimized="minimized"
        @print-message="printMessage"
        @reply-mail="reply"
        @reply-all-mail="replyAll"
        @forward-as-attachement="forwardAsAttachement"
        @forward-mail="forwardMail"
        @delete-mail="deleteMail"
        @archive-mail="archiveMail"
        @restore-mail="restoreMail"
        @refresh-list="refreshEmailList"
        @refresh-list-after-close="refreshListAfterClose"
        @refresh-reminders="refreshReminders"
        @hide="hide"
        @loading-message="changeLoading" />
      <div
        slot="modal-footer"
        class="w-100 float-right">
        <coozzy-button
          design="default"
          @click="hide()">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>

<script>

import CoozzyButton from '@/framework/components/button/CoozzyButton'
import { user } from '@/mixins/user'
import { media } from '@/mixins/media'
import { contact } from '@/mixins/contact'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import MessageApi from '@/misc/apis/MessageApi'
import { routeChecks } from '@/mixins/routeChecks'
import { request } from '@/mixins/request'
import { eventBus, mail } from '@/mixins/mail'
import { algolia } from '@/mixins/algolia'
import MessageDetailContent from './MessageDetailContent.vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import CoozzyFullScreenIcon from '@/framework/components/icons/CoozzyFullScreenIcon.vue'
import CoozzyMinimizeIcon from '@/framework/components/icons/CoozzyMinimizeIcon.vue'
import CoozzyMinusIcon from '@/framework/components/icons/CoozzyMinusIcon.vue'

export default {
  name: 'MessageDetailsModal',
  components: {
    CoozzyMinusIcon,
    CoozzyMinimizeIcon,
    CoozzyFullScreenIcon,
    CoozzySpinner,
    CoozzyFormCheckbox,
    MessageDetailContent,
    CoozzyButton
  },
  mixins: [algolia, routeChecks, user, mail, media, contact, request],
  data() {
    return {
      hideModal: false,
      email: [],
      contact: [],
      ticket: [],
      sourceComponent: null,
      minus: false,
      minimized: false,
      mediasList: [],
      printAllow: false,
      loading: true,
      emailForForward: false,
      confidentialEmail: false,
      documentDirection: '',
      ticketType: '',
      mustRefresh: false,
      scrollPosition: 0
    }
  },
  computed: {
    ...mapState('request', ['marketplace']),
    ...mapState('message', ['confidentialMailDomain', 'messageDetailData']),
    ...mapGetters('message', ['getMailboxesForUserId']),
    mailboxesForCurrentUser() {
      return this.getMailboxesForUserId(this.userId)
    },
    canChangeMailConfidential() {
      const outboundEmails = this.mailboxesForCurrentUser.filter(mailbox => mailbox.mainUserId === this.userId).map(mailbox => mailbox.outboundEmail)
      const emailFrom = this.email.from ? [this.email.from.email] : []
      const emailTo = this.email.to ? this.email.to.flatMap(x => x.email) : []
      const emailBcc = (this.email.bcc && this.email.bcc[0]) ? this.email.bcc[0].email : ''
      const emailCc = this.email.cc ? this.email.cc.flatMap(x => x.email) : []
      const recipientsMails = emailFrom.concat(emailTo).concat(emailCc).concat(emailBcc)
      return recipientsMails.some(x => outboundEmails.includes(x))
    },
    mailId() {
      return this.email.id || this.email.objectID
    }
  },
  watch: {
    minimized(newVal) {
      if (newVal) {
        this.minus = false
      }
    },
    minus(newVal) {
      if (newVal) {
        this.minimized = false
      }
    },
    $route(to, from) {
      if (to.path !== from.path) {
        this.hide()
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleEscKey)
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'modal-detail-mail') {
        this.hideModal = false
        this.$store.dispatch('user/setDetailMailModalOpen', true)
        this.email = this.messageDetailData.email
        this.contact = this.messageDetailData.contact
        this.ticket = this.messageDetailData.ticket
        this.sourceComponent = this.messageDetailData.sourceComponent
        this.confidentialEmail = this.email.confidential
          // Save the current scroll position
          this.scrollPosition = window.pageYOffset

          if (!this.email?.readEntries?.some(entry => entry.accountId === this.accountId) || this.getUnreadMail().find(entry => entry.accountId === this.accountId && entry.mailId === this.mailId)) {
            const outboundEmails = this.mailboxesForCurrentUser
              .filter(mailbox => mailbox.mainUserId === this.userId)
              .map(mailbox => mailbox.outboundEmail)
            const emailTo = this.email.to ? this.email.to.flatMap(x => x.email) : []
            const emailBcc = (this.email.bcc && this.email.bcc[0]) ? this.email.bcc[0].email : ''
            const emailCc = this.email.cc ? this.email.cc.flatMap(x => x.email) : []
            if (outboundEmails.includes(emailBcc) || emailCc.some(x => outboundEmails.includes(x)) || emailTo.some(x => x.startsWith('req_') || outboundEmails.includes(x))) {
              MessageApi.mailMarkRead([this.mailId], this.accountId).then(() => {
                this.addReadMail({
                  mailId: this.mailId,
                  accountId: this.accountId
                })
              })
            }
          }
          this.loadConfidentialMailDomain(this.accountId)
          this.$nextTick(() => {
            const dropdownMenu = document.querySelector('#modal-detail-mail___BV_modal_content_')
            if (dropdownMenu) {
              dropdownMenu.removeAttribute('tabindex')
            }
          })
      }
    })
    this.$root.$on('bv::modal::hide', (_, modalId) => {
      if (modalId === 'modal-detail-mail') {
        this.minimized = false
        this.minus = false
        this.$emit('restore')
        this.$emit('modal-hidden')
        this.$store.dispatch('user/setDetailMailModalOpen', false)
      }
    })
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleEscKey)
  },
  methods: {
    ...mapGetters('message', ['getUnreadMail']),
    ...mapMutations('message', ['addReadMail', 'setChangingStatusMails']),
    ...mapActions('message', ['loadMailboxesForUserId', 'loadConfidentialMailDomain']),
    handleEscKey(event) {
      if (event.key === 'Escape') {
        this.hide()
      }
    },
    show() {
      this.$refs['modal-detail-mail'].show()
    },
    changeMinimized() {
      this.minus = false
      this.minimized = !this.minimized
      if (this.minimized || this.minus) {
        document.getElementsByClassName('body')[0].style.overflow = 'auto'
      } else {
        document.getElementsByClassName('body')[0].style.overflow = 'hidden'
      }
    },
    minusEvent() {
      this.minus = !this.minus
      if (this.minimized || this.minus) {
        document.getElementsByClassName('body')[0].style.overflow = 'auto'
      } else {
        document.getElementsByClassName('body')[0].style.overflow = 'hidden'
      }
    },
    changeLoading(value) {
      setTimeout(() => {
        this.loading = value
      }, 400)
    },
    hide() {
      this.hideModal = true
      this.loading = true
      this.$nextTick(() => {
        if (this.mustRefresh) {
          this.$emit('refresh-list')
        }
        this.$bvModal.hide('modal-detail-mail')
        this.setMailDetailSourceComponent(null)
      })
    },
    refreshListAfterClose() {
      this.mustRefresh = true
    },
    reply(email) {
      if (this.sourceComponent === 'InterestedPartyDetails') {
        eventBus.$emit('interested-party-reply', email)
      } else {
        this.replyMail(email)
      }
    },
    replyAll(email) {
      if (this.sourceComponent === 'InterestedPartyDetails') {
        eventBus.$emit('interested-party-reply-all', email)
      } else {
        this.replyAllMail(email)
      }
    },
    forwardMail(email) {
      this.forwardMails(email)
    },
    deleteMail(id) {
      eventBus.$emit('deleteMail', id)
      if (this.sourceComponent === 'InterestedPartyDetails') {
        eventBus.$emit('interested-party-refresh-messages')
      }
    },
    archiveMail(email) {
      eventBus.$emit('archive-mail', email)
      if (this.sourceComponent === 'InterestedPartyDetails') {
        eventBus.$emit('interested-party-refresh-messages')
      }
    },
    restoreMail(email) {
      eventBus.$emit('restore-mail', email)
      if (this.sourceComponent === 'InterestedPartyDetails') {
        eventBus.$emit('interested-party-refresh-messages')
      }
    },
    forwardAsAttachement(mediasList, email) {
      this.forwardAsAttachements(mediasList, email)
    },
    refreshReminders() {
      eventBus.$emit('refresh-reminders')
    },
    refreshEmailList(id) {
      eventBus.$emit('refresh-list', id)
    },
    linkToContact(contactId) {
      eventBus.$emit('link-to-contact', contactId)
    },
    linkToTicket(ticketId) {
      eventBus.$emit('link-to-ticket', ticketId)
    },
    linkToRequest(requestId) {
      eventBus.$emit('link-to-request', requestId)
    },
    printMessage(newVal) {
      eventBus.$emit('print-message', newVal)
    },
    markAsConfidential(value) {
      this.$refs.messageDetailContent.markAsConfidential(value)
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.more-btn button) {
  border-radius: 0.2rem;
  color: #515151;
  font-size: .875rem;
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid #ced4da;
}

:deep(.modal-header) {
  padding-bottom: 0;
}

:deep(.modal-body) {
  padding-top: 0;
}

p {
  white-space: pre-wrap;
}

.media-block {
  max-width: 33%;
  margin-right: 0.5%;
}

:deep(.modal-dialog) {
  max-width: 1200px !important;
}

.close-img {
  width: 15px;
}

.section-to-print {
  opacity: 1;
  background-color: #FFF;
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 100%;
  min-height: 200px;
  top: 0;
  left: 0;
  padding: 15px;
  margin: 0;
  z-index: 1000;
}

.section-to-print-bg {
  background-color: transparent !important;
}

:deep(.ticket-dropdown-bulk-action button) {
  border: 0;
}

.linkTicket {
  cursor: pointer;
  color: #007bff !important;
}
:deep(.minimized-block) {
  z-index: 1;
  width: auto;
  height: auto;

  .modal-dialog {
    height: 40vh !important;
    position: fixed;
    right: 25px;
    bottom: 100px;
    max-width: 600px !important;
  }

  .modal-body {
    max-height: 40vh !important;
    overflow-y: scroll;
  }
}

:deep(.minus-block) {
  z-index: 1;
  width: auto;
  height: auto;

  .modal-header {
    background-color: #eeeeee;
    padding: 0.5rem;
    width: 25vw;

    h5 {
      line-height: 2;
    }
  }

  .modal-dialog {
    bottom: 0 !important;
    margin-bottom: 0 !important;
    max-height: 40vh !important;
    position: fixed;
    right: 25px;
    max-width: 600px !important;
  }

  .modal-body {
    display: none;
  }

  .modal-footer {
    display: none;
  }
}
:deep(#modal-detail-mail.minus-block + .modal-backdrop[id^='modal-detail-mail']) {
  opacity: 0;
  width: auto;
  height: auto;
}

:deep(#modal-detail-mail.minimized-block + .modal-backdrop[id^='modal-detail-mail']) {
  opacity: 0;
  width: auto;
  height: auto;
}
#modal-detail-mail___BV_modal_outer_ {
  z-index: 8000 !important;
}
</style>
