<template>
  <b-modal
    id="template-generate-modal"
    ref="template-generate-modal"
    no-close-on-backdrop
    hide-footer
    :title="templateEdit === null ? $t('message.settings.exportTemplate.modalTitle') : $t('message.settings.exportTemplate.modalEditTitle')">
    <div
      v-if="loading"
      class="row">
      <div
        class="text-center col">
        <coozzy-spinner />
      </div>
    </div>
    <div
      v-else
      class="row">
      <div
        class="dragover-mask"
        @dragleave="drag=false" />
      <div class="col-sm-12 col-md-12">
        <coozzy-form-input
          v-model="template.name"
          class="mb-2"
          :limit="255"
          :name="$t('message.tableColumnHeadings.title') + '*'"
          :state="$v.template.name.$dirty && $v.template.name.$error ? false : null" />
      </div>
      <div
        class="col-sm-12 col-md-6">
        <label>{{ $t('message.settings.exportTemplate.tenancyType.title') }}</label>
        <coozzy-form-select
          v-model="template.tenancyType"
          class="mb-2">
          <option :value="'TENANCY_TYPE_ALL'">
            {{ $t('message.generateExcel.all') }}
          </option>
          <option :value="'TENANCY_TYPE_RENTED'">
            {{ $t('message.generateExcel.rented') }}
          </option>
          <option :value="'TENANCY_TYPE_VACANT'">
            {{ $t('message.generateExcel.vacant') }}
          </option>
        </coozzy-form-select>
      </div>
      <div class="col-sm-12 col-md-6">
        <label>{{ $t('message.generateExcel.sorting.title') }}</label>
        <coozzy-form-select
          v-model="template.sortingType"
          class="mb-2">
          <option :value="'SORTING_TYPE_OBJECT_ID'">
            {{ $t('message.generateExcel.sorting.byObjectId') }}
          </option>
          <option :value="'SORTING_TYPE_OBJECT_NUMBER'">
            {{ $t('message.generateExcel.sorting.byObjectNumber') }}
          </option>
          <option :value="'SORTING_TYPE_STANDARD'">
            {{ $t('message.generateExcel.sorting.standard') }}
          </option>
        </coozzy-form-select>
      </div>
      <div class="col-sm-12 col-md-6">
        <label>{{ $t('message.generateExcel.composition.title') }}</label>
        <coozzy-form-select
          v-model="template.compositionType"
          class="mb-2">
          <option :value="'COMPOSITION_TYPE_SEPARATELY'">
            {{ $t('message.generateExcel.composition.separately') }}
          </option>
          <option :value="'COMPOSITION_TYPE_CONSOLIDATE'">
            {{ $t('message.generateExcel.composition.consolidate') }}
          </option>
        </coozzy-form-select>
        <coozzy-form-select
          v-model="template.compositionLinesOrColumns"
          class="mb-2">
          <option :value="'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'">
            {{ $t('message.generateExcel.compositionLinesOrColumns.vertical') }}
          </option>
          <option :value="'COMPOSITION_LINES_OR_COLUMNS_HORIZONTAL'">
            {{ $t('message.generateExcel.compositionLinesOrColumns.horizontal') }}
          </option>
        </coozzy-form-select>
      </div>
      <div
        class="col-sm-12 col-md-6">
        <label>{{ $t('message.settings.template.placeholderTemplate.language') }}</label>
        <coozzy-form-select
          v-model="template.language"
          class="mb-2">
          <option :value="'PREFERRED_LANGUAGE_ENGLISH'">
            EN
          </option>
          <option :value="'PREFERRED_LANGUAGE_GERMAN'">
            DE
          </option>
          <option :value="'PREFERRED_LANGUAGE_ITALIAN'">
            IT
          </option>
        </coozzy-form-select>
      </div>
      <div class="row col-12">
        <div class="col-sm-12 col-md-2">
          <coozzy-form-checkbox
            ref="selectAll_checkbox"
            v-model="selectAll"
            class="pt-2 pb-2 pr-1 m-1">
            <template>
              {{ $t('message.generateExcel.selectAll') }}
            </template>
          </coozzy-form-checkbox>
        </div>
        <div class="col-sm-12 col-md-2">
          <coozzy-form-checkbox
            ref="deselectAll_checkbox"
            v-model="deselectAll"
            class="pt-2 pb-2 pr-1 m-1">
            <template>
              {{ $t('message.generateExcel.deselectAll') }}
            </template>
          </coozzy-form-checkbox>
        </div>
      </div>
      <div class="col-sm-12 col-md-12">
        <label
          class="text-lg color_dark mt-3 mb-1 col-12 row">
          {{ $t('message.generateExcel.selectedFields') }}
          <coozzy-info-circle-icon
            v-b-tooltip.hover.html="$t('message.generateExcel.selectedFieldsTooltip')" />
        </label>
        <coozzy-card
          class="global-card padding-top-1rem">
          <draggable
            :list="selectedFields"
            group="fields"
            class="row pr-2 pl-2">
            <template
              v-for="(item, itemIndex) in selectedFields">
              <div
                :key="`item_${itemIndex}`"
                class="col-4 pl-0 pr-0">
                <coozzy-form-checkbox
                  v-model="item.selected"
                  :initial="item.selected"
                  class="borderFields pt-2 pb-2 p r-1 m-1">
                  <template>
                    {{ $t(`message.generateExcel.fields.${item.key}`) }}
                  </template>
                  <template v-if="template.compositionLinesOrColumns === 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'">
                    <font-awesome-icon
                      v-if="item.subgroup === 'tenant' "
                      icon="address-book"
                      class="fa" />
                    <font-awesome-icon
                      v-if="item.subgroup === 'subtenancy' "
                      icon="history"
                      class="fa" />
                    <img
                      v-if="item.subgroup === 'rentAdjustment' "
                      width="12.5px"
                      height="14px"
                      alt="rentAdjustment"
                      src="@/assets/icon/activate-tenancy-house.svg">
                  </template>
                </coozzy-form-checkbox>
              </div>
            </template>
          </draggable>
        </coozzy-card>

        <label class="text-lg color_dark mt-3 mb-1 col-12 row">
          {{ $t('message.generateExcel.availableFields') }}
          <coozzy-info-circle-icon
            v-b-tooltip.hover.html="$t('message.generateExcel.selectedFieldsTooltip')" />
        </label>
        <coozzy-card class="global-card padding-top-1rem">
          <label class="color_dark">
            {{ $t('message.generateExcel.property') }}
          </label>
          <draggable
            :list="propertyFields"
            :disabled="true"
            class="row pr-2 pl-2">
            <template
              v-for="(item, itemIndex) in propertyFields">
              <div
                :key="`item_${itemIndex}`"
                class="col-4 pl-0 pr-0">
                <coozzy-form-checkbox
                  v-model="item.selected"
                  :initial="item.selected"
                  class="borderFields pt-2 pb-2 pr-1 m-1">
                  <template class="font-size-0875rem">
                    {{ $t(`message.generateExcel.fields.${item.key}`) }}
                  </template>
                  <template v-if="template.compositionLinesOrColumns === 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'">
                    <font-awesome-icon
                      v-if="item.subgroup === 'tenant' "
                      icon="address-book"
                      class="fa" />
                    <font-awesome-icon
                      v-if="item.subgroup === 'subtenancy' "
                      icon="history"
                      class="fa" />
                    <img
                      v-if="item.subgroup === 'rentAdjustment' "
                      width="12.5px"
                      height="14px"
                      alt="rentAdjustment"
                      src="@/assets/icon/activate-tenancy-house.svg">
                  </template>
                </coozzy-form-checkbox>
              </div>
            </template>
          </draggable>
        </coozzy-card>

        <coozzy-card class="global-card padding-top-1rem">
          <label class="color_dark">
            {{ $t('message.generateExcel.building') }}
          </label>
          <draggable
            :list="buildingFields"
            :disabled="true"
            class="row pr-2 pl-2">
            <template
              v-for="(item, itemIndex) in buildingFields">
              <div
                :key="`item_${itemIndex}`"
                class="col-4 pl-0 pr-0">
                <coozzy-form-checkbox
                  v-model="item.selected"
                  :initial="item.selected"
                  class="borderFields pt-2 pb-2 pr-1 m-1">
                  <template class="font-size-0875rem">
                    {{ $t(`message.generateExcel.fields.${item.key}`) }}
                  </template>
                  <template v-if="template.compositionLinesOrColumns === 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'">
                    <font-awesome-icon
                      v-if="item.subgroup === 'tenant' "
                      icon="address-book"
                      class="fa" />
                    <font-awesome-icon
                      v-if="item.subgroup === 'subtenancy' "
                      icon="history"
                      class="fa" />
                    <img
                      v-if="item.subgroup === 'rentAdjustment' "
                      width="12.5px"
                      height="14px"
                      alt="rentAdjustment"
                      src="@/assets/icon/activate-tenancy-house.svg">
                  </template>
                </coozzy-form-checkbox>
              </div>
            </template>
          </draggable>
        </coozzy-card>

        <coozzy-card class="global-card padding-top-1rem">
          <label class="color_dark">
            {{ $t('message.generateExcel.object') }}
          </label>
          <draggable
            :list="objectFields"
            :disabled="true"
            class="row pr-2 pl-2">
            <template
              v-for="(item, itemIndex) in objectFields">
              <div
                :key="`item_${itemIndex}`"
                class="col-4 pl-0 pr-0">
                <coozzy-form-checkbox
                  v-model="item.selected"
                  :initial="item.selected"
                  class="borderFields pt-2 pb-2 pr-1 m-1">
                  <template class="font-size-0875rem">
                    {{ $t(`message.generateExcel.fields.${item.key}`) }}
                  </template>
                  <template v-if="template.compositionLinesOrColumns === 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'">
                    <font-awesome-icon
                      v-if="item.subgroup === 'tenant' "
                      icon="address-book"
                      class="fa" />
                    <font-awesome-icon
                      v-if="item.subgroup === 'subtenancy' "
                      icon="history"
                      class="fa" />
                    <img
                      v-if="item.subgroup === 'rentAdjustment' "
                      width="12.5px"
                      height="14px"
                      alt="rentAdjustment"
                      src="@/assets/icon/activate-tenancy-house.svg">
                  </template>
                </coozzy-form-checkbox>
              </div>
            </template>
          </draggable>
        </coozzy-card>

        <coozzy-card class="global-card padding-top-1rem">
          <label class="color_dark">
            {{ $t('message.generateExcel.tenancy') }}
          </label>
          <draggable
            :list="tenancyFields"
            :disabled="true"
            class="row pr-2 pl-2">
            <template
              v-for="(item, itemIndex) in tenancyFields">
              <div
                :key="`item_${itemIndex}`"
                class="col-4 pl-0 pr-0">
                <coozzy-form-checkbox
                  v-model="item.selected"
                  :initial="item.selected"
                  class="borderFields pt-2 pb-2 pr-1 m-1">
                  <template class="font-size-0875rem">
                    {{ $t(`message.generateExcel.fields.${item.key}`) }}
                  </template>
                  <template v-if="template.compositionLinesOrColumns === 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'">
                    <font-awesome-icon
                      v-if="item.subgroup === 'tenant' "
                      icon="address-book"
                      class="fa" />
                    <font-awesome-icon
                      v-if="item.subgroup === 'subtenancy' "
                      icon="history"
                      class="fa" />
                    <img
                      v-if="item.subgroup === 'rentAdjustment' "
                      width="12.5px"
                      height="14px"
                      alt="rentAdjustment"
                      src="@/assets/icon/activate-tenancy-house.svg">
                  </template>
                </coozzy-form-checkbox>
              </div>
            </template>
          </draggable>
        </coozzy-card>

        <coozzy-card class="global-card padding-top-1rem">
          <label class="color_dark">
            {{ $t('message.generateExcel.tenantOwner') }}
          </label>
          <draggable
            :list="tenantOwnerFields"
            :disabled="true"
            class="row pr-2 pl-2">
            <template
              v-for="(item, itemIndex) in tenantOwnerFields">
              <div
                :key="`item_${itemIndex}`"
                class="col-4 pl-0 pr-0">
                <coozzy-form-checkbox
                  v-model="item.selected"
                  :initial="item.selected"
                  class="borderFields pt-2 pb-2 pr-1 m-1">
                  <template>
                    {{ $t(`message.generateExcel.fields.${item.key}`) }}
                  </template>
                  <template v-if="template.compositionLinesOrColumns === 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'">
                    <font-awesome-icon
                      v-if="item.subgroup === 'tenant' "
                      icon="address-book"
                      class="fa" />
                    <font-awesome-icon
                      v-if="item.subgroup === 'subtenancy' "
                      icon="history"
                      class="fa" />
                    <img
                      v-if="item.subgroup === 'rentAdjustment' "
                      width="12.5px"
                      height="14px"
                      alt="rentAdjustment"
                      src="@/assets/icon/activate-tenancy-house.svg">
                  </template>
                </coozzy-form-checkbox>
              </div>
            </template>
          </draggable>
        </coozzy-card>

        <coozzy-card class="global-card padding-top-1rem">
          <label class="color_dark">
            {{ $t('message.generateExcel.marketing') }}
          </label>
          <draggable
            :list="marketingFields"
            :disabled="true"
            class="row pr-2 pl-2">
            <template
              v-for="(item, itemIndex) in marketingFields">
              <div
                :key="`item_${itemIndex}`"
                class="col-4 pl-0 pr-0">
                <coozzy-form-checkbox
                  v-model="item.selected"
                  :initial="item.selected"
                  class="borderFields pt-2 pb-2 pr-1 m-1">
                  <template>
                    {{ $t(`message.generateExcel.fields.${item.key}`) }}
                  </template>
                  <template v-if="template.compositionLinesOrColumns === 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'">
                    <font-awesome-icon
                      v-if="item.subgroup === 'tenant' "
                      icon="address-book"
                      class="fa" />
                    <font-awesome-icon
                      v-if="item.subgroup === 'subtenancy' "
                      icon="history"
                      class="fa" />
                    <img
                      v-if="item.subgroup === 'rentAdjustment' "
                      width="12.5px"
                      height="14px"
                      alt="rentAdjustment"
                      src="@/assets/icon/activate-tenancy-house.svg">
                  </template>
                </coozzy-form-checkbox>
              </div>
            </template>
          </draggable>
        </coozzy-card>
        <coozzy-card class="global-card padding-top-1rem">
          <label class="color_dark">
            {{ $t('message.generateExcel.linkedContacts') }}
          </label>
          <draggable
            :list="linkedContactFields"
            :disabled="true"
            class="row pr-2 pl-2">
            <template
              v-for="(item, itemIndex) in linkedContactFields">
              <div
                :key="`item_${itemIndex}`"
                class="col-4 pl-0 pr-0">
                <coozzy-form-checkbox
                  v-model="item.selected"
                  :initial="item.selected"
                  class="borderFields pt-2 pb-2 pr-1 m-1">
                  <template>
                    {{ $t(`message.generateExcel.fields.${item.key}`) }}
                  </template>
                  <template v-if="compositionLinesOrColumns === 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'">
                    <font-awesome-icon
                      v-if="item.subgroup === 'tenant' "
                      icon="address-book"
                      class="fa" />
                    <font-awesome-icon
                      v-if="item.subgroup === 'subtenancy' "
                      icon="history"
                      class="fa" />
                    <img
                      v-if="item.subgroup === 'rentAdjustment' "
                      width="12.5px"
                      height="14px"
                      alt="rentAdjustment"
                      src="@/assets/icon/activate-tenancy-house.svg">
                  </template>
                </coozzy-form-checkbox>
              </div>
            </template>
          </draggable>
        </coozzy-card>
      </div>
      <div class="col mt-2 mb-1">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('template-generate-modal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="loading"
          @click="onSaveClicked">
          {{ templateEdit === null ? $t('message.generic.create') : $t('message.generic.form.save') }}
        </coozzy-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import ObjectApi from '../../misc/apis/ObjectApi'
import CoozzyFormInput from '../../framework/components/form/input/CoozzyFormInput'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import { user } from '@/mixins/user'
import { media } from '@/mixins/media'
import draggable from 'vuedraggable'
import { generateExcel } from '@/mixins/generateExcel'
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'

export default {
  name: 'TemplateExportModal',
  components: {
    CoozzyInfoCircleIcon,
    CoozzyFormCheckbox,
    CoozzyCard,
    CoozzyFormSelect,
    CoozzySpinner,
    CoozzyButton,
    CoozzyFormInput,
    draggable
  },
  mixins: [user, media, generateExcel],
  props: {
    templateEdit: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      file: null,
      drag: false,
      template: {
        language: this.setUserPreferredLanguage(),
        name: '',
        tenancyType: 'TENANCY_TYPE_ALL',
        sortingType: 'SORTING_TYPE_STANDARD',
        compositionType: 'COMPOSITION_TYPE_SEPARATELY',
        compositionLinesOrColumns: 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'
      },
      mediaUpload: null,
      selectedFields: [],
      propertyFields: [],
      buildingFields: [],
      objectFields: [],
      tenancyFields: [],
      tenantOwnerFields: [],
      marketingFields: [],
      linkedContactFields: [],
      selectAll: false,
      deselectAll: false
    }
  },
  computed: {
    getUserLang() {
      return this.$store.getters['user/getCurrentUser']['https://coozzy.ch/lang'].toUpperCase()
    }
  },
  watch: {
    selectAll: function(newVal) {
      if (newVal) {
        const values = this.propertyFields.concat(this.buildingFields).concat(this.objectFields).concat(this.tenancyFields).concat(this.tenantOwnerFields).concat(this.marketingFields).concat(this.linkedContactFields)
        this.selectedFields = this.selectedFields.concat(values.map(x => ({ key: x.key, selected: true, category: x.category, subgroup: x.subgroup })))
        this.eraseAvailableTables()
      }
    },
    deselectAll: function(newVal) {
      if (newVal) {
        this.selectedFields.forEach(element => {
          this.pushField(element)
        })
        this.selectedFields = []
      }
    },
    selectedFields: {
      handler() {
        if (this.selectedFields.filter(x => x.selected === false).length) {
          this.selectedFields.filter(x => x.selected === false).forEach(element => {
            this.pushField(element)
          })
          this.$nextTick(function () {
            this.selectedFields = this.selectedFields.filter(x => x.selected === true)
            this.changeCheckboxes()
          })
        } else {
          this.changeCheckboxes()
        }
      },
      deep: true
    },
    propertyFields: {
      handler() {
        if (this.propertyFields.filter(x => x.selected === true).length) {
          this.propertyFields.filter(x => x.selected === true).forEach(element => {
            this.selectedFields.push({ key: element.key, selected: true, category: element.category, subgroup: element.subgroup })
          })
          this.$nextTick(function () {
            this.propertyFields = this.propertyFields.filter(x => x.selected === false)
          })
        }
      },
      deep: true
    },
    buildingFields: {
      handler() {
        if (this.buildingFields.filter(x => x.selected === true).length) {
          this.buildingFields.filter(x => x.selected === true).forEach(element => {
            this.selectedFields.push({ key: element.key, selected: true, category: element.category, subgroup: element.subgroup })
          })
          this.$nextTick(function () {
            this.buildingFields = this.buildingFields.filter(x => x.selected === false)
          })
        }
      },
      deep: true
    },
    objectFields: {
      handler() {
        if (this.objectFields.filter(x => x.selected === true).length) {
          this.objectFields.filter(x => x.selected === true).forEach(element => {
            this.selectedFields.push({ key: element.key, selected: true, category: element.category, subgroup: element.subgroup })
          })
          this.$nextTick(function () {
            this.objectFields = this.objectFields.filter(x => x.selected === false)
          })
        }
      },
      deep: true
    },
    tenancyFields: {
      handler() {
        if (this.tenancyFields.filter(x => x.selected === true).length) {
          this.tenancyFields.filter(x => x.selected === true).forEach(element => {
            this.selectedFields.push({ key: element.key, selected: true, category: element.category, subgroup: element.subgroup })
          })
          this.$nextTick(function () {
            this.tenancyFields = this.tenancyFields.filter(x => x.selected === false)
          })
        }
      },
      deep: true
    },
    tenantOwnerFields: {
      handler() {
        if (this.tenantOwnerFields.filter(x => x.selected === true).length) {
          this.tenantOwnerFields.filter(x => x.selected === true).forEach(element => {
            this.selectedFields.push({ key: element.key, selected: true, category: element.category, subgroup: element.subgroup })
          })
          this.$nextTick(function () {
            this.tenantOwnerFields = this.tenantOwnerFields.filter(x => x.selected === false)
          })
        }
      },
      deep: true
    },
    marketingFields: {
      handler() {
        if (this.marketingFields.filter(x => x.selected === true).length) {
          this.marketingFields.filter(x => x.selected === true).forEach(element => {
            this.selectedFields.push({ key: element.key, selected: true, category: element.category, subgroup: element.subgroup })
          })
          this.$nextTick(function () {
            this.marketingFields = this.marketingFields.filter(x => x.selected === false)
          })
        }
      },
      deep: true
    },
    linkedContactFields: {
      handler() {
        if (this.linkedContactFields.filter(x => x.selected === true).length) {
          this.linkedContactFields.filter(x => x.selected === true).forEach(element => {
            this.selectedFields.push({ key: element.key, selected: true, category: element.category, subgroup: element.subgroup })
          })
          this.$nextTick(function () {
            this.linkedContactFields = this.linkedContactFields.filter(x => x.selected === false)
          })
        }
      },
      deep: true
    }
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'template-generate-modal') {
        if (this.templateEdit !== null) {
          this.template = JSON.parse(JSON.stringify(this.templateEdit))
          this.eraseAvailableTables()
          const selectedFields = []
          for (const field of this.template.fields) {
            if (this.defaultFields.some(x => x.name === field.name)) {
              selectedFields.push({ key: field.name, selected: true, category: this.defaultFields.find(x => x.name === field.name)?.category || field.category, subgroup: this.defaultFields.find(x => x.name === field.name)?.subgroup || field.subgroup })
            }
          }
          this.selectedFields = selectedFields

          this.defaultFields.filter(x => !this.selectedFields.find(y => y.key === x.name)).forEach(element => {
            this.pushField(element)
          })
        } else {
          this.template = {
            language: this.setUserPreferredLanguage(),
            name: '',
            tenancyType: 'TENANCY_TYPE_ALL',
            sortingType: 'SORTING_TYPE_STANDARD',
            compositionType: 'COMPOSITION_TYPE_SEPARATELY',
            compositionLinesOrColumns: 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'
          }
          this.eraseAvailableTables()
          this.selectedFields = []
          this.mountFields()
        }
      }
    })
  },
  methods: {
    sortFieldsByDefaultOrder(fields, category) {
      const indices = Object.fromEntries(
        this.defaultFields.filter(x => x.category === category).map(
          ({ name }, index) => [name, index]
        )
      )
      return fields.sort((a, b) => indices[a.key] - indices[b.key])
    },
    changeCheckboxes() {
      if (this.propertyFields.length || this.buildingFields.length || this.objectFields.length || this.tenancyFields.length || this.tenantOwnerFields.length || this.marketingFields.length || this.linkedContactFields.length) {
        if (this.$refs.selectAll_checkbox) {
          this.selectAll = false
          this.$refs.selectAll_checkbox.checked = false
        }
      } else {
        if (this.$refs.selectAll_checkbox) {
          this.selectAll = true
          this.$refs.selectAll_checkbox.checked = true
        }
      }
      this.$nextTick(function () {
        if (this.selectedFields.length) {
          if (this.$refs.deselectAll_checkbox) {
            this.deselectAll = false
            this.$refs.deselectAll_checkbox.checked = false
          }
        }
      })
    },
    eraseAvailableTables() {
      this.propertyFields = []
      this.buildingFields = []
      this.objectFields = []
      this.tenancyFields = []
      this.tenantOwnerFields = []
      this.marketingFields = []
      this.linkedContactFields = []
    },
    pushField(element) {
      const elm = { key: element.key || element.name, selected: false, category: element.category, subgroup: element.subgroup }
      switch (elm.category) {
        case 'property':
          this.propertyFields.push(elm)
          this.propertyFields = this.sortFieldsByDefaultOrder(this.propertyFields, 'property')
          break
        case 'building':
          this.buildingFields.push(elm)
          this.buildingFields = this.sortFieldsByDefaultOrder(this.buildingFields, 'building')
          break
        case 'object':
          this.objectFields.push(elm)
          this.objectFields = this.sortFieldsByDefaultOrder(this.objectFields, 'object')
          break
        case 'tenancy':
          this.tenancyFields.push(elm)
          this.tenancyFields = this.sortFieldsByDefaultOrder(this.tenancyFields, 'tenancy')
          break
        case 'tenantOwner':
          this.tenantOwnerFields.push(elm)
          this.tenantOwnerFields = this.sortFieldsByDefaultOrder(this.tenantOwnerFields, 'tenantOwner')
          break
        case 'marketing':
          this.marketingFields.push(elm)
          this.marketingFields = this.sortFieldsByDefaultOrder(this.marketingFields, 'marketing')
          break
        case 'linkedContact':
          this.linkedContactFields.push(elm)
          this.linkedContactFields = this.sortFieldsByDefaultOrder(this.linkedContactFields, 'linkedContact')
          break
        default:
          return ''
      }
    },
    mountFields() {
      this.defaultFields.filter(x => x.category === 'property').forEach(element => {
        const elm = { key: element.name, selected: false, category: element.category, subgroup: element.subgroup }
        this.propertyFields.push(elm)
      })
      this.defaultFields.filter(x => x.category === 'building').forEach(element => {
        const elm = { key: element.name, selected: false, category: element.category, subgroup: element.subgroup }
        this.buildingFields.push(elm)
      })
      this.defaultFields.filter(x => x.category === 'object').forEach(element => {
        const elm = { key: element.name, selected: false, category: element.category, subgroup: element.subgroup }
        this.objectFields.push(elm)
      })
      this.defaultFields.filter(x => x.category === 'tenancy').forEach(element => {
        const elm = { key: element.name, selected: false, category: element.category, subgroup: element.subgroup }
        this.tenancyFields.push(elm)
      })
      this.defaultFields.filter(x => x.category === 'tenantOwner').forEach(element => {
        const elm = { key: element.name, selected: false, category: element.category, subgroup: element.subgroup }
        this.tenantOwnerFields.push(elm)
      })
      this.defaultFields.filter(x => x.category === 'marketing').forEach(element => {
        const elm = { key: element.name, selected: false, category: element.category, subgroup: element.subgroup }
        this.marketingFields.push(elm)
      })
      this.defaultFields.filter(x => x.category === 'linkedContact').forEach(element => {
        const elm = { key: element.name, selected: false, category: element.category, subgroup: element.subgroup }
        this.linkedContactFields.push(elm)
      })
    },
    setUserPreferredLanguage() {
      const userPreferredLanguage = this.currentLanguage
      if (userPreferredLanguage === 'en') {
        return 'PREFERRED_LANGUAGE_ENGLISH'
      } else if (userPreferredLanguage === 'it') {
        return 'PREFERRED_LANGUAGE_ITALIAN'
      } else if (userPreferredLanguage === 'fr') {
        return 'PREFERRED_LANGUAGE_FRENCH'
      } else {
        return 'PREFERRED_LANGUAGE_GERMAN'
      }
    },
    onSaveClicked() {
      this.$v.template.$touch()
      if (!this.$v.template.$invalid) {
        this.loading = true
        this.saveContractTemplate()
      }
    },
    saveContractTemplate() {
      const fields = []
      const selectedFields = this.selectedFields.filter(x => x.selected === true)
      for (let i = 0; i < selectedFields.length; i++) {
        fields.push({ name: selectedFields[i].key, order: i })
      }
      if (!this.templateEdit) {
        ObjectApi.rentalIndexTemplateCreate(
          fields,
          this.template.language,
          this.template.name,
          this.accountId,
          this.template.tenancyType,
          this.template.sortingType,
          this.template.compositionType,
          this.template.compositionLinesOrColumns
        ).then(() => {
          this.$v.$reset()
          this.hide()
          Vue.toasted.show(this.$t('message.settings.template.templateSavedSuccessfully'), { type: 'success' })
          this.$emit('on-creation')
        }).catch(e => {
          this.loading = false
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.template'), { type: 'error' })
        }).finally(() => {
          this.loading = false
        })
      } else {
        ObjectApi.rentalIndexTemplateUpdate(
          fields,
          this.template.id,
          this.template.language,
          this.template.name,
          this.template.tenancyType,
          this.template.sortingType,
          this.template.compositionType,
          this.template.compositionLinesOrColumns
        ).then(() => {
          this.$v.$reset()
          this.hide()
          Vue.toasted.show(this.$t('message.settings.template.templateSavedSuccessfully'), { type: 'success' })
          this.$emit('on-creation')
        }).catch(e => {
          this.loading = false
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.template'), { type: 'error' })
        }).finally(() => {
          this.loading = false
        })
      }
    },
    show() {
      this.$refs['template-generate-modal'].show()
    },
    hide() {
      this.template = {
        language: this.setUserPreferredLanguage(),
        name: '',
        tenancyType: 'TENANCY_TYPE_ALL',
        sortingType: 'SORTING_TYPE_STANDARD',
        compositionType: 'COMPOSITION_TYPE_SEPARATELY',
        compositionLinesOrColumns: 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'
      }
      this.$refs['template-generate-modal'].hide()
    }
  },
  validations: {
    template: {
      name: {
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped>
label {
  margin-bottom: 0;
}

 .drag-zone  .dragover-mask{
    display: block !important;
    transition: z-index 0s step-end;
    z-index: 2500;;
  }
  .dragover-mask{
    display: none;
    background: rgba(255,255,255,0.3);
    border: 3px dashed $color-primary;
    border-radius: 5px;
    bottom: 0;
    left: 0;
    margin: 2px;
    position: absolute;
    right: 0;
    top: 0;
    transition: z-index .2s step-end;
    z-index: -1;
  }

.media-block {
  max-width: 33%;
  margin-right: 0.5%;

  .uploaded-file {
    background-position: center center;
    background-size: cover;
    min-height: 7em;
  }

  .file-block {
    min-height: 7em;
    background: $color-bg-grey-dark;

    a {
      color: $color-text-grey;
      overflow-wrap: break-word;
    }
  }
}

.borderFields {
  padding-left: 2rem;
  border: 0.55px solid rgba(0,0,0,0.1);
}

:deep(#template-generate-modal .modal-dialog) {
  max-width: 1000px !important;
}
.color_dark {
  color: #333;
}
.text-lg {
  font-size: 1.1rem;
}
.padding-top-1rem .card-body {
  padding-top: 1rem;
}
:deep(.global-card .custom-checkbox label) {
  font-size: 0.875rem;
  font-weight: 400;
  color: #333;
}

</style>
