const obj = {
  methods: {
    getObjectRoom(o) {
      return o.characteristics && o.characteristics.rooms ? o.characteristics.rooms : -1
    },
    getObjectFloor(o) {
      return o.characteristics ? o.characteristics.floor : -10000
    },
    getStartOfDayTimestamp() {
      // Because of timezone offset we need to so some trickery here. This only works for timezones with a positive offset
      const now = new Date()
      const localDay = now.getDate()
      now.setUTCHours(0, 0, 0, 0)
      let startOfDayTimestamp
      if (now.getDate() !== localDay) {
        startOfDayTimestamp = Math.trunc((now.getTime() + 86400000) / 1000)
      } else {
        startOfDayTimestamp = Math.trunc(now.getTime() / 1000)
      }

      return startOfDayTimestamp
    },
    getLA(object) {
      if (object.category === 'house' || object.category === 'apartment') {
        return object.livingArea
      } else {
        return object.effectiveArea
      }
    },
    getStatus(obj) {
      if (!obj) {
        return 'n/a'
      }

      // We got an algolia object entry
      if (obj.status) {
        return obj.status.toLowerCase()
      } else {
        if (obj.inactiveBy && new Date(this.$options.filters.objectToDateInput(obj.inactiveBy)) <= new Date()) {
          return 'inactive'
        }
        if (obj.condominiumOwnership) {
          const startOfDayTimestamp = this.getStartOfDayTimestamp()

          const currentCondominium = this.getFilteredCondominium(obj.id)
            .sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
            .find(t => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 <= startOfDayTimestamp && (!t.endingAt || (new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 < 0 || new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 >= startOfDayTimestamp)))

          if (currentCondominium) {
            if (currentCondominium.status) {
              return currentCondominium.status.replace('CONDOMINIUM_STATUS_', '').toLowerCase()
            } else {
              return 'undefined'
            }
          } else {
            return 'undefined'
          }
        } else {
          // We got an object from our backend
          const startOfDayTimestamp = this.getStartOfDayTimestamp()

          const currentTenancy = this.getFilteredTenancies(obj.id)
            .sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
            .find(t => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 <= startOfDayTimestamp && (!t.endingAt || (new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 < 0 || new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 >= startOfDayTimestamp)))

          if (currentTenancy) {
            if (currentTenancy.vacancy === true) {
              if (currentTenancy.activated) {
                return 'vacant'
              } else {
                return 'vacant_not_activated'
              }
            } else if (currentTenancy.contract.type === 'LIMITED' && currentTenancy.activated === true) {
              return 'temporary'
            } else if (currentTenancy.status) {
              return currentTenancy.status.replace('TENANCY_STATUS_', '').toLowerCase()
            } else {
              return 'undefined'
            }
          } else {
            return 'undefined'
          }
        }
      }
    },
    getFutureStatus(obj, fromObject = false) {
      if (!obj) {
        return undefined
      }
      if (this.getStatus(obj) === 'not_activated') {
        return undefined
      }
      // We got an algolia object entry
      if (obj.status) {
        if (fromObject && obj.futureRentalStatus === 'future') {
          return 'futureRented'
        }
        return obj.futureRentalStatus
      } else {
        if (obj.condominiumOwnership) {
          const currentCondominium = this.getCurrentCondominium(obj.id)
          const startOfDayTimestamp = this.getStartOfDayTimestamp()

          const futureCondominium = this.getFilteredCondominium(obj.id)
            .sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? 1 : -1)
            .find(t => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 > startOfDayTimestamp)

          if (futureCondominium) {
            if (futureCondominium.activated === true) {
              return 'future'
            } else {
              return 'reserved'
            }
          } else {
            if (currentCondominium && currentCondominium.status !== 'CONDOMINIUM_STATUS_CURRENT' && currentCondominium.status !== 'CONDOMINIUM_STATUS_NOT_ACTIVATED') {
              return 'undefined'
            }
          }
        } else {
          // We got an object from our backend
          const startOfDayTimestamp = this.getStartOfDayTimestamp()
          const currentTenancy = this.getFilteredTenancies(obj.id)
            .sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
            .find(t => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 <= startOfDayTimestamp && (!t.endingAt || (new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 < 0 || new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 >= startOfDayTimestamp)))

          const futureTenancy = this.getFilteredTenancies(obj.id)
            .sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? 1 : -1)
            .find(t => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 > startOfDayTimestamp)
          if (futureTenancy) {
            if (futureTenancy.vacancy === true) {
              return 'vacant'
            } else if (futureTenancy.activated === true) {
              if (fromObject) {
                return 'futureRented'
              }
              return 'future'
            } else {
              return 'reserved'
            }
          } else {
            if (currentTenancy && currentTenancy.status !== 'TENANCY_STATUS_CURRENT' && currentTenancy.status !== 'TENANCY_STATUS_NOT_ACTIVATED') {
              return 'undefined'
            }
          }
        }
      }
      return undefined
    },
    getFutureStartingAt(obj) {
      if (!obj) {
        return undefined
      }

      if (obj.futureTenancy) {
        return obj.futureTenancy.startingAt
      } else {
        // We got an object from our backend
        const startOfDayTimestamp = this.getStartOfDayTimestamp()

        const futureTenancy = this.getFilteredTenancies(obj.id)
          .sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? 1 : -1)
          .find(t => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 > startOfDayTimestamp)
        if (futureTenancy) {
          return futureTenancy.startingAt
        }
      }

      return undefined
    },
    getUnjustifiedStatus(obj) {
      if (!obj) {
        return undefined
      }

      if (obj?.tenancy?.unjustifiedUseFrom) {
        return { from: obj.tenancy.unjustifiedUseFrom, until: obj.tenancy.unjustifiedUseUntil }
      } else {
        // We got an object from our backend
        const startOfDayTimestamp = this.getStartOfDayTimestamp()

        const currentTenancy = this.getFilteredTenancies(obj.id)
          .sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
          .find(t => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 <= startOfDayTimestamp && (!t.endingAt || (new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 < 0 || new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 >= startOfDayTimestamp)))

        if (currentTenancy) {
          if (currentTenancy.vacancy === false && currentTenancy.unjustifiedUse && new Date(this.$options.filters.objectToDateInput(currentTenancy.unjustifiedUse?.from)).getTime() >= startOfDayTimestamp) {
            return currentTenancy.unjustifiedUse
          } else {
            return undefined
          }
        } else {
          return undefined
        }
      }
    },
    getMarketingStatus(obj) {
      if (!obj) {
        return 'no'
      }

      // We got an algolia object entry
      if (obj.status) {
        return obj.inMarketingStatus
      } else {
        if ((obj.marketingStatus.markedForMarketingAt > 0 || (obj.marketingStatus.clearFromMarketingAt > 0 && obj.marketingStatus.clearFromMarketingAt > Math.trunc(Date.now() / 1000)))) {
          if (obj.marketingStatus.publications.length > 0 && obj.marketingStatus.publications.filter(p => parseInt(p.unPublished) === 0).length > 0) {
            return 'online'
          } else {
            return 'offline'
          }
        }

        return 'no'
      }
    },
    getFullStatusColorClass(obj) {
      return this.getStatus(obj)
    },
    getFullStatusText(obj, fromObject = false) {
      if (!obj) {
        return 'n/a'
      }
      if (obj.inactiveBy && new Date(this.$options.filters.objectToDateInput(obj.inactiveBy)) <= new Date()) {
        return this.$t('message.onBoarding.buildings.objects.status.inactive')
      }

      if (obj.inactive) {
        return this.$t('message.onBoarding.buildings.objects.status.' + obj.status)
      }

      const status = this.getStatus(obj)
      const marketingStatus = this.getMarketingStatus(obj)
      const futureStatus = this.getFutureStatus(obj, fromObject)
      const futureStartingAt = this.getFutureStartingAt(obj)
      const UnjustifiedObj = this.getUnjustifiedStatus(obj)
      return this.$t('message.onBoarding.buildings.objects.status.' + status) +
        (UnjustifiedObj && UnjustifiedObj.until !== null && UnjustifiedObj.until !== undefined
? '<br>(' + this.$t('message.enterTermination.unjustifiedUse.statusWithEnd', {
          startDate: this.$options.filters.objectToDate(UnjustifiedObj.from),
          endDate: this.$options.filters.objectToDate(UnjustifiedObj.until)
        }) + ')'
: UnjustifiedObj && (UnjustifiedObj.until === null || UnjustifiedObj.until === undefined)
? '<br>(' + this.$t('message.enterTermination.unjustifiedUse.statusWithoutEnd', {
          startDate: this.$options.filters.objectToDate(UnjustifiedObj.from)
        }) + ')'
: '') +
        (marketingStatus !== 'no' ? '<br>' + this.$t('message.onBoarding.buildings.objects.inMarketingStatus.' + marketingStatus) : '') +
        (futureStatus ? '<br>' + this.$t('message.onBoarding.buildings.objects.futureStatus.' + futureStatus) : '') +
        (futureStatus === 'vacant' && futureStartingAt ? ` (${this.$options.filters.objectToDate(futureStartingAt)})` : '')
    },
    calculateMarketingStatus(marketingStatus) {
      const status = {
        label: '-',
        date: '',
        dateFuture: '',
        color: ''
      }
      if (marketingStatus) {
        const pub = marketingStatus.publications

        let publishedEntry = null
        if (pub && pub.length > 0) {
          publishedEntry = marketingStatus.publications.find(element => element.unPublished <= 0 && element.published > 0 && element.published <= Math.trunc(Date.now() / 1000))
        }
        if (marketingStatus.status?.includes('rented')) {
          status.label = this.$t('message.marketingObjects.status.rented')
          status.color = 'text-blue'
        } else if (marketingStatus.newInMarketing === true) {
          status.label = this.$t('message.marketingObjects.status.new')
          status.date = this.$options.filters.timestampToDate(marketingStatus.markedForMarketingAt)
        } else if (marketingStatus.published || publishedEntry) {
          status.label = this.$t('message.marketingObjects.status.online')
          status.color = 'text-green'

          if (marketingStatus.publishedAt) {
            status.date = this.$options.filters.timestampToDate(marketingStatus.publishedAt)
          } else {
            status.date = this.$options.filters.timestampToDate(publishedEntry.published)
          }
        } else if (pub && pub.length > 0 && pub[pub.length - 1].unPublished) {
          if (parseInt(pub[pub.length - 1]?.unPublished) * 1000 > new Date().getTime() &&
            parseInt(pub[pub.length - 1]?.published) * 1000 <= new Date().getTime()) {
            // The object is currently published (online)
            status.label = this.$t('message.marketingObjects.status.online')
            status.color = 'text-green'
            status.date = this.$options.filters.timestampToDate(pub[pub.length - 1]?.published)
          } else {
            // The object is offline or scheduled to be published in the future
            status.label = this.$t('message.marketingObjects.status.offline')
            status.color = 'text-orange'
            if (parseInt(pub[pub.length - 1]?.published) * 1000 > new Date().getTime()) {
              status.dateFuture = this.$options.filters.timestampToDateTime(pub[pub.length - 1]?.published)
            }
            if (pub[pub.length - 1]?.unPublished > 0) {
              status.date = this.$options.filters.timestampToDate(pub[pub.length - 1].unPublished)
            }
          }
        } else {
          status.label = this.$t('message.marketingObjects.status.offline')
          status.color = 'text-orange'
          if (marketingStatus.unPublishedAt && marketingStatus.unPublishedAt > 0) {
            status.date = this.$options.filters.timestampToDate(marketingStatus.unPublishedAt)
          } else if (marketingStatus.markedAsSeenAt && marketingStatus.markedAsSeenAt > 0) {
            status.date = this.$options.filters.timestampToDate(marketingStatus.markedAsSeenAt)
          } else if (marketingStatus.markedForMarketingAt && marketingStatus.markedForMarketingAt > 0) {
            status.date = this.$options.filters.timestampToDate(marketingStatus.markedForMarketingAt)
          }
        }
      }
      return status
    },
    sortObjectsByPriority(array, getObjectFunc = null) {
      const compareCategories = (objA, objB) => {
        return objA.category.localeCompare(objB.category)
      }

      const compareInactiveStatus = (objA, objB) => {
        if ((objA.inactiveBy === null && objB.inactiveBy === null) ||
            (objA.inactiveBy !== null && objB.inactiveBy !== null)) {
          return 0
        }
        return objA.inactiveBy === null ? -1 : 1
      }

      const compareCharacteristics = (objA, objB) => {
        const floorA = objA.characteristics?.floor
        const floorB = objB.characteristics?.floor

        if (floorA === -10000 || floorB === -10000) {
          return floorA === -10000 ? 1 : -1
        }

        return floorA - floorB
      }

      const compareNames = (objA, objB) => {
        const nameCompare = objA.name.localeCompare(objB.name)
        if (nameCompare !== 0) return nameCompare
        return objA.numericId.localeCompare(objB.numericId)
      }

      const compareObjNumber = (objA, objB) => {
        const objNumberA = objA.number
        const objNumberB = objB.number

        const isNumericA = /^\d+$/.test(objNumberA)
        const isNumericB = /^\d+$/.test(objNumberB)

        if (isNumericA && isNumericB) {
          return parseInt(objNumberA) - parseInt(objNumberB)
        } else if (isNumericA) {
          return -1
        } else if (isNumericB) {
          return 1
        } else if (objNumberA && objNumberB) {
          return objNumberA.localeCompare(objNumberB)
        } else if (objNumberA) {
          return -1
        } else if (objNumberB) {
          return 1
        }

        return 0
      }

      return array.sort((a, b) => {
        const objA = getObjectFunc ? getObjectFunc(a.objectId) : a
        const objB = getObjectFunc ? getObjectFunc(b.objectId) : b

        const sortingFunctions = [
          compareObjNumber,
          compareCategories,
          compareInactiveStatus,
          compareCharacteristics,
          compareNames
        ]

        for (const compareFunc of sortingFunctions) {
          const result = compareFunc(objA, objB)
          if (result !== 0) return result
        }

        return 0
      })
    }
  }
}

export { obj }
