<template>
  <section :class="[readOnly ? 'is-disabled' : '']">
    <coozzy-card class="global-card no-top-border">
      <div class="row justify-content-between pb-1">
        <div class="col-12 col-md">
          <h5 class="pt-1 pb-1">
            {{ countTenancy }} {{ $t('message.onBoarding.buildings.objects.tenancies.tenants.title') }}
          </h5>
        </div>
        <div
          v-if="isFiltered"
          class="col-12 col-md-2">
          <coozzy-button
            class="w-100 sort"
            @click="initFilter()">
            <coozzy-filter-icon /> {{ $t('message.generic.clearFilters') }}
          </coozzy-button>
        </div>
      </div>
      <div class="row justify-content-between align-items-center my-2">
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-form-input
            ref="searchText"
            v-model="searchText"
            :placeholder="$t('message.generic.search')"
            @keyup="searchInputText()" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchStatus"
            class="filter-inputs"
            :options="sortedFilterValues(searchStatusOption, customLabelStatus , 'value')"
            :multiple="true"
            :placeholder="$t('message.generic.currentStatus')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelStatus"
            :preselect-first="false"
            :taggable="false"
            :track-by="'value'"
            @select="filterTenantList"
            @remove="filterTenantList" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchCategories"
            class="filter-inputs"
            :options="sortedFilterValues(categoryFilter, customLabelCategory , 'value')"
            :multiple="true"
            :placeholder="$t('message.onBoarding.buildings.objects.category.title')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelCategory"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterTenantList"
            @remove="filterTenantList" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchFloor"
            class="filter-inputs"
            :options="floorFilter"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.floor')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelFloor"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterTenantList"
            @remove="filterTenantList" />
        </div>
        <div class="col-12 col-md-2">
          <coozzy-multiselect
            v-model="searchRooms"
            class="filter-inputs"
            :options="roomsFilter"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.rooms')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabel"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterTenantList"
            @remove="filterTenantList" />
        </div>
        <div class="col-12 col-md-2">
          <coozzy-multiselect
            v-model="searchVacancy"
            class="filter-inputs"
            :options="vacancyFilters"
            :multiple="false"
            :placeholder="$t('message.tableColumnHeadings.tenant')"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelVacancy"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterTenantList"
            @remove="filterTenantList" />
        </div>
      </div>
      <div class="row align-items-center my-2">
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-assignee-multiselect
            v-model="searchAssignee"
            class="filter-inputs"
            :options="sortedFilterValues(assigneeFilter, customLabelAssignee , 'value')"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.assignee')"
            :close-on-select="false"
            :clear-on-select="false"
            :custom-label="customLabelAssignee"
            :preserve-search="true"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterTenantList"
            @remove="filterTenantList" />
        </div>
        <div class="col-12 col-md-2 mt-0 mt-md-1">
          <div
            class="date-picker-container">
            <date-picker
              id="date-picker-input2"
              v-model="searchStartingAtFilter"
              popup-class="date-picker-calendar2"
              value-type="format"
              format="M/YYYY"
              title-format="M"
              :placeholder="$t('message.onBoarding.buildings.objects.tenancies.startAt')"
              :multiple="true"
              :clearable="true"
              :editable="false"
              range-separator=", "
              type="month"
              :lang="getLang"
              @open="resizeStartingDatePicker"
              @input="filterTenantList"
              @clear="filterTenantList" />
          </div>
        </div>
        <div class="col-12 col-md-2 mt-0 mt-md-1">
          <div
            class="date-picker-container">
            <date-picker
              id="date-picker-input"
              v-model="searchEndingAtFilter"
              popup-class="date-picker-calendar"
              value-type="format"
              format="M/YYYY"
              title-format="M"
              :placeholder="$t('message.onBoarding.buildings.objects.tenancies.endAt')"
              :multiple="true"
              :clearable="true"
              :editable="false"
              range-separator=", "
              type="month"
              :lang="getLang"
              @open="resizeDatePicker"
              @input="filterTenantList"
              @clear="filterTenantList" />
          </div>
        </div>
        <div
          class="col-12 col-md-2  mt-0 mt-md-1">
          <coozzy-multiselect
            v-model="searchPreferredCommunicationChannel"
            class="filter-inputs"
            :options="filterPreferredCommunicationChannel"
            :multiple="true"
            :placeholder="$t('message.contact.preferredCommunicationChannel')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelPreferredCommunicationChannel"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterTenantList"
            @remove="filterTenantList" />
        </div>
      </div>
      <div
        id="rowConfig"
        class="row mb-2 align-items-center">
        <div class="col-md-2 col-sm-6 col-12">
          <coozzy-dropdown
            design="green"
            size="sm"
            class="w-100 sort"
            :text="selectedEntriesText">
            <coozzy-dropdown-item
              v-if="isTicketingEnabled && (isEditor || isTicketEditor) && (isOwnerModule || isAssetModule)"
              :disabled="selectedEntries.length === 0"
              @click="createTicket('TICKET_TYPE_TASK')">
              {{ $t('message.ticketOverview.createTicket') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown
              v-if="!isOwnerModule && !isAssetModule && isTicketingEnabled && (isEditor || isTicketEditor)"
              design="transparent"
              size="sm"
              class="w-100 ticket-dropdown-bulk-action"
              :class="selectedEntries.length > 0 ? 'enabled' : ''"
              :disabled="true"
              :text="$t('message.ticketOverview.createTicket')"
              dropright
              @click.prevent>
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="navigateToCreateOrder()">
                {{ $t('message.ticketOverview.createOrder') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="createTicket('TICKET_TYPE_TASK')">
                {{ $t('message.ticketOverview.createTask') }}
              </coozzy-dropdown-item>
            </coozzy-dropdown>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="showActivity()">
              {{ $t('message.contact.activity.create') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="$refs['reminder-creation'].show()">
              {{ $t('message.calendar.reminder.create') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="serialEmail()">
              {{ selectedEntries.length === 1 ? $t('message.generic.oneEmail') : $t('message.generic.serialEmail') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="serialLetter()">
              {{ selectedEntries.length === 1 ? $t('message.generic.oneSerialLetter') : $t('message.generic.serialLetter') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-space-divider v-if="isEditor" />
            <div
              v-if="isEditor"
              class="row m-0"
              :class="selectedEntries.length === 0 || notAllStatusNotActivated ? 'coozzyDropdownItemTooltip' : ''">
              <coozzy-dropdown-item
                v-if="isAdminModule || isOwnerModule || isAssetModule || isAccountingModule"
                :disabled="selectedEntries.length === 0 || notAllStatusNotActivated"
                @click="activateTenancyClicked()">
                {{ $t('message.manageBuilding.bulkAction.activateTenancy') }}
              </coozzy-dropdown-item>
              <div class="pl-0">
                <coozzy-info-circle-icon
                  v-if="notAllStatusNotActivated"
                  v-b-tooltip.hover.html="$t('message.deleteErrors.activateTenancyTooltip')"
                  class="mt-2" />
              </div>
            </div>
            <div
              v-if="isEditor && (isAdminModule || isAccountingModule)"
              class="row m-0"
              :class="selectedEntries.length === 0 || notAllStatusActivated ? 'coozzyDropdownItemTooltip' : ''">
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0 || notAllStatusActivated"
                @click="$refs.closeTenancyModal.show()">
                {{ $t('message.manageBuilding.bulkAction.closeTenancy') }}
              </coozzy-dropdown-item>
              <div class="pl-0">
                <coozzy-info-circle-icon
                  v-if="notAllStatusActivated"
                  v-b-tooltip.hover.html="$t('message.deleteErrors.closeTenancyTooltip')"
                  class="mt-2" />
              </div>
            </div>
            <coozzy-dropdown-space-divider v-if="isEditor" />
            <div
              v-if="isEditor"
              class="row m-0"
              :class="selectedEntries.length === 0 || isTenanciesActivated > 0 ? 'coozzyDropdownItemTooltip' : ''">
              <coozzy-dropdown-item
                class="col pr-0 pl-0"
                :disabled="selectedEntries.length === 0 || isTenanciesActivated > 0"
                @click="selectedEntries.length !== 0 ? openConfirmationModal() : ''">
                {{ $t('message.generic.delete') }}
              </coozzy-dropdown-item>
              <div class="pl-0">
                <coozzy-info-circle-icon
                  v-if="isTenanciesActivated > 0"
                  v-b-tooltip.hover.html="$t('message.deleteErrors.deleteActiveTenancyTooltipMsg')"
                  class="mt-2" />
              </div>
            </div>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2 col-sm-6 col-12">
          <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :initial="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              name="selectAllEntries"
              button>
              {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: nbrTotalOfHits }) : $t('message.generic.selectHiddenEntries', { amount: nbrTotalOfHits }) }}
            </coozzy-form-checkbox>
          </template>
        </div>
        <div
          v-if="isCompanyAdmin === true"
          class="offset-md-5 col-md-1 d-flex justify-content-end align-items-center">
          <div
            @click="openAssembleListModal()">
            <coozzy-filters-icon
              v-b-tooltip="{container: '#rowConfig', title: $t('message.generic.tooltipAssembleList')}" />
          </div>
        </div>
        <div
          class="col-md-2 col-sm-6 col-12 mt-2 mt-sm-0"
          :class="isCompanyAdmin !== true ? 'offset-md-8' : ''">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries float-right">
            <option value="5">
              5 {{ $t('message.generic.entries') }}
            </option>
            <option value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option value="15">
              15 {{ $t('message.generic.entries') }}
            </option>
            <option value="20">
              20 {{ $t('message.generic.entries') }}
            </option>
            <option value="all">
              {{ $t('message.manageInterestedParties.filter.allEntries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <b-table
        ref="tenantsTable"
        bordered
        hover
        responsive="true"
        stacked="md"
        selectable
        select-mode="single"
        foot-clone
        :fields="fields"
        :table-class="elementSelected !== null && elementSelected !== '' ? 'b-table-selecting' : ''"
        :items="tenantList"
        class="overflow-auto shadow"
        @row-clicked="changeSelectedTenant"
        @row-middle-clicked="onMiddlelicked">
        <template #head(checkbox)>
          <coozzy-form-checkbox
            ref="header_checkbox"
            @change="(value) => { handleCheckboxesInsideTable(value) }" />
        </template>
        <template #head(currentTenant)>
          {{ $t('message.onBoarding.buildings.objects.tenancies.tenants.current') }}
        </template>
        <template #head(subtenant)>
          {{ $t('message.tableColumnHeadings.subtenant') }}
        </template>
        <template #head(status)>
          {{ $t('message.tableColumnHeadings.status') }}
        </template>
        <template #head(objects)>
          {{ $t('message.tableColumnHeadings.objects') }}
        </template>
        <template #head(contractNumber)>
          {{ $t('message.tableColumnHeadings.contractNumber') }}
        </template>
        <template #head(tenancieObjectId)>
          {{ $t('message.tableColumnHeadings.tenancieObjectId') }}
        </template>
        <template #head(tenanciePartner)>
          {{ $t('message.tableColumnHeadings.tenanciePartner') }}
        </template>
        <template #head(type)>
          {{ $t('message.tableColumnHeadings.type') }}
        </template>
        <template #head(floor)>
          {{ $t('message.tableColumnHeadings.floor') }}
        </template>
        <template #head(rooms)>
          {{ $t('message.tableColumnHeadings.rooms') }}
        </template>
        <template #head(livingArea)>
          {{ $t('message.tableColumnHeadings.area') }}
        </template>
        <template #head(netRent)>
          {{ $t('message.onBoarding.buildings.objects.pricing.coldRent') }}
        </template>
        <template #head(grossRent)>
          {{ $t('message.onBoarding.buildings.objects.pricing.totalRent') }}
        </template>
        <template #head(startAt)>
          {{ $t('message.onBoarding.buildings.objects.tenancies.startAt') }}
        </template>
        <template #head(endAt)>
          {{ $t('message.onBoarding.buildings.objects.tenancies.endAt') }}
        </template>
        <template #head(assignee)>
          {{ $t('message.tableColumnHeadings.assignee') }}
        </template>
        <template #head(m2PerYear)>
          {{ $t('message.tableColumnHeadings.m2PerYear') }}
        </template>
        <template #head(debitUntil)>
          {{ $t('message.tableColumnHeadings.debitUntil') }}
        </template>
        <template #head(marketNetRent)>
          {{ $t('message.tableColumnHeadings.marketNetRent') }}
        </template>
        <template #head(targetNetRent)>
          {{ $t('message.tableColumnHeadings.targetNetRent') }}
        </template>
        <template #head(marketGrossRent)>
          {{ $t('message.tableColumnHeadings.marketGrossRent') }}
        </template>
        <template #head(targetGrossRent)>
          {{ $t('message.tableColumnHeadings.targetGrossRent') }}
        </template>
        <template #head(subCategory)>
          {{ $t('message.tableColumnHeadings.subCategory') }}
        </template>
        <template #head(HeatingAdditionalCosts)>
          {{ $t('message.tableColumnHeadings.HeatingAdditionalCosts') }}
        </template>
        <!-- Table content -->
        <template
          #cell(checkbox)="data">
          <!-- data needs to be used in order to work properly -->
          <coozzy-form-checkbox
            :id="'check_' + data.item.id"
            @change="(value) => { itemSelected(data.item, value) }" />
        </template>
        <template
          #cell(currentTenant)="data">
          <div v-if="data.item.vacancy === true">
            {{ $t('message.onBoarding.buildings.objects.tenancies.vacancy') }}
          </div>
          <div v-else-if="getSortedContactIds(data.item.tenant).length > 0">
            <router-link
              v-for="(contactId, tenantIndex) in getSortedContactIds(data.item.tenant)"
              :key="tenantIndex"
              :to="redirectToDetailPage(contactId)">
              {{ tenantIndex === 0 ? getContactName(contactId) : ', ' + getContactName(contactId) }}
            </router-link>
          </div>
          <span v-else>-</span>
        </template>
        <template
          #cell(subtenant)="data">
          <div v-if="data.item.subTenants.length > 0">
            <template
              v-for="(subtenant, subtenantIndex) in data.item.subTenants">
              <template
                v-if="subtenant.contactIds.length > 0">
                <router-link
                  v-for="(contactId, tenantIndex) in subtenant.contactIds"
                  :key="Math.random() + '_' + tenantIndex"
                  :to="redirectToDetailPage(contactId)">
                  {{ tenantIndex === 0 ? getContactName(contactId) : ', ' + getContactName(contactId) }}
                  <span v-if="tenantIndex === subtenant.contactIds.length - 1 && subtenantIndex < data.item.subTenants.length - 1">,</span>
                </router-link>
              </template>
            </template>
          </div>
          <span v-else>-</span>
        </template>

        <template #cell(debitUntil)="data">
          <template v-if="getObject(data.item.objectId).debitUntil">
            {{ getObject(data.item.objectId).debitUntil | objectToDate }}
          </template>
          <template v-else>
            -
          </template>
        </template>
        <template
          #cell(status)="data">
          <template v-if="data.item.closed === true">
            <div
              class="color-box mr-2 mt-2 closed" />
            {{ $t('message.onBoarding.buildings.objects.tenancies.status.closed') }}
            {{ getUnjustifiedDate(data.item.unjustifiedUse) }}
          </template>
          <template v-else-if="data.item.activated === false">
            <div
              class="color-box mr-2 mt-2 not_activated" />
            {{ $t('message.onBoarding.buildings.objects.tenancies.status.not_activated') }}
            {{ getUnjustifiedDate(data.item.unjustifiedUse) }}
          </template>
          <template v-else-if="new Date($options.filters.objectToDateInput(data.item.startingAt)).getTime() > (new Date()).getTime()">
            <div
              class="color-box mr-2 mt-2 future" />
            {{ $t('message.onBoarding.buildings.objects.tenancies.status.future') }}
            {{ getUnjustifiedDate(data.item.unjustifiedUse) }}
          </template>
          <template v-else-if="data.item.endingAt && new Date($options.filters.objectToDateInput(data.item.endingAt)).getTime() > (new Date()).getTime() && new Date($options.filters.objectToDateInput(data.item.startingAt)).getTime() <= (new Date()).getTime() && data.item.contract.type === 'LIMITED'">
            <div
              class="color-box mr-2 mt-2 temporary" />
            {{ $t('message.onBoarding.buildings.objects.tenancies.status.current_temporary') }}
            {{ getUnjustifiedDate(data.item.unjustifiedUse) }}
          </template>
          <template v-else-if="data.item.endingAt && new Date($options.filters.objectToDateInput(data.item.endingAt)).getTime() > (new Date()).getTime() && new Date($options.filters.objectToDateInput(data.item.startingAt)).getTime() <= (new Date()).getTime()">
            <div
              class="color-box mr-2 mt-2 terminated" />
            {{ $t('message.onBoarding.buildings.objects.tenancies.status.current_terminated') }}
            {{ getUnjustifiedDate(data.item.unjustifiedUse) }}
          </template>
          <template v-else-if="data.item.endingAt && data.item.endingAt.year > 0">
            <div
              class="color-box mr-2 mt-2 terminated" />
            {{ $t('message.onBoarding.buildings.objects.tenancies.status.terminated') }}
            {{ getUnjustifiedDate(data.item.unjustifiedUse) }}
          </template>
          <template v-else-if="!data.item.endingAt || data.item.endingAt <= 0">
            <div
              class="color-box mr-2 mt-2 current" />
            {{ $t('message.onBoarding.buildings.objects.tenancies.status.current') }}
            {{ getUnjustifiedDate(data.item.unjustifiedUse) }}
          </template>
          <template v-else>
            <div
              class="color-box mr-2 mt-2 future" />
            {{ $t('message.onBoarding.buildings.objects.tenancies.status.future') }}
            {{ getUnjustifiedDate(data.item.unjustifiedUse) }}
          </template>
        </template>
        <template #cell(contractNumber)="data">
          <div
            class="color-box mr-2" />
          {{ data.item.numericId }}
        </template>
        <template #cell(objects)="data">
          <div
            class="color-box mr-2" />
          {{ getObject(data.item.objectId).name | displayOptionalValue }}
        </template>
        <template
          #cell(type)="data">
          <template v-if="getObject(data.item.objectId).category !== 'UNDEFINED_CATEGORY'">
            {{ $t('message.onBoarding.buildings.objects.category.' + getObject(data.item.objectId).category.toLowerCase()) }}
          </template>
          <template v-else>
            -
          </template>
        </template>
        <template #cell(tenancieObjectId)="data">
          <span
            v-if="getObject(data.item.objectId)">
            {{ getObject(data.item.objectId).numericId }}
          </span>
          <span v-else>-</span>
        </template>
        <template #cell(tenanciePartner)="data">
          <span class="d-block">
            <img
              height="20px"
              src="/img/propbase-icon.png"
              alt="prop-base">
            <template v-if="ownerAccount && ownerAccount.company">
              {{ ownerAccount.company.name }}
            </template>
            <template v-if="ownerAccount && ownerAccount.individual">
              {{ ownerAccount.individual.firstName.substr(0, 1) }}. {{ ownerAccount.individual.lastName }}
            </template>
          </span>
          <span
            v-if="!$route.path.startsWith('/asset') && getNetworkPartners(data.item.objectId).length > 0 && getNetworkPartners(data.item.objectId).find(element => element.scope ==='ASSET_SCOPE')"
            class="d-block">
            <img
              height="20px"
              src="/img/assetbase-icon.png"
              alt="asset-base">
            <template v-if="getNetworkPartners(data.item.objectId).find(element => element.scope ==='ASSET_SCOPE').company">
              {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='ASSET_SCOPE').company.name }}
            </template>
            <template v-if="getNetworkPartners(data.item.objectId).find(element => element.scope ==='ASSET_SCOPE').individual">
              {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='ASSET_SCOPE').individual.firstName.substr(0, 1) }}. {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='ASSET_SCOPE').individual.lastName }}
            </template>
          </span>
          <span
            v-if="!$route.path.startsWith('/admin') && getNetworkPartners(data.item.objectId).length > 0 && getNetworkPartners(data.item.objectId).find(element => element.scope ==='MANAGEMENT_SCOPE')"
            class="d-block">
            <img
              height="20px"
              src="/img/adminbase-icon.png"
              alt="admine-base">
            <template v-if="getNetworkPartners(data.item.objectId).find(element => element.scope ==='MANAGEMENT_SCOPE').company">
              {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='MANAGEMENT_SCOPE').company.name }}
            </template>
            <template v-if="getNetworkPartners(data.item.objectId).find(element => element.scope ==='MANAGEMENT_SCOPE').individual">
              {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='MANAGEMENT_SCOPE').individual.firstName.substr(0, 1) }}. {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='MARKETING_SCOPE').individual.lastName }}
            </template>
          </span>
          <span
            v-if="!$route.path.startsWith('/accounting') && getNetworkPartners(data.item.objectId).length > 0 && getNetworkPartners(data.item.objectId).find(element => element.scope ==='ACCOUNTING_SCOPE')"
            class="d-block">
            <img
              height="20px"
              src="/img/financebase-icon.png"
              alt="finance-base">
            <template v-if="getNetworkPartners(data.item.objectId).find(element => element.scope ==='ACCOUNTING_SCOPE').company">
              {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='ACCOUNTING_SCOPE').company.name }}
            </template>
            <template v-if="getNetworkPartners(data.item.objectId).find(element => element.scope ==='ACCOUNTING_SCOPE').individual">
              {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='ACCOUNTING_SCOPE').individual.firstName.substr(0, 1) }}. {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='ACCOUNTING_SCOPE').individual.lastName }}
            </template>
          </span>
          <span
            v-if="getNetworkPartners(data.item.objectId).length > 0 && getNetworkPartners(data.item.objectId).find(element => element.scope ==='MARKETING_SCOPE')"
            class="d-block">
            <img
              height="20px"
              src="/img/marketbase-icon.png"
              alt="market-base">
            <template v-if="getNetworkPartners(data.item.objectId).find(element => element.scope ==='MARKETING_SCOPE').company">
              {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='MARKETING_SCOPE').company.name }}
            </template>
            <template v-if="getNetworkPartners(data.item.objectId).find(element => element.scope ==='MARKETING_SCOPE').individual">
              {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='MARKETING_SCOPE').individual.firstName.substr(0, 1) }}. {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='MARKETING_SCOPE').individual.lastName }}
            </template>
          </span>
        </template>
        <template
          #cell(floor)="data">
          {{ formatFloor(getObject(data.item.objectId) && getObject(data.item.objectId).characteristics ? getObject(data.item.objectId).characteristics.floor : '') }}
        </template>
        <template
          #cell(position)="data">
          {{ data.item.object ? getPositionLabelFromArray(getObject(data.item.objectId).object.positions) : '' }}
        </template>
        <template
          #cell(rooms)="data">
          {{ (getObject(data.item.objectId) && getObject(data.item.objectId).characteristics ? getObject(data.item.objectId).characteristics.rooms : '') | displayOptionalValue }}
        </template>
        <template
          #cell(livingArea)="data">
          <div v-if="['APARTMENT', 'HOUSE'].includes(getObject(data.item.objectId).category) ">
            {{ getObject(data.item.objectId) && getObject(data.item.objectId).characteristics && getObject(data.item.objectId).characteristics.areas ? getObject(data.item.objectId).characteristics.areas.livingArea : '' | formatArea }}
          </div>
          <div v-else>
            {{ getObject(data.item.objectId) && getObject(data.item.objectId).characteristics && getObject(data.item.objectId).characteristics.areas ? getObject(data.item.objectId).characteristics.areas.effectiveArea : '' | formatArea }}
          </div>
        </template>
        <template
          #cell(m2PerYear)="data">
          <div class="text-nowrap">
            <template>
              <span>{{ Math.round(getAdjustedRentValues(data.item).coldRentM2PerYear) | formatPrice }}</span>
            </template>
          </div>
        </template>
        <template
          #cell(marketNetRent)="data">
          <div class="text-nowrap">
            <template v-if="getObject(data.item.objectId) && getObject(data.item.objectId).pricing && getObject(data.item.objectId).pricing.marketNetRent">
              <span>{{ getObject(data.item.objectId).pricing.marketNetRent | formatPrice }}</span>
            </template>
            <template v-else>
              -
            </template>
          </div>
        </template>
        <template
          #cell(targetNetRent)="data">
          <div class="text-nowrap">
            <template v-if="getObject(data.item.objectId) && getObject(data.item.objectId).pricing && getObject(data.item.objectId).pricing.targetNetRent">
              <span>{{ getObject(data.item.objectId).pricing.targetNetRent | formatPrice }}</span>
            </template>
            <template v-else>
              -
            </template>
          </div>
        </template>
        <template
          #cell(marketGrossRent)="data">
          <div class="text-nowrap">
            <template v-if="getObject(data.item.objectId) && getObject(data.item.objectId).pricing && getObject(data.item.objectId).pricing.marketGrossRent">
              <span>{{ getObject(data.item.objectId).pricing.marketGrossRent | formatPrice }}</span>
            </template>
            <template v-else>
              -
            </template>
          </div>
        </template>
        <template
          #cell(targetGrossRent)="data">
          <div class="text-nowrap">
            <template v-if="getObject(data.item.objectId) && getObject(data.item.objectId).pricing && getObject(data.item.objectId).pricing.targetGrossRent">
              <span>{{ getObject(data.item.objectId).pricing.targetGrossRent | formatPrice }}</span>
            </template>
            <template v-else>
              -
            </template>
          </div>
        </template>
        <template
          #cell(netRent)="data">
          <span
            v-if="data.item.taxable || data.item.opted">
            {{ getRentWithTax(data.item, 'netRent') | formatPrice('CHF') }}
            <coozzy-info-circle-icon
              v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.inclTax')"
              class="ml-1" />
          </span>
          <span v-else>
            {{ getAdjustedRentValues(data.item).coldRentPerMonth | formatPrice('CHF') }}
          </span>
        </template>
        <template
          #cell(grossRent)="data">
          <span
            v-if="data.item.taxable || data.item.opted">
            {{ getRentWithTax(data.item, 'grossRent') | formatPrice('CHF') }}
            <coozzy-info-circle-icon
              v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.inclTax')"
              class="ml-1" />
          </span>
          <span v-else>
            {{ getTotalRent(data.item) | formatPrice('CHF') }}
          </span>
        </template>
        <template
          #cell(startAt)="data">
          {{ data.item.startingAt | objectToDate }}
        </template>
        <template
          #cell(endAt)="data">
          {{ data.item.endingAt | objectToDate }}
        </template>
        <template #cell(assignee)="data">
          <template v-if="isAdminModule">
            {{ getObject(data.item.objectId) && getObject(data.item.objectId).contacts.contactIdAssigneeAdministration !== '' ? getContactName(getObject(data.item.objectId).contacts.contactIdAssigneeAdministration) : '-' }}
          </template>
          <template v-if="isAccountingModule">
            {{ getObject(data.item.objectId) && getObject(data.item.objectId).contacts.contactIdAssigneeAccounting !== '' ? getContactName(getObject(data.item.objectId).contacts.contactIdAssigneeAccounting) : '-' }}
          </template>
          <template v-if="isAssetModule">
            {{ getObject(data.item.objectId) && getObject(data.item.objectId).contacts.contactIdAssigneeAsset !== '' ? getContactName(getObject(data.item.objectId).contacts.contactIdAssigneeAsset) : '-' }}
          </template>
          <template v-if="isOwnerModule">
            {{ getObject(data.item.objectId) && getObject(data.item.objectId).contacts.contactIdAssigneeOwner !== '' ? getContactName(getObject(data.item.objectId).contacts.contactIdAssigneeOwner) : '-' }}
          </template>
        </template>
        <template
          #cell(HeatingAdditionalCosts)="data">
          <span class="float-right">
            {{ getAdjustedRentValues(data.item).additionalCosts | formatPrice('CHF') }}
          </span>
        </template>
        <template #cell(subCategory)="data">
          <template v-if="getObject(data.item.objectId)?.subCategories.length > 0">
            <template v-for="(subCat, subCatIndex) in getObject(data.item.objectId)?.subCategories">
              <span
                v-if="subCat !== 'UNDEFINED_SUB_CATEGORY'"
                :key="subCatIndex">
                {{ $t(`message.onBoarding.buildings.objects.subCategory.` + subCat?.toLowerCase()) }}
              </span>
              <template v-else>
                -
              </template>
            </template>
          </template>
          <template v-else>
            -
          </template>
        </template>
        <template #foot(checkbox)>
          <span />
        </template>
        <template #foot(currentTenant)>
          <template
            v-if="fieldsIncludeCalculatedRent()">
            <span>
              {{ $t('message.generic.total') }}
            </span>
          </template>
          <template v-else>
            <span />
          </template>
        </template>
        <template #foot(subtenant)>
          <span />
        </template>
        <template #foot(status)>
          <span />
        </template>
        <template #foot(contractNumber)>
          <span />
        </template>
        <template #foot(type)>
          <span />
        </template>
        <template #foot(floor)>
          <span />
        </template>
        <template #foot(debitUntil)>
          <span />
        </template>
        <template #foot(rooms)>
          <span />
        </template>
        <template #foot(tenanciePartner)>
          <span />
        </template>
        <template #foot(tenancieObjectId)>
          <span />
        </template>
        <template #foot(livingArea)>
          <span />
        </template>
        <template #foot(netRent)>
          {{ totalNetRent | formatPrice }}
        </template>
        <template #foot(grossRent)>
          {{ totalGrossRent | formatPrice }}
        </template>
        <template #foot(startAt)>
          <span />
        </template>
        <template #foot(endAt)>
          <span />
        </template>
        <template #foot(assignee)>
          <span />
        </template>
        <template #foot(m2PerYear)>
          <span />
        </template>
        <template #foot(marketNetRent)>
          <span />
        </template>
        <template #foot(targetNetRent)>
          <span />
        </template>
        <template #foot(marketGrossRent)>
          <span />
        </template>
        <template #foot(targetGrossRent)>
          <span />
        </template>
        <template #foot(subCategory)>
          <span />
        </template>
        <template #foot(HeatingAdditionalCosts)>
          <span />
        </template>
      </b-table>
      <b-table
        id="responsiveFooterTable"
        stacked
        :items="responsiveTableItems">
        <template #head(total)>
          {{ $t('message.generic.total') }}
        </template>
        <template #head(netRent)>
          {{ $t('message.onBoarding.buildings.objects.pricing.coldRent') }}
        </template>
        <template #head(grossRent)>
          {{ $t('message.onBoarding.buildings.objects.pricing.totalRent') }}
        </template>
        <template
          #cell(netRent)>
          {{ totalNetRent | formatPrice }}
        </template>
        <template
          #cell(grossRent)>
          {{ totalGrossRent | formatPrice }}
        </template>
      </b-table>
      <b-pagination
        v-if="filterTenants.length > 0"
        v-model="currentPage"
        align="center"
        class="mt-3 mb-3"
        :total-rows="filterTenants.length"
        :per-page="nbrPerPage === 'all' ? filterTenants.length : nbrPerPage" />
    </coozzy-card>
    <div
      v-if="selectedTenancy !== null">
      <coozzy-card class="global-card">
        <div class="row">
          <div class="col-8 mt-3">
            <h5>
              <router-link
                :to="getObjectDetailRoute(object)">
                {{ $t('message.onBoarding.buildings.objects.titleObject') + ': ' }} {{ object && object !== undefined ? object.name : '' }}
              </router-link>
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <object-tenancy
              v-if="selectedTenancy.vacancy === false"
              :ref="'object-tenancy'"
              :key="`tenancy-${selectedTenancy.id || selectedTenancy.internalId}`"
              :index="1"
              :object="object"
              :tenancy="selectedTenancy"
              :read-only="readOnly"
              :is-first="selectedTenancy.id && getTenancyIndex(selectedTenancy)" />
            <!-- Display vacancies -->
            <object-vacancy
              v-else
              ref="object-vacancy"
              :key="`vacancy -` + selectedTenancy.id ? selectedTenancy.id : selectedTenancy.internalId"
              :index="1"
              :tenancy="selectedTenancy"
              :object="object"
              :read-only="readOnly" />
          </div>
        </div>
      </coozzy-card>
    </div>
    <b-modal
      :id="'tenancy-confirmationModal'"
      ref="tenancy-confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.confirmation')">
      <div
        v-if="loadingDeletion"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <template
        v-else>
        <div class="col">
          <p>{{ $t('message.onBoarding.deleteModals.bodyTenancy') }}</p>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('tenancy-confirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deleteTenancies()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      :id="'closeTenancyModal'"
      ref="closeTenancyModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.deleteModals.confirmation')">
      <template>
        <div class="col">
          <p>{{ $t('message.onBoarding.buildings.objects.tenancies.closeModalBody') }}</p>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('closeTenancyModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="closeTenancies()">
            {{ $t('message.generic.closeTenancy') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <assemble-list-modal
      ref="assembleListModal"
      modal-id="assembleListModal"
      :fields="selectedFields"
      :default-fields="dataFields"
      @save-fields="saveFields" />
    <reminder-creation-modal
      ref="reminder-creation"
      :references-ids="selectedEntriesIds"
      :source="'tenants'"
      @new-reminder="clearCheckbox" />
    <create-activity-modal
      ref="activity-creation"
      :key="keyActivity"
      :ids="selectedEntriesIds"
      @activity-created="clearCheckbox" />
    <activate-tenancy-modal
      v-if="selectedEntriesIds.length > 0 && showActivateTenancyModal"
      ref="activateTenancyModal"
      :selected-entries-ids="selectedEntriesIds"
      :owner-id="selectedEntries[0].ownerId"
      :is-same-tenant="false"
      :tenancies="tenanciesList"
      :contacts="contactsList"
      :objects="objectsList"
      :last-tenancy="lastTenancy"
      @hide="hideActivateTenancyModal"
      @tenancy-activated="tenancyActivated" />
  </section>
</template>

<script>
import ActivateTenancyModal from '@/addTenancy/ActivateTenancyModal'
import ObjectTenancy from './components/ObjectTenancy'
import ObjectVacancy from '@/building/tabs/components/ObjectVacancy'
import { onboarding } from '@/mixins/onboarding'
import { formatting } from '@/mixins/formatting'
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import ObjectApi from '../../misc/apis/ObjectApi'
import ContactApi from '../../misc/apis/ContactApi'
import Vue from 'vue'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import CreateActivityModal from '@/contact/components/CreateActivityModal'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzyAssigneeMultiselect from '@/framework/components/multiselect/CoozzyAssigneeMultiselect'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem'
import CoozzyDropdownSpaceDivider from '@/framework/components/dropdown/CoozzyDropdownSpaceDivider'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import { algolia } from '@/mixins/algolia'
import { tables } from '@/mixins/tables'
import { mapMutations } from 'vuex'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon'
import { routeChecks } from '@/mixins/routeChecks'
import { toastError } from '@/mixins/toastError'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/de'
import { mail } from '@/mixins/mail'
import CoozzyFiltersIcon from '@/framework/components/icons/CoozzyFiltersIcon'
import AssembleListModal from '@/contact/components/AssembleListModal'
import SearchApi from '@/misc/apis/SearchApi'
import CoozzyFilterIcon from '@/framework/components/icons/CoozzyFilterIcon.vue'
import { v4 as uuidv4 } from 'uuid'
import { referenceInterestRate } from '@/mixins/referenceInterestRate'

export default {
  name: 'CreateTenantsTab',
  components: {
    CoozzyFilterIcon,
    DatePicker,
    CoozzyButton,
    CoozzySpinner,
    CoozzyFormCheckbox,
    CoozzyDropdownSpaceDivider,
    ActivateTenancyModal,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyMultiselect,
    CoozzyAssigneeMultiselect,
    CoozzyFormInput,
    CoozzyFormSelect,
    ReminderCreationModal,
    CreateActivityModal,
    CoozzyCard,
    ObjectTenancy,
    ObjectVacancy,
    CoozzyInfoCircleIcon,
    CoozzyFiltersIcon,
    AssembleListModal
  },
  mixins: [onboarding, formatting, tables, routeChecks, toastError, algolia, mail, referenceInterestRate],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    elementSelected: {
      type: String,
      default: ''
    },
    tenantFilters: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      file: null,
      uploading: false,
      loadingDeletion: false,
      filterTenants: [],
      nbrPerPage: 5,
      currentPage: 1,
      searchText: '',
      searchCategories: [],
      searchRooms: [],
      searchVacancy: null,
      searchFloor: [],
      searchStatus: [],
      searchZip: [],
      selectedTenancy: null,
      isBusy: false,
      isLoad: false,
      isLoaded: false,
      keyActivity: 0,
      selectedEntries: [],
      searchAssignee: [],
      searchPreferredCommunicationChannel: [],
      searchEndingAtFilter: [],
      searchStartingAtFilter: [],
      contactIdsLoaded: [],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        }
      ],
      dataFields: [
        {
          key: 'currentTenant',
          label: this.$t('message.onBoarding.buildings.objects.tenancies.tenants.current'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'status',
          label: this.$t('message.tableColumnHeadings.status'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'objects',
          label: this.$t('message.tableColumnHeadings.objects'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'contractNumber',
          label: this.$t('message.tableColumnHeadings.contractNumber'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'type',
          label: this.$t('message.tableColumnHeadings.type'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'floor',
          label: this.$t('message.tableColumnHeadings.floor'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'rooms',
          label: this.$t('message.tableColumnHeadings.rooms'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'debitUntil',
          label: this.$t('message.tableColumnHeadings.debitUntil'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'netRent',
          label: this.$t('message.onBoarding.buildings.objects.pricing.coldRent'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'grossRent',
          label: this.$t('message.onBoarding.buildings.objects.pricing.totalRent'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'startAt',
          label: this.$t('message.onBoarding.buildings.objects.tenancies.startAt'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'endAt',
          label: this.$t('message.onBoarding.buildings.objects.tenancies.endAt'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'assignee',
          label: this.$t('message.tableColumnHeadings.assignee'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'tenanciePartner',
          label: this.$t('message.tableColumnHeadings.tenanciePartner'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'subtenant',
          label: this.$t('message.tableColumnHeadings.subtenant'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'subCategory',
          label: this.$t('message.tableColumnHeadings.subCategory'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'tenancieObjectId',
          label: this.$t('message.tableColumnHeadings.tenancieObjectId'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'livingArea',
          label: this.$t('message.onBoarding.buildings.objects.livingSpaceShort'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'm2PerYear',
          label: this.$t('message.tableColumnHeadings.m2PerYear'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'HeatingAdditionalCosts',
          label: this.$t('message.tableColumnHeadings.HeatingAdditionalCosts'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'marketNetRent',
          label: this.$t('message.tableColumnHeadings.marketNetRent'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'marketGrossRent',
          label: this.$t('message.tableColumnHeadings.marketGrossRent'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'targetNetRent',
          label: this.$t('message.tableColumnHeadings.targetNetRent'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'targetGrossRent',
          label: this.$t('message.tableColumnHeadings.targetGrossRent'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        }
      ],
      selectedFields: [],
      responsiveTableItems: [
        { total: '', netRent: this.totalNetRent, grossRent: this.totalGrossRent }
      ],
      tenanciesList: [],
      contactsList: [],
      objectsList: [],
      lastTenancy: [],
      vacancyKeyFilter: ['tenanciesVacancies', 'tenancies', 'vacancies'],
      showActivateTenancyModal: false,
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: []
    }
  },
  computed: {
    nbrTotalOfHits() {
      return this.sortedTenantList.length
    },
    allContacts() {
      return this.objectOwnerContacts.concat(this.contacts).concat(this.employeeContacts)
    },
    vacancyFilters() {
      const list = []
      this.vacancyKeyFilter.forEach(element => {
        let count = 0
        if (element === 'tenanciesVacancies') {
          count = this.tenancies.length
        } else if (element === 'tenancies') {
          count = this.tenancies.filter(x => !x.vacancy).length
        } else {
          count = this.tenancies.filter(x => x.vacancy).length
        }
        const obj = { count: count, value: element }
        list.push(obj)
      })
      return list
    },
    searchStatusOption() {
      const counts = []
      const current = this
      const tenancies = this.tenancies.filter(x => !x.vacancy)
      tenancies.forEach(element => {
        if (!element.vacancy) {
          const tenantStatus = this.getTenantStatus(element)
          const existingCount = counts.filter(e => e.value === tenantStatus)
          if (existingCount.length === 0) {
            const sameStatus = tenancies.map(obj => current.getTenantStatus(obj)).filter(z => z === tenantStatus)
            if (sameStatus.length > 1) {
              counts.push({
                count: sameStatus.length,
                value: tenantStatus
              })
            } else {
              counts.push({
                count: 1,
                value: tenantStatus
              })
            }
          }
        }
      })
      return counts
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.allEntries.map(x => x.id) : this.selectedEntries.map(x => x.id)
    },
    selectedEntriesContactIds() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return [].concat.apply([], listEntries.map(obj => obj.tenant.contactIds))
    },
    notAllStatusNotActivated() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.length > 0 && listEntries.filter(x => x.status !== 'TENANCY_STATUS_NOT_ACTIVATED').length > 0
    },
    notAllStatusActivated() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.length > 0 && listEntries.filter(x => !((x.status === 'TENANCY_STATUS_CURRENT' || x.status === 'TENANCY_STATUS_TERMINATED') && x.endingAt && x.endingAt.year !== -1 && new Date(this.$options.filters.objectToDateInput(x.endingAt)) < new Date())).length > 0
    },
    object() {
      return this.selectedTenancy ? this.getObject(this.selectedTenancy.objectId) : null
    },
    isFiltered() {
      if ((this.searchStartingAtFilter && this.searchStartingAtFilter.length > 0) || (this.searchEndingAtFilter && this.searchEndingAtFilter.length > 0) || this.searchText !== '' || this.searchCategories.length > 0 || this.searchStatus.length > 0 || this.searchRooms.length > 0 || this.searchVacancy !== null || this.searchFloor.length > 0 || this.searchZip.length > 0 || this.searchAssignee.length > 0 || this.searchPreferredCommunicationChannel.length > 0) {
        return true
      }
      return false
    },
    countTenancy() {
      return this.filterTenants.length
    },
    sortedTenantList() {
      return this.sortObjectsByPriority(this.filterTenants, this.getObject)
    },
    tenantList() {
      if (this.nbrPerPage === 'all') {
        return this.sortedTenantList
      } else {
        return this.sortedTenantList.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
      }
    },
    categoryFilter() {
      const counts = []
      const current = this
      current.filterTenants.map(obj => current.getObject(obj.objectId).category).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== 'UNDEFINED_CATEGORY') {
          const sameNetwork = current.filterTenants.map(obj => current.getObject(obj.objectId).category).filter(z => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    },
    assigneeFilter() {
      const assigneeList = []
      let unassigned = 0
      const counts = []
      const current = this

      const assigneeKey = current.isAccountingModule
        ? 'contactIdAssigneeAccounting'
          : current.isAdminModule
        ? 'contactIdAssigneeAdministration'
          : current.isAssetModule
        ? 'contactIdAssigneeAsset'
          : 'contactIdAssigneeOwner'

      current.filterTenants.forEach(obj => {
        const element = current.getObject(obj.objectId)
        if (element && element.contacts && element.contacts[assigneeKey] !== null && element.contacts[assigneeKey] !== '') {
          assigneeList.push(element)
        } else {
          unassigned += 1
        }
      })

      const assigneeIds = assigneeList.map(x => x.contacts[assigneeKey])
      assigneeIds.forEach(id => {
        const existingCount = counts.filter(e => e.value === id)
        const sameAssignee = current.filterTenants.filter(obj => {
          const element = current.getObject(obj.objectId)
          return element && element.contacts && element.contacts[assigneeKey] === id
        })

        if (existingCount.length === 0) {
          counts.push({
            count: sameAssignee.length,
            value: id,
            label: this.getContactName(id),
            active: !(this.assigneeContacts.find(x => x.id === id)?.blocked)
          })
        }
      })

      if (unassigned !== 0) {
        counts.push({
          count: unassigned,
          value: '',
          label: this.$t('message.generic.unassigned'),
          active: true
        })
      }

      return counts
    },
    floorFilter() {
      const counts = []
      const current = this
      current.filterTenants.map(obj => current.getObject(obj.objectId).characteristics.floor).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== -10000) {
          const sameNetwork = current.filterTenants.map(obj => current.getObject(obj.objectId).characteristics.floor).filter(z => z === x)
          if (x !== -10000) {
            if (existingCount.length === 0) {
              counts.push({
                count: sameNetwork.length,
                value: x
              })
            } else {
              counts.push({
                count: 1,
                value: x
              })
            }
          }
        }
      })
      return counts
    },
    roomsFilter() {
      const counts = []
      const current = this
      current.filterTenants.map(obj => current.getObject(obj.objectId).characteristics.rooms).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== -1) {
          const sameNetwork = current.filterTenants.map(obj => current.getObject(obj.objectId).characteristics.rooms).filter(z => z === x)
          if (x !== -1) {
            if (existingCount.length === 0) {
              counts.push({
                count: sameNetwork.length,
                value: x
              })
            } else {
              counts.push({
                count: 1,
                value: x
              })
            }
          }
        }
      })
      return counts
    },
    isTenanciesActivated() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.filter(x => x.activated).length
    },
    totalNetRent() {
      let sum = 0
      for (let i = 0; i < this.tenantList.length; i++) {
        sum += this.getAdjustedRentValues(this.tenantList[i]).coldRent
      }
      return sum
    },
    totalGrossRent() {
      let sum = 0
      for (let i = 0; i < this.tenantList.length; i++) {
        sum += this.getTotalRent(this.tenantList[i])
      }
      return sum
    },
    getLang() {
      return this.$store.getters['internationalization/getCurrentLanguage']
    },
    filterPreferredCommunicationChannel() {
      let channelList = []
      const counts = []
      this.filterTenants.forEach(element => {
        if (element.tenant.contactIds.length > 0 && this.objectOwnerContacts.length > 0) {
          const contactId = element.tenant.contactIds[0]
          const channelName = this.objectOwnerContacts.find(x => contactId === x.id)?.preferredCommunicationChannel
          if (channelName && channelName !== 'PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED') {
            channelList.push(channelName)
          }
        }
      })
      channelList = [].concat.apply([], channelList)
      channelList.forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== -1) {
          const sameNetwork = channelList.filter(z => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    }
  },
  watch: {
    allContacts: function (newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        this.filterTenantList()
      }
    },
    currentPage: function () {
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    nbrPerPage: function (newVal) {
      localStorage.setItem('createTenantsTab-nbrPerPage', newVal)
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    searchText: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'tenantFilters')
      }
    },
    searchCategories: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'tenantFilters')
      }
    },
    searchRooms: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'tenantFilters')
      }
    },
    searchVacancy: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'tenantFilters')
      }
    },
    searchStatus: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'tenantFilters')
      }
    },
    searchFloor: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'tenantFilters')
      }
    },
    searchZip: function() {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'tenantFilters')
      }
    },
    tenantList: function (newVal) {
      let contactIds = []
      newVal.forEach(element => {
        if (element.tenant && element.tenant.contactIds.length > 0) {
          contactIds = contactIds.concat(element.tenant.contactIds)
        }
        if (element.subTenants.length > 0) {
          element.subTenants.forEach(element => {
            if (element.contactIds.length > 0) {
              contactIds = contactIds.concat(element.contactIds)
            }
          })
        }
      })
      contactIds = contactIds.filter(x => !this.contactIdsLoaded.includes(x))
      this.contactIdsLoaded = this.contactIdsLoaded.concat(contactIds)
      if (contactIds.length > 0) {
        this.$nextTick(function () {
          this.availableContact([...new Set(contactIds.filter(x => x !== null && x !== ''))])
        })
      }
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        this.allEntries = this.sortedTenantList
      }
    }
  },
  mounted() {
    this.$refs.searchText?.setFocusInput()
    this.loadInstantSearchInternal()
    if (this.tenantFilters !== '') {
      const objFilter = JSON.parse(this.tenantFilters)
      this.searchZip = objFilter.searchZip
      this.searchFloor = objFilter.searchFloor
      this.searchRooms = objFilter.searchRooms
      this.searchVacancy = objFilter.searchVacancy
      this.searchStatus = objFilter.searchStatus
      this.searchCategories = objFilter.searchCategories
      this.searchAssignee = objFilter.searchAssignee || []
      this.searchText = objFilter.searchText
    }
    this.nbrPerPage = localStorage.getItem('createTenantsTab-nbrPerPage') ? localStorage.getItem('createTenantsTab-nbrPerPage') === 'all' ? localStorage.getItem('createTenantsTab-nbrPerPage') : parseInt(localStorage.getItem('createTenantsTab-nbrPerPage')) : 5
    let contactIds = []
    this.tenancies.forEach(element => {
      if (element.tenant && element.tenant.contactIds.length > 0) {
        contactIds = contactIds.concat(element.tenant.contactIds)
      }
      if (element.subTenants.length > 0) {
        element.subTenants.forEach(element => {
          if (element.contactIds.length > 0) {
            contactIds = contactIds.concat(element.contactIds)
          }
        })
      }
    })
    contactIds = contactIds.filter(x => !this.contactIdsLoaded.includes(x))
    this.contactIdsLoaded = this.contactIdsLoaded.concat(contactIds)
    if (contactIds.length > 0) {
      this.$nextTick(function () {
        this.availableContact([...new Set(contactIds.filter(x => x !== null && x !== ''))])
      })
    }
    this.filterTenantList()
    setTimeout(() => {
      const tempTenancies = this.sortedTenantList
      if (this.elementSelected !== null && this.elementSelected !== '' && this.isLoad === false) {
        const indexOfTenancy = tempTenancies.map(function(e) { return e.id }).indexOf(this.elementSelected)
        if (indexOfTenancy !== -1) {
          this.currentPage = (Math.floor((indexOfTenancy) / this.nbrPerPage)) + 1
          tempTenancies[indexOfTenancy]._rowVariant = 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary'
        }
      }
      if (this.elementSelected) {
        const checker = this.tenancies.find(ten => ten.id === this.elementSelected)
        if (checker !== undefined && checker !== null) {
          this.selectedTenancy = checker
        }
      }
    }, 1500)
    this.isLoaded = true
    SearchApi.loadSettings(this.accountId).then(response => {
      const settings = response.tableSettings
      let hasOldList = false
      if (settings.length > 0) {
        settings.forEach(setting => {
          if (setting.columns.length > 0 && setting.table === 'tenancy_details') {
            hasOldList = true
            this.loadFields(setting)
          }
        })
      }
      if (hasOldList === false) {
        this.fields.push(...this.dataFields.filter(x => x.key !== 'm2PerYear' && x.key !== 'marketNetRent' && x.key !== 'marketGrossRent' && x.key !== 'targetNetRent' && x.key !== 'targetGrossRent' && x.key !== 'tenancieObjectId' && x.key !== 'tenanciePartner' && x.key !== 'subtenant'))
        let elm = null
        this.dataFields.forEach(element => {
          if (element.key !== 'm2PerYear' && element.key !== 'marketNetRent' && element.key !== 'marketGrossRent' && element.key !== 'targetNetRent' && element.key !== 'targetGrossRent' && element.key !== 'tenancieObjectId' && element.key !== 'tenanciePartner' && element.key !== 'subtenant') {
            elm = { key: element.key, label: element.label, selected: true }
          } else {
            elm = { key: element.key, label: element.label, selected: false }
          }
          this.selectedFields.push(elm)
        })
      }
    }).catch(e => {
      console.log(e)
    })
  },
  methods: {
    ...mapMutations('onboarding', ['setObjectOwnerContacts']),
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply',
      'setUseBcc',
      'setSendMailSecondaryTitle',
      'setSendMailContactsWithoutEmail',
      'setShowAllPlaceholders'
    ]),
    getSortedContactIds(tenant) {
      // this function is used to sort the contactIds of a tenant in the following order: debtor, tenants
      let contactIds = []
      if (tenant) {
        if (tenant.contactIds.includes(tenant.billingContactId)) {
          contactIds = [tenant.billingContactId].concat(tenant.contactIds.filter(x => x !== tenant.billingContactId))
        } else {
          contactIds = tenant.contactIds
        }
      }
      return contactIds
    },
    getTenancyIndex(tenancy) {
      const objectTenancies = this.tenancies.filter(ten => { return ten.objectId === tenancy.objectId })
      const index = objectTenancies.findIndex(ten => { return ten.id === tenancy.id })
      return index === 0
    },
    hideActivateTenancyModal() {
      this.showActivateTenancyModal = false
    },
    getRentWithTax(tenancy, type) {
      const adjustedRentValues = this.getAdjustedRentValues(tenancy)
      if (adjustedRentValues) {
        return this.calculateRentWithTax(adjustedRentValues, type)
      }
    },
    fieldsIncludeCalculatedRent() {
      const items = this.fields.filter(x => x.key === 'netRent' || x.key === 'grossRent')
      if (items?.length > 0) {
        return true
      }
      return false
    },
    onMiddlelicked(item, index, event) {
      const element = event.srcElement
      const buildingId = this.building.id
      if (element.tagName !== 'A') {
        if (item && buildingId) {
          let route = null
          if (this.isOwnerModule) {
            route = this.$router.resolve({
              name: 'OwnerBuildingDetailsView',
              params: { id: buildingId },
              query: {
                view: 'tenants',
                elementSelected: item.id
              }
            })
          } else if (this.isAdminModule) {
            route = this.$router.resolve({
              name: 'AdminBuildingDetailsView',
              params: { id: buildingId },
              query: {
                view: 'tenants',
                elementSelected: item.id
              }
            })
          } else if (this.isAssetModule) {
            route = this.$router.resolve({
              name: 'AssetBuildingDetailsView',
              params: { id: buildingId },
              query: {
                view: 'tenants',
                elementSelected: item.id
              }
            })
          } else if (this.isAccountingModule) {
            route = this.$router.resolve({
              name: 'AccountingBuildingDetailsView',
              params: { id: buildingId },
              query: {
                view: 'tenants',
                elementSelected: item.id
              }
            })
          }
          window.open(route.href)
        }
      }
    },
    getNetworkPartners(objectId) {
      const shares = this.shares.filter(share => {
        return share.resourceIds.includes(objectId)
      })

      if (shares.length > 0 && this.networkPartners.length !== 0) {
        const partners = this.networkPartners.filter(partner => {
          return shares.map(share => share.targetAccountId).includes(partner.id)
        })
        const networkPartner = []
        partners.forEach(element => {
          const cnd = shares.filter(share => share.targetAccountId === element.id)
          if (cnd.length > 0) {
            for (let index = 0; index < cnd.length; index++) {
              element.scope = cnd[index].scope
              networkPartner.push({ ...element })
            }
          }
        })
        return networkPartner
      }

      return []
    },
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting)
      this.fields = result?.fields
      this.selectedFields = result?.selectedFields
      this.$refs.objectsTable?.refresh()
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      SearchApi.saveSettings(this.accountId, columns, 'tenancy_details').then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal')
      })
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    createTicket(type) {
      const defaultAssignee = this.selectedEntries.every((val, i, arr) => this.getObject(val.objectId) && this.getObject(arr[0].objectId) && this.getObject(arr[0].objectId).contacts.contactIdAssigneeAsset !== '' && this.getObject(val.objectId).contacts.contactIdAssigneeAsset !== '' && this.getObject(val.objectId).contacts.contactIdAssigneeAsset === this.getObject(arr[0].objectId).contacts.contactIdAssigneeAsset)
        ? this.getObject(this.selectedEntries[0].objectId).contacts?.contactIdAssigneeAsset
: this.selectedEntries.every((val, i, arr) => this.getObject(val.objectId) && this.getObject(arr[0].objectId) && this.getObject(arr[0].objectId).contacts.contactIdAssigneeAdministration !== '' && this.getObject(val.objectId).contacts.contactIdAssigneeAdministration !== '' && this.getObject(val.objectId).contacts.contactIdAssigneeAdministration === this.getObject(arr[0].objectId).contacts.contactIdAssigneeAdministration)
          ? this.getObject(this.selectedEntries[0].objectId).contacts?.contactIdAssigneeAdministration
: ''
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
      }
    },
    navigateToCreateOrder() {
      const currentRoute = { name: this.$router.currentRoute.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      const tenancyIds = this.selectedEntriesIds.join(',')
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerCreateOrderView', query: { tenancyIds: tenancyIds, source: 'tenancy' } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminCreateOrderView', query: { tenancyIds: tenancyIds, source: 'tenancy' } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetCreateOrderView', query: { tenancyIds: tenancyIds, source: 'tenancy' } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingCreateOrderView', query: { tenancyIds: tenancyIds, source: 'tenancy' } }, () => {})
      }
    },
    getUnjustifiedDate(UnjustifiedObj) {
      return (UnjustifiedObj && UnjustifiedObj.until !== null && UnjustifiedObj.from !== null && UnjustifiedObj.until !== undefined
? '(' + this.$t('message.enterTermination.unjustifiedUse.statusWithEnd', {
        startDate: this.$options.filters.objectToDate(UnjustifiedObj.from),
        endDate: this.$options.filters.objectToDate(UnjustifiedObj.until)
      }) + ')'
: UnjustifiedObj && UnjustifiedObj.until === null && UnjustifiedObj.from !== null
? '(' + this.$t('message.enterTermination.unjustifiedUse.statusWithoutEnd', {
        startDate: this.$options.filters.objectToDate(UnjustifiedObj.from)
      }) + ')'
: '')
    },
    resizeDatePicker() {
      this.$nextTick(function () {
        const calendarEl = document.getElementsByClassName('date-picker-calendar')[0].getElementsByClassName('mx-calendar')[0]
        const inputWidth = document.getElementById('date-picker-input').offsetWidth
        calendarEl.style.cssText = 'width: ' + inputWidth + 'px !important'
      })
    },
    resizeStartingDatePicker() {
      this.$nextTick(function () {
        const calendarEl = document.getElementsByClassName('date-picker-calendar2')[0].getElementsByClassName('mx-calendar')[0]
        const inputWidth = document.getElementById('date-picker-input2').offsetWidth
        calendarEl.style.cssText = 'width: ' + inputWidth + 'px !important'
      })
    },
    customLabelStatus(status) {
      return this.$t('message.onBoarding.buildings.objects.tenancies.status.' + status.value) + ' (' + status.count + ')'
    },
    customLabelFloor(item) {
      return this.formatFloor(item.label) + ' (' + item.count + ')'
    },
    customLabelPreferredCommunicationChannel(item) {
      if (item.value === 'PREFERRED_COMMUNICATION_CHANNEL_EMAIL') {
        return this.$t('message.contact.preferredCommunicationChannelType.email') + ' (' + item.count + ')'
      }
      if (item.value === 'PREFERRED_COMMUNICATION_CHANNEL_LETTER') {
        return this.$t('message.contact.preferredCommunicationChannelType.letter') + ' (' + item.count + ')'
      }
      return item.value
    },
    serialLetter() {
      let nameOfRoute = ''
      const source = 'tenancy'
      if (this.isOwnerModule) {
        nameOfRoute = 'OwnerSerialLetterProcessView'
      } else if (this.isAdminModule) {
        nameOfRoute = 'AdminSerialLetterProcessView'
      } else if (this.isAssetModule) {
        nameOfRoute = 'AssetSerialLetterProcessView'
      } else {
        nameOfRoute = 'AccountingSerialLetterProcessView'
      }
      const uniqKey = uuidv4()
      localStorage.setItem(uniqKey, this.selectedEntriesIds)
      this.$nextTick(() => {
        this.$router.push({
          name: nameOfRoute,
          query: {
            source: source,
            uuid: uniqKey,
            previousRoute: this.$route.fullPath
          }
        })
      })
    },
    getObjectDetailRoute(object) {
      if (object !== null && object !== undefined) {
        if (this.isOwnerModule) {
          return {
            name: 'OwnerBuildingDetailsView',
            params: { id: object.buildingId },
            query: { view: 'objects', elementSelected: object.id }
          }
        } else if (this.isAdminModule) {
          return {
            name: 'AdminBuildingDetailsView',
            params: { id: object.buildingId },
            query: { view: 'objects', elementSelected: object.id }
          }
        } else if (this.isAssetModule) {
          return {
            name: 'AssetBuildingDetailsView',
            params: { id: object.buildingId },
            query: { view: 'objects', elementSelected: object.id }
          }
        } else if (this.isAccountingModule) {
          return {
            name: 'AccountingBuildingDetailsView',
            params: { id: object.buildingId },
            query: { view: 'objects', elementSelected: object.id }
          }
        }
      }
    },
    showActivity() {
      this.keyActivity = Math.random().toString(36).substr(2, 9)
      this.$nextTick(() => {
        this.$refs['activity-creation'].show()
      })
    },
    allFilters() {
      return JSON.stringify({
        searchText: this.searchText,
        searchStatus: this.searchStatus,
        searchCategories: this.searchCategories,
        searchRooms: this.searchRooms,
        searchVacancy: this.searchVacancy,
        searchFloor: this.searchFloor,
        searchZip: this.searchZip
      })
    },
    async serialEmail() {
      this.setShowAllPlaceholders(true)
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      await this.prepareSerialEmail(listEntries)
      this.loading = false
    },
    initFilter() {
      this.searchText = ''
      this.searchCategories = []
      this.searchStatus = []
      this.searchStatus = []
      this.searchRooms = []
      this.searchVacancy = null
      this.searchFloor = []
      this.searchZip = []
      this.searchAssignee = []
      this.searchEndingAtFilter = []
      this.searchStartingAtFilter = []
      this.searchPreferredCommunicationChannel = []
      this.filterTenantList()
    },
    openConfirmationModal() {
      this.$refs['tenancy-confirmationModal'].show()
    },
    clearCheckbox() {
      this.selectedEntries = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    deleteTenancies() {
      const promises = []
      const current = this
      for (let index = 0; index < this.selectedEntriesIds.length; index++) {
        promises.push(ObjectApi.deleteTenancy(this.selectedEntriesIds[index]))
      }
      this.loadingDeletion = true
      Promise.all(promises)
        .then(() => {
          setTimeout(() => {
            this.loadingDeletion = false
            this.filterTenants = this.filterTenants.filter(entry => !this.selectedEntriesIds.includes(entry.id))
            for (let index = 0; index < this.selectedEntriesIds.length; index++) {
              this.removeTenancyInstant(this.selectedEntriesIds[index])
            }
            this.clearCheckbox()
            this.refreshObjects()
            this.selectedTenancy = null
            this.$emit('selected-tenancy', null)
            Vue.toasted.show(this.$t('message.successMessages.tenancies'), { type: 'success' })
            current.$refs['tenancy-confirmationModal'].hide()
          }, 2000)
        })
        .catch(e => {
          this.loadingDeletion = false
          current.$refs['tenancy-confirmationModal'].hide()
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.tenancies'), { type: 'error' })
        })
        .then(() => {
        })
    },
    handleCheckboxesInsideTable(value) {
      const allCheckboxesComponents = this.$refs.tenantsTable.$children[1].$children
      this.handleCheckbox(allCheckboxesComponents, value)
      this.selectAllVisibleEntries = value
      if (value === false) {
        this.selectAllEntries = false
      }
    },
    itemSelected(item, checked) {
      if (checked) {
        this.selectedEntries.push(item)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.id !== item.id)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    customLabel(item) {
      return item.value + ' (' + item.count + ')'
    },
    customLabelVacancy(item) {
      return this.$t('message.onBoarding.tenants.' + item.value) + ' (' + item.count + ')'
    },
    customLabelCategory(item) {
      return this.$t('message.onBoarding.buildings.objects.category.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    searchInputText() {
      this.filterTenantList()
    },
    filterTenantList() {
      this.$nextTick(function () {
        this.filterTenants = this.tenancies.filter(x => this.getObject(x.objectId)).filter(item => {
          const contactsMaped = item.tenant.contactIds.map(x => this.getContactName(x)).toString()
          const cdtSearch = contactsMaped.toLowerCase().includes(this.searchText.toLowerCase()) || (item && item.numericId && item.numericId.includes(this.searchText.toLowerCase()))
          const floorMapped = this.searchFloor.map(x => x.value)
          const cdtFloor = floorMapped.includes(this.getObject(item.objectId).characteristics.floor) || this.searchFloor.length === 0
          const roomMapped = this.searchRooms.map(x => x.value)
          const cdtRooms = roomMapped.includes(this.getObject(item.objectId).characteristics.rooms) || this.searchRooms.length === 0
          const categoryMapped = this.searchCategories.map(x => x.value)
          const assigneeMapped = this.searchAssignee.map(x => x.value)
          const cdtCategory = categoryMapped.includes(this.getObject(item.objectId).category) || this.searchCategories.length === 0
          let cdtAssignee = null
          if (this.isOwnerModule) {
            cdtAssignee = assigneeMapped.includes(this.getObject(item.objectId).contacts.contactIdAssigneeOwner) || this.searchAssignee.length === 0
          } else if (this.isAssetModule) {
            cdtAssignee = assigneeMapped.includes(this.getObject(item.objectId).contacts.contactIdAssigneeAsset) || this.searchAssignee.length === 0
          } else if (this.isAccountingModule) {
            cdtAssignee = assigneeMapped.includes(this.getObject(item.objectId).contacts.contactIdAssigneeAccounting) || this.searchAssignee.length === 0
          } else {
            cdtAssignee = assigneeMapped.includes(this.getObject(item.objectId).contacts.contactIdAssigneeAdministration) || this.searchAssignee.length === 0
          }
          const cdtEndingAt = this.searchEndingAtFilter && this.searchEndingAtFilter.length > 0 ? (item.endingAt && (this.searchEndingAtFilter.includes(item.endingAt.month + '/' + item.endingAt.year))) : true
          const cdtStartingAt = this.searchStartingAtFilter && this.searchStartingAtFilter.length > 0 ? (item.startingAt && (this.searchStartingAtFilter.includes(item.startingAt.month + '/' + item.startingAt.year))) : true
          const cdtStatus = (this.searchStatus.map(x => x.value).includes(this.getTenantStatus(item)) !== false) || this.searchStatus.length === 0
          const currentContact = item.tenant.contactIds.length > 0 ? this.objectOwnerContacts.find(x => item.tenant.contactIds[0] === x.id) : null
          const currentPreferredChanel = currentContact ? currentContact.preferredCommunicationChannel : 'PREFERRED_COMMUNICATION_CHANNEL_UNDEFINED'
          const cdtChannel = this.searchPreferredCommunicationChannel.map(x => x.value).includes(currentPreferredChanel) || this.searchPreferredCommunicationChannel.length === 0
          const cdtVacancy = this.searchVacancy !== null ? (this.searchVacancy.value === 'tenanciesVacancies' ? true : this.searchVacancy.value === 'tenancies' && item.vacancy === false ? true : this.searchVacancy.value === 'vacancies' && item.vacancy === true) : true
          return cdtChannel && cdtStartingAt && cdtEndingAt && cdtSearch && cdtCategory && cdtRooms && cdtFloor && cdtStatus && cdtAssignee && cdtVacancy
        })
      })
    },
    closeTenancies() {
      ObjectApi.closeTenancy(this.selectedEntriesIds)
        .then(response => {
          this.$refs.closeTenancyModal.hide()
          Vue.toasted.show(this.$t('message.successMessages.closeTenancy'), { type: 'success' })
          this.clearCheckbox()
          setTimeout(() => {
            this.filterTenantList()
          }, 2000)
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.closeTenancy'), { type: 'error' })
        })
    },
    activateTenancyClicked() {
      this.tenanciesList = []
      if (this.selectedEntriesIds.length > 0) {
        const promises = []
        const listVacancy = []
        const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
        listEntries.forEach(element => {
          if (!element.vacancy) {
            promises.push(ObjectApi.getTenanciesByTenant(element.ownerId, element.tenant.billingContactId))
          } else {
            listVacancy.push(element)
          }
        })
        Promise.all(promises)
          .then((response) => {
            if (response.length > 0) {
              response.forEach(element => {
                if (element.tenancies) {
                  this.tenanciesList = this.tenanciesList.concat(element.tenancies).filter(x => x.status === 'TENANCY_STATUS_NOT_ACTIVATED')
                }
              })
              this.tenanciesList = [...new Set(this.tenanciesList.map(a => a.id))].map(id => { return this.tenanciesList.find(a => a.id === id) })
              let contactIds = []
              const objectsIds = []
              const promises2 = []
              this.tenanciesList.forEach(element => {
                objectsIds.push(element.objectId)
                contactIds = contactIds.concat(element.tenant?.contactIds)
              })
              if (listVacancy.length > 0) {
                listVacancy.forEach(element => {
                  objectsIds.push(element.objectId)
                  contactIds = contactIds.concat(element.tenant?.contactIds)
                })
              }
              if (objectsIds.length > 0) {
                promises2.push(ObjectApi.listObjectsByIds(objectsIds))
                promises2.push(ObjectApi.getTenancies(objectsIds))
              }
              if (contactIds.length > 0) {
                promises2.push(ContactApi.contactResolve(contactIds))
              }
              Promise.all(promises2)
                .then(response => {
                  if (response.length > 0) {
                    response.forEach(element => {
                      if (element.objects) {
                        this.objectsList = this.objects.concat(element.objects)
                      }
                      if (element.tenancies) {
                        const list = element.tenancies
                        this.lastTenancy = []
                        const t = this
                        this.tenanciesList.forEach(element => {
                          let tenant = null
                          if (list.find(x => x.status === 'TENANCY_STATUS_CURRENT' && x.endingAt !== null && x.objectId === element.objectId && x.id !== element.id)) {
                            tenant = list.find(x => x.status === 'TENANCY_STATUS_CURRENT' && x.endingAt !== null && x.objectId === element.objectId && x.id !== element.id) || null
                          } else {
                            tenant = list.filter(x => x.endingAt !== null && x.objectId === element.objectId && x.id !== element.id).sort(function (a, b) {
                              return new Date(t.$options.filters.objectToDateInput(b.endingAt)).getTime() - new Date(t.$options.filters.objectToDateInput(a.endingAt)).getTime()
                            })[0] || null
                          }
                          if (tenant !== null && tenant.vacancy === false) {
                            this.lastTenancy.push(tenant)
                          }
                        })
                      }
                      if (element.persons || element.companies) {
                        this.contactsList = element.persons.concat(element.companies)
                      }
                    })
                    this.tenanciesList = this.tenanciesList.concat(listVacancy)
                    this.showActivateTenancyModal = true
                    this.$nextTick(() => {
                      this.$refs.activateTenancyModal.show()
                    })
                  }
                })
                .catch(e => {
                  console.log(e)
                  Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
                })
            } else if (listVacancy.length > 0) {
              this.tenanciesList = listVacancy
              this.showActivateTenancyModal = true
              this.$nextTick(() => {
                this.$refs.activateTenancyModal.show()
              })
            }
          })
      }
    },
    getTenantStatus(item) {
      if (item.closed === true) {
        return 'closed'
      } else if (item.activated === false) {
        return 'not_activated'
      } else if (new Date(this.$options.filters.objectToDateInput(item.startingAt)) > new Date()) {
        return 'future'
      } else if (item.endingAt && new Date(this.$options.filters.objectToDateInput(item.endingAt)).getTime() > (new Date()).getTime() && new Date(this.$options.filters.objectToDateInput(item.startingAt)).getTime() <= (new Date()).getTime() && item.contract.type === 'LIMITED') {
        return 'current_temporary'
      } else if (item.endingAt && new Date(this.$options.filters.objectToDateInput(item.endingAt)).getTime() > (new Date()).getTime() && new Date(this.$options.filters.objectToDateInput(item.startingAt)).getTime() <= (new Date()).getTime()) {
        return 'current_terminated'
      } else if (item.endingAt && item.endingAt.year > 0) {
        return 'terminated'
      } else if (!item.endingAt || item.endingAt <= 0) {
        return 'current'
      } else {
        return 'future'
      }
    },
    changeSelectedTenant(item) {
      if (!this.isInvalid()) {
        for (let i = 0; i < this.tenancies.length; i++) {
          if (this.tenancies[i]._rowVariant === 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary') {
            this.tenancies[i]._rowVariant = ''
          }
        }
        this.selectedTenancy = item
        this.$emit('selected-tenancy', item)
      }
    },
    redirectToDetailPage(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          return { name: 'OwnerContactDetailsView', params: { id: contactId } }
        } else if (this.isAssetModule) {
          return { name: 'AssetContactDetailsView', params: { id: contactId } }
        } else if (this.isAccountingModule) {
          return { name: 'AccountingContactDetailsView', params: { id: contactId } }
        } else {
          return { name: 'AdminContactDetailsView', params: { id: contactId } }
        }
      }
    },
    tenancyActivated() {
      this.clearCheckbox()
      setTimeout(() => {
        this.filterTenantList()
      }, 2000)
    },
    getContactName(contactId) {
      const contact = this.objectOwnerContacts.concat(this.contacts).concat(this.employeeContacts).find(function (contact) {
        return contact.id === contactId || contact.objectID === contactId
      })

      return contact ? contact.name : '-'
    },
    getAdjustedRentValues(tenancy) {
      let currentRent = tenancy.rent.adjustments[0]
      const current = this
      const pastAdjustments = tenancy.rent.adjustments.filter(obj => parseInt(obj.byDate) !== -1 && new Date(this.$options.filters.objectToDateInput(obj.byDate)) < Date.now())
      if (pastAdjustments.length > 0) {
        pastAdjustments.sort(function (a, b) {
          return new Date(current.$options.filters.objectToDateInput(b.byDate)) - new Date(current.$options.filters.objectToDateInput(a.byDate))
        })
        currentRent = pastAdjustments[0]
      }
      return currentRent
    },
    getTotalRent(tenancy) {
      const adjustedRentValues = this.getAdjustedRentValues(tenancy)
      const dueDates = adjustedRentValues.dueDates.length > 0 ? adjustedRentValues.dueDates.length : 1

      // Subtract rent reduction if necessary
      const rentReductionComponent = adjustedRentValues.additionalValues?.find(e => e.type === 'ADDITIONAL_RENT_COMPONENT_RENT_REDUCTION')
      let rentReduction = 0
      if (rentReductionComponent) {
        if (rentReductionComponent.value > 0) {
          rentReduction = rentReductionComponent.value * -1
        } else if (rentReductionComponent.value < -1) {
          rentReduction = rentReductionComponent.value
        }
      }

      const total = (adjustedRentValues.coldRent !== -1 ? adjustedRentValues.coldRent : 0) +
        (adjustedRentValues.additionalCosts !== -1 ? adjustedRentValues.additionalCosts : 0) +
        rentReduction
      return total !== 0 ? (total * dueDates) / 12 : 0
    },
    isInvalid() {
      let valid = true
      const errorArray = []
      if (this.selectedTenancy !== null) {
        if (this.$refs['object-tenancy']) {
          if (this.$refs['object-tenancy'].isInvalid()) {
            if (!this.$refs['object-tenancy'].isCollapseOpen()) {
              errorArray.push(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.buildings.objects.tenancies.tenancy') + this.$t('message.savingErrors.validationError2'))
            }
            valid = false
          }
        }
      }
      this.showManyToast(errorArray)
      return !valid
    },
    customLabelAssignee(item) {
      if (item.value === '') {
        return `${this.$t('message.generic.unassigned')} (${item.count})`
      }
      const name = this.getContactName(item.value)
      return `${name} (${item.count})`
    },
    availableContact(search) {
      ContactApi.contactResolve(search)
        .then(response => {
          const contacts = response.persons.concat(response.companies)
          const usedContacts = this.objectOwnerContacts.concat(contacts)
          this.setObjectOwnerContacts(usedContacts)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }

}
</script>

<style lang="scss" scoped>
  @media (min-width: 768px) {
    #responsiveFooterTable {
      display: none;
    }
  }
  :deep(.date-picker-container) {
    .mx-input {
      display: block;
      width: 100%;
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      line-height: 1.5;
      border-radius: 0.2rem;
      font-weight: 400;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
      height: 30px !important;
      box-shadow: 1px 1px 6px #ccc;
    }
    .mx-input:hover, .mx-input:focus {
      border-color: #ccc !important;
    }
    .mx-datepicker {
      width: 100%;
    }
    .mx-icon-calendar {
      display: none;
    }
  }
</style>
