// @ts-nocheck
import Vue from 'vue'
import { i18n } from '@/internationalization/i18nSetup'
import store from '../store'
import ObjectApi from '../../misc/apis/ObjectApi'
import MediaApi from '../../misc/apis/MediaApi'
import ContactApi from '../../misc/apis/ContactApi'
import ShareApi from '../../misc/apis/ShareApi'
import UserApi from '../../misc/apis/UserApi'
import NetworkPartnerAPI from '../../misc/apis/NetworkPartnerApi'
import RoomApi from '../../misc/apis/RoomApi'
import { sort } from '@/mixins/sort'
import HandoverProtocolApi from '@/misc/apis/HandoverProtocolApi'
import { helper } from '@/types/helper'
import InvestPlanApi from '@/misc/apis/InvestPlanApi'
import PortalApi from '@/misc/apis/PortalApi'

function copyObject(object) {
  const objCopy = {} // objCopy will store a copy of the mainObj
  let key

  for (key in object) {
    // noinspection JSUnfilteredForInLoop
    objCopy[key] = object[key] // copies each property to the objCopy object
  }
  return objCopy
}

function addRooms(nbr, objectId, ownerId) {
  const arrayRooms = []
  const rm = {
    internalId: Date.now(),
    dimensions: {
      area: 0,
      height: 0,
      length: 0,
      volume: 0,
      width: 0
    },
    documentIds: [],
    imageMediaIds: [],
    name: '',
    note: '',
    ownerId: ownerId,
    referenceId: objectId
  }
  const rooms = [
    i18n.t('message.onBoarding.buildings.objects.features.kitchens.title'),
    i18n.t('message.onBoarding.buildings.objects.features.defaultCorridor'),
    i18n.t('message.onBoarding.buildings.objects.features.defaultBath'),
    i18n.t('message.onBoarding.buildings.objects.features.defaultLivingroom')]
  rooms.forEach((element) => {
    const cp = { ...rm }
    cp.name = element
    arrayRooms.push(cp)
  })
  for (let index = 0; index < nbr; index++) {
    const cp = { ...rm }
    cp.name = i18n.t('message.onBoarding.buildings.objects.features.defaultBedroom')
    arrayRooms.push(cp)
  }
  return arrayRooms
}

export default {
  mixins: [sort],
  namespaced: true,
  state: {
    loading: false,
    loadingFinish: false,
    isBuildingObjectsFiltered: [],
    errors: [],
    dismissCountDown: 0,
    hasChanged: false,
    hasChangedObject: false,
    hasChangedTenant: false,
    building: null,
    isHandoverEnabled: false,
    isPortalLinkEnabled: false,
    buildingImageMedia: null,
    buildingDocuments: [],
    energySourceDocuments: [],
    tankReadingDocuments: [],
    energySources: [],
    buildingDevices: [],
    removedBuildingDevices: [],
    buildingDeviceDocuments: [],
    buildingRooms: [],
    removedBuildingRooms: [],
    buildingRoomDocuments: [],
    insurances: [],
    properties: [],
    isPortalLinkEnabled: false,
    plots: [],
    removedInsurances: [],
    removedCondominium: [],
    insuranceDocuments: [],
    buildingMortgages: [],
    removedBuildingMortgages: [],
    buildingMortgageDocuments: [],
    objectMortgages: [],
    removedObjectMortgages: [],
    objectMortgageDocuments: [],
    buildingEnergySources: [],
    objectEnergySources: [],
    handoverProtocols: [],
    loadingObjects: false,
    objects: [],
    allObjects: [],
    removedObjects: [],
    objectDocuments: [],
    loadingTenancies: false,
    tenancies: [],
    loadingCondominium: false,
    condominiumOwnership: [],
    assigneeContacts: [],
    contacts: [],
    employeesContacts: [],
    objectOwnerContacts: [],
    shares: [],
    ownerAccount: [],
    networkPartners: [],
    removedTenancies: [],
    tenancyDocuments: [],
    tenancyContracts: [],
    condominiumDocuments: [],
    condominiumAdjustmentDocuments: [],
    objectDevices: [],
    components: [],
    portalLinks: [],
    objectRooms: [],
    removedObjectDevices: [],
    removedObjectRooms: [],
    removedComponents: [],
    removedPortalLinks: [],
    objectDeviceDocuments: [],
    objectRoomDocuments: [],
    openedCollapses: [],
    propertyBuildings: []
  },
  getters: {
    isBuildingObjectsFiltered: (state) => {
      return state.isBuildingObjectsFiltered
    },
    loading: (state) => {
      return state.loading
    },
    loadingFinish: (state) => {
      return state.loadingFinish
    },
    dismissCountDown: (state) => {
      return state.dismissCountDown
    },
    hasChanged: (state) => {
      return state.hasChanged
    },
    hasChangedObject: (state) => {
      return state.hasChangedObject
    },
    hasChangedTenant: (state) => {
      return state.hasChangedTenant
    },
    getBuilding: (state) => {
      return state.building
    },
    getHandoverProtocolEnabled: (state) => {
      return state.isHandoverEnabled
    },
    getPortalLinkEnabled: (state) => {
      return state.isPortalLinkEnabled
    },
    getBuildingImageMedia: (state) => {
      return state.buildingImageMedia
    },
    getBuildingDocuments: (state) => {
      return state.buildingDocuments
    },
    getEnergySourceDocuments: (state) => {
      return state.energySourceDocuments
    },
    getTankReadingDocuments: (state) => {
      return state.tankReadingDocuments
    },
    getBuildingDevices: (state) => {
      return state.buildingDevices
    },
    getBuildingRooms: (state) => {
      return state.buildingRooms
    },
    getBuildingDeviceDocuments: (state) => {
      return state.buildingDeviceDocuments
    },
    getBuildingRoomDocuments: (state) => {
      return state.buildingRoomDocuments
    },
    getInsurances: (state) => {
      return sort.methods.sortByDateInsurances(state.insurances)
    },
    getProperties: (state) => {
      return state.properties
    },
    getIsPortalLinkEnabled: (state) => {
      return state.isPortalLinkEnabled
    },
    getPlots: (state) => {
      return state.plots
    },
    getInsuranceDocuments: (state) => {
      return state.insuranceDocuments
    },
    getBuildingMortgages: (state) => {
      return state.buildingMortgages
    },
    getBuildingMortgageDocuments: (state) => {
      return state.buildingMortgageDocuments
    },
    getObjectMortgages: (state) => {
      return state.objectMortgages
    },
    getObjectMortgageDocuments: (state) => {
      return state.objectMortgageDocuments
    },
    getBuildingEnergySources: (state) => {
      return state.buildingEnergySources
    },
    getObjectEnergySources: (state) => {
      return state.objectEnergySources
    },
    // getEnergySources: (state) => {
    //   return state.energySources
    // },
    getObjects: (state) => {
      return state.objects
    },
    getAllObjects: (state) => {
      return state.allObjects
    },
    getObjectDocuments: (state) => {
      return state.objectDocuments
    },
    getTenancies: (state) => {
      return state.tenancies
    },
    getCondominiumOwnership: (state) => {
      return state.condominiumOwnership
    },
    getAssigneeContacts: (state) => {
      return state.assigneeContacts
    },
    getContacts: (state) => {
      return state.contacts
    },
    getEmployeesContacts: (state) => {
      return state.employeesContacts
    },
    getObjectOwnerContacts: (state) => {
      return state.objectOwnerContacts
    },
    getShares: (state) => {
      return state.shares
    },
    getOwnerAccount: (state) => {
      return state.ownerAccount
    },
    getNetworkPartners: (state) => {
      return state.networkPartners
    },
    getTenancyDocuments: (state) => {
      return state.tenancyDocuments
    },
    getHandoverProtocols: (state) => {
      return state.handoverProtocols
    },
    getTenancyContracts: (state) => {
      return state.tenancyContracts
    },
    getCondominiumDocuments: (state) => {
      return state.condominiumDocuments
    },
    getCondominiumAdjustmentDocuments: (state) => {
      return state.condominiumAdjustmentDocuments
    },
    getObjectDevices: (state) => {
      return state.objectDevices
    },
    getComponents: (state) => {
      return state.components
    },
    getPortalLinks: (state) => {
      return state.portalLinks
    },
    getObjectRooms: (state) => {
      return state.objectRooms
    },
    getObjectDeviceDocuments: (state) => {
      return state.objectDeviceDocuments
    },
    getObjectRoomDocuments: (state) => {
      return state.objectRoomDocuments
    },
    getOpenedCollapses: (state) => {
      return state.openedCollapses
    },
    getCraftsmanContactIds: (state) => {
      let ids = state.building?.contacts.filter(x => x.type === 'BUILDING_CONTACT_TYPE_CRAFTSMAN')?.map(y => y.id)
      return [...new Set(ids)]
    },
    getJanitorContactIds: (state) => {
      let ids = state.building?.contacts.filter(x => x.type === 'BUILDING_CONTACT_TYPE_JANITOR')?.map(y => y.id)
      return [...new Set(ids)]
    },
    getServiceProviderContactIds: (state) => {
      let ids = state.building?.contacts.filter(x => x.type === 'BUILDING_CONTACT_TYPE_SERVICE_PROVIDER')?.map(y => y.id)
      return [...new Set(ids)]
    },
    getDelegateContactIds: (state) => {
      let ids = state.building?.contacts.filter(x => x.type === 'BUILDING_CONTACT_TYPE_DELEGATE')?.map(y => y.id)
      return [...new Set(ids)]
    },
    getPropertyBuildings: (state) => {
      return state.propertyBuildings
    }
  },
  mutations: {
    setIsBuildingObjectsFiltered: (state, value) => {
      Vue.set(state, 'isBuildingObjectsFiltered', value)
    },
    setLoading: (state, value) => {
      if (value) {
        Vue.set(state, 'loadingFinish', false)
      }
      Vue.set(state, 'loading', value)
    },
    setLoadingFinish: (state, value) => {
      Vue.set(state, 'loadingFinish', value)
    },
    resetErrors: (state) => {
      Vue.set(state, 'errors', [])
    },
    addError: (state, error) => {
      state.errors.push(error)
    },
    setDismissCountDown: (state, value) => {
      Vue.set(state, 'dismissCountDown', value)
    },
    setBuilding: (state, building) => {
      Vue.set(state, 'building', building)
    },
    setBuildingImageMedia: (state, data) => {
      Vue.set(state, 'buildingImageMedia', data)
    },
    setBuildingDocuments: (state, data) => {
      Vue.set(state, 'buildingDocuments', data.documents)
    },
    // setEnergySourceDocuments: (state, data) => {
    //   Vue.set(state, 'energySourceDocuments', data.documents)
    // },
    // setTankReadingDocuments: (state, data) => {
    //   Vue.set(state, 'tankReadingDocuments', data.documents)
    // },
    updateBuildingField: (state, data) => {
      Vue.set(state, 'hasChanged', true)
      state.building.modified = true
      Vue.set(state.building, data.field, data.value)
    },
    updateBuildingDistances: (state, data) => {
      Vue.set(state, 'hasChanged', true)
      state.building.modified = true
      Vue.set(state.building.distances, data.field, data.value)
    },
    updateBuildingStrategy: (state, data) => {
      Vue.set(state, 'hasChanged', true)
      state.building.modified = true
      Vue.set(state.building.buildingStrategy, data.field, data.value)
    },
    updateBuildingRating: (state, data) => {
      Vue.set(state, 'hasChanged', true)
      state.building.modified = true
      Vue.set(state.building.rating, data.field, data.value)
    },
    updateSia: (state, data) => {
      Vue.set(state, 'hasChanged', true)
      state.building.modified = true
      Vue.set(state.building.sia, data.field, data.value)
    },
    updateCharacteristics: (state, data) => {
      Vue.set(state, 'hasChanged', true)
      state.building.modified = true
      Vue.set(state.building.characteristics, data.field, data.value)
    },
    updateEnergySource: (state, energySource) => {
      Vue.set(state, 'hasChanged', true)
      state.building.modified = true
      const index = state.building.energySources
        .findIndex(ins => (ins.id === energySource.id && ins.id) ||
          (ins.internalId === energySource.internalId && ins.internalId))

      if (!energySource.type) {
        energySource.type = 'ENERGY_SOURCE_TYPE_UNDEFINED'
      }
      if (!energySource.tankSize) {
        energySource.tankSize = -1
      }
      if (!energySource.orderQuantity) {
        energySource.orderQuantity = -1
      }
      if (!energySource.tankContent) {
        energySource.tankContent = -1
      }
      if (!energySource.hasStorage) {
        energySource.hasStorage = false
      }
      Vue.set(state.energySources, index, energySource)
    },
    setBuildingDevices: (state, devices) => {
      Vue.set(state, 'buildingDevices', devices)
    },
    setBuildingRooms: (state, rooms) => {
      Vue.set(state, 'buildingRooms', rooms)
    },
    addNewBuildingDevice: (state) => {
      Vue.set(state, 'hasChanged', true)
      state.buildingDevices.push({
        internalId: Date.now(),
        numericId: 999999,
        ownerId: state.building.ownerId,
        referenceId: state.building.id,
        installation: null,
        uninstallation: null,
        type: undefined,
        system: undefined,
        facilityPlant: undefined,
        bkpH: undefined,
        lifeSpan: -1,
        amount: -1,
        acquisitionCosts: -1,
        unit: 'DEVICE_UNIT_UNDEFINED',
        measureUntil: null,
        measure: 'DEVICE_MEASURE_UNDEFINED',
        cataloguePrice: -1,
        maintenance: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastMaintenance: null,
          nextMaintenance: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        inspection: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastOfficialControl: null,
          nextOfficialControl: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        revision: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastOfficialRevision: null,
          nextOfficialRevision: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        condition: 'DEVICE_CONDITION_UNDEFINED',
        note: '',
        documentIds: [],
        images: [],
        isNewItem: true,
        lockingKey: {
          combinedKeys: false,
          combinedKeysNote: '',
          description: '',
          lockingPlanNumber: ''
        }
      })
    },
    addNewBuildingRoom: (state) => {
      const rooms = []
      Vue.set(state, 'hasChanged', true)
      rooms.push({
        internalId: Date.now(),
        dimensions: {
          area: null,
          height: null,
          length: null,
          volume: null,
          width: null
        },
        documentIds: [],
        imageMediaIds: [],
        name: '',
        note: '',
        ownerId: state.building.ownerId,
        referenceId: state.building.id
      })
      RoomApi.createRooms(rooms).then(res => {
        const newRoom = res.rooms[0]
        newRoom.isNewItem = true
        state.buildingRooms.push(newRoom)
      })
    },
    duplicateBuildingDevice: (state, device) => {
      Vue.set(state, 'hasChanged', true)

      const duplicatedDevice = copyObject(device)
      delete duplicatedDevice.id
      duplicatedDevice.internalId = Date.now()
      duplicatedDevice.documentIds = []
      duplicatedDevice.isNewItem = true
      state.buildingDevices.push(duplicatedDevice)
    },
    updateBuildingDevice: (state, device) => {
      Vue.set(state, 'hasChanged', true)
      const index = state.buildingDevices
        .findIndex(dev => (dev.id === device.id && dev.id) ||
          (dev.internalId === device.internalId && dev.internalId))

      device.modified = true
      if (isNaN(device.acquisitionCosts)) {
        device.acquisitionCosts = -1
      }
      if (isNaN(device.cataloguePrice)) {
        device.cataloguePrice = -1
      }
      if (isNaN(device.amount)) {
        device.amount = -1
      }
      if (isNaN(device.lifeSpan)) {
        device.lifeSpan = -1
      }
      if (isNaN(device.maintenance.costs.amount)) {
        device.maintenance.costs.amount = -1
      }
      if (isNaN(device.inspection.costs.amount)) {
        device.inspection.costs.amount = -1
      }
      if (isNaN(device.revision.costs.amount)) {
        device.revision.costs.amount = -1
      }

      Vue.set(state.buildingDevices, index, device)
    },
    updateBuildingRoom: (state, room) => {
      Vue.set(state, 'hasChanged', true)
      const index = state.buildingRooms
        .findIndex(rm => (rm.id === room.id && rm.id) ||
          (rm.internalId === room.internalId && rm.internalId))

      room.modified = true
      Vue.set(state.buildingRooms, index, room)
    },
    removeBuildingDevice: (state, device) => {
      Vue.set(state, 'hasChanged', true)

      state.removedBuildingDevices.push(device)
      const index = state.buildingDevices
        .findIndex(dev => (dev.id === device.id && dev.id) ||
          (dev.internalId === device.internalId && dev.internalId))
      Vue.delete(state.buildingDevices, index)
    },
    removeBuildingRoom: (state, room) => {
      Vue.set(state, 'hasChanged', true)

      state.removedBuildingRooms.push(room)
      const index = state.buildingRooms
        .findIndex(rm => (rm.id === room.id && rm.id) ||
          (rm.internalId === room.internalId && rm.internalId))
      // delete devices that have the removed room as reference
      const buildingDevices = state.buildingDevices.filter(dev => dev.roomId === room.id)
      buildingDevices.forEach(device => {
        state.removedBuildingDevices.push(device)
        const devIndex = state.buildingDevices
          .findIndex(dev => (dev.id === device.id && dev.id) ||
            (dev.internalId === device.internalId && dev.internalId))
        Vue.delete(state.buildingDevices, devIndex)
      })
      Vue.delete(state.buildingRooms, index)
    },
    setBuildingDeviceDocuments: (state, data) => {
      Vue.set(state, 'buildingDeviceDocuments', data.documents)
    },
    setBuildingRoomDocuments: (state, data) => {
      Vue.set(state, 'buildingRoomDocuments', data.documents)
    },
    setInsurances: (state, insurances) => {
      Vue.set(state, 'insurances', insurances)
    },
    setProperties: (state, properties) => {
      Vue.set(state, 'properties', properties)
    },
    setIsPortalLinkEnabled: (state, enabled) => {
      Vue.set(state, 'isPortalLinkEnabled', enabled)
    },
    setHandoverProtocolEnabled: (state, enabled) => {
      Vue.set(state, 'isHandoverEnabled', enabled)
    },
    setPortalLinkEnabled: (state, enabled) => {
      Vue.set(state, 'isPortalLinkEnabled', enabled)
    },
    setPlots: (state, plots) => {
      Vue.set(state, 'plots', plots)
    },
    setBuildingMortgages: (state, mortgages) => {
      Vue.set(state, 'buildingMortgages', mortgages)
    },
    setObjectMortgages: (state, mortgages) => {
      Vue.set(state, 'objectMortgages', mortgages)
    },
    setBuildingEnergySources: (state, energySource) => {
      Vue.set(state, 'buildingEnergySources', energySource)
    },
    setObjectEnergySources: (state, energySource) => {
      Vue.set(state, 'objectEnergySources', energySource)
    },
    // setEnergySources: (state, energySources) => {
    //   Vue.set(state, 'energySources', energySources)
    // },
    addNewInsurance: (state) => {
      Vue.set(state, 'hasChanged', true)
      state.insurances.push({
        internalId: Date.now(),
        ownerId: state.building.ownerId,
        referenceId: state.building.id,
        documentIds: [],
        isNewItem: true,
        insuranceCompany: '',
        type: '',
        benefits: [],
        notes: '',
        premiumPaidUntil: null,
        startDate: null,
        endDate: null,
        amount: -1,
        yearlyCosts: -1,
        excess: -1,
        earliestTerminationDate: null
      })
    },
    updateInsurance: (state, insurance) => {
      Vue.set(state, 'hasChanged', true)
      const index = state.insurances
        .findIndex(ins => (ins.id === insurance.id && ins.id) ||
          (ins.internalId === insurance.internalId && ins.internalId))

      insurance.modified = true
      if (!insurance.amount) {
        insurance.amount = -1
      }
      if (!insurance.yearlyCosts) {
        insurance.yearlyCosts = -1
      }
      if (!insurance.excess) {
        insurance.excess = -1
      }

      Vue.set(state.insurances, index, insurance)
    },
    removeInsurance: (state, insurance) => {
      Vue.set(state, 'hasChanged', true)

      state.removedInsurances.push(insurance)
      const index = state.insurances
        .findIndex(ins => (ins.id === insurance.id && ins.id) ||
          (ins.internalId === insurance.internalId && ins.internalId))
      Vue.delete(state.insurances, index)
    },
    setInsuranceDocuments: (state, data) => {
      Vue.set(state, 'insuranceDocuments', data.documents)
    },
    addNewMortgage: (state, referenceId) => {
      Vue.set(state, 'hasChanged', true)
      const newMortgage = {
        internalId: Date.now(),
        ownerId: state.building.ownerId,
        referenceId: referenceId,
        documentIds: [],
        enableAmortizations: false,
        isNewItem: true,
        amortizations: [],
        amortisationAmount: null,
        amount: -1,
        annualAmortisation: -1,
        bankName: '',
        bankNumber: '',
        bic: '',
        designation: '',
        dueDateMonthsAmortization: [],
        dueDateMonthsInterestRate: [],
        inactiveFrom: {
          day: -1,
          month: -1,
          year: -1
        },
        interestRate: '',
        debitAccount: '',
        mortgageDesignation: '',
        mortgageeAddress: {
          city: '',
          country: '',
          street: '',
          streetNumber: '',
          zip: ''
        },
        number: '',
        terminableOn: '',
        validFrom: {
          day: -1,
          month: -1,
          year: -1
        },
        expiresAt: {
          day: -1,
          month: -1,
          year: -1
        },
        amortizationFrom: {
          day: -1,
          month: -1,
          year: -1
        }
      }
      if (referenceId.startsWith('building')) {
        state.buildingMortgages.push(newMortgage)
      } else {
        state.objectMortgages.push(newMortgage)
      }
    },
    updateBuildingMortgage: (state, mortgage) => {
      Vue.set(state, 'hasChanged', true)
      const index = state.buildingMortgages
        .findIndex(ins => (ins.id === mortgage.referenceId && ins.id) ||
          (ins.internalId === mortgage.internalId && ins.internalId))

      mortgage.modified = true
      if (!mortgage.amount) {
        mortgage.amount = -1
      }

      Vue.set(state.buildingMortgages, index, mortgage)
    },
    removeBuildingMortgage: (state, mortgage) => {
      Vue.set(state, 'hasChanged', true)

      state.removedBuildingMortgages.push(mortgage)
      const index = state.buildingMortgages
        .findIndex(ins => (ins.id === mortgage.id && ins.id) ||
          (ins.internalId === mortgage.internalId && ins.internalId))
      Vue.delete(state.buildingMortgages, index)
    },
    updateObjectMortgage: (state, mortgage) => {
      Vue.set(state, 'hasChanged', true)
      const index = state.objectMortgages
        .findIndex(ins => (ins.id === mortgage.referenceId && ins.id) ||
          (ins.internalId === mortgage.internalId && ins.internalId))

      mortgage.modified = true
      if (!mortgage.amount) {
        mortgage.amount = -1
      }

      Vue.set(state.objectMortgages, index, mortgage)
    },
    removeObjectMortgage: (state, mortgage) => {
      Vue.set(state, 'hasChanged', true)
      state.removedObjectMortgages.push(mortgage)
      const index = state.objectMortgages
        .findIndex(ins => (ins.id === mortgage.id && ins.id) ||
          (ins.internalId === mortgage.internalId && ins.internalId))
      Vue.delete(state.objectMortgages, index)
    },
    setBuildingMortgageDocuments: (state, data) => {
      Vue.set(state, 'buildingMortgageDocuments', data.documents)
    },
    setObjectMortgageDocuments: (state, data) => {
      Vue.set(state, 'ObjectMortgageDocuments', data.documents)
    },
    // addNewEnergySource: (state, referenceId) => {
    //   Vue.set(state, 'hasChanged', true)
    //   state.building.modified = true
    //   state.energySources.push({
    //     type: 'UNDEFINED_ENERGY_SOURCE_TYPE',
    //     tankSize: -1,
    //     orderQuantity: -1,
    //     tankContent: -1,
    //     documentIds: [],
    //     isNewItem: true,
    //     hasStorage: false,
    //     tankReadings: []
    //   })
    // },
    addNewEnergySource: (state, referenceId) => {
      Vue.set(state, 'hasChanged', true)
      const newEnergySource = {
        internalId: Date.now(),
        type: 'ENERGY_SOURCE_TYPE_UNDEFINED',
        kind: 'ENERGY_SOURCE_KIND_UNDEFINED',
        referenceId: referenceId,
        unit: 'ENERGY_SOURCE_UNIT_UNDEFINED',
        costDistribution: -1,
        consumptionKwhPerYear: -1,
        co2PerYear: -1,
        consumption: -1,
        documentIds: [],
        isNewItem: true,
        tankReadings: [],
        tankSize: -1,
        energyReferenceArea: -1,
        heatDistribution: null,
        orders: [],
        ownerId: state.building.ownerId,
        photovoltaic: false,
        selfConsumptionCommunity: false
      }
      if (referenceId.startsWith('building')) {
        state.buildingEnergySources.push(newEnergySource)
      } else {
        state.objectEnergySources.push(newEnergySource)
      }
    },
    updateObjectEnergySource: (state, energySource) => {
      Vue.set(state, 'hasChanged', true)
      const index = state.objectEnergySources
        .findIndex(ins => (ins.id === energySource.referenceId && ins.id) ||
          (ins.internalId === energySource.internalId && ins.internalId))

      energySource.modified = true

      Vue.set(state.objectEnergySources, index, energySource)
    },
    removeObjectEnergySource: (state, energySource) => {
      Vue.set(state, 'hasChanged', true)
      state.removedObjectEnergySources.push(energySource)
      const index = state.objectEnergySources
        .findIndex(ins => (ins.id === energySource.id && ins.id) ||
          (ins.internalId === energySource.internalId && ins.internalId))
      Vue.delete(state.objectEnergySources, index)
    },
    updateBuildingEnergySource: (state, energySource) => {
      Vue.set(state, 'hasChanged', true)
      const index = state.buildingEnergySources
        .findIndex(ins => (ins.id === energySource.referenceId && ins.id) ||
          (ins.internalId === energySource.internalId && ins.internalId))

      energySource.modified = true

      Vue.set(state.buildingEnergySources, index, energySource)
    },
    removeBuildingEnergySource: (state, energySource) => {
      Vue.set(state, 'hasChanged', true)
      state.removedBuildingEnergySources.push(energySource)
      const index = state.buildingEnergySources
        .findIndex(ins => (ins.id === energySource.id && ins.id) ||
          (ins.internalId === energySource.internalId && ins.internalId))
      Vue.delete(state.buildingEnergySources, index)
    },
    // removeEnergySource: (state, energySourceIndex) => {
    //   Vue.set(state, 'hasChanged', true)
    //   state.building.modified = true
    //   Vue.delete(state.energySources, energySourceIndex)
    // },
    setObjects: (state, data) => {
      const objects = data.objects
      const tmpObjListe = objects.sort(function (a, b) {
        return (a.number < b.number) ? -1 : (a.number > b.number) ? 1 : 0
      })
      let listobject = []

      const objecWithPositiveFloor = tmpObjListe.filter(x => x.characteristics && x.characteristics.floor !== -10000 && x.characteristics.floor >= 0).sort(function (a, b) {
        return (a.characteristics.floor < b.characteristics.floor) ? -1 : (a.characteristics.floor > b.characteristics.floor) ? 1 : 0
      })
      const objecWithNegativeFloor = tmpObjListe.filter(x => x.characteristics && x.characteristics.floor !== -10000 && x.characteristics.floor < 0).sort(function (a, b) {
        return (a.characteristics.floor < b.characteristics.floor) ? 1 : (a.characteristics.floor > b.characteristics.floor) ? -1 : 0
      })
      const objecWithoutFloor = tmpObjListe.filter(x => x.characteristics && x.characteristics.floor === -10000)

      const translatedCategories = [
        i18n.t('message.onBoarding.buildings.objects.category.plot'),
        i18n.t('message.onBoarding.buildings.objects.category.house'),
        i18n.t('message.onBoarding.buildings.objects.category.apartment'),
        i18n.t('message.onBoarding.buildings.objects.category.commercial'),
        i18n.t('message.onBoarding.buildings.objects.category.gastronomy'),
        i18n.t('message.onBoarding.buildings.objects.category.agriculture'),
        i18n.t('message.onBoarding.buildings.objects.category.garden'),
        i18n.t('message.onBoarding.buildings.objects.category.secondary_room'),
        i18n.t('message.onBoarding.buildings.objects.category.parking_space'),
        i18n.t('message.onBoarding.buildings.objects.category.undefined_category')
      ]
      translatedCategories.forEach(element => {
        const categoryObject = objecWithPositiveFloor.concat(objecWithNegativeFloor).concat(objecWithoutFloor).filter((obj) => {
          return i18n.t('message.onBoarding.buildings.objects.category.' + obj.category.toLowerCase()) === element
        })
        if (categoryObject.length > 0) {
          listobject = listobject.concat(categoryObject)
        }
      })
      const objectWithCategoryApartment = listobject.filter((obj) => {
        return i18n.t('message.onBoarding.buildings.objects.category.' + obj.category.toLowerCase()) === i18n.t('message.onBoarding.buildings.objects.category.apartment')
      })
      const objectWithCategoryHouse = listobject.filter((obj) => {
        return i18n.t('message.onBoarding.buildings.objects.category.' + obj.category.toLowerCase()) === i18n.t('message.onBoarding.buildings.objects.category.house')
      })
      const objectWithCategoryGastronomy = listobject.filter((obj) => {
        return i18n.t('message.onBoarding.buildings.objects.category.' + obj.category.toLowerCase()) === i18n.t('message.onBoarding.buildings.objects.category.gastronomy')
      })
      const objectWithCategoryCommercial = listobject.filter((obj) => {
        return i18n.t('message.onBoarding.buildings.objects.category.' + obj.category.toLowerCase()) === i18n.t('message.onBoarding.buildings.objects.category.commercial')
      })
      const objectWithCategorySecondaryroom = listobject.filter((obj) => {
        return i18n.t('message.onBoarding.buildings.objects.category.' + obj.category.toLowerCase()) === i18n.t('message.onBoarding.buildings.objects.category.secondary_room')
      })
      const objectWithCategoryGarden = listobject.filter((obj) => {
        return i18n.t('message.onBoarding.buildings.objects.category.' + obj.category.toLowerCase()) === i18n.t('message.onBoarding.buildings.objects.category.garden')
      })
      const objectWithCategoryPlot = listobject.filter((obj) => {
        return i18n.t('message.onBoarding.buildings.objects.category.' + obj.category.toLowerCase()) === i18n.t('message.onBoarding.buildings.objects.category.plot')
      })
      const objectWithCategoryAgriculture = listobject.filter((obj) => {
        return i18n.t('message.onBoarding.buildings.objects.category.' + obj.category.toLowerCase()) === i18n.t('message.onBoarding.buildings.objects.category.agriculture')
      })
      const objectWithCategoryParkingSpace = listobject.filter((obj) => {
        return i18n.t('message.onBoarding.buildings.objects.category.' + obj.category.toLowerCase()) === i18n.t('message.onBoarding.buildings.objects.category.parking_space')
      })
      const objectWithoutCategory = listobject.filter((obj) => {
        return i18n.t('message.onBoarding.buildings.objects.category.' + obj.category.toLowerCase()) === i18n.t('message.onBoarding.buildings.objects.category.undefined_category')
      })
      Vue.set(state, 'objects', objectWithCategoryApartment.concat(objectWithCategoryHouse).concat(objectWithCategoryGastronomy).concat(objectWithCategoryCommercial).concat(objectWithCategorySecondaryroom).concat(objectWithCategoryParkingSpace).concat(objectWithCategoryGarden).concat(objectWithCategoryPlot).concat(objectWithCategoryAgriculture).concat(objectWithoutCategory))
    },
    setAllObjects: (state, data) => {
      Vue.set(state, 'allObjects', data)
    },
    // addNewObjects: (state, objects) => {
    //   // We don't need to set the hasChanged flag since we update the building immediately after the object was created
    //   state.building.objectIds.unshift(objects.map(x => x.id))
    //   state.objects.unshift(objects.map(x => x.id))
    // },
    updateObject: (state, object) => {
      Vue.set(state, 'hasChanged', true)
      Vue.set(state, 'hasChangedObject', true)

      const index = state.objects.findIndex(obj => (obj.id === object.id))

      object.modified = true
      if (!object.subCategories || object.subCategories.length === 0) {
        object.subCategories = ['UNDEFINED_SUB_CATEGORY']
      }
      if (!object.utilizations || object.utilizations.length === 0) {
        object.utilizations = ['UNDEFINED_UTILIZATION']
      }

      if (!object.characteristics.areas.livingArea || isNaN(object.characteristics.areas.livingArea)) {
        object.characteristics.areas.livingArea = -1
      }
      if (!object.characteristics.areas.netLivingArea || isNaN(object.characteristics.areas.netLivingArea)) {
        object.characteristics.areas.netLivingArea = -1
      }
      if (!object.characteristics.areas.plotArea || isNaN(object.characteristics.areas.plotArea)) {
        object.characteristics.areas.plotArea = -1
      }
      if (!object.characteristics.areas.effectiveArea || isNaN(object.characteristics.areas.effectiveArea)) {
        object.characteristics.areas.effectiveArea = -1
      }

      if (!object.pricing.upperMarketNetRent || isNaN(object.pricing.upperMarketNetRent)) {
        object.pricing.upperMarketNetRent = -1
      }
      if (!object.pricing.upperMarketHeatingAndAdditional || isNaN(object.pricing.upperMarketHeatingAndAdditional)) {
        object.pricing.upperMarketHeatingAndAdditional = -1
      }
      if (!object.pricing.upperMarketGrossRent || isNaN(object.pricing.upperMarketGrossRent)) {
        object.pricing.upperMarketGrossRent = -1
      }
      if (object.pricing.underMarketNetRent === null || isNaN(object.pricing.underMarketNetRent)) {
        object.pricing.underMarketNetRent = -1
      }
      if (object.pricing.underMarketHeatingAndAdditional === null || isNaN(object.pricing.underMarketGrossRent)) {
        object.pricing.underMarketHeatingAndAdditional = -1
      }
      if (object.pricing.underMarketGrossRent === null || isNaN(object.pricing.underMarketGrossRent)) {
        object.pricing.underMarketGrossRent = -1
      }
      if (!object.pricing.marketNetRent || isNaN(object.pricing.marketNetRent)) {
        object.pricing.marketNetRent = -1
      }
      if (!object.pricing.marketHeatingAndAdditional || isNaN(object.pricing.marketHeatingAndAdditional)) {
        object.pricing.marketHeatingAndAdditional = -1
      }
      if (!object.pricing.marketGrossRent || isNaN(object.pricing.marketGrossRent)) {
        object.pricing.marketGrossRent = -1
      }
      if (object.pricing.targetMarketNetRent === null || isNaN(object.pricing.targetMarketNetRent)) {
        object.pricing.targetMarketNetRent = -1
      }
      if (object.pricing.targetMarketHeatingAndAdditional === null || isNaN(object.pricing.targetMarketHeatingAndAdditional)) {
        object.pricing.targetMarketHeatingAndAdditional = -1
      }
      if (object.pricing.targetMarketGrossRent === null || isNaN(object.pricing.targetMarketGrossRent)) {
        object.pricing.targetMarketGrossRent = -1
      }
      // Floor needs to be handled separately since -1 floor exists
      if (object.characteristics.floor === null || isNaN(object.characteristics.floor)) {
        object.characteristics.floor = -10000
      }
      const nullableCharacteristics = ['volume', 'numberOfFloors', 'floorLoad', 'rooms',
        'bathrooms', 'showers', 'toilets', 'guestToilets', 'separateToilets', 'yearBuilt', 'yearLastRenovation',
        'ceilingHeight', 'hallHeight', 'grossPremium']
      for (const nullableChar of nullableCharacteristics) {
        if (!object.characteristics[nullableChar] || isNaN(object.characteristics[nullableChar])) {
          object.characteristics[nullableChar] = -1
        }
      }
      Vue.set(state.objects, index, object)
    },
    removeObject: (state, object) => {
      const index = state.objects
        .findIndex(obj => (obj.id === object.id))
      Vue.delete(state.objects, index)

      // Remove object from building
      const buildingIndex = state.building.objectIds
        .findIndex(objId => (objId === object.id))
      Vue.delete(state.building.objectIds, buildingIndex)
    },
    setObjectDocuments: (state, data) => {
      Vue.set(state, 'objectDocuments', data.documents)
    },
    setTenancies: (state, tenancies) => {
      Vue.set(state, 'tenancies', tenancies)
    },
    setCondominiumOwnership: (state, condominiumOwnership) => {
      Vue.set(state, 'condominiumOwnership', condominiumOwnership)
    },
    setAssigneeContacts: (state, contacts) => {
      Vue.set(state, 'assigneeContacts', contacts)
    },
    setContacts: (state, contacts) => {
      Vue.set(state, 'contacts', contacts)
    },
    addContacts: (state, contacts) => {
      Vue.set(state, 'contacts', state.contacts.concat(contacts))
    },
    setEmployeeContacts: (state, contacts) => {
      Vue.set(state, 'employeesContacts', contacts)
    },
    setObjectOwnerContacts: (state, objectOwnerContacts) => {
      Vue.set(state, 'objectOwnerContacts', objectOwnerContacts)
    },
    setShares: (state, shares) => {
      Vue.set(state, 'shares', shares)
    },
    setOwnerAccount: (state, ownerAccount) => {
      Vue.set(state, 'ownerAccount', ownerAccount)
    },
    setNetworkPartners: (state, networkPartners) => {
      Vue.set(state, 'networkPartners', networkPartners)
    },
    addNewCondominiumOwnership: (state, objectId) => {
      Vue.set(state, 'hasChanged', true)
      state.condominiumOwnership.push({
        activated: false,
        internalId: Date.now(),
        ownerId: state.building.ownerId,
        objectId: objectId,
        finalInvoiceCreated: false,
        closed: false,
        contract: {
          intendedUse: '',
          licensePlate: '',
          typeOfUse: ''
        },
        tenants: [],
        status: 'CONDOMINIUM_STATUS_NOT_ACTIVATED',
        secondaryResidence: false,
        startingAt: null,
        endingAt: null,
        documentIds: [],
        rent: {
          adjustments: [
            {
              byDate: null,
              flatRateValues: [{
                type: 'FLAT_RATE_RENT_COMPONENT_BUDGET_EXPECTATIONS',
                value: -1
              },
                {
                  type: 'FLAT_RATE_RENT_COMPONENT_RENOVATION_FUND',
                  value: -1
                }],
              dueDates: [1, 4, 7, 10],
              newItem: true
            }
          ]
        },
        isNewItem: true,
        owner: {
          contactIds: [],
          numberOfPersons: -1,
          billingContactId: '',
          correspondenceContactId: '',
          roommateContactIds: []
        },
        flatRateValues: [],
        opted: false,
        taxable: null
      })
    },
    addNewTenancy: (state, objectId) => {
      Vue.set(state, 'hasChanged', true)
      state.tenancies.push({
        internalId: Date.now(),
        activated: false,
        closed: false,
        ownerId: state.building.ownerId,
        objectId: objectId,
        vacancy: false,
        opted: false,
        taxable: null,
        contract: {
          activationDate: null,
          creationDate: null,
          number: '',
          type: 'UNDEFINED_CONTRACT_TYPE',
          rentType: '',
          typeOfUse: ''
        },
        subTenants: [],
        roommates: [],
        tenant: {
          contactIds: [],
          industryName: '',
          numberOfPersons: -1,
          type: 'UNDEFINED_TENANT_TYPE',
          copyOfContactId: '',
          billingContactId: '',
          correspondenceContactId: '',
          guarantorContactIds: []
        },
        deposit: {
          type: 'DEPOSIT_TYPE_NO_DEPOSIT',
          depositShares: [],
          address: {
            country: 'CH',
            state: '',
            zip: '',
            city: '',
            street: '',
            streetNumber: ''
          },
          amount: -1,
          dueDate: null,
          paidAt: null,
          paidAmount: -1,
          certificateReceivedAt: null,
          clearingNumber: -1,
          bic: '',
          bankAccount: null,
          bankName: null
        },
        indexData: {
          additionalCostsAdjustmentAllowed: false,
          belowInitialRentAllowed: false,
          maximumRate: null,
          referenceMonthForIndex: null,
          rentAdjustmentAdditionalBenefit: false,
          rentAdjustmentByMonths: null,
          rentAdjustmentByPoints: null,
          rentAdjustmentMonths: [],
          contractPercentImputedAtIndex: 100,
          rentAdjustmentNotification: {
            unit: 'RENT_ADJUSTMENT_NOTIFICATION_MONTHS',
            value: null
          }
        },
        renewalOptions: [],
        earlyRightOfTerminationOptions: [],
        rent: {
          additionalCosts: -1,
          coldRent: -1,
          heatingCosts: -1,
          additionalCostsBillingType: 'ADVANCE_PAYMENT_ADDITIONAL_COSTS_BILLING_TYPE',
          countryIndex: {
            baseDate: null,
            date: null,
            inflation: -1
          },
          referenceInterestRate: {
            date: null,
            rate: -10000,
            ratePossible: -10000
          },
          costIncreaseType: 'COST_INCREASE_TYPE_FLAT_RATE',
          costIncreaseDate: null,
          costIncreaseValue: -1,
          monthlyRentDueBy: 1,
          adjustments: [
            {
              calculationBasis: '',
              byDate: null,
              dueDates: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              additionalCosts: -1,
              coldRentM2PerYear: -1,
              coldRentPerMonth: -1,
              coldRent: -1,
              heatingCosts: -1,
              additionalCostsBillingType: 'UNDEFINED_ADDITIONAL_COSTS_BILLING_TYPE',
              countryIndex: {
                baseDate: null,
                date: null,
                inflation: -1,
                possibleDate: null,
                possibleInflation: -1
              },
              referenceInterestRate: {
                date: null,
                rate: -10000,
                ratePossible: -10000
              },
              costIncreaseType: 'COST_INCREASE_TYPE_FLAT_RATE',
              costIncreaseDate: null,
              costIncreaseValue: -1,
              costIncreasePossibleDate: null,
              monthlyRentDueBy: 1,
              inArrears: false,
              advancePaymentValues: [],
              flatRateValues: [],
              additionalValues: [],
              retentionOfRents: [],
              documentIds: []
            }
          ],
          dueDates: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
          advancePaymentValues: [],
          flatRateValues: [],
          additionalValues: []
        },
        terminationTenant: {
          earliestDate: null,
          possibleMonths: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
          periodInMonths: -1
        },
        terminationLandlord: {
          earliestDate: null,
          possibleMonths: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
          periodInMonths: -1
        },
        startingAt: null,
        endingAt: null,
        liabilityEndsAt: null,
        documentIds: [],
        isNewItem: true,
        terminatedBy: 'TERMINATED_BY_UNDEFINED',
        terminationType: 'TERMINATION_TYPE_UNDEFINED',
        terminationReason: [],
        terminationCreatedAt: null,
        terminatedOn: null,
        terminationJustification: '',
        movedOutAt: null,
        nextPossibleMoveInAt: null
      })
    },
    addNewVacancy: (state, objectId) => {
      Vue.set(state, 'hasChanged', true)
      state.tenancies.push({
        internalId: Date.now(),
        activated: true,
        closed: false,
        ownerId: state.building.ownerId,
        objectId: objectId,
        vacancy: true,
        opted: false,
        taxable: null,
        vacancyReason: '',
        contract: {
          activationDate: null,
          creationDate: null,
          number: '',
          type: 'UNDEFINED_CONTRACT_TYPE',
          rentType: '',
          typeOfUse: ''
        },
        indexData: {
          additionalCostsAdjustmentAllowed: false,
          belowInitialRentAllowed: false,
          maximumRate: null,
          referenceMonthForIndex: null,
          rentAdjustmentAdditionalBenefit: false,
          rentAdjustmentByMonths: null,
          rentAdjustmentByPoints: null,
          rentAdjustmentMonths: [],
          contractPercentImputedAtIndex: 100,
          rentAdjustmentNotification: {
            unit: 'RENT_ADJUSTMENT_NOTIFICATION_MONTHS',
            value: null
          }
        },
        tenant: {
          contactIds: [],
          industryName: '',
          numberOfPersons: -1,
          type: 'UNDEFINED_TENANT_TYPE',
          copyOfContactId: '',
          billingContactId: '',
          correspondenceContactId: '',
          guarantorContactIds: []
        },
        deposit: {
          type: 'DEPOSIT_TYPE_NO_DEPOSIT',
          address: {
            country: 'CH',
            state: '',
            zip: '',
            city: '',
            street: '',
            streetNumber: ''
          },
          amount: -1,
          dueDate: null,
          paidAt: null,
          paidAmount: -1,
          certificateReceivedAt: null
        },
        rent: {
          additionalCosts: -1,
          coldRent: -1,
          heatingCosts: -1,
          additionalCostsBillingType: 'ADVANCE_PAYMENT_ADDITIONAL_COSTS_BILLING_TYPE',
          countryIndex: {
            baseDate: null,
            date: null,
            inflation: -1
          },
          referenceInterestRate: {
            date: null,
            rate: -10000,
            ratePossible: -10000
          },
          costIncreaseType: 'COST_INCREASE_TYPE_FLAT_RATE',
          costIncreaseDate: null,
          costIncreaseValue: -1,
          adjustments: [
            {
              calculationBasis: '',
              byDate: null,
              dueDates: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
              additionalCosts: -1,
              coldRent: -1,
              coldRentM2PerYear: -1,
              coldRentPerMonth: -1,
              heatingCosts: -1,
              additionalCostsBillingType: 'UNDEFINED_ADDITIONAL_COSTS_BILLING_TYPE',
              countryIndex: {
                baseDate: null,
                date: null,
                inflation: -1
              },
              referenceInterestRate: {
                date: null,
                rate: -10000,
                ratePossible: -10000
              },
              costIncreaseType: 'COST_INCREASE_TYPE_FLAT_RATE',
              costIncreaseDate: null,
              costIncreaseValue: -1,
              monthlyRentDueBy: 1,
              inArrears: false,
              advancePaymentValues: [],
              additionalValues: [],
              flatRateValues: [],
              retentionOfRents: [],
              documentIds: []
            }
          ],
          dueDates: [],
          advancePaymentValues: [],
          additionalValues: [],
          flatRateValues: []
        },
        terminationTenant: {
          earliestDate: null,
          possibleMonths: [],
          periodInMonths: -1
        },
        terminationLandlord: {
          earliestDate: null,
          possibleMonths: [],
          periodInMonths: -1
        },
        startingAt: null,
        endingAt: null,
        documentIds: [],
        isNewItem: true
      })
    },
    enterTermination: (state) => {
      Vue.set(state, 'hasChanged', true)
    },
    updateTenancy: (state, { tenancy, alreadySaved = false }) => {
      if (!alreadySaved) {
        Vue.set(state, 'hasChanged', true)
        tenancy.modified = true
      }
      const index = state.tenancies
        .findIndex(ten => (ten.id === tenancy.id && tenancy.id) ||
          (ten.internalId === tenancy.internalId && ten.internalId))

      if (!tenancy.deposit.amount || isNaN(tenancy.deposit.amount)) {
        tenancy.deposit.amount = -1
      }
      if (!tenancy.deposit.paidAmount || isNaN(tenancy.deposit.paidAmount)) {
        tenancy.deposit.paidAmount = -1
      }
      if (tenancy.rent.coldRent === null || isNaN(tenancy.rent.coldRent)) {
        tenancy.rent.coldRent = -1
      }
      if (!tenancy.tenant.numberOfPersons || isNaN(tenancy.tenant.numberOfPersons)) {
        tenancy.tenant.numberOfPersons = -1
      }
      if (tenancy.rent.additionalCosts === null || isNaN(tenancy.rent.additionalCosts)) {
        tenancy.rent.additionalCosts = -1
      }
      if (tenancy.rent.heatingCosts === null || isNaN(tenancy.rent.heatingCosts)) {
        tenancy.rent.heatingCosts = -1
      }
      if (tenancy.rent.countryIndex.inflation === null || isNaN(tenancy.rent.countryIndex.inflation)) {
        tenancy.rent.countryIndex.inflation = -1
      }
      if (tenancy.rent.referenceInterestRate.rate === null || isNaN(tenancy.rent.referenceInterestRate.rate)) {
        tenancy.rent.referenceInterestRate.rate = -10000
      }
      if (tenancy.rent.referenceInterestRate.ratePossible === null || isNaN(tenancy.rent.referenceInterestRate.ratePossible)) {
        tenancy.rent.referenceInterestRate.ratePossible = -10000
      }
      if (tenancy.rent.costIncreaseValue === null || isNaN(tenancy.rent.costIncreaseValue)) {
        tenancy.rent.costIncreaseValue = -1
      }
      for (const adjustment of tenancy.rent.adjustments) {
        if (adjustment.coldRent === null || isNaN(adjustment.coldRent)) {
          adjustment.coldRent = -1
        }
        if (adjustment.additionalCosts === null || isNaN(adjustment.additionalCosts)) {
          adjustment.additionalCosts = -1
        }
        if (adjustment.heatingCosts === null || isNaN(adjustment.heatingCosts)) {
          adjustment.heatingCosts = -1
        }
        if (adjustment.countryIndex.inflation === null || isNaN(adjustment.countryIndex.inflation)) {
          adjustment.countryIndex.inflation = -1
        }
        if (adjustment.referenceInterestRate.rate === null || isNaN(adjustment.referenceInterestRate.rate)) {
          adjustment.referenceInterestRate.rate = -10000
        }
        if (adjustment.referenceInterestRate.ratePossible === null || isNaN(adjustment.referenceInterestRate.ratePossible)) {
          adjustment.referenceInterestRate.ratePossible = -10000
        }
        if (adjustment.costIncreaseValue === null || isNaN(adjustment.costIncreaseValue)) {
          adjustment.costIncreaseValue = -1
        }

        for (const adjAdvancePayment of adjustment.advancePaymentValues) {
          if ((!adjAdvancePayment.value && adjAdvancePayment.value !== 0) || isNaN(adjAdvancePayment.value)) {
            adjAdvancePayment.value = -1
          }
        }
        for (const adjFlatRate of adjustment.flatRateValues) {
          if ((!adjFlatRate.value && adjFlatRate.value !== 0) || isNaN(adjFlatRate.value)) {
            adjFlatRate.value = -1
          }
        }
        for (const adjAdditional of adjustment.additionalValues) {
          if ((!adjAdditional.value && adjAdditional.value !== 0) || isNaN(adjAdditional.value)) {
            adjAdditional.value = -1
          }
        }
      }
      for (const advancePayment of tenancy.rent.advancePaymentValues) {
        if (!advancePayment.value || isNaN(advancePayment.value)) {
          advancePayment.value = -1
        }
      }
      for (const flatRate of tenancy.rent.flatRateValues) {
        if (!flatRate.value || isNaN(flatRate.value)) {
          flatRate.value = -1
        }
      }
      Vue.set(state.tenancies, index, tenancy)
    },
    updateCondominiumOwnership: (state, condominium) => {
      Vue.set(state, 'hasChanged', true)
      const index = state.condominiumOwnership
        .findIndex(ten => (ten.id === condominium.id && condominium.id) ||
          (ten.internalId === condominium.internalId && ten.internalId))

      condominium.modified = true
      if (!condominium.owner.numberOfPersons || isNaN(condominium.owner.numberOfPersons)) {
        condominium.owner.numberOfPersons = -1
      }
      Vue.set(state.condominiumOwnership, index, condominium)
    },
    removeTenancy: (state, tenancy) => {
      Vue.set(state, 'hasChanged', true)

      state.removedTenancies.push(tenancy)
      const index = state.tenancies
        .findIndex(ten => (ten.id === tenancy.id && ten.id) ||
          (ten.internalId === tenancy.internalId && ten.internalId))
      Vue.delete(state.tenancies, index)
    },
    removeTenancyInstant: (state, tenancyId) => {
      Vue.set(state, 'hasChanged', true)

      const index = state.tenancies
        .findIndex(ten => (ten.id === tenancyId))
      Vue.delete(state.tenancies, index)
    },
    removeCondominiumOwnership: (state, condominiumOwnership) => {
      Vue.set(state, 'hasChanged', true)
      state.removedCondominium.push(condominiumOwnership)
      const index = state.condominiumOwnership.findIndex(ten => (ten.id === condominiumOwnership.id && ten.id) || (ten.internalId === condominiumOwnership.internalId && ten.internalId))
      Vue.delete(state.condominiumOwnership, index)
    },
    removeCondominiumInstant: (state, condominiumOwnershipId) => {
      Vue.set(state, 'hasChanged', true)
      const index = state.condominiumOwnership.findIndex(ten => (ten.id === condominiumOwnershipId))
      Vue.delete(state.condominiumOwnership, index)
    },
    setTenancyDocuments: (state, data) => {
      Vue.set(state, 'tenancyDocuments', data.documents)
    },
    setTenancyContracts: (state, data) => {
      Vue.set(state, 'tenancyContracts', data.templates)
    },
    setCondominiumDocuments: (state, data) => {
      Vue.set(state, 'condominiumDocuments', data.documents)
    },
    setCondominiumAdjustmentDocuments: (state, data) => {
      Vue.set(state, 'condominiumAdjustmentDocuments', data.documents)
    },
    setObjectDevices: (state, devices) => {
      Vue.set(state, 'objectDevices', devices)
    },
    setComponents: (state, components) => {
      const order = ['building_', 'obj_']
      const getOrderIndex = (id) => order.findIndex(prefix => id.startsWith(prefix))

      // Group components by referenceId
      const groupedComponents = components.reduce((acc, component) => {
        const key = component.referenceId
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(component)
        return acc
      }, {})

      // Sort each group by bkp
      for (const key in groupedComponents) {
        groupedComponents[key].sort((a, b) => {
          if (a.bkp === '' && b.bkp !== '') return 1
          if (a.bkp !== '' && b.bkp === '') return -1
          return a.bkp.localeCompare(b.bkp)
        })
      }

      // Separate objects from properties and buildings
      const objects = Object.values(groupedComponents).flat().filter(component => component.referenceId.startsWith('obj_'))
      const buildings = Object.values(groupedComponents).flat().filter(component => component.referenceId.startsWith('building_'))

      // Sort the objects by number (numeric sorting)
      objects.sort((a, b) => {
        const objA = state.objects.find(obj => obj.id === a.referenceId)
        const objB = state.objects.find(obj => obj.id === b.referenceId)

        const numA = parseInt(objA?.number)
        const numB = parseInt(objB?.number)

        if (!isNaN(numA) && !isNaN(numB)) {
          return numA - numB
        } else if (!isNaN(numA)) {
          return -1
        } else if (!isNaN(numB)) {
          return 1
        } else {
          return objA?.number.localeCompare(objB?.number)
        }
      })
      const list = [...buildings, ...objects]

      // Flatten the sorted groups and sort by type and optionalValueEnhancingComponent
      const sortedList = list.sort((a, b) => {
        const isOptionalA = a.optionalValueEnhancingComponent
        const isOptionalB = b.optionalValueEnhancingComponent

        if (isOptionalA && isOptionalB) {
          return getOrderIndex(a.referenceId) - getOrderIndex(b.referenceId)
        }

        if (isOptionalA) {
          return 1
        }
        if (isOptionalB) {
          return -1
        }

        return getOrderIndex(a.referenceId) - getOrderIndex(b.referenceId)
      })
      Vue.set(state, 'components', sortedList)
    },
    setPortalLinks: (state, components) => {
      Vue.set(state, 'portalLinks', components)
    },
    setHandoverProtocols: (state, handoverProtocols) => {
      Vue.set(state, 'handoverProtocols', handoverProtocols)
    },
    setObjectRooms: (state, rooms) => {
      Vue.set(state, 'objectRooms', rooms)
    },
    addNewObjectDevice: (state, objectId) => {
      Vue.set(state, 'hasChanged', true)
      state.objectDevices.push({
        internalId: Date.now(),
        ownerId: state.building.ownerId,
        numericId: 99999,
        referenceId: objectId,
        installation: null,
        uninstallation: null,
        measureUntil: null,
        measure: 'DEVICE_MEASURE_UNDEFINED',
        type: undefined,
        system: undefined,
        facilityPlant: undefined,
        bkpH: undefined,
        lifeSpan: -1,
        amount: -1,
        acquisitionCosts: -1,
        unit: 'DEVICE_UNIT_UNDEFINED',
        cataloguePrice: -1,
        maintenance: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastMaintenance: null,
          nextMaintenance: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        inspection: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastOfficialControl: null,
          nextOfficialControl: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        revision: {
          contact: {
            userReference: '',
            accountId: -1
          },
          costs: {
            currency: 'CHF',
            interval: 'UNDEFINED_INTERVAL'
          },
          lastOfficialRevision: null,
          nextOfficialRevision: null,
          subscriptionStart: null,
          subscriptionEnd: null,
          note: ''
        },
        condition: 'DEVICE_CONDITION_UNDEFINED',
        note: '',
        documentIds: [],
        images: [],
        isNewItem: true,
        lockingKey: {
          combinedKeys: false,
          combinedKeysNote: '',
          description: '',
          lockingPlanNumber: ''
        }
      })
    },
    addComponent: (state, { refId, optionalValue = false }) => {
      Vue.set(state, 'hasChanged', true)
      const internalId = Date.now() + Math.floor(Math.random() * 1000)
      const component = JSON.parse(JSON.stringify(helper.emptyComponent))
      component.internalId = JSON.parse(JSON.stringify(internalId))
      component.ownerId = state.building.ownerId
      component.referenceId = refId
      component.optionalValueEnhancingComponent = optionalValue
      if (optionalValue) {
        state.components.push(component)
      } else {
        state.components.unshift(component)
      }
    },
    updateComponent: (state, component) => {
      Vue.set(state, 'hasChanged', true)
      const index = state.components
        .findIndex(comp => (comp.id === component.id && comp.id) ||
          (comp.internalId === component.internalId && comp.internalId))

      component.modified = true

      Vue.set(state.components, index, component)
    },
    removeComponent: (state, component) => {
      Vue.set(state, 'hasChanged', true)

      state.removedComponents.push(component)
      const index = state.components
        .findIndex(dev => (dev.id === component.id && dev.id) ||
          (dev.internalId === component.internalId && dev.internalId))
      Vue.delete(state.components, index)
    },
    addPortalLink: (state, refId) => {
      Vue.set(state, 'hasChanged', true)
      const internalId = Date.now() + Math.floor(Math.random() * 1000)
      const item = {
        internalId: internalId,
        name: '',
        referenceId: refId,
        url: ''
      }
      state.portalLinks.push(item)
    },
    updatePortalLink: (state, item) => {
      Vue.set(state, 'hasChanged', true)
      const index = state.portalLinks
        .findIndex(link => (link.id === item.id && link.id) ||
          (link.internalId === item.internalId && link.internalId))
      if (item.id) {
        item.modified = true
      }

      Vue.set(state.portalLinks, index, item)
    },
    removePortalLink: (state, item) => {
      Vue.set(state, 'hasChanged', true)

      state.removedPortalLinks.push(item)
      const index = state.portalLinks
        .findIndex(link => (link.id === item.id && link.id) ||
          (link.internalId === item.internalId && link.internalId))
      Vue.delete(state.portalLinks, index)
    },
    addNewObjectRooms: (state, objectId) => {
      Vue.set(state, 'hasChanged', true)
      const rooms = []
      rooms.push({
        internalId: Date.now(),
        dimensions: {
          area: null,
          height: null,
          length: null,
          volume: null,
          width: null
        },
        documentIds: [],
        imageMediaIds: [],
        name: '',
        note: '',
        ownerId: state.building.ownerId,
        referenceId: objectId
      })
      RoomApi.createRooms(rooms).then(res => {
        const newRoom = res.rooms[0]
        newRoom.isNewItem = true
        state.objectRooms.push(newRoom)
      })
    },
    duplicateObjectDevice: (state, device) => {
      Vue.set(state, 'hasChanged', true)

      const duplicatedDevice = copyObject(device)
      delete duplicatedDevice.id
      duplicatedDevice.internalId = Date.now()
      duplicatedDevice.documentIds = []
      state.objectDevices.push(duplicatedDevice)
    },
    updateObjectDevice: (state, device) => {
      Vue.set(state, 'hasChanged', true)
      const index = state.objectDevices
        .findIndex(dev => (dev.id === device.id && dev.id) ||
          (dev.internalId === device.internalId && dev.internalId))

      device.modified = true
      if (isNaN(device.acquisitionCosts)) {
        device.acquisitionCosts = -1
      }
      if (isNaN(device.cataloguePrice)) {
        device.cataloguePrice = -1
      }
      if (isNaN(device.amount)) {
        device.amount = -1
      }
      if (isNaN(device.lifeSpan)) {
        device.lifeSpan = -1
      }
      if (device.maintenance && isNaN(device.maintenance.costs.amount)) {
        device.maintenance.costs.amount = -1
      }
      if (device.inspection && isNaN(device.inspection.costs.amount)) {
        device.inspection.costs.amount = -1
      }
      if (device.revision && isNaN(device.revision.costs.amount)) {
        device.revision.costs.amount = -1
      }

      Vue.set(state.objectDevices, index, device)
    },
    updateObjectRoom: (state, room) => {
      Vue.set(state, 'hasChanged', true)
      const index = state.objectRooms
        .findIndex(rm => (rm.id === room.id && rm.id) ||
          (rm.internalId === room.internalId && rm.internalId))

      room.modified = true

      Vue.set(state.objectRooms, index, room)
    },
    removeObjectDevice: (state, device) => {
      Vue.set(state, 'hasChanged', true)

      state.removedObjectDevices.push(device)
      const index = state.objectDevices
        .findIndex(dev => (dev.id === device.id && dev.id) ||
          (dev.internalId === device.internalId && dev.internalId))
      Vue.delete(state.objectDevices, index)
    },
    removeObjectRoom: (state, room) => {
      Vue.set(state, 'hasChanged', true)

      state.removedObjectRooms.push(room)
      const index = state.objectRooms
        .findIndex(dev => (dev.id === room.id && dev.id) ||
          (dev.internalId === room.internalId && dev.internalId))
      // delete devices that have the removed room
      const devices = state.objectDevices.filter(device => device.roomId === room.id)
      for (const device of devices) {
        state.removedObjectDevices.push(device)
        const deviceIndex = state.objectDevices
          .findIndex(dev => (dev.id === device.id && dev.id) ||
            (dev.internalId === device.internalId && dev.internalId))
        Vue.delete(state.objectDevices, deviceIndex)
      }
      Vue.delete(state.objectRooms, index)
    },
    setObjectDeviceDocuments: (state, data) => {
      Vue.set(state, 'objectDeviceDocuments', data.documents)
    },
    setObjectRoomDocuments: (state, data) => {
      Vue.set(state, 'objectRoomDocuments', data.documents)
    },
    resetChanged: (state) => {
      Vue.set(state, 'hasChanged', false)
    },
    setChangedTenant: (state) => {
      Vue.set(state, 'hasChangedTenant', true)
    },
    activateChanged: (state) => {
      Vue.set(state, 'hasChanged', true)
    },
    clear: (state) => {
      Vue.set(state, 'hasChanged', false)
      Vue.set(state, 'building', null)
    },
    setOpenedCollapses: (state) => {
      const itemsNotCollapsed = document.querySelectorAll('.collapse.show')
      const arrayNotCollapsed = []
      for (let i = 0; i < itemsNotCollapsed.length; i++) {
        arrayNotCollapsed.push(itemsNotCollapsed[i].id)
      }
      Vue.set(state, 'openedCollapses', arrayNotCollapsed)
    },
    setPropertyBuildings: (state, propertyBuildings) => {
      Vue.set(state, 'propertyBuildings', propertyBuildings)
    }
  },
  actions: {
    load: async ({ commit, state, dispatch, getters }, buildingId) => {
      // Hacky fix because webpack tries to load the async components and calls this method with the route name
      if (!buildingId.startsWith('building')) {
        return
      }

      commit('setLoading', true)
      state.isHandoverEnabled = false
      state.isPortalLinkEnabled = false
      state.hasChanged = false
      state.building = null
      state.buildingImageMedia = null
      state.buildingDocuments = []
      state.energySourceDocuments = []
      state.buildingDevices = []
      state.buildingRooms = []
      state.removedBuildingDevices = []
      state.removedBuildingRooms = []
      state.buildingDeviceDocuments = []
      state.buildingRoomDocuments = []
      state.insurances = []
      state.mortgages = []
      state.energySources = []
      state.removedEnergySources = []
      state.removedInsurances = []
      state.removedMortgages = []
      state.removedObjectEnergySources = []
      state.removedBuildingEnergySources = []
      state.insuranceDocuments = []
      state.buildingMortgageDocuments = []
      state.objectMortgageDocuments = []
      state.objects = []
      state.removedObjects = []
      state.objectDocuments = []
      state.tenancies = []
      state.condominiumOwnership = []
      state.contacts = []
      state.employeesContacts = []
      state.removedTenancies = []
      state.tenancyDocuments = []
      state.tenancyContracts = []
      state.condominiumDocuments = []
      state.condominiumAdjustmentDocuments = []
      state.objectDevices = []
      state.components = []
      state.portalLinks = []
      state.handoverProtocols = []
      state.objectRooms = []
      state.properties = []
      state.plots = []
      state.removedObjectDevices = []
      state.removedObjectRooms = []
      state.removedComponents = []
      state.removedPortalLinks = []
      state.objectDeviceDocuments = []
      state.objectRoomDocuments = []

      const buildingResponse = await ObjectApi.getBuilding(buildingId)
      if (buildingResponse.building.characteristics === null) {
        buildingResponse.building.characteristics = {
          broadbandInternet: null,
          cableTv: null,
          chargingStation: null,
          childFriendly: null,
          communityGarden: null,
          conciergeService: null,
          elevator: null,
          fiberGlass: null,
          intercom: null,
          isdn: null,
          park: null,
          playground: null,
          satellite: null
        }
      }
      if (buildingResponse.building.rating === null) {
        buildingResponse.building.rating = {
          microLocationRating: null
        }
      }
      if (buildingResponse.building.buildingStrategy === null) {
        buildingResponse.building.buildingStrategy = {
          type: 'BUILDING_STRATEGY_TYPE_UNDEFINED'
        }
      }
      // Load protocols enable
      const HandoverProtocolEnabled = await HandoverProtocolApi.hasHandoverProtocolEnabled(store.getters['user/getAccountId'])
      commit('setHandoverProtocolEnabled', HandoverProtocolEnabled.enabled)

      // Load portal link enable
      const PortalLinkEnabled = await PortalApi.getPortalStatus(buildingResponse.building.ownerId)
      commit('setPortalLinkEnabled', PortalLinkEnabled.enabled)
      if (PortalLinkEnabled.enabled) {
        PortalApi.listLinksByReferenceIds([buildingResponse.building.id])
          .then((response) => {
            commit('setPortalLinks', response.links)
          })
      }
      // Load properties
      const propertyResponse = await ObjectApi.getPropertiesByBuildingIds([buildingResponse.building.id])
      commit('setProperties', propertyResponse.properties)

      if (propertyResponse.properties[0]) {
        PortalApi.getPortalStatus(propertyResponse.properties[0].ownerId)
          .then(responsePortal => {
            commit('setIsPortalLinkEnabled', responsePortal.enabled)
          })
      }

      let valueQuotaWithTotal = {
        totalValueQuota: -1
      }
      if (propertyResponse.properties[0].type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP') {
        valueQuotaWithTotal = await ObjectApi.getTotalValueQuotaForBuilding(buildingResponse.building.id)
      }
      const buildingData = {
        ...buildingResponse.building,
        valueQuotaWithTotal: valueQuotaWithTotal?.totalValueQuota !== -1 ? valueQuotaWithTotal.totalValueQuota : null
      }

      commit('setBuilding', buildingData)
      // commit('setEnergySources', buildingResponse.building.energySources)

      // Load building image
      if (buildingResponse.building.imageMediaId) {
        MediaApi.getMediaByID(buildingResponse.building.imageMediaId)
          .then(media => {
            commit('setBuildingImageMedia', media)
          })
      }
      // Load documents of building
      if (buildingResponse.building.documentIds.length > 0) {
        MediaApi.listDocuments(buildingResponse.building.documentIds)
          .then(documents => {
            commit('setBuildingDocuments', documents)
          })
      }
      // Load documents of energy source
      // if (buildingResponse.building.energySources.length > 0) {
      //   let documentIds = []
      //   const tankReadingsdocumentIds = []
      //   for (const energySource of buildingResponse.building.energySources) {
      //     if (energySource.documentIds.length > 0) {
      //       documentIds = documentIds.concat(energySource.documentIds)
      //     }
      //     for (const tankReading of energySource.tankReadings) {
      //       if (tankReading.documentIds.length > 0) {
      //         tankReadingsdocumentIds.push(tankReading.documentIds)
      //       }
      //     }
      //   }

      //   if (documentIds.length > 0) {
      //     MediaApi.listDocuments(documentIds)
      //       .then(documents => {
      //         commit('setEnergySourceDocuments', documents)
      //       })
      //   }
      //   if (tankReadingsdocumentIds.length > 0) {
      //     MediaApi.listDocuments(tankReadingsdocumentIds)
      //       .then(documents => {
      //         commit('setTankReadingDocuments', documents)
      //       })
      //   }
      // }
      if (buildingResponse.building) {
        // Load building rooms
        RoomApi.getRoomsByReferenceIds([buildingResponse.building.id])
          .then(response => {
            commit('setBuildingRooms', response.rooms)

            // Load documents of building devices
            let documentIds = []
            for (const rooms of response.rooms) {
              if (rooms.documentIds.length > 0) {
                documentIds = documentIds.concat(rooms.documentIds)
              }
            }

            if (documentIds.length > 0) {
              MediaApi.listDocuments(documentIds)
                .then(documents => {
                  commit('setBuildingRoomDocuments', documents)
                })
            }
          })

        // Load insurances
        ObjectApi.getInsurancesByReferenceId(buildingResponse.building.id)
          .then(response => {
            commit('setInsurances', response.insurances)

            // Load documents of insurances
            let documentIds = []
            for (const insurance of response.insurances) {
              if (insurance.documentIds.length > 0) {
                documentIds = documentIds.concat(insurance.documentIds)
              }
            }

            if (documentIds.length > 0) {
              MediaApi.listDocuments(documentIds)
                .then(documents => {
                  commit('setInsuranceDocuments', documents)
                })
            }
          })

        // Load plots
        const plotsResponse = await ObjectApi.listByPropertyId(propertyResponse.properties[0].id)
        commit('setPlots', plotsResponse.plots)

        // Load building energySource
        const buildingEnergySourceResponse = await ObjectApi.getEnergySourcesByReferenceIds([buildingResponse.building.id])
        commit('setBuildingEnergySources', buildingEnergySourceResponse.energySources)

        // Load building Mortgages
        const buildingMortgagesResponse = await ObjectApi.getMortgagesByReferenceIds([buildingResponse.building.id])
        commit('setBuildingMortgages', buildingMortgagesResponse.mortgages)

        // Load documents of mortgages
        let documentIds = []
        for (const mortgage of buildingMortgagesResponse.mortgages) {
          if (mortgage.documentIds.length > 0) {
            documentIds = documentIds.concat(mortgage.documentIds)
          }
        }

        if (documentIds.length > 0) {
          MediaApi.listDocuments(documentIds)
            .then(documents => {
              commit('setBuildingMortgageDocuments', documents)
            })
        }
      }

      // Load objects, condominiums, tenancies and contacts in parallel
      const promises = []
      promises.push(dispatch('loadObjects'))
      promises.push(ObjectApi.getCondominiumOwnership(buildingResponse.building.objectIds))
      promises.push(ObjectApi.getTenancies(buildingResponse.building.objectIds))
      promises.push(ContactApi.listEmployees(store.getters['user/getAccountId']))

      const responses = await Promise.all(promises)

      const objects = state.objects
      let objectDocumentIds = []
      let contactIdsAssignee = []
      for (const object of objects) {
        const filteredDocuments = object.documents.filter(d => d.documentId).sort((a, b) => a.name - b.name)
        if (filteredDocuments.length > 0) {
          objectDocumentIds = objectDocumentIds.concat(filteredDocuments.map(d => d.documentId))
        }
        // load all assignee
        if (object.contacts) {
          contactIdsAssignee = contactIdsAssignee.concat([object.contacts.contactIdAssigneeOwner, object.contacts.contactIdAssigneeAsset, object.contacts.contactIdAssigneeAdministration, object.contacts.contactIdAssigneeMarketing, object.contacts.contactIdAssigneeAccounting])
        }
      }
      contactIdsAssignee = [...new Set(contactIdsAssignee.filter(x => x !== null && x !== ''))]
      if (contactIdsAssignee.length > 0) {
        ContactApi.contactResolve(contactIdsAssignee)
          .then(async res => {
            const contacts = res.persons.concat(res.companies)
            const promises = contacts.map(async item => {
              try {
                const response = await UserApi.getUserByMail(item.email)
                item.blocked = response.blocked
              } catch (err) {
                console.log(err)
              }
            })
            await Promise.all(promises)
            commit('setAssigneeContacts', contacts)
          })
      }

      // Process condominiumOwnership
      const condominiumOwnershipResponse = responses[1]
      commit('setCondominiumOwnership', condominiumOwnershipResponse.condominiums.sort(function (a, b) {
        return b.startingAt - a.startingAt
      }))
      let contactIds = []
      condominiumOwnershipResponse.condominiums.forEach(element => {
        if (element.owner && element.owner.contactIds.length > 0) {
          contactIds = contactIds.concat(element.owner.contactIds)
        }
        if (element.tenants?.length > 0) {
          element.tenants.forEach((tenant) => {
            contactIds = contactIds.concat(tenant.contactIds)
          })
        }
      })
      ContactApi.contactResolve([...new Set(contactIds.filter(x => x !== null && x !== ''))])
        .then(response => {
          const contacts = response.persons.concat(response.companies)
          const usedContacts = state.objectOwnerContacts.concat(contacts)
          commit('setObjectOwnerContacts', usedContacts)
        })
        .catch(e => {
          console.log(e)
        })
      let condominiumDocumentIds = []
      for (const condominium of condominiumOwnershipResponse.condominiums) {
        if (condominium.documentIds.length > 0) {
          condominiumDocumentIds = condominiumDocumentIds.concat(condominium.documentIds)
        }
      }
      if (condominiumDocumentIds.length > 0) {
        MediaApi.listDocuments(condominiumDocumentIds)
          .then(documents => {
            commit('setCondominiumDocuments', documents)
          })
      }
      let condominiumAdjustmentDocumentIds = []
      for (const condominium of condominiumOwnershipResponse.condominiums) {
        condominiumAdjustmentDocumentIds = condominiumAdjustmentDocumentIds.concat(condominium.rent.adjustments.flatMap(x => x.documentIds))
      }
      if (condominiumAdjustmentDocumentIds.length > 0) {
        MediaApi.listDocuments(condominiumAdjustmentDocumentIds)
          .then(documents => {
            commit('setCondominiumAdjustmentDocuments', documents)
          })
      }

      // Process tenancies
      const tenancyResponse = responses[2]
      commit('setTenancies', tenancyResponse.tenancies.sort(function (a, b) {
        const aStartDate = a.startingAt && a.startingAt.year !== -1 && a.startingAt.year > 0 ? a.startingAt.year + '-' + ('0' + a.startingAt.month).slice(-2) + '-' + ('0' + a.startingAt.day).slice(-2) : ''
        const bStartDate = b.startingAt && b.startingAt.year !== -1 && b.startingAt.year > 0 ? b.startingAt.year + '-' + ('0' + b.startingAt.month).slice(-2) + '-' + ('0' + b.startingAt.day).slice(-2) : ''
        return new Date(bStartDate).getTime() - new Date(aStartDate).getTime()
      }))
      let tenancyDocumentIds = []
      let tenancyOwnerIds = []
      for (const tenancy of tenancyResponse.tenancies) {
        if (tenancy.documentIds.length > 0) {
          tenancyDocumentIds = tenancyDocumentIds.concat(tenancy.documentIds)
        }
        if (tenancy.rent && tenancy.rent.adjustments) {
          for (const adjustment of tenancy.rent.adjustments) {
            tenancyDocumentIds = tenancyDocumentIds.concat(adjustment.documentIds)
          }
        }
        tenancyOwnerIds = tenancyOwnerIds.concat(tenancy.ownerId)
      }
      if (tenancyDocumentIds.length > 0) {
        MediaApi.listDocuments(tenancyDocumentIds)
          .then(documents => {
            commit('setTenancyDocuments', documents)
          })
      }
      if (tenancyOwnerIds.length > 0) {
        ObjectApi.getTemplateContract([...new Set(tenancyOwnerIds)])
          .then(templates => {
            commit('setTenancyContracts', templates)
          })
      }

      // Process employee contacts for current account
      // and load building contacts if not owner
      commit('setEmployeeContacts', responses[3].persons)
      if (store.getters['user/getAccountId'].toString() !== state.building.ownerId.toString()) {
        // Resolve building and device contacts
        const contactIds = [
          ...getters.getCraftsmanContactIds,
          ...getters.getJanitorContactIds,
          ...getters.getServiceProviderContactIds,
          ...getters.getDelegateContactIds
        ]
        ContactApi.contactResolve(contactIds)
          .then(response => {
            commit('addContacts', response.persons.concat(response.companies))
          })
      }

      // Load building devices
      const deviceReferenceIds = [buildingId].concat(objects.map(obj => obj.id))
      ObjectApi.getDevicesByReferenceIds(deviceReferenceIds)
        .then(response => {
          const allDevices = response.devices.sort((a) => (a.uninstallation === null ? -1 : 1))
          const buildingDevices = allDevices.filter(d => d.referenceId === buildingId)
          const objectDevices = allDevices.filter(d => d.referenceId !== buildingId)
          commit('setBuildingDevices', buildingDevices)
          commit('setObjectDevices', objectDevices)

          // Load contacts of all devices
          const deviceContactIds = []
          for (const device of allDevices) {
            if (device.inspection.contact.userReference) {
              deviceContactIds.push(device.inspection.contact.userReference)
            }
            if (device.maintenance.contact.userReference) {
              deviceContactIds.push(device.maintenance.contact.userReference)
            }
            if (device.revision.contact.userReference) {
              deviceContactIds.push(device.revision.contact.userReference)
            }
          }
          if (deviceContactIds.length > 0) {
            ContactApi.contactResolve(deviceContactIds)
              .then(contactResponse => {
                commit('addContacts', contactResponse.persons.concat(contactResponse.companies))
              })
          }

          // Load documents of building devices
          let documentIds = []
          for (const devices of buildingDevices) {
            if (devices.documentIds.length > 0) {
              documentIds = documentIds.concat(devices.documentIds)
            }
          }
          if (documentIds.length > 0) {
            MediaApi.listDocuments(documentIds)
              .then(documents => {
                commit('setBuildingDeviceDocuments', documents)
              })
          }

          // Load documents of object devices
          documentIds = []
          for (const devices of objectDevices) {
            if (devices.documentIds.length > 0) {
              documentIds = documentIds.concat(devices.documentIds)
            }
          }

          if (documentIds.length > 0) {
            MediaApi.listDocuments(documentIds)
              .then(documents => {
                commit('setObjectDeviceDocuments', documents)
              })
          }
        })

      const allRefIds = [buildingId].concat(buildingResponse.building.objectIds)
      InvestPlanApi.listComponentsByReferenceIds(allRefIds)
        .then(response => {
          if (response.components.length > 0) {
            commit('setComponents', response.components)
          }
        })
      PortalApi.listLinksByReferenceIds([buildingId])
        .then((response) => {
          commit('setPortalLinks', response.links)
        })
      if (objects.length > 0) {
        // load handover protocole for object
        HandoverProtocolApi.handoverProtocolServiceListByObjectIds(objects.map(obj => obj.id))
          .then(response => {
            commit('setHandoverProtocols', response.protocols)
          })
        // Load object Rooms
        RoomApi.getRoomsByReferenceIds(objects.map(obj => obj.id))
          .then(response => {
            commit('setObjectRooms', response.rooms)

            // Load documents of object devices
            let documentIds = []
            for (const rooms of response.rooms) {
              if (rooms.documentIds.length > 0) {
                documentIds = documentIds.concat(rooms.documentIds)
              }
            }

            if (documentIds.length > 0) {
              MediaApi.listDocuments(documentIds)
                .then(documents => {
                  commit('setObjectRoomDocuments', documents)
                })
            }
          })

        ObjectApi.getMortgagesByReferenceIds(objects.map(obj => obj.id))
          .then(response => {
            commit('setObjectMortgages', response.mortgages)

            // Load documents of mortgages
            let documentIds = []
            for (const mortgage of response.mortgages) {
              if (mortgage.documentIds.length > 0) {
                documentIds = documentIds.concat(mortgage.documentIds)
              }
            }

            if (documentIds.length > 0) {
              MediaApi.listDocuments(documentIds)
                .then(documents => {
                  commit('setObjectMortgageDocuments', documents)
                })
            }
          })

        ObjectApi.getEnergySourcesByReferenceIds(objects.map(obj => obj.id))
          .then(response => {
            commit('setObjectEnergySources', response.energySources)

            // Load documents of energySources
            let documentIds = []
            for (const energySource of response.energySources) {
              if (energySource.documentIds.length > 0) {
                documentIds = documentIds.concat(energySource.documentIds)
              }
            }

            if (documentIds.length > 0) {
              MediaApi.listDocuments(documentIds)
                .then(documents => {
                  commit('setObjectEnergySourcesDocuments', documents)
                })
            }
          })
      }

      // load list property buildings + value quota
      if (propertyResponse.properties[0]?.type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP') {
        ObjectApi.getBuildingsListByIds(propertyResponse.properties[0].buildingIds)
          .then(async response => {
            const buildings = response.buildings
            const promiseQuota = []
            for (let i = 0; i < buildings.length; i++) {
              const buildingId = buildings[i].id
              promiseQuota.push(ObjectApi.getTotalValueQuotaForBuilding(buildingId))
            }

            Promise.all(promiseQuota).then(response => {
              response.forEach((elem, index) => {
                buildings[index].valueQuota = elem.totalValueQuota
              })
              commit('setPropertyBuildings', buildings)
            })
          })
      }

      const owner = await UserApi.getAccount(buildingResponse.building.ownerId)
      commit('setOwnerAccount', owner.account)

      NetworkPartnerAPI.listNetworkPartner(
        0,
        0,
        [],
        ['LANDLORD', 'MARKETING', 'LANDLORD_MARKETING', 'ASSET_MANAGEMENT', 'ASSET_LANDLORD', 'ASSET_ACCOUNTING', 'ACCOUNTING', 'LANDLORD_ACCOUNTING', 'MARKETING_ACCOUNTING', 'LANDLORD_MARKETING_ACCOUNTING', 'ASSET_LANDLORD_MARKETING_ACCOUNTING', 'ASSET_MARKETING', 'ASSET_LANDLORD_MARKETING', 'ASSET_LANDLORD_ACCOUNTING', 'ASSET_MARKETING_ACCOUNTING'],
        '',
        '',
        ''
      ).then(response => {
        commit('setNetworkPartners', response.accounts)
      })

      if (objectDocumentIds.length > 0) {
        MediaApi.listDocuments(objectDocumentIds)
          .then(documents => {
            commit('setObjectDocuments', documents)
          })
      }
      commit('resetChanged')
      commit('setLoading', false)
    },
    loadBuildingForRequest: async ({ commit, state, dispatch }, buildingId) => {
      // Hacky fix because webpack tries to load the async components and calls this method with the route name
      if (!buildingId.startsWith('building')) {
        return
      }

      commit('setLoading', true)
      state.hasChanged = false
      state.building = null
      state.buildingImageMedia = null
      state.buildingDocuments = []
      state.energySourceDocuments = []
      state.buildingDevices = []
      state.buildingRooms = []
      state.removedBuildingDevices = []
      state.removedBuildingRooms = []
      state.buildingDeviceDocuments = []
      state.buildingRoomDocuments = []
      state.insurances = []
      state.mortgages = []
      state.energySources = []
      state.removedEnergySources = []
      state.removedInsurances = []
      state.removedMortgages = []
      state.insuranceDocuments = []
      state.buildingMortgageDocuments = []
      state.objectMortgageDocuments = []
      state.objects = []
      state.removedObjects = []
      state.objectDocuments = []
      state.tenancies = []
      state.condominiumOwnership = []
      state.contacts = []
      state.employeesContacts = []
      state.removedTenancies = []
      state.tenancyDocuments = []
      state.condominiumDocuments = []
      state.objectDevices = []
      state.components = []
      state.portalLinks = []
      state.handoverProtocols = []
      state.objectRooms = []
      state.properties = []
      state.plots = []
      state.removedObjectDevices = []
      state.removedObjectRooms = []
      state.removedComponents = []
      state.removedPortalLinks = []
      state.objectDeviceDocuments = []
      state.objectRoomDocuments = []

      const buildingResponse = await ObjectApi.getBuilding(buildingId)
      if (buildingResponse.building.characteristics === null) {
        buildingResponse.building.characteristics = {
          broadbandInternet: null,
          cableTv: null,
          chargingStation: null,
          childFriendly: null,
          communityGarden: null,
          conciergeService: null,
          elevator: null,
          fiberGlass: null,
          intercom: null,
          isdn: null,
          park: null,
          playground: null,
          satellite: null
        }
      }
      if (buildingResponse.building.rating === null) {
        buildingResponse.building.rating = {
          microLocationRating: null
        }
      }
      commit('setBuilding', buildingResponse.building)
      await dispatch('loadObjects')
      // Load building image
      if (buildingResponse.building.imageMediaId) {
        MediaApi.getMediaByID(buildingResponse.building.imageMediaId)
          .then(media => {
            commit('setBuildingImageMedia', media)
          })
      }
      // Load documents of building
      if (buildingResponse.building.documentIds.length > 0) {
        MediaApi.listDocuments(buildingResponse.building.documentIds)
          .then(documents => {
            commit('setBuildingDocuments', documents)
          })
      }
      commit('resetChanged')
      commit('setLoading', false)
    },
    loadObjects: async ({ commit, state }, fromBulkCreation = false) => {
      // Load objects
      let objectResponse = { objects: [] }
      if ((state.building && state.building.objectIds && state.building.objectIds.length > 0) || fromBulkCreation) {
        objectResponse = await ObjectApi.listObjectsByBuildingId(state.building.id)
      }
      if (objectResponse.objects.length > 0) {
        const shareResponse = await ShareApi.listByResource(objectResponse.objects.map(obj => obj.id))

        let filteredObjects = objectResponse.objects.filter(obj => {
          const accountId = parseInt(store.getters['user/getAccountId'])
          return parseInt(obj.ownerId) === accountId ||
            shareResponse.shares
              .filter(share => {
                return share.scope !== 'MARKETING_SCOPE' &&
                  parseInt(share.targetAccountId) === parseInt(accountId) &&
                  share.resourceIds.includes(obj.id)
              })
              .map(share => parseInt(share.targetAccountId))
              .includes(accountId)
        }).sort(function (a, b) {
          if (a.name === '') {
            return 1
          }
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          if (a.name === b.name) {
            if (a.category === '') {
              return 1
            }
            if (a.category < b.category) {
              return -1
            }
            if (a.category > b.category) {
              return 1
            }
          }
          return 0
        })

        let valueQuotaWithTotalString = -1
        if (state.building?.id && filteredObjects.some(x => x.valueQuota > -1)) {
          const valueQuotaWithTotal = await ObjectApi.getTotalValueQuotaForBuilding(state.building.id)
          valueQuotaWithTotalString = valueQuotaWithTotal?.totalValueQuota ? ' / ' + valueQuotaWithTotal.totalValueQuota : ''
        }
        filteredObjects = filteredObjects.map(obj => ({
          ...obj,
          valueQuotaWithTotal: (obj.valueQuota !== -1 ? obj.valueQuota : 0) + valueQuotaWithTotalString
        }))

        commit('setObjects', { objects: filteredObjects })
        commit('setAllObjects', objectResponse.objects)
        commit('setShares', shareResponse.shares)
      } else {
        commit('setObjects', { objects: [] })
        commit('setAllObjects', { objects: [] })
      }
    },
    loadAdditionalBuildingContacts: async ({ commit }, contactIds) => {
      if (contactIds && contactIds.length > 0) {
        ContactApi.contactResolve(contactIds)
          .then(response => {
            commit('addContacts', response.persons.concat(response.companies))
          })
      }
    },
    saveOnboarding: async ({ commit, state, dispatch }, externalSource = false) => {
      commit('setLoading', true)
      commit('resetErrors')

      // Validate tenancies
      for (const tenancy of state.tenancies) {
        if (parseInt(tenancy.endingAt) >= 0 && parseInt(tenancy.startingAt) >= parseInt(tenancy.endingAt)) {
          commit('addError', {
            entityType: 'tenancy',
            id: tenancy.id || tenancy.internalId,
            objectId: tenancy.objectId,
            entity: tenancy,
            field: 'endingAt'
          })
        }
        if (!tenancy.startingAt || tenancy.startingAt < 0) {
          commit('addError', {
            entityType: 'tenancy',
            id: tenancy.id || tenancy.internalId,
            objectId: tenancy.objectId,
            entity: tenancy,
            field: 'startingAt'
          })
        }
        if (['RENT_TYPE_UNDEFINED', '', undefined, null].includes(tenancy.contract.rentType)) {
          commit('addError', {
            entityType: 'tenancy',
            id: tenancy.id || tenancy.internalId,
            objectId: tenancy.objectId,
            entity: tenancy,
            field: 'rentType'
          })
        }
        if (['TYPE_OF_USE_UNDEFINED', '', undefined, null].includes(tenancy.contract.typeOfUse)) {
          commit('addError', {
            entityType: 'tenancy',
            id: tenancy.id || tenancy.internalId,
            objectId: tenancy.objectId,
            entity: tenancy,
            field: 'typeOfUse'
          })
        }
        for (const adjustment of tenancy.rent.adjustments) {
          if ((!adjustment.byDate || adjustment.byDate < 0 || parseInt(adjustment.byDate) < parseInt(tenancy.startingAt)) && !tenancy.vacancy) {
            commit('addError', {
              entityType: 'tenancy',
              id: tenancy.id || tenancy.internalId,
              objectId: tenancy.objectId,
              entity: tenancy,
              field: 'adjustment.byDate'
            })
          }
          if (adjustment.coldRent < 0) {
            commit('addError', {
              entityType: 'tenancy',
              id: tenancy.id || tenancy.internalId,
              objectId: tenancy.objectId,
              entity: tenancy,
              field: 'adjustment.coldRent'
            })
          }
          if (tenancy.tenant.contactIds.length > 0) {
            if (state.objectOwnerContacts.length !== 0) {
              const selectedTenant = state.objectOwnerContacts.filter(x => tenancy.tenant.contactIds.includes(x.id))
              selectedTenant.forEach((tenant) => {
                if (typeof (tenant) !== 'undefined') {
                  const isAddressComplete = !!((tenant.address.zip && tenant.address.city && tenant.address.country))
                  if (tenant.types.includes('EMPLOYEE') && isAddressComplete) {
                    commit('addError', {
                      entityType: 'tenancy',
                      id: tenancy.id || tenancy.internalId,
                      objectId: tenancy.objectId,
                      entity: tenancy,
                      field: 'tenants'
                    })
                  }
                }
              })
            }
          }
          if (adjustment.coldRent > 0) {
            adjustment.coldRent = parseFloat(adjustment.coldRent)
          }
        }
      }

      // Validate condominiumOwnership
      for (const tenancy of state.condominiumOwnership) {
        if (parseInt(tenancy.endingAt) >= 0 && parseInt(tenancy.startingAt) >= parseInt(tenancy.endingAt)) {
          commit('addError', {
            entityType: 'condominiumOwnership',
            id: tenancy.id || tenancy.internalId,
            objectId: tenancy.objectId,
            entity: tenancy,
            field: 'endingAt'
          })
        }
        if (!tenancy.startingAt || tenancy.startingAt < 0) {
          commit('addError', {
            entityType: 'condominiumOwnership',
            id: tenancy.id || tenancy.internalId,
            objectId: tenancy.objectId,
            entity: tenancy,
            field: 'startingAt'
          })
        }
      }

      if (state.errors.length > 0 && !externalSource) {
        commit('setLoading', false)
        return false
      }

      let promises = []
      const b = state.building

      // Update building
      if (b.modified) {
        promises.push(ObjectApi.updateBuilding(
          b.id,
          b.name,
          b.objectIds,
          b.egid,
          b.number,
          b.imageMediaId,
          b.realEstateNumber,
          b.characteristics,
          b.constructionYear,
          b.renovationYear,
          b.buildingZone,
          b.plotIds,
          b.plotArea,
          b.buildingArea,
          b.volume,
          b.floors,
          b.locationQuality,
          b.constructionTypes,
          b.sia,
          b.distances,
          b.energySources,
          b.documentIds,
          b.acquisitionDate,
          b.address,
          b.note,
          b.contractAmendments,
          b.rating,
          b.buildingStrategy,
          b.buildingValue
        ))
      }

      // Create or update building devices
      for (const device of state.buildingDevices) {
        if (device.internalId) {
          promises.push(ObjectApi.createDevice(device))
        } else if (device.modified) {
          promises.push(ObjectApi.updateDevice(device))
        }
      }

      // Create or update building rooms
      const buildingRoomToCreate = []
      const buildingRoomToUpdate = []
      for (const room of state.buildingRooms) {
        if (room.internalId) {
          buildingRoomToCreate.push(room)
        } else if (room.modified) {
          buildingRoomToUpdate.push(room)
        }
      }
      if (buildingRoomToCreate.length > 0) {
        promises.push(RoomApi.createRooms(buildingRoomToCreate))
      } else if (buildingRoomToUpdate.length > 0) {
        promises.push(RoomApi.updateRooms(buildingRoomToUpdate))
      }

      // Delete devices that were removed and already created
      for (const removedDevice of state.removedBuildingDevices) {
        if (!removedDevice.internalId && removedDevice.id) {
          promises.push(ObjectApi.deleteDevice(removedDevice.id))
        }
      }
      // Delete building room that were removed and already created
      const roomsIdsToRemove = []
      for (const removedRoom of state.removedBuildingRooms) {
        if (removedRoom.id) {
          roomsIdsToRemove.push(removedRoom.id)
        }
      }
      if (roomsIdsToRemove.length > 0) {
        promises.push(RoomApi.deleteRooms(roomsIdsToRemove))
      }

      // Create or update insurances
      for (const insurance of state.insurances) {
        if (insurance.internalId) {
          promises.push(ObjectApi.createInsurance(insurance))
        } else if (insurance.modified) {
          promises.push(ObjectApi.updateInsurance(insurance))
        }
      }

      // Delete insurances that were removed and already created
      for (const removedInsurance of state.removedInsurances) {
        if (removedInsurance.id) {
          promises.push(ObjectApi.deleteInsurance(removedInsurance.id))
        }
      }

      // Delete Condominium that were removed and already created
      for (const removedCond of state.removedCondominium) {
        if (removedCond.id) {
          promises.push(ObjectApi.deleteCondominium(removedCond.id))
        }
      }

      // Create or update building energySource
      for (const energyS of state.buildingEnergySources) {
        if (energyS.internalId) {
          promises.push(ObjectApi.createEnergySource(energyS))
        } else if (energyS.modified) {
          promises.push(ObjectApi.updateEnergySource(energyS))
        }
      }

      // Create or update building mortgage
      for (const mortgage of state.buildingMortgages) {
        if (mortgage.internalId) {
          promises.push(ObjectApi.createMortgage(mortgage))
        } else if (mortgage.modified) {
          promises.push(ObjectApi.updateMortgage(mortgage))
        }
      }

      // Delete energysources that were removed and already created
      const idsEnergySources = []
      for (const removedEnergySource of state.removedBuildingEnergySources) {
        if (removedEnergySource.id) {
          idsEnergySources.push(removedEnergySource.id)
        }
      }
      for (const removedEnergySource of state.removedObjectEnergySources) {
        if (removedEnergySource.id) {
          idsEnergySources.push(removedEnergySource.id)
        }
      }
      if (idsEnergySources.length > 0) {
        promises.push(ObjectApi.deleteEnergySources(idsEnergySources))
      }

      // Delete mortgages that were removed and already created
      const idsMortgage = []
      for (const removedMortgage of state.removedBuildingMortgages) {
        if (removedMortgage.id) {
          idsMortgage.push(removedMortgage.id)
        }
      }
      for (const removedMortgage of state.removedObjectMortgages) {
        if (removedMortgage.id) {
          idsMortgage.push(removedMortgage.id)
        }
      }
      if (idsMortgage.length > 0) {
        promises.push(ObjectApi.deleteMortgage(idsMortgage))
      }

      // Create or update object mortgage
      for (const mortgage of state.objectMortgages) {
        if (mortgage.internalId) {
          promises.push(ObjectApi.createMortgage(mortgage))
        } else if (mortgage.modified) {
          promises.push(ObjectApi.updateMortgage(mortgage))
        }
      }

      // Create or update object energySource
      for (const energySrc of state.objectEnergySources) {
        if (energySrc.internalId) {
          promises.push(ObjectApi.createEnergySource(energySrc))
        } else if (energySrc.modified) {
          promises.push(ObjectApi.updateEnergySource(energySrc))
        }
      }

      // Create or update object rooms
      const roomToCreate = []
      const roomToUpdate = []
      for (const room of state.objectRooms) {
        if (room.internalId) {
          roomToCreate.push(room)
        } else if (room.modified) {
          roomToUpdate.push(room)
        }
      }
      if (roomToCreate.length > 0) {
        promises.push(RoomApi.createRooms(roomToCreate))
      } else if (roomToUpdate.length > 0) {
        promises.push(RoomApi.updateRooms(roomToUpdate))
      }

      // Update objects
      for (const object of state.objects) {
        if (object.modified) {
          if (object.category === 'APARTMENT' && object.characteristics.rooms !== -1) {
            if (state.objectRooms.filter(rm => rm.referenceId === object.id).length === 0) {
              if (object.characteristics.rooms >= 2) {
                await RoomApi.getRoomsByReferenceIds([object.id])
                  .then(response => {
                    if (response.rooms?.length === 0) {
                      const nbrRmTocreate = Math.floor(object.characteristics.rooms)
                      let countToadd = 0
                      for (let index = 0; index < nbrRmTocreate - 1; index += 1) {
                        countToadd += 1
                      }
                      const rooms = addRooms(countToadd, object.id, object.ownerId)
                      promises.push(RoomApi.createRooms(rooms))
                    }
                  })
                  .catch(e => {
                    console.log(e)
                  })
              } else if (object.characteristics.rooms >= 1) {
                await RoomApi.getRoomsByReferenceIds([object.id])
                  .then(response => {
                    if (response.rooms?.length === 0) {
                      const rooms = addRooms(0, object.id, object.ownerId)
                      promises.push(RoomApi.createRooms(rooms))
                    }
                  })
                  .catch(e => {
                    console.log(e)
                  })
              }
            }
          }
          promises.push(ObjectApi.updateObject(object))
        }
      }

      // Delete objects
      for (const removedObjects of state.removedObjects) {
        promises.push(ObjectApi.deleteObject(removedObjects.id))
      }

      // Create or update object devices
      for (const device of state.objectDevices) {
        if (device.internalId) {
          promises.push(ObjectApi.createDevice(device))
        } else if (device.modified) {
          promises.push(ObjectApi.updateDevice(device))
        }
      }

      // Delete devices that were removed and already created
      for (const removedDevice of state.removedObjectDevices) {
        if (!removedDevice.internalId && removedDevice.id) {
          promises.push(ObjectApi.deleteDevice(removedDevice.id))
        }
      }
      // Create or update components
      const listComponentToCreate = []
      const listComponentToEdit = []
      for (const component of state.components) {
        if (component.internalId) {
          listComponentToCreate.push(component)
        } else if (component.modified) {
          listComponentToEdit.push(component)
        }
      }
      if (listComponentToCreate.length > 0) {
        promises.push(InvestPlanApi.createComponentBulk(listComponentToCreate))
      }
      if (listComponentToEdit.length > 0) {
        promises.push(InvestPlanApi.updateComponentBulk(listComponentToEdit))
      }
      // Delete devices that were removed and already created
      for (const removedComponent of state.removedComponents) {
        if (!removedComponent.internalId && removedComponent.id) {
          promises.push(InvestPlanApi.deleteComponent(removedComponent.id))
        }
      }
      // Create or update portal links
      const listPortalLinkToCreate = []
      const listPortalLinkToEdit = []
      const listPortalLinkIdsToRemove = []
      for (const link of state.portalLinks) {
        if (link.internalId) {
          listPortalLinkToCreate.push(link)
        } else if (link.modified) {
          listPortalLinkToEdit.push(link)
        }
      }
      if (listPortalLinkToCreate.length > 0) {
        promises.push(PortalApi.addLinks(listPortalLinkToCreate))
      }
      if (listPortalLinkToEdit.length > 0) {
        promises.push(PortalApi.updateLinks(listPortalLinkToEdit))
      }
      // Delete portal links that were removed and already created
      for (const link of state.removedPortalLinks) {
        if (!link.internalId && link.id) {
          listPortalLinkIdsToRemove.push(link.id)
        }
      }
      if (listPortalLinkIdsToRemove.length > 0) {
        promises.push(PortalApi.removeLinks(listPortalLinkIdsToRemove))
      }
      // Delete Room that were removed and already created
      const idsObjectRoom = []
      for (const removedObjectRoom of state.removedObjectRooms) {
        if (removedObjectRoom.id) {
          idsObjectRoom.push(removedObjectRoom.id)
        }
      }
      if (idsObjectRoom.length > 0) {
        promises.push(RoomApi.deleteRooms(idsObjectRoom.join()))
      }

      // For tenancies we MUST ensure the order of execution, otherwise some requests could fail, because a former
      // tenancy was not updated/deleted yet and with the new one there would be time overlaps or gaps
      let tenancyPromises = []
      // Delete tenancies that were removed and already created
      for (const removedTenancy of state.removedTenancies) {
        if (removedTenancy.id) {
          tenancyPromises.push(ObjectApi.deleteTenancy(removedTenancy.id))
        }
      }

      await Promise.all(tenancyPromises)
      tenancyPromises = []

      // Update tenancies
      if (state.tenancies.filter(t => !t.internalId && t.modified).length > 0) {
        tenancyPromises.push(ObjectApi.updateBulkTenancies(state.tenancies.filter(t => !t.internalId && t.modified)))
      }

      // Update condominiumOwnership
      // for (const condominiumOwnership of state.condominiumOwnership) {
      //   if (!condominiumOwnership.internalId && condominiumOwnership.modified) {
      //     promises.push(ObjectApi.updateCondominiumOwnership(condominiumOwnership))
      //   }
      if (state.condominiumOwnership.filter(t => !t.internalId && t.modified).length > 0) {
        promises.push(ObjectApi.updateBulkCondominiumOwnership(state.condominiumOwnership.filter(t => !t.internalId && t.modified)))
      }

      await Promise.all(tenancyPromises)
      await Promise.all(promises)
      tenancyPromises = []
      promises = []

      // Create tenancies
      for (const tenancy of state.tenancies) {
        if (tenancy.internalId) {
          tenancyPromises.push(ObjectApi.createTenancy(tenancy))
        }
      }
      // Create condominium
      for (const condominium of state.condominiumOwnership) {
        if (condominium.internalId) {
          promises.push(ObjectApi.createCondominiumOwnership(condominium))
        }
      }

      await Promise.all(tenancyPromises)
      await Promise.all(promises)

      // Get the attached entities from the backend, so we have the correct IDs
      promises = []
      promises.push(ObjectApi.getDevicesByReferenceIds([b.id]))
      promises.push(ObjectApi.getInsurancesByReferenceId(b.id))
      promises.push(ObjectApi.getTenancies(state.objects.map(obj => obj.id)))
      promises.push(ObjectApi.getMortgagesByReferenceIds([b.id]))
      promises.push(ObjectApi.getCondominiumOwnership(state.objects.map(obj => obj.id)))
      promises.push(ObjectApi.getEnergySourcesByReferenceIds([b.id]))
      if (state.objects.length > 0) {
        promises.push(ObjectApi.getDevicesByReferenceIds(state.objects.map(obj => obj.id)))
        promises.push(ObjectApi.getMortgagesByReferenceIds(state.objects.map(obj => obj.id)))
        promises.push(ObjectApi.getEnergySourcesByReferenceIds(state.objects.map(obj => obj.id)))
        promises.push(RoomApi.getRoomsByReferenceIds(state.objects.map(obj => obj.id)))
      }
      promises.push(InvestPlanApi.listComponentsByReferenceIds([b.id].concat(b.objectIds)))
      promises.push(PortalApi.listLinksByReferenceIds([b.id]))
      const responses = await Promise.all(promises)
      commit('setBuildingDevices', responses[0].devices.sort((a) => (a.uninstallation === null ? -1 : 1)))
      commit('setInsurances', responses[1].insurances)
      commit('setTenancies', responses[2].tenancies.sort(function (a, b) {
        const aStartDate = a.startingAt && a.startingAt.year !== -1 && a.startingAt.year > 0 ? a.startingAt.year + '-' + ('0' + a.startingAt.month).slice(-2) + '-' + ('0' + a.startingAt.day).slice(-2) : ''
        const bStartDate = b.startingAt && b.startingAt.year !== -1 && b.startingAt.year > 0 ? b.startingAt.year + '-' + ('0' + b.startingAt.month).slice(-2) + '-' + ('0' + b.startingAt.day).slice(-2) : ''
        return new Date(bStartDate).getTime() - new Date(aStartDate).getTime()
      }))
      commit('setBuildingMortgages', responses[3].mortgages)
      commit('setCondominiumOwnership', responses[4].condominiums)
      commit('setBuildingEnergySources', responses[5].energySources)
      if (state.objects.length > 0) {
        commit('setObjectDevices', responses[6].devices.sort((a) => (a.uninstallation === null ? -1 : 1)))
        commit('setObjectMortgages', responses[7].mortgages)
        commit('setObjectEnergySources', responses[8].energySources)
        commit('setObjectRooms', responses[9].rooms)
      }
      if (responses[10] && responses[10].components) {
        commit('setComponents', responses[10].components)
      }
      if (responses[11] && responses[11].links) {
        commit('setPortalLinks', responses[11].links)
      }
      await dispatch('loadObjects')

      // get value total quota
      let valueQuotaWithTotal = {
        totalValueQuota: -1
      }
      if (state.properties.length > 0 && state.properties[0].type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP' && state.building) {
        valueQuotaWithTotal = await ObjectApi.getTotalValueQuotaForBuilding(state.building.id)
      }
      const buildingData = {
        ...state.building,
        valueQuotaWithTotal: valueQuotaWithTotal?.totalValueQuota !== -1 ? valueQuotaWithTotal.totalValueQuota : null
      }

      commit('setBuilding', buildingData)

      state.removedBuildingDevices = []
      state.removedInsurances = []
      state.removedCondominium = []
      state.removedObjects = []
      state.removedTenancies = []
      state.removedObjectDevices = []
      state.removedObjectRooms = []
      state.removedBuildingRooms = []
      state.removedComponents = []
      state.removedPortalLinks = []
      commit('resetChanged')
      Vue.set(state, 'hasChangedObject', false)
      Vue.set(state, 'hasChangedTenant', false)
      commit('setLoading', false)
      if (!externalSource) {
        Vue.toasted.show(i18n.t('message.successMessages.onboarding'), { type: 'success' })
      }
      commit('setIsBuildingObjectsFiltered', [])
      return true
    },
    setBuildingImageMedia: ({ commit }, data) => {
      commit('setBuildingImageMedia', data)
    },
    updateBuildingField: ({ commit }, data) => {
      if (data.dataType === 'number') {
        data.value = parseFloat(data.value)
        if (isNaN(data.value)) {
          data.value = -1
        }
      }

      commit('updateBuildingField', data)
    },
    updateBuildingDistances: ({ commit }, data) => {
      data.value = parseFloat(data.value)
      if (isNaN(data.value)) {
        data.value = -1
      }

      commit('updateBuildingDistances', data)
    },
    updateBuildingRating: ({ commit }, data) => {
      data.value = parseFloat(data.value)
      if (isNaN(data.value)) {
        data.value = -1
      }

      commit('updateBuildingRating', data)
    },
    updateBuildingStrategy: ({ commit }, data) => {
      commit('updateBuildingStrategy', data)
    },
    updateSia: ({ commit }, data) => {
      data.value = parseFloat(data.value)
      if (isNaN(data.value)) {
        data.value = -1
      }

      commit('updateSia', data)
    },
    updateCharacteristics: ({ commit }, characteristics) => {
      commit('updateCharacteristics', characteristics)
    },
    updateEnergySource: ({ commit }, energySourceIndex) => {
      commit('updateEnergySource', energySourceIndex)
    },
    addNewBuildingDevice: ({ commit }) => {
      commit('addNewBuildingDevice')
    },
    addNewBuildingRoom: ({ commit }) => {
      commit('addNewBuildingRoom')
    },
    duplicateBuildingDevice: ({ commit }, device) => {
      commit('duplicateBuildingDevice', device)
    },
    updateBuildingDevice: ({ commit }, device) => {
      commit('updateBuildingDevice', device)
    },
    removeBuildingDevice: ({ commit }, device) => {
      commit('removeBuildingDevice', device)
    },
    updateBuildingRoom: ({ commit }, room) => {
      commit('updateBuildingRoom', room)
    },
    removeBuildingRoom: ({ commit }, room) => {
      commit('removeBuildingRoom', room)
    },
    addNewInsurance: ({ commit }) => {
      commit('addNewInsurance')
    },
    addNewMortgage: ({ commit }, referenceId) => {
      commit('addNewMortgage', referenceId)
    },
    addNewEnergySource: ({ commit }, referenceId) => {
      commit('addNewEnergySource', referenceId)
    },
    updateInsurance: ({ commit }, insurance) => {
      commit('updateInsurance', insurance)
    },
    removeInsurance: ({ commit }, insurance) => {
      commit('removeInsurance', insurance)
    },
    updateBuildingMortgage: ({ commit }, mortgage) => {
      commit('updateBuildingMortgage', mortgage)
    },
    removeBuildingMortgage: ({ commit }, mortgage) => {
      commit('removeBuildingMortgage', mortgage)
    },
    updateObjectMortgage: ({ commit }, mortgage) => {
      commit('updateObjectMortgage', mortgage)
    },
    removeObjectMortgage: ({ commit }, mortgage) => {
      commit('removeObjectMortgage', mortgage)
    },
    // removeEnergySource: ({ commit }, energySourceIndex) => {
    //   commit('removeEnergySource', energySourceIndex)
    // },
    updateBuildingEnergySource: ({ commit }, energySource) => {
      commit('updateBuildingEnergySource', energySource)
    },
    removeBuildingEnergySource: ({ commit }, energySource) => {
      commit('removeBuildingEnergySource', energySource)
    },
    updateObjectEnergySource: ({ commit }, energySource) => {
      commit('updateObjectEnergySource', energySource)
    },
    removeObjectEnergySource: ({ commit }, energySource) => {
      commit('removeObjectEnergySource', energySource)
    },
    setDismissCountDown: ({ commit }, dismissCountDown) => {
      commit('setDismissCountDown', dismissCountDown)
    },
    setLoading: ({ commit }, value) => {
      commit('setLoading', value)
    },
    setLoadingFinish: ({ commit }, value) => {
      commit('setLoadingFinish', value)
    },
    setIsBuildingObjectsFiltered: ({ commit }, value) => {
      commit('setIsBuildingObjectsFiltered', value)
    },
    addNewObject: async ({ commit, state }, value) => {
      const b = state.building
      const promises = []
      for (const object of value) {
        promises.push(
          ObjectApi.createBulkPropertyObject({
            amount: object.amount,
            buildingId: b.id,
            address: b.address,
            administrationType: object.administrationType,
            category: object.category,
            ownerId: b.ownerId,
            type: 'RENT'
          })
        )
      }

      await Promise.all(promises)
    },
    updateObject: ({ commit }, object) => {
      commit('updateObject', object)
    },
    removeObjects: async ({ commit, dispatch }, objects) => {
      commit('setLoading', true)
      for (let index = 0; index < objects.length; index++) {
        await ObjectApi.deleteObject(objects[index].id)
        commit('removeObject', objects[index])
      }
      Vue.toasted.show(i18n.t('message.managementOverview.objects') + ' ' + i18n.t('message.successMessages.deletion'), { type: 'success' })
      commit('setLoading', false)
    },
    addNewTenancy: ({ commit }, objectId) => {
      commit('addNewTenancy', objectId)
    },
    addNewCondominiumOwnership: ({ commit }, objectId) => {
      commit('addNewCondominiumOwnership', objectId)
    },
    addNewVacancy: ({ commit }, objectId) => {
      commit('addNewVacancy', objectId)
    },
    updateTenancy: ({ commit }, tenancy) => {
      commit('updateTenancy', { tenancy: tenancy })
    },
    updateSavedTenancy: ({ commit }, tenancy) => {
      commit('updateTenancy', { tenancy: tenancy, alreadySaved: true })
    },
    updateCondominiumOwnership: ({ commit }, condominium) => {
      commit('updateCondominiumOwnership', condominium)
    },
    removeTenancy: ({ commit }, tenancy) => {
      commit('removeTenancy', tenancy)
    },
    removeTenancyInstant: ({ commit }, tenancy) => {
      commit('removeTenancyInstant', tenancy)
    },
    removeCondominiumOwnership: ({ commit }, condominium) => {
      commit('removeCondominiumOwnership', condominium)
    },
    removeCondominiumInstant: ({ commit }, tenancy) => {
      commit('removeCondominiumInstant', tenancy)
    },
    addNewObjectDevice: ({ commit }, objectId) => {
      commit('addNewObjectDevice', objectId)
    },
    addComponent: ({ commit }, data) => {
      commit('addComponent', { refId: data.refId, optionalValue: data.optionalValue })
    },
    updateComponent: ({ commit }, component) => {
      commit('updateComponent', component)
    },
    setComponents: ({ commit }, component) => {
      commit('setComponents', component)
    },
    setPortalLinks: ({ commit }, items) => {
      commit('setPortalLinks', items)
    },
    removeComponent: ({ commit }, component) => {
      commit('removeComponent', component)
    },
    addPortalLink: ({ commit }, refId) => {
      commit('addPortalLink', refId)
    },
    updatePortalLink: ({ commit }, item) => {
      commit('updatePortalLink', item)
    },
    removePortalLink: ({ commit }, item) => {
      commit('removePortalLink', item)
    },
    setHandoverProtocols: ({ commit }, handoverProtocols) => {
      commit('setHandoverProtocols', handoverProtocols)
    },
    addNewObjectRooms: ({ commit }, objectId) => {
      commit('addNewObjectRooms', objectId)
    },
    duplicateObjectDevice: ({ commit }, device) => {
      commit('duplicateObjectDevice', device)
    },
    updateObjectDevice: ({ commit }, device) => {
      commit('updateObjectDevice', device)
    },
    updateObjectRoom: ({ commit }, room) => {
      commit('updateObjectRoom', room)
    },
    removeObjectDevice: ({ commit }, device) => {
      commit('removeObjectDevice', device)
    },
    removeObjectRoom: ({ commit }, room) => {
      commit('removeObjectRoom', room)
    },
    resetChanged: ({ commit }) => {
      commit('resetChanged')
    },
    setChangedTenant: ({ commit }) => {
      commit('setChangedTenant')
    },
    activateChanged: ({ commit }) => {
      commit('activateChanged')
    },
    clear: ({ commit }) => {
      commit('clear')
    },
    setOpenedCollapses: ({ commit }, value) => {
      commit('setOpenedCollapses', value)
    }
  }
}
