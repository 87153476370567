<template>
  <b-modal
    id="new-building"
    ref="new-building"
    :modal-class="'has-confirmation-modal'"
    no-close-on-backdrop
    hide-footer
    :title="$t('message.generic.createNewBuildingActionButton')">
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <coozzy-form-input
          v-model="name"
          class="mb-2"
          :state="$v.name.$dirty && $v.name.$error ? false : null"
          :name="$t('message.onBoarding.buildings.name')+'*'" />
      </div>
      <div class="col-sm-12 col-md-8">
        <coozzy-form-input
          v-model="address.street"
          class="mb-2"
          :state="$v.address.street.$dirty && $v.address.street.$error ? false : null"
          :name="$t('message.generic.street')+'*'" />
      </div>
      <div class="col-sm-12 col-md-4">
        <coozzy-form-input
          v-model="address.streetNumber"
          :name="$t('message.generic.no')" />
      </div>
      <div class="col-sm-12 col-md-6 mb-2">
        <coozzy-form-country-select
          v-model="address.country"
          :disabled="defaultAddress !== null"
          :state="$v.address.country.$dirty && $v.address.country.$error ? false : null"
          :initial-value="address.country" />
      </div>
      <div class="col-sm-12 col-md-6 mb-2">
        <coozzy-form-region-select
          v-model="address.state"
          :disabled="defaultAddress !== null"
          :initial-value="address.state"
          :state="$v.address.state.$dirty && $v.address.state.$error ? false : null"
          :country="address.country" />
      </div>
      <div class="col-sm-12 col-md-4">
        <coozzy-form-input
          v-model="address.zip"
          :disabled="defaultAddress !== null"
          :state="$v.address.zip.$dirty && $v.address.zip.$error ? false : null"
          :name="$t('message.generic.zip')+'*'" />
      </div>
      <div class="col-sm-12 col-md-8">
        <coozzy-form-input
          v-model="address.city"
          :disabled="defaultAddress !== null"
          :state="$v.address.city.$dirty && $v.address.city.$error ? false : null"
          class="mb-2"
          :name="$t('message.generic.location')+'*'" />
      </div>
      <div class="col mt-3">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$bvModal.hide('new-building')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="prop-green"
          :disabled="loading"
          @click="newAddress">
          {{ $t('message.onBoarding.buildings.createBuilding') }}
        </coozzy-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import ObjectApi from '../misc/apis/ObjectApi'
import { required } from 'vuelidate/lib/validators'
import Vue from 'vue'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyFormCountrySelect from '@/framework/components/form/select/CoozzyFormCountrySelect'
import CoozzyFormRegionSelect from '@/framework/components/form/select/CoozzyFormRegionSelect'

export default {
  name: 'CreateBuildingsModal',
  components: { CoozzyFormRegionSelect, CoozzyFormCountrySelect, CoozzyButton, CoozzyFormInput },
  props: {
    defaultAddress: {
      type: Object,
      default: null
    },
    targetBuilding: {
      type: String,
      default: 'Owner'
    }
  },
  data() {
    return {
      name: '',
      address: {
        country: 'CH',
        state: '',
        city: '',
        zip: '',
        street: '',
        streetNumber: '',
        streetAddition: '',
        subUnit: ''
      },
      loading: false
    }
  },
  watch: {
    name: {
      handler: function (val) {
        if (val !== '') {
          this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
        }
      }
    },
    address: {
      deep: true,
      handler: function (val) {
        if (val.country !== 'CH' || val.state !== '' || val.city !== '' || val.zip !== '' || val.street !== '' || val.street !== '' || val.streetNumber !== '' || val.streetAddition !== '' || val.subUnit !== '') {
          this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
        }
      }
    }
  },
  mounted() {
    if (this.defaultAddress) {
      this.address = this.defaultAddress
      this.$nextTick(function () {
        this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
      })
    }

    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'new-building') {
        this.$nextTick(function () {
          this.$v.$reset()
          this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
        })
        this.name = ''
        this.address.street = ''
        this.address.streetNumber = ''
        if (this.defaultAddress === null) {
          this.address.country = 'CH'
          this.address.state = ''
          this.address.city = ''
          this.address.zip = ''
          this.address.streetAddition = ''
          this.address.subUnit = ''
        }
      }
    })
  },
  methods: {
    newAddress() {
      this.$v.address.$touch()
      this.$v.name.$touch()
      if (!this.$v.address.$invalid && !this.$v.name.$invalid) {
        this.loading = true
        ObjectApi.createBuilding(
          this.$store.getters['user/getAccountId'],
          this.address,
          this.name,
          ''
        ).then(response => {
          if (response) {
            this.loading = false
            if (this.defaultAddress === null) {
              if (this.targetBuilding === 'admin') {
                this.$router.push({ name: 'AdminOnboardingView', params: { id: response.building.id } })
              } else {
                this.$router.push({ name: 'OwnerOnboardingView', params: { id: response.building.id } })
              }
            } else {
              this.$emit('building-created', response)
              this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
              this.hide()
            }
            Vue.toasted.show(this.$t('message.successMessages.buildingCreation'), { type: 'success' })
          } else {
            Vue.toasted.show(this.$t('message.savingErrors.building'), { type: 'error' })
          }
        }).catch(e => {
          console.log(e)
          if (e.response.data.error === 'No coordinates found for given address') {
            Vue.toasted.show(this.$t('message.savingErrors.buildingCreation'), { type: 'error' })
          } else {
            Vue.toasted.show(this.$t('message.savingErrors.building'), { type: 'error' })
          }
        })
      }
    },
    show() {
      this.$refs['new-building'].show()
    },
    hide() {
      this.$refs['new-building'].hide()
    }
  },
  validations: {
    name: {
      required
    },
    address: {
      street: {
        required
      },
      city: {
        required
      },
      zip: {
        required
      },
      state: {
        required
      },
      country: {
        required
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  label {
    margin-bottom: 0;
  }
</style>
