// @ts-nocheck
import Vue from 'vue'
import { sort } from '@/mixins/sort'
import { obj } from '@/mixins/object'
import { Contactcontactv2Company, V2Person } from '@/types/api'

const onboarding = {
  mixins: [sort, obj],
  computed: {
    isBuildingObjectsFiltered() {
      return this.$store.getters['onboarding/isBuildingObjectsFiltered']
    },
    onboardingLoading() {
      return this.$store.getters['onboarding/loading']
    },
    loadingFinish() {
      return this.$store.getters['onboarding/loadingFinish']
    },
    dismissCountDown() {
      return this.$store.getters['onboarding/dismissCountDown']
    },
    hasChanged() {
      return this.$store.getters['onboarding/hasChanged']
    },
    hasChangedObject() {
      return this.$store.getters['onboarding/hasChangedObject']
    },
    hasChangedTenant() {
      return this.$store.getters['onboarding/hasChangedTenant']
    },
    building() {
      return this.$store.getters['onboarding/getBuilding']
    },
    buildingImageMedia() {
      return this.$store.getters['onboarding/getBuildingImageMedia']
    },
    buildingDocuments() {
      return this.$store.getters['onboarding/getBuildingDocuments']
    },
    energySourceDocuments() {
      return this.$store.getters['onboarding/getEnergySourceDocuments']
    },
    tankReadingDocuments() {
      return this.$store.getters['onboarding/getTankReadingDocuments']
    },
    buildingDevices() {
      return this.$store.getters['onboarding/getBuildingDevices']
    },
    buildingRooms() {
      return this.$store.getters['onboarding/getBuildingRooms']
    },
    buildingDeviceDocuments() {
      return this.$store.getters['onboarding/getBuildingDeviceDocuments']
    },
    buildingRoomDocuments() {
      return this.$store.getters['onboarding/getBuildingRoomDocuments']
    },
    insurances() {
      return this.$store.getters['onboarding/getInsurances']
    },
    settlementObjects() {
      return this.$store.getters['onboarding/getSettlementObjects']
    },
    properties() {
      return this.$store.getters['onboarding/getProperties']
    },
    isPortalLinkEnabled() {
      return this.$store.getters['user/getIsPortalLinkEnabled']
    },
    plots() {
      return this.$store.getters['onboarding/getPlots']
    },
    buildingMortgages() {
      return this.sortByDateMortgages(this.$store.getters['onboarding/getBuildingMortgages'])
    },
    objectMortgages() {
      return this.$store.getters['onboarding/getObjectMortgages']
    },
    // energySources() {
    //   return this.$store.getters['onboarding/getEnergySources']
    // },
    objectEnergySources() {
      return this.$store.getters['onboarding/getObjectEnergySources']
    },
    buildingEnergySources() {
      return this.$store.getters['onboarding/getBuildingEnergySources']
    },
    insuranceDocuments() {
      return this.$store.getters['onboarding/getInsuranceDocuments']
    },
    buildingMortgageDocuments() {
      return this.$store.getters['onboarding/getBuildingMortgageDocuments']
    },
    objectMortgageDocuments() {
      return this.$store.getters['onboarding/getObjectMortgageDocuments']
    },
    objects() {
      return this.$store.getters['onboarding/getObjects']
    },
    allObjects() {
      return this.$store.getters['onboarding/getAllObjects']
    },
    objectDocuments() {
      return this.$store.getters['onboarding/getObjectDocuments']
    },
    tenancies() {
      return this.$store.getters['onboarding/getTenancies']
    },
    condominiumOwnership() {
      return this.$store.getters['onboarding/getCondominiumOwnership']
    },
    assigneeContacts() {
      return this.$store.getters['onboarding/getAssigneeContacts']
    },
    contacts(): (V2Person | Contactcontactv2Company)[] {
      return this.$store.getters['onboarding/getContacts']
    },
    employeeContacts() {
      return this.$store.getters['onboarding/getEmployeesContacts']
    },
    objectOwnerContacts() {
      return this.$store.getters['onboarding/getObjectOwnerContacts']
    },
    handoverProtocols() {
      return this.$store.getters['onboarding/getHandoverProtocols']
    },
    tenancyDocuments() {
      return this.$store.getters['onboarding/getTenancyDocuments']
    },
    tenancyContracts() {
      return this.$store.getters['onboarding/getTenancyContracts']
    },
    condominiumDocuments() {
      return this.$store.getters['onboarding/getCondominiumDocuments']
    },
    condominiumAdjustmentDocuments() {
      return this.$store.getters['onboarding/getCondominiumAdjustmentDocuments']
    },
    objectDevices() {
      return this.$store.getters['onboarding/getObjectDevices']
    },
    objectRooms() {
      return this.$store.getters['onboarding/getObjectRooms']
    },
    objectDeviceDocuments() {
      return this.$store.getters['onboarding/getObjectDeviceDocuments']
    },
    objectRoomDocuments() {
      return this.$store.getters['onboarding/getObjectRoomDocuments']
    },
    craftsmanContactIds() {
      return this.$store.getters['onboarding/getCraftsmanContactIds']
    },
    janitorContactIds() {
      return this.$store.getters['onboarding/getJanitorContactIds']
    },
    serviceProviderContactIds() {
      return this.$store.getters['onboarding/getServiceProviderContactIds']
    },
    delegateContactIds() {
      return this.$store.getters['onboarding/getDelegateContactIds']
    },
    shares() {
      return this.$store.getters['onboarding/getShares']
    },
    ownerAccount() {
      return this.$store.getters['onboarding/getOwnerAccount']
    },
    networkPartners() {
      return this.$store.getters['onboarding/getNetworkPartners']
    },
    openedCollapses() {
      return this.$store.getters['onboarding/getOpenedCollapses']
    },
    components() {
      return this.$store.getters['onboarding/getComponents']
    },
    portalLinks() {
      return this.$store.getters['onboarding/getPortalLinks']
    },
    typeOfUseData() {
      if (!this.object || this.objectCategory === 'UNDEFINED_CATEGORY') {
        return ['TYPE_OF_USE_VACATION', 'TYPE_OF_USE_COMMERCIAL', 'TYPE_OF_USE_PARKING', 'TYPE_OF_USE_PARKING_EXTERNAL', 'TYPE_OF_USE_AGRICULTURAL', 'TYPE_OF_USE_CONSTRUCTION', 'TYPE_OF_USE_GASTRONOMY', 'TYPE_OF_USE_INDUSTRIAL', 'TYPE_OF_USE_INVESTMENT', 'TYPE_OF_USE_STORAGE', 'TYPE_OF_USE_RESIDENTIAL', 'TYPE_OF_USE_OFFICE', 'TYPE_OF_USE_STORE', 'TYPE_OF_USE_ADVERTISING_AREA']
      } else if (this.objectCategory === 'APARTMENT' || this.objectCategory === 'HOUSE') {
        return ['TYPE_OF_USE_STORAGE', 'TYPE_OF_USE_VACATION', 'TYPE_OF_USE_GASTRONOMY', 'TYPE_OF_USE_INDUSTRIAL', 'TYPE_OF_USE_INVESTMENT', 'TYPE_OF_USE_CONSTRUCTION', 'TYPE_OF_USE_RESIDENTIAL', 'TYPE_OF_USE_COMMERCIAL']
      } else if (this.objectCategory === 'COMMERCIAL' || this.object.category === 'GASTRONOMY') {
        return ['TYPE_OF_USE_CONSTRUCTION', 'TYPE_OF_USE_GASTRONOMY', 'TYPE_OF_USE_INDUSTRIAL', 'TYPE_OF_USE_INVESTMENT', 'TYPE_OF_USE_STORAGE', 'TYPE_OF_USE_RESIDENTIAL', 'TYPE_OF_USE_COMMERCIAL', 'TYPE_OF_USE_OFFICE', 'TYPE_OF_USE_STORE', 'TYPE_OF_USE_ADVERTISING_AREA', 'TYPE_OF_USE_RADIO_ANTENNA']
      } else if (this.objectCategory === 'GARDEN' || this.objectCategory === 'PLOT' || this.objectCategory === 'AGRICULTURE') {
        return ['TYPE_OF_USE_COMMERCIAL', 'TYPE_OF_USE_GASTRONOMY', 'TYPE_OF_USE_INDUSTRIAL', 'TYPE_OF_USE_INVESTMENT', 'TYPE_OF_USE_CONSTRUCTION', 'TYPE_OF_USE_AGRICULTURAL']
      } else if (this.objectCategory === 'SECONDARY_ROOM') {
        return ['TYPE_OF_USE_STORAGE', 'TYPE_OF_USE_RESIDENTIAL', 'TYPE_OF_USE_SECONDARY_ROOM']
      } else if (this.objectCategory === 'PARKING_SPACE') {
        return ['TYPE_OF_USE_PARKING', 'TYPE_OF_USE_PARKING_EXTERNAL']
      } else {
        return []
      }
    }
  },
  methods: {
    loadOnboarding(buildingId) {
      this.$store.dispatch('onboarding/load', buildingId)
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.generic'), { type: 'error' })
        })
    },
    loadBuildingForRequest(buildingId) {
      this.$store.dispatch('onboarding/loadBuildingForRequest', buildingId)
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.generic'), { type: 'error' })
        })
    },
    refreshBuilding(buildingId) {
      return this.$store.dispatch('onboarding/loadBuildingForRequest', buildingId)
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.generic'), { type: 'error' })
        })
    },
    refreshObjects() {
      return this.$store.dispatch('onboarding/loadObjects', true)
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
        })
    },
    async refreshObjectsAsync() {
      return await this.$store.dispatch('onboarding/loadObjects', true)
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
        })
    },
    removeDuplicatesContact(array) {
      return array.reverse().reduce((acc, current) => {
        const currentObjectId = current.objectID ? current.objectID : current.id
        const x = acc.find(item => (item?.objectID && item.objectID === currentObjectId) || (item?.id && item.id === currentObjectId))
        if (!x) {
          return acc.concat([current])
        } else {
          return acc
        }
      }, [])
    },
    loadAdditionalBuildingContacts(contactIds) {
      return this.$store.dispatch('onboarding/loadAdditionalBuildingContacts', contactIds)
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        })
    },
    // refreshShares() {
    //   this.$store.dispatch('onboarding/loadShares')
    //     .catch(e => {
    //       console.log(e)
    //       Vue.toasted.show(this.$t('message.loadingErrors.generic'), { type: 'error' })
    //     })
    // },
    async saveOnboarding(externalSource) {
      return await this.$store.dispatch('onboarding/saveOnboarding', externalSource)
        .catch(e => {
          console.log(e)
          this.$store.dispatch('onboarding/setLoading', false)
          Vue.toasted.show(this.$t('message.savingErrors.onboarding'), { type: 'error' })
        })
    },
    setBuildingImageMedia(media) {
      if (media === null) {
        this.$store.dispatch('onboarding/updateBuildingField', { field: 'imageMediaId', value: '' })
        this.$store.dispatch('onboarding/setBuildingImageMedia', '')
      } else {
        this.$store.dispatch('onboarding/updateBuildingField', { field: 'imageMediaId', value: media.media.id })
        this.$store.dispatch('onboarding/setBuildingImageMedia', media)
      }
    },
    addNewBuildingDevice() {
      this.$store.dispatch('onboarding/addNewBuildingDevice')
    },
    addNewBuildingRoom() {
      this.$store.dispatch('onboarding/addNewBuildingRoom')
    },
    setIsBuildingObjectsFiltered(value) {
      this.$store.dispatch('onboarding/setIsBuildingObjectsFiltered', value)
    },
    duplicateBuildingDevice(device) {
      this.$store.dispatch('onboarding/duplicateBuildingDevice', device)
    },
    updateBuildingDevice(device) {
      this.$store.dispatch('onboarding/updateBuildingDevice', device)
    },
    updateBuildingRoom(room) {
      this.$store.dispatch('onboarding/updateBuildingRoom', room)
    },
    removeBuildingDevice(device) {
      this.$store.dispatch('onboarding/removeBuildingDevice', device)
    },
    removeBuildingRoom(room) {
      this.$store.dispatch('onboarding/removeBuildingRoom', room)
    },
    getFilteredBuildingDeviceDocuments(documentIds) {
      return this.buildingDeviceDocuments.filter(doc => {
        return documentIds.includes(doc.id)
      })
    },
    getFilteredBuildingRoomDocuments(documentIds) {
      return this.buildingRoomDocuments.filter(doc => {
        return documentIds.includes(doc.id)
      })
    },
    addNewInsurance() {
      this.$store.dispatch('onboarding/addNewInsurance')
    },
    updateInsurance(insurance) {
      this.$store.dispatch('onboarding/updateInsurance', insurance)
    },
    addNewSettlement() {
      this.$store.dispatch('onboarding/addNewSettlementObjects')
    },
    updateSettlementObjects(settlement) {
      this.$store.dispatch('onboarding/updateSettlementObjects', settlement)
    },
    addNewMortgage(referenceId) {
      this.$store.dispatch('onboarding/addNewMortgage', referenceId)
    },
    updateBuildingMortgage(mortgage) {
      this.$store.dispatch('onboarding/updateBuildingMortgage', mortgage)
    },
    updateObjectMortgage(mortgage) {
      this.$store.dispatch('onboarding/updateObjectMortgage', mortgage)
    },
    addNewEnergySource(referenceId) {
      this.$store.dispatch('onboarding/addNewEnergySource', referenceId)
    },
    // updateEnergySource(energySourceIndex) {
    //   this.$store.dispatch('onboarding/updateEnergySource', energySourceIndex)
    // },
    updateObjectEnergySource(energySourceIndex) {
      this.$store.dispatch('onboarding/updateObjectEnergySource', energySourceIndex)
    },
    updateBuildingEnergySource(energySourceIndex) {
      this.$store.dispatch('onboarding/updateBuildingEnergySource', energySourceIndex)
    },
    updateDismissCountDown(countdown) {
      this.$store.dispatch('onboarding/setDismissCountDown', countdown)
    },
    removeInsurance(insurance) {
      this.$store.dispatch('onboarding/removeInsurance', insurance)
    },
    removeSettlementObjects(settlement) {
      this.$store.dispatch('onboarding/removeSettlementObjects', settlement)
    },
    removeBuildingMortgage(mortgage) {
      this.$store.dispatch('onboarding/removeBuildingMortgage', mortgage)
    },
    removeObjectMortgage(mortgage) {
      this.$store.dispatch('onboarding/removeObjectMortgage', mortgage)
    },
    // removeEnergySource(energySourceIndex) {
    //   this.$store.dispatch('onboarding/removeEnergySource', energySourceIndex)
    // },
    removeBuildingEnergySource(energySource) {
      this.$store.dispatch('onboarding/removeBuildingEnergySource', energySource)
    },
    removeObjectEnergySource(energySource) {
      this.$store.dispatch('onboarding/removeObjectEnergySource', energySource)
    },
    getFilteredInsuranceDocuments(documentIds) {
      return this.insuranceDocuments.filter(doc => {
        return documentIds.includes(doc.id)
      })
    },
    getFilteredBuildingMortgageDocuments(documentIds) {
      return this.buildingMortgageDocuments.filter(doc => {
        return documentIds.includes(doc.id)
      })
    },
    getFilteredObjectMortgageDocuments(documentIds) {
      return this.objectMortgageDocuments.filter(doc => {
        return documentIds.includes(doc.id)
      })
    },
    getObject(id) {
      return this.objects.find(obj => obj.id === id)
    },
    async addNewObject(objects) {
      await this.$store.dispatch('onboarding/addNewObject', objects)
    },
    async removeObjects(object) {
      await this.$store.dispatch('onboarding/removeObjects', object)
    },
    getFilteredObjectDocuments(documentIds, filterType = '', filterName = '') {
      return this.objectDocuments.filter(doc => {
        const cdtObject = documentIds.includes(doc.id)
        const cdtName = (doc.name?.toLowerCase()).includes(filterName.toLowerCase())
        const cdtType = filterType === '' || doc.type === filterType
        return cdtObject && cdtName && cdtType
      })
    },
    getDocumentIdsOfObject(object) {
      return object.documents.reduce((acc, document) => {
        return acc.concat(document.documentId)
      }, [])
    },
    getFilteredTenancies(objectId) {
      const tempTenancies = this.tenancies.filter(ten => {
        return ten.objectId === objectId
      })
      return tempTenancies
    },
    getFilteredCondominium(objectId) {
      const tempCondominiumOwnership = this.condominiumOwnership.filter(ten => {
        return ten.objectId === objectId
      })
      return tempCondominiumOwnership
    },
    addNewCondominiumOwnership(objectId) {
      this.$store.dispatch('onboarding/addNewCondominiumOwnership', objectId)
    },
    addNewTenancy(objectId) {
      this.$store.dispatch('onboarding/addNewTenancy', objectId)
    },
    setTenancies(tenancies) {
      this.$store.dispatch('onboarding/setTenancies', tenancies)
    },
    addNewVacancy(objectId) {
      this.$store.dispatch('onboarding/addNewVacancy', objectId)
    },
    enterTermination(objectId) {
      this.$store.dispatch('onboarding/enterTermination', objectId)
    },
    updateTenancy(tenancy) {
      this.$store.dispatch('onboarding/updateTenancy', tenancy)
    },
    updateCondominiumOwnership(condominium) {
      this.$store.dispatch('onboarding/updateCondominiumOwnership', condominium)
    },
    async removeTenancy(tenancy) {
      return this.$store.dispatch('onboarding/removeTenancy', tenancy)
    },
    async removeCondominiumOwnership(condominium) {
      return this.$store.dispatch('onboarding/removeCondominiumOwnership', condominium)
    },
    async removeTenancyInstant(tenancy) {
      return this.$store.dispatch('onboarding/removeTenancyInstant', tenancy)
    },
    async removeCondominiumInstant(condominium) {
      return this.$store.dispatch('onboarding/removeCondominiumInstant', condominium)
    },
    getFilteredTenancyDocuments(documentIds) {
      return this.tenancyDocuments.filter(doc => {
        return documentIds.includes(doc.id)
      })
    },
    getFilteredCondominiumDocuments(documentIds) {
      return this.condominiumDocuments.filter(doc => {
        return documentIds.includes(doc.id)
      })
    },
    getFilteredObjectDevices(objectId) {
      return this.objectDevices.filter(dev => {
        return dev.referenceId === objectId
      })
    },
    getFilteredObjectRooms(objectId) {
      return this.objectRooms.filter(room => {
        return room.referenceId === objectId
      }).sort((a, b) => a.order - b.order)
    },
    addNewObjectDevice(objectId) {
      this.$store.dispatch('onboarding/addNewObjectDevice', objectId)
    },
    addComponent(refId, optionalValue) {
      this.$store.dispatch('onboarding/addComponent', { refId: refId, optionalValue: optionalValue })
    },
    updateComponent(component) {
      this.$store.dispatch('onboarding/updateComponent', component)
    },
    removeComponent(component) {
      this.$store.dispatch('onboarding/removeComponent', component)
    },
    setComponents(components) {
      this.$store.dispatch('onboarding/setComponents', components)
    },
    addPortalLink(refId) {
      this.$store.dispatch('onboarding/addPortalLink', refId)
    },
    updatePortalLink(item) {
      this.$store.dispatch('onboarding/updatePortalLink', item)
    },
    removePortalLink(item) {
      this.$store.dispatch('onboarding/removePortalLink', item)
    },
    setHandoverProtocols(protocols) {
      this.$store.dispatch('onboarding/setHandoverProtocols', protocols)
    },
    addNewObjectRooms(objectId) {
      this.$store.dispatch('onboarding/addNewObjectRooms', objectId)
    },
    duplicateObjectDevice(device) {
      this.$store.dispatch('onboarding/duplicateObjectDevice', device)
    },
    updateObjectDevice(device) {
      this.$store.dispatch('onboarding/updateObjectDevice', device)
    },
    updateObjectRoom(room) {
      this.$store.dispatch('onboarding/updateObjectRoom', room)
    },
    removeObjectDevice(device) {
      this.$store.dispatch('onboarding/removeObjectDevice', device)
    },
    removeObjectRoom(room) {
      this.$store.dispatch('onboarding/removeObjectRoom', room)
    },
    getFilteredObjectDeviceDocuments(documentIds) {
      return this.objectDeviceDocuments.filter(doc => {
        return documentIds.includes(doc.id)
      })
    },
    getFilteredObjectRoomDocuments(documentIds) {
      return this.objectRoomDocuments.filter(doc => {
        return documentIds.includes(doc.id)
      })
    },
    getPositionLabelFromArray(positions) {
      if (positions === null || positions.length === 0) {
        return ''
      } else {
        let label = ''
        positions.forEach(position => {
          label += ' ' + this.$t('message.onBoarding.buildings.positionTypes.' + position)
        })
        return label
      }
    },
    resetChanged() {
      this.$store.dispatch('onboarding/resetChanged')
    },
    setChangedTenant() {
      this.$store.dispatch('onboarding/setChangedTenant')
    },
    activateChanged() {
      this.$store.dispatch('onboarding/activateChanged')
    },
    getCurrentTenancy(objectId) {
      // We got an object from our backend
      const startOfDayTimestamp = this.getStartOfDayTimestamp()

      return this.getFilteredTenancies(objectId)
        .sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
        .find(t => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 <= startOfDayTimestamp && (!t.endingAt || (new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 < 0 || new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 >= startOfDayTimestamp)))
    },
    getSortedContactIdsCurrentTenancy(objectId) {
      // this function returns the contactIds of the current tenancy sorted by debtor first then tenants
      const startOfDayTimestamp = this.getStartOfDayTimestamp()
      const tenancy = this.getFilteredTenancies(objectId)
        .sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
        .find(t => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 <= startOfDayTimestamp && (!t.endingAt || (new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 < 0 || new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 >= startOfDayTimestamp)))
      let contactIds = []
      if (tenancy) {
        if (tenancy.tenant.contactIds.includes(tenancy.tenant.billingContactId)) {
          contactIds = [tenancy.tenant.billingContactId].concat(tenancy.tenant.contactIds.filter(x => x !== tenancy.tenant.billingContactId))
        } else {
          contactIds = tenancy.tenant.contactIds
        }
      }
      return contactIds
    },
    getCurrentCondominium(objectId) {
      // We got an object from our backend
      const startOfDayTimestamp = this.getStartOfDayTimestamp()
      if (this.getFilteredCondominium(objectId).length > 0) {
        const t = this.getFilteredCondominium(objectId)
          .sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
          .find(t => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 <= startOfDayTimestamp && (!t.endingAt || (new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 < 0 || new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 >= startOfDayTimestamp)))
        return t
      }
      return null
    }
  }
}

export { onboarding }
