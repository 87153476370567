<template>
  <section>
    <div
      v-if="loading"
      class="row">
      <div class="col text-center">
        <coozzy-spinner class="m-3" />
      </div>
    </div>
    <div
      v-else
      class="col-12">
      <coozzy-card
        class="global-card">
        <div class="row col-xl-5 mt-3">
          <label for="templateId">
            {{ $t('message.settings.exportTemplate.title') }}
          </label>
          <coozzy-form-select
            v-model="templateId"
            name="templateId">
            <option value="">
              -
            </option>
            <option
              v-for="(template, typeIndex) in templates"
              :key="typeIndex"
              :value="template"
              :selected="typeIndex === 0">
              {{ template.name }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="row col-xl-5 mt-3">
          <label for="typeOfUse">
            {{ $t('message.navigation.calendar.export') }}
          </label>
          <coozzy-form-select v-model="typeOfTenancy">
            <option :value="'TENANCY_TYPE_ALL'">
              {{ $t('message.onBoarding.tenants.tenanciesVacancies') }}
            </option>
            <option :value="'TENANCY_TYPE_RENTED'">
              {{ $t('message.onBoarding.tenants.tenancies') }}
            </option>
            <option :value="'TENANCY_TYPE_VACANT'">
              {{ $t('message.onBoarding.tenants.vacancies') }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="row col-12 mb-3">
          <coozzy-form-checkbox
            ref="target_date_checkbox"
            v-model="targetDateCheckbox"
            :initial="true"
            class="mt-3 col-12"
            @change="(value) => { handleCheckboxesTargetData('targetDateCheckbox', value) }">
            {{ $t('message.generateExcel.targetData') }}
          </coozzy-form-checkbox>
          <div class="col-sm-12 col-md-3 mt-2 date-picker-container">
            <date-picker
              v-model="targetDate"
              value-type="format"
              format="DD/MM/YYYY"
              title-format="M"
              :placeholder="$t('message.navigation.reporting.keyDate')"
              :multiple="false"
              :clearable="true"
              :editable="true"
              type="date"
              :disabled="periodCheckbox || (!periodCheckbox && !targetDateCheckbox)"
              :lang="getLang" />
          </div>
        </div>
        <div class="row col-12 mb-3">
          <coozzy-form-checkbox
            ref="period_checkbox"
            v-model="periodCheckbox"
            class="col-12"
            @change="(value) => { handleCheckboxesTargetData('periodCheckbox', value) }">
            {{ $t('message.generateExcel.period') }}
          </coozzy-form-checkbox>
          <div class="col-sm-12 col-md-3 mt-2">
            <div
              class="date-picker-container"
              :class="(targetDateCheckbox || (!periodCheckbox && !targetDateCheckbox)) ? 'date-picker-container-gray-disable' : ''">
              <label>{{
                $t('message.tableColumnHeadings.from')
              }}</label>
              <date-picker
                :id="'periodDateFrom'"
                ref="periodDateFrom"
                v-model="periodDate.from"
                value-type="format"
                format="MM/YYYY"
                title-format="M"
                :placeholder="$t('message.tableColumnHeadings.from')"
                :multiple="false"
                :clearable="true"
                :editable="true"
                type="month"
                :disabled="targetDateCheckbox || (!periodCheckbox && !targetDateCheckbox)"
                :lang="getLang" />
            </div>
          </div>
          <div class="col-sm-12 col-md-3 mt-2">
            <div
              class="date-picker-container"
              :class="(targetDateCheckbox || (!periodCheckbox && !targetDateCheckbox)) ? 'date-picker-container-gray-disable' : ''">
              <label>{{
                $t('message.tableColumnHeadings.to')
              }}</label>
              <date-picker
                :id="'periodDateTo'"
                ref="periodDateTo"
                v-model="periodDate.to"
                value-type="format"
                format="MM/YYYY"
                title-format="M"
                :placeholder="$t('message.tableColumnHeadings.to')"
                :multiple="false"
                :clearable="true"
                :editable="true"
                type="month"
                :disabled="targetDateCheckbox || (!periodCheckbox && !targetDateCheckbox)"
                :lang="getLang" />
            </div>
          </div>
        </div>
        <div
          class="col-sm-12 col-md-6 mb-3">
          <label>{{ $t('message.settings.template.placeholderTemplate.language') }}</label>
          <coozzy-form-select v-model="language">
            <option :value="'PREFERRED_LANGUAGE_ENGLISH'">
              EN
            </option>
            <option :value="'PREFERRED_LANGUAGE_GERMAN'">
              DE
            </option>
            <option :value="'PREFERRED_LANGUAGE_ITALIAN'">
              IT
            </option>
          </coozzy-form-select>
        </div>
        <div
          class="col-sm-12 col-md-6">
          <label>{{ $t('message.generateExcel.sorting.title') }}</label>
          <coozzy-form-select
            v-model="sortingType"
            class="mb-2">
            <option :value="'SORTING_TYPE_OBJECT_ID'">
              {{ $t('message.generateExcel.sorting.byObjectId') }}
            </option>
            <option :value="'SORTING_TYPE_OBJECT_NUMBER'">
              {{ $t('message.generateExcel.sorting.byObjectNumber') }}
            </option>
            <option :value="'SORTING_TYPE_STANDARD'">
              {{ $t('message.generateExcel.sorting.standard') }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col-sm-12 col-md-6">
          <label>{{ $t('message.generateExcel.composition.title') }}</label>
          <coozzy-form-select
            v-model="compositionType"
            class="mb-2">
            <option :value="'COMPOSITION_TYPE_SEPARATELY'">
              {{ $t('message.generateExcel.composition.separately') }}
            </option>
            <option :value="'COMPOSITION_TYPE_CONSOLIDATE'">
              {{ $t('message.generateExcel.composition.consolidate') }}
            </option>
          </coozzy-form-select>
          <coozzy-form-select
            v-model="compositionLinesOrColumns"
            class="mb-2">
            <option :value="'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'">
              {{ $t('message.generateExcel.compositionLinesOrColumns.vertical') }}
            </option>
            <option :value="'COMPOSITION_LINES_OR_COLUMNS_HORIZONTAL'">
              {{ $t('message.generateExcel.compositionLinesOrColumns.horizontal') }}
            </option>
          </coozzy-form-select>
        </div>
        <div
          :id="'collapse-selected-fields'"
          v-b-toggle="'collapse-selected-fields'"
          class="col pt-3 mb-0">
          <div class="row">
            <div class="col">
              <h5
                class="cursor-pointer hover width-only-content">
                {{ $t('message.generateExcel.fieldsTitle') }}
              </h5>
            </div>
          </div>
        </div>
        <b-collapse
          :id="'collapse-selected-fields'"
          ref="collapse"
          :visible="false"
          class="col">
          <div class="row col-12">
            <div class="row col-sm-12 col-md-4 col-lg-3 col-xl-2">
              <coozzy-form-checkbox
                ref="selectAll_checkbox"
                v-model="selectAll"
                class="pt-2 pb-2 pr-1 m-1 checkbox-label-text-md">
                <template>
                  {{ $t('message.generateExcel.selectAll') }}
                </template>
              </coozzy-form-checkbox>
            </div>
            <div class="row col-sm-12 col-md-4 col-lg-3 col-xl-2">
              <coozzy-form-checkbox
                ref="deselectAll_checkbox"
                v-model="deselectAll"
                class="pt-2 pb-2 pr-1 m-1 checkbox-label-text-md">
                <template>
                  {{ $t('message.generateExcel.deselectAll') }}
                </template>
              </coozzy-form-checkbox>
            </div>
          </div>
          <label class="text-lg color_dark mt-3 col-12 row">
            {{ $t('message.generateExcel.selectedFields') }}
            <coozzy-info-circle-icon
              v-b-tooltip.hover.html="$t('message.generateExcel.selectedFieldsTooltip')" />
          </label>
          <coozzy-card class="global-card padding-top-1rem">
            <draggable
              :list="selectedFields"
              :move="checkMove"
              class="row pr-2 pl-2">
              <template
                v-for="(item, itemIndex) in selectedFields">
                <div
                  :key="`item_${itemIndex}`"
                  class="col-4 pl-0 pr-0">
                  <coozzy-form-checkbox
                    v-model="item.selected"
                    :initial="item.selected"
                    class="borderFields pt-2 pb-2 p r-1 m-1">
                    <template>
                      {{ $t(`message.generateExcel.fields.${item.key}`) }}
                    </template>
                    <template v-if="compositionLinesOrColumns === 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'">
                      <font-awesome-icon
                        v-if="item.subgroup === 'tenant' "
                        icon="address-book"
                        class="fa" />
                      <font-awesome-icon
                        v-if="item.subgroup === 'subtenancy' "
                        icon="history"
                        class="fa" />
                      <img
                        v-if="item.subgroup === 'rentAdjustment' "
                        width="12.5px"
                        height="14px"
                        src="@/assets/icon/activate-tenancy-house.svg">
                    </template>
                  </coozzy-form-checkbox>
                </div>
              </template>
            </draggable>
          </coozzy-card>
          <label class="text-lg color_dark mt-3 col-12 row">
            {{ $t('message.generateExcel.availableFields') }}
            <coozzy-info-circle-icon
              v-b-tooltip.hover.html="$t('message.generateExcel.selectedFieldsTooltip')" />
          </label>
          <coozzy-card class="global-card padding-top-1rem">
            <label class="color_dark">
              {{ $t('message.generateExcel.property') }}
            </label>
            <draggable
              :list="propertyFields"
              :disabled="true"
              class="row pr-2 pl-2">
              <template
                v-for="(item, itemIndex) in propertyFields">
                <div
                  :key="`item_${itemIndex}`"
                  class="col-4 pl-0 pr-0">
                  <coozzy-form-checkbox
                    v-model="item.selected"
                    :initial="item.selected"
                    class="borderFields pt-2 pb-2 pr-1 m-1">
                    <template>
                      {{ $t(`message.generateExcel.fields.${item.key}`) }}
                    </template>
                    <template v-if="compositionLinesOrColumns === 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'">
                      <font-awesome-icon
                        v-if="item.subgroup === 'tenant' "
                        icon="address-book"
                        class="fa" />
                      <font-awesome-icon
                        v-if="item.subgroup === 'subtenancy' "
                        icon="history"
                        class="fa" />
                      <img
                        v-if="item.subgroup === 'rentAdjustment' "
                        width="12.5px"
                        height="14px"
                        src="@/assets/icon/activate-tenancy-house.svg">
                    </template>
                  </coozzy-form-checkbox>
                </div>
              </template>
            </draggable>
          </coozzy-card>

          <coozzy-card class="global-card padding-top-1rem">
            <label class="color_dark">
              {{ $t('message.generateExcel.building') }}
            </label>
            <draggable
              :list="buildingFields"
              :disabled="true"
              class="row pr-2 pl-2">
              <template
                v-for="(item, itemIndex) in buildingFields">
                <div
                  :key="`item_${itemIndex}`"
                  class="col-4 pl-0 pr-0">
                  <coozzy-form-checkbox
                    v-model="item.selected"
                    :initial="item.selected"
                    class="borderFields pt-2 pb-2 pr-1 m-1">
                    <template>
                      {{ $t(`message.generateExcel.fields.${item.key}`) }}
                    </template>
                    <template v-if="compositionLinesOrColumns === 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'">
                      <font-awesome-icon
                        v-if="item.subgroup === 'tenant' "
                        icon="address-book"
                        class="fa" />
                      <font-awesome-icon
                        v-if="item.subgroup === 'subtenancy' "
                        icon="history"
                        class="fa" />
                      <img
                        v-if="item.subgroup === 'rentAdjustment' "
                        width="12.5px"
                        height="14px"
                        src="@/assets/icon/activate-tenancy-house.svg">
                    </template>
                  </coozzy-form-checkbox>
                </div>
              </template>
            </draggable>
          </coozzy-card>

          <coozzy-card class="global-card padding-top-1rem">
            <label class="color_dark">
              {{ $t('message.generateExcel.object') }}
            </label>
            <draggable
              :list="objectFields"
              :disabled="true"
              class="row pr-2 pl-2">
              <template
                v-for="(item, itemIndex) in objectFields">
                <div
                  :key="`item_${itemIndex}`"
                  class="col-4 pl-0 pr-0">
                  <coozzy-form-checkbox
                    v-model="item.selected"
                    :initial="item.selected"
                    class="borderFields pt-2 pb-2 pr-1 m-1">
                    <template>
                      {{ $t(`message.generateExcel.fields.${item.key}`) }}
                    </template>
                    <template v-if="compositionLinesOrColumns === 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'">
                      <font-awesome-icon
                        v-if="item.subgroup === 'tenant' "
                        icon="address-book"
                        class="fa" />
                      <font-awesome-icon
                        v-if="item.subgroup === 'subtenancy' "
                        icon="history"
                        class="fa" />
                      <img
                        v-if="item.subgroup === 'rentAdjustment' "
                        width="12.5px"
                        height="14px"
                        src="@/assets/icon/activate-tenancy-house.svg">
                    </template>
                  </coozzy-form-checkbox>
                </div>
              </template>
            </draggable>
          </coozzy-card>

          <coozzy-card class="global-card padding-top-1rem">
            <label class="color_dark">
              {{ $t('message.generateExcel.tenancy') }}
            </label>
            <draggable
              :list="tenancyFields"
              :disabled="true"
              class="row pr-2 pl-2">
              <template
                v-for="(item, itemIndex) in tenancyFields">
                <div
                  :key="`item_${itemIndex}`"
                  class="col-4 pl-0 pr-0">
                  <coozzy-form-checkbox
                    v-model="item.selected"
                    :initial="item.selected"
                    class="borderFields pt-2 pb-2 pr-1 m-1">
                    <template>
                      {{ $t(`message.generateExcel.fields.${item.key}`) }}
                    </template>
                    <template v-if="compositionLinesOrColumns === 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'">
                      <font-awesome-icon
                        v-if="item.subgroup === 'tenant' "
                        icon="address-book"
                        class="fa" />
                      <font-awesome-icon
                        v-if="item.subgroup === 'subtenancy' "
                        icon="history"
                        class="fa" />
                      <img
                        v-if="item.subgroup === 'rentAdjustment' "
                        width="12.5px"
                        height="14px"
                        src="@/assets/icon/activate-tenancy-house.svg">
                    </template>
                  </coozzy-form-checkbox>
                </div>
              </template>
            </draggable>
          </coozzy-card>

          <coozzy-card class="global-card padding-top-1rem">
            <label class="color_dark">
              {{ $t('message.generateExcel.tenantOwner') }}
            </label>
            <draggable
              :list="tenantOwnerFields"
              :disabled="true"
              class="row pr-2 pl-2">
              <template
                v-for="(item, itemIndex) in tenantOwnerFields">
                <div
                  :key="`item_${itemIndex}`"
                  class="col-4 pl-0 pr-0">
                  <coozzy-form-checkbox
                    v-model="item.selected"
                    :initial="item.selected"
                    class="borderFields pt-2 pb-2 pr-1 m-1">
                    <template>
                      {{ $t(`message.generateExcel.fields.${item.key}`) }}
                    </template>
                    <template v-if="compositionLinesOrColumns === 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'">
                      <font-awesome-icon
                        v-if="item.subgroup === 'tenant' "
                        icon="address-book"
                        class="fa" />
                      <font-awesome-icon
                        v-if="item.subgroup === 'subtenancy' "
                        icon="history"
                        class="fa" />
                      <img
                        v-if="item.subgroup === 'rentAdjustment' "
                        width="12.5px"
                        height="14px"
                        src="@/assets/icon/activate-tenancy-house.svg">
                    </template>
                  </coozzy-form-checkbox>
                </div>
              </template>
            </draggable>
          </coozzy-card>

          <coozzy-card class="global-card padding-top-1rem">
            <label class="color_dark">
              {{ $t('message.generateExcel.marketing') }}
            </label>
            <draggable
              :list="marketingFields"
              :disabled="true"
              class="row pr-2 pl-2">
              <template
                v-for="(item, itemIndex) in marketingFields">
                <div
                  :key="`item_${itemIndex}`"
                  class="col-4 pl-0 pr-0">
                  <coozzy-form-checkbox
                    v-model="item.selected"
                    :initial="item.selected"
                    class="borderFields pt-2 pb-2 pr-1 m-1">
                    <template>
                      {{ $t(`message.generateExcel.fields.${item.key}`) }}
                    </template>
                    <template v-if="compositionLinesOrColumns === 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'">
                      <font-awesome-icon
                        v-if="item.subgroup === 'tenant' "
                        icon="address-book"
                        class="fa" />
                      <font-awesome-icon
                        v-if="item.subgroup === 'subtenancy' "
                        icon="history"
                        class="fa" />
                      <img
                        v-if="item.subgroup === 'rentAdjustment' "
                        width="12.5px"
                        height="14px"
                        src="@/assets/icon/activate-tenancy-house.svg">
                    </template>
                  </coozzy-form-checkbox>
                </div>
              </template>
            </draggable>
          </coozzy-card>
          <coozzy-card class="global-card padding-top-1rem">
            <label class="color_dark">
              {{ $t('message.generateExcel.linkedContacts') }}
            </label>
            <draggable
              :list="linkedContactFields"
              :disabled="true"
              class="row pr-2 pl-2">
              <template
                v-for="(item, itemIndex) in linkedContactFields">
                <div
                  :key="`item_${itemIndex}`"
                  class="col-4 pl-0 pr-0">
                  <coozzy-form-checkbox
                    v-model="item.selected"
                    :initial="item.selected"
                    class="borderFields pt-2 pb-2 pr-1 m-1">
                    <template>
                      {{ $t(`message.generateExcel.fields.${item.key}`) }}
                    </template>
                    <template v-if="compositionLinesOrColumns === 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL'">
                      <font-awesome-icon
                        v-if="item.subgroup === 'tenant' "
                        icon="address-book"
                        class="fa" />
                      <font-awesome-icon
                        v-if="item.subgroup === 'subtenancy' "
                        icon="history"
                        class="fa" />
                      <img
                        v-if="item.subgroup === 'rentAdjustment' "
                        width="12.5px"
                        height="14px"
                        src="@/assets/icon/activate-tenancy-house.svg">
                    </template>
                  </coozzy-form-checkbox>
                </div>
              </template>
            </draggable>
          </coozzy-card>
        </b-collapse>
      </coozzy-card>
    </div>
  </section>
</template>

<script>

import { tables } from '@/mixins/tables'
import { user } from '@/mixins/user'
import draggable from 'vuedraggable'
import ObjectApi from '@/misc/apis/ObjectApi'
import { generateExcel } from '@/mixins/generateExcel'
import { required } from 'vuelidate/lib/validators'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import DatePicker from 'vue2-datepicker'
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'

function endDateMoreThanStartDate() {
  if (this.periodDate.from && this.periodDate.to) {
    const startDateArray = this.periodDate.from.split('/')
    const endDateArray = this.periodDate.to.split('/')
    const startDate = new Date(startDateArray[1], startDateArray[0] - 1, 1).getTime()
    const endDate = new Date(endDateArray[1], endDateArray[0], 0).getTime()
    return startDate < endDate
  }
  return false
}

export default {
  name: 'SelectFieldsAndParameters',
  components: {
    CoozzySpinner,
    CoozzyInfoCircleIcon,
    CoozzyCard,
    CoozzyFormCheckbox,
    CoozzyFormSelect,
    draggable,
    DatePicker
  },
  mixins: [tables, user, generateExcel],
  props: {
    propertyIds: {
      type: Array,
      default() {
        return []
      }
    },
    buildingIds: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      loading: true,
      templates: [],
      templateId: null,
      typeOfTenancy: 'TENANCY_TYPE_ALL',
      sortingType: 'SORTING_TYPE_STANDARD',
      compositionType: 'COMPOSITION_TYPE_SEPARATELY',
      compositionLinesOrColumns: 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL',
      targetDateCheckbox: true,
      periodCheckbox: false,
      targetDate: null,
      periodDate: {
        from: null,
        to: null
      },
      selectedFields: [],
      propertyFields: [],
      buildingFields: [],
      objectFields: [],
      tenancyFields: [],
      tenantOwnerFields: [],
      marketingFields: [],
      linkedContactFields: [],
      language: 'PREFERRED_LANGUAGE_GERMAN',
      selectAll: false,
      deselectAll: false,
      movedIndex: 0
    }
  },
  computed: {
    getLang() {
      return this.$store.getters['internationalization/getCurrentLanguage']
    }
  },
  watch: {
    templateId: function (newVal) {
      if (newVal) {
        this.typeOfTenancy = newVal.tenancyType
        this.language = newVal.language
        this.sortingType = newVal.sortingType
        this.compositionType = newVal.compositionType
        this.compositionLinesOrColumns = newVal.compositionLinesOrColumns
        this.selectedFields = []
        this.eraseAvailableTables()

        const selectedFields = []
        for (const field of newVal.fields) {
          if (this.defaultFields.some(x => x.name === field.name)) {
            selectedFields.push({ key: field.name, selected: true, category: this.defaultFields.find(x => x.name === field.name)?.category || field.category, subgroup: this.defaultFields.find(x => x.name === field.name)?.subgroup || field.subgroup })
          }
        }
        this.selectedFields = selectedFields
        this.defaultFields.filter(x => !this.selectedFields.find(y => y.key === x.name)).forEach(element => {
          this.pushField(element)
        })
      }
    },
    selectAll: function(newVal) {
      if (newVal) {
        const values = this.propertyFields.concat(this.buildingFields).concat(this.objectFields).concat(this.tenancyFields).concat(this.tenantOwnerFields).concat(this.marketingFields).concat(this.linkedContactFields)
        this.selectedFields = this.selectedFields.concat(values.map(x => ({ key: x.key, selected: true, category: x.category, subgroup: x.subgroup })))
        this.eraseAvailableTables()
      }
    },
    deselectAll: function(newVal) {
      if (newVal) {
        this.selectedFields.forEach(element => {
          this.pushField(element)
        })
        this.selectedFields = []
      }
    },
    targetDate: function() {
      this.changedData()
    },
    language: function() {
      this.changedData()
    },
    periodDate: {
      handler() {
        this.changedData()
      },
      deep: true
    },
    typeOfTenancy: function() {
      this.changedData()
    },
    sortingType: function() {
      this.changedData()
    },
    compositionType: function() {
      this.changedData()
    },
    compositionLinesOrColumns: function() {
      if (this.compositionLinesOrColumns === 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL') {
        this.ignoreChangeAfterSortingSelectedFields = true
        this.groupFields()
      }
      this.changedData()
    },
    periodCheckbox: function() {
      this.changedData()
    },
    selectedFields: {
      handler() {
        if (!this.ignoreChangeAfterSortingSelectedFields) {
          this.$nextTick(function () {
            if (this.compositionLinesOrColumns === 'COMPOSITION_LINES_OR_COLUMNS_VERTICAL') {
              this.ignoreChangeAfterSortingSelectedFields = true
              this.groupFields()
            }
          })
          if (this.selectedFields.filter(x => x.selected === false).length) {
            this.selectedFields.filter(x => x.selected === false).forEach(element => {
              this.pushField(element)
            })
            this.$nextTick(function () {
              this.selectedFields = this.selectedFields.filter(x => x.selected === true)
              if (this.propertyFields.length || this.buildingFields.length || this.objectFields.length || this.tenancyFields.length || this.tenantOwnerFields.length || this.marketingFields.length || this.linkedContactFields.length) {
                if (this.$refs.selectAll_checkbox) {
                  this.selectAll = false
                  this.$refs.selectAll_checkbox.checked = false
                }
              } else {
                if (this.$refs.selectAll_checkbox) {
                  this.selectAll = true
                  this.$refs.selectAll_checkbox.checked = true
                }
              }
            })
          }
        } else {
          this.ignoreChangeAfterSortingSelectedFields = false
        }
        this.changedData()
      },
      deep: true
    },
    propertyFields: {
      handler() {
        if (this.propertyFields.filter(x => x.selected === true).length) {
          this.propertyFields.filter(x => x.selected === true).forEach(element => {
            this.selectedFields.push({ key: element.key, selected: true, category: element.category, subgroup: element.subgroup })
          })
          this.$nextTick(function () {
            this.propertyFields = this.propertyFields.filter(x => x.selected === false)
          })
        }
      },
      deep: true
    },
    buildingFields: {
      handler() {
        if (this.buildingFields.filter(x => x.selected === true).length) {
          this.buildingFields.filter(x => x.selected === true).forEach(element => {
            this.selectedFields.push({ key: element.key, selected: true, category: element.category, subgroup: element.subgroup })
          })
          this.$nextTick(function () {
            this.buildingFields = this.buildingFields.filter(x => x.selected === false)
          })
        }
      },
      deep: true
    },
    objectFields: {
      handler() {
        if (this.objectFields.filter(x => x.selected === true).length) {
          this.objectFields.filter(x => x.selected === true).forEach(element => {
            this.selectedFields.push({ key: element.key, selected: true, category: element.category, subgroup: element.subgroup })
          })
          this.$nextTick(function () {
            this.objectFields = this.objectFields.filter(x => x.selected === false)
          })
        }
      },
      deep: true
    },
    tenancyFields: {
      handler() {
        if (this.tenancyFields.filter(x => x.selected === true).length) {
          this.tenancyFields.filter(x => x.selected === true).forEach(element => {
            this.selectedFields.push({ key: element.key, selected: true, category: element.category, subgroup: element.subgroup })
          })
          this.$nextTick(function () {
            this.tenancyFields = this.tenancyFields.filter(x => x.selected === false)
          })
        }
      },
      deep: true
    },
    tenantOwnerFields: {
      handler() {
        if (this.tenantOwnerFields.filter(x => x.selected === true).length) {
          this.tenantOwnerFields.filter(x => x.selected === true).forEach(element => {
            this.selectedFields.push({ key: element.key, selected: true, category: element.category, subgroup: element.subgroup })
          })
          this.$nextTick(function () {
            this.tenantOwnerFields = this.tenantOwnerFields.filter(x => x.selected === false)
          })
        }
      },
      deep: true
    },
    marketingFields: {
      handler() {
        if (this.marketingFields.filter(x => x.selected === true).length) {
          this.marketingFields.filter(x => x.selected === true).forEach(element => {
            this.selectedFields.push({ key: element.key, selected: true, category: element.category, subgroup: element.subgroup })
          })
          this.$nextTick(function () {
            this.marketingFields = this.marketingFields.filter(x => x.selected === false)
          })
        }
      },
      deep: true
    },
    linkedContactFields: {
      handler() {
        if (this.linkedContactFields.filter(x => x.selected === true).length) {
          this.linkedContactFields.filter(x => x.selected === true).forEach(element => {
            this.selectedFields.push({ key: element.key, selected: true, category: element.category, subgroup: element.subgroup })
          })
          this.$nextTick(function () {
            this.linkedContactFields = this.linkedContactFields.filter(x => x.selected === false)
          })
        }
      },
      deep: true
    }
  },
  async mounted() {
    this.loading = true
    this.setLanguage()
    this.mountFields()
    const today = new Date()
    this.targetDate = `${today.getDate().toString().padStart(2, '0')}/${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()}`
    this.templates = (await ObjectApi.rentalIndexTemplatesList(this.accountId))?.templates
    this.loading = false
  },
  methods: {
    sortFieldsByDefaultOrder(fields, category) {
      const indices = Object.fromEntries(
        this.defaultFields.filter(x => x.category === category).map(
          ({ name }, index) => [name, index]
        )
      )
      return fields.sort((a, b) => indices[a.key] - indices[b.key])
    },
    checkMove: function(evt) {
      this.movedIndex = evt.draggedContext.futureIndex
    },
    groupFields() {
      const sortedFields = []
      const subgroupsNeedSort = ['tenant', 'subtenancy', 'rentAdjustment']
      const subgroupsSorted = []
      const fieldMoved = this.selectedFields[this.movedIndex]
      for (const field of this.selectedFields) {
        if (field.subgroup === undefined) {
          sortedFields.push(field)
        } else if (subgroupsNeedSort.includes(field.subgroup) && !subgroupsSorted.includes(field.subgroup) && (!fieldMoved || (field.subgroup !== fieldMoved.subgroup || field.key === fieldMoved.key))) {
          if (fieldMoved && fieldMoved.subgroup !== field.subgroup) {
            for (const subgroupField of this.selectedFields.filter(x => x.subgroup === field.subgroup)) {
              sortedFields.push(subgroupField)
            }
            subgroupsSorted.push(field.subgroup)
          } else {
            for (const subgroupField of this.selectedFields.slice(0, this.movedIndex).filter(x => x.subgroup === field.subgroup && x.key !== field.key)) {
              sortedFields.push(subgroupField)
            }
            sortedFields.push(field)
            for (const subgroupField of this.selectedFields.slice(this.movedIndex, this.selectedFields.length).filter(x => x.subgroup === field.subgroup && x.key !== field.key)) {
              sortedFields.push(subgroupField)
            }
            subgroupsSorted.push(field.subgroup)
          }
        }
      }
      if (!(this.selectedFields.length === sortedFields.length && this.selectedFields.every((element, index) => element === sortedFields[index]))) {
        this.selectedFields = sortedFields
      } else {
        this.ignoreChangeAfterSortingSelectedFields = false
      }
    },
    changeCheckboxes() {
      if (this.propertyFields.length || this.buildingFields.length || this.objectFields.length || this.tenancyFields.length || this.tenantOwnerFields.length || this.marketingFields.length || this.linkedContactFields.length) {
        if (this.$refs.selectAll_checkbox) {
          this.selectAll = false
          this.$refs.selectAll_checkbox.checked = false
        }
      } else {
        if (this.$refs.selectAll_checkbox) {
          this.selectAll = true
          this.$refs.selectAll_checkbox.checked = true
        }
      }
      this.$nextTick(function () {
        if (this.selectedFields.length) {
          if (this.$refs.deselectAll_checkbox) {
            this.deselectAll = false
            this.$refs.deselectAll_checkbox.checked = false
          }
        }
      })
    },
    eraseAvailableTables() {
      this.propertyFields = []
      this.buildingFields = []
      this.objectFields = []
      this.tenancyFields = []
      this.tenantOwnerFields = []
      this.marketingFields = []
      this.linkedContactFields = []
    },
    pushField(element) {
      const elm = { key: element.key || element.name, selected: false, category: element.category, subgroup: element.subgroup }
      switch (elm.category) {
        case 'property':
          this.propertyFields.push(elm)
          this.propertyFields = this.sortFieldsByDefaultOrder(this.propertyFields, 'property')
          break
        case 'building':
          this.buildingFields.push(elm)
          this.buildingFields = this.sortFieldsByDefaultOrder(this.buildingFields, 'building')
          break
        case 'object':
          this.objectFields.push(elm)
          this.objectFields = this.sortFieldsByDefaultOrder(this.objectFields, 'object')
          break
        case 'tenancy':
          this.tenancyFields.push(elm)
          this.tenancyFields = this.sortFieldsByDefaultOrder(this.tenancyFields, 'tenancy')
          break
        case 'tenantOwner':
          this.tenantOwnerFields.push(elm)
          this.tenantOwnerFields = this.sortFieldsByDefaultOrder(this.tenantOwnerFields, 'tenantOwner')
          break
        case 'marketing':
          this.marketingFields.push(elm)
          this.marketingFields = this.sortFieldsByDefaultOrder(this.marketingFields, 'marketing')
          break
        case 'linkedContact':
          this.linkedContactFields.push(elm)
          this.linkedContactFields = this.sortFieldsByDefaultOrder(this.linkedContactFields, 'linkedContact')
          break
        default:
          return ''
      }
    },
    mountFields() {
      this.defaultFields.filter(x => x.category === 'property').forEach(element => {
        const elm = { key: element.name, selected: false, category: element.category, subgroup: element.subgroup }
        this.propertyFields.push(elm)
      })
      this.defaultFields.filter(x => x.category === 'building').forEach(element => {
        const elm = { key: element.name, selected: false, category: element.category, subgroup: element.subgroup }
        this.buildingFields.push(elm)
      })
      this.defaultFields.filter(x => x.category === 'object').forEach(element => {
        const elm = { key: element.name, selected: false, category: element.category, subgroup: element.subgroup }
        this.objectFields.push(elm)
      })
      this.defaultFields.filter(x => x.category === 'tenancy').forEach(element => {
        const elm = { key: element.name, selected: false, category: element.category, subgroup: element.subgroup }
        this.tenancyFields.push(elm)
      })
      this.defaultFields.filter(x => x.category === 'tenantOwner').forEach(element => {
        const elm = { key: element.name, selected: false, category: element.category, subgroup: element.subgroup }
        this.tenantOwnerFields.push(elm)
      })
      this.defaultFields.filter(x => x.category === 'marketing').forEach(element => {
        const elm = { key: element.name, selected: false, category: element.category, subgroup: element.subgroup }
        this.marketingFields.push(elm)
      })
      this.defaultFields.filter(x => x.category === 'linkedContact').forEach(element => {
        const elm = { key: element.name, selected: false, category: element.category, subgroup: element.subgroup }
        this.linkedContactFields.push(elm)
      })
    },
    setLanguage() {
      if (this.currentLanguage === 'de') {
        this.language = 'PREFERRED_LANGUAGE_GERMAN'
      } else if (this.currentLanguage === 'en') {
        this.language = 'PREFERRED_LANGUAGE_ENGLISH'
      } else if (this.currentLanguage === 'it') {
        this.language = 'PREFERRED_LANGUAGE_ITALIAN'
      }
    },
    handleCheckboxesTargetData(target, value) {
      if (target === 'targetDateCheckbox') {
        if (this.$refs.period_checkbox) {
          this.$refs.period_checkbox.checked = !value
        }
        if (this.$refs.target_date_checkbox) {
          this.$refs.target_date_checkbox.checked = value
        }
      } else {
        if (this.$refs.target_date_checkbox) {
          this.$refs.target_date_checkbox.checked = !value
        }
        if (this.$refs.period_checkbox) {
          this.$refs.period_checkbox.checked = value
        }
      }
    },
    selectedObject(object) {
      this.$emit('selected-object', object)
    },
    clearCheckbox() {
      this.selectedEntries = []
      this.selectedEntriesTransferred = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    handleCheckboxesInsideTable(value, index) {
      if (this.$refs['PropertiesViewTable' + index]) {
        const ref = this.$refs['PropertiesViewTable' + index]
        if (ref.length) {
          const allCheckboxesComponents = ref[0].$children[1].$children
          this.handleCheckbox(allCheckboxesComponents, value)
        }
      }
    },
    propertySelected(building, checked) {
      if (checked) {
        this.selectedEntries.push(building.id)
      } else {
        this.selectedEntries = this.selectedEntries.filter(
          entry => entry !== building.id
        )
      }
    },
    changedData() {
      const dateFrom = this.periodCheckbox ? this.periodDate.from : null
      const dateTo = this.periodCheckbox ? this.periodDate.to : this.targetDate
      const fieldsSelected = this.selectedFields.filter(x => x.selected)
      const fields = []
      for (let i = 0; i < fieldsSelected.length; i++) {
        fields.push({ name: fieldsSelected[i].key, order: i })
      }
      const language = this.language
      const tenancyType = this.typeOfTenancy
      const targetDateCheckbox = this.targetDateCheckbox
      const sortingType = this.sortingType
      const compositionType = this.compositionType
      const compositionLinesOrColumns = this.compositionLinesOrColumns
      this.$emit('changed-data', dateFrom, dateTo, fields, language, tenancyType, targetDateCheckbox, sortingType, compositionType, compositionLinesOrColumns)
      this.changeCheckboxes()
    }
  },
  validations() {
    const validationReport = {}
    if (this.periodCheckbox) {
      validationReport.periodDate = {}
      validationReport.periodDate.from = {
        required
      }
      validationReport.periodDate.to = {
        required,
        endDateMoreThanStartDate
      }
    }
    return validationReport
  }
}
</script>

<style lang="scss" scoped>
.borderFields {
  padding-left: 2rem;
  border: 0.55px solid rgba(0,0,0,0.1);
}
button.primary:focus {
  background-color: rgb(0, 68, 95);
  border-color: rgb(0, 68, 95);
  box-shadow: 0 0 0 0.2rem rgb(0 68 95 / 50%) !important;
}
.color_dark {
  color: #333;
}
:deep(#collapse-selected-fields .checkbox-label-text-md label.custom-control-label) {
  font-size: 1rem;
  font-weight: 700;
}
.text-lg {
  font-size: 1.1rem;
}
.padding-top-1rem .card-body {
  padding-top: 1rem;
}
:deep(#collapse-selected-fields .custom-checkbox label) {
  font-size: 0.875rem;
  font-weight: 400;
  color: #333;
}
.fa {
  color: $color-text-grey;
}
:deep(.date-picker-container) {
  .mx-input {
    display: block;
    width: 100%;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    font-weight: 400;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    height: 30px !important;
    box-shadow: 1px 1px 6px #ccc;
  }
  .mx-input:hover, .mx-input:focus {
    border-color: #ccc !important;
  }
  .mx-datepicker {
    width: 100%;
  }
  .mx-icon-calendar {
    display: none;
  }
}
:deep(.date-picker-container-gray-disable) {
  .mx-input {
    background-color: #e9ecef;
  }
}

:deep(.date-picker-container) {
  .mx-input {
    display: block;
    width: 100%;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    font-weight: 400;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    height: 30px !important;
    box-shadow: 1px 1px 6px #ccc;
  }
  .mx-input:hover, .mx-input:focus {
    border-color: #ccc !important;
  }
  .mx-datepicker {
    width: 100%;
  }
  .mx-icon-calendar {
    display: none;
  }
  .mx-input:disabled, .mx-input.disabled {
    color: #ccc;
    background-color: #f3f3f3 !important;
    border-color: #ccc !important;
    cursor: not-allowed;
  }
}
:deep(.is-invalid) {
  border-color: #dc3545 !important;
}
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
</style>
