<template>
  <section class="w-100">
    <div v-if="countCondominiums !== 0">
      <coozzy-detail-card
        :title="$t('message.contact.condoOwners') + ' (' + countCondominiums + ')'"
        :is-adjustable="isCompanyAdmin"
        @adjust-fields="openAssembleListModal">
        <div class="col p-0">
          <div
            v-if="loadingCondominiums"
            class="text-center">
            <coozzy-spinner />
          </div>
          <b-table
            id="condominiumTable"
            ref="condominiumTable"
            bordered
            hover
            responsive
            selectable
            select-mode="single"
            :fields="fields"
            :items="condominiumsList"
            @row-clicked="onRowClicked"
            @row-middle-clicked="onRowMiddleClicked">
            <template #head(condominiumOwner)>
              {{ $t('message.tableColumnHeadings.condominiumOwner') }}
            </template>
            <template #head(status)>
              {{ $t('message.tableColumnHeadings.status') }}
            </template>
            <template #head(contractNumber)>
              {{ $t('message.tableColumnHeadings.contractNumber') }}
            </template>
            <template #head(type)>
              {{ $t('message.tableColumnHeadings.type') }}
            </template>
            <template #head(floor)>
              {{ $t('message.tableColumnHeadings.floor') }}
            </template>
            <template #head(rooms)>
              {{ $t('message.tableColumnHeadings.rooms') }}
            </template>
            <template #head(livingArea)>
              {{ $t('message.tableColumnHeadings.livingArea') }}
            </template>
            <template #head(valueQuota)>
              {{ $t('message.tableColumnHeadings.valueQuota') }}
            </template>
            <template #head(ownershipStarting)>
              {{ $t('message.tableColumnHeadings.ownershipStarting') }}
            </template>
            <template #head(ownershipEnding)>
              {{ $t('message.tableColumnHeadings.ownershipEnding') }}
            </template>
            <template #head(assignee)>
              {{ $t('message.tableColumnHeadings.assignee') }}
            </template>
            <template #head(roommate)>
              {{ $t('message.tableColumnHeadings.roommate') }}
            </template>
            <template #head(address)>
              {{ $t('message.tableColumnHeadings.address') }}
            </template>
            <template #head(numericId)>
              {{ $t('message.tableColumnHeadings.numericId') }}
            </template>
            <template #head(objectNumber)>
              {{ $t('message.tableColumnHeadings.objectNumber') }}
            </template>
            <template #head(objects)>
              {{ $t('message.tableColumnHeadings.objects') }}
            </template>
            <template #head(subCategory)>
              {{ $t('message.tableColumnHeadings.subCategory') }}
            </template>

            <!-- Table content -->
            <template #cell(address)="data">
              <address-text
                v-if="getObject(data.item.objectId)"
                :address="getObject(data.item.objectId)?.address" />
            </template>
            <template #cell(numericId)="data">
              {{ getObject(data.item.objectId)?.numericId | displayOptionalValue }}
            </template>
            <template #cell(objectNumber)="data">
              <a
                class="link external-link"
                target="_blank"
                tabindex="0"
                @click="getRouteObject(data.item.objectId, getObject(data.item.objectId).buildingId)"
                @click.middle="getRouteObjectMiddle(data.item.objectId, getObject(data.item.objectId).buildingId)">
                {{ getObject(data.item.objectId)?.number | displayOptionalValue }}
              </a>
            </template>
            <template #cell(objects)="data">
              <a
                class="link external-link"
                target="_blank"
                tabindex="0"
                @click="getRouteObject(data.item.objectId, getObject(data.item.objectId).buildingId)"
                @click.middle="getRouteObjectMiddle(data.item.objectId, getObject(data.item.objectId).buildingId)">
                {{ getObject(data.item.objectId)?.name | displayOptionalValue }}
              </a>
            </template>
            <template
              #cell(condominiumOwner)="data">
              <div v-if="data.item.vacancy === true">
                {{ $t('message.onBoarding.buildings.objects.tenancies.vacancy') }}
              </div>
              <div v-else-if="data.item.owner && data.item.owner.contactIds.length > 0">
                <template
                  v-for="(contactId, tenantIndex) in data.item.owner.contactIds">
                  <span
                    v-if="tenantIndex !== 0"
                    :key="tenantIndex">, </span>
                  <router-link
                    v-if="contactId !== contact.id"
                    :key="tenantIndex"
                    :to="redirectToDetailPage(contactId)"
                    class="external-link">
                    {{ getContactName(contactId) }}
                  </router-link>
                  <span
                    v-else
                    :key="tenantIndex"
                    class="">
                    {{ getContactName(contactId) }}
                  </span>
                </template>
              </div>
              <span v-else>-</span>
            </template>
            <template
              #cell(status)="data">
              <template v-if="data.item.closed === true">
                <div
                  class="color-box mr-2 mt-2 closed" />
                {{ $t('message.onBoarding.buildings.objects.tenancies.status.closed') }}
              </template>
              <template v-else-if="dateObjectToTimestamp(data.item.startingAt) > (Date.now() / 1000)">
                <div
                  class="color-box mr-2 mt-2 future" />
                {{ $t('message.onBoarding.buildings.objects.tenancies.status.future') }}
              </template>
              <template v-else-if="dateObjectToTimestamp(data.item.endingAt) > 0 && dateObjectToTimestamp(data.item.endingAt) > (Date.now() / 1000) && dateObjectToTimestamp(data.item.startingAt) <= (Date.now() / 1000)">
                <div
                  class="color-box mr-2 mt-2 terminated" />
                {{ $t('message.onBoarding.buildings.objects.tenancies.status.current_terminated') }}
              </template>
              <template v-else-if="dateObjectToTimestamp(data.item.endingAt) > 0 && data.item.status !== 'CONDOMINIUM_STATUS_NOT_ACTIVATED'">
                <div
                  class="color-box mr-2 mt-2 terminated" />
                {{ $t('message.onBoarding.buildings.objects.tenancies.status.terminated') }}
              </template>
              <template v-else-if="(!dateObjectToTimestamp(data.item.endingAt) || dateObjectToTimestamp(data.item.endingAt) <= 0) && data.item.activated === true">
                <div
                  class="color-box mr-2 mt-2 current" />
                {{ $t('message.onBoarding.buildings.objects.tenancies.status.current') }}
              </template>
              <template v-else-if="data.item.activated === false">
                <div
                  class="color-box mr-2 mt-2 not_activated" />
                {{ $t('message.onBoarding.buildings.objects.tenancies.status.not_activated') }}
              </template>
              <template v-else>
                <div
                  class="color-box mr-2 mt-2 future" />
                {{ $t('message.onBoarding.buildings.objects.tenancies.status.future') }}
              </template>
            </template>
            <template #cell(contractNumber)="data">
              <div
                class="" />
              {{ data.item.numericId }}
            </template>
            <template
              #cell(type)="data">
              <template v-if="getObject(data.item.objectId)?.category !== 'UNDEFINED_CATEGORY'">
                {{ $t('message.onBoarding.buildings.objects.category.' + getObject(data.item.objectId)?.category.toLowerCase()) }}
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template
              #cell(floor)="data">
              {{ formatFloor(getObject(data.item.objectId) ? getObject(data.item.objectId).characteristics.floor : '') }}
            </template>
            <template
              #cell(position)="data">
              {{ data.item.object ? getPositionLabelFromArray(getObject(data.item.objectId).object.positions) : '' }}
            </template>
            <template
              #cell(rooms)="data">
              {{ (getObject(data.item.objectId) ? getObject(data.item.objectId).characteristics.rooms : '') | displayOptionalValue }}
            </template>
            <template
              #cell(livingArea)="data">
              {{ getObject(data.item.objectId)?.characteristics.areas ? getObject(data.item.objectId).characteristics.areas.livingArea : '' | formatArea }}
            </template>
            <template
              #cell(valueQuota)="data">
              {{ getObject(data.item.objectId)?.valueQuota | displayOptionalValue }}
            </template>
            <template
              #cell(ownershipStarting)="data">
              {{ data.item.startingAt | objectToDate }}
            </template>
            <template
              #cell(ownershipEnding)="data">
              {{ data.item.endingAt | objectToDate }}
            </template>
            <template #cell(assignee)="data">
              <template v-if="isAdminModule">
                {{ getObject(data.item.objectId) && getObject(data.item.objectId).contacts.contactIdAssigneeAdministration !== '' ? getContactName(getObject(data.item.objectId).contacts.contactIdAssigneeAdministration) : '-' }}
              </template>
              <template v-if="isAccountingModule">
                {{ getObject(data.item.objectId) && getObject(data.item.objectId).contacts.contactIdAssigneeAccounting !== '' ? getContactName(getObject(data.item.objectId).contacts.contactIdAssigneeAccounting) : '-' }}
              </template>
              <template v-if="isAssetModule">
                {{ getObject(data.item.objectId) && getObject(data.item.objectId).contacts.contactIdAssigneeAsset !== '' ? getContactName(getObject(data.item.objectId).contacts.contactIdAssigneeAsset) : '-' }}
              </template>
              <template v-if="isOwnerModule">
                {{ getObject(data.item.objectId) && getObject(data.item.objectId).contacts.contactIdAssigneeOwner !== '' ? getContactName(getObject(data.item.objectId).contacts.contactIdAssigneeOwner) : '-' }}
              </template>
            </template>
            <template #cell(roommate)="data">
              <div v-if="data.item?.owner?.roommateContactIds?.length > 0">
                <router-link
                  v-for="(roommateContactId, roommateIndex) in data.item.owner.roommateContactIds"
                  :key="roommateContactId + roommateIndex"
                  class="external-link"
                  :to="redirectToDetailPage(roommateContactId)">
                  {{ roommateIndex === 0 ? getContactName(roommateContactId).substr(2) : getContactName(roommateContactId) }}
                </router-link>
              </div>
              <span v-else>-</span>
            </template>
            <template #cell(subCategory)="data">
              <template v-if="getObject(data.item.objectId)?.subCategories.length > 0">
                <template v-for="(subCat, subCatIndex) in getObject(data.item.objectId)?.subCategories">
                  <span
                    v-if="subCat !== 'UNDEFINED_SUB_CATEGORY'"
                    :key="subCatIndex">
                    {{ $t(`message.onBoarding.buildings.objects.subCategory.` + subCat?.toLowerCase()) }}
                  </span>
                  <template v-else>
                    -
                  </template>
                </template>
              </template>
              <template v-else>
                -
              </template>
            </template>
          </b-table>
          <div class="text-center">
            <a
              v-if="countCondominiums > 5"
              class="link external-link"
              @click="showAllCondominiums = !showAllCondominiums">
              <coozzy-angle-double-up-icon v-if="showAllCondominiums === true" />
              <coozzy-angle-double-down-icon v-else />
            </a>
          </div>
        </div>
      </coozzy-detail-card>
    </div>
    <assemble-list-modal
      ref="assembleListModal"
      modal-id="assembleListModal"
      :fields="selectedFields"
      :default-fields="dataFields"
      @save-fields="saveFields" />
  </section>
</template>

<script>
import { tables } from '@/mixins/tables'
import { routeChecks } from '@/mixins/routeChecks'
import ObjectApi from '@/misc/apis/ObjectApi'
import ContactApi from '@/misc/apis/ContactApi'
import SearchApi from '@/misc/apis/SearchApi'
import Vue from 'vue'
import AssembleListModal from '@/contact/components/AssembleListModal'
import { user } from '@/mixins/user'
import { formatting } from '@/mixins/formatting'
import AddressText from '@/framework/components/misc/AddressText'
import CoozzyDetailCard from '@/framework/components/card/CoozzyDetailCard.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import CoozzyAngleDoubleUpIcon from '@/framework/components/icons/CoozzyAngleDoubleUpIcon.vue'
import CoozzyAngleDoubleDownIcon from '@/framework/components/icons/CoozzyAngleDoubleDownIcon.vue'

export default {
  name: 'CondominiumContactCard',
  components: {
    CoozzyAngleDoubleDownIcon,
    CoozzyAngleDoubleUpIcon,
    CoozzySpinner,
    CoozzyDetailCard,
    AssembleListModal,
    AddressText
  },
  mixins: [tables, routeChecks, user, formatting],
  props: {
    availableContact: {
      type: Array,
      default() {
        return []
      }
    },
    contact: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      dataFields: [
        {
          key: 'condominiumOwner',
          label: this.$t('message.tableColumnHeadings.condominiumOwner'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'status',
          label: this.$t('message.tableColumnHeadings.status'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'contractNumber',
          label: this.$t('message.tableColumnHeadings.contractNumber'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'type',
          label: this.$t('message.tableColumnHeadings.type'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'ownershipStarting',
          label: this.$t('message.tableColumnHeadings.ownershipStarting'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'ownershipEnding',
          label: this.$t('message.tableColumnHeadings.ownershipEnding'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'objects',
          label: this.$t('message.tableColumnHeadings.objects'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'objectNumber',
          label: this.$t('message.tableColumnHeadings.objectNumber'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'numericId',
          label: this.$t('message.tableColumnHeadings.numericId'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'address',
          label: this.$t('message.tableColumnHeadings.address'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'floor',
          label: this.$t('message.tableColumnHeadings.floor'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'rooms',
          label: this.$t('message.tableColumnHeadings.rooms'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'livingArea',
          label: this.$t('message.tableColumnHeadings.livingArea'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'valueQuota',
          label: this.$t('tableColumnHeadings.valueQuota'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'assignee',
          label: this.$t('message.tableColumnHeadings.assignee'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'roommate',
          label: this.$t('message.tableColumnHeadings.roommate'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'subCategory',
          label: this.$t('message.tableColumnHeadings.subCategory'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        }
      ],
      fields: [],
      selectedFields: [],
      filterCondominiums: [],
      objects: [],
      shares: [],
      listContacts: [],
      networkPartners: [],
      showAllCondominiums: false,
      ownerAccounts: [],
      loadingCondominiums: false
    }
  },
  computed: {
    countCondominiums() {
      return this.filterCondominiums.length
    },
    condominiumsList() {
      const t = this
      const array = this.filterCondominiums
      array.sort((a, b) => new Date(t.$options.filters.objectToDateInput(b.startingAt)).getTime() > new Date(t.$options.filters.objectToDateInput(a.startingAt)).getTime() ? -1 : 1)

      array.sort(function(x, y) {
        return (x.closed === y.closed) ? 0 : !x.closed ? -1 : 0
      })
      array.sort(function(x, y) {
        return (x.activated && !x.closed && new Date(t.$options.filters.objectToDateInput(x.endingAt)).getTime() > new Date(t.$options.filters.objectToDateInput(y.endingAt)).getTime()) ? -1 : 0
      })
      array.sort(function(x) {
        return (x.activated && x.endingAt) ? 0 : -1
      })
      array.sort(function(x, y) {
        return (x.activated === y.activated) ? 0 : !x.activated ? -1 : 0
      })
      if (this.showAllCondominiums) {
        return array
      } else {
        return array.slice(0, 5)
      }
    }
  },
  mounted() {
    this.loadData()
    if (this.accountId) {
      SearchApi.loadSettings(this.accountId).then(response => {
        const settings = response.tableSettings
        let hasOldList = false
        if (settings.length > 0) {
          settings.forEach(setting => {
            if (setting.columns.length > 0 && setting.table === 'condominium_contact_card') {
              hasOldList = true
              this.loadFields(setting)
            }
          })
        }
        if (hasOldList === false) {
          let elm = null
          this.fields.push(...this.dataFields.filter(element => element.key === 'condominiumOwner' || element.key === 'status' || element.key === 'contractNumber' || element.key === 'type' || element.key === 'ownershipStarting' || element.key === 'ownershipEnding'))
          this.dataFields.forEach(element => {
            if (element.key === 'condominiumOwner' || element.key === 'status' || element.key === 'contractNumber' || element.key === 'type' || element.key === 'ownershipStarting' || element.key === 'ownershipEnding') {
              elm = {
                key: element.key,
                label: element.label,
                selected: true
              }
            } else {
              elm = {
                key: element.key,
                label: element.label,
                selected: false
              }
            }
            this.selectedFields.push(elm)
          })
          if (this.fields.length > 0) {
            this.fields[0].stickyColumn = true
            this.fields[0].thClass += ' sticky-header'
          }
        }
      }).catch(e => {
        console.log(e)
      })
    }
  },
  methods: {
    loadData() {
      if (this.contact?.id) {
        this.loadingCondominiums = true
        const promises = []
        promises.push(ObjectApi.getCondominiumByOwnerContact(this.contact?.id))
        promises.push(ObjectApi.getCondominiumByBillingContactId(this.contact?.id))
        if (this.availableContact.length > 0) {
          this.availableContact.forEach(element => {
            promises.push(ObjectApi.getCondominiumByOwnerContact(element.id))
            promises.push(ObjectApi.getCondominiumByBillingContactId(element.id))
          })
        }
        Promise.all(promises)
          .then(async response => {
            let list = []
            response.forEach(element => {
              list = list.concat(element.condominiums)
            })
            this.filterCondominiums = this.removeDuplicatedItems(list)
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.condominium'), { type: 'error' })
          })
          .finally(() => {
            const objectIds = []
            for (let index = 0; index < this.filterCondominiums?.length; index++) {
              objectIds.push(this.filterCondominiums[index].objectId)
            }
            ObjectApi.listObjectsByIds(objectIds)
              .then(response => {
                let ids = []
                this.objects = response.objects
                this.filterCondominiums.forEach(element => {
                  ids = ids.concat(element.owner.contactIds)
                  if (element && element.tenants.length > 0) {
                    element.tenants.forEach(contact => {
                      ids = ids.concat(contact.contactIds)
                    })
                  }
                })
                this.objects.forEach(element => {
                  if (element.contacts) {
                    ids.push(element.contacts.contactIdAssigneeOwner)
                    ids.push(element.contacts.contactIdAssigneeAsset)
                    ids.push(element.contacts.contactIdAssigneeAccounting)
                    ids.push(element.contacts.contactIdAssigneeAdministration)
                  }
                })
                ContactApi.contactResolve(ids).then(response => {
                  this.listContacts = response.companies.concat(response.persons)
                }).finally(() => {
                  this.$emit('list-condominium', this.filterCondominiums, this.listContacts)
                })
                this.loadingCondominiums = false
              })
          })
      }
    },
    removeDuplicatedItems(items) {
      const uniqueIds = []
      return items.filter(element => {
        const isDuplicate = uniqueIds.includes(element.id)
        if (!isDuplicate) {
          uniqueIds.push(element.id)
          return true
        }
        return false
      })
    },
    onRowClicked(condominium) {
      const obj = this.objects.find(x => x.id === condominium.objectId)
      const buildingId = obj.buildingId
      const condominiumId = condominium.id

      let routeName = null

      if (this.isOwnerModule) {
        routeName = 'OwnerBuildingDetailsView'
      } else if (this.isAdminModule) {
        routeName = 'AdminBuildingDetailsView'
      } else if (this.isAccountingModule) {
        routeName = 'AccountingBuildingDetailsView'
      } else if (this.isAssetModule) {
        routeName = 'AssetBuildingDetailsView'
      } else if (this.isMarketingModule) {
        if (this.hasAccessAdminBase) {
          routeName = 'AdminBuildingDetailsView'
        } else if (this.hasAccessAccountingBase) {
          routeName = 'AccountingBuildingDetailsView'
        }
      }

      if (routeName) {
        this.$router.push({
          name: routeName,
          params: { id: buildingId },
          query: {
            view: 'condominium',
            elementSelected: condominiumId
          }
        })
      }
    },
    onRowMiddleClicked(condominium, index, event) {
      if (event.target.classList.contains('external-link')) {
        return
      }

      const obj = this.objects.find(x => x.id === condominium.objectId)
      const buildingId = obj.buildingId
      const condominiumId = condominium.id
      this.getRouteObjectMiddle(condominiumId, buildingId)
    },
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting)
      this.fields = result?.fields.filter(x => x.key !== 'checkbox')
      this.selectedFields = result?.selectedFields
      this.fields.map(function(x) {
        x.stickyColumn = false
        return x
      })
      if (this.fields.length > 0) {
        this.fields[0].stickyColumn = true
        this.fields[0].thClass += ' sticky-header'
      }
      this.$refs.objectsTable?.refresh()
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      SearchApi.saveSettings(this.accountId, columns, 'condominium_contact_card').then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal')
      })
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    getObject(id) {
      if (this.objects.length > 0) {
        return this.objects.find(obj => obj.id === id)
      }
      return null
    },
    getRouteObject(objectId, buildingId) {
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'objects',
            elementSelected: objectId
          }
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'objects',
            elementSelected: objectId
          }
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'objects',
            elementSelected: objectId
          }
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'objects',
            elementSelected: objectId
          }
        })
      } else if (this.isMarketingModule) {
        if (this.hasAccessAdminBase) {
          this.$router.push({
            name: 'AdminBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: 'objects',
              elementSelected: objectId
            }
          })
        } else if (this.hasAccessAccountingBase) {
          this.$router.push({
            name: 'AccountingBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: 'objects',
              elementSelected: objectId
            }
          })
        }
      }
    },
    getRouteObjectMiddle(objectId, buildingId) {
      let route = ''
      if (this.isOwnerModule) {
        route = this.$router.resolve({
          name: 'OwnerBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'objects',
            elementSelected: objectId
          }
        })
      } else if (this.isAdminModule) {
        route = this.$router.resolve({
          name: 'AdminBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'objects',
            elementSelected: objectId
          }
        })
      } else if (this.isAccountingModule) {
        route = this.$router.resolve({
          name: 'AccountingBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'objects',
            elementSelected: objectId
          }
        })
      } else if (this.isAssetModule) {
        route = this.$router.resolve({
          name: 'AssetBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'objects',
            elementSelected: objectId
          }
        })
      } else if (this.isMarketingModule) {
        if (this.hasAccessAdminBase) {
          route = this.$router.resolve({
            name: 'AdminBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: 'objects',
              elementSelected: objectId
            }
          })
        } else if (this.hasAccessAccountingBase) {
          route = this.$router.resolve({
            name: 'AccountingBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: 'objects',
              elementSelected: objectId
            }
          })
        }
      }
      window.open(route.href)
    },
    getContactName(contactId) {
      const contact = this.listContacts.find(function (contact) {
        return contact.id === contactId || contact.objectID === contactId
      })

      return contact ? contact.name : '-'
    },
    redirectToDetailPage(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          return { name: 'OwnerContactDetailsView', params: { id: contactId } }
        } else if (this.isAssetModule) {
          return { name: 'AssetContactDetailsView', params: { id: contactId } }
        } else if (this.isAccountingModule) {
          return { name: 'AccountingContactDetailsView', params: { id: contactId } }
        } else {
          return { name: 'AdminContactDetailsView', params: { id: contactId } }
        }
      }
    },
    getAdjustedRentValues(tenancy) {
      let currentRent = tenancy.rent
      const current = this
      const pastAdjustements = tenancy.rent.adjustments.filter(obj => parseInt(obj.byDate) !== -1 && new Date(this.$options.filters.objectToDateInput(obj.byDate)) < Date.now())
      if (pastAdjustements.length > 0) {
        pastAdjustements.sort(function (a, b) {
          return new Date(current.$options.filters.objectToDateInput(b.byDate)) - new Date(current.$options.filters.objectToDateInput(a.byDate))
        })
        currentRent = pastAdjustements[0]
      }
      return currentRent
    },
    getTotalRent(tenancy) {
      const adjustedRentValues = this.getAdjustedRentValues(tenancy)
      const grossRent = (adjustedRentValues.heatingCosts > 0 ? adjustedRentValues.heatingCosts : 0) +
        (adjustedRentValues.additionalCosts > 0 ? adjustedRentValues.additionalCosts : 0) +
        (adjustedRentValues.coldRent > 0 ? adjustedRentValues.coldRent : 0)
      const dueDates = adjustedRentValues.dueDates.length > 0 ? adjustedRentValues.dueDates.length : 1
      return (grossRent * dueDates) / 12
    },
    dateObjectToTimestamp(item) {
      if (item) {
        const date = new Date()
        date.setFullYear(item.year)
        date.setMonth(item.month - 1)
        date.setDate(item.day)
        return date.getTime() / 1000
      }
      return 0
    }
  }
}
</script>

<style lang="scss" scoped>
.text-grey {
  color: $color-text-grey-light;
}
:deep(#condominiumTable) {
  overflow: unset !important;
}
:deep(.sticky-header) {
  background-color: #f8f8f8 !important;
}
:deep(.b-table-sticky-column) {
  background-image: none !important;
}
</style>
