<template>
  <section>
    <b-modal
      id="send-brief-butler-modal"
      ref="send-brief-butler-modal"
      no-close-on-backdrop>
      <div slot="modal-title">
        {{ $t('message.briefButler.modal.title') }}
      </div>
      <div
        v-if="loading"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <div
        v-else
        class="row">
        <div class="col-sm-12 col-md-12 mb-2">
          {{ $t('message.briefButler.modal.howSendDoc') }}
        </div>
        <div class="col-sm-12 col-md-12 mb-2">
          <template
            v-for="(item, itemIndex) in profiles">
            <div
              :key="`item_${itemIndex}`"
              class="col-6 pl-0 pr-0">
              <coozzy-form-radio
                v-model="selectedProfile"
                :default-model="selectedProfile"
                :name="item.briefbutlerId"
                :select-value="item.briefbutlerId"
                :state="$v.selectedProfile.$dirty && $v.selectedProfile.$error ? false : null"
                class="mt-2">
                {{ item.description }}
              </coozzy-form-radio>
            </div>
          </template>
        </div>
        <div class="col-sm-12 col-md-12 mb-2">
          <label for="file">{{
            $t('message.manageDocuments.document') + ' ' + $t('message.briefButler.onlyPdf') + ' *'
          }}</label>
          <coozzy-form-file-input
            id="file"
            ref="file-input"
            :disabled="uploading"
            :state="$v.file.$dirty && $v.file.$error ? false : null"
            accept="text/plain, text/csv, application/pdf, image/*, video/*, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
            @change="addFile" />
        </div>
        <div
          v-if="filteredMediaList.length > 0"
          class="col-12">
          <div
            class="row mb-2">
            <label
              class="col-12 subtitle"> {{ $t('message.ticketOverview.attachement.document') }}
              ({{ filteredMediaList.length }})</label>
            <template
              v-for="media in filteredMediaList">
              <div
                v-if="!(['image/jpeg', 'image/png', 'image/gif', 'video/webm', 'video/mp4'].indexOf(media.mimeType) !== -1)"
                :key="media.id"
                class="col-12 square">
                <div
                  class="file-block">
                  <a
                    class="link">
                    {{ media.name }}
                  </a>
                  <coozzy-button
                    class="delete-icon"
                    design="transparent"
                    @click="deleteMedia()">
                    <coozzy-delete-icon />
                  </coozzy-button>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div
        slot="modal-footer"
        class="w-100 float-right">
        <coozzy-button
          :disabled="loading || file === null"
          class="float-right ml-3"
          design="green"
          @click="sendDocument">
          {{ $t('message.generic.form.send') }}
        </coozzy-button>
        <coozzy-button
          :disabled="loading"
          design="default"
          @click="$bvModal.hide('send-brief-butler-modal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
      </div>
    </b-modal>
  </section>
</template>
<script>
import { required } from 'vuelidate/lib/validators'
import MediaApi from '../../misc/apis/MediaApi'
import Vue from 'vue'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import CoozzyFormFileInput from '@/framework/components/form/fileInput/CoozzyFormFileInput'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import { media } from '@/mixins/media'
import BriefButlerApi from '@/misc/apis/BriefButlerApi'
import CoozzyFormRadio from '@/framework/components/form/radio/CoozzyFormRadio.vue'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon.vue'

export default {
  name: 'SendBriefButlerModal',
  components: {
    CoozzyDeleteIcon,
    CoozzyFormRadio,
    CoozzyButton,
    CoozzyFormFileInput,
    CoozzySpinner
  },
  mixins: [media],
  props: {
    activityDetail: {
      type: Object,
      default() {
        return null
      }
    }
  },
  data() {
    return {
      profiles: [],
      loading: false,
      mediaUpload: null,
      file: null,
      uploading: false,
      selectedProfile: null,
      filteredMediaList: [],
      deleting: false
    }
  },
  mounted: function () {
    this.loading = true
    BriefButlerApi.loadSettings(this.$store.getters['user/getAccountId'])
      .then(response => {
        this.profiles = response.profiles
      })
      .catch(e => {
        console.error(e)
        Vue.toasted.show('Die Buchhaltungsstatistiken konnten nicht geladen werden', { type: 'error' })
      })
      .finally(() => {
        this.loading = false
      })
  },
  methods: {
    async addFile(event) {
      const files = await this.getFilesFromEvent(event)
      if (files.length > 0) {
        this.file = files[0]
        if (this.file.type === 'application/pdf') {
          this.$store.dispatch('user/setChangedModal', true).then().catch(e => console.log(e))
          if (this.file.name) {
            this.updateUploadedFileName(this, this.file.name)
            this.filteredMediaList[0] = this.file
          }
        } else {
          this.file = null
          Vue.toasted.show(this.$tc('message.briefButler.error.fileType'), { type: 'error' })
        }
      }
      this.$nextTick(() => {
        if (this.$refs['file-input']) {
          this.$refs['file-input'].resetPlaceholder()
        }
      })
    },
    hide() {
      this.$refs['send-brief-butler-modal'].hide()
    },
    show() {
      this.$refs['send-brief-butler-modal'].show()
    },
    async sendDocument() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        if (this.file.type === 'application/pdf') {
          this.uploading = true
          this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
          const selectedFile = this.file ? [this.file] : []
          const response = await this.uploadMedia(selectedFile, this.ownerId || '')
          if (response.length > 0) {
            const media = response[0]
            this.reset()
            this.hide()
            BriefButlerApi.sendRawDocument(this.selectedProfile, media.id)
              .then(() => {
                Vue.toasted.show(this.$t('message.successMessages.sendRawDocument'), { type: 'success' })
              })
              .catch(e => {
                console.error(e)
                Vue.toasted.show('Die Buchhaltungsstatistiken konnten nicht geladen werden', { type: 'error' })
              })
              .finally(() => {
                this.loading = false
              })
          } else {
            this.uploading = false
            this.file = null
            if (this.$refs['file-input']) {
              this.$refs['file-input'].resetPlaceholder()
            }
          }
        } else {
          this.uploading = false
          Vue.toasted.show(this.$t('message.briefButler.error.fileType'), { type: 'error' })
        }
      }
    },
    handleError(e) {
      this.reset()
      console.log(e)
      Vue.toasted.show(this.$t('message.savingErrors.file'), { type: 'error' })
    },
    reset() {
      this.$v.$reset()
      this.uploading = false
      this.name = ''
      this.file = null
    },
    deleteMedia() {
      if (this.filteredMediaList.length > 0) {
        this.deleting = true
        this.file = null
        this.$refs['file-input'].resetPlaceholder()
        this.filteredMediaList = []
      }
    }
  },
  validations: {
    selectedProfile: {
      required
    },
    file: {
      required
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
