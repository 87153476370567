<template>
  <b-modal
    :id="'modalAddConnectionsForOrderCreation'"
    ref="modalAddConnectionsForOrderCreation"
    no-close-on-backdrop
    hide-footer
    :title="$t('message.createOrder.addConnection')"
    @close="hide">
    <div
      class="col p-0">
      <div
        id="list-connections"
        class="row mb-2 connection">
        <label
          class="col-12"
          for="connections"> {{ $t('message.ticketOverview.ticket.connections') }}</label>
        <div
          v-for="(ref, index) in references"
          :key="index"
          class="col-12 d-flex align-items-center">
          <coozzy-button
            class="border mb-2"
            design="transparent"
            size="small"
            @click="removeReference(index)">
            <coozzy-delete-icon />
          </coozzy-button>
          <div class="containerCircles">
            <div
              v-if="ref.tenancy"
              v-b-tooltip="{container: '#list-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.tenancy')}"
              class="color-box mt-0 ml-2 mr-2 tenancy" />
            <div
              v-if="ref.condominium"
              v-b-tooltip="{container: '#list-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.condo_owner')}"
              class="color-box mt-0 ml-2 mr-2 condo_owner" />
            <div
              v-if="ref.object && ref.object.condominiumOwnership === true && !ref.tenancy && !ref.condominium && !ref.device && !ref.request"
              v-b-tooltip="{container: '#list-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.condominium')}"
              class="color-box mt-0 ml-2 mr-2 condominium" />
            <div
              v-if="ref.object && ref.object.condominiumOwnership === false && !ref.tenancy && !ref.condominium && !ref.device && !ref.request"
              v-b-tooltip="{container: '#list-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.object')}"
              class="color-box mt-0 ml-2 mr-2 object" />
            <div
              v-if="ref.device"
              v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.device')}"
              class="color-box mt-0 ml-2 mr-2 device" />
            <div
              v-if="ref.mortgage"
              v-b-tooltip="{container: '#collapse-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.mortgage')}"
              class="color-box mt-0 ml-2 mr-2 mortgage" />
            <div
              v-if="ref.insurance"
              v-b-tooltip="{container: '#collapse-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.insurance')}"
              class="color-box mt-0 ml-2 mr-2 insurance" />
            <div
              v-if="ref.building && !ref.object && !ref.device && !ref.mortgage && !ref.insurance"
              v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.building')}"
              class="color-box mt-0 ml-2 mr-2 not_activated" />
            <div
              v-if="ref.property && !ref.building && !ref.device && !ref.mortgage && !ref.insurance"
              v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.property')}"
              class="color-box mt-0 ml-2 mr-2 current" />
            <div
              v-if="ref.contact"
              v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.contact')}"
              class="color-box mt-0 ml-2 mr-2 terminated" />
            <div
              v-if="ref.request"
              v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.request')}"
              class="color-box mt-0 ml-2 mr-2 request" />
          </div>
          <span v-if="ref.property">
            <router-link
              v-if="!isMarketingModule"
              :to="redirectToPropertyDetail(ref.property.objectID || ref.property.id)">
              {{ ref.property.name }}
            </router-link>
            <span v-else>{{ ref.property.name }}</span>
          </span>
          <span v-if="ref.building">
            <span v-if="ref.property"> > </span>
            <router-link
              v-if="!isMarketingModule"
              :to="redirectToBuildingDetail(ref.building.objectID || ref.building.id)">
              {{ ref.building.name }}
            </router-link>
            <span v-else>{{ ref.building.name }}</span>
          </span>
          <span v-if="ref.object">
            <span v-if="ref.building"> > </span>
            <router-link
              v-if="!isMarketingModule"
              :to="redirectToDetail(ref.object.buildingId, ref.object.objectID || ref.object.id, 'objects')">
              {{
                ref.object.name !== '' ? ref.object.name : ref.object.number !== '' ? ref.object.number : ref.object.numericId
              }}
            </router-link>
            <span v-else> {{
              ref.object.name !== '' ? ref.object.name : ref.object.number !== '' ? ref.object.number : ref.object.numericId
            }}</span>
          </span>

          <span v-if="ref.mortgage">
            <template v-if="ref.building">
              <span> > </span>
              <span
                v-if="!isMarketingModule"
                @click="setMortgageId('building', ref.mortgage.id)"
                @click.middle="setMortgageId('building', ref.mortgage.id)">
                <router-link
                  :to="redirectToBuildingDetail(ref.building.id)">
                  {{ ref.mortgage.bankName + ' - ' + $t('message.onBoarding.mortgage.mortgageDesignation.' + ref.mortgage.mortgageDesignation) }}
                </router-link>
              </span>
              <span v-else>{{ ref.mortgage.bankName + ' - ' + $t('message.onBoarding.mortgage.mortgageDesignation.' + ref.mortgage.mortgageDesignation) }}</span>
            </template>
            <template v-if="ref.property && !ref.building">
              <span> > </span>
              <span
                v-if="!isMarketingModule"
                @click="setMortgageId('property', ref.mortgage.id)"
                @click.middle="setMortgageId('property', ref.mortgage.id)">
                <router-link
                  v-if="!isMarketingModule"
                  :to="redirectToPropertyDetail(ref.property.id)">
                  {{ ref.mortgage.bankName + ' - ' + $t('message.onBoarding.mortgage.mortgageDesignation.' + ref.mortgage.mortgageDesignation) }}
                </router-link>
              </span>
              <span v-else>{{ ref.mortgage.bankName + ' - ' + $t('message.onBoarding.mortgage.mortgageDesignation.' + ref.mortgage.mortgageDesignation) }}</span>
            </template>
          </span>
          <span v-if="ref.insurance">
            <template v-if="ref.building">
              <span> > </span>
              <span
                v-if="!isMarketingModule"
                @click="setInsuranceId('building', ref.insurance.id)"
                @click.middle="setInsuranceId('building', ref.insurance.id)">
                <router-link
                  v-if="!isMarketingModule"
                  :to="redirectToBuildingDetail(ref.building.id)">
                  {{ $t('message.onBoarding.buildings.insurances.type.' + ref.insurance.type) }}
                </router-link>
              </span>
              <span v-else>{{ $t('message.onBoarding.buildings.insurances.type.' + ref.insurance.type) }}</span>
            </template>
            <template v-if="ref.property && !ref.building">
              <span> > </span>
              <span
                v-if="!isMarketingModule"
                @click="setInsuranceId('property', ref.insurance.id)"
                @click.middle="setInsuranceId('property', ref.insurance.id)">
                <router-link
                  v-if="!isMarketingModule"
                  :to="redirectToPropertyDetail(ref.property.id)">
                  {{ $t('message.onBoarding.buildings.insurances.type.' + ref.insurance.type) }}
                </router-link>
              </span>
              <span v-else>{{ $t('message.onBoarding.buildings.insurances.type.' + ref.insurance.type) }}</span>
            </template>
          </span>
          <span v-if="ref.device">
            <span v-if="ref.object || ref.building || ref.property"> > </span>
            <router-link
              v-if="redirectToDeviceDetail(ref.device.referenceId, ref.device.objectID || ref.device.id) && !isMarketingModule"
              :to="redirectToDeviceDetail(ref.device.referenceId, ref.device.objectID || ref.device.id)">
              <template v-if="ref.device.type && ref.device.type !== 'DEVICE_TYPE_UNDEFINED'">
                {{
                  $t('message.onBoarding.devices.typeValues.' + ref.device.type).length > 50 ? $t('message.onBoarding.devices.typeValues.' + ref.device.type).slice(0, 50) + '...' : $t('message.onBoarding.devices.typeValues.' + ref.device.type)
                }}
              </template>
              <template v-else>
                -
              </template>
            </router-link>
            <span v-else>
              <template v-if="ref.device.type && ref.device.type !== 'DEVICE_TYPE_UNDEFINED'">
                {{
                  $t('message.onBoarding.devices.typeValues.' + ref.device.type).length > 50 ? $t('message.onBoarding.devices.typeValues.' + ref.device.type).slice(0, 50) + '...' : $t('message.onBoarding.devices.typeValues.' + ref.device.type)
                }}
              </template>
              <template v-else>
                -
              </template>
            </span>
          </span>
          <span v-if="ref.object && ref.tenancy">
            <span v-if="ref.object"> > </span>
            <router-link
              v-if="!isMarketingModule"
              :to="redirectToDetail(ref.object.buildingId, ref.tenancy.objectID || ref.tenancy.id, 'tenants')">
              <template v-if="ref.tenancy.tenants">
                <span
                  v-for="(contact, j) in ref.tenancy.tenants"
                  :key="j">
                  <span v-if="j > 0">- </span>{{ contact.name }}
                </span>
              </template>
              <template v-else>
                <span
                  v-for="(contact, j) in ref.listContact"
                  :key="j">
                  <span v-if="j > 0">- </span>{{ contact.name }}
                </span>
              </template>
            </router-link>
            <span v-else>
              <template v-if="ref.tenancy.tenants">
                <span
                  v-for="(contact, j) in ref.tenancy.tenants"
                  :key="j">
                  <span v-if="j > 0">- </span>{{ contact.name }}
                </span>
              </template>
              <template v-else>
                <span
                  v-for="(contact, j) in ref.listContact"
                  :key="j">
                  <span v-if="j > 0">- </span>{{ contact.name }}
                </span>
              </template>
            </span>
          </span>
          <span v-if="ref.object && ref.condominium">
            <span v-if="ref.object"> > </span>
            <router-link
              v-if="!isMarketingModule"
              :to="redirectToDetail(ref.object.buildingId, ref.condominium.objectID || ref.condominium.id, 'condominium')">
              <template v-if="ref.condominium.condominiumOwners">
                <span
                  v-for="(contact, j) in ref.condominium.condominiumOwners"
                  :key="j">
                  {{ contact.name }}
                </span>
              </template>
              <template v-else>
                <span
                  v-for="(contact, j) in ref.listContact"
                  :key="j">
                  {{ contact.name }}
                </span>
              </template>
            </router-link>
            <span v-else>
              <template v-if="ref.condominium.condominiumOwners">
                <span
                  v-for="(contact, j) in ref.condominium.condominiumOwners"
                  :key="j">
                  {{ contact.name }}
                </span>
              </template>
              <template v-else>
                <span
                  v-for="(contact, j) in ref.listContact"
                  :key="j">
                  {{ contact.name }}
                </span>
              </template>
            </span>
          </span>
          <span v-if="ref.room">
            <template v-if="ref.object">
              <span> > </span>
              <span
                v-if="!isMarketingModule"
                @click="setRoomId('object', ref.room.id)"
                @click.middle="setRoomId('object', ref.room.id)">
                <router-link
                  :to="redirectToDetail(ref.object.buildingId, ref.object.objectID || ref.object.id, 'objects')">
                  {{ ref.room.name }}
                </router-link>
              </span>
              <span v-else>{{ ref.room.name }}</span>
            </template>
            <template v-if="ref.building && !ref.object">
              <span> > </span>
              <span
                v-if="!isMarketingModule"
                @click="setRoomId('building', ref.room.id)"
                @click.middle="setRoomId('building', ref.room.id)">
                <router-link
                  :to="redirectToBuildingDetail(ref.building.id)">
                  {{ ref.room.name }}
                </router-link>
              </span>
              <span v-else>{{ ref.room.name }}</span>
            </template>
          </span>
          <span v-if="ref.contact">
            <router-link
              v-if="!isMarketingModule"
              :to="redirectToContactDetail(ref.contact.objectID || ref.contact.id)">
              {{ ref.contact.name }}
            </router-link>
            <span v-else>{{ ref.contact.name }}</span>
          </span>
          <span v-if="ref.request">
            <span> > </span>
            <router-link
              :to="redirectToRequestDetail(ref.request.objectId, ref.request.id)">
              <span
                v-for="(contact, j) in ref.listContact"
                :key="j">
                <span v-if="j > 0">- </span>{{ contact.name }}
              </span>
            </router-link>
          </span>
        </div>
        <div class="col-12">
          <div class="row">
            <div class="col-3">
              <coozzy-form-select
                v-model="typeOfConnection">
                <option
                  :value="null">
                  {{ $t('message.generic.all') }}
                </option>
                <option
                  v-for="item in availableConnectionTypes"
                  :key="item.key"
                  :value="item">
                  {{ $t('message.ticketOverview.ticket.connection_items.' + item.key) }}
                </option>
              </coozzy-form-select>
            </div>
            <div class="col-9">
              <ais-instant-search
                v-if="searchClient"
                :index-name="typeOfConnection ? typeOfConnection.value : 'object'"
                :routing="routing"
                :search-client="searchClient"
                class="w-100">
                <!-- eslint-disable vue/attribute-hyphenation -->
                <ais-configure
                  :filters="query"
                  :hitsPerPage="10" />
                <template v-if="typeOfConnection === null">
                  <ais-index index-name="building" />
                  <ais-index index-name="tenancy" />
                  <ais-index index-name="property" />
                  <ais-index index-name="condominium" />
                  <ais-index index-name="contact" />
                </template>
                <!-- eslint-disable vue/attribute-hyphenation -->
                <ais-autocomplete
                  v-if="!indexNameChanging"
                  :key="refrecheAutosuggestKey">
                  <template #default="{ indices, refine }">
                    <vue-autosuggest
                      :key="refrecheAutosuggestKey"
                      ref="autoSuggest_connections"
                      v-model="searchQuery"
                      :input-props="{...inputProps, placeholder: $t('message.generic.search')}"
                      :suggestions="indicesToSuggestions(indices)"
                      @input="onChange(refine,searchQuery)"
                      @selected="onSelect">
                      <template #default="{ suggestion }">
                        <span
                          :id="'tooltip-target_' + suggestion.item.objectID">
                          <template
                            v-if="suggestion.item.objectID.startsWith('tenancy')">
                            <div
                              :class="checkSelectedElement(suggestion.item.objectID)"
                              class="row m-0">
                              <div
                                class="p-0">
                                <div
                                  v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.tenancy')}"
                                  class="color-box ml-2 mr-2 tenancy" />
                              </div>

                              <div
                                class="col p-0">
                                <p
                                  v-if="suggestion.item.objectID.startsWith('tenancy')"
                                  class="mb-0">
                                  <strong v-if="suggestion.item.tenants.length > 0">
                                    <span
                                      v-for="(contact, j) in suggestion.item.tenants"
                                      :key="j">
                                      {{ contact.name }} <span
                                        v-if="j < suggestion.item.tenants.length - 1">-</span>
                                    </span>
                                  </strong>
                                  <strong v-else>-</strong>
                                </p>
                                <p class="mb-0">
                                  {{ suggestion.item.startingAt | objectToDate }} -
                                  {{ suggestion.item.endingAt | objectToDate }}
                                </p>
                              </div>
                              <span class="showSelected">
                                {{ $t('message.ticketOverview.ticket.connection_items.selected') }}
                              </span>
                            </div>
                          </template>
                          <template
                            v-if="suggestion.item.objectID.startsWith('condominium')">
                            <div
                              :class="checkSelectedElement(suggestion.item.objectID)"
                              class="row m-0">
                              <div
                                class="p-0">
                                <div
                                  v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.condo_owner')}"
                                  class="color-box ml-2 mr-2 condo_owner" />
                              </div>

                              <div class="col p-0">
                                <p
                                  v-if="suggestion.item.objectID.startsWith('condominium')"
                                  class="mb-0">
                                  <strong>
                                    {{
                                      suggestion.item.condominiumOwners.length > 0 ? suggestion.item.condominiumOwners[0].name : '-'
                                    }}
                                  </strong>
                                </p>
                                <p class="mb-0">
                                  {{ suggestion.item.startingAt | objectToDate }} -
                                  {{ suggestion.item.endingAt | objectToDate }}
                                </p>
                              </div>
                              <span class="showSelected">
                                {{ $t('message.ticketOverview.ticket.connection_items.selected') }}
                              </span>
                            </div>
                          </template>
                          <template
                            v-else-if="suggestion.item.objectID.startsWith('obj')">
                            <div
                              :class="checkSelectedElement(suggestion.item.objectID)"
                              class="row m-0">
                              <div
                                class="p-0">
                                <div
                                  v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.' + [suggestion.item.condominiumOwnership === true ? 'condominium' : 'object'])}"
                                  :class="[suggestion.item.condominiumOwnership === true ? 'condominium' : 'object']"
                                  class="color-box ml-2 mr-2" />
                              </div>
                              <div class="col p-0">
                                <p class="mb-0">
                                  <b>{{ suggestion.item.name }}</b>
                                </p>
                                <p class="mb-0">
                                  <address-text
                                    :address="suggestion.item.address"
                                    :hide-if-empty="true"
                                    :show-short-country="true" />
                                </p>
                              </div>
                              <span class="showSelected">
                                {{ $t('message.ticketOverview.ticket.connection_items.selected') }}
                              </span>
                            </div>
                          </template>
                          <template
                            v-else-if="suggestion.item.objectID.startsWith('prop')">
                            <div
                              :class="checkSelectedElement(suggestion.item.objectID)"
                              class="row m-0">
                              <div
                                class="p-0">
                                <div
                                  v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.property')}"
                                  class="color-box ml-2 mr-2 current" />
                              </div>
                              <div class="col p-0">
                                <p class="mb-0">
                                  <b>{{ suggestion.item.name }}</b>
                                </p>
                                <p class="mb-0">
                                  <address-text
                                    :address="suggestion.item.address"
                                    :hide-if-empty="true"
                                    :show-short-country="true" />
                                </p>
                              </div>
                              <span class="showSelected">
                                {{ $t('message.ticketOverview.ticket.connection_items.selected') }}
                              </span>
                            </div>
                          </template>
                          <template
                            v-else-if="suggestion.item.objectID.startsWith('building')">
                            <div
                              :class="checkSelectedElement(suggestion.item.objectID)"
                              class="row m-0">
                              <div
                                class="p-0">
                                <div
                                  v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.building')}"
                                  class="color-box ml-2 mr-2 not_activated" />
                              </div>
                              <div class="col p-0">
                                <p class="mb-0">
                                  <b>{{ suggestion.item.name }}</b>
                                </p>
                                <p class="mb-0">
                                  <address-text
                                    :address="suggestion.item.address"
                                    :hide-if-empty="true"
                                    :show-short-country="true" />
                                </p>
                              </div>
                              <span class="showSelected">
                                {{ $t('message.ticketOverview.ticket.connection_items.selected') }}
                              </span>
                            </div>
                          </template>
                          <template v-else-if="suggestion.item.objectID.startsWith('contact')">
                            <div
                              :class="checkSelectedElement(suggestion.item.objectID)"
                              class="row m-0">
                              <div
                                class="p-0">
                                <div
                                  v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.contact')}"
                                  class="color-box ml-2 mr-2 terminated" />
                              </div>

                              <div class="col p-0">
                                <strong>{{ suggestion.item.lastName }} {{ suggestion.item.firstName }}</strong>
                                <br>
                                <address-text
                                  :address="suggestion.item.address"
                                  :hide-if-empty="true"
                                  :show-short-country="true" />
                                <br>
                                <span v-if="getListRole(suggestion.item).length > 0">
                                  {{ $t('message.generic.roles') }}: {{ getListRole(suggestion.item) }}
                                </span>
                              </div>
                              <span class="showSelected">
                                {{ $t('message.ticketOverview.ticket.connection_items.selected') }}
                              </span>
                            </div>
                          </template>
                          <b-tooltip
                            v-if="checkSelectedElement(suggestion.item.objectID) === 'selected'"
                            :container="'#list-connections'"
                            :target="'tooltip-target_' + suggestion.item.objectID"
                            placement="left"
                            triggers="hover">
                            {{ $t('message.generic.alreadySelected') }}
                          </b-tooltip>
                        </span>
                      </template>
                    </vue-autosuggest>
                  </template>
                </ais-autocomplete>
              </ais-instant-search>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col p-0">
      <coozzy-button
        size="small"
        class="mb-0 border"
        design="transparent"
        @click="hide">
        {{ $t('message.generic.cancel') }}
      </coozzy-button>
      <coozzy-button
        size="small"
        class="float-right mb-0"
        design="green"
        :disabled="references.length === 0"
        @click="redirectToCreateOrder()">
        {{ $t('message.enterTermination.noAllTerminationReceived.continue') }}
      </coozzy-button>
    </div>
  </b-modal>
</template>

<script>
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon.vue'
import AddressText from '@/framework/components/misc/AddressText.vue'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import { algolia } from '@/mixins/algolia'
import { routeChecks } from '@/mixins/routeChecks'
import ContactApi from '@/misc/apis/ContactApi'
import ObjectApi from '@/misc/apis/ObjectApi'
import Vue from 'vue'
import { VueAutosuggest } from 'vue-autosuggest'

export default {
  name: 'addTicketConnectionsModal',
  components: {
    CoozzyFormSelect,
    AddressText,
    CoozzyDeleteIcon,
    CoozzyButton,
    VueAutosuggest
  },
  mixins: [algolia, routeChecks],
  props: {
    ticketId: {
      type: String,
      default: ''
    },
    connections: {
      type: Array,
      default: () => []
    },
    ticketType: {
      type: String,
      default: 'TICKET_TYPE_ORDER'
    },
    email: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      references: [],
      typeOfConnection: null,
      refrecheAutosuggestKey: '',
      query: '',
      searchQuery: '',
      indexNameChanging: false,
      availableConnectionTypes: [
        {
          key: 'property',
          value: 'property'
        },
        {
          key: 'building',
          value: 'building'
        },
        {
          key: 'object',
          value: 'object'
        },
        {
          key: 'condominium',
          value: 'object'
        },
        {
          key: 'tenancy',
          value: 'tenancy'
        },
        {
          key: 'condo_owner',
          value: 'condominium'
        },
        {
          key: 'contact',
          value: 'contact'
        }
      ]
    }
  },
  computed: {
  },
  watch: {
    typeOfConnection: function (item) {
      // this.indexNameChanging = true
      if (item) {
        this.query = ''
        if (item.key === 'condominium') {
          this.query = 'condominiumOwnership:true'
        }
        if (item.key === 'object') {
          this.query = 'condominiumOwnership:false'
        }
        if (item.value === 'contact') {
          this.query = 'isOwner:false AND isEmployee:false AND isOnlyInterestedParty:false'
        }
      }
      setTimeout(() => {
        this.refrecheAutosuggestKey = Math.floor(Math.random() * 100)
        // this.indexNameChanging = false
      }, 200)
    }
  },
  mounted() {
    this.loadInstantSearch()
    this.references = JSON.parse(JSON.stringify(this.connections))
  },
  methods: {
    show() {
      this.$bvModal.show('modalAddConnectionsForOrderCreation')
    },
    hide() {
      this.$bvModal.hide('modalAddConnectionsForOrderCreation')
      this.$emit('close-modal')
    },
    removeReference(index) {
      this.references = this.references.filter((element, i) => i !== index)
    },
    indicesToSuggestions(indices) {
      return indices.map(({ hits }) => ({
        data: hits
      }))
    },
    onChange(refine, email) {
      if (email && email.length > 0) {
        if (email !== '') clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => {
          this.debounceTimeout = null
          refine(email)
        }, 300)
      }
    },
    onSelect(selected) {
      if (selected && this.checkSelectedElement(selected.item.objectID) !== 'selected') {
        let alreadyExist = false
        const id = selected.item.objectID
        if (selected.item.objectID.startsWith('condominium')) {
          if (this.references.find(x => x.condominium && (x.condominium.id === id || x.condominium.objectID === id))) {
            this.references = this.references.filter(x => !x.condominium || (x.condominium && ((x.condominium.objectID && x.condominium.objectID !== id) || (x.condominium.id && x.condominium.id !== id))))
            alreadyExist = true
          }
        }
        if (selected.item.objectID.startsWith('tenancy')) {
          if (this.references.find(x => x.tenancy && (x.tenancy.id === id || x.tenancy.objectID === id))) {
            this.references = this.references.filter(x => !x.tenancy || (x.tenancy && ((x.tenancy.objectID && x.tenancy.objectID !== id) || (x.tenancy.id && x.tenancy.id !== id))))
            alreadyExist = true
          }
        }
        if (selected.item.objectID.startsWith('obj')) {
          if (this.references.find(x => x.object && (x.object.id === id || x.object.objectID === id) && (!x.tenancy && !x.condominium))) {
            this.references = this.references.filter(x => !x.object || (x.object && ((x.object.objectID && x.object.objectID !== id) || (x.object.id && x.object.id !== id) || (x.tenancy || x.condominium))))
            alreadyExist = true
          }
        }
        if (selected.item.objectID.startsWith('building')) {
          if (this.references.find(x => x.building && (x.building.id === id || x.building.objectID === id) && !x.object)) {
            this.references = this.references.filter(x => !x.building || (x.building && ((x.building.objectID && x.building.objectID !== id) || (x.building.id && x.building.id !== id) || x.object)))
            alreadyExist = true
          }
        }
        if (selected.item.objectID.startsWith('prop')) {
          if (this.references.find(x => x.property && (x.property.id === id || x.property.objectID === id) && !x.building)) {
            this.references = this.references.filter(x => !x.property || (x.property && ((x.property.objectID && x.property.objectID !== id) || (x.property.id && x.property.id !== id) || x.building)))
            alreadyExist = true
          }
        }
        if (selected.item.objectID.startsWith('contact')) {
          if (this.references.find(x => x.contact && (x.contact.id === id || x.contact.objectID === id))) {
            this.references = this.references.filter(x => !x.contact || (x.contact && ((x.contact.objectID && x.contact.objectID !== id) || (x.contact.id && x.contact.id !== id))))
            alreadyExist = true
          }
        }
        if (alreadyExist === false) {
          this.addToReferences(selected, true)
        }
        this.$nextTick(function () {
          this.searchQuery = ''
          this.refrecheAutosuggestKey = Math.floor(Math.random() * 100)
        })
      } else {
        this.$refs.autoSuggest_connections.internalValue = ''
      }
    },
    checkSelectedElement(id) {
      if (id.startsWith('condominium')) {
        if (this.references.find(x => x.condominium && (x.condominium.id === id || x.condominium.objectID === id))) {
          return 'selected'
        }
      }
      if (id.startsWith('tenancy')) {
        if (this.references.find(x => x.tenancy && (x.tenancy.id === id || x.tenancy.objectID === id))) {
          return 'selected'
        }
      }
      if (id.startsWith('obj')) {
        if (this.references.find(x => x.object && (x.object.id === id || x.object.objectID === id) && (!x.tenancy && !x.condominium))) {
          return 'selected'
        }
      }
      if (id.startsWith('building')) {
        if (this.references.find(x => x.building && (x.building.id === id || x.building.objectID === id) && !x.object)) {
          return 'selected'
        }
      }
      if (id.startsWith('prop')) {
        if (this.references.find(x => x.property && (x.property.id === id || x.property.objectID === id) && !x.building)) {
          return 'selected'
        }
      }
      if (id.startsWith('contact')) {
        if (this.references.find(x => x.contact && (x.contact.id === id || x.contact.objectID === id))) {
          return 'selected'
        }
      }
      return ''
    },
    redirectToRequestDetail(objectId, requestId) {
      return {
        name: 'MarketingObjectDetailsView',
        params: { id: objectId },
        query: {
          view: 0,
          elementSelected: requestId
        }
      }
    },
    redirectToContactDetail(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          return {
            name: 'OwnerContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isAssetModule) {
          return {
            name: 'AssetContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isAccountingModule) {
          return {
            name: 'AccountingContactDetailsView',
            params: { id: contactId }
          }
        } else if (this.isMarketingModule) {
          return {
            name: 'MarketingContactDetailsView',
            params: { id: contactId }
          }
        } else {
          return {
            name: 'AdminContactDetailsView',
            params: { id: contactId }
          }
        }
      }
    },
    redirectToBuildingDetail(buildingId) {
      if (this.isOwnerModule) {
        return {
          name: 'OwnerBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      } else if (this.isAssetModule) {
        return {
          name: 'AssetBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      } else if (this.isAccountingModule) {
        return {
          name: 'AccountingBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      } else if (this.isAdminModule) {
        return {
          name: 'AdminBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      }
    },
    redirectToDetail(buildingId, objectId, view) {
      if (this.isOwnerModule) {
        return {
          name: 'OwnerBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      } else if (this.isAdminModule) {
        return {
          name: 'AdminBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      } else if (this.isAssetModule) {
        return {
          name: 'AssetBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      } else if (this.isAccountingModule) {
        return {
          name: 'AccountingBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      }
    },
    redirectToDeviceDetail(referenceId, deviceId) {
      if (referenceId.startsWith('obj') || referenceId.startsWith('building')) {
        let id = null
        if (referenceId.startsWith('building')) {
          id = referenceId
        } else {
          const item = this.references.filter(x => x.device && x.device.id === deviceId)
          if (item.length > 0) {
            id = item[0].building?.id
          }
        }
        if (id && deviceId) {
          if (this.isOwnerModule) {
            return {
              name: 'OwnerBuildingDetailsView',
              params: { id: id },
              query: {
                view: 'devices',
                elementSelected: deviceId
              }
            }
          } else if (this.isAdminModule) {
            return {
              name: 'AdminBuildingDetailsView',
              params: { id: id },
              query: {
                view: 'devices',
                elementSelected: deviceId
              }
            }
          } else if (this.isAssetModule) {
            return {
              name: 'AssetBuildingDetailsView',
              params: { id: id },
              query: {
                view: 'devices',
                elementSelected: deviceId
              }
            }
          } else if (this.isAccountingModule) {
            return {
              name: 'AccountingBuildingDetailsView',
              params: { id: id },
              query: {
                view: 'devices',
                elementSelected: deviceId
              }
            }
          }
        }
      } else {
        let id = null
        id = referenceId
        if (this.isOwnerModule) {
          return {
            name: 'OwnerPropertyDetailsView',
            params: { id: id },
            query: {
              view: 'devices',
              elementSelected: deviceId
            }
          }
        } else if (this.isAdminModule) {
          return {
            name: 'AdminPropertyDetailsView',
            params: { id: id },
            query: {
              view: 'devices',
              elementSelected: deviceId
            }
          }
        } else if (this.isAssetModule) {
          return {
            name: 'AssetPropertyDetailsView',
            params: { id: id },
            query: {
              view: 'devices',
              elementSelected: deviceId
            }
          }
        } else if (this.isAccountingModule) {
          return {
            name: 'AccountingPropertyDetailsView',
            params: { id: id },
            query: {
              view: 'devices',
              elementSelected: deviceId
            }
          }
        }
      }
    },
    setInsuranceId(source, id) {
      if (source === 'building') {
        localStorage.setItem('expandBuildingInsuranceId', id)
      } else if (source === 'property') {
        localStorage.setItem('expandPropertyInsuranceId', id)
      }
    },
    setMortgageId(source, id) {
      if (source === 'building') {
        localStorage.setItem('expandBuildingMortgageId', id)
      } else if (source === 'property') {
        localStorage.setItem('expandPropertyMortgageId', id)
      }
    },
    setRoomId(source, id) {
      if (source === 'building') {
        localStorage.setItem('expandBuildingRoomId', id)
      } else if (source === 'object') {
        localStorage.setItem('expandObjectRoomId', id)
      }
    },
    redirectToPropertyDetail(propertyId) {
      if (this.isOwnerModule) {
        return {
          name: 'OwnerPropertyDetailsView',
          params: { id: propertyId }
        }
      } else if (this.isAdminModule) {
        return {
          name: 'AdminPropertyDetailsView',
          params: { id: propertyId }
        }
      } else if (this.isAssetModule) {
        return {
          name: 'AssetPropertyDetailsView',
          params: { id: propertyId }
        }
      } else if (this.isAccountingModule) {
        return {
          name: 'AccountingPropertyDetailsView',
          params: { id: propertyId }
        }
      }
    },

    addToReferences(selected) {
      this.searchQuery = null
      const lengthOfConnections = this.references.length
      this.references.push({
        request: null,
        object: null,
        building: null,
        property: null,
        condominium: null,
        tenancy: null,
        contact: null,
        device: null,
        room: null,
        mortgage: null,
        insurance: null,
        listContact: []
      })
      if (selected.item.objectID?.startsWith('condominium') || selected.item.id?.startsWith('condominium') || selected.item.objectID?.startsWith('tenancy') || selected.item.id?.startsWith('tenancy')) {
        if (selected.item.objectID?.startsWith('condominium') || selected.item.id?.startsWith('condominium')) {
          this.references[lengthOfConnections].condominium = selected.item
          if (selected.item.id || selected.item.objectID) {
            ContactApi.contactResolve(selected.item.owner.contactIds)
              .then(responseCon => {
                this.references[lengthOfConnections].listContact = responseCon.persons.concat(responseCon.companies).filter(c => selected.item.owner.contactIds.includes(c.id))
              })
          }
        } else if (selected.item.objectID?.startsWith('tenancy') || selected.item.id?.startsWith('tenancy')) {
          this.references[lengthOfConnections].tenancy = selected.item
          if (selected.item.id || selected.item.objectID) {
            const tenant = selected.item.tenant ? selected.item.tenant.contactIds : selected.item.tenants[0].contactIds
            ContactApi.contactResolve(tenant)
              .then(responseCon => {
                this.references[lengthOfConnections].listContact = responseCon.persons.concat(responseCon.companies).filter(c => tenant.includes(c.id))
              })
          }
        }
        ObjectApi.getObject(selected.item.obj?.id || selected.item.objectId)
          .then(response => {
            this.references[lengthOfConnections].object = response.object
            ObjectApi.getBuildingByObjectId(this.references[lengthOfConnections].object.id).then(response => {
              this.references[lengthOfConnections].building = response.building
              ObjectApi.getPropertiesByBuildingIds([this.references[lengthOfConnections].building.id])
                .then(response => {
                  this.references[lengthOfConnections].property = response.properties[0]
                })
            }).catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
            })
          })
      }
      if (selected.item.objectID?.startsWith('obj') || selected.item.id?.startsWith('obj')) {
        this.references[lengthOfConnections].object = selected.item
        // add address of object to description
        ObjectApi.getBuildingByObjectId(selected.item.objectID || selected.item.id).then(response => {
          this.references[lengthOfConnections].building = response.building
          ObjectApi.getPropertiesByBuildingIds([this.references[lengthOfConnections].building.id])
            .then(response => {
              this.references[lengthOfConnections].property = response.properties[0]
            })
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
        })
      }
      if (selected.item.objectID?.startsWith('building') || selected.item.id?.startsWith('building')) {
        this.references[lengthOfConnections].building = selected.item
        // add address of building to description
        ObjectApi.getPropertiesByBuildingIds([selected.item.objectID || selected.item.id])
          .then(response => {
            this.references[lengthOfConnections].property = response.properties[0]
          })
      }
      if (selected.item.objectID?.startsWith('prop') || selected.item.id?.startsWith('prop')) {
        this.references[lengthOfConnections].property = selected.item
        // add the addresses of all the buildings of a property to the description
      }
      if (selected.item.objectID?.startsWith('contact') || selected.item.id?.startsWith('contact')) {
        this.references[lengthOfConnections].contact = selected.item
      }
      if (selected.item.objectID?.startsWith('device') || selected.item.id?.startsWith('device')) {
        this.references[lengthOfConnections].device = selected.item

        if (selected.item.referenceId?.startsWith('obj')) {
          ObjectApi.getObject(selected.item.referenceId)
            .then(response => {
              this.references[lengthOfConnections].object = response.object
              ObjectApi.getBuildingByObjectId(this.references[lengthOfConnections].object.id).then(response => {
                this.references[lengthOfConnections].building = response.building
                ObjectApi.getPropertiesByBuildingIds([this.references[lengthOfConnections].building.id])
                  .then(response => {
                    this.references[lengthOfConnections].property = response.properties[0]
                  })
              }).catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
              })
            })
        }
        if (selected.item.referenceId?.startsWith('building')) {
          ObjectApi.getBuilding(selected.item.referenceId)
            .then(response => {
              this.references[lengthOfConnections].building = response.building
              ObjectApi.getPropertiesByBuildingIds(response.building.id)
                .then(response => {
                  this.references[lengthOfConnections].property = response.properties[0]
                })
            })
        }
        if (selected.item.referenceId?.startsWith('prop')) {
          ObjectApi.getPropertyListByIds([selected.item.referenceId])
            .then(response => {
              this.references[lengthOfConnections].property = response.properties[0]
            })
        }
      }
      if (selected.item.objectID?.startsWith('req') || selected.item.id?.startsWith('req')) {
        this.references[lengthOfConnections].request = selected.item
        if (selected.item.id || selected.item.objectID) {
          const cont = selected.item.requesters[0].contactId
          ContactApi.contactResolve([cont])
            .then(response => {
              this.references[lengthOfConnections].listContact = response.persons.concat(response.companies).filter(c => cont.includes(c.id))
            })
          ObjectApi.getObject(selected.item.objectId)
            .then(response => {
              this.references[lengthOfConnections].object = response.object
              ObjectApi.getBuildingByObjectId(this.references[lengthOfConnections].object.id).then(response => {
                this.references[lengthOfConnections].building = response.building
                ObjectApi.getPropertiesByBuildingIds([this.references[lengthOfConnections].building.id])
                  .then(response => {
                    this.references[lengthOfConnections].property = response.properties[0]
                  })
              }).catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
              })
            })
        }
      }
      if (selected.item.id?.startsWith('room')) {
        this.references[lengthOfConnections].room = selected.item
        if (selected.item && selected.item.referenceId.startsWith('obj')) {
          ObjectApi.getObject(selected.item.referenceId)
            .then(response => {
              this.references[lengthOfConnections].object = response.object
              ObjectApi.getBuildingByObjectId(this.references[lengthOfConnections].object.id).then(response => {
                this.references[lengthOfConnections].building = response.building
                ObjectApi.getPropertiesByBuildingIds([this.references[lengthOfConnections].building.id])
                  .then(response => {
                    this.references[lengthOfConnections].property = response.properties[0]
                  })
              }).catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
              })
            })
        }
        if (selected.item && selected.item.referenceId.startsWith('building')) {
          ObjectApi.getBuilding(selected.item.referenceId).then(response => {
            this.references[lengthOfConnections].building = response.building
            ObjectApi.getPropertiesByBuildingIds([this.references[lengthOfConnections].building.id])
              .then(response => {
                this.references[lengthOfConnections].property = response.properties[0]
              })
          }).catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
          })
        }
      }
    },
    getListRole(contact) {
      if (contact !== null && contact.types !== undefined) {
        if (contact.types && contact.types.length === 1 && contact.types[0] === 'NONE') {
          return '-'
        } else {
          const array = []
          contact.types.forEach(role => {
            if (role !== 'NONE') {
              array.push(this.$t('message.contact.types.' + role.toUpperCase()))
            }
          })
          return array.join(', ')
        }
      }
      return '-'
    },
    redirectToCreateOrder() {
      const buildingIds = []
      const deviceIds = []
      const propertyIds = []
      const objectIds = []
      const tenancyIds = []
      const condoOwnerIds = []
      const roomIds = []
      const insuranceIds = []
      const mortgageIds = []
      const contactIds = []
      const requestIds = []
      this.references.forEach((element) => {
        if (element.mortgage) {
          mortgageIds.push(element.mortgage.id ? element.mortgage.id : element.mortgage.objectID)
        } else if (element.insurance) {
          insuranceIds.push(element.insurance.id ? element.insurance.id : element.insurance.objectID)
        } else if (element.room) {
          roomIds.push(element.room.id ? element.room.id : element.room.objectID)
        } else if (element.contact) {
          contactIds.push(element.contact.id ? element.contact.id : element.contact.objectID)
        } else if (element.tenancy) {
          tenancyIds.push(element.tenancy.id ? element.tenancy.id : element.tenancy.objectID)
        } else if (element.request) {
          requestIds.push(element.request.id ? element.request.id : element.request.objectID)
        } else if (element.condominium) {
          condoOwnerIds.push(element.condominium.id ? element.condominium.id : element.condominium.objectID)
        } else if (element.device) {
          deviceIds.push(element.device.id ? element.device.id : element.device.objectID)
        } else if (element.object) {
          objectIds.push(element.object.id ? element.object.id : element.object.objectID)
        } else if (element.building) {
          buildingIds.push(element.building.id ? element.building.id : element.building.objectID)
        } else if (element.property) {
          propertyIds.push(element.property.id ? element.property.id : element.property.objectID)
        }
      })
      const query = {
        source: this.email ? 'email' : 'ticket'
      }
      if (this.ticketId) {
        query.linkedTicketId = this.ticketId
      }
      if (buildingIds.length > 0) {
        query.buildingIds = buildingIds.join(',')
      }
      if (deviceIds.length > 0) {
        query.deviceIds = deviceIds.join(',')
      }
      if (propertyIds.length > 0) {
        query.propertyIds = propertyIds.join(',')
      }
      if (objectIds.length > 0) {
        query.objectIds = objectIds.join(',')
      }
      if (tenancyIds.length > 0) {
        query.tenancyIds = tenancyIds.join(',')
      }
      if (condoOwnerIds.length > 0) {
        query.condoOwnerIds = condoOwnerIds.join(',')
      }
      if (roomIds.length > 0) {
        query.roomIds = roomIds.join(',')
      }
      if (insuranceIds.length > 0) {
        query.insuranceIds = insuranceIds.join(',')
      }
      if (mortgageIds.length > 0) {
        query.mortgageIds = mortgageIds.join(',')
      }
      if (contactIds.length > 0) {
        query.contactIds = contactIds.join(',')
      }
      if (requestIds.length > 0) {
        query.requestIds = requestIds.join(',')
      }
      if (this.email) {
        query.emailsData = JSON.stringify({ id: this.email.id || this.email.objectID, referenceIds: this.email.referenceIds })
      }
      if (this.ticketType === 'TICKET_TYPE_ORDER') {
        const currentRoute = { name: this.$router.currentRoute.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query }
        localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerCreateOrderView',
            query: query
          }, () => {
          })
        } else if (this.isAdminModule) {
          this.$router.push({
            name: 'AdminCreateOrderView',
            query: query
          }, () => {
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetCreateOrderView',
            query: query
          }, () => {
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingCreateOrderView',
            query: query
          }, () => {
          })
        }
      } else {
        query.ticketType = this.ticketType
        query.emailsData = JSON.stringify({ id: this.email.id || this.email.objectID, referenceIds: this.email.referenceIds })
        if (this.isOwnerModule) {
          this.$router.push({
            name: 'OwnerTicketCreation',
            query: query
          })
        } else if (this.isAdminModule) {
          this.$router.push({
            name: 'AdminTicketCreation',
            query: query
          })
        } else if (this.isAssetModule) {
          this.$router.push({
            name: 'AssetTicketCreation',
            query: query
          })
        } else if (this.isAccountingModule) {
          this.$router.push({
            name: 'AccountingTicketCreation',
            query: query
          })
        }
      }
      this.hide()
      this.$bvModal.hide('modal-detail-mail')
    }
  }
}
</script>

<style lang="scss" scoped>

.color-box.condo_owner {
  background: #f589c7;
}

.color-box.condominium {
  background: #913489;
}

.color-box.tenancy {
  background: #00a4bd;
}

.color-box.object {
  background: #f38d38;
}

.color-box.device {
  background: #767171;
}

.color-box.request {
  background: #f7b6ae;
}

.containerCircles {
  height: 21px;
  align-items: center;
  display: flex;
}

.showSelected {
  display: none;
}

.selected {
  background-color: rgba(0, 0, 0, 0.08);
}

.selected .showSelected {
  display: block;
  color: rgba(0, 0, 0, 0.5);
  align-self: center;
  margin-right: 20px;
  font-weight: 600;
}

.disableOptionHover {
  cursor: default !important;
  color: #6c757d !important;
  opacity: 0.5;
}

:deep(#autosuggest .autosuggest__results) {
  z-index: 9998;
}

.tooltip {
  z-index: 9999;
}

.connection a.router-link-active, .connection a {
  color: inherit !important;
  text-decoration: none !important;
}

.connection a.router-link-active:hover, .connection a:hover {
  color: #0055b7 !important;
  text-decoration: underline !important;
  cursor: pointer;
}

:deep(.autosuggest__results-item.autosuggest__results-item--highlighted) {
  background-color: rgba(0, 0, 0, 0.03);
}
.autosuggest__results-item .selected {
  color: rgba(0, 0, 0, 0.5) !important;
}
.selectedOption {
  color: #495057;
  background-color: #e9ecef;
}
#modalAddConnectionsForOrderCreation___BV_modal_outer_ {
   z-index: 8001 !important;
 }
</style>
