<template>
  <img
    width="16px"
    height="16px"
    src="@/assets/icon/comment.png"
    alt="commentIcon">
</template>

<script>

export default {
  name: 'CoozzyCommentIconImg'
}
</script>

<style lang="scss" scoped>
</style>
