<template>
  <div class="row">
    <template>
      <b-tabs
        v-model="tabIndex"
        no-key-nav
        class="col-12 col-md-12 mb-3">
        <b-tab
          :title="$t('message.createOrder.linkedObjects')"
          class="p-4">
          <div class="col-12">
            <b-table
              id="table-groups"
              ref="table-groups"
              hover
              bordered
              responsive="true"
              stacked="md"
              class="mb-4"
              :fields="fields"
              :items="listEstates">
              <!-- Busy state -->
              <div
                slot="table-busy"
                class="text-center text-danger my-2">
                <coozzy-spinner />
              </div>
              <template #head(category)>
                {{ $t('message.advertisement.objectCategory.name') }}
              </template>
              <template #head(name)>
                {{ $t('message.onBoarding.devices.description') }}
              </template>
              <template #head(tenant)>
                {{ $t('message.tableColumnHeadings.tenant') }}
              </template>
              <!-- Body -->
              <template #cell(checkbox)="data">
                <coozzy-form-checkbox
                  :id="'check_' + data.item.id"
                  :disabled="selectedEntries.length > 0 && !selectedEntries.some(x => x.id === data.item.id)"
                  @change="(value) => { itemSelected(data.item, value) }" />
              </template>
              <template #cell(category)="data">
                <template v-if="data.item.id.startsWith('prop_')">
                  {{ $t('message.propertiesView.tabs.property') }}
                </template>
                <template v-else-if="data.item.id.startsWith('building_')">
                  {{ $t('message.generic.building') }}
                </template>
                <template v-else-if="data.item.category && data.item.category !== 'UNDEFINED_CATEGORY'">
                  {{ $t('message.onBoarding.buildings.objects.category.' + data.item.category.toLowerCase()) }}
                </template>
                <template v-else>
                  -
                </template>
              </template>
              <template #cell(name)="data">
                {{ data.item.name }}
              </template>
              <template #cell(tenant)="data">
                {{ getContactRentObject(data.item) }}
              </template>
            </b-table>
          </div>
        </b-tab>
        <b-tab
          :title="$t('message.createOrder.allObjects')"
          class="p-4">
          <div
            id="list-connections"
            class="row mb-2 connection">
            <label
              class="col-12"
              for="connections"> {{ $t('message.ticketOverview.ticket.connections') }}</label>
            <div
              v-for="(ref, index) in listReferences"
              :key="index"
              class="col-12 d-flex align-items-center">
              <coozzy-button
                class="border mb-2"
                design="transparent"
                size="small"
                @click="removeReference(index)">
                <coozzy-delete-icon />
              </coozzy-button>
              <div class="containerCircles">
                <div
                  v-if="ref.tenancy"
                  v-b-tooltip="{container: '#list-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.tenancy')}"
                  class="color-box mt-0 ml-2 mr-2 tenancy" />
                <div
                  v-if="ref.condominium"
                  v-b-tooltip="{container: '#list-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.condo_owner')}"
                  class="color-box mt-0 ml-2 mr-2 condo_owner" />
                <div
                  v-if="ref.object && ref.object.condominiumOwnership === true && !ref.tenancy && !ref.condominium && !ref.device && !ref.request"
                  v-b-tooltip="{container: '#list-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.condominium')}"
                  class="color-box mt-0 ml-2 mr-2 condominium" />
                <div
                  v-if="ref.object && ref.object.condominiumOwnership === false && !ref.tenancy && !ref.condominium && !ref.device && !ref.request"
                  v-b-tooltip="{container: '#list-connections', placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.object')}"
                  class="color-box mt-0 ml-2 mr-2 object" />
                <div
                  v-if="ref.building && !ref.object && !ref.device && !ref.mortgage && !ref.insurance"
                  v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.building')}"
                  class="color-box mt-0 ml-2 mr-2 not_activated" />
                <div
                  v-if="ref.property && !ref.building && !ref.device && !ref.mortgage && !ref.insurance"
                  v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.property')}"
                  class="color-box mt-0 ml-2 mr-2 current" />
              </div>
              <span v-if="ref.property">
                <router-link
                  v-if="!isMarketingModule"
                  :to="redirectToPropertyDetail(ref.property.objectID || ref.property.id)">
                  {{ ref.property.name }}
                </router-link>
                <span v-else>{{ ref.property.name }}</span>
              </span>
              <span v-if="ref.building">
                <span v-if="ref.property"> > </span>
                <router-link
                  v-if="!isMarketingModule"
                  :to="redirectToBuildingDetail(ref.building.objectID || ref.building.id)">
                  {{ ref.building.name }}
                </router-link>
                <span v-else>{{ ref.building.name }}</span>
              </span>
              <span v-if="ref.object">
                <span v-if="ref.building"> > </span>
                <router-link
                  v-if="!isMarketingModule"
                  :to="redirectToDetail(ref.object.buildingId, ref.object.objectID || ref.object.id, 'objects')">
                  {{
                    ref.object.name !== '' ? ref.object.name : ref.object.number !== '' ? ref.object.number : ref.object.numericId
                  }}
                </router-link>
                <span v-else> {{
                  ref.object.name !== '' ? ref.object.name : ref.object.number !== '' ? ref.object.number : ref.object.numericId
                }}</span>
              </span>

              <span v-if="ref.object && ref.tenancy">
                <span v-if="ref.object"> > </span>
                <router-link
                  v-if="!isMarketingModule"
                  :to="redirectToDetail(ref.object.buildingId, ref.tenancy.objectID || ref.tenancy.id, 'tenants')">
                  <template v-if="ref.tenancy.tenants">
                    <span
                      v-for="(contact, j) in ref.tenancy.tenants"
                      :key="j">
                      <span v-if="j > 0">- </span>{{ contact.name }}
                    </span>
                  </template>
                  <template v-else>
                    <span
                      v-for="(contact, j) in ref.listContact"
                      :key="j">
                      <span v-if="j > 0">- </span>{{ contact.name }}
                    </span>
                  </template>
                </router-link>
                <span v-else>
                  <template v-if="ref.tenancy.tenants">
                    <span
                      v-for="(contactTen, j) in ref.tenancy.tenants"
                      :key="j">
                      <span v-if="j > 0">- </span>{{ contactTen.name }}
                    </span>
                  </template>
                  <template v-else>
                    <span
                      v-for="(contact, j) in ref.listContact"
                      :key="j">
                      <span v-if="j > 0">- </span>{{ contact.name }}
                    </span>
                  </template>
                </span>
              </span>
              <span v-if="ref.object && ref.condominium">
                <span v-if="ref.object"> > </span>
                <router-link
                  v-if="!isMarketingModule"
                  :to="redirectToDetail(ref.object.buildingId, ref.condominium.objectID || ref.condominium.id, 'condominium')">
                  <template v-if="ref.condominium.condominiumOwners">
                    <span
                      v-for="(contact, j) in ref.condominium.condominiumOwners"
                      :key="j">
                      {{ contact.name }}
                    </span>
                  </template>
                  <template v-else>
                    <span
                      v-for="(contact, j) in ref.listContact"
                      :key="j">
                      {{ contact.name }}
                    </span>
                  </template>
                </router-link>
                <span v-else>
                  <template v-if="ref.condominium.condominiumOwners">
                    <span
                      v-for="(contact, j) in ref.condominium.condominiumOwners"
                      :key="j">
                      {{ contact.name }}
                    </span>
                  </template>
                  <template v-else>
                    <span
                      v-for="(contact, j) in ref.listContact"
                      :key="j">
                      {{ contact.name }}
                    </span>
                  </template>
                </span>
              </span>
            </div>
            <div class="col-12">
              <div class="row">
                <div class="col-3">
                  <coozzy-form-select
                    v-model="typeOfConnection"
                    :disabled="listReferences.length > 0">
                    <option
                      :value="null">
                      {{ $t('message.generic.all') }}
                    </option>
                    <option
                      v-for="item in availableConnectionTypes"
                      :key="item.key"
                      :value="item">
                      {{ $t('message.ticketOverview.ticket.connection_items.' + item.key) }}
                    </option>
                  </coozzy-form-select>
                </div>
                <div class="col-9">
                  <ais-instant-search
                    v-if="searchClient"
                    :index-name="typeOfConnection ? typeOfConnection.value : 'object'"
                    :routing="routing"
                    :search-client="searchClient"
                    class="w-100">
                    <!-- eslint-disable vue/attribute-hyphenation -->
                    <ais-configure
                      :filters="query"
                      :hitsPerPage="10" />
                    <template v-if="typeOfConnection === null">
                      <ais-index index-name="building" />
                      <ais-index index-name="property" />
                    </template>
                    <!-- eslint-disable vue/attribute-hyphenation -->
                    <ais-autocomplete
                      :key="refrecheAutosuggestKey">
                      <template #default="{ indices, refine }">
                        <vue-autosuggest
                          :key="refrecheAutosuggestKey"
                          ref="autoSuggest_connections"
                          v-model="searchQuery"
                          :input-props="{...inputProps, placeholder: $t('message.generic.search'), disabled: listReferences.length > 0}"
                          :suggestions="indicesToSuggestions(indices)"
                          @input="onChange(refine,searchQuery)"
                          @selected="onSelect">
                          <template #default="{ suggestion }">
                            <span
                              :id="'tooltip-target_' + suggestion.item.objectID">
                              <template
                                v-if="suggestion.item.objectID.startsWith('tenancy')">
                                <div
                                  class="row m-0">
                                  <div
                                    class="p-0">
                                    <div
                                      v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.tenancy')}"
                                      class="color-box ml-2 mr-2 tenancy" />
                                  </div>

                                  <div class="col p-0">
                                    <p
                                      v-if="suggestion.item.objectID.startsWith('tenancy')"
                                      class="mb-0">
                                      <strong v-if="suggestion.item.tenants.length > 0">
                                        <span
                                          v-for="(tn, j) in suggestion.item.tenants"
                                          :key="j">
                                          {{ tn.name }} <span v-if="j < suggestion.item.tenants.length - 1">-</span>
                                        </span>
                                      </strong>
                                      <strong v-else>-</strong>
                                    </p>
                                    <p class="mb-0">
                                      {{ suggestion.item.startingAt | objectToDate }} -
                                      {{ suggestion.item.endingAt | objectToDate }}
                                    </p>
                                  </div>
                                </div>
                              </template>
                              <template
                                v-if="suggestion.item.objectID.startsWith('condominium')">
                                <div
                                  class="row m-0">
                                  <div
                                    class="p-0">
                                    <div
                                      v-b-tooltip="{container: '#list-connections',placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.condo_owner')}"
                                      class="color-box ml-2 mr-2 condo_owner" />
                                  </div>

                                  <div class="col p-0">
                                    <p
                                      v-if="suggestion.item.objectID.startsWith('condominium')"
                                      class="mb-0">
                                      <strong>
                                        {{
                                          suggestion.item.condominiumOwners.length > 0 ? suggestion.item.condominiumOwners[0].name : '-'
                                        }}
                                      </strong>
                                    </p>
                                    <p class="mb-0">
                                      {{ suggestion.item.startingAt | objectToDate }} -
                                      {{ suggestion.item.endingAt | objectToDate }}
                                    </p>
                                  </div>
                                </div>
                              </template>
                              <template
                                v-if="suggestion.item.objectID.startsWith('obj')">
                                <div
                                  class="row m-0">
                                  <div
                                    class="p-0">
                                    <div
                                      v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.' + [suggestion.item.condominiumOwnership === true ? 'condominium' : 'object'])}"
                                      :class="[suggestion.item.condominiumOwnership === true ? 'condominium' : 'object']"
                                      class="color-box ml-2 mr-2" />
                                  </div>
                                  <div class="col p-0">
                                    <p class="mb-0">
                                      <b>{{ suggestion.item.name }}</b>
                                    </p>
                                    <p class="mb-0">
                                      <address-text
                                        :address="suggestion.item.address"
                                        :hide-if-empty="true"
                                        :show-short-country="true" />
                                    </p>
                                  </div>
                                </div>
                              </template>
                              <template
                                v-else-if="suggestion.item.objectID.startsWith('prop')">
                                <div
                                  class="row m-0">
                                  <div
                                    class="p-0">
                                    <div
                                      v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.property')}"
                                      class="color-box ml-2 mr-2 current" />
                                  </div>
                                  <div class="col p-0">
                                    <p class="mb-0">
                                      <b>{{ suggestion.item.name }}</b>
                                    </p>
                                    <p class="mb-0">
                                      <address-text
                                        :address="suggestion.item.address"
                                        :hide-if-empty="true"
                                        :show-short-country="true" />
                                    </p>
                                  </div>
                                </div>
                              </template>
                              <template
                                v-else-if="suggestion.item.objectID.startsWith('building')">
                                <div
                                  class="row m-0">
                                  <div
                                    class="p-0">
                                    <div
                                      v-b-tooltip="{placement : 'left', title : $t('message.ticketOverview.ticket.connection_items.building')}"
                                      class="color-box ml-2 mr-2 not_activated" />
                                  </div>
                                  <div class="col p-0">
                                    <p class="mb-0">
                                      <b>{{ suggestion.item.name }}</b>
                                    </p>
                                    <p class="mb-0">
                                      <address-text
                                        :address="suggestion.item.address"
                                        :hide-if-empty="true"
                                        :show-short-country="true" />
                                    </p>
                                  </div>
                                </div>
                              </template>
                            </span>
                          </template>
                        </vue-autosuggest>
                      </template>
                    </ais-autocomplete>
                  </ais-instant-search>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </template>
  </div>
</template>

<script>
import { algolia } from '@/mixins/algolia'
import { routeChecks } from '@/mixins/routeChecks'
import { contact } from '@/mixins/contact'
import { user } from '@/mixins/user'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import { obj } from '@/mixins/object'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import AddressText from '@/framework/components/misc/AddressText.vue'
import { VueAutosuggest } from 'vue-autosuggest'
import ObjectApi from '@/misc/apis/ObjectApi'
import Vue from 'vue'
import ContactApi from '@/misc/apis/ContactApi'

export default {
  name: 'OrderObjectsTab',
  components: {
    AddressText,
    CoozzyButton,
    CoozzyDeleteIcon,
    CoozzyFormSelect,
    CoozzySpinner,
    CoozzyFormCheckbox,
    VueAutosuggest
  },
  mixins: [user, algolia, contact, routeChecks, obj],
  props: {
    properties: {
      type: Array,
      default: () => []
    },
    buildings: {
      type: Array,
      default: () => []
    },
    objects: {
      type: Array,
      default: () => []
    },
    tenancies: {
      type: Array,
      default: () => []
    },
    condoOwners: {
      type: Array,
      default: () => []
    },
    contacts: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tabIndex: 0,
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'category',
          label: this.$t('message.advertisement.objectCategory.name'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'name',
          label: this.$t('message.onBoarding.devices.description'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'tenant',
          label: this.$t('message.tableColumnHeadings.tenant'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      selectedEntries: [],
      listReferences: [],
      availableConnectionTypes: [
        {
          key: 'property',
          value: 'property'
        },
        {
          key: 'building',
          value: 'building'
        },
        {
          key: 'object',
          value: 'object'
        },
        {
          key: 'condominium',
          value: 'object'
        },
        {
          key: 'tenancy',
          value: 'tenancy'
        },
        {
          key: 'condo_owner',
          value: 'condominium'
        }
      ],
      typeOfConnection: null,
      query: '',
      refrecheAutosuggestKey: '',
      searchQuery: ''
    }
  },
  computed: {
    listEstates() {
      return this.sortListEstates(this.properties, this.buildings, this.objects)
    }
  },
  watch: {
    typeOfConnection: function (item) {
      // this.indexNameChanging = true
      if (item) {
        this.query = ''
        if (item.key === 'condominium') {
          this.query = 'condominiumOwnership:true'
        }
        if (item.key === 'object') {
          this.query = 'condominiumOwnership:false'
        }
      }
      setTimeout(() => {
        this.refrecheAutosuggestKey = Math.floor(Math.random() * 100)
        // this.indexNameChanging = false
      }, 200)
    }
  },
  mounted: function () {
    this.loadInstantSearch()
  },
  methods: {
    // sort list estates like property, building, object of the same property
    sortListEstates(properties, buildings, objects) {
      // Sort properties by their IDs
      properties.sort((a, b) => a.id.localeCompare(b.id))

      // Sort buildings by their IDs
      buildings.sort((a, b) => a.id.localeCompare(b.id))

      // Sort objects by their buildingId and then by their IDs
      objects.sort((a, b) => {
        if (a.buildingId === b.buildingId) {
          return a.id.localeCompare(b.id)
        }
        return a.buildingId.localeCompare(b.buildingId)
      })

      // Combine the sorted lists
      const sortedList = []
      properties.forEach(property => {
        sortedList.push(property)
        property.buildingIds.forEach(buildingId => {
          const building = buildings.find(bld => bld.id === buildingId)
          if (building) {
            sortedList.push(building)
            objects
              .filter(object => object.buildingId === building.id)
              .forEach(object => {
                sortedList.push(object)
              })
          }
        })
      })

      return sortedList
  },
    indicesToSuggestions(indices) {
      return indices.map(({ hits }) => ({
        data: hits
      }))
    },
    onChange(refine, email) {
      if (email && email.length > 0) {
        if (email !== '') clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => {
          this.debounceTimeout = null
          refine(email)
        }, 300)
      }
    },
    removeReference(index) {
      this.listReferences = this.listReferences.filter((element, i) => i !== index)
      this.$emit('references-removed')
    },
    onSelect(selected) {
      this.addToReferences(selected, true)
      this.$nextTick(function () {
        this.searchQuery = ''
        this.refrecheAutosuggestKey = Math.floor(Math.random() * 100)
      })
    },
    async addToReferences(selected) {
      this.searchQuery = null
      this.listReferences.push({
        condominium: null,
        tenancy: null,
        object: null,
        building: null,
        property: null,
        listContact: []
      })
      if ('objectID' in selected.item) {
        selected.item.id = selected.item.objectID
      }
      this.$emit('object-selected', [selected.item])
      if (selected.item.objectID?.startsWith('condominium') || selected.item.id?.startsWith('condominium') || selected.item.objectID?.startsWith('tenancy') || selected.item.id?.startsWith('tenancy')) {
        if (selected.item.objectID?.startsWith('condominium') || selected.item.id?.startsWith('condominium')) {
          this.listReferences[0].condominium = selected.item
          if (selected.item.id || selected.item.objectID) {
            await ContactApi.contactResolve(selected.item.owner.contactIds)
              .then(responseCon => {
                this.listReferences[0].listContact = responseCon.persons.concat(responseCon.companies).filter(c => selected.item.owner.contactIds.includes(c.id))
              })
          }
        } else if (selected.item.objectID?.startsWith('tenancy') || selected.item.id?.startsWith('tenancy')) {
          this.listReferences[0].tenancy = selected.item
          if (selected.item.id || selected.item.objectID) {
            const tenant = selected.item.tenant ? selected.item.tenant.contactIds : selected.item.tenants[0].contactIds
            await ContactApi.contactResolve(tenant)
              .then(responseCon => {
                this.listReferences[0].listContact = responseCon.persons.concat(responseCon.companies).filter(c => tenant.includes(c.id))
              })
          }
        }
        await ObjectApi.getObject(selected.item.obj?.id || selected.item.objectId)
          .then(async (response) => {
            this.listReferences[0].object = response.object
            await ObjectApi.getBuildingByObjectId(this.listReferences[0].object.id).then(async (response) => {
              this.listReferences[0].building = response.building
              await ObjectApi.getPropertiesByBuildingIds([this.listReferences[0].building.id])
                .then(response => {
                  this.listReferences[0].property = response.properties[0]
                })
            }).catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
            })
          })
      }
      if (selected.item.objectID?.startsWith('obj') || selected.item.id?.startsWith('obj')) {
        this.listReferences[0].object = selected.item
        // add address of object to description
        await ObjectApi.getBuildingByObjectId(selected.item.objectID || selected.item.id).then(async (response) => {
          this.listReferences[0].building = response.building
          await ObjectApi.getPropertiesByBuildingIds([this.listReferences[0].building.id])
            .then(response => {
              this.listReferences[0].property = response.properties[0]
            })
        }).catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
        })
      }
      if (selected.item.objectID?.startsWith('building') || selected.item.id?.startsWith('building')) {
        this.listReferences[0].building = selected.item
        // add address of building to description
        await ObjectApi.getPropertiesByBuildingIds([selected.item.objectID || selected.item.id])
          .then(response => {
            this.listReferences[0].property = response.properties[0]
          })
      }
      if (selected.item.objectID?.startsWith('prop') || selected.item.id?.startsWith('prop')) {
        this.listReferences[0].property = selected.item
        // add the addresses of all the buildings of a property to the description
      }
      this.$nextTick(() => {
        const data = JSON.parse(JSON.stringify(this.listReferences[0]))
        this.$emit('loaded-data-algolia', data)
      })
    },
    redirectToPropertyDetail(propertyId) {
      if (this.isOwnerModule) {
        return {
          name: 'OwnerPropertyDetailsView',
          params: { id: propertyId }
        }
      } else if (this.isAdminModule) {
        return {
          name: 'AdminPropertyDetailsView',
          params: { id: propertyId }
        }
      } else if (this.isAssetModule) {
        return {
          name: 'AssetPropertyDetailsView',
          params: { id: propertyId }
        }
      } else if (this.isAccountingModule) {
        return {
          name: 'AccountingPropertyDetailsView',
          params: { id: propertyId }
        }
      }
    },
    redirectToBuildingDetail(buildingId) {
      if (this.isOwnerModule) {
        return {
          name: 'OwnerBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      } else if (this.isAssetModule) {
        return {
          name: 'AssetBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      } else if (this.isAccountingModule) {
        return {
          name: 'AccountingBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      } else if (this.isAdminModule) {
        return {
          name: 'AdminBuildingDetailsView',
          params: { id: buildingId },
          query: { view: 'building' }
        }
      }
    },
    redirectToDetail(buildingId, objectId, view) {
      if (this.isOwnerModule) {
        return {
          name: 'OwnerBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      } else if (this.isAdminModule) {
        return {
          name: 'AdminBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      } else if (this.isAssetModule) {
        return {
          name: 'AssetBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      } else if (this.isAccountingModule) {
        return {
          name: 'AccountingBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }
      }
    },
    itemSelected(item, checked) {
      if (checked) {
        this.selectedEntries.push(item)
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry.id !== item.id)
      }
      this.$emit('object-selected', this.selectedEntries)
    },
    getContactRentObject(item) {
      if (item.id.startsWith('obj_')) {
        const obj = this.objects.find(obj => obj.id === item.id)
        const currentTenancy = this.getCurrentRent(obj.id) ? this.getCurrentRent(obj.id) : null
        if (currentTenancy && currentTenancy.vacancy === true) {
          return this.$t('message.onBoarding.buildings.objects.tenancies.vacancy')
        } else if (currentTenancy && currentTenancy.vacancy === false && currentTenancy.tenant.contactIds.length > 0) {
          let names = ''
          currentTenancy.tenant.contactIds.forEach(element => {
            names += this.getContactName(element)
          })
          return names !== '' ? names.substring(1) : ''
        }
        return '-'
      }
      return '-'
    },
    getContactName(contactId) {
      const contact = this.contacts.find(function (contact) {
        return contact.id === contactId || contact.objectID === contactId
      })
      return contact ? ', ' + contact.name : '-'
    },
    getCurrentRent(objectId) {
      // We got an object from our backend
      const startOfDayTimestamp = this.getStartOfDayTimestamp()
      const list = this.tenancies.concat(this.condoOwners)
      const listByObject = list.filter(t => t.objectId === objectId)
      return listByObject
        .sort((a, b) => new Date(this.$options.filters.objectToDateInput(a.startingAt)).getTime() / 1000 > new Date(this.$options.filters.objectToDateInput(b.startingAt)).getTime() / 1000 ? -1 : 1)
        .find(t => t.startingAt && new Date(this.$options.filters.objectToDateInput(t.startingAt)).getTime() / 1000 <= startOfDayTimestamp && (!t.endingAt || (new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 < 0 || new Date(this.$options.filters.objectToDateInput(t.endingAt)).getTime() / 1000 >= startOfDayTimestamp)))
    }
  }
}
</script>

<style lang="scss" scoped>
.color-box.condo_owner {
  background: #f589c7;
}

.color-box.tenancy {
  background: #00a4bd;
}
.color-box.condominium {
  background: #913489;
}

.color-box.object {
  background: #f38d38;
}
:deep(#autosuggest .autosuggest__results) {
  z-index: 9998;
}
.tooltip {
  z-index: 9999;
}
</style>
