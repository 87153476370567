<template>
  <coozzy-card :title="$t('message.settings.reporting.title')">
    <div
      v-if="loading"
      style="height: 30px">
      <coozzy-spinner />
    </div>
    <div v-else>
      <div class="col-12 mt-3">
        <b-table
          ref="reportingTable"
          class="m-0"
          bordered
          hover
          responsive="true"
          stacked="md"
          select-mode="single"
          :fields="fields"
          :items="listReporting"
          @row-clicked="onRowClicked">
          <!-- Headings -->
          <template #head(name)>
            {{ $t('message.generic.name') }}
          </template>
          <template #head(permittedUsers)>
            {{ $t('message.settings.reporting.addUsersModal.permittedUsers') }}
          </template>

          <!-- Data -->
          <template #cell(name)="data">
            {{ data.item.title }}
          </template>
          <template #cell(permittedUsers)="data">
            {{ data.item.allowedUserIds.length > 0 ? getAllowedUserNames(data.item.allowedUserIds) : '-' }}
          </template>
        </b-table>
      </div>
    </div>
    <b-modal
      v-if="showSelectAllowedUsers"
      :id="'selectAllowedUsersModal'"
      ref="selectAllowedUsersModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.settings.reporting.addUsersModal.title', { reportingName: nameSelectedReporting })"
      @close="hideSelectAllowedUsersModal">
      <template>
        <div class="col-12">
          <p class="mb-1">
            {{ $t('message.settings.reporting.addUsersModal.subtitle') }}
          </p>
        </div>
        <div class="col-12 mt-1 mb-3">
          <label for="selectPermittedUsers">
            {{ $t('message.settings.reporting.addUsersModal.permittedUsers') }}
            <coozzy-info-circle-icon
              v-b-tooltip.hover.html="$t('message.settings.reporting.addUsersModal.tooltipPermittedUsers')" />
          </label>
          <coozzy-multiselect
            id="selectPermittedUsers"
            v-model="selectEmployees"
            class="filter-inputs"
            :options="employeeFilteredList"
            :multiple="true"
            :placeholder="$tc('message.tableColumnHeadings.assignee')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabel"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false" />
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="hideSelectAllowedUsersModal">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="saveReporting">
            {{ $t('message.generic.form.save') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
  </coozzy-card>
</template>
<script lang="ts">
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import ReportingApi from '@/misc/apis/ReportingApi'
import { mapActions, mapGetters } from 'vuex'
import { user } from '@/mixins/user'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect.vue'
import Vue from 'vue'
import { ReportingReportingUrl, Userv2User } from '@/types/api'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'

interface optionSelect {
  label: string
  value: string
}

export default Vue.extend({
  name: 'ReportingSettings',
  components: {
    CoozzyInfoCircleIcon,
    CoozzyMultiselect,
    CoozzyButton,
    CoozzySpinner,
    CoozzyCard
  },
  mixins: [user],
  data() {
    return {
      loading: false as boolean,
      listReporting: [] as ReportingReportingUrl[],
      fields: [
        {
          key: 'name',
          label: 'name',
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'permittedUsers',
          label: 'permittedUsers',
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      showSelectAllowedUsers: false as boolean,
      nameSelectedReporting: '' as string,
      selectEmployees: [] as Userv2User[],
      reportingToEdit: null as ReportingReportingUrl
    }
  },
  computed: {
    employeeList(): Userv2User[] {
      if (this.getEmployees().length > 0) {
        return this.getEmployees().filter((x: Userv2User) => !x.blocked && !x.deleted && x.id !== this.userId && !x.roles.includes('company_admin'))
      }
      return []
    },
    employeeFilteredList(): optionSelect[] {
      if (this.employeeList.length > 0) {
        const list = [] as optionSelect[]
        this.employeeList.forEach((element: Userv2User) => {
            list.push({
              label: element.profile.firstName + ' ' + element.profile.lastName,
              value: element.id
            })
        })
        return list
      }
      return []
    }
  },
  mounted: function () {
    this.loading = true
    this.loadEmployees()
    this.loadReportings()
  },
  methods: {
    ...mapActions('employee', ['loadEmployees']),
    ...mapGetters('employee', ['getEmployees']),
    loadReportings() {
      ReportingApi.getMyReportings().then((response) => {
        this.listReporting = response.defaultReportings.concat(response.additionalReportings)
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },
    customLabel(option: optionSelect) {
      return `${option.label}`
    },
    onRowClicked(row: ReportingReportingUrl) {
      this.reportingToEdit = row
      this.showSelectAllowedUsers = true
      this.nameSelectedReporting = row.title
      this.selectEmployees = this.employeeFilteredList.filter((x: optionSelect) => row.allowedUserIds.includes(x.value))
      this.$nextTick(() => {
        this.$refs.selectAllowedUsersModal.show()
      })
    },
    hideSelectAllowedUsersModal() {
      this.$refs.selectAllowedUsersModal.hide()
      this.showSelectAllowedUsers = false
      this.nameSelectedReporting = ''
      this.selectEmployees = []
      this.reportingToEdit = null
    },
    getAllowedUserNames(allowedUserIds: string[]) {
      return this.employeeList.filter((x: Userv2User) => allowedUserIds.includes(x.id)).map((x: Userv2User) => x.profile.firstName + ' ' + x.profile.lastName).join(', ')
    },
    saveReporting() {
      this.loading = true
      ReportingApi.setReportingPermissions(this.accountId, this.selectEmployees.map((x: optionSelect) => x.value), this.reportingToEdit.url).then(() => {
        this.hideSelectAllowedUsersModal()
        this.loadReportings()
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    }
  }
})
</script>
