import InvestPlanApi from '@/misc/apis/InvestPlanApi'

const investmentPlanning = {
  methods: {
    async getBuildingEnabledInvestPlan(buildingIds: string[]) {
      try {
        const response = await InvestPlanApi.hasInvestPlanEnabled(buildingIds)
        const entries = response.entries
        let activateInvestPlan = true
        buildingIds.forEach((buildingId) => {
          const entry = entries.find((entry) => entry.buildingId === buildingId)
          if (entry.enabled === false) {
            activateInvestPlan = false
          }
        })
        return activateInvestPlan
      } catch {
        return false
      }
    }
  }
}
export { investmentPlanning }
