<template>
  <div>
    <template v-if="selectedObjectToProcess && selectedObjectToProcess.length > 0">
      <div
        v-for="(object, index) in selectedObjectToProcess"
        :key="object ? object.id : index"
        class="mb-1">
        <div
          v-b-toggle="`select-contract-${object ? object.id : index}`"
          class="collapse-title -mb-3">
          <h5 class="cursor-pointer">
            {{ $t('message.enterTermination.createDocumentFor') + ' ' + object.number + ' - ' + object.name }}
          </h5>
        </div>
        <b-collapse
          :id="`select-contract-${object ? object.id : index}`"
          :ref="`select-contract-${object ? object.id : index}`"
          :visible="true">
          <template-table
            :object-id="object.id"
            :by-owners-ids="templateOwnersIds(object)"
            :default-category="'TEMPLATE_CATEGORY_TERMINATION'"
            @selected-contract="selectedContract" />
          <div class="row">
            <div class="col">
              <coozzy-form-checkbox
                v-if="isEditor && hasDocumentsPortal && addDocumentToContact"
                v-model="showInPortal"
                class="float-right pr-3 mt-1 mr-0 display-none-xs font-weight-lighter">
                {{ $t('message.generic.showInPortal') }}
                <coozzy-info-circle-icon
                  v-if="!isPortalLinkEnabled"
                  v-b-tooltip.hover.html="$t('message.manageDocuments.tooltipShowInPortal')"
                  class="ml-1" />
              </coozzy-form-checkbox>
              <coozzy-form-checkbox
                v-model="addDocumentToContact"
                class="float-right pr-3 mt-1 mr-0 display-none-xs font-weight-lighter">
                {{ $t('message.generic.addDocumentToTenancy') }}
              </coozzy-form-checkbox>
            </div>
          </div>
        </b-collapse>
        <hr
          v-if="index != selectedObjectToProcess.length - 1"
          class="mt-0">
      </div>
    </template>
  </div>
</template>

<script>
import TemplateTable from '@/settings/components/TemplateTable'
import { user } from '@/mixins/user'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox.vue'

export default {
  name: 'EnterTerminationCreateDocument',
  components: {
    CoozzyFormCheckbox,
    CoozzyInfoCircleIcon,
    TemplateTable
  },
  mixins: [user],
  props: {
    isPortalLinkEnabled: {
      type: Boolean,
      required: true,
      default: false
    },
    selectedObjectToProcess: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      addDocumentToContact: false,
      showInPortal: false
    }
  },
  watch: {
    addDocumentToContact: function (newValue) {
      this.$emit('attach-tenancy', newValue)
    },
    showInPortal: function (newValue) {
      this.$emit('show-in-portal', newValue)
    }
  },
  methods: {
    selectedContract(value, contracts, objectId) {
      this.$emit('selected-contract', value, contracts, objectId)
    },
    templateOwnersIds(object) {
      return [].concat(parseInt(object.ownerId))
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
