<template>
  <section>
    <div
      v-if="!searchClient"
      class="text-center">
      <coozzy-spinner />
    </div>
    <ais-instant-search
      v-if="searchClient"
      ref="instant-search"
      :search-client="searchClient"
      :index-name="algoliaIndex"
      :routing="routing">
      <app-refresh
        ref="app-refresh"
        @refresh-done="refreshDone" />
      <ais-stats>
        <template #default="{ nbHits }">
          <coozzy-page-title
            :title="$t('message.propertiesView.title')"
            :count="nbHits"
            :count-text="$t('message.propertiesView.title')"
            :clear-filter="checkfilter"
            @clear-filter="clickOnClear" />
        </template>
      </ais-stats>

      <!-- Filter -->
      <div class="row">
        <div class="col-12 col-md mb-2 mb-md-0">
          <ais-search-box>
            <template #default="{ currentRefinement }">
              <debounced-algolia-search-box
                ref="debouncedAlgolia"
                :default-value="currentRefinement"
                :delay="300" />
            </template>
          </ais-search-box>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="type"
            :limit="100">
            <template #default="{items, refine}">
              {{ defaultRefining('searchType', items) }}
              <coozzy-multiselect
                v-model="searchType"
                :options="sortedFilterValues(items, customLabelType)"
                :multiple="true"
                :placeholder="$t('message.propertiesView.propertyType.title')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelType"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="address.zip"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchZip', items) }}
              <coozzy-multiselect
                v-model="searchZip"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.generic.zip')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="address.city"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchCity', items) }}
              <coozzy-multiselect
                v-model="searchCity"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.generic.city')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="buildingCount"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchBuildingCount', items) }}
              <coozzy-multiselect
                v-model="searchBuildingCount"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.propertiesView.filter.buildingCount')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="assignees.administration.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchAdminAssignee', items) }}
              <coozzy-assignee-multiselect
                v-model="searchAdminAssignee"
                :options="sortedFilterValues(items, customLabelAssignee)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.adminAssignee')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabelAssignee"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="selectValue($event.value, items, refine)"
                @remove="removeValue($event.value, items, refine)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-md-1 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="owner.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchOwner', items) }}
              <coozzy-multiselect
                v-model="searchOwner"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.owner')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-md-1 mb-2 mb-md-0">
          <ais-refinement-list
            attribute="networkPartnerAsset.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchAsset', items) }}
              <coozzy-multiselect
                v-model="searchAsset"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.tableColumnHeadings.asset')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
        <div class="col-12 col-md-2 mt-md-1">
          <ais-refinement-list
            attribute="networkPartnerAccounting.name"
            :limit="100">
            <template #default="{items, refine, searchForItems}">
              {{ defaultRefining('searchAccounting', items) }}
              <coozzy-multiselect
                v-model="searchAccounting"
                :options="sortedFilterValues(items, customLabel)"
                :multiple="true"
                :placeholder="$t('message.networkPartner.subTypes.accounting')"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="true"
                :custom-label="customLabel"
                :track-by="'value'"
                :preselect-first="false"
                :taggable="false"
                @search-change="searchForItems($event)"
                @select="refine($event.value)"
                @remove="refine($event.value)" />
            </template>
          </ais-refinement-list>
        </div>
      </div>
      <!-- eslint-disable vue/attribute-hyphenation -->
      <ais-configure
        :hitsPerPage="nbrPerPage"
        :filters="filterQuery" />
      <!-- eslint-enable vue/attribute-hyphenation -->
      <div
        id="adminProperty"
        class="row mt-2 align-items-center">
        <div class="col-md-2 col-sm-6 col-12">
          <coozzy-dropdown
            design="green"
            size="sm"
            class="w-100"
            :text="selectedEntriesText">
            <coozzy-dropdown
              v-if="isTicketingEnabled && (isEditor || isTicketEditor || isTicketCreator)"
              design="transparent"
              size="sm"
              class="w-100 ticket-dropdown-bulk-action"
              :class="selectedEntries.length > 0 ? 'enabled' : ''"
              :disabled="true"
              :text="$t('message.ticketOverview.createTicket')"
              dropright
              @click.prevent>
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="navigateToCreateOrder()">
                {{ $t('message.ticketOverview.createOrder') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                :disabled="selectedEntries.length === 0"
                @click="createTicket('TICKET_TYPE_TASK')">
                {{ $t('message.ticketOverview.createTask') }}
              </coozzy-dropdown-item>
            </coozzy-dropdown>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="showActivity()">
              {{ $t('message.contact.activity.create') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntries.length === 0"
              @click="$refs['reminder-creation'].show()">
              {{ $t('message.calendar.reminder.create') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-space-divider v-if="isEditor" />
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0"
              @click="openSetAssigneeModal(null)">
              {{ $t('message.marketingObjects.setAssignee') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-space-divider v-if="isEditor" />
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0 || !isSelectedPropertyHasBuildings"
              @click="callAction('addJanitors')">
              {{ $t('message.manageBuilding.bulkAction.addJanitors') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0 || !isSelectedPropertyHasBuildings"
              @click="callAction('addCraftsmen')">
              {{ $t('message.manageBuilding.bulkAction.addCraftsmen') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0 || !isSelectedPropertyHasBuildings"
              @click="callAction('addServiceProviders')">
              {{ $t('message.manageBuilding.bulkAction.addServiceProviders') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="isEditor && isSelectedPropertiesTypeCondominium"
              :disabled="selectedEntries.length === 0 || !isSelectedPropertyHasBuildings"
              @click="callAction('addDelegate')">
              {{ $t('message.manageBuilding.bulkAction.addDelegate') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-space-divider
              v-if="!isJanitor" />
            <coozzy-dropdown-item
              v-if="!isJanitor"
              :disabled="selectedEntries.length === 0"
              @click="redirectToExcelReport">
              {{ $t('message.generateExcel.title') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              v-if="!isJanitor"
              :disabled="selectedEntries.length === 0"
              @click="showCreateTenantReportModal">
              {{ $t('message.navigation.reporting.createTenantReport') }}
            </coozzy-dropdown-item>
            <!--            <coozzy-dropdown-space-divider v-if="isEditor" />-->
            <!--            <coozzy-dropdown-item-->
            <!--              :disabled="selectedEntries.length === 0 || isSelectedPropertiesTypeCondominium"-->
            <!--              @click="createMassRental">-->
            <!--              {{ $t('message.massRental.title') }}-->
            <!--            </coozzy-dropdown-item>-->
            <coozzy-dropdown-space-divider v-if="isEditor" />
            <coozzy-dropdown-item
              v-if="isEditor"
              :disabled="selectedEntries.length === 0 || isSelectedPropertiesAlreadyTransferred"
              @click="$bvModal.show('confirmationModalDeleteProperty')">
              {{ $t('message.propertiesView.deleteConfirmation.title') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2">
          <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :initial="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              name="selectAllEntries"
              button>
              {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: nbrTotalOfHits }) : $t('message.generic.selectHiddenEntries', { amount: nbrTotalOfHits }) }}
            </coozzy-form-checkbox>
          </template>
          <ais-clear-refinements :included-attributes="['query', 'address.zip', 'address.city', 'buildingCount', 'owner.name', 'networkPartnerAsset.name', 'type', 'networkPartnerAccounting.name', 'assignees.administration.name']">
            <template #default="{ canRefine, refine }">
              {{ setCanRefine(canRefine) }}
              <a
                v-if="canRefine"
                ref="clearClick"
                @click="initFilter(refine)" />
            </template>
          </ais-clear-refinements>
        </div>
        <div
          v-if="isCompanyAdmin === true"
          class="offset-md-5 col-md-1 d-flex justify-content-end align-items-center">
          <div
            @click="openAssembleListModal()">
            <coozzy-filters-icon
              v-b-tooltip="{container: '#rowConfig', title: $t('message.generic.tooltipAssembleList')}" />
          </div>
        </div>
        <div
          class="col-md-2 col-sm-6 col-12 mt-2 mt-sm-0"
          :class="!isCompanyAdmin ? 'offset-md-6' : ''">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries float-right">
            <option value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option value="50">
              50 {{ $t('message.generic.entries') }}
            </option>
            <option value="100">
              100 {{ $t('message.generic.entries') }}
            </option>
            <option value="500">
              500 {{ $t('message.generic.entries') }}
            </option>
            <option value="1000">
              1000 {{ $t('message.generic.entries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>

      <!-- Advertisements table -->
      <ais-state-results>
        <template #default="{ hits, results }">
          {{ getState(results) }}
          <b-table
            ref="PropertiesViewTable"
            class="mt-2"
            bordered
            hover
            responsive="true"
            stacked="md"
            selectable
            select-mode="single"
            :fields="fields"
            :items="hits"
            @row-clicked="onRowClicked"
            @row-middle-clicked="onMiddlelicked">
            <!-- Busy state -->
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>

            <!-- Headings -->
            <template #head(checkbox)>
              <coozzy-form-checkbox
                ref="header_checkbox"
                @change="(value) => { handleCheckboxesInsideTable(value) }" />
            </template>
            <template #head(thumbnail)>
              <span>{{ $t('message.tableColumnHeadings.thumbnail') }}</span>
            </template>
            <template #head(propertyName)>
              <span>{{ $t('message.tableColumnHeadings.propertyName') }}</span>
            </template>
            <template #head(propertiesId)>
              <span>{{ $t('message.tableColumnHeadings.propertiesId') }}</span>
            </template>
            <template #head(zipCity)>
              <span>{{ $t('message.tableColumnHeadings.zipCity') }}</span>
            </template>
            <template #head(transferredToAccounting)>
              <span>{{ $t('message.tableColumnHeadings.transferredToAccounting' ) }}</span>
            </template>
            <template #head(buildings)>
              <span>{{ $t('message.tableColumnHeadings.buildings') }}</span>
            </template>
            <template #head(grossTargetRentYear)>
              <span>{{ $t('message.tableColumnHeadings.grossTargetRentYear') }}</span>
            </template>
            <template #head(grossActualRentYear)>
              <span>{{ $t('message.tableColumnHeadings.grossActualRentYear') }}</span>
            </template>
            <template #head(netTargetRentYear)>
              <span>{{ $t('message.tableColumnHeadings.netTargetRentYear') }}</span>
            </template>
            <template #head(netActualRentYear)>
              <span>{{ $t('message.tableColumnHeadings.netActualRentYear') }}</span>
            </template>
            <template #head(partner)>
              {{ $t('message.tableColumnHeadings.partner') }}
            </template>
            <template #head(netOverallRentPerYear)>
              {{ $t('message.tableColumnHeadings.netOverallRentPerYear') }}
            </template>
            <template #head(grossOverallRentPerYear)>
              {{ $t('message.tableColumnHeadings.grossOverallRentPerYear') }}
            </template>
            <template #head(assignee)>
              {{ $t('message.tableColumnHeadings.assignee') }}
            </template>
            <!-- Data -->
            <template #cell(checkbox)="data">
              <coozzy-form-checkbox
                :id="'check_' + data.item.objectID"
                @change="(value) => { propertySelected(data.item, value, data.item.transferToAccounting) }" />
            </template>
            <template #cell(thumbnail)="data">
              <div
                v-if="data.item.imageUrl"
                class="d-flex justify-content-center">
                <coozzy-thumbnail
                  class="thumbnail"
                  :src="data.item.thumbnailSUrl"
                  :original="data.item.imageUrl" />
              </div>
              <div
                v-else
                class="d-flex justify-content-center">
                <coozzy-thumbnail
                  class="thumbnail"
                  src="/img/image-placeholder.png"
                  alt="Placeholder" />
              </div>
            </template>
            <template #cell(propertyName)="data">
              {{ data.item.name | displayOptionalValue }}
            </template>
            <template #cell(propertiesId)="data">
              {{ data.item.numericId | displayOptionalValue }}
            </template>
            <template #cell(zipCity)="data">
              <address-text
                :address="data.item.address"
                :one-line="true" />
            </template>
            <template #cell(transferredToAccounting)="data">
              <div class="text-center">
                <template v-if="data.item.transferToAccounting">
                  <coozzy-success-icon />
                </template>
                <template v-else>
                  <coozzy-fail-icon />
                </template>
              </div>
            </template>
            <template #cell(buildings)="data">
              <div class="text-center">
                {{ data.item.buildingCount }}
              </div>
            </template>
            <template #cell(grossTargetRentYear)="data">
              {{ data.item.actualGross | formatPrice }}
            </template>
            <template #cell(grossActualRentYear)="data">
              {{ data.item.grossOverallRentPerYear | formatPrice }}
            </template>
            <template #cell(netTargetRentYear)="data">
              {{ data.item.actualNet | formatPrice }}
            </template>
            <template #cell(netActualRentYear)="data">
              {{ data.item.netOverallRentPerYear | formatPrice }}
            </template>
            <template #cell(assignee)="data">
              <coozzy-button
                v-if="!isJanitor"
                design="default"
                size="small"
                @click="openSetAssigneeModal(data.item)">
                {{
                  data.item.assignees && data.item.assignees.administration && data.item.assignees.administration.name && data.item.assignees.administration.name !== 'Unassigned Nicht zugeordnet Non assegnato' ? data.item.assignees.administration.name : '-'
                }}
              </coozzy-button>
              <span v-else>
                {{
                  data.item.assignees && data.item.assignees.administration && data.item.assignees.administration.name && data.item.assignees.administration.name !== 'Unassigned Nicht zugeordnet Non assegnato' ? data.item.assignees.administration.name : '-'
                }}
              </span>
            </template>
            <template #cell(partner)="data">
              <span
                v-b-tooltip="{container: '#adminProperty', title: $t('message.generic.accountId') + data.item.owner.id}"
                class="d-block">
                <img
                  v-if="data.item.owner.name"
                  height="20px"
                  src="/img/propbase-icon.png"
                  alt="prop-base">
                {{ data.item.owner.name | displayOptionalValue }}
              </span>
              <span
                v-if="data.item.networkPartnerAccounting.name && data.item.networkPartnerAccounting.name !== 'Unassigned Nicht zugeordnet Non assegnato'"
                v-b-tooltip="{container: '#adminProperty', title: $t('message.generic.accountId') + data.item.networkPartnerAccounting.accountId}"
                class="d-block">
                <img
                  height="20px"
                  src="/img/financebase-icon.png"
                  alt="finance-base">
                {{ data.item.networkPartnerAccounting.name | displayOptionalValue }}
              </span>
              <span
                v-if="data.item.networkPartnerAsset && data.item.networkPartnerAsset.name !== 'Unassigned Nicht zugeordnet Non assegnato'"
                v-b-tooltip="{container: '#adminProperty', title: $t('message.generic.accountId') + data.item.networkPartnerAsset.accountId}"
                class="d-block">
                <img
                  height="20px"
                  src="/img/assetbase-icon.png"
                  alt="asset-base">
                {{ data.item.networkPartnerAsset.name | displayOptionalValue }}
              </span>
            </template>
            <template #cell(netOverallRentPerYear)="data">
              {{ data.item.targetNet | formatPrice }}
              <coozzy-info-circle-icon
                v-if="data.item.targetIncomplete"
                v-b-tooltip="{container: '#adminProperty', title: $t('message.propertiesView.noExactValue')}" />
            </template>
            <template #cell(grossOverallRentPerYear)="data">
              {{ data.item.targetGross | formatPrice }}
              <coozzy-info-circle-icon
                v-if="data.item.targetIncomplete"
                v-b-tooltip="{container: '#adminProperty', title: $t('message.propertiesView.noExactValue')}" />
            </template>
          </b-table>
        </template>
      </ais-state-results>
      <pagination-algolia
        ref="paginator"
        @changed-page="clearCheckbox()" />
    </ais-instant-search>
    <transfer-to-accounting-modal
      :ids="selectedEntriesIds"
      @transferred="transferred" />
    <reminder-creation-modal
      ref="reminder-creation"
      :references-ids="selectedEntriesIds"
      :source="'property'"
      @new-reminder="handleCheckboxesInsideTable(false)" />
    <b-modal
      :id="'confirmationModalDeleteProperty'"
      ref="confirmationModalDeleteProperty"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.propertiesView.deleteConfirmation.title')">
      <div
        v-if="loadingDeletion"
        class="row">
        <div class="col text-center">
          <coozzy-spinner class="m-3" />
        </div>
      </div>
      <template v-else>
        <div class="col p-0">
          <p>{{ $t('message.propertiesView.deleteConfirmation.text') }}</p>
        </div>
        <div class="col p-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('confirmationModalDeleteProperty')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deleteProperty()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <create-activity-modal
      ref="activity-creation"
      :key="keyActivity"
      :ids="selectedEntriesIds"
      @activity-created="clearCheckbox" />
    <create-tenant-report-modal
      v-if="showCreateTenantReport"
      ref="createTenantReportModal"
      :buildings-ids="selectedBuildingIds"
      @hide="hideCreateTenantReportModal" />
    <assemble-list-modal
      ref="assembleListModal"
      modal-id="assembleListModal"
      :fields="selectedFields"
      :default-fields="dataFields"
      @save-fields="saveFields" />
    <object-contacts-bulk-action
      v-if="selectedActions && selectedOwnerIds.length > 0"
      ref="objectContactsBulk"
      :title="selectedActions"
      :owner-ids="selectedOwnerIds"
      :building-ids="selectedBuildingIds"
      @action-done="clearArrays()" />
    <set-advert-relations
      v-if="contactList"
      :key="contactList.length"
      ref="setAssigneeModal"
      :title="$t('message.advertisementsOverview.setAssignee')"
      :element-list="contactList"
      type="employee"
      @save="setAssignee" />
  </section>
</template>

<script>
import { tables } from '@/mixins/tables'
import CoozzyFormCheckbox from '../framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyThumbnail from '../framework/components/img/CoozzyThumbnail'
import CoozzySpinner from '../framework/components/misc/CoozzySpinner'
import AddressText from '../framework/components/misc/AddressText'
import CoozzyDropdown from '../framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '../framework/components/dropdown/CoozzyDropdownItem'
import CoozzyInfoCircleIcon from '../framework/components/icons/CoozzyInfoCircleIcon'
import CoozzyPageTitle from '../framework/layout/CoozzyPageTitle'
import CreateActivityModal from '@/contact/components/CreateActivityModal'
import ReminderCreationModal from '@/calendar/components/ReminderCreationModal'
import AppRefresh from '../framework/components/RefreshAlgolia'
import DebouncedAlgoliaSearchBox from '../framework/components/DebouncedAlgoliaSearchBox'
import PaginationAlgolia from '../framework/components/PaginationAlgolia'
import { algolia } from '@/mixins/algolia'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyDropdownSpaceDivider from '@/framework/components/dropdown/CoozzyDropdownSpaceDivider'
import CoozzySuccessIcon from '@/framework/components/icons/CoozzySuccessIcon'
import CoozzyFailIcon from '@/framework/components/icons/CoozzyFailIcon'
import TransferToAccountingModal from '@/properties/TransferToAccountingModal'
import Vue from 'vue'
import { user } from '@/mixins/user'
import ObjectApi from '@/misc/apis/ObjectApi'
import CreateTenantReportModal from '@/reporting/CreateTenantReportModal.vue'
import { routeChecks } from '@/mixins/routeChecks'
import AssembleListModal from '@/contact/components/AssembleListModal.vue'
import SearchApi from '@/misc/apis/SearchApi'
import CoozzyFiltersIcon from '@/framework/components/icons/CoozzyFiltersIcon.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import ObjectContactsBulkAction from '@/owner/ObjectContactsBulkAction.vue'
import SetAdvertRelations from '@/marketingBasic/components/advertisements/management/SetAdvertRelations.vue'
import ContactApi from '@/misc/apis/ContactApi'
import CoozzyAssigneeMultiselect from '@/framework/components/multiselect/CoozzyAssigneeMultiselect.vue'

export default {
  name: 'AdminPropertyView',
  components: {
    CoozzyAssigneeMultiselect,
    SetAdvertRelations,
    ObjectContactsBulkAction,
    CoozzyButton,
    CoozzyFiltersIcon,
    AssembleListModal,
    TransferToAccountingModal,
    CoozzyFailIcon,
    CoozzySuccessIcon,
    CoozzyDropdownSpaceDivider,
    CoozzyFormSelect,
    CoozzyMultiselect,
    DebouncedAlgoliaSearchBox,
    AppRefresh,
    PaginationAlgolia,
    ReminderCreationModal,
    CoozzyPageTitle,
    CoozzyInfoCircleIcon,
    CoozzyDropdownItem,
    CoozzyDropdown,
    AddressText,
    CreateActivityModal,
    CoozzySpinner,
    CoozzyThumbnail,
    CoozzyFormCheckbox,
    CreateTenantReportModal
  },
  mixins: [algolia, tables, user, routeChecks],
  metaInfo() {
    return {
      title: this.$t('message.navigation.management.properties')
    }
  },
  data() {
    return {
      algoliaIndex: 'property',
      keyActivity: 0,
      showFirst: false,
      checkfilter: false,
      selectedEntries: [],
      selectedEntriesTransferred: [],
      currentPage: 1,
      galleryImages: [],
      galleryIndex: null,
      searchZip: [],
      searchType: [],
      searchBuildingCount: [],
      searchCity: [],
      searchOwner: [],
      searchAsset: [],
      searchAccounting: [],
      loadingDeletion: false,
      appRefreshAction: '',
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox align-middle',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        }
      ],
      dataFields: [
        {
          key: 'thumbnail',
          label: this.$t('message.tableColumnHeadings.thumbnail'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'propertyName',
          label: this.$t('message.tableColumnHeadings.propertyName'),
          thClass: 'align-middle',
          tdClass: 'align-middle border-left-0',
          selected: true
        },
        {
          key: 'propertiesId',
          label: this.$t('message.tableColumnHeadings.propertiesId'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center border-left-0',
          selected: true
        },
        {
          key: 'zipCity',
          label: this.$t('message.tableColumnHeadings.zipCity'),
          thClass: 'align-middle',
          tdClass: 'align-middle container-circle',
          selected: true
        },
        {
          key: 'transferredToAccounting',
          label: this.$t('message.tableColumnHeadings.transferredToAccounting'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'buildings',
          label: this.$t('message.tableColumnHeadings.buildings'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'grossTargetRentYear',
          label: this.$t('message.tableColumnHeadings.grossTargetRentYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'grossActualRentYear',
          label: this.$t('message.tableColumnHeadings.grossActualRentYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'grossOverallRentPerYear',
          label: this.$t('message.tableColumnHeadings.grossOverallRentPerYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'netTargetRentYear',
          label: this.$t('message.tableColumnHeadings.netTargetRentYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'netActualRentYear',
          label: this.$t('message.tableColumnHeadings.netActualRentYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'assignee',
          label: this.$t('message.tableColumnHeadings.assignee'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: true
        },
        {
          key: 'netOverallRentPerYear',
          label: this.$t('message.tableColumnHeadings.netOverallRentPerYear'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: true
        },
        {
          key: 'partner',
          label: this.$t('message.tableColumnHeadings.partner'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        }
      ],
      selectedFields: [],
      showCreateTenantReport: false,
      selectedActions: '',
      selectedOwnerIds: [],
      selectedBuildingIds: [],
      paramsAlgolia: {
        query: '',
        params: '',
        index: ''
      },
      nbrTotalOfHits: 0,
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: [],
      contactList: [],
      selectedPropertyIds: [],
      searchAdminAssignee: []
    }
  },
  computed: {
    clientList() {
      return (this.$store.getters['advert/getClients'] || []).filter(
        c => !c.deleted
      )
    },
    employeeList() {
      return (this.$store.getters['employee/getEmployees'] || []).filter(
        e => !e.deleted
      )
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntries.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.allEntries.map(x => x.objectID) : this.selectedEntries.map(obj => obj.objectID)
    },
    isSelectedPropertiesAlreadyTransferred() {
      return this.selectedEntriesTransferred.filter(entry => entry.transferred === true).length > 0
    },
    isSelectedPropertiesTypeCondominium() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.every(entry => entry.type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP')
    },
    filterQuery() {
      let query = ''

      if (!this.isPlatformEmployee) {
        query += `networkPartners.combinedId:${this.accountId.toString()}-management`
      }

      return query
    },
    isSelectedPropertyHasBuildings() {
      const listEntries = this.selectAllEntries ? this.allEntries : this.selectedEntries
      return listEntries.filter(entry => entry.buildingIds && entry.buildingIds.length > 0).length > 0
    }
  },
  watch: {
    checkfilter: function () {
      this.clearCheckbox()
    },
    nbrPerPage: function () {
      if (this.showFirst) {
        this.$nextTick(() => {
          if (this.$refs.paginator) {
            this.$refs.paginator.refresh()
          }
        })
      } else {
        this.$nextTick(() => {
          if (this.$refs.paginator) {
            this.$refs.paginator.redirect(this.$route.query.page)
          }
        })
      }
      this.showFirst = true
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    currentPage: function () {
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        // check if we already call algolia with the same params
        if (this.nbrTotalOfHits !== this.allEntries.length) {
          this.allEntries = await this.getAllAvailableDataByIndex(this.paramsAlgolia)
        }
        this.$nextTick(async () => {
          this.selectedOwnerIds = this.allEntries.map(prop => prop.owner.id)
          this.selectedBuildingIds = this.allEntries.flatMap(prop => prop.buildingIds)
        })
      } else {
        this.selectedOwnerIds = this.selectedEntries.map(prop => prop.owner.id)
        this.selectedBuildingIds = this.selectedEntries.flatMap(prop => prop.buildingIds)
      }
    }
  },
  mounted: function () {
    this.getContacts()
    this.loadInstantSearch()
    setTimeout(() => {
      this.$refs.debouncedAlgolia?.setFocusInput()
    }, 300)
    if (this.isJanitor) {
      this.dataFields = this.dataFields.filter(x => x.key !== 'transferredToAccounting' && x.key !== 'grossTargetRentYear' && x.key !== 'grossActualRentYear' && x.key !== 'netTargetRentYear' && x.key !== 'netActualRentYear')
    }
    this.dataFields = this.dataFields.filter(x => x.selected).concat(this.dataFields.filter(x => !x.selected))
    SearchApi.loadSettings(this.accountId).then(response => {
      const settings = response.tableSettings
      let hasOldList = false
      if (settings.length > 0) {
        settings.forEach(setting => {
          if (setting.columns.length > 0 && setting.table === 'property_overview') {
            hasOldList = true
            this.loadFields(setting)
          }
        })
      }
      if (hasOldList === false) {
        this.fields.push(...this.dataFields.filter(x => x.selected))
        this.selectedFields = JSON.parse(JSON.stringify(this.dataFields))
      }
    }).catch(e => {
      console.log(e)
    })
  },
  methods: {
    createMassRental() {
      const buildingIds = this.selectedEntries.flatMap(prop => prop.buildingIds)
      this.$router.push({ name: 'AdminCreateMassRentalView', query: { source: 'property', buildingIds: buildingIds.join() } })
    },
    openSetAssigneeModal(item) {
      this.$store.dispatch('user/setChangedModal', false).then().catch(e => console.log(e))
      if (item === null) {
        this.selectedPropertyIds = this.selectedEntriesIds
      } else {
        this.selectedPropertyIds = [item.objectID]
      }
      this.$refs.setAssigneeModal.newElementId =
        item && item.assignees && item.assignees.administration ? item.assignees.administration.contactId || null : null
      this.$refs.setAssigneeModal.setModalShow = true
    },

    setAssignee(newEmployee) {
      this.$refs.setAssigneeModal.setModalDisabled = true
      ObjectApi.setAssigneePropertyAdmin(newEmployee ? newEmployee.id : null, this.selectedPropertyIds)
        .then(() => {
          Vue.toasted.show(this.$t('message.successMessages.setMarketingObjectAssignee'), { type: 'success' })
          this.appRefreshAction = 'assignee'
          this.$refs['app-refresh'].refresh()
          this.$refs.setAssigneeModal.setModalShow = false
          this.$refs.setAssigneeModal.setModalDisabled = false
        })
        .catch(e => {
          console.log(e)
          this.$refs.setAssigneeModal.setModalDisabled = false
          Vue.toasted.show(this.$t('message.savingErrors.setMarketingObjectAssignee'), { type: 'error' })
        })
    },
    getContacts() {
      ContactApi.getContacts(this.$store.getters['user/getAccountId'])
        .then(response => {
          const allContacts = response.persons.concat(response.companies)
          this.contactList = allContacts.filter(con => con.types.includes('EMPLOYEE'))
          this.contactList = this.contactList.filter(contact => {
            const matchingEmployee = this.employeeList
              .filter(employee => !employee.blocked)
              .find(employee => employee?.email?.toLowerCase()?.trim() === contact?.email?.toLowerCase()?.trim())

            return matchingEmployee !== undefined && matchingEmployee !== null
          })
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.contacts'), { type: 'error' })
        }).finally(() => {
        setTimeout(() => {
          this.$refs.debouncedAlgolia?.setFocusInput()
        }, 300)
      })
    },
    selectValue(item, items, refine) {
      if (item === undefined) {
        if (this.searchAdminAssignee.length > 0) {
          this.searchAdminAssignee.map(a => a.value).forEach(element => {
            refine(element)
          })
        }
        const result = items.map(a => a.value)
        result.forEach(element => {
          refine(element)
        })
      } else {
        refine(item)
      }
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    removeValue(item, items, refine) {
      if (item === undefined) {
        const result = items.map(a => a.value)
        result.forEach(element => {
          refine(element)
          this.searchAdminAssignee.length = 0
        })
      } else {
        refine(item)
      }
      this.selectedEntries = []
      this.handleCheckboxesInsideTable(false)
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
    },
    getState(result) {
      this.nbrTotalOfHits = result.nbHits
      const params = this.editAlgoliaParams(result.params)
      this.paramsAlgolia = {
        query: result.query,
        params: params,
        index: result.index,
        attributesToRetrieve: ['objectID', 'owner', 'buildingIds']
      }
      return ''
    },
    callAction(action) {
      this.selectedActions = action
      this.$nextTick(function () {
        this.$refs.objectContactsBulk.show()
      })
    },
    clearArrays() {
      this.clearCheckbox()
      this.selectedActions = ''
      this.selectedOwnerIds = []
      this.selectedBuildingIds = []
    },
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting)
      this.fields = result?.fields
      this.selectedFields = result?.selectedFields
      this.$refs.PropertiesViewTable?.refresh()
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      SearchApi.saveSettings(this.accountId, columns, 'property_overview').then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal')
      })
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    hideCreateTenantReportModal() {
      this.showCreateTenantReport = false
      this.clearCheckbox()
    },
    showCreateTenantReportModal() {
      this.showCreateTenantReport = true
      this.$nextTick(() => {
        this.$refs.createTenantReportModal?.show()
      })
    },
    createTicket(type) {
      const defaultAssignee = this.selectedEntries.every((val, i, arr) => val.networkPartnerAsset?.accountId && arr[0].networkPartnerAsset?.accountId && val.networkPartnerAsset?.accountId === arr[0].networkPartnerAsset?.accountId)
        ? this.selectedEntries[0].networkPartnerAsset?.accountId
: this.selectedEntries.every((val, i, arr) => arr[0].networkPartnerAdministration?.accountId && val.networkPartnerAdministration?.accountId && val.networkPartnerAdministration?.accountId === arr[0].networkPartnerAdministration?.accountId)
          ? this.selectedEntries[0].networkPartnerAdministration?.accountId
: ''
      this.$router.push({ name: 'AdminTicketCreation', query: { fromBulk: true, ids: this.selectedEntriesIds, defaultAssignee: defaultAssignee, ticketType: type } })
    },
    navigateToCreateOrder() {
      const currentRoute = { name: this.$router.currentRoute.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      const propertyIds = this.selectedEntriesIds.join(',')
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerCreateOrderView', query: { propertyIds: propertyIds, source: 'property' } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminCreateOrderView', query: { propertyIds: propertyIds, source: 'property' } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetCreateOrderView', query: { propertyIds: propertyIds, source: 'property' } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingCreateOrderView', query: { propertyIds: propertyIds, source: 'property' } }, () => {})
      }
    },
    deleteProperty() {
      this.loadingDeletion = true
      ObjectApi.deleteProperty(this.selectedEntriesIds)
        .then(() => {
          setTimeout(() => {
            this.appRefreshAction = 'delete'
            this.$refs['app-refresh'].refresh()
          }, 2000)
        })
        .catch(e => {
          this.loadingDeletion = false
          this.$bvModal.hide('confirmationModalDeleteProperty')
          console.log(e)
          Vue.toasted.show(this.$t('message.deleteErrors.property'), { type: 'error' })
        })
    },
    clickOnClear() {
      this.$refs.clearClick.click()
    },
    setCanRefine(canRefine) {
      this.checkfilter = canRefine
      return ''
    },
    clearCheckbox() {
      this.selectedEntries = []
      this.selectedEntriesTransferred = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    onMiddlelicked(property) {
      const route = this.$router.resolve({
        name: 'AdminPropertyDetailsView',
        params: { id: property.objectID }
      })
      window.open(route.href)
    },
    onRowClicked(item, index, event) {
      if (item) {
        if (event.srcElement.classList.contains('custom-checkbox') || event.srcElement.classList.contains('checkbox-cell')) {
          this.propertySelected(item, !document.getElementById('check_' + item.objectID).checked, item.transferToAccounting)
          document.getElementById('check_' + item.objectID).checked = !document.getElementById('check_' + item.objectID).checked
        } else {
          this.propertyClicked(item, index, event)
        }
      }
    },
    clickSearch() {
      this.currentPage = 1
    },
    initFilter(refine) {
      this.searchZip = []
      this.searchCity = []
      this.searchBuildingCount = []
      this.searchOwner = []
      this.searchAsset = []
      this.searchAccounting = []
      this.searchType = []
      refine()
    },
    showActivity() {
      this.keyActivity = Math.random().toString(36).substr(2, 9)
      this.$nextTick(() => {
        this.$refs['activity-creation'].show()
      })
    },
    propertyClicked(property, index, event) {
      const element = event.srcElement
      if (element.tagName === 'IMG' && element.classList.contains('thumbnail')) {
        if (property.imageUrl && property.imageUrl !== '') {
          this.galleryImages = [
            property.imageUrl
          ]
          this.$nextTick(function () {
            this.$viewerApi({
              images: this.galleryImages,
              options: this.viewerOptions
            })
          })
        }
      } else {
        this.$router.push({
          name: 'AdminPropertyDetailsView',
          params: { id: property.objectID }
        })
      }
    },
    openConfirmationModal() {
      this.$refs['confirmation-modal'].show()
    },
    customLabel(item) {
      if (item.value === 'Unassigned Nicht zugeordnet Non assegnato') {
        return this.$t('message.generic.unassigned') + ' (' + item.count + ')'
      }
      return item.value + ' (' + item.count + ')'
    },
    customLabelType(item) {
      return this.$t(`message.propertiesView.propertyType.${item.value}`) + ' (' + item.count + ')'
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs.PropertiesViewTable) {
        const allCheckboxesComponents = this.$refs.PropertiesViewTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
        this.selectAllVisibleEntries = value
        if (value === false) {
          this.selectAllEntries = false
        }
      }
    },
    propertySelected(property, checked, transferred) {
      if (checked) {
        this.selectedEntries.push(property)
        this.selectedEntriesTransferred.push({ id: property.objectID, transferred: transferred })

        this.selectedOwnerIds.push(property.owner.id)
        this.selectedBuildingIds = this.selectedBuildingIds.concat(property.buildingIds)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(
          entry => entry.objectID !== property.objectID
        )
        this.selectedEntriesTransferred = this.selectedEntriesTransferred.filter(entry => entry.id !== property.objectID)

        const index = this.selectedOwnerIds.findIndex(x => x === property.owner.id)
        this.selectedOwnerIds.splice(index, 1)
        this.selectedBuildingIds = this.selectedBuildingIds.filter(function (obj) {
          return !this.has(obj)
        }, new Set(property.buildingIds.map(obj => obj)))
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    transferred() {
      setTimeout(() => {
        this.appRefreshAction = 'transferredToAccounting'
        this.$refs['app-refresh'].refresh()
      }, 1500)
    },
    refreshDone() {
      this.clearCheckbox()
      if (this.appRefreshAction === 'transferredToAccounting') {
        this.$bvModal.hide('transferPropertiesToAccountingModal')
        Vue.toasted.show(this.$t('message.successMessages.transferPropertiesToAccounting'), { type: 'success' })
      } else if (this.appRefreshAction === 'delete') {
        this.$bvModal.hide('confirmationModalDeleteProperty')
        this.loadingDeletion = false
        Vue.toasted.show(this.$t('message.successMessages.propertyDeletion'), { type: 'success' })
      }
    },
    redirectToExcelReport() {
      let source = ''
      if (this.$route.fullPath.includes('view=objects')) {
        source = 'objects'
      } else if (this.$route.fullPath.includes('view=buildings')) {
        source = 'buildings'
      } else {
        source = 'properties'
      }
      localStorage.setItem('generateExcel-page', source)
      this.$router.push({ name: 'AdminGenerateExcel', params: { propertyIds: this.selectedEntriesIds } })
    }
  }
}
</script>

<style lang="scss" scoped>
.thumbnail {
  max-width: 100px;
}

:deep(th button) {
  padding-right: 0 !important;
}

:deep(.span-dropdown) {
  padding: 0 !important;
}
</style>
