<template>
  <section>
    <coozzy-card class="global-card no-top-border table-card">
      <!-- Heading -->
      <div class="row justify-content-between align-items-center">
        <div class="col-md-8">
          <h5>
            {{ componentsCount }} {{ $t('message.onBoarding.investmentPlanning.components') }}
          </h5>
        </div>
        <div
          v-if="isFiltered"
          class="col-12 col-md-2">
          <coozzy-button
            class="w-100 sort"
            @click="initFilter()">
            <coozzy-filter-icon /> {{ $t('message.generic.clearFilters') }}
          </coozzy-button>
        </div>
        <div
          v-if="isEditor"
          class="col-12 col-md-2">
          <coozzy-button
            design="prop-green"
            class="w-100 sort"
            @click="readOnly ? goToEditComponent(true) : showCreateComponentModalClicked()">
            {{ $t('message.onBoarding.investmentPlanning.addComponent') }}
          </coozzy-button>
        </div>
      </div>
      <div class="row align-items-center my-2">
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-form-input
            ref="searchText"
            v-model="searchText"
            class="allowClickOnInputInTable mb-2"
            :placeholder="$t('message.generic.search')"
            @keyup="searchInputText()" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchCategories"
            :clear-on-select="false"
            :close-on-select="false"
            :custom-label="customLabelCategory"
            :multiple="true"
            :options="categoryFilter"
            :placeholder="$t('message.onBoarding.devices.filter.category')"
            :preselect-first="false"
            :preserve-search="true"
            :taggable="false"
            :track-by="'value'"
            class="filter-inputs"
            @remove="filterComponentsList"
            @select="filterComponentsList" />
        </div>
      </div>
      <div
        class="row mb-2">
        <div class="col-12 col-md-2 mt-2 mb-md-0">
          <label
            for="propertyValue"
            class="font-weight-normal">{{ $t('message.onBoarding.investmentPlanning.buildingValue') }}</label>
          <coozzy-form-input-numeric
            id="buildingValue"
            ref="buildingValue"
            v-model="localBuildingValue"
            type="number"
            class="mb-2"
            :disabled="readOnly"
            :filter="'formatPrice'"
            :is-decimal="true"
            :placeholder="$t('message.onBoarding.investmentPlanning.buildingValue')"
            @blur="calculateAllCosts()" />
        </div>
        <div class="col-12 col-md-2 mt-2 mb-md-0">
          <label
            for="propertyValue"
            class="font-weight-normal">{{ $t('message.onBoarding.investmentPlanning.totalPercentage') }}</label>
          <coozzy-form-input
            id="totalPercentageComponents"
            ref="totalPercentageComponents"
            v-model="totalPercentageComponents"
            class="mb-2"
            type="number"
            :min="0"
            :disabled="true"
            :state="totalPercentageComponents > 100 ? false : null"
            :placeholder="$t('message.onBoarding.investmentPlanning.totalPercentage')" />
        </div>
      </div>
      <div class="row align-items-center mb-2">
        <div class="col-md-2 col-sm-6 col-12">
          <coozzy-dropdown
            :text="selectedEntriesText"
            class="w-100"
            design="green"
            size="sm">
            <coozzy-dropdown-item
              :disabled="selectedEntriesGlobal.length === 0"
              @click="bulkEditComponents('installationDate')">
              {{ $t('message.onBoarding.investmentPlanning.actions.enterInstallationDate') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntriesGlobal.length === 0"
              @click="bulkEditComponents('costs')">
              {{ $t('message.onBoarding.investmentPlanning.actions.enterCostComponent') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntriesGlobal.length === 0"
              @click="bulkEditComponents('percentage')">
              {{ $t('message.onBoarding.investmentPlanning.actions.enterDistributionPercent') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntriesGlobal.length === 0"
              @click="bulkEditComponents('measures')">
              {{ $t('message.onBoarding.investmentPlanning.actions.enterMeasures') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntriesGlobal.length === 0"
              @click="bulkEditComponents('plannedReplacement')">
              {{ $t('message.onBoarding.investmentPlanning.actions.enterPlannedReplacement') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntriesGlobal.length === 0"
              @click="bulkEditComponents('futureCostFactor')">
              {{ $t('message.onBoarding.investmentPlanning.actions.enterFutureCostFactor') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntriesGlobal.length === 0"
              @click="bulkEditComponents('plannedCosts')">
              {{ $t('message.onBoarding.investmentPlanning.actions.enterPlannedCosts') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown-item
              :disabled="selectedEntriesGlobal.length === 0"
              @click="deleteComponentsClicked">
              {{ $t('message.generic.delete') }}
            </coozzy-dropdown-item>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2">
          <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :initial="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              name="selectAllEntries"
              button>
              {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: nbrTotalOfHits }) : $t('message.generic.selectHiddenEntries', { amount: nbrTotalOfHits }) }}
            </coozzy-form-checkbox>
          </template>
        </div>
        <div
          v-if="isCompanyAdmin === true"
          class="offset-md-5 col-md-1 d-flex justify-content-end align-items-center">
          <div
            @click="openAssembleListModal()">
            <coozzy-filters-icon
              v-b-tooltip="{container: '#rowConfig', title: $t('message.generic.tooltipAssembleList')}" />
          </div>
        </div>
        <div class="col-md-2 col-sm-6 col-12 mt-2 mt-sm-0">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries">
            <option value="5">
              5 {{ $t('message.generic.entries') }}
            </option>
            <option value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option value="15">
              15 {{ $t('message.generic.entries') }}
            </option>
            <option value="20">
              20 {{ $t('message.generic.entries') }}
            </option>
            <option value="all">
              {{ $t('message.manageInterestedParties.filter.allEntries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <div
        v-if="isBusy && listComponents.length === 0"
        class="text-center">
        <coozzy-spinner />
      </div>
      <b-table
        v-else
        id="componentsTable"
        ref="componentsTable"
        bordered
        hover
        responsive="true"
        stacked="md"
        select-mode="single"
        class="overflow-auto shadow"
        :fields="fields"
        :items="componentsList"
        :tbody-tr-class="rowClass">
        <template
          #head(checkbox)>
          <coozzy-form-checkbox
            ref="header_checkbox"
            @change="(value) => { handleCheckboxesInsideTable(value) }" />
        </template>
        <template #head(description)>
          {{ $t('message.tableColumnHeadings.description') }}
        </template>
        <template #head(bkp)>
          {{ $t('message.tableColumnHeadings.bkp') }}
        </template>
        <template #head(component)>
          {{ $t('message.tableColumnHeadings.component') }}
        </template>
        <template #head(installation)>
          {{ $t('message.tableColumnHeadings.installation') }}
        </template>
        <template #head(lifeSpan)>
          {{ $t('message.tableColumnHeadings.lifeSpan') }}
        </template>
        <template #head(calculatedReplacement)>
          {{ $t('message.tableColumnHeadings.calculatedReplacement') }}
        </template>
        <template #head(costs)>
          {{ $t('message.tableColumnHeadings.costs') }}
        </template>
        <template #head(percentage)>
          %
        </template>
        <template #head(measures)>
          {{ $t('message.tableColumnHeadings.measures') }}
        </template>
        <template #head(plannedReplacement)>
          {{ $t('message.tableColumnHeadings.plannedReplacement') }}
        </template>
        <template #head(futureCostFactor)>
          {{ $t('message.tableColumnHeadings.futureCostFactor') }}
        </template>
        <template #head(plannedCosts)>
          {{ $t('message.tableColumnHeadings.plannedCosts') }}
        </template>
        <!-- Body -->
        <template
          #cell(checkbox)="data">
          <coozzy-form-checkbox
            :id="'check_' + data.item.id"
            :key="'checkbox_' + data.item.id"
            class="selectComponentCheckbox"
            @change="(value) => { itemSelectedGlobal(data.item, value) }" />
        </template>
        <template #cell(description)="data">
          <template v-if="data.item.referenceId.includes('building_')">
            {{ $t('message.onBoardingTabs.building') }} {{ building.name }}
          </template>
          <template v-if="data.item.referenceId.includes('obj_')">
            {{ $t('message.onBoarding.buildings.objects.titleObject') }} {{ data.item.referenceId ? getObjectName(data.item.referenceId) : '' }}
          </template>
        </template>
        <template #cell(bkp)="data">
          <coozzy-form-input
            :id="'bkpH_' + data.item.id"
            v-model="data.item.bkp"
            :is-read-only="readOnly"
            :allow-click-input-on-table="true"
            @input="emitChanges(data.item)" />
        </template>
        <template #cell(component)="data">
          <coozzy-form-input
            :id="'type_' + data.item.id"
            v-model="data.item.name"
            :is-read-only="readOnly"
            :allow-click-input-on-table="true"
            @input="emitChanges(data.item)" />
        </template>
        <template #cell(installation)="data">
          <coozzy-form-input
            :id="'installation_' + data.item.id"
            v-model="data.item.installationDateString"
            :is-read-only="readOnly"
            :filter="'formatDate'"
            type="date"
            :allow-click-input-on-table="true"
            @blur="dateToObject(data.item)" />
        </template>
        <template #cell(lifeSpan)="data">
          <coozzy-form-input
            :id="'lifeSpan_' + data.item.id"
            v-model="data.item.lifeSpan"
            :is-read-only="readOnly"
            type="number"
            :min="0"
            :allow-click-input-on-table="true"
            @input="calculateReplacementDate(data.item)" />
        </template>
        <template #cell(calculatedReplacement)="data">
          <coozzy-form-input
            :id="'calculatedReplacement_' + data.item.id"
            v-model="data.item.calculatedReplacement"
            :is-read-only="readOnly"
            type="number"
            :min="getYear(data.item.installationDateString)"
            :allow-click-input-on-table="true"
            @input="calculateLifeSpan(data.item)" />
        </template>
        <template #cell(costs)="data">
          <coozzy-form-input-numeric
            :id="'costs_' + data.item.id"
            v-model="data.item.costs"
            type="number"
            :is-read-only="readOnly"
            :filter="'formatPrice'"
            placeholder="CHF"
            :is-decimal="true"
            :allow-click-input-on-table="true"
            @input="calculateFieldsRelatedToCost(data.item)" />
        </template>
        <template #cell(percentage)="data">
          <coozzy-form-input
            :id="'percentage_' + data.item.id"
            v-model="data.item.percentage"
            :is-read-only="readOnly"
            type="number"
            :allow-click-input-on-table="true"
            @input="calculateCost(data.item)" />
        </template>
        <template #cell(measures)="data">
          <coozzy-form-input
            :id="'measures_' + data.item.id"
            v-model="data.item.note"
            :is-read-only="readOnly"
            :allow-click-input-on-table="true"
            @input="emitChanges(data.item)" />
        </template>
        <template #cell(plannedReplacement)="data">
          <coozzy-form-input
            :id="'plannedReplacement_' + data.item.id"
            v-model="data.item.plannedReplacementDateString"
            :is-read-only="readOnly"
            :filter="'formatDate'"
            type="date"
            :allow-click-input-on-table="true"
            @blur="dateToObject(data.item)" />
        </template>
        <template #cell(futureCostFactor)="data">
          <coozzy-form-input-numeric
            :id="'futureCostFactor_' + data.item.id"
            v-model="data.item.futurePercentage"
            :is-read-only="readOnly"
            type="number"
            filter="formatNumber"
            :min="0"
            :is-decimal="true"
            :allow-click-input-on-table="true"
            @input="calculatePlannedCost(data.item)" />
        </template>
        <template #cell(plannedCosts)="data">
          <coozzy-form-input-numeric
            :id="'plannedCosts_' + data.item.id"
            v-model="data.item.plannedCosts"
            type="number"
            :is-read-only="readOnly"
            :filter="'formatPrice'"
            placeholder="CHF"
            :is-decimal="true"
            :allow-click-input-on-table="true"
            @input="calculateFutureCostFactor(data.item)" />
        </template>
      </b-table>
      <b-pagination
        v-if="nbrPerPage !== 'all'"
        v-model="currentPage"
        :per-page="nbrPerPage"
        :total-rows="listComponents.length"
        align="center"
        class="mb-3" />
    </coozzy-card>
    <b-modal
      v-if="showCreateComponentModal"
      :id="'createComponentInvestmentModal'"
      ref="createComponentInvestmentModal"
      :title="$t('message.onBoarding.investmentPlanning.addComponent')"
      hide-footer
      no-close-on-backdrop>
      <template>
        <div class="col p-0">
          <b-table
            id="table-groups"
            ref="table-groups"
            :busy="loadingAddComponent"
            :fields="fieldsAddComponent"
            :items="listBuildObj"
            bordered
            class="mb-4"
            hover
            responsive="true"
            stacked="md">
            <!-- Busy state -->
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>
            <template #head(category)>
              {{ $t('message.advertisement.objectCategory.name') }}
            </template>
            <template #head(name)>
              {{ $t('message.onBoarding.devices.description') }}
            </template>
            <!-- Body -->
            <template #cell(checkbox)="data">
              <coozzy-form-checkbox
                :id="data.item.id"
                @change="(value) => { itemSelected(data.item, value) }" />
            </template>
            <template #cell(category)="data">
              <template v-if="data.item.id.startsWith('building_')">
                {{ $t('message.generic.building') }}
              </template>
              <template v-else-if="data.item.category && data.item.category !== 'UNDEFINED_CATEGORY'">
                {{ $t('message.onBoarding.buildings.objects.category.' + data.item.category.toLowerCase()) }}
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template #cell(name)="data">
              {{ data.item.name }}
            </template>
          </b-table>
        </div>
        <div class="col p-0">
          <coozzy-button
            class="mb-0 border"
            design="transparent"
            size="small"
            @click="hideCreateComponentModal">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            :disabled="selectedEntries.length < 1"
            class="float-right mb-0"
            design="green"
            size="small"
            @click="createComponentClicked()">
            {{ $t('message.generic.add') }}
          </coozzy-button>
          <div class="d-flex float-right mr-3 mt-1">
            <coozzy-form-checkbox
              v-model="optionalValueEnhancingComponent"
              :initial="optionalValueEnhancingComponent"
              class="pr-0 mr-0"
              name="optionalValueEnhancingComponent" />
            <span class="align-self-center">
              {{ $t('message.onBoarding.investmentPlanning.optionalValueEnhancingComponent') }}
              <coozzy-info-circle-icon
                v-b-tooltip.hover.html="$t('message.onBoarding.investmentPlanning.tooltipOptionalValue')" />
            </span>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-if="showDeleteModal"
      :id="'confirmationModalDeleteComponents'"
      ref="confirmationModalDeleteComponents"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.investmentPlanning.deleteModal.title')"
      @close="hideDeleteComponentModal">
      <template>
        <div class="col p-0">
          <p>{{ $t('message.onBoarding.investmentPlanning.deleteModal.text') }}</p>
        </div>
        <div class="col p-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="hideDeleteComponentModal">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="deleteComponent()">
            {{ $t('message.generic.delete') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      v-if="showEditModal"
      :id="'editComponentsModal'"
      ref="editComponentsModal"
      no-close-on-backdrop
      hide-footer
      :title="titleEditModal"
      @close="hideEditComponentsModal">
      <template>
        <div
          v-if="editAction === 'installationDate'"
          class="col p-0">
          <p>{{ $t('message.onBoarding.investmentPlanning.subTitlesEditModal.enterInstallationDate') }}</p>
          <coozzy-form-input
            v-model="newInstallationDate"
            type="date" />
        </div>
        <div
          v-if="editAction === 'costs'"
          class="col p-0">
          <p>{{ $t('message.onBoarding.investmentPlanning.subTitlesEditModal.enterCostComponent') }}</p>
          <coozzy-form-input
            v-model="newCostComponent"
            type="number" />
        </div>
        <div
          v-if="editAction === 'percentage'"
          class="col p-0">
          <p>{{ $t('message.onBoarding.investmentPlanning.subTitlesEditModal.enterDistributionPercent') }}</p>
          <coozzy-form-input
            v-model="newPercentage"
            type="number" />
        </div>
        <div
          v-if="editAction === 'measures'"
          class="col p-0">
          <p>{{ $t('message.onBoarding.investmentPlanning.subTitlesEditModal.enterMeasures') }}</p>
          <coozzy-form-input
            v-model="newMeasures" />
        </div>
        <div
          v-if="editAction === 'plannedReplacement'"
          class="col p-0">
          <p>{{ $t('message.onBoarding.investmentPlanning.subTitlesEditModal.enterPlannedReplacement') }}</p>
          <coozzy-form-input
            v-model="newPlannedReplacementDate"
            type="date" />
        </div>
        <div
          v-if="editAction === 'futureCostFactor'"
          class="col p-0">
          <p>{{ $t('message.onBoarding.investmentPlanning.subTitlesEditModal.enterFutureCostFactor') }}</p>
          <coozzy-form-input-numeric
            v-model="newFuturePercentage"
            type="number"
            filter="formatNumber"
            :min="0"
            :is-decimal="true" />
        </div>
        <div
          v-if="editAction === 'plannedCosts'"
          class="col p-0">
          <p>{{ $t('message.onBoarding.investmentPlanning.subTitlesEditModal.enterPlannedCosts') }}</p>
          <coozzy-form-input-numeric
            v-model="newPlannedCosts"
            type="number"
            filter="formatNumber"
            :min="0"
            :is-decimal="true" />
        </div>
        <div class="col p-0 mt-3">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="hideEditComponentsModal">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="applyEditChanges()">
            {{ $t('message.generic.form.applyAdd') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <assemble-list-modal
      ref="assembleListModal"
      modal-id="assembleListModal"
      :fields="selectedFields"
      :default-fields="dataFields"
      @save-fields="saveFields" />
  </section>
</template>

<script>
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import { routeChecks } from '@/mixins/routeChecks'
import { tables } from '@/mixins/tables'
import { user } from '@/mixins/user'
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import debounce from 'debounce'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem.vue'
import { componentUtils } from '@/mixins/componentUtils'
import { dateUtils } from '@/mixins/dateUtils'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzyFilterIcon from '@/framework/components/icons/CoozzyFilterIcon.vue'
import { helper } from '@/types/helper'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown.vue'
import { onboarding } from '@/mixins/onboarding'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect.vue'
import CoozzyFormInputNumeric from '@/framework/components/form/input/CoozzyFormInputNumeric.vue'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'
import Vue from 'vue'
import InvestPlanApi from '@/misc/apis/InvestPlanApi'
import CoozzyFiltersIcon from '@/framework/components/icons/CoozzyFiltersIcon.vue'
import AssembleListModal from '@/contact/components/AssembleListModal.vue'
import SearchApi from '@/misc/apis/SearchApi'

export default {
  name: 'BuildingInvestPlanTab',
  components: {
    AssembleListModal,
    CoozzyFiltersIcon,
    CoozzyInfoCircleIcon,
    CoozzyFormInputNumeric,
    CoozzyMultiselect,
    CoozzyDropdown,
    CoozzyFilterIcon,
    CoozzyButton,
    CoozzyDropdownItem,
    CoozzySpinner,
    CoozzyFormInput,
    CoozzyCard,
    CoozzyFormSelect,
    CoozzyFormCheckbox
  },
  mixins: [routeChecks, tables, user, componentUtils, dateUtils, helper, onboarding],
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    addNewComponent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isBusy: true,
      selectedEntries: [],
      nbrPerPage: 5,
      currentPage: 1,
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        }
      ],
      dataFields: [
        {
          key: 'description',
          label: this.$t('message.tableColumnHeadings.description'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'bkp',
          label: this.$t('message.tableColumnHeadings.bkp'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'component',
          label: this.$t('message.tableColumnHeadings.component'),
          thClass: 'align-middle width-15',
          tdClass: 'align-middle width-15',
          selected: true
        },
        {
          key: 'installation',
          label: this.$t('message.tableColumnHeadings.installation'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'lifeSpan',
          label: this.$t('message.tableColumnHeadings.lifeSpan'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'calculatedReplacement',
          label: this.$t('message.tableColumnHeadings.calculatedReplacement'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'costs',
          label: this.$t('message.tableColumnHeadings.costs'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'percentage',
          label: this.$t('message.tableColumnHeadings.percentage'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'measures',
          label: this.$t('message.tableColumnHeadings.measures'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'plannedReplacement',
          label: this.$t('message.tableColumnHeadings.plannedReplacement'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'futureCostFactor',
          label: this.$t('message.tableColumnHeadings.measures'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'plannedCosts',
          label: this.$t('message.tableColumnHeadings.measures'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        }
      ],
      selectedFields: [],
      selectedEntriesGlobal: [],
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      searchText: '',
      debouncedFilterComponentsList: null,
      closed: false,
      typeSuggestInputProps: {
        id: 'autosuggest__input',
        placeholder: this.$t('message.onBoarding.devices.searchType'),
        class: 'custom-select allowClickInputOnTable form-control'
      },
      objectsList: [],
      fieldsAddComponent: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'category',
          label: this.$t('message.advertisement.objectCategory.name'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'name',
          label: this.$t('message.onBoarding.devices.description'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      loadingAddComponent: false,
      showCreateComponentModal: false,
      allComponents: [],
      filteredSuggestions: [],
      defaultComponents: [],
      shouldShowOnTop: false,
      listSharedObjects: [],
      showDeleteModal: false,
      showEditModal: false,
      editAction: '',
      newInstallationDate: '',
      newCostComponent: 0,
      newPercentage: 0,
      newMeasures: '',
      listComponents: [],
      searchCategories: [],
      listColoredItems: [],
      optionalValueEnhancingComponent: false,
      newPlannedReplacementDate: '',
      newFuturePercentage: '',
      newPlannedCosts: ''
    }
  },
  computed: {
    titleEditModal() {
      switch (this.editAction) {
        case 'installationDate':
          return this.$t('message.onBoarding.investmentPlanning.titlesEditModal.titleInstallationDate')
        case 'costs':
          return this.$t('message.onBoarding.investmentPlanning.titlesEditModal.titleCostComponent')
        case 'percentage':
          return this.$t('message.onBoarding.investmentPlanning.titlesEditModal.titleDistributionPercent')
        case 'measures':
          return this.$t('message.onBoarding.investmentPlanning.titlesEditModal.titleMeasures')
        case 'plannedReplacement':
          return this.$t('message.onBoarding.investmentPlanning.titlesEditModal.titlePlannedReplacement')
        case 'futureCostFactor':
          return this.$t('message.onBoarding.investmentPlanning.titlesEditModal.titleFutureCostFactor')
        case 'plannedCosts':
          return this.$t('message.onBoarding.investmentPlanning.titlesEditModal.titlePlannedCosts')
        default:
          return ''
      }
    },
    localBuildingValue: {
      get() {
        return this.building.buildingValue ? this.building.buildingValue : 0
      },
      set(value) {
        this.$store.dispatch('onboarding/updateBuildingField',
          { field: 'buildingValue', value: value })
      }
    },
    listBuildObj() {
      const list = this.building ? [this.building] : []
      return list.concat(this.objectsList)
    },
    componentsCount() {
      return this.listComponents !== null ? this.listComponents.length : 0
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntriesGlobal.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    componentsList: {
      get() {
        let list = []
        if (this.listComponents && this.listComponents.length > 0) {
          list = this.listComponents.map(component => {
            return {
              ...component,
              installationDateString: this.$options.filters.objectToDateInput(component.installationDate),
              plannedReplacementDateString: this.$options.filters.objectToDateInput(component.plannedReplacementDate)
            }
          })
        }
        if (this.nbrPerPage !== 'all') {
          list = list.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
        }
        return list
      },
      set(value) {
        this.listComponents = value
      }
    },
    isFiltered() {
      return this.searchText !== ''
    },
    categoryFilter() {
      const counts = []
      if (this.allComponents.length === 0) {
        return counts
      }
      counts.push({
        count: this.allComponents.filter(x => x.referenceId.includes('building_')).length,
        value: 'building'
      })
      const current = this
      this.allComponents.map(obj => this.findObjectById(obj.referenceId) && this.findObjectById(obj.referenceId).category).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== 'UNDEFINED_CATEGORY' && x) {
          const sameNetwork = current.allComponents.map(obj => current.findObjectById(obj.referenceId) && current.findObjectById(obj.referenceId).category).filter(z => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    },
    nbrTotalOfHits() {
      return this.listComponents.length
    },
    selectedEntriesIds() {
      return this.selectAllEntries ? this.listComponents.map(x => x.id || x.internalId) : this.selectedEntriesGlobal.map(obj => obj.id || obj.internalId)
    },
    totalPercentageComponents() {
      const total = this.listComponents.reduce((acc, component) => {
        if (component.optionalValueEnhancingComponent) {
          return acc
        }
        return acc + (component.percentage ? parseFloat(component.percentage) : 0)
      }, 0)
      return Math.round(total)
    }
  },
  watch: {
    currentPage: function () {
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    nbrPerPage: function (newVal) {
      localStorage.setItem('investmentPlanningTab-nbrPerPage', newVal)
    },
    components: {
      handler: function (newVal) {
        if (newVal.length > 0) {
          this.listComponents = newVal
          this.allComponents = newVal
          this.listComponents.forEach(component => {
            component.installationDateString = this.$options.filters.objectToDateInput(component.installationDate)
            component.plannedReplacementDateString = this.$options.filters.objectToDateInput(component.plannedReplacementDate)
            component.calculatedReplacement = this.fillOutCalculatedReplacement(component)
            if (component.plannedCosts === 0) {
              component.plannedCosts = component.costs
            }
            if (component.installationDate) {
              component.lifeSpan = this.fillOutLifeSpan(component)
            }
          })
        }
        this.getListColoredItems()
        this.isBusy = false
      },
      deep: true
    }
  },
  created() {
    // Initialize the debounced function in the created lifecycle hook
    this.debouncedFilterComponentsList = debounce(() => {
      this.filterComponentsList()
    }, 300)
  },
  mounted: function () {
    this.loadData()
    if (this.components.length === 0) {
      this.getDefaultComponents()
    } else {
      this.listComponents = this.components
      this.listComponents.forEach(component => {
        component.installationDateString = this.$options.filters.objectToDateInput(component.installationDate)
        component.plannedReplacementDateString = this.$options.filters.objectToDateInput(component.plannedReplacementDate)
        component.calculatedReplacement = this.fillOutCalculatedReplacement(component)
        if (component.plannedCosts === 0) {
          component.plannedCosts = component.costs
        }
        if (component.installationDate) {
          component.lifeSpan = this.fillOutLifeSpan(component)
        }
      })
      this.allComponents = JSON.parse(JSON.stringify(this.listComponents))
    }
    this.nbrPerPage = localStorage.getItem('investmentPlanningTab-nbrPerPage') ? localStorage.getItem('investmentPlanningTab-nbrPerPage') === 'all' ? 'all' : parseInt(localStorage.getItem('investmentPlanningTab-nbrPerPage')) : 5
    if (this.addNewComponent && !this.readOnly) {
      this.showCreateComponentModalClicked()
    }
    this.getListColoredItems()
    SearchApi.loadSettings(this.accountId).then(response => {
      const settings = response.tableSettings
      let hasOldList = false
      if (settings.length > 0) {
        settings.forEach(setting => {
          if (setting.columns.length > 0 && setting.table === 'investmentPlanningBuilding') {
            hasOldList = true
            this.loadFields(setting)
          }
        })
      }
      if (hasOldList === false) {
        this.fields.push(...this.dataFields.filter(x => x.selected))
        this.selectedFields = JSON.parse(JSON.stringify(this.dataFields))
      }
    }).catch(e => {
      console.log(e)
    })
  },
  methods: {
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting)
      this.fields = result?.fields
      this.selectedFields = result?.selectedFields
      this.$refs.componentsTable?.refresh()
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      SearchApi.saveSettings(this.accountId, columns, 'investmentPlanningBuilding').then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal')
      })
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    calculatePlannedCost(item) {
      const componentIndex = this.listComponents.findIndex(component => component.id && item.id ? component.id === item.id : component.internalId === item.internalId)
      if (componentIndex !== -1) {
        item.plannedCosts = item.futurePercentage ? Math.round(((parseFloat(item.costs) * (parseFloat(item.futurePercentage) / 100)) + parseFloat(item.costs)) * 20) / 20 : null
        this.$refs.componentsTable?.refresh()
        this.emitChanges(item)
      }
    },
    calculateFutureCostFactor(item) {
      const componentIndex = this.listComponents.findIndex(component => component.id && item.id ? component.id === item.id : component.internalId === item.internalId)
      if (componentIndex !== -1) {
        item.futurePercentage = item.plannedCosts ? Math.round((((item.plannedCosts - parseFloat(item.costs)) / parseFloat(item.costs)) * 100) * 20) / 20 : null
        this.$refs.componentsTable?.refresh()
        this.emitChanges(item)
      }
    },
    getListColoredItems() {
      this.listColoredItems = this.listComponents.reduce((acc, component) => {
        const referenceId = component.referenceId
        if (!acc[referenceId]) {
          acc[referenceId] = []
        }
        acc[referenceId].push(component.id || component.internalId)
        return acc
      }, {})
    },
    rowClass(item, type) {
      if (type === 'row') {
        let indexColor = -1
        if (item.optionalValueEnhancingComponent) {
          return 'optionalValue'
        } else {
          Object.entries(this.listColoredItems).forEach(([key, value], index) => {
            const indexComp = value.find((comp) => comp === item.id || comp === item.internalId)
            if (indexComp) {
              indexColor = index
            }
          })

          return indexColor % 2 === 0 ? '' : 'dark_line'
        }
      }
      return ''
    },
    customLabelCategory(item) {
      if (item.value === 'building') {
        return this.$t('message.generic.building') + ' (' + item.count + ')'
      }
      return this.$t('message.onBoarding.buildings.objects.category.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    hideEditComponentsModal() {
      this.$refs.editComponentsModal.hide()
      this.showEditModal = false
      this.editAction = ''
    },
    bulkEditComponents(type) {
      this.editAction = type
      this.showEditModal = true
      this.$nextTick(() => {
        this.$refs.editComponentsModal.show()
      })
    },
    applyEditChanges() {
      if (this.editAction === 'installationDate') {
        this.selectedEntriesIds.forEach(id => {
          this.listComponents.forEach(component => {
            if (component.id && id ? component.id === id : component.internalId === id) {
              component.installationDate = this.toObject(this.newInstallationDate)
              component.installationDateString = this.newInstallationDate
              this.calculateReplacementDate(component)
            }
          })
        })
      } else if (this.editAction === 'costs') {
        this.selectedEntriesIds.forEach(id => {
          this.listComponents.forEach(component => {
            if (component.id && id ? component.id === id : component.internalId === id) {
              component.costs = this.newCostComponent
              component.percentage = Math.round(((component.costs * 100) / this.localBuildingValue) * 20) / 20
              this.emitChanges(component)
            }
          })
        })
      } else if (this.editAction === 'percentage') {
        this.selectedEntriesIds.forEach(id => {
          this.listComponents.forEach(component => {
            if (component.id && id ? component.id === id : component.internalId === id) {
              component.percentage = this.newPercentage
              component.costs = Math.round(((this.localBuildingValue * this.newPercentage) / 100) * 20) / 20
              this.emitChanges(component)
            }
          })
        })
      } else if (this.editAction === 'measures') {
        this.selectedEntriesIds.forEach(id => {
          this.listComponents.forEach(component => {
            if (component.id && id ? component.id === id : component.internalId === id) {
              component.note = this.newMeasures
              this.emitChanges(component)
            }
          })
        })
      } else if (this.editAction === 'plannedReplacement') {
        this.selectedEntriesIds.forEach(id => {
          this.listComponents.forEach(component => {
            if (component.id && id ? component.id === id : component.internalId === id) {
              component.plannedReplacementDate = this.toObject(this.newPlannedReplacementDate)
              component.plannedReplacementDateString = this.newPlannedReplacementDate
              this.emitChanges(component)
            }
          })
        })
      } else if (this.editAction === 'futureCostFactor') {
        this.selectedEntriesIds.forEach(id => {
          this.listComponents.forEach(component => {
            if (component.id && id ? component.id === id : component.internalId === id) {
              component.futurePercentage = this.newFuturePercentage
              component.plannedCosts = component.futurePercentage ? Math.round(((parseFloat(component.costs) * (parseFloat(component.futurePercentage) / 100)) + parseFloat(component.costs)) * 20) / 20 : null
              this.emitChanges(component)
            }
          })
        })
      } else if (this.editAction === 'plannedCosts') {
        this.selectedEntriesIds.forEach(id => {
          this.listComponents.forEach(component => {
            if (component.id && id ? component.id === id : component.internalId === id) {
              component.plannedCosts = this.newPlannedCosts
              component.futurePercentage = component.plannedCosts ? Math.round((((component.plannedCosts - parseFloat(component.costs)) / parseFloat(component.costs)) * 100) * 20) / 20 : null
              this.emitChanges(component)
            }
          })
        })
      }
      if (this.readOnly) {
        this.saveData()
      }
      this.hideEditComponentsModal()
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    async saveData() {
      this.isBusy = true
      this.$store.dispatch('onboarding/setLoading', true)
      const promises = []
      const listComponentToCreate = []
      const listComponentToEdit = []
      for (const component of this.listComponents) {
        if (component.internalId) {
          listComponentToCreate.push(component)
        } else if (component.modified) {
          listComponentToEdit.push(component)
        }
      }
      if (listComponentToCreate.length > 0) {
        promises.push(InvestPlanApi.createComponentBulk(listComponentToCreate))
      }
      if (listComponentToEdit.length > 0) {
        promises.push(InvestPlanApi.updateComponentBulk(listComponentToEdit))
      }
      return Promise.all(promises).then(() => {
        Vue.toasted.show(this.$tc('message.successMessages.componentSaved'), { type: 'success' })
        this.$store.dispatch('onboarding/setLoading', false)
        this.isBusy = false
      })
    },
    showCreateComponentModalClicked() {
      this.showCreateComponentModal = true
      this.optionalValueEnhancingComponent = false
      this.$nextTick(() => {
        this.$refs.createComponentInvestmentModal.show()
      })
    },
    hideCreateComponentModal() {
      this.$refs.createComponentInvestmentModal.hide()
      this.optionalValueEnhancingComponent = false
      this.showCreateComponentModal = false
      this.selectedEntries = []
    },
    createComponentClicked() {
      this.loadingAddComponent = true
      this.selectedEntries.forEach(entry => {
        this.addComponent(entry, this.optionalValueEnhancingComponent)
      })
      this.$nextTick(() => {
        this.loadingAddComponent = false
        this.hideCreateComponentModal()
        this.goToEditComponent()
      })
    },
    goToEditComponent(newComponent = false) {
      const query = { view: 'investmentPlanning' }
      if (newComponent) {
        query.addNewComponent = newComponent
      }
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerOnboardingView', params: { id: this.building.id }, query: query })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminOnboardingView', params: { id: this.building.id }, query: query })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetOnboardingView', params: { id: this.building.id }, query: query })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingOnboardingView', params: { id: this.building.id }, query: query })
      }
    },
    initFilter() {
      this.searchText = ''
    },
    dateToObject(item) {
      const componentIndex = this.listComponents.findIndex(component => component.id && item.id ? component.id === item.id : component.internalId === item.internalId)
      if (componentIndex !== -1) {
        item.installationDate = this.toObject(item.installationDateString)
        item.plannedReplacementDate = this.toObject(item.plannedReplacementDateString)
        this.calculateReplacementDate(item)
      }
    },
    loadData() {
      this.isBusy = true
      // sort the list by category translated
      if (this.allObjects?.length > 0) {
        this.objectsList = this.allObjects.sort((a, b) => {
          return this.$t('message.onBoarding.buildings.objects.category.' + a.category.toLowerCase()) > this.$t('message.onBoarding.buildings.objects.category.' + b.category.toLowerCase()) ? 1 : -1
        })
      }
    },
    getDefaultComponents() {
      this.listComponents = []
      this.defaultComponents = []
      const ownerId = this.building.ownerId
      const addedPercentage = this.getAddedPercentage()
      this.typeComponentsBuilding.forEach(type => {
        const internalId = Date.now() + Math.floor(Math.random() * 1000)
        const component = JSON.parse(JSON.stringify(helper.emptyComponent))
        component.internalId = JSON.parse(JSON.stringify('b_' + internalId))
        component.ownerId = ownerId
        component.referenceId = this.building.id
        component.type = type
        component.name = this.$t('message.onBoarding.investmentPlanning.types.' + type)
        component.bkp = this.getBkpComponent(type)
        component.lifeSpan = this.getLifeSpanComponent(type)
        component.percentage = Math.floor((this.getPercentageComponent(type) * (type !== 'COMPONENT_TYPE_SHELL_CONSTRUCTION_1' ? addedPercentage : 1)))
        component.installationDateString = ''
        component.plannedReplacementDateString = ''
        component.calculatedReplacement = ''
        component.optionalValueEnhancingComponent = false
        component.costs = Math.round(this.localBuildingValue * (parseFloat(component.percentage) / 100) * 100) / 100
        component.plannedCosts = component.costs
        this.defaultComponents.push(component)
      })
      this.objectsList.forEach(object => {
          if (object.category === 'APARTMENT') {
            this.typeComponentsObject.forEach(type => {
              const internalId = Date.now() + Math.floor(Math.random() * 1000)
              const component = JSON.parse(JSON.stringify(helper.emptyComponent))
              component.internalId = JSON.parse(JSON.stringify('o_' + internalId))
              component.ownerId = ownerId
              component.referenceId = object.id
              component.type = type
              component.name = this.$t('message.onBoarding.investmentPlanning.types.' + type)
              component.bkp = this.getBkpComponent(type)
              component.lifeSpan = this.getLifeSpanComponent(type)
              component.percentage = Math.floor((this.getPercentageComponent(type) * addedPercentage))
              component.installationDateString = ''
              component.plannedReplacementDateString = ''
              component.calculatedReplacement = ''
              component.optionalValueEnhancingComponent = false
              component.costs = Math.round(this.localBuildingValue * (parseFloat(component.percentage) / 100) * 100) / 100
              component.plannedCosts = component.costs
              this.defaultComponents.push(component)
            })
          }
      })
      const totalPercentage = this.defaultComponents.reduce((acc, component) => {
        return acc + parseFloat(component.percentage)
      }, 0)
      if (totalPercentage < 100) {
        const rest = 100 - totalPercentage
        this.defaultComponents.forEach(component => {
          if (component.type === 'COMPONENT_TYPE_FACADE' || component.type === 'COMPONENT_TYPE_THERMAL_INSULATION') {
            component.percentage = (component.percentage + (rest / 2))
            component.costs = Math.round((this.localBuildingValue * (parseFloat(component.percentage) / 100)) * 100) / 100
          }
        })
      }
      this.listComponents = JSON.parse(JSON.stringify(this.defaultComponents))
      this.allComponents = JSON.parse(JSON.stringify(this.defaultComponents))
      this.setComponents(this.listComponents)
      this.isBusy = false
    },
    getAddedPercentage() {
      const totalBuildingPercentage = this.typeComponentsBuilding.reduce((acc, type) => {
        return acc + parseFloat(this.getPercentageComponent(type))
      }, 0)
      const totalObjectPercentage = this.objectsList.reduce((acc, object) => {
        if (object.category === 'APARTMENT') {
          return acc + this.typeComponentsObject.reduce((innerAcc, type) => {
            return innerAcc + parseFloat(this.getPercentageComponent(type))
          }, 0)
        }
        return acc
      }, 0)
      const totalPercentage = (totalBuildingPercentage + totalObjectPercentage) - 35
      return 65 / totalPercentage
    },
    calculateReplacementDate(item) {
      const componentIndex = this.listComponents.findIndex(component => component.id && item.id ? component.id === item.id : component.internalId === item.internalId)
      if (componentIndex !== -1) {
        item.calculatedReplacement = this.fillOutCalculatedReplacement(item)
        this.$refs.componentsTable?.refresh()
        this.emitChanges(item)
      }
    },
    calculateLifeSpan(item) {
      const componentIndex = this.listComponents.findIndex(component => component.id && item.id ? component.id === item.id : component.internalId === item.internalId)
      if (componentIndex !== -1) {
        item.lifeSpan = this.fillOutLifeSpan(item)
        this.$refs.componentsTable?.refresh()
        this.emitChanges(item)
      }
    },
    calculateCost(item) {
      const componentIndex = this.listComponents.findIndex(component => component.id && item.id ? component.id === item.id : component.internalId === item.internalId)
      if (componentIndex !== -1) {
        if (item.optionalValueEnhancingComponent === false) {
          item.costs = this.localBuildingValue && item.percentage ? Math.round((this.localBuildingValue * (parseFloat(item.percentage) / 100)) * 20) / 20 : item.costs
          this.$refs.componentsTable?.refresh()
        }
        this.emitChanges(item)
      }
    },
    calculateFieldsRelatedToCost(item) {
      const componentIndex = this.listComponents.findIndex(component => component.id && item.id ? component.id === item.id : component.internalId === item.internalId)
      if (componentIndex !== -1) {
        if (item.optionalValueEnhancingComponent === false) {
          item.percentage = this.localBuildingValue ? Math.round(((parseFloat(item.costs) * 100) / this.localBuildingValue) * 20) / 20 : null
          this.$refs.componentsTable?.refresh()
        }
        item.futurePercentage = item.plannedCosts ? Math.round((((item.plannedCosts - parseFloat(item.costs)) / parseFloat(item.costs)) * 100) * 20) / 20 : null
        item.plannedCosts = item.futurePercentage ? Math.round(((parseFloat(item.costs) * (parseFloat(item.futurePercentage) / 100)) + parseFloat(item.costs)) * 20) / 20 : null
        this.emitChanges(item)
      }
    },
    calculateAllCosts() {
      this.listComponents.forEach(component => {
        if (component.optionalValueEnhancingComponent === false) {
          component.costs = this.localBuildingValue && component.percentage ? Math.round((this.localBuildingValue * (parseFloat(component.percentage) / 100)) * 20) / 20 : component.costs
          if (component.percentage === null) {
            this.calculateFieldsRelatedToCost(component)
          }
          this.emitChanges(component)
        }
      })
      this.$nextTick(() => {
        this.$refs.componentsTable?.refresh()
      })
    },
    findObjectById(id) {
      return this.objectsList.find(obj => obj.id === id)
    },
    filterComponentsList() {
      this.$nextTick(function () {
        this.listComponents = this.allComponents.filter(item => {
          const nameSearch = item.name ? item.name.toLowerCase().includes(this.searchText.toLowerCase()) : null
          const bkpHSearch = item.bkp ? item.bkp.includes(this.searchText.toLowerCase()) : null
          const categoryMapped = this.searchCategories.map(x => x.value)
          let cdtCategory
          if (item.referenceId.includes('building_')) {
            cdtCategory = categoryMapped.includes('building') || this.searchCategories.length === 0
          } else {
            cdtCategory = categoryMapped.includes(this.findObjectById(item.referenceId).category) || this.searchCategories.length === 0
          }
          let cdtNameObj
          if (item.referenceId.includes('building_')) {
            cdtNameObj = this.building.name ? (this.building.name.toLowerCase()).includes(this.searchText.toLowerCase()) : null
          } else {
            cdtNameObj = this.findObjectById(item.referenceId).name ? (this.findObjectById(item.referenceId).name.toLowerCase()).includes(this.searchText.toLowerCase()) : null
          }
          return (bkpHSearch || nameSearch || cdtNameObj) && cdtCategory
        })
      })
    },
    searchInputText() {
      this.debouncedFilterComponentsList()
    },
    fillOutLifeSpan(item) {
      if (item.calculatedReplacement) {
        let date = new Date(item.installationDateString)
        return item.calculatedReplacement - date.getFullYear()
      }
      return ''
    },
    getYear(date) {
      if (date) {
        let d = new Date(this.$options.filters.objectToDateInput(date))
        return d.getFullYear()
      }
    },
    fillOutCalculatedReplacement(item) {
      if (item.installationDateString) {
        let date = new Date(item.installationDateString)
        return date.getFullYear() + (item.lifeSpan ? parseInt(item.lifeSpan) : 0)
      }
      return ''
    },
    getObjectName(id) {
      return (this.objectsList.length > 0 && this.objectsList.find(x => x.id === id)) ? this.objectsList.find(x => x.id === id).name : ''
    },
    itemSelected(item, checked) {
      if (checked) {
        this.selectedEntries.push(item.id)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry !== item.id)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    itemSelectedGlobal(item, checked) {
      if (checked) {
        this.selectedEntriesGlobal.push(item)
        if (this.selectedEntries.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntriesGlobal = this.selectedEntriesGlobal.filter(entry => item.internalId && entry.internalId ? entry.internalId !== item.internalId : entry.id && item.id ? entry.id !== item.id : false)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs.componentsTable) {
        const allCheckboxesComponents = this.$refs.componentsTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
        this.selectAllVisibleEntries = value
      }
    },
    emitChanges(component) {
      this.updateComponent(component)
    },
    hideDeleteComponentModal() {
      this.$refs.confirmationModalDeleteComponents.hide()
      this.showDeleteModal = false
    },
    deleteComponentsClicked() {
      this.showDeleteModal = true
      this.$nextTick(() => {
        this.$refs.confirmationModalDeleteComponents.show()
      })
    },
    deleteComponent() {
      this.selectedEntriesIds.forEach(id => {
        const component = this.listComponents.find(component => component.id && id ? component.id === id : component.internalId === id)
        if (component) {
          this.removeComponent(component)
        }
      })
      this.hideDeleteComponentModal()
      this.handleCheckboxesInsideTable(false)
      this.selectedEntriesGlobal = []
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(.selectComponentCheckbox) > .custom-control-input {
  z-index: 10;
}
.suggestion-container-bottom {
  position: absolute !important;
  background-color: white;
  z-index: 1000;
  border: 1px solid #ddd;
  max-height: 200px;
  overflow-y: auto;
  top: 100%;
  left: 0;
  right: 0;
}

.suggestion-container-top {
  position: absolute !important;
  background-color: white;
  z-index: 1000;
  border: 1px solid #ddd;
  max-height: 200px;
  overflow-y: auto;
  bottom: 100%;
  left: 0;
  right: 0;
}
:deep(.dark_line) {
  background-color: #eee;
  td {
    background-color: #eee !important;
  }
}
:deep(.width-8) {
  width: 8%;
}
:deep(.width-15) {
  width: 15%;
}
:deep(.width-20) {
  width: 20%;
}
:deep(.optionalValue) {
  background-color: #FFF9E6;
  td {
    background-color: #FFF9E6 !important;
  }
}
</style>
