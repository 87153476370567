<template>
  <span
    :class="[pullRight ? 'align-right float-right' : '']">
    {{ text }}
  </span>
</template>

<script>
export default {
  name: 'AddressText',
  props: {
    address: {
      type: Object,
      default: () => {
      }
    },
    oneLine: {
      type: Boolean,
      default: false
    },
    showState: {
      type: Boolean,
      default: false
    },
    showCountry: {
      type: Boolean,
      default: false
    },
    showShortCountry: {
      type: Boolean,
      default: false
    },
    pullRight: {
      type: Boolean,
      default: false
    },
    hideIfEmpty: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    text() {
      if (!this.address) {
        return '-'
      }

      const addr = (this.address.careOf && this.address.careOf !== this.address.streetAddition ? this.address.careOf + '\n' : '') +
        (this.address.streetAddition ? this.address.streetAddition + '\n' : '') +
        (this.address.street
? this.address.street + (this.address.streetNumber ? ' ' + this.address.streetNumber : '') +
        (this.oneLine ? ', ' : '\n')
: '') +
        (this.address.mailbox ? this.address.mailbox + '\n' : '') +
        (this.showShortCountry && this.address.zip ? this.address.country + '-' : '') +
        (this.address.zip ? this.address.zip + ' ' : '') +
        (this.address.city ? this.address.city : '') +
        (this.showState ? (this.address.state ? ', ' + this.address.state : '') : '') +
        (this.showCountry ? (this.translatedCountry ? ', ' + this.translatedCountry : '') : '')

      if (addr) {
        return addr
      } else {
        return this.hideIfEmpty ? '' : '-'
      }
    },
    translatedCountry() {
      switch (this.address.country) {
        case 'CH':
        case 'CHE':
          return this.$t('message.generic.countries.switzerland')
        case 'DEU':
          return this.$t('message.generic.countries.germany')
        case 'AUT':
          return this.$t('message.generic.countries.austria')
        case 'LIE':
          return this.$t('message.generic.countries.liechtenstein')
        default:
          return ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .align-right {
    text-align: right;
  }

  span {
    white-space: pre-line
  }
</style>
