<template>
  <div
    class="root">
    <div class="row">
      <div class="col">
        <label
          v-if="name"
          :for="name"
          class="mb-0">
          {{ name }}
          <coozzy-info-circle-icon
            v-if="tooltipText !== ''"
            v-b-tooltip="{ container: '#app', title: tooltipText }"
            class="ml-2" />
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <span
          v-if="forcedPlaceholder !== '' && !isReadOnly"
          class="limit text-grey-light">
          {{ forcedPlaceholder }}
        </span>
        <input
          v-if="!isReadOnly"
          v-model="displayValue"
          type="text"
          class="form-control form-control-sm"
          :class="[(forcedPlaceholder !== '' && !isReadOnly ? 'padding-right-80px' : ''), checkValide, (allowClickInputOnTable ? 'allowClickInputOnTable' : '')]"
          :disabled="disabled"
          :placeholder="placeholder"
          @blur="handleBlur"
          @focus="isInputActive = true"
          @keypress="isDecimalOrNumber">
        <span
          v-else
          class="show-data">
          <span v-if="filter === 'formatArea'">{{ value | formatArea }}</span>
          <span v-else-if="filter === 'formatMeter'">{{ value | formatMeter }}</span>
          <span v-else-if="filter === 'formatVolume'">{{ value | formatVolume }}</span>
          <span v-else-if="filter === 'formatPrice'">{{ value | formatPrice }}</span>
          <span v-else-if="filter === 'formatPriceDecimal'">{{ value | formatPrice('CHF', true) }}</span>
          <span v-else-if="filter === 'formatNumber'">{{ Number(value) | formatNumber }}</span>
          <span v-else-if="filter === 'formatPercent'">{{ value | formatPercent }}</span>
          <span v-else-if="filter === 'formatMeterDecimal'">{{ value | formatMeter }}</span>
          <span v-else>{{ value !=='' && value !== null ? value : '-' }}</span>
          <span v-if="forcedPlaceholder !== ''"> {{ forcedPlaceholder }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CoozzyFormInputNumeric',
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [Number, String],
      default: null
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    isDecimal: {
      type: Boolean,
      default: false
    },
    isOnlyDecimal: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isInteger: {
      type: Boolean,
      default: false
    },
    isIntegerFormat: {
      type: Boolean,
      default: false
    },
    allowNegative: {
      type: Boolean,
      default: false
    },
    isFormated: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: [String, Number],
      default: '',
      required: false
    },
    filter: {
      type: String,
      default: ''
    },
    step: {
      type: Number,
      default: 1
    },
    forcedPlaceholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) => {
        const types = [
          'color', 'date', 'datetime-local', 'email',
          'file', 'hidden', 'image', 'month',
          'number', 'password', 'radio', 'range',
          'reset', 'search', 'submit', 'tel',
          'text', 'time', 'url', 'week'
        ]
        return types.includes(value)
      }
    },
    checkValide: {
      type: String,
      default: ''
    },
    limited: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: ''
    },
    decimalFraction: {
      type: Number,
      default: 2
    },
    allowClickInputOnTable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isInputActive: false
    }
  },
  computed: {
    displayValue: {
      get: function() {
        if (this.value !== null) {
          const value = this.value.toString()

          // When input is active, format with thousands separator but preserve decimal input
          if (this.isInputActive) {
            if (value.includes('.')) {
              const [whole, decimal] = value.split('.')
              const formattedWhole = whole.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'")
                return `${formattedWhole}.${decimal.slice(0, 2)}`
            }
            return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'")
          }

          if (this.filter === 'formatPrice') {
            const numValue = Number(value)
            if (Number.isInteger(numValue)) {
              return numValue.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'")
            } else if (!this.isInputActive) {
              if (value.toString().endsWith('.')) {
                return value.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'")
              }
              return numValue.toFixed(this.decimalFraction).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'")
            } else {
              return value
            }
          } else {
            const numValue = Number(value)
            if (this.isInteger === true) {
              if (this.isIntegerFormat === true) {
                return value
              } else {
                return numValue.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'")
              }
            }
            if (this.isDecimal === true) {
              if (numValue % 1 === 0 && this.isFormated) {
                return numValue.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'")
              } else if (numValue % 1 === 0 && !this.isOnlyDecimal) {
                return value.toString().replace(/\.00$/, '')
              } else {
                return numValue.toFixed(this.decimalFraction).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'")
              }
            }
            return numValue.toFixed(this.decimalFraction).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1'")
          }
        }

        return this.value
      },
      set: function(modifiedValue) {
        // Remove thousands separators before processing
        let cleanValue = modifiedValue.replace(/'/g, '')
        let newValue = null

        if (this.isInteger === true) {
          let regex = this.allowNegative ? /[^0-9-]/g : /[^0-9]/g
          newValue = parseInt(cleanValue.replace(regex, '').replace(/^(-?)(.*)-$/, '$1$2'))
        } else {
          cleanValue = cleanValue
            .replace(',', '.')
            .replace(this.allowNegative ? /[^\d.-]/g : /[^\d.]/g, '')
            .replace(/(?!^)-/g, '')

          // Only parse to float when not actively typing a decimal number
          if (!cleanValue.endsWith('.') && !/\.\d*$/.test(cleanValue)) {
            newValue = parseFloat(cleanValue)
          } else {
            newValue = cleanValue
          }
        }

        // Ensure that it is not NaN
        if (isNaN(newValue)) {
          newValue = null
        }

        this.$emit('input', newValue)
      }
    }
  },
  methods: {
    isDecimalOrNumber ($event) {
      const keyCode = ($event.keyCode ? $event.keyCode : $event.which)
      const key = $event.key || String.fromCharCode(keyCode)

      const { selectionStart, selectionEnd, value } = $event.target
      const isFullySelected = selectionStart === 0 && selectionEnd === value.length

      // Allow valid number input when text fully selected
      if (isFullySelected && /[\d]/.test(key)) {
        return true
      }

      // Calculate new value based on selection
      const currentValue = this.displayValue?.toString() || ''
      const newValue = isFullySelected ? key : currentValue + key

      // Handle negative sign
      if (key === '-' && (!this.allowNegative || currentValue.includes('-'))) {
        $event.preventDefault()
        return
      }

      if (this.isDecimal) {
        // Allow digits, comma (44), and dot (46)
        if (!/[\d-,.]/.test(key)) {
          $event.preventDefault()
        } else if (newValue.includes('.')) {
          // Check decimal places against decimalFraction prop
          const decimals = newValue.split('.')[1]
          if (decimals && decimals.length > this.decimalFraction) {
            $event.preventDefault()
          }

          if (this.limited) {
            // Prevent values greater than 99
            if (parseFloat(newValue) > 99) {
              $event.preventDefault()
            }
          }
        }
      } else {
        // Allow digits and negative sign (45)
        if (!/[\d-]/.test(key)) {
          $event.preventDefault()
        }
      }
    },

    handleBlur() {
      this.isInputActive = false

      // Clean up trailing dot if there is no number after it
      if (this.value !== null) {
        const stringValue = String(this.value)
        if (stringValue.endsWith('.')) {
          const cleanedValue = stringValue.slice(0, -1)
          this.$emit('input', parseFloat(cleanedValue))
        }
      }
      this.$emit('blur')
    }
  }
}
</script>

<style lang="scss" scoped>
.root {
    input {
      padding: 0.2em 8px;
    }
  }
  .limit {
    position: absolute;
    right: 2rem;
    top: 0;
    line-height: 30px;
  }
</style>
