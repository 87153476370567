<template>
  <section>
    <div
      v-if="loading"
      class="row">
      <div class="col">
        <coozzy-spinner />
      </div>
    </div>
    <template v-else>
      <coozzy-page-title
        :title="$t('message.navigation.logFiles')"
        :sub-title="$t('message.logFileView.subTitle')"
        :count="logsLength"
        :count-text="$t('message.navigation.logFiles')"
        :action-text="$t('message.generic.reload')"
        @click="loadLogs()" />
      <div class="row mt-2">
        <div class="col-md-2 col-sm-6 col-12 offset-md-10 mt-2 mt-sm-0">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries float-right">
            <option value="5">
              5 {{ $t('message.generic.entries') }}
            </option>
            <option value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option value="50">
              50 {{ $t('message.generic.entries') }}
            </option>
            <option value="100">
              100 {{ $t('message.generic.entries') }}
            </option>
            <option value="500">
              500 {{ $t('message.generic.entries') }}
            </option>
            <option value="1000">
              1000 {{ $t('message.generic.entries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <b-table
            ref="logsTable"
            bordered
            hover
            responsive="true"
            stacked="md"
            :fields="fields"
            :items="logs">
            <!-- Headings -->
            <template #head(success)>
              {{ $t('message.tableColumnHeadings.logs.success') }}
            </template>
            <template #head(timestamp)>
              {{ $t('message.tableColumnHeadings.logs.time') }}
            </template>
            <template #head(entity)>
              {{ $t('message.tableColumnHeadings.logs.entity') }}
            </template>
            <template #head(id)>
              {{ $t('message.tableColumnHeadings.logs.id') }}
            </template>
            <template #head(triggered)>
              {{ $t('message.tableColumnHeadings.logs.triggered') }}
            </template>
            <template #head(error)>
              {{ $t('message.tableColumnHeadings.logs.error') }}
            </template>

            <!-- Columns -->
            <template
              #cell(success)="data">
              <coozzy-success-icon v-if="data.item.success" />
              <coozzy-fail-icon v-else />
            </template>
            <template
              #cell(timestamp)="data">
              {{ (data.item.timestamp / 1000) | timestampToDateTime }}
            </template>
            <template
              #cell(entity)="data">
              <template v-if="getEntityDetail(data.item.entityId)">
                <span v-if="getEntityDetail(data.item.entityId).property">
                  <a
                    target="_blank"
                    tabindex="0"
                    @click="redirectToPropertyDetail(getEntityDetail(data.item.entityId).property.objectID || getEntityDetail(data.item.entityId).property.id)"
                    @click.middle="redirectToPropertyDetail(getEntityDetail(data.item.entityId).property.objectID || getEntityDetail(data.item.entityId).property.id, true)">
                    {{ getEntityDetail(data.item.entityId).property.name | displayOptionalValue }}
                  </a>
                </span>
                <span v-if="getEntityDetail(data.item.entityId).building">
                  <span v-if="getEntityDetail(data.item.entityId).property"> > </span>
                  <a
                    target="_blank"
                    tabindex="0"
                    @click.middle="redirectToBuildingDetail(getEntityDetail(data.item.entityId).building.objectID || getEntityDetail(data.item.entityId).building.id, true)"
                    @click="redirectToBuildingDetail(getEntityDetail(data.item.entityId).building.objectID || getEntityDetail(data.item.entityId).building.id)">
                    {{ getEntityDetail(data.item.entityId).building.name | displayOptionalValue }}
                  </a>
                </span>
                <span v-if="getEntityDetail(data.item.entityId).object">
                  <span v-if="getEntityDetail(data.item.entityId).building"> > </span>
                  <a
                    target="_blank"
                    tabindex="0"
                    @click.middle="redirectToDetail(getEntityDetail(data.item.entityId).object.buildingId, getEntityDetail(data.item.entityId).object.objectID || getEntityDetail(data.item.entityId).object.id, 'objects', true)"
                    @click="redirectToDetail(getEntityDetail(data.item.entityId).object.buildingId, getEntityDetail(data.item.entityId).object.objectID || getEntityDetail(data.item.entityId).object.id, 'objects')">
                    {{ getEntityDetail(data.item.entityId).object.name | displayOptionalValue }}
                  </a>
                </span>
                <span v-if="getEntityDetail(data.item.entityId).tenancy">
                  <span v-if="getEntityDetail(data.item.entityId).object"> > </span>
                  <a
                    target="_blank"
                    tabindex="0"
                    @click="redirectToDetail(getEntityDetail(data.item.entityId).object.buildingId, getEntityDetail(data.item.entityId).tenancy.objectID || getEntityDetail(data.item.entityId).tenancy.id, 'tenants')">
                    <template v-if="getEntityDetail(data.item.entityId).tenancy.tenants">
                      <a
                        v-for="(contact, j) in getEntityDetail(data.item.entityId).tenancy.tenants"
                        :key="j"
                        target="_blank"
                        tabindex="0"
                        @click.middle="redirectToDetail(getEntityDetail(data.item.entityId).object.buildingId, getEntityDetail(data.item.entityId).tenancy.objectID || getEntityDetail(data.item.entityId).tenancy.id, 'tenants', true)"
                        @click="redirectToDetail(getEntityDetail(data.item.entityId).object.buildingId, getEntityDetail(data.item.entityId).tenancy.objectID || getEntityDetail(data.item.entityId).tenancy.id, 'tenants')">
                        {{ contact.name | displayOptionalValue }}
                      </a>
                    </template>
                    <template v-else>
                      <a
                        v-for="(contact, j) in getEntityDetail(data.item.entityId).listContact"
                        :key="j"
                        target="_blank"
                        tabindex="0"
                        @click.middle="redirectToDetail(getEntityDetail(data.item.entityId).object.buildingId, getEntityDetail(data.item.entityId).tenancy.objectID || getEntityDetail(data.item.entityId).tenancy.id, 'tenants', true)"
                        @click="redirectToDetail(getEntityDetail(data.item.entityId).object.buildingId, getEntityDetail(data.item.entityId).tenancy.objectID || getEntityDetail(data.item.entityId).tenancy.id, 'tenants')">
                        {{ contact.name | displayOptionalValue }}
                      </a>
                    </template>
                  </a>
                </span>
                <span v-if="getEntityDetail(data.item.entityId).condominium">
                  <span v-if="getEntityDetail(data.item.entityId).object"> > </span>
                  <a
                    target="_blank"
                    tabindex="0"
                    @click.middle="redirectToDetail(getEntityDetail(data.item.entityId).object.buildingId, getEntityDetail(data.item.entityId).condominium.objectID || getEntityDetail(data.item.entityId).condominium.id, 'condominium', true)"
                    @click="redirectToDetail(getEntityDetail(data.item.entityId).object.buildingId, getEntityDetail(data.item.entityId).condominium.objectID || getEntityDetail(data.item.entityId).condominium.id, 'condominium')">
                    <template v-if="getEntityDetail(data.item.entityId).condominium.condominiumOwners">
                      <a
                        v-for="(contact, j) in getEntityDetail(data.item.entityId).condominium.condominiumOwners"
                        :key="j"
                        target="_blank"
                        tabindex="0"
                        @click.middle="redirectToDetail(getEntityDetail(data.item.entityId).object.buildingId, getEntityDetail(data.item.entityId).condominium.objectID || getEntityDetail(data.item.entityId).condominium.id, 'condominium', true)">
                        {{ contact.name | displayOptionalValue }}
                      </a>
                    </template>
                    <template v-else>
                      <a
                        v-for="(contact, j) in getEntityDetail(data.item.entityId).listContact"
                        :key="j"
                        target="_blank"
                        tabindex="0"
                        @click.middle="redirectToDetail(getEntityDetail(data.item.entityId).object.buildingId, getEntityDetail(data.item.entityId).condominium.objectID || getEntityDetail(data.item.entityId).condominium.id, 'condominium', true)">
                        {{ contact.name | displayOptionalValue }}
                      </a>
                    </template>
                  </a>
                </span>
                <span v-if="getEntityDetail(data.item.entityId).contact">
                  <a
                    target="_blank"
                    tabindex="0"
                    @click.middle="redirectToContactDetail(getEntityDetail(data.item.entityId).contact.objectID || getEntityDetail(data.item.entityId).contact.id, true)"
                    @click="redirectToContactDetail(getEntityDetail(data.item.entityId).contact.objectID || getEntityDetail(data.item.entityId).contact.id)">
                    {{ getEntityDetail(data.item.entityId).contact.name | displayOptionalValue }}
                  </a>
                </span>
                <span v-if="hasFourDashes(data.item.entityId)">
                  {{ $t('message.manageDocuments.document') }}
                </span>
              </template>
            </template>
            <template
              #cell(id)="data">
              {{ data.item.entityNumericId }}
            </template>
            <template
              #cell(triggered)="data">
              {{ data.item.triggeredBy.name | displayOptionalValue }}
            </template>
            <template
              #cell(error)="data">
              {{ data.item.errorMessage | displayOptionalValue }}
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            align="center"
            class="mt-3 mb-3"
            :total-rows="logsLength"
            :per-page="nbrPerPage" />
        </div>
      </div>
    </template>
  </section>
</template>

<script>
import ObjectApi from '@/misc/apis/ObjectApi'
import CoozzySuccessIcon from '@/framework/components/icons/CoozzySuccessIcon'
import CoozzyFailIcon from '@/framework/components/icons/CoozzyFailIcon'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import ContactApi from '@/misc/apis/ContactApi'
import Vue from 'vue'
import { user } from '@/mixins/user'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import { routeChecks } from '@/mixins/routeChecks'

export default {
  name: 'LogFilesView',
  components: {
    CoozzyPageTitle,
    CoozzyFormSelect,
    CoozzySpinner,
    CoozzySuccessIcon,
    CoozzyFailIcon
  },
  mixins: [user, routeChecks],
  metaInfo() {
    return {
      title: this.$t('message.navigation.logFiles')
    }
  },
  data() {
    return {
      logsLength: 0,
      loading: false,
      nbrPerPage: 5,
      currentPage: 1,
      fields: [
        {
          key: 'success',
          label: '',
          tdClass: 'align-middle'
        },
        {
          key: 'timestamp',
          label: '',
          tdClass: 'align-middle'
        },
        {
          key: 'entity',
          label: '',
          tdClass: 'align-middle'
        },
        {
          key: 'id',
          label: '',
          tdClass: 'align-middle'
        },
        {
          key: 'triggered',
          label: '',
          tdClass: 'align-middle'
        },
        {
          key: 'error',
          label: '',
          tdClass: 'align-middle'
        }
      ],
      logs: [],
      entitiesDetail: []
    }
  },
  watch: {
    currentPage: function () {
      this.loadLogs()
    },
    nbrPerPage: function () {
      this.loadLogs()
    }
  },
  mounted() {
    this.loadLogs()
  },
  methods: {
    loadLogs() {
      this.loading = true
      ObjectApi.getLogs(this.accountId, this.nbrPerPage, this.currentPage)
        .then(response => {
          this.logs = response.logEntries
          this.logsLength = response.totalLogsCount
          this.mountedEntites()
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getEntityDetail(entityId) {
      return this.entitiesDetail.find(x => x.entityId === entityId)
    },
    mountedEntites() {
      this.entitiesDetail = []
      const globalArray = []
      for (let index = 0; index < this.logs.length; index++) {
        this.entitiesDetail.push({
          entityId: this.logs[index].entityId,
          object: null,
          building: null,
          property: null,
          condominium: null,
          tenancy: null,
          contact: null,
          listContact: []
        })
        const element = this.entitiesDetail[index].entityId
        if (element.startsWith('condominium') || element.startsWith('tenancy')) {
          if (element.startsWith('condominium')) {
            ObjectApi.getCondominiumByIds([element])
              .then(response => {
                if (this.entitiesDetail[index] && response && response.condominiums.length > 0) {
                  this.entitiesDetail[index].condominium = response.condominiums[0]
                  if (this.entitiesDetail[index].condominium) {
                    ContactApi.contactResolve(this.entitiesDetail[index].condominium.owner.contactIds)
                      .then(responseCon => {
                        this.entitiesDetail[index].listContact = responseCon.persons.concat(responseCon.companies)
                      })
                    ObjectApi.getObject(this.entitiesDetail[index].condominium.objectId)
                      .then(responseObj => {
                        if (this.entitiesDetail[index]) {
                          this.entitiesDetail[index].object = responseObj.object
                          if (this.entitiesDetail[index].object) {
                            ObjectApi.getBuildingByObjectId(this.entitiesDetail[index].object.id).then(responseBuild => {
                              this.entitiesDetail[index].building = responseBuild.building
                              if (this.entitiesDetail[index].building) {
                                ObjectApi.getPropertiesByBuildingIds([this.entitiesDetail[index].building.id])
                                  .then(responseProp => {
                                    if (Array.isArray(this.entitiesDetail) && this.entitiesDetail[index] && 'property' in this.entitiesDetail[index] && responseProp && Array.isArray(responseProp.properties) && responseProp.properties.length > 0) {
                                      this.entitiesDetail[index].property = responseProp.properties[0]
                                    } else {
                                      console.error('Invalid data structure, index out of bounds, or property does not exist')
                                    }
                                  })
                              }
                            }).catch(e => {
                              console.log(e)
                              Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
                            })
                          }
                        }
                      })
                  }
                }
              })
          } else if (element.startsWith('tenancy')) {
            ObjectApi.getTenanciesByIds([element])
              .then(response => {
                this.entitiesDetail[index].tenancy = response.tenancies[0]
                ContactApi.contactResolve(this.entitiesDetail[index].tenancy.tenant.contactIds)
                  .then(responseCon => {
                    this.entitiesDetail[index].listContact = responseCon.persons.concat(responseCon.companies)
                  })
                ObjectApi.getObject(this.entitiesDetail[index].tenancy.objectId)
                  .then(response => {
                    if (this.entitiesDetail[index]) {
                      this.entitiesDetail[index].object = response.object
                      if (this.entitiesDetail[index].object) {
                        ObjectApi.getBuildingByObjectId(this.entitiesDetail[index].object.id).then(response => {
                          this.entitiesDetail[index].building = response.building
                          if (this.entitiesDetail[index].building) {
                            ObjectApi.getPropertiesByBuildingIds([this.entitiesDetail[index].building.id])
                              .then(response => {
                                this.entitiesDetail[index].property = response.properties[0]
                              })
                          }
                        }).catch(e => {
                          console.log(e)
                          Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
                        })
                      }
                    }
                  })
              })
          }
        }
        if (element.startsWith('obj')) {
          ObjectApi.getObject(element)
            .then(response => {
              if (this.entitiesDetail[index]) {
                this.entitiesDetail[index].object = response.object
                if (this.entitiesDetail[index].object) {
                  ObjectApi.getBuildingByObjectId(this.entitiesDetail[index].object.id).then(response => {
                    this.entitiesDetail[index].building = response.building
                    if (this.entitiesDetail[index].building) {
                      ObjectApi.getPropertiesByBuildingIds([this.entitiesDetail[index].building.id])
                        .then(response => {
                          this.entitiesDetail[index].property = response.properties[0]
                        })
                    }
                  }).catch(e => {
                    console.log(e)
                    Vue.toasted.show(this.$t('message.loadingErrors.advertisement'), { type: 'error' })
                  })
                }
              }
            })
        }
        if (element.startsWith('building')) {
          ObjectApi.getBuildingsListByIds([element]).then(response => {
            if (this.entitiesDetail[index]) {
              this.entitiesDetail[index].building = response.buildings[0]
              if (this.entitiesDetail[index].building) {
                ObjectApi.getPropertiesByBuildingIds([this.entitiesDetail[index].building.id])
                  .then(response => {
                    this.entitiesDetail[index].property = response.properties[0]
                  })
              }
            }
          })
        }
        if (element.startsWith('prop')) {
          globalArray.push({ index: index, id: element, prefix: 'prop' })
        }
        if (element.startsWith('contact')) {
          globalArray.push({ index: index, id: element, prefix: 'contact' })
        }
      }
      if (globalArray.filter(x => x.prefix === 'prop')) {
        ObjectApi.getPropertyListByIds(globalArray.filter(x => x.prefix === 'prop').map(c => c.id))
          .then(response => {
            globalArray.forEach(element => {
              if (this.entitiesDetail[element.index]) {
                this.entitiesDetail[element.index].property = response.properties.find(x => x.id === element.id)
              }
            })
          })
      }
      if (globalArray.filter(x => x.prefix === 'contact')) {
        ContactApi.contactResolve(globalArray.filter(x => x.prefix === 'contact').map(c => c.id))
          .then(response => {
            globalArray.forEach(element => {
              this.entitiesDetail[element.index].contact = response.persons.concat(response.companies).find(x => x.id === element.id)
            })
          })
      }
    },
    redirectToContactDetail(contactId, newTab = false) {
      let name = ''
      if (this.isOwnerModule) {
        name = 'OwnerContactDetailsView'
      } else if (this.isAssetModule) {
        name = 'AssetContactDetailsView'
      } else if (this.isAdminModule) {
        name = 'AdminContactDetailsView'
      } else if (this.isAccountingModule) {
        name = 'AccountingContactDetailsView'
      } else if (this.isMarketingModule) {
        name = 'MarketingContactDetailsView'
      }
      if (newTab) {
        const routerHref = this.$router.resolve({
          name: name,
          params: { id: contactId }
        })
        window.open(routerHref.href, '_blank')
      } else {
        this.$router.push({
          name: name,
          params: { id: contactId }
        })
      }
    },
    redirectToBuildingDetail(buildingId, newTab = false) {
      let name = ''
      if (this.isOwnerModule) {
        name = 'OwnerBuildingDetailsView'
      } else if (this.isAssetModule) {
        name = 'AssetBuildingDetailsView'
      } else if (this.isAdminModule) {
        name = 'AdminBuildingDetailsView'
      } else if (this.isAccountingModule) {
        name = 'AccountingBuildingDetailsView'
      }
      if (newTab) {
        const routerHref = this.$router.resolve({
          name: name,
          params: { id: buildingId },
          query: { view: 'building' }
        })
        window.open(routerHref.href, '_blank')
      } else {
        this.$router.push({
          name: name,
          params: { id: buildingId },
          query: { view: 'building' }
        })
      }
    },
    redirectToPropertyDetail(propertyId, newTab = false) {
      let name = ''
      if (this.isOwnerModule) {
        name = 'OwnerPropertyDetailsView'
      } else if (this.isAssetModule) {
        name = 'AssetPropertyDetailsView'
      } else if (this.isAdminModule) {
        name = 'AdminPropertyDetailsView'
      } else if (this.isAccountingModule) {
        name = 'AccountingPropertyDetailsView'
      }
      if (newTab) {
        const routerHref = this.$router.resolve({
          name: name,
          params: { id: propertyId }
        }, () => {
        })
        window.open(routerHref.href, '_blank')
      } else {
        this.$router.push({
          name: name,
          params: { id: propertyId }
        }, () => {
        })
      }
    },
    redirectToDetail(buildingId, objectId, view, newTab = false) {
      let name = ''
      if (this.isOwnerModule) {
        name = 'OwnerBuildingDetailsView'
      } else if (this.isAssetModule) {
        name = 'AssetBuildingDetailsView'
      } else if (this.isAdminModule) {
        name = 'AdminBuildingDetailsView'
      } else if (this.isAccountingModule) {
        name = 'AccountingBuildingDetailsView'
      }
      if (newTab) {
        const routerHref = this.$router.resolve({
          name: name,
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        })
        window.open(routerHref.href, '_blank')
      } else {
        this.$router.push({
          name: name,
          params: { id: buildingId },
          query: {
            view: view,
            elementSelected: objectId
          }
        }, () => {
        })
      }
    },
    hasFourDashes(str) {
      const dashCount = (str.match(/-/g) || []).length
      return dashCount === 4
    }
  }
}
</script>
