<template>
  <b-modal
    id="contact-bulk-modal"
    ref="contact-bulk-modal"
    no-close-on-backdrop
    hide-footer
    :title="$t('message.manageBuilding.bulkAction.' + title)">
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <template>
          <label>{{ $t('message.calendar.reminder.contact') }}</label>
          <ais-instant-search
            v-if="searchClient"
            :search-client="searchClient"
            :routing="routing"
            index-name="contact">
            <!-- eslint-disable vue/attribute-hyphenation -->
            <ais-configure
              :hitsPerPage="10"
              :filters="filterQuery" />
            <!-- eslint-enable vue/attribute-hyphenation -->
            <ais-autocomplete>
              <template slot-scope="{ indices, refine }">
                <vue-autosuggest
                  ref="autosuggest"
                  v-model="name"
                  :suggestions="indicesToSuggestions(indices)"
                  :input-props="{
                    placeholder: $t('message.generic.search'),
                    class: 'custom-select',
                    id: 'autosuggest__input',
                  }"
                  @input="onChange(refine,name)"
                  @selected="onSelect">
                  <template slot-scope="{ suggestion }">
                    <strong>{{ suggestion.item.name }} {{ suggestion.item.types.length > 0 ? '-' : '' }} {{ suggestion.item.types.map(obj => $t('message.contact.types.' + obj.toUpperCase())).join(', ') }}</strong>
                    <span> <br>{{ suggestion.item.address.street }} {{
                      suggestion.item.address.streetNumber }} <span v-if="(suggestion.item.address.streetNumber && suggestion.item.address.streetNumber !== '') || (suggestion.item.address.street && suggestion.item.address.street !== '')">,</span> {{ suggestion.item.address.zip }} {{
                      suggestion.item.address.city }}</span>
                  </template>
                </vue-autosuggest>
              </template>
            </ais-autocomplete>
          </ais-instant-search>
        </template>
      </div>
      <div
        v-if="selectedContacts.length > 0"
        class="col-12">
        <b-table
          bordered
          hover
          responsive="true"
          stacked="md"
          selectable
          select-mode="single"
          class="mb-0 mt-2"
          :fields="fields"
          :items="displayedSelectedContact">
          <template
            #head(contactName)>
            <span>{{ $t('message.interestedParty.reference.contactName') }}</span>
          </template>
          <template
            #head(action)>
            <span>{{ $t('message.calendar.event.action') }}</span>
          </template>
          <template
            #cell(contactName)="data">
            {{ data.item ? data.item.name : '' }}
          </template>
          <template
            #cell(action)="data">
            <coozzy-button
              design="transparent"
              @click="removeContactFromList(data.item.objectID)">
              <coozzy-delete-icon />
            </coozzy-button>
          </template>
          <template
            #row-details="data"
            class="p-0">
            <div class="col-12 p-0">
              <coozzy-form-textarea
                ref="note"
                :key="data.item.objectID"
                v-model="data.item.internalNote"
                :initial="data.item.internalNote"
                :value-text-area="data.item.internalNote"
                :show-label="false"
                name="note"
                :rows="3"
                :placeholder="$t('message.createOrder.orderDescription')"
                @blur="noteChanged(data.item.id, data.item.internalNote)" />
            </div>
          </template>
        </b-table>
      </div>
      <div class="col mt-3">
        <coozzy-button
          size="small"
          class="mb-0 border"
          design="transparent"
          @click="$emit('action-cancel');hide()">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          size="small"
          class="float-right mb-0"
          design="green"
          :disabled="disabledBtn || selectedContacts.length === 0"
          @click="addTypeAction">
          <template v-if="['removeJanitors', 'removeCraftsmen', 'removeServiceProviders', 'removeDelegate', 'removeRevisor'].includes(title)">
            {{ $t('message.onBoarding.createContactTab.confirmation.title') }}
          </template>
          <template v-else>
            {{ $t('message.generic.form.save') }}
          </template>
        </coozzy-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import ObjectApi from '@/misc/apis/ObjectApi'
import CoozzyDeleteIcon from '@/framework/components/icons/CoozzyDeleteIcon'
import { algolia } from '@/mixins/algolia'
import { user } from '@/mixins/user'
import { VueAutosuggest } from 'vue-autosuggest'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea.vue'

export default {
  name: 'ObjectContactsBulkAction',
  components: { CoozzyFormTextarea, CoozzyButton, CoozzyDeleteIcon, VueAutosuggest },
  mixins: [algolia, user],
  props: {
    title: {
      type: String,
      default: ''
    },
    buildingIds: {
      type: Array,
      default() {
        return []
      }
    },
    ownerIds: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      disabledBtn: false,
      selectedContacts: [],
      name: '',
      fields: [
        {
          key: 'contactName',
          label: 'contactName',
          thClass: 'table-header',
          tdClass: 'align-middle'
        },
        {
          key: 'action',
          label: 'action',
          thClass: 'table-header',
          tdClass: 'align-middle'
        }
      ]
    }
  },
  computed: {
    filterQuery() {
      let query = 'isOnlyInterestedParty:false'
      const filteredOwners = [...new Set(this.ownerIds)]
      // if (this.title && (this.title === 'addServiceProviders' || this.title === 'removeServiceProviders')) {
      //   query += 'types:SERVICE_PROVIDER'
      // } else if (this.title && (this.title === 'addJanitors' || this.title === 'removeJanitors')) {
      //   query += 'types:CARETAKER'
      // } else if (this.title && (this.title === 'addCraftsmen' || this.title === 'removeCraftsmen')) {
      //   query += 'types:CRAFTSMAN'
      // } else if (this.title && (this.title === 'addDelegate' || this.title === 'removeDelegate')) {
      //   query += 'types:DELEGATE'
      // } else if (this.title && (this.title === 'addRevisor' || this.title === 'removeRevisor')) {
      //   query += 'types:REVISOR'
      // }
      if (filteredOwners.length === 1 && this.accountId) {
        query += ' AND ( owner.id=' + filteredOwners[0] + ' OR owner.id=' + this.accountId + ' )'
      }
      if (filteredOwners.length > 1) {
        query += ' AND owner.id=' + this.accountId
      }
      return query
    },
    displayedSelectedContact() {
      let list = this.selectedContacts
      for (let i = 0; i < list.length; i++) {
        if (list[i]) {
          list[i]._showDetails = true
        }
      }
      return list
    }
  },
  watch: {
  },
  mounted() {
    this.loadInstantSearch()
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'contact-bulk-modal') {
        this.selectedContacts = []
        this.name = ''
      }
    })
  },
  methods: {
    onChange(refine, name) {
      this.name = name
      refine(name)
    },
    onSelect(selected) {
      if (selected) {
        selected.internalNote = ''
        this.selectedContacts.push(selected.item)
      }
      this.name = ''
      this.$refs.autosuggest.internalValue = ''
    },
    indicesToSuggestions(indices) {
      return indices.map(({ hits }) => ({
        data: hits.filter(hit => {
          return hit.active
        })
      }))
    },
    addTypeAction() {
      this.disabledBtn = true
      const contactIds = this.selectedContacts.map(c => c.objectID)
      const buildingContacts = []
      let type = ''
      switch (this.title) {
        case 'addJanitors':
          type = 'BUILDING_CONTACT_TYPE_JANITOR'
          break
        case 'addCraftsmen':
          type = 'BUILDING_CONTACT_TYPE_CRAFTSMAN'
          break
        case 'addServiceProviders':
          type = 'BUILDING_CONTACT_TYPE_SERVICE_PROVIDER'
          break
        case 'addDelegate':
          type = 'BUILDING_CONTACT_TYPE_DELEGATE'
          break
        case 'addRevisor':
          type = 'BUILDING_CONTACT_TYPE_REVISOR'
          break
        default:
          break
      }
      for (const contact of this.selectedContacts) {
        buildingContacts.push({ id: contact.id || contact.objectID, note: contact.internalNote, type: type })
      }
      ObjectApi.addBuildingContacts(buildingContacts, this.buildingIds)
        .then(() => {
          this.disabledBtn = false
          const adding = ['addJanitors', 'addCraftsmen', 'addServiceProviders', 'addDelegate', 'addRevisor'].includes(this.title)
          this.hide()
          this.$emit('action-done', adding, contactIds, this.buildingIds)
          if (adding) {
            Vue.toasted.show(this.$t('message.manageBuilding.bulkAction.successMessages.' + this.title), { type: 'success' })
          } else {
            Vue.toasted.show(this.$t('message.accountSettings.updatedInformation'), { type: 'success' })
          }
        })
        .catch(e => {
          console.log(e)
          this.disabledBtn = false
          this.hide()
          Vue.toasted.show(this.$t('message.savingErrors.building'), { type: 'error' })
        })
    },
    removeContactFromList(id) {
      this.selectedContacts.forEach((element, index) => {
        if (element.objectID === id) {
          this.selectedContacts.splice(index, 1)
        }
      }, this)
    },
    noteChanged(id, note) {
      this.$emit('note-changed', id, note)
    },
    show() {
      this.$refs['contact-bulk-modal'].show()
    },
    hide() {
      this.$emit('on-close')
      this.selectedContacts = []
      this.$refs['contact-bulk-modal'].hide()
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.name
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.custom-select) {
    background: none;
  }
  label {
    margin-bottom: 0;
  }

  .media-block {
    max-width: 33%;
    margin-right: 0.5%;

    .uploaded-file {
      background-position: center center;
      background-size: cover;
      min-height: 7em;
    }

    .file-block {
      min-height: 7em;
      background: $color-bg-grey-dark;

      a {
        color: $color-text-grey;
        overflow-wrap: break-word;
      }
    }
  }
</style>
