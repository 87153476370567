<template>
  <section class="">
    <div class="row">
      <div class="col-12">
        <label for="city">{{ fromHandover === 'moveIn' ? $t('message.processHandover.movingInTenancy') : $t('message.processHandover.movingOutTenancy') }}</label>
        <coozzy-form-select
          id="tenancy-select"
          v-model="selectedTenancy"
          class="mb-3"
          :state="selectTenancyStatus"
          @change="tenancySelectionChanged">
          <option
            :value="'UNDEFINED_VALUE'">
            -
          </option>
          <option
            v-for="(item, typeIndex) in listTenancies"
            :key="typeIndex + ' + Tenancy'"
            :value="item">
            {{ getItemName(item) }}
          </option>
        </coozzy-form-select>
      </div>
      <div class="col-12">
        <template class="">
          <div class="row m-0 mb-3 w-100">
            <div class="col-md-12 mt-2 p-0">
              <coozzy-detail-card>
                <template
                  v-if="loadingTenancies"
                  class="text-center">
                  <coozzy-spinner />
                </template>
                <b-table
                  v-else
                  ref="tenancyContactsTable"
                  :fields="fieldsTenantsList"
                  :items="listContactTenancies"
                  bordered
                  class="overflow-auto shadow w-100"
                  hover
                  responsive="true"
                  select-mode="single"
                  stacked="md"
                  @row-clicked="onRowClicked">
                  <!-- Busy state -->
                  <div
                    slot="table-busy"
                    class="text-center text-danger my-2">
                    <coozzy-spinner />
                  </div>

                  <!-- Headings -->
                  <template #head(name)>
                    {{ $t('message.generic.name') }}
                  </template>
                  <template #head(contactPerson)>
                    {{ $t('message.interestedParty.reference.landlord.contactPerson') }}
                  </template>
                  <template #head(communication)>
                    {{ $t('message.generic.communication') }}
                  </template>
                  <template #head(startingFrom)>
                    {{ $t('message.onBoarding.buildings.objects.tenancies.startingFrom') }}
                  </template>
                  <template #head(endingFrom)>
                    {{ $t('message.onBoarding.buildings.objects.tenancies.endingFrom') }}
                  </template>
                  <template #head(action) />

                  <!-- Body -->
                  <template #cell(name)="data">
                    {{
                      data.item.name
                    }}
                  </template>
                  <template #cell(startingFrom)="data">
                    {{
                      data.item.startingFrom
                    }}
                  </template>
                  <template #cell(endingFrom)="data">
                    {{
                      data.item.endingFrom
                    }}
                  </template>
                  <template #cell(contactPerson)="data">
                    <coozzy-form-select
                      v-if="data.item.contactPersons"
                      id="contactPerson"
                      :ref="'contactPerson_' + data.item.id"
                      v-model="data.item.selectedContactPerson">
                      <option :value="null">
                        -
                      </option>
                      <option
                        v-for="contact of data.item.contactPersons"
                        :key="contact.id"
                        :value="contact.id">
                        {{ contact.name }}
                      </option>
                    </coozzy-form-select>
                    <span v-else>
                      -
                    </span>
                  </template>
                  <template #cell(communication)="data">
                    <template
                      v-if="data.item.id">
                      <div
                        v-if="getPhoneNumbers(data.item.phoneNumbers, 'MOBILE') !== '-'"
                        class="row">
                        <div class="col-1">
                          <coozzy-mobile-alt-icon />
                        </div>
                        <div class="col">
                          <a
                            :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'MOBILE')"
                            class="link">
                            {{ getPhoneNumbers(data.item.phoneNumbers, 'MOBILE') }}
                          </a>
                        </div>
                      </div>
                      <div
                        v-if="getPhoneNumbers(data.item.phoneNumbers, 'HOME') !== '-'"
                        class="row">
                        <div class="col-1">
                          <coozzy-phone-icon />
                        </div>
                        <div class="col">
                          <a
                            :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'HOME')"
                            class="link">
                            {{ getPhoneNumbers(data.item.phoneNumbers, 'HOME') }}
                          </a>
                        </div>
                      </div>
                      <div
                        v-if="getPhoneNumbers(data.item.phoneNumbers, 'WORK') !== '-'"
                        class="row">
                        <div class="col-1">
                          <coozzy-headset-icon />
                        </div>
                        <div class="col">
                          <a
                            :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'WORK')"
                            class="link">
                            {{ getPhoneNumbers(data.item.phoneNumbers, 'WORK') }}
                          </a>
                        </div>
                      </div>
                      <div
                        v-if="getPhoneNumbers(data.item.phoneNumbers, 'FAX') !== '-'"
                        class="row">
                        <div class="col-1">
                          <coozzy-fax-icon />
                        </div>
                        <div class="col">
                          <a
                            :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'FAX')"
                            class="link">
                            {{ getPhoneNumbers(data.item.phoneNumbers, 'FAX') }}
                          </a>
                        </div>
                      </div>
                      <div
                        v-if="data.item.email !== ''"
                        class="row">
                        <div class="col-1">
                          <img
                            alt="Placeholder"
                            class="email-icon-img"
                            src="@/assets/icon/arroba.svg">
                        </div>
                        <div class="col">
                          <template v-if="data.item.email !== ''">
                            <a
                              class="link"
                              tabindex="0">
                              {{ data.item.email }}
                            </a>
                          </template>
                          <template v-else>
                            {{ data.item.email | displayOptionalValue }}
                          </template>
                        </div>
                      </div>
                      <div
                        v-if="data.item.website !== ''"
                        class="row">
                        <div class="col-1">
                          <coozzy-globe-icon />
                        </div>
                        <div class="col">
                          <template v-if="data.item.website !== ''">
                            <a
                              :href="getUrlWebsite(data.item.website)"
                              class="link"
                              tabindex="0"
                              target="_blank">
                              {{ data.item.website }}
                            </a>
                          </template>
                          <template v-else>
                            {{ data.item.website | displayOptionalValue }}
                          </template>
                        </div>
                      </div>
                    </template>
                    <template
                      v-else>
                      -
                    </template>
                  </template>
                  <template #cell(action)="data">
                    <coozzy-button
                      v-if="data.item.id"
                      size="small"
                      class="float-right mb-0 address-button"
                      design="green"
                      @click="addAddress(data.item.tenancy)">
                      {{ $t('message.contact.actions.addAddress') }}
                    </coozzy-button>
                  </template>
                </b-table>
              </coozzy-detail-card>
            </div>
            <template
              v-if="listCompanies.length > 0">
              <h6>{{ $t('message.processHandover.subtitleContactPersonsSelection') }}</h6>
              <div
                v-for="company in listCompanies"
                :key="company.id"
                class="col-md-12 mt-1 mb-3 w-100 p-0">
                <coozzy-detail-card
                  class="mb-2"
                  :title="$t('message.tableColumnHeadings.contactPersons') + ' (' + company.contactPersons.length +')' + ' - ' + company.name">
                  <div class="col-12 p-0">
                    <b-table
                      :id="'tableContactPersons_' + company.id"
                      :ref="'tableContactPersons_' + company.id"
                      bordered
                      hover
                      responsive="true"
                      stacked="md"
                      class="m-0"
                      select-mode="single"
                      :fields="fieldsContactPersons"
                      :items="company.contactPersons">
                      <!-- Headings -->
                      <template #head(name)>
                        {{ $t('message.generic.name') }}
                      </template>
                      <template #head(phoneNumber)>
                        {{ $t('message.generic.phoneNumber') }}
                      </template>
                      <template #head(email)>
                        {{ $t('message.generic.email') }}
                      </template>
                      <template #head(action)>
                        {{ '' }}
                      </template>
                      <template #head(edit)>
                        {{ '' }}
                      </template>

                      <!-- Columns -->
                      <template
                        #cell(name)="data">
                        <div
                          class="contact-person-name"
                          :class="[data.item.dead === true || !data.item.active ? 'text-gray' : '']">
                          <span>
                            <template v-if="data.item.gender === 'male'">
                              {{ $t('message.generic.title.mr') }}
                            </template>
                            <template v-if="data.item.gender === 'female'">
                              {{ $t('message.generic.title.mrs') }}
                            </template>
                            <template v-if="data.item.gender === 'other'">
                              {{ $t('message.generic.title.divers') }}
                            </template>
                          </span>
                          <span
                            v-if="data.item.title && data.item.title !== ''">{{ data.item.title }} </span>
                          <div class="font-weight-bold">
                            {{ data.item.name | displayOptionalValue }}
                          </div>
                          <div v-if="data.item.companyPosition !== ''">
                            {{ data.item.companyPosition }}
                          </div>
                        </div>
                      </template>
                      <template
                        #cell(phoneNumber)="data">
                        <div
                          v-if="getPhoneNumbers(data.item.phoneNumbers, 'work') !== '-'"
                          class="row"
                          :class="[data.item.dead === true || !data.item.active ? 'text-gray' : '']">
                          <div class="col-1">
                            <coozzy-phone-icon />
                          </div>
                          <div class="col">
                            <a :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'work')">
                              {{ getPhoneNumbers(data.item.phoneNumbers, 'work') }}
                            </a>
                          </div>
                        </div>
                        <div
                          v-if="getPhoneNumbers(data.item.phoneNumbers, 'mobile') !== '-'"
                          class="row"
                          :class="[data.item.dead === true || !data.item.active ? 'text-gray' : '']">
                          <div class="col-1">
                            <coozzy-mobile-alt-icon />
                          </div>
                          <div class="col">
                            <a :href="'tel:' + getPhoneNumbers(data.item.phoneNumbers, 'mobile')">
                              {{ getPhoneNumbers(data.item.phoneNumbers, 'mobile') }}
                            </a>
                          </div>
                        </div>
                      </template>
                      <template
                        #cell(email)="data">
                        <div
                          class="row"
                          :class="[data.item.dead === true || !data.item.active ? 'text-gray' : '']">
                          <div class="col-1">
                            <img
                              class="email-icon-img"
                              src="@/assets/icon/arroba.svg"
                              alt="Placeholder">
                          </div>
                          <div class="col">
                            <template v-if="data.item.email !== ''">
                              <a
                                class="link"
                                @click="sendEmailModal(data.item)">{{ data.item.email }}</a>
                            </template>
                            <template v-else>
                              {{ data.item.email | displayOptionalValue }}
                            </template>
                          </div>
                        </div>
                      </template>
                      <template
                        #cell(action)="data">
                        <coozzy-button
                          v-if="!selectedContactPersons.find(x => x.id === data.item.id)"
                          class="col"
                          design="transparent"
                          :disabled="!data.item.active"
                          @click="onContactPersonSelect(data.item)">
                          {{ $t('message.generic.select') }}
                        </coozzy-button>
                        <coozzy-button
                          v-else
                          class="col mb-0"
                          design="alert"
                          :disabled="!data.item.active"
                          @click="onContactPersonSelect(data.item)">
                          {{ $t('message.generic.unselect') }}
                        </coozzy-button>
                      </template>

                      <template
                        #cell(edit)="data">
                        <coozzy-button
                          class="col-1 p-0"
                          design="transparent"
                          @click="showPerson(company, data.item)">
                          <coozzy-edit-icon />
                        </coozzy-button>
                      </template>
                    </b-table>
                  </div>
                </coozzy-detail-card>
                <coozzy-button
                  class="col-3 mt-0"
                  design="prop-green"
                  @click="openContactModal(company)">
                  {{ $t('message.generic.addContactPerson') }}
                </coozzy-button>
              </div>
            </template>
          </div>
        </template>
      </div>
      <add-address-modal
        v-if="contact"
        :contact="contact" />
      <contact-person-creation
        ref="contact-person-modal"
        :key="selectedPersonToEdit !== null ? 'modal-person'+selectedPersonToEdit.id : 'modal-person'"
        :company-contact="selectedCompanyToEdit"
        :contact-type="'company'"
        :selected-contact="selectedPersonToEdit"
        @new-contact-person="contactPersonCreated"
        @edit-contact-person="contactPersonEdited"
        @delete-contact-person="contactPersonDeleted" />
      <create-contact-modal
        v-if="contact"
        :key="contact ? contact.id : null"
        :for-owner-id="null"
        :from-handover="fromHandover"
        :show-search="false"
        :show-request-sources="false"
        :set-interested-party-role="false"
        :show-add-another="true"
        :contact-to-edit="contact"
        @contact-updated="contactUpdated"
        @modal-hide="modalHide" />
    </div>
  </section>
</template>

<script>
import { tables } from '@/mixins/tables'
import { routeChecks } from '@/mixins/routeChecks'
import { user } from '@/mixins/user'
import { formatting } from '@/mixins/formatting'
import CoozzyDetailCard from '@/framework/components/card/CoozzyDetailCard.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import { referenceInterestRate } from '@/mixins/referenceInterestRate'
import AddAddressModal from '@/contact/components/AddAddressModal.vue'
import CreateContactModal from '@/contact/components/CreateContactModal.vue'
import CoozzyButton from '@/framework/components/button/CoozzyButton.vue'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'
import CoozzyMobileAltIcon from '@/framework/components/icons/CoozzyMobileAltIcon.vue'
import CoozzyPhoneIcon from '@/framework/components/icons/CoozzyPhoneIcon.vue'
import CoozzyHeadsetIcon from '@/framework/components/icons/CoozzyHeadsetIcon.vue'
import CoozzyGlobeIcon from '@/framework/components/icons/CoozzyGlobeIcon.vue'
import CoozzyFaxIcon from '@/framework/components/icons/CoozzyFaxIcon.vue'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon.vue'
import ContactPersonCreation from '@/contact/components/ContactPersonCreation.vue'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'HandoverTenancyMove',
  components: {
    ContactPersonCreation,
    CoozzyEditIcon,
    CoozzyFaxIcon,
    CoozzyGlobeIcon,
    CoozzyHeadsetIcon,
    CoozzyPhoneIcon,
    CoozzyMobileAltIcon,
    CoozzyFormSelect,
    CoozzyButton,
    CreateContactModal,
    AddAddressModal,
    CoozzySpinner,
    CoozzyDetailCard
  },
  mixins: [tables, routeChecks, user, formatting, referenceInterestRate],
  props: {
    availableContact: {
      type: Array,
      default() {
        return []
      }
    },
    fromHandover: {
      type: String,
      default: ''
    },
    moveTenancies: {
      type: Array,
      default() {
        return []
      }
    },
    object: {
      type: Object,
      required: true
    },
    preselectedTenancy: {
      type: [Object, String],
      default: null
    },
    selectTenancyStatus: {
      type: Boolean,
      default: null
    },
    preselectedContactPersons: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      contact: null,
      selectedTenancy: null,
      fieldsTenantsList: [
        {
          key: 'name',
          label: this.$t('message.generic.name'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'startingFrom',
          label: this.$t('message.onBoarding.buildings.objects.tenancies.startingFrom'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'endingFrom',
          label: this.$t('message.onBoarding.buildings.objects.tenancies.endingFrom'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'communication',
          label: this.$t('message.generic.communication'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'action',
          label: '',
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      listContactTenancies: [],
      filterTenants: [],
      listContacts: [],
      loadingTenancies: false,
      selectedPersonToEdit: null,
      selectedCompanyToEdit: null,
      selectedContactPersons: [],
      fieldsContactPersons: [
        {
          key: 'name',
          label: this.$t('message.generic.name'),
          tdClass: 'align-middle p-md-0'
        },
        {
          key: 'phoneNumber',
          label: this.$t('message.generic.phoneNumber'),
          tdClass: 'align-middle phone-cell'
        },
        {
          key: 'email',
          label: this.$t('message.generic.email'),
          tdClass: 'align-middle'
        },
        {
          key: 'action',
          label: '',
          tdClass: 'align-middle'
        },
        {
          key: 'edit',
          label: '',
          tdClass: 'align-middle'
        }
      ],
      listCompanies: []
    }
  },
  computed: {
    listTenancies() {
      return this.moveTenancies.filter(tenancy => this.object.id === tenancy.objectId).sort((a, b) => {
        return new Date(this.$options.filters.objectToDateInput(b.startingAt)) - new Date(this.$options.filters.objectToDateInput(a.startingAt))
      })
    }
  },
  watch: {
    selectedTenancy: {
      handler() {
        this.getListCompanies()
      },
      deep: true
    },
    availableContact: {
      handler() {
        this.listContacts = this.availableContact
        this.loadData()
      },
      deep: true
    }
  },
  mounted() {
    this.listContacts = [...this.availableContact]
    this.fieldsTenantsList = this.fieldsTenantsList.filter(field =>
      this.fromHandover === 'moveIn' ? (field.key !== 'action' && field.key !== 'endingFrom') : (field.key !== 'startingFrom')
    )
    const todayTime = new Date().getTime()
    const fifteenDaysAgo = todayTime - (15 * 24 * 60 * 60 * 1000) // 15 days in milliseconds
    if (this.preselectedTenancy) {
      this.selectedTenancy = this.preselectedTenancy
    } else {
      if (this.fromHandover === 'moveIn') {
        const list = this.listTenancies.filter(tenancy => {
          const startingAt = new Date(this.$options.filters.objectToDateInput(tenancy.startingAt)).getTime()
          return !tenancy.vacancy && startingAt > fifteenDaysAgo
        })
        if (list.length > 0) {
          this.selectedTenancy = list[0]
          this.$emit('move-in-tenancy-selected', this.selectedTenancy)
        }
      } else {
        const list = this.listTenancies.filter(tenancy => {
          const endingAt = new Date(this.$options.filters.objectToDateInput(tenancy.endingAt)).getTime()
          return tenancy.endingAt && endingAt > fifteenDaysAgo
        })
        if (list.length > 0) {
          this.selectedTenancy = list[0]
          this.$emit('move-out-tenancy-selected', this.selectedTenancy)
        }
      }
    }
    this.loadData()
  },
  methods: {
    ...mapMutations('message', [
      'setSendMailContacts',
      'setSendMailOnlyCurrentContact',
      'setSendMailPreselectedContact',
      'setSendMailReferenceIds',
      'setSendMailEmailToReply'
    ]),
    ...mapActions('message', ['loadMailboxesForUserId']),
    getListCompanies() {
      const list = []
      if (this.selectedTenancy) {
        const tenancy = this.moveTenancies.find(tenancy => this.object.id === tenancy.objectId && tenancy.id === this.selectedTenancy.id)
        if (tenancy) {
          tenancy.tenant.contactIds.forEach(contactId => {
            const contact = this.availableContact.find(contact => contact.id === contactId)
            if (contact && 'contactPersons' in contact) {
              list.push(contact)
            }
          })
        }
      }
      this.listCompanies = list
    },
    onContactPersonSelect(contact) {
      if (this.selectedContactPersons.find(item => item.id === contact.id)) {
        this.selectedContactPersons = this.selectedContactPersons.filter(item => item.id !== contact.id)
      } else {
        this.selectedContactPersons.push(contact)
      }
      this.$nextTick(() => {
        if (this.fromHandover === 'moveIn') {
          this.$emit('move-in-contact-persons-selected', this.selectedContactPersons)
        } else {
          this.$emit('move-out-contact-persons-selected', this.selectedContactPersons)
        }
      })
    },
    showPerson(company, item) {
      this.selectedCompanyToEdit = company
      this.selectedPersonToEdit = item
      this.$nextTick(function () {
        this.$refs['contact-person-modal'].show()
      })
    },
    openContactModal(company) {
      this.selectedCompanyToEdit = company
      this.selectedPersonToEdit = null
      this.$nextTick(function () {
        this.$refs['contact-person-modal']?.show()
      })
    },

    contactPersonCreated(item) {
      const index = this.availableContact.findIndex(contact => contact.id === this.selectedCompanyToEdit.id)
      this.availableContact[index].contactPersons.push(item)
      this.$nextTick(function () {
        this.getListCompanies()
        const ref = this.$refs['tableContactPersons_' + this.selectedCompanyToEdit.id]
        if (ref && ref.length > 0) {
          ref[0].refresh()
        }
        this.selectedCompanyToEdit = null
        this.selectedPersonToEdit = null
      })
    },
    contactPersonEdited(item) {
      const index = this.availableContact.findIndex(contact => contact.id === this.selectedCompanyToEdit.id)
      const indexCP = this.availableContact[index].contactPersons.findIndex(contactPerson => contactPerson.id === item.id)
      this.availableContact[index].contactPersons[indexCP] = item
      this.$nextTick(function () {
        this.getListCompanies()
        const ref = this.$refs['tableContactPersons_' + this.selectedCompanyToEdit.id]
        if (ref && ref.length > 0) {
          ref[0].refresh()
        }
        this.selectedCompanyToEdit = null
        this.selectedPersonToEdit = null
      })
    },
    contactPersonDeleted(id) {
      const index = this.availableContact.findIndex(contact => contact.id === this.selectedCompanyToEdit.id)
      const indexCP = this.availableContact[index].contactPersons.findIndex(contactPerson => contactPerson.id === id)
      this.availableContact[index].contactPersons.splice(indexCP, 1)
      this.$nextTick(function () {
        this.getListCompanies()
        const ref = this.$refs['tableContactPersons_' + this.selectedCompanyToEdit.id]
        if (ref && ref.length > 0) {
          ref[0].refresh()
        }
      })
      this.selectedPersonToEdit = null
      this.selectedCompanyToEdit = null
    },
    sendEmailModal(contact) {
      const emailToContact = {
        id: contact.id,
        name: contact.name,
        email: contact.email
      }
      this.setSendMailContacts([emailToContact])
      this.setSendMailPreselectedContact(emailToContact)
      this.setSendMailEmailToReply(null)
      this.setSendMailOnlyCurrentContact(true)
      this.$nextTick(function () {
        this.callShowSendMailModal()
      })
    },
    tenancySelectionChanged(value) {
      this.loadData()
      if (this.fromHandover === 'moveIn') {
        this.$emit('move-in-tenancy-selected', value)
      } else {
        this.$emit('move-out-tenancy-selected', value)
      }
    },
    getItemName(item) {
      // name must be (list all tenants with a , between) - (startdate) - (enddate)
      return this.getTenantsName(item) + ' - ' + this.$options.filters.objectToDate(item.startingAt) + (item.endingAt ? ' - ' + this.$options.filters.objectToDate(item.endingAt) : '')
    },
    getTenantsName(tenancy) {
        if (tenancy && tenancy.vacancy === true) {
          return this.$t('message.onBoarding.buildings.objects.tenancies.vacancy')
        } else if (tenancy && tenancy.vacancy === false && tenancy.tenant.contactIds.length > 0) {
          let names = ''
          tenancy.tenant.contactIds.forEach(element => {
            names += this.getContactName(element)
          })
          return names !== '' ? names.substring(1) : ''
        }
        return '-'
    },
    getContactName(contactId) {
      const contact = this.availableContact.find(function (contact) {
        return contact.id === contactId
      })
      return contact ? ', ' + contact.name : '-'
    },
    getPhoneNumbers(phoneNumbers, type) {
      if (phoneNumbers && phoneNumbers.length > 0) {
        if (phoneNumbers.find(element => element.type?.toLowerCase() === type?.toLowerCase())) {
          return phoneNumbers.find(element => element.type.toLowerCase() === type.toLowerCase()).number
        }
      }
      return '-'
    },
    loadData() {
      if (this.moveTenancies.length > 0 && this.availableContact.length > 0 && this.selectedTenancy) {
        this.filterTenants = this.moveTenancies
        // contacts that match contactIds of tenant of every tenancy
        const list = []
        const tenancy = this.moveTenancies.find(tenancy => this.object.id === tenancy.objectId && tenancy.id === this.selectedTenancy.id)
        if (tenancy) {
          const contacts = this.availableContact.filter(contact => tenancy.tenant.contactIds.includes(contact.id))
          contacts.forEach(contact => {
            list.push({
              id: contact.id,
              name: contact.name,
              type: this.object ? this.object.type : '-',
              email: contact.email,
              phoneNumbers: contact.phoneNumbers,
              website: contact.website,
              tenancy: tenancy,
              startingFrom: this.$options.filters.objectToDate(tenancy.startingAt),
              endingFrom: this.$options.filters.objectToDate(tenancy.endingAt)
            })
          })
          if (tenancy.vacancy) {
            list.push({
              id: '',
              name: this.$t('message.onBoarding.buildings.objects.tenancies.vacancy'),
              type: this.object ? this.object.type : '-',
              email: '',
              phoneNumbers: [],
              website: '',
              tenancy: tenancy,
              startingFrom: this.$options.filters.objectToDate(tenancy.startingAt),
              endingFrom: this.$options.filters.objectToDate(tenancy.endingAt)
            })
          }
        }
        this.listContactTenancies = list.flat().filter((v, i, a) => a.findIndex(t => (t === v)) === i)
        this.loadingTenancies = false
        // remove contact that is duplicated by id on listContactTenancies
        this.listContactTenancies = this.listContactTenancies.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i)
        this.getListCompanies()
        this.$nextTick(() => {
          if (this.preselectedContactPersons.length > 0) {
            this.preselectedContactPersons.forEach(participant => {
              const company = this.availableContact.find(x => ('contactPersons' in x) && x && x.contactPersons.some(x => x.id === participant.contactId))
              if (this.fromHandover === 'moveIn' && participant.type === 'PARTICIPANT_TYPE_TENANT_MOVE_IN' && this.selectedTenancy && company) {
                const contact = company.contactPersons.find(x => x.id === participant.contactId)
                this.selectedContactPersons.push(contact)
              }
              if (this.fromHandover === 'moveOut' && participant.type === 'PARTICIPANT_TYPE_TENANT_MOVE_OUT' && this.selectedTenancy && company) {
                const contact = company.contactPersons.find(x => x.id === participant.contactId)
                this.selectedContactPersons.push(contact)
              }
            })
          }
        })
      }
    },
    onRowClicked(contact, index, event) {
      if (contact.id) {
        if (event.srcElement.classList.contains('address-button')) {
          this.addAddress(contact.tenancy)
        }
        this.editContactClicked(this.availableContact.find(c => c.id === contact.id))
      }
    },
    contactUpdated(contact) {
      this.listContacts.find((element, index) => {
        if (element.id === contact.contact.id) {
          this.listContacts[index] = contact.contact
        }
      })
      this.contact = null
      this.$emit('contact-updated', contact.contact)
    },
    editContactClicked(contact) {
      this.contact = contact
      this.$nextTick(function () {
        this.$bvModal.show('create-contact-modal')
      })
    },
    addAddress(tenancy) {
      this.availableContact.find(contact => {
        if (contact.id === tenancy.tenant.contactIds[0]) {
          this.contact = contact
        }
      })
      this.$nextTick(function () {
        this.$bvModal.show('add-address-modal')
      })
    },
    getUrlWebsite(item) {
      if (item.indexOf('//') > -1) {
        return item
      } else {
        return '//' + item
      }
    },
    modalHide() {
      this.contact = null
      this.$nextTick(function () {
        this.$bvModal.hide('create-contact-modal')
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.text-grey {
  color: $color-text-grey-light;
}
:deep(#tenantsTable) {
  overflow: unset !important;
}
:deep(.sticky-header) {
  background-color: #f8f8f8 !important;
}
:deep(.b-table-sticky-column) {
  background-image: none !important;
}
:deep(.table .contact-person-name) {
    padding: 0.4rem 0.75rem;
}
</style>
