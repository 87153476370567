<template>
  <section class="w-100">
    <div v-if="countTenancy !== 0">
      <coozzy-detail-card
        :title="fromHandover === 'moveIn' ? 'moveIn' : fromHandover === 'moveOut' ? 'moveOut' : cardTitle"
        :is-adjustable="isCompanyAdmin"
        @adjust-fields="openAssembleListModal">
        <div class="col p-0">
          <div
            v-if="loadingTenancies"
            class="text-center">
            <coozzy-spinner />
          </div>
          <b-table
            v-else
            id="tenantsTable"
            ref="tenantsTable"
            bordered
            hover
            responsive
            selectable
            select-mode="single"
            :fields="fields"
            :items="tenantList"
            @row-clicked="onRowClicked"
            @row-middle-clicked="onRowMiddleClicked">
            <template #head(currentTenant)>
              {{ $t('message.onBoarding.buildings.objects.tenancies.tenants.current') }}
            </template>
            <template #head(subtenant)>
              {{ $t('message.tableColumnHeadings.subtenant') }}
            </template>
            <template #head(status)>
              {{ $t('message.tableColumnHeadings.status') }}
            </template>
            <template #head(objects)>
              {{ $t('message.tableColumnHeadings.objects') }}
            </template>
            <template #head(contractNumber)>
              {{ $t('message.tableColumnHeadings.contractNumber') }}
            </template>
            <template #head(tenancieObjectId)>
              {{ $t('message.tableColumnHeadings.tenancieObjectId') }}
            </template>
            <template #head(tenanciePartner)>
              {{ $t('message.tableColumnHeadings.tenanciePartner') }}
            </template>
            <template #head(type)>
              {{ $t('message.tableColumnHeadings.type') }}
            </template>
            <template #head(floor)>
              {{ $t('message.tableColumnHeadings.floor') }}
            </template>
            <template #head(rooms)>
              {{ $t('message.tableColumnHeadings.rooms') }}
            </template>
            <template #head(livingArea)>
              {{ $t('message.tableColumnHeadings.area') }}
            </template>
            <template #head(netRent)>
              {{ $t('message.onBoarding.buildings.objects.pricing.coldRent') }}
            </template>
            <template #head(grossRent)>
              {{ $t('message.onBoarding.buildings.objects.pricing.totalRent') }}
            </template>
            <template #head(startAt)>
              {{ $t('message.onBoarding.buildings.objects.tenancies.startAt') }}
            </template>
            <template #head(endAt)>
              {{ $t('message.onBoarding.buildings.objects.tenancies.endAt') }}
            </template>
            <template #head(assignee)>
              {{ $t('message.tableColumnHeadings.assignee') }}
            </template>
            <template #head(m2PerYear)>
              {{ $t('message.tableColumnHeadings.m2PerYear') }}
            </template>
            <template #head(marketNetRent)>
              {{ $t('message.tableColumnHeadings.marketNetRent') }}
            </template>
            <template #head(targetNetRent)>
              {{ $t('message.tableColumnHeadings.targetNetRent') }}
            </template>
            <template #head(marketGrossRent)>
              {{ $t('message.tableColumnHeadings.marketGrossRent') }}
            </template>
            <template #head(targetGrossRent)>
              {{ $t('message.tableColumnHeadings.targetGrossRent') }}
            </template>
            <template #head(subCategory)>
              {{ $t('message.tableColumnHeadings.subCategory') }}
            </template>
            <!-- Table content -->
            <template
              #cell(currentTenant)="data">
              <div v-if="data.item.vacancy === true">
                {{ $t('message.onBoarding.buildings.objects.tenancies.vacancy') }}
              </div>
              <div v-else-if="data.item.tenant.contactIds.length > 0">
                <template
                  v-for="(contactId, tenantIndex) in data.item.tenant.contactIds">
                  {{ tenantIndex === 0 ? '' : ', ' }}
                  <router-link
                    v-if="contactId !== contact.id"
                    :key="tenantIndex"
                    class="external-link"
                    :to="redirectToDetailPage(contactId)">
                    {{ getContactName(contactId) }}
                  </router-link>
                  <span
                    v-else
                    :key="tenantIndex"
                    class="currentContact">
                    {{ getContactName(contactId) }}
                  </span>
                </template>
              </div>
              <span v-else>-</span>
            </template>
            <template
              #cell(subtenant)="data">
              <div v-if="data.item.subTenants.length > 0">
                <template
                  v-for="(subtenant, subtenantIndex) in data.item.subTenants">
                  <template
                    v-if="subtenant.contactIds.length > 0">
                    <router-link
                      v-for="(contactId, tenantIndex) in subtenant.contactIds"
                      :key="Math.random() + '_' + tenantIndex"
                      class="external-link"
                      :to="redirectToDetailPage(contactId)">
                      {{ tenantIndex === 0 ? getContactName(contactId) : ', ' + getContactName(contactId) }}
                      <span v-if="tenantIndex === subtenant.contactIds.length - 1 && subtenantIndex < data.item.subTenants.length - 1">,</span>
                    </router-link>
                  </template>
                </template>
              </div>
              <span v-else>-</span>
            </template>
            <template
              #cell(status)="data">
              <template v-if="data.item.closed === true">
                <div
                  class="color-box mr-2 mt-2 closed" />
                {{ $t('message.onBoarding.buildings.objects.tenancies.status.closed') }}
                {{ getUnjustifiedDate(data.item.unjustifiedUse) }}
              </template>
              <template v-else-if="data.item.activated === false">
                <div
                  class="color-box mr-2 mt-2 not_activated" />
                {{ $t('message.onBoarding.buildings.objects.tenancies.status.not_activated') }}
                {{ getUnjustifiedDate(data.item.unjustifiedUse) }}
              </template>
              <template v-else-if="new Date($options.filters.objectToDateInput(data.item.startingAt)).getTime() > (new Date()).getTime()">
                <div
                  class="color-box mr-2 mt-2 future" />
                {{ $t('message.onBoarding.buildings.objects.tenancies.status.future') }}
                {{ getUnjustifiedDate(data.item.unjustifiedUse) }}
              </template>
              <template v-else-if="data.item.endingAt && new Date($options.filters.objectToDateInput(data.item.endingAt)).getTime() > (new Date()).getTime() && new Date($options.filters.objectToDateInput(data.item.startingAt)).getTime() <= (new Date()).getTime() && data.item.contract.type === 'LIMITED'">
                <div
                  class="color-box mr-2 mt-2 temporary" />
                {{ $t('message.onBoarding.buildings.objects.tenancies.status.current_temporary') }}
                {{ getUnjustifiedDate(data.item.unjustifiedUse) }}
              </template>
              <template v-else-if="data.item.endingAt && new Date($options.filters.objectToDateInput(data.item.endingAt)).getTime() > (new Date()).getTime() && new Date($options.filters.objectToDateInput(data.item.startingAt)).getTime() <= (new Date()).getTime()">
                <div
                  class="color-box mr-2 mt-2 terminated" />
                {{ $t('message.onBoarding.buildings.objects.tenancies.status.current_terminated') }}
                {{ getUnjustifiedDate(data.item.unjustifiedUse) }}
              </template>
              <template v-else-if="data.item.endingAt && data.item.endingAt.year > 0">
                <div
                  class="color-box mr-2 mt-2 terminated" />
                {{ $t('message.onBoarding.buildings.objects.tenancies.status.terminated') }}
                {{ getUnjustifiedDate(data.item.unjustifiedUse) }}
              </template>
              <template v-else-if="!data.item.endingAt || data.item.endingAt <= 0">
                <div
                  class="color-box mr-2 mt-2 current" />
                {{ $t('message.onBoarding.buildings.objects.tenancies.status.current') }}
                {{ getUnjustifiedDate(data.item.unjustifiedUse) }}
              </template>
              <template v-else>
                <div
                  class="color-box mr-2 mt-2 future" />
                {{ $t('message.onBoarding.buildings.objects.tenancies.status.future') }}
                {{ getUnjustifiedDate(data.item.unjustifiedUse) }}
              </template>
            </template>
            <template #cell(contractNumber)="data">
              <div
                class="" />
              {{ data.item.numericId }}
            </template>
            <template
              #cell(type)="data">
              <a
                v-if="getObject(data.item.objectId).category !== 'UNDEFINED_CATEGORY'"
                class="external-link"
                target="_blank"
                tabindex="0"
                @click="getRouteObject(data.item.objectId, getObject(data.item.objectId).buildingId)"
                @click.middle="getRouteObjectMiddle(data.item.objectId, getObject(data.item.objectId).buildingId)">
                {{ $t('message.onBoarding.buildings.objects.category.' + getObject(data.item.objectId).category.toLowerCase()) }}
              </a>
              <template v-else>
                -
              </template>
            </template>
            <template #cell(objectNumber)="data">
              <a
                class="link external-link"
                target="_blank"
                tabindex="0"
                @click="getRouteObject(data.item.objectId, getObject(data.item.objectId).buildingId)"
                @click.middle="getRouteObjectMiddle(data.item.objectId, getObject(data.item.objectId).buildingId)">
                {{ getObject(data.item.objectId).number | displayOptionalValue }}
              </a>
            </template>
            <template #cell(objects)="data">
              <a
                class="link external-link"
                target="_blank"
                tabindex="0"
                @click="getRouteObject(data.item.objectId, getObject(data.item.objectId).buildingId)"
                @click.middle="getRouteObjectMiddle(data.item.objectId, getObject(data.item.objectId).buildingId)">
                {{ getObject(data.item.objectId).name | displayOptionalValue }}
              </a>
            </template>
            <template #cell(tenancieObjectId)="data">
              <a
                v-if="getObject(data.item.objectId)"
                class="link external-link"
                target="_blank"
                tabindex="0"
                @click="getRouteObject(data.item.objectId, getObject(data.item.objectId).buildingId)"
                @click.middle="getRouteObjectMiddle(data.item.objectId, getObject(data.item.objectId).buildingId)">
                {{ getObject(data.item.objectId).numericId }}
              </a>
              <span v-else>-</span>
            </template>
            <template #cell(tenanciePartner)="data">
              <span class="d-block">
                <img
                  height="20px"
                  src="/img/propbase-icon.png"
                  alt="prop-base">
                <template v-if="getBuildingOwner(data.item.objectId) && getBuildingOwner(data.item.objectId).company">
                  {{ getBuildingOwner(data.item.objectId).company.name }}
                </template>
                <template v-if="getBuildingOwner(data.item.objectId) && getBuildingOwner(data.item.objectId).individual">
                  {{ getBuildingOwner(data.item.objectId).individual.firstName.substr(0, 1) }}. {{ getBuildingOwner(data.item.objectId).individual.lastName }}
                </template>
              </span>
              <span
                v-if="!$route.path.startsWith('/asset') && getNetworkPartners(data.item.objectId).length > 0 && getNetworkPartners(data.item.objectId).find(element => element.scope ==='ASSET_SCOPE')"
                class="d-block">
                <img
                  height="20px"
                  src="/img/assetbase-icon.png"
                  alt="asset-base">
                <template v-if="getNetworkPartners(data.item.objectId).find(element => element.scope ==='ASSET_SCOPE').company">
                  {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='ASSET_SCOPE').company.name }}
                </template>
                <template v-if="getNetworkPartners(data.item.objectId).find(element => element.scope ==='ASSET_SCOPE').individual">
                  {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='ASSET_SCOPE').individual.firstName.substr(0, 1) }}. {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='ASSET_SCOPE').individual.lastName }}
                </template>
              </span>
              <span
                v-if="!$route.path.startsWith('/admin') && getNetworkPartners(data.item.objectId).length > 0 && getNetworkPartners(data.item.objectId).find(element => element.scope ==='MANAGEMENT_SCOPE')"
                class="d-block">
                <img
                  height="20px"
                  src="/img/adminbase-icon.png"
                  alt="admine-base">
                <template v-if="getNetworkPartners(data.item.objectId).find(element => element.scope ==='MANAGEMENT_SCOPE').company">
                  {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='MANAGEMENT_SCOPE').company.name }}
                </template>
                <template v-if="getNetworkPartners(data.item.objectId).find(element => element.scope ==='MANAGEMENT_SCOPE').individual">
                  {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='MANAGEMENT_SCOPE').individual.firstName.substr(0, 1) }}. {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='MARKETING_SCOPE').individual.lastName }}
                </template>
              </span>
              <span
                v-if="!$route.path.startsWith('/accounting') && getNetworkPartners(data.item.objectId).length > 0 && getNetworkPartners(data.item.objectId).find(element => element.scope ==='ACCOUNTING_SCOPE')"
                class="d-block">
                <img
                  height="20px"
                  src="/img/financebase-icon.png"
                  alt="finance-base">
                <template v-if="getNetworkPartners(data.item.objectId).find(element => element.scope ==='ACCOUNTING_SCOPE').company">
                  {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='ACCOUNTING_SCOPE').company.name }}
                </template>
                <template v-if="getNetworkPartners(data.item.objectId).find(element => element.scope ==='ACCOUNTING_SCOPE').individual">
                  {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='ACCOUNTING_SCOPE').individual.firstName.substr(0, 1) }}. {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='ACCOUNTING_SCOPE').individual.lastName }}
                </template>
              </span>
              <span
                v-if="getNetworkPartners(data.item.objectId).length > 0 && getNetworkPartners(data.item.objectId).find(element => element.scope ==='MARKETING_SCOPE')"
                class="d-block">
                <img
                  height="20px"
                  src="/img/marketbase-icon.png"
                  alt="market-base">
                <template v-if="getNetworkPartners(data.item.objectId).find(element => element.scope ==='MARKETING_SCOPE').company">
                  {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='MARKETING_SCOPE').company.name }}
                </template>
                <template v-if="getNetworkPartners(data.item.objectId).find(element => element.scope ==='MARKETING_SCOPE').individual">
                  {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='MARKETING_SCOPE').individual.firstName.substr(0, 1) }}. {{ getNetworkPartners(data.item.objectId).find(element => element.scope ==='MARKETING_SCOPE').individual.lastName }}
                </template>
              </span>
            </template>
            <template
              #cell(floor)="data">
              {{ formatFloor(getObject(data.item.objectId) && getObject(data.item.objectId).characteristics ? getObject(data.item.objectId).characteristics.floor : '') }}
            </template>
            <template
              #cell(position)="data">
              {{ data.item.object ? getPositionLabelFromArray(getObject(data.item.objectId).object.positions) : '' }}
            </template>
            <template
              #cell(rooms)="data">
              {{ (getObject(data.item.objectId) && getObject(data.item.objectId).characteristics ? getObject(data.item.objectId).characteristics.rooms : '') | displayOptionalValue }}
            </template>
            <template
              #cell(livingArea)="data">
              <div v-if="['APARTMENT', 'HOUSE'].includes(getObject(data.item.objectId).category) ">
                {{ getObject(data.item.objectId) && getObject(data.item.objectId).characteristics && getObject(data.item.objectId).characteristics.areas ? getObject(data.item.objectId).characteristics.areas.livingArea : '' | formatArea }}
              </div>
              <div v-else>
                {{ getObject(data.item.objectId) && getObject(data.item.objectId).characteristics && getObject(data.item.objectId).characteristics.areas ? getObject(data.item.objectId).characteristics.areas.effectiveArea : '' | formatArea }}
              </div>
            </template>
            <template
              #cell(m2PerYear)="data">
              <div class="text-nowrap">
                <template>
                  <span>{{ Math.round(getAdjustedRentValues(data.item).coldRentM2PerYear) | formatPrice }}</span>
                </template>
              </div>
            </template>
            <template
              #cell(marketNetRent)="data">
              <div class="text-nowrap">
                <template v-if="getObject(data.item.objectId) && getObject(data.item.objectId).pricing && getObject(data.item.objectId).pricing.marketNetRent">
                  <span>{{ getObject(data.item.objectId).pricing.marketNetRent | formatPrice }}</span>
                </template>
                <template v-else>
                  -
                </template>
              </div>
            </template>
            <template
              #cell(targetNetRent)="data">
              <div class="text-nowrap">
                <template v-if="getObject(data.item.objectId) && getObject(data.item.objectId).pricing && getObject(data.item.objectId).pricing.targetMarketNetRent">
                  <span>{{ getObject(data.item.objectId).pricing.targetMarketNetRent | formatPrice }}</span>
                </template>
                <template v-else>
                  -
                </template>
              </div>
            </template>
            <template
              #cell(marketGrossRent)="data">
              <div class="text-nowrap">
                <template v-if="getObject(data.item.objectId) && getObject(data.item.objectId).pricing && getObject(data.item.objectId).pricing.marketGrossRent">
                  <span>{{ getObject(data.item.objectId).pricing.marketGrossRent | formatPrice }}</span>
                </template>
                <template v-else>
                  -
                </template>
              </div>
            </template>
            <template
              #cell(targetGrossRent)="data">
              <div class="text-nowrap">
                <template v-if="getObject(data.item.objectId) && getObject(data.item.objectId).pricing && getObject(data.item.objectId).pricing.targetMarketGrossRent">
                  <span>{{ getObject(data.item.objectId).pricing.targetMarketGrossRent | formatPrice }}</span>
                </template>
                <template v-else>
                  -
                </template>
              </div>
            </template>
            <template
              #cell(netRent)="data">
              <span
                v-if="data.item.taxable || data.item.opted"
                class="float-right">
                {{ getRentWithTax(data.item, 'netRent') | formatPrice('CHF') }}
                <coozzy-info-circle-icon
                  v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.inclTax')"
                  class="ml-1" />
              </span>
              <span
                v-else
                class="float-right">
                {{ getAdjustedRentValues(data.item).coldRentPerMonth | formatPrice('CHF') }}
              </span>
            </template>
            <template
              #cell(grossRent)="data">
              <span
                v-if="data.item.taxable || data.item.opted"
                class="float-right">
                {{ getRentWithTax(data.item, 'grossRent') | formatPrice('CHF') }}
                <coozzy-info-circle-icon
                  v-b-tooltip.hover.html="$t('message.onBoarding.buildings.objects.inclTax')"
                  class="ml-1" />
              </span>
              <span
                v-else
                class="float-right">
                {{ getTotalRent(data.item) | formatPrice('CHF') }}
              </span>
            </template>
            <template
              #cell(startAt)="data">
              {{ data.item.startingAt | objectToDate }}
            </template>
            <template
              #cell(endAt)="data">
              {{ data.item.endingAt | objectToDate }}
            </template>
            <template #cell(assignee)="data">
              <template v-if="isAdminModule">
                {{ getObject(data.item.objectId) && getObject(data.item.objectId).contacts.contactIdAssigneeAdministration !== '' ? getContactName(getObject(data.item.objectId).contacts.contactIdAssigneeAdministration) : '-' }}
              </template>
              <template v-if="isAccountingModule">
                {{ getObject(data.item.objectId) && getObject(data.item.objectId).contacts.contactIdAssigneeAccounting !== '' ? getContactName(getObject(data.item.objectId).contacts.contactIdAssigneeAccounting) : '-' }}
              </template>
              <template v-if="isAssetModule">
                {{ getObject(data.item.objectId) && getObject(data.item.objectId).contacts.contactIdAssigneeAsset !== '' ? getContactName(getObject(data.item.objectId).contacts.contactIdAssigneeAsset) : '-' }}
              </template>
              <template v-if="isOwnerModule">
                {{ getObject(data.item.objectId) && getObject(data.item.objectId).contacts.contactIdAssigneeOwner !== '' ? getContactName(getObject(data.item.objectId).contacts.contactIdAssigneeOwner) : '-' }}
              </template>
            </template>
            <template #cell(subCategory)="data">
              <template v-if="getObject(data.item.objectId)?.subCategories.length > 0">
                <template v-for="(subCat, subCatIndex) in getObject(data.item.objectId)?.subCategories">
                  <span
                    v-if="subCat !== 'UNDEFINED_SUB_CATEGORY'"
                    :key="subCatIndex">
                    {{ $t(`message.onBoarding.buildings.objects.subCategory.` + subCat?.toLowerCase()) }}
                  </span>
                  <template v-else>
                    -
                  </template>
                </template>
              </template>
              <template v-else>
                -
              </template>
            </template>
          </b-table>
          <div class="text-center">
            <a
              v-if="countTenancy > 5"
              class="link"
              @click="showAllTenancies = !showAllTenancies">
              <coozzy-angle-double-up-icon v-if="showAllTenancies === true" />
              <coozzy-angle-double-down-icon v-else />
            </a>
          </div>
        </div>
      </coozzy-detail-card>
    </div>
    <assemble-list-modal
      :ref="'assembleListModal'+type"
      :modal-id="'assembleListModal'+type"
      :fields="selectedFields"
      :default-fields="dataFields"
      @save-fields="saveFields" />
  </section>
</template>

<script>
import { tables } from '@/mixins/tables'
import { routeChecks } from '@/mixins/routeChecks'
import ObjectApi from '@/misc/apis/ObjectApi'
import ContactApi from '@/misc/apis/ContactApi'
import SearchApi from '@/misc/apis/SearchApi'
import ShareApi from '@/misc/apis/ShareApi'
// import UserApi from '@/misc/apis/UserApi'
import Vue from 'vue'
import AssembleListModal from '@/contact/components/AssembleListModal'
import { user } from '@/mixins/user'
import { formatting } from '@/mixins/formatting'
import NetworkPartnerAPI from '../../misc/apis/NetworkPartnerApi'
import UserApi from '../../misc/apis/UserApi'
import CoozzyDetailCard from '@/framework/components/card/CoozzyDetailCard.vue'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import CoozzyAngleDoubleUpIcon from '@/framework/components/icons/CoozzyAngleDoubleUpIcon.vue'
import CoozzyAngleDoubleDownIcon from '@/framework/components/icons/CoozzyAngleDoubleDownIcon.vue'
import CoozzyInfoCircleIcon from '@/framework/components/icons/CoozzyInfoCircleIcon.vue'
import { referenceInterestRate } from '@/mixins/referenceInterestRate'

export default {
  name: 'TenanciesContactCard',
  components: {
    CoozzyInfoCircleIcon,
    CoozzyAngleDoubleDownIcon,
    CoozzyAngleDoubleUpIcon,
    CoozzySpinner,
    CoozzyDetailCard,
    AssembleListModal
  },
  mixins: [tables, routeChecks, user, formatting, referenceInterestRate],
  props: {
    type: {
      type: String,
      default: 'TENANT'
    },
    availableContact: {
      type: Array,
      default() {
        return []
      }
    },
    contact: {
      type: Object,
      default: null
    },
    fromHandover: {
      type: String,
      default: ''
    },
    moveTenancies: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      dataFields: [
        {
          key: 'currentTenant',
          label: this.$t('message.onBoarding.buildings.objects.tenancies.tenants.current'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'status',
          label: this.$t('message.tableColumnHeadings.status'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'contractNumber',
          label: this.$t('message.tableColumnHeadings.contractNumber'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'type',
          label: this.$t('message.tableColumnHeadings.type'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'objects',
          label: this.$t('message.tableColumnHeadings.objects'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'objectNumber',
          label: this.$t('message.tableColumnHeadings.objectNumber'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'startAt',
          label: this.$t('message.onBoarding.buildings.objects.tenancies.startAt'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'endAt',
          label: this.$t('message.onBoarding.buildings.objects.tenancies.endAt'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'floor',
          label: this.$t('message.tableColumnHeadings.floor'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'rooms',
          label: this.$t('message.tableColumnHeadings.rooms'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'livingArea',
          label: this.$t('message.onBoarding.buildings.objects.livingSpaceShort'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'netRent',
          label: this.$t('message.onBoarding.buildings.objects.pricing.coldRent'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'grossRent',
          label: this.$t('message.onBoarding.buildings.objects.pricing.totalRent'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'assignee',
          label: this.$t('message.tableColumnHeadings.assignee'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'tenancieObjectId',
          label: this.$t('message.tableColumnHeadings.tenancieObjectId'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'tenanciePartner',
          label: this.$t('message.tableColumnHeadings.tenanciePartner'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'subtenant',
          label: this.$t('message.tableColumnHeadings.subtenant'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'm2PerYear',
          label: this.$t('message.tableColumnHeadings.m2PerYear'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'marketNetRent',
          label: this.$t('message.tableColumnHeadings.marketNetRent'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'targetNetRent',
          label: this.$t('message.tableColumnHeadings.targetNetRent'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'marketGrossRent',
          label: this.$t('message.tableColumnHeadings.marketGrossRent'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'targetGrossRent',
          label: this.$t('message.tableColumnHeadings.targetGrossRent'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        },
        {
          key: 'subCategory',
          label: this.$t('message.tableColumnHeadings.subCategory'),
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center',
          selected: false
        }
      ],
      fields: [],
      buildings: [],
      selectedFields: [],
      filterTenants: [],
      objects: [],
      shares: [],
      listContacts: [],
      networkPartners: [],
      showAllTenancies: false,
      loadingTenancies: false,
      ownerAccounts: [],
      tenantList: []
      // tax: [
      //   { start: null, end: '31-12-1994', value: '0' },
      //   { start: '01-01-1995', end: '31-12-1998', value: '6.5' },
      //   { start: '01-01-1999', end: '31-12-2000', value: '7.5' },
      //   { start: '01-01-2001', end: '31-12-2010', value: '7.6' },
      //   { start: '01-01-2011', end: '31-12-2017', value: '8.0' },
      //   { start: '01-01-2018', end: '31-12-2023', value: '7.7' },
      //   { start: '01-01-2024', end: null, value: '8.1' }
      // ]
    }
  },
  computed: {
    cardTitle() {
      return (this.type === 'GUARANTOR' ? this.$t('message.onBoarding.buildings.objects.tenancies.titleGuarantor') : this.$t('message.onBoarding.buildings.objects.tenancies.title')) + ' (' + this.countTenancy + ')'
    },
    countTenancy() {
      return this.filterTenants.length
    }
  },
  watch: {
    showAllTenancies: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.filterTenantList()
      }
    }
  },
  mounted() {
    this.loadData()
    if (this.accountId) {
      SearchApi.loadSettings(this.accountId).then(response => {
        const settings = response.tableSettings
        let hasOldList = false
        if (settings.length > 0) {
          settings.forEach(setting => {
            if (setting.columns.length > 0 && setting.table === 'tenancy_contact_card') {
              hasOldList = true
              this.loadFields(setting)
            }
          })
        }
        if (hasOldList === false) {
          let elm = null
          this.fields.push(...this.dataFields.filter(element => element.key === 'currentTenant' || element.key === 'status' || element.key === 'contractNumber' || element.key === 'type' || element.key === 'startAt' || element.key === 'endAt'))
          this.dataFields.forEach(element => {
            if (element.key === 'currentTenant' || element.key === 'status' || element.key === 'contractNumber' || element.key === 'type' || element.key === 'startAt' || element.key === 'endAt') {
              elm = {
                key: element.key,
                label: element.label,
                selected: true
              }
            } else {
              elm = {
                key: element.key,
                label: element.label,
                selected: false
              }
            }
            this.selectedFields.push(elm)
          })
          if (this.fields.length > 0) {
            this.fields[0].stickyColumn = true
            this.fields[0].thClass += ' sticky-header'
          }
        }
      }).catch(e => {
        console.log(e)
      })
    }
  },
  methods: {
    loadData() {
      if (this.moveTenancies.length > 0) {
        this.filterTenants = this.moveTenancies
        this.loadingTenancies = false
        this.filterTenantList()
      } else if (this.contact) {
        if (this.contact.ownerId && this.contact.id) {
          this.loadingTenancies = true
          const promises = []
          if (this.type === 'TENANT') {
            promises.push(ObjectApi.getTenanciesByTenant(this.contact.ownerId, this.contact.id))
          } else if (this.type === 'GUARANTOR') {
            promises.push(ObjectApi.getTenanciesByGuarantor(this.contact.id))
          }
          Promise.all(promises)
            .then(async response => {
              let list = []
              response.forEach(element => {
                list = list.concat(element.tenancies)
              })
              this.filterTenants = this.removeDuplicatedItems(list)
              // if (this.fromHandover !== '') {
              //   const t = this
              //   const allReceivedTenancies = this.filterTenants
              //   for (let index = 0; index < allReceivedTenancies.length; index++) {
              //     if (this.fromHandover === 'moveIn') {
              //       // check if allReceivedTenancies[index] have enddate is after today +1 week
              //       if (allReceivedTenancies[index].endingAt && new Date(t.$options.filters.objectToDateInput(allReceivedTenancies[index].endingAt)).getTime() > new Date().getTime() + 7 * 24 * 60 * 60 * 1000) {
              //         this.filterTenants.push(allReceivedTenancies[index])
              //       }
              //     } else if (this.fromHandover === 'moveOut') {
              //       if (!(allReceivedTenancies[index].endingAt && new Date(t.$options.filters.objectToDateInput(allReceivedTenancies[index].endingAt)).getTime() > new Date().getTime() + 7 * 24 * 60 * 60 * 1000)) {
              //         this.filterTenants.push(allReceivedTenancies[index])
              //       }
              //     }
              //   }
              // }
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.tenancy'), { type: 'error' })
            })
            .finally(() => {
              const objectIds = []
              for (let index = 0; index < this.filterTenants.length; index++) {
                objectIds.push(this.filterTenants[index].objectId)
              }
              ObjectApi.listObjectsByIds(objectIds)
                .then(response => {
                  this.objects = response.objects
                  let ids = []
                  this.filterTenants.forEach(element => {
                    ids = ids.concat(element.tenant.contactIds)
                    if (element && element.subTenants.length > 0) {
                      element.subTenants.forEach(contact => {
                        ids = ids.concat(contact.contactIds)
                      })
                    }
                  })
                  this.objects.forEach(element => {
                    if (element.contacts) {
                      ids.push(element.contacts.contactIdAssigneeOwner)
                      ids.push(element.contacts.contactIdAssigneeAsset)
                      ids.push(element.contacts.contactIdAssigneeAccounting)
                      ids.push(element.contacts.contactIdAssigneeAdministration)
                    }
                  })
                  this.loadListNetworkPartner()
                  this.loadShares()
                  this.loadOwnerAccounts()
                  ContactApi.contactResolve(ids).then(response => {
                    this.listContacts = response.companies.concat(response.persons)
                  }).finally(() => {
                    this.$emit('list-tenancies', this.filterTenants, this.listContacts)
                  })
                  this.loadingTenancies = false
                  this.filterTenantList()
                })
              this.loadingTenancies = false
            })
        }
      }
    },
    getRentWithTax(tenancy, type) {
      const adjustedRentValues = this.getAdjustedRentValues(tenancy)
      if (adjustedRentValues) {
        return this.calculateRentWithTax(adjustedRentValues, type)
      }
    },
    filterTenantList() {
      const t = this
      const array = this.filterTenants
      array.sort((a, b) => new Date(t.$options.filters.objectToDateInput(b.startingAt)).getTime() > new Date(t.$options.filters.objectToDateInput(a.startingAt)).getTime() ? -1 : 1)

      array.sort(function(x, y) {
        return (x.closed === y.closed) ? 0 : !x.closed ? -1 : 0
      })
      array.sort(function(x, y) {
        return (x.activated && !x.closed && new Date(t.$options.filters.objectToDateInput(x.endingAt)).getTime() > new Date(t.$options.filters.objectToDateInput(y.endingAt)).getTime()) ? -1 : 0
      })
      array.sort(function(x) {
        return (x.activated && x.endingAt) ? 0 : -1
      })
      array.sort(function(x, y) {
        return (x.activated === y.activated) ? 0 : !x.activated ? -1 : 0
      })
      if (this.showAllTenancies) {
        this.tenantList = array
      } else {
        this.tenantList = array.slice(0, 5)
      }
    },
    removeDuplicatedItems(items) {
      const uniqueIds = []
      const list = items.filter(element => {
        const isDuplicate = uniqueIds.includes(element.id)
        if (!isDuplicate) {
          uniqueIds.push(element.id)
          return true
        }
        return false
      })
      return list
    },
    getRouteObject(objectId, buildingId) {
      if (this.isOwnerModule) {
        this.$router.push({
          name: 'OwnerBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'objects',
            elementSelected: objectId
          }
        })
      } else if (this.isAdminModule) {
        this.$router.push({
          name: 'AdminBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'objects',
            elementSelected: objectId
          }
        })
      } else if (this.isAccountingModule) {
        this.$router.push({
          name: 'AccountingBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'objects',
            elementSelected: objectId
          }
        })
      } else if (this.isAssetModule) {
        this.$router.push({
          name: 'AssetBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'objects',
            elementSelected: objectId
          }
        })
      } else if (this.isMarketingModule) {
        if (this.hasAccessAdminBase) {
          this.$router.push({
            name: 'AdminBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: 'objects',
              elementSelected: objectId
            }
          })
        } else if (this.hasAccessAccountingBase) {
          this.$router.push({
            name: 'AccountingBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: 'objects',
              elementSelected: objectId
            }
          })
        }
      }
    },
    getRouteObjectMiddle(objectId, buildingId) {
      let route = ''
      if (this.isOwnerModule) {
        route = this.$router.resolve({
          name: 'OwnerBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'objects',
            elementSelected: objectId
          }
        })
      } else if (this.isAdminModule) {
        route = this.$router.resolve({
          name: 'AdminBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'objects',
            elementSelected: objectId
          }
        })
      } else if (this.isAccountingModule) {
        route = this.$router.resolve({
          name: 'AccountingBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'objects',
            elementSelected: objectId
          }
        })
      } else if (this.isAssetModule) {
        route = this.$router.resolve({
          name: 'AssetBuildingDetailsView',
          params: { id: buildingId },
          query: {
            view: 'objects',
            elementSelected: objectId
          }
        })
      } else if (this.isMarketingModule) {
        if (this.hasAccessAdminBase) {
          route = this.$router.resolve({
            name: 'AdminBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: 'objects',
              elementSelected: objectId
            }
          })
        } else if (this.hasAccessAccountingBase) {
          route = this.$router.resolve({
            name: 'AccountingBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: 'objects',
              elementSelected: objectId
            }
          })
        }
      }
      window.open(route.href)
    },
    onRowClicked(tenancy, index, event) {
      if (event.srcElement.classList.contains('currentContact')) {
        return
      }
      this.navigateToTenancy(tenancy)
    },
    onRowMiddleClicked(tenancy, index, event) {
      if (event.target.classList.contains('external-link')) {
        return
      }
      this.navigateToTenancy(tenancy, true)
    },
    navigateToTenancy(tenancy, openInNewTab = false) {
      const obj = this.objects.find(x => x.id === tenancy.objectId)
      const buildingId = obj.buildingId
      const tenancyId = tenancy.id

      let routeName = null
      if (this.isOwnerModule) {
        routeName = 'OwnerBuildingDetailsView'
      } else if (this.isAdminModule) {
        routeName = 'AdminBuildingDetailsView'
      } else if (this.isAccountingModule) {
        routeName = 'AccountingBuildingDetailsView'
      } else if (this.isAssetModule) {
        routeName = 'AssetBuildingDetailsView'
      } else if (this.isMarketingModule) {
        if (this.hasAccessAdminBase) {
          routeName = 'AdminBuildingDetailsView'
        } else if (this.hasAccessAccountingBase) {
          routeName = 'AccountingBuildingDetailsView'
        }
      }

      if (routeName) {
        const route = {
          name: routeName,
          params: { id: buildingId },
          query: {
            view: 'tenants',
            elementSelected: tenancyId
          }
        }

        if (openInNewTab) {
          window.open(this.$router.resolve(route).href, '_blank')
        } else {
          this.$router.push(route)
        }
      }
    },
    loadOwnerAccounts() {
      ObjectApi.getBuildingsListByIds(this.objects.map(x => x.buildingId)).then(response => {
        const promises = []
        this.buildings = response.buildings
        if (response.buildings.length > 0) {
          response.buildings.forEach(element => {
            promises.push(UserApi.getAccount(element.ownerId))
          })
          Promise.all(promises).then(response => {
            response.forEach(element => {
              this.ownerAccounts.push(element.account)
            })
          })
        }
      })
    },
    getBuildingOwner(objectId) {
      let building = null
      let account = null
      const obj = this.objects.find(x => x.id === objectId)
      if (obj) {
        building = this.buildings.find(x => x.id === obj.buildingId)
      }
      if (building) {
        account = this.ownerAccounts.find(x => x.id === building.ownerId)
      }
      return account
    },
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting)
      this.fields = result?.fields.filter(x => x.key !== 'checkbox')
      this.selectedFields = result?.selectedFields
      this.fields.map(function(x) {
        x.stickyColumn = false
        return x
      })
      if (this.fields.length > 0) {
        this.fields[0].stickyColumn = true
        this.fields[0].thClass += ' sticky-header'
      }
      this.$refs.objectsTable?.refresh()
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      SearchApi.saveSettings(this.accountId, columns, 'tenancy_contact_card').then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal' + this.type)
      })
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal' + this.type)
    },
    loadShares() {
      if (this.objects.length > 0) {
        ShareApi.listByResource(this.objects.map(obj => obj.id))
          .then(async response => {
            this.shares = response.shares
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.template'), { type: 'error' })
          })
      }
    },
    loadListNetworkPartner() {
      NetworkPartnerAPI.listNetworkPartner(
        0,
        0,
        [],
        ['LANDLORD', 'MARKETING', 'LANDLORD_MARKETING', 'ASSET_MANAGEMENT', 'ASSET_LANDLORD', 'ASSET_ACCOUNTING', 'ACCOUNTING', 'LANDLORD_ACCOUNTING', 'MARKETING_ACCOUNTING', 'LANDLORD_MARKETING_ACCOUNTING', 'ASSET_LANDLORD_MARKETING_ACCOUNTING', 'ASSET_MARKETING', 'ASSET_LANDLORD_MARKETING', 'ASSET_LANDLORD_ACCOUNTING', 'ASSET_MARKETING_ACCOUNTING'],
        '',
        '',
        ''
      ).then(response => {
        this.networkPartners = response.accounts
      })
    },
    getNetworkPartners(objectId) {
      const shares = this.shares.filter(share => {
        return share.resourceIds.includes(objectId)
      })
      if (shares.length > 0 && this.networkPartners.length !== 0) {
        const partners = this.networkPartners.filter(partner => {
          return shares.map(share => share.targetAccountId).includes(partner.id)
        })
        const networkPartner = []
        partners.forEach(element => {
          const cnd = shares.filter(share => share.targetAccountId === element.id)
          if (cnd.length > 0) {
            for (let index = 0; index < cnd.length; index++) {
              element.scope = cnd[index].scope
              networkPartner.push({ ...element })
            }
          }
        })
        return networkPartner
      }

      return []
    },
    getUnjustifiedDate(UnjustifiedObj) {
      return (UnjustifiedObj && UnjustifiedObj.until !== null && UnjustifiedObj.from !== null && UnjustifiedObj.until !== undefined
? '(' + this.$t('message.enterTermination.unjustifiedUse.statusWithEnd', {
        startDate: this.$options.filters.objectToDate(UnjustifiedObj.from),
        endDate: this.$options.filters.objectToDate(UnjustifiedObj.until)
      }) + ')'
: UnjustifiedObj && UnjustifiedObj.until === null && UnjustifiedObj.from !== null
? '(' + this.$t('message.enterTermination.unjustifiedUse.statusWithoutEnd', {
        startDate: this.$options.filters.objectToDate(UnjustifiedObj.from)
      }) + ')'
: '')
    },
    getObject(id) {
      if (this.objects.length > 0) {
        return this.objects.find(obj => obj.id === id)
      }
      return null
    },
    getContactName(contactId) {
      const contact = this.listContacts.find(function (contact) {
        return contact.id === contactId || contact.objectID === contactId
      })

      return contact ? contact.name : '-'
    },
    redirectToDetailPage(contactId) {
      if (contactId !== null) {
        if (this.isOwnerModule) {
          return { name: 'OwnerContactDetailsView', params: { id: contactId } }
        } else if (this.isAssetModule) {
          return { name: 'AssetContactDetailsView', params: { id: contactId } }
        } else if (this.isAccountingModule) {
          return { name: 'AccountingContactDetailsView', params: { id: contactId } }
        } else {
          return { name: 'AdminContactDetailsView', params: { id: contactId } }
        }
      }
    },
    getAdjustedRentValues(tenancy) {
      let currentRent = tenancy.rent.adjustments[0]
      const current = this
      const pastAdjustments = tenancy.rent.adjustments.filter(obj => parseInt(obj.byDate) !== -1 && new Date(current.$options.filters.objectToDateInput(obj.byDate)) < Date.now())
      if (pastAdjustments.length > 0) {
        pastAdjustments.sort(function (a, b) {
          return new Date(current.$options.filters.objectToDateInput(b.byDate)) - new Date(current.$options.filters.objectToDateInput(a.byDate))
        })
        currentRent = pastAdjustments[0]
      }
      return currentRent
    },
    getTotalRent(tenancy) {
      const adjustedRentValues = this.getAdjustedRentValues(tenancy)
      const grossRent = (adjustedRentValues.heatingCosts > 0 ? adjustedRentValues.heatingCosts : 0) +
        (adjustedRentValues.additionalCosts > 0 ? adjustedRentValues.additionalCosts : 0) +
        (adjustedRentValues.coldRent > 0 ? adjustedRentValues.coldRent : 0)
      const dueDates = adjustedRentValues.dueDates.length > 0 ? adjustedRentValues.dueDates.length : 1
      return (grossRent * dueDates) / 12
    }
  }
}
</script>

<style lang="scss" scoped>
.text-grey {
  color: $color-text-grey-light;
}
:deep(#tenantsTable) {
  overflow: unset !important;
}
:deep(.sticky-header) {
  background-color: #f8f8f8 !important;
}
:deep(.b-table-sticky-column) {
  background-image: none !important;
}
</style>
