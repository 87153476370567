<template>
  <coozzy-card :title="$t('message.settings.mailbox.title')">
    <div class="row justify-content-between align-items-center">
      <div class="col-12 col-md-2">
        <coozzy-dropdown
          design="green"
          size="sm"
          class="w-100"
          :text="selectedEntriesText">
          <coozzy-dropdown-item
            v-if="isEditor"
            :disabled="selectedEntries.length === 0"
            @click="$refs['automatic-response-modal'].show()">
            {{ $t('message.settings.mailbox.setActivatedResponse') }}
          </coozzy-dropdown-item>
          <coozzy-dropdown-item
            :disabled="selectedEntries.length === 0"
            @click="selectedEntries.length !== 0 ? activateMailBox(selectedEntries, true) : ''">
            {{ $t('message.generic.activate') }}
          </coozzy-dropdown-item>
          <coozzy-dropdown-item
            :disabled="selectedEntries.length === 0"
            @click="selectedEntries.length !== 0 ? activateMailBox(selectedEntries, false) : ''">
            {{ $t('message.generic.deactivate') }}
          </coozzy-dropdown-item>
          <coozzy-dropdown-item
            :disabled="selectedEntries.length === 0"
            @click="selectedEntries.length !== 0 ? setRemoveMailboxConfidential() : ''">
            {{ $t('message.settings.confidentialEmails.setRemoveConfidential') }}
          </coozzy-dropdown-item>
        </coozzy-dropdown>
      </div>
      <div class="col-12 col-md-2">
        <coozzy-button
          v-if="isCompanyAdmin"
          class="my-2 float-right w-100"
          design="prop-green"
          @click="$refs['add-mailbox-modal'].show()">
          {{ $t('message.settings.mailbox.create') }}
        </coozzy-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-table
          ref="mailBoxesTable"
          bordered
          hover
          responsive="true"
          stacked="md"
          selectable
          no-select-on-click
          select-mode="single"
          class="mb-0"
          :busy="loading"
          :fields="fields"
          :items="updatedMailBoxList"
          @row-clicked="onRowClicked">
          <!-- Busy state -->
          <div
            slot="table-busy"
            class="text-center text-danger my-2">
            <coozzy-spinner />
          </div>

          <!-- Header -->
          <template #head(checkbox)>
            <coozzy-form-checkbox
              ref="header_checkbox"
              @change="(value) => { handleCheckboxesInsideTable(value) }" />
          </template>
          <template #head(inbound)>
            {{ $t('message.settings.mailbox.inbound') }}
          </template>
          <template #head(outbound)>
            {{ $t('message.settings.mailbox.outbound') }}
          </template>
          <template #head(name)>
            {{ $t('message.settings.mailbox.name') }}
          </template>
          <template #head(mainUser)>
            {{ $t('message.settings.mailbox.mainUser') }}
          </template>
          <template #head(allowedUser)>
            {{ $t('message.settings.mailbox.allowedUsers') }}
          </template>
          <template #head(status)>
            {{ $t('message.generic.status') }}
          </template>
          <template #head(automaticResponseStatus)>
            {{ $t('message.settings.mailbox.automaticResponse') }}
          </template>
          <template #head(confidential)>
            {{ $t('message.settings.confidentialEmails.confidential') }}
          </template>

          <!-- Body -->
          <template
            #cell(checkbox)="data">
            <!-- data needs to be used in order to work properly -->
            <coozzy-form-checkbox
              :id="'check_' + data.item.id"
              @change="(value) => { itemSelected(data.item.id, value) }" />
          </template>
          <template
            #cell(inbound)="data">
            {{ data.item.inboundEmail }}
          </template>
          <template
            #cell(outbound)="data">
            {{ data.item.outboundEmail }}
          </template>
          <template
            #cell(name)="data">
            <span>{{ data.item.name }}</span>
          </template>
          <template
            #cell(mainUser)="data">
            <span>{{ getEmployeeName(data.item.mainUserId) }}</span>
          </template>
          <template
            #cell(allowedUser)="data">
            <span
              v-for="(element, index) in data.item.allowedUserIds"
              :key="index">
              {{ getEmployeeName(element) }}<br>
            </span>
          </template>
          <template
            #cell(status)="data">
            <coozzy-button
              v-if="data.item.active"
              class="btn-active"
              @click="activateMailBox([data.item.id], false)">
              {{ $t('message.generic.active') }}
            </coozzy-button>
            <coozzy-button
              v-else
              class="btn-inactive"
              @click="activateMailBox([data.item.id], true)">
              {{ $t('message.generic.inactive') }}
            </coozzy-button>
          </template>
          <template #cell(automaticResponseStatus)="data">
            <span>{{ getAutomaticResponseStatus(data.item.automaticReply) }}</span>
          </template>
          <template #cell(confidential)="data">
            <div class="text-center">
              <template v-if="data.item.confidential">
                <coozzy-success-icon />
              </template>
              <template v-else>
                <coozzy-fail-icon />
              </template>
            </div>
          </template>
        </b-table>
      </div>
    </div>
    <create-mailbox-modal
      ref="add-mailbox-modal"
      :employee-list="employeeList"
      @mailbox-created="mailboxCreated" />
    <automatic-response-mailbox-modal
      ref="automatic-response-modal"
      :key="selectedEntries.length > 0 ? selectedEntries.map(x => x.id).toString() : null"
      :mail-boxes="mailBoxList.filter(x => selectedEntries.includes(x.id))"
      @automatic-response-created="clearCheckbox(); getMailBoxList()" />
    <edit-mailbox-modal
      ref="edit-mailbox-modal"
      :key="selectedMailbox ? selectedMailbox.id : null"
      :employee-list="employeeList"
      :default-mailbox="selectedMailbox"
      @mailbox-edited="mailboxEdited" />
    <b-modal
      :id="'confirmationDeleteMailboxModal'"
      ref="confirmationDeleteMailboxModal"
      no-close-on-backdrop
      :title="$t('message.settings.mailbox.confirmation.title')"
      hide-footer
      @ok="deleteMailbox()">
      <div class="col">
        <p>{{ $t('message.settings.mailbox.confirmation.text') }}</p>
      </div>
      <div class="col">
        <coozzy-button
          size="small"
          class="float-left mb-0 border"
          design="transparent"
          @click="$bvModal.hide('confirmationDeleteMailboxModal')">
          {{ $t('message.generic.cancel') }}
        </coozzy-button>
        <coozzy-button
          design="green"
          class="float-right ml-3"
          @click="deleteMailbox()">
          {{ $t('message.generic.delete') }}
        </coozzy-button>
      </div>
    </b-modal>
  </coozzy-card>
</template>

<script>
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import CreateMailboxModal from './CreateMailboxModal'
import AutomaticResponseMailboxModal from './AutomaticResponseMailboxModal'
import EditMailboxModal from './EditMailboxModal'
import MessageApi from '../../misc/apis/MessageApi'
import Vue from 'vue'
import CoozzyDropdown from '../../framework/components/dropdown/CoozzyDropdown'
import CoozzyDropdownItem from '../../framework/components/dropdown/CoozzyDropdownItem'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import CoozzySpinner from '../../framework/components/misc/CoozzySpinner'
import CoozzyFormCheckbox from '../../framework/components/form/checkbox/CoozzyFormCheckbox'
import { mapActions, mapGetters } from 'vuex'
import { tables } from '@/mixins/tables'
import { user } from '@/mixins/user'
import CoozzySuccessIcon from '@/framework/components/icons/CoozzySuccessIcon.vue'
import CoozzyFailIcon from '@/framework/components/icons/CoozzyFailIcon.vue'

export default {
  name: 'MailboxSettings',
  components: {
    CoozzyFailIcon,
    CoozzySuccessIcon,
    CoozzyFormCheckbox,
    CoozzySpinner,
    CoozzyButton,
    CoozzyDropdownItem,
    CoozzyDropdown,
    CoozzyCard,
    CreateMailboxModal,
    EditMailboxModal,
    AutomaticResponseMailboxModal
    },
  mixins: [tables, user],
  data() {
    return {
      mailBoxList: [],
      selectedDomain: null,
      loading: false,
      selectedEntries: [],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox align-middle',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'inbound',
          label: this.$t('message.settings.mailbox.inbound'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'outbound',
          label: this.$t('message.settings.mailbox.outbound'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'name',
          label: this.$t('message.settings.mailbox.name'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'mainUser',
          label: this.$t('message.settings.mailbox.mainUser'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'allowedUser',
          label: this.$t('message.settings.mailbox.allowedUsers'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'status',
          label: this.$t('message.generic.status'),
          thClass: 'align-middle',
          tdClass: 'align-middle status-cell cursor-pointer'
        },
        {
          key: 'automaticResponseStatus',
          label: this.$t('message.settings.mailbox.automaticResponse'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'confidential',
          label: '',
          thClass: 'align-middle text-center',
          tdClass: 'align-middle text-center'
        }
      ],
      selectedMailbox: null
    }
  },
  computed: {
    selectedEntriesText() {
      return this.$t('message.generic.selectedEntries') + ':  ' + this.selectedEntries.length
    },
    updatedMailBoxList() {
      const list = this.mailBoxList ? this.mailBoxList : []
      // sort list by active status
      list.sort((a, b) => (a.active === b.active) ? 0 : a.active ? -1 : 1)
      list.forEach(function (element) {
        element._cellVariants = { status: element.active ? 'green-cell' : 'red-cell' }
      })
      return list
    },
    employeeList() {
      if (this.getEmployees().length > 0) {
        return this.getEmployees().filter(
          e => !e.deleted
        )
      }
      return []
    }
  },
  mounted() {
    this.getMailBoxList()
    this.loadEmployees()
  },
  methods: {
    ...mapGetters('employee', ['getEmployees']),
    ...mapActions('employee', ['loadEmployees']),
    ...mapActions('message', ['resetMailboxes']),
    onRowClicked(item, index, event) {
      if (event.srcElement.classList.contains('custom-checkbox') || event.srcElement.classList.contains('checkbox-cell')) {
        this.itemSelected(item.id, !document.getElementById('check_' + item.id).checked)
        document.getElementById('check_' + item.id).checked = !document.getElementById('check_' + item.id).checked
      } else {
        this.changeSelectedMailbox(item, index, event)
      }
    },
    changeSelectedMailbox(item) {
      this.selectedMailbox = item
      this.$nextTick(function () {
        this.$refs['edit-mailbox-modal'].show()
      })
    },
    mailboxCreated(response) {
      this.mailBoxList.push(response.mailbox)
    },
    mailboxEdited(mailbox, id) {
      for (let index = 0; index < this.mailBoxList.length; index++) {
        if (this.mailBoxList[index].id === id) {
          this.mailBoxList[index].outboundEmail = mailbox.outbound
          this.mailBoxList[index].name = mailbox.name
          this.mailBoxList[index].signatureId = mailbox.signatureId
          this.mailBoxList[index].mainUserId = mailbox.mainUser
          this.mailBoxList[index].allowedUserIds = mailbox.allowedUsers.map(obj => obj.id)
        }
      }
    },
    getMailBoxList() {
      this.loading = false
      if (this.isCompanyAdmin) {
        MessageApi.listMailboxes(this.$store.getters['user/getAccountId'])
          .then(response => {
            this.mailBoxList = response.mailboxes
            this.loading = false
          })
          .catch(e => {
            console.log(e)
            this.loading = false
            Vue.toasted.show(this.$t('message.loadingErrors.mailbox'), { type: 'error' })
          })
      } else {
        MessageApi.listMailboxesByUserId(this.$store.getters['user/getUserId'])
          .then(response => {
            this.mailBoxList = response.mailboxes
            this.loading = false
          })
          .catch(e => {
            console.log(e)
            this.loading = false
            Vue.toasted.show(this.$t('message.loadingErrors.mailbox'), { type: 'error' })
          })
      }
    },
    getAutomaticResponseStatus(automaticResponse) {
      if (automaticResponse?.enabled && automaticResponse.startDate) {
        const today = new Date()
        let startDate = null
        let endDate = null
        startDate = new Date(this.$options.filters.objectToDateInput(automaticResponse.startDate))
        startDate.setHours(automaticResponse?.startTime?.hours, automaticResponse?.startTime?.minutes, 0, 0)

        if (automaticResponse.endDate) {
          endDate = new Date(this.$options.filters.objectToDateInput(automaticResponse.endDate))
          endDate.setHours(automaticResponse?.endTime?.hours, automaticResponse?.endTime?.minutes, 0, 0)
        }

        if (startDate && (!endDate || (endDate && endDate.getTime() >= today.getTime()))) {
          if (endDate && endDate.getTime() >= today.getTime()) {
            const startHour = automaticResponse?.startTime?.hours
            const startMinutes = '0' + automaticResponse?.startTime?.minutes
            const endHour = automaticResponse?.endTime?.hours
            const endMinutes = '0' + automaticResponse?.endTime?.minutes
            return this.$t('message.settings.mailbox.automaticResponseStatusWithEnd', {
              from: this.$options.filters.objectToDate(automaticResponse.startDate),
              startTime: (((startHour < 10) ? '0' + startHour : startHour) + ':' + startMinutes.substr(-2)),
              until: this.$options.filters.objectToDate(automaticResponse.endDate),
              endTime: (((endHour < 10) ? '0' + endHour : endHour) + ':' + endMinutes.substr(-2))
            })
          }
          const startHour = automaticResponse?.startTime?.hours
          const startMinutes = '0' + automaticResponse?.startTime?.minutes
          return this.$t('message.settings.mailbox.automaticResponseStatusWithoutEnd', {
            from: this.$options.filters.objectToDate(automaticResponse.startDate),
            startTime: (((startHour < 10) ? '0' + startHour : startHour) + ':' + startMinutes.substr(-2))
          })
        }
      }
      return '-'
    },
    clearCheckbox() {
      this.selectedEntries = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    activateMailBox(selectedEntries, status) {
      if (status) {
        MessageApi.activateMailboxes(selectedEntries)
          .then(() => {
            for (let index = 0; index < selectedEntries.length; index++) {
              this.mailBoxList.find(x => x.id === selectedEntries[index]).active = true
              this.mailBoxList.find(x => x.id === selectedEntries[index])._cellVariants = 'green-cell'
              this.resetMailboxes()
            }
            this.clearCheckbox()
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.mailbox'), { type: 'error' })
          })
      } else {
        MessageApi.deactivateMailboxes(selectedEntries)
          .then(() => {
            for (let index = 0; index < selectedEntries.length; index++) {
              this.mailBoxList.find(x => x.id === selectedEntries[index]).active = false
              this.mailBoxList.find(x => x.id === selectedEntries[index])._cellVariants = 'red-cell'
              this.resetMailboxes()
            }
            this.clearCheckbox()
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.loadingErrors.mailbox'), { type: 'error' })
          })
      }
    },
    handleCheckboxesInsideTable(value) {
      const allCheckboxesComponents = this.$refs.mailBoxesTable.$children[1].$children
      this.handleCheckbox(allCheckboxesComponents, value)
    },
    itemSelected(id, checked) {
      if (checked && !this.selectedEntries.includes(id)) {
        this.selectedEntries.push(id)
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry !== id)
      }
    },
    deleteMailbox() {
      this.loading = false
      MessageApi.deleteMailboxes(this.selectedEntries)
        .then(() => {
          this.resetMailboxes()
          this.$refs.confirmationDeleteMailboxModal.hide()
          for (let index = 0; index < this.selectedEntries.length; index++) {
            const indexToDelete = this.mailBoxList.map(function(x) { return x.id }).indexOf(this.selectedEntries[index])
            this.mailBoxList.splice(indexToDelete, 1)
          }
          this.loading = false
          this.selectedEntries = []
          this.$refs.header_checkbox.checked = false
        })
        .catch(e => {
          console.log(e)
          this.loading = false
          Vue.toasted.show(this.$t('message.deleteErrors.mailbox'), { type: 'error' })
        })
    },
    getEmployeeName(id) {
      const employee = this.employeeList.find(function (employee) {
        return employee.id === id
      })
      return employee ? employee.profile.firstName + ' ' + employee.profile.lastName : '-'
    },
    copyClicked(text) {
      this.$clipboard(text)
    },
    setRemoveMailboxConfidential() {
      let mailBoxToSetConfidential = []
      let mailBoxToRemoveConfidential = []
      let promise = []
      if (this.selectedEntries.length > 0) {
        this.selectedEntries.forEach((element) => {
          const mailbox = this.mailBoxList.find(x => x.id === element)
          if (mailbox.confidential) {
            mailBoxToRemoveConfidential.push(element)
          } else {
            mailBoxToSetConfidential.push(element)
          }
        })
      }
      if (mailBoxToSetConfidential.length > 0) {
        promise.push(MessageApi.setMailboxesConfidential(true, mailBoxToSetConfidential))
      }
      if (mailBoxToRemoveConfidential.length > 0) {
        promise.push(MessageApi.setMailboxesConfidential(false, mailBoxToRemoveConfidential))
      }
      Promise.all(promise)
        .then(() => {
          this.clearCheckbox()
          this.getMailBoxList()
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.applicationForm.creditTrust.certificateOrderError'), { type: 'error' })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  :deep(.copy-icon) {
    color: $color-primary !important;
  }
</style>
