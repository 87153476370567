<template>
  <section :class="[readOnly ? 'is-disabled' : '']">
    <coozzy-card class="global-card no-top-border table-card">
      <div class="row justify-content-between pb-1">
        <div class="col pl-0">
          <h5 class="ml-3 pt-1">
            {{ devicesCount }} {{ $t('message.onBoarding.devices.title') }}
          </h5>
        </div>
        <div
          v-if="isFiltered"
          class="col-12 col-md-2">
          <coozzy-button
            class="w-100 sort"
            @click="initFilter()">
            <coozzy-filter-icon /> {{ $t('message.generic.clearFilters') }}
          </coozzy-button>
        </div>
        <div
          v-if="isEditor"
          class="col-12 col-md-2">
          <coozzy-button
            design="prop-green"
            class="w-100 sort"
            @click="readOnly ? addNewDevice(true) : $bvModal.show('createDeviceModal')">
            {{ $t('message.onBoarding.devices.add') }}
          </coozzy-button>
        </div>
      </div>
      <div class="row justify-content-between align-items-center my-2">
        <div class="col-12 col-md mb-2 mb-md-0">
          <coozzy-form-input
            ref="searchText"
            v-model="searchText"
            :placeholder="$t('message.generic.search')"
            @keyup="searchInputText()" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchCategories"
            class="filter-inputs"
            :options="sortedFilterValues(categoryFilter, customLabelCategory , 'value')"
            :multiple="true"
            :placeholder="$t('message.onBoarding.devices.filter.category')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelCategory"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterDevicesList"
            @remove="filterDevicesList" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchRoom"
            class="filter-inputs"
            :options="roomFilter"
            :multiple="true"
            :placeholder="$t('message.onBoarding.devices.room')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelRoom"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterDevicesList"
            @remove="filterDevicesList" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchInstallation"
            class="filter-inputs"
            :options="sortedFilterValues(installationData, customLabelInstallation , 'value')"
            :multiple="false"
            :placeholder="$t('message.onBoarding.devices.filter.deviceStatus')"
            :close-on-select="true"
            :clear-on-select="true"
            :preserve-search="true"
            :custom-label="customLabelInstallation"
            :track-by="'label'"
            :preselect-first="false"
            :taggable="false"
            @select="filterDevicesList"
            @remove="filterDevicesList" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchFloor"
            class="filter-inputs"
            :options="floorFilter"
            :multiple="true"
            :disabled="isBuildingCategory"
            :placeholder="$t('message.tableColumnHeadings.floor')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelFloor"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterDevicesList"
            @remove="filterDevicesList" />
        </div>
        <div class="col-12 col-md-2">
          <coozzy-multiselect
            v-model="searchRooms"
            :disabled="isBuildingCategory"
            class="filter-inputs"
            :options="roomsFilter"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.rooms')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelFloorRoom"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterDevicesList"
            @remove="filterDevicesList" />
        </div>
      </div>
      <div class="row my-2">
        <div
          v-if="properties[0].type !== 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP'"
          class="col-12 col-md-2">
          <coozzy-multiselect
            v-model="searchTenant"
            class="filter-inputs"
            :options="sortedFilterValues(tenantFilter, customLabelAssignee , 'value')"
            :disabled="isBuildingCategory"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.tenant')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelAssignee"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterDevicesList"
            @remove="filterDevicesList" />
        </div>
        <div
          v-else
          class="col-12 col-md-2">
          <coozzy-multiselect
            v-model="searchOwner"
            class="filter-inputs"
            :options="sortedFilterValues(ownerFilter, customLabelAssignee , 'value')"
            :disabled="isBuildingCategory"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.owner')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelAssignee"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterDevicesList"
            @remove="filterDevicesList" />
        </div>
        <div class="col-12 col-md-2 mb-2 mb-md-0">
          <coozzy-multiselect
            v-model="searchAssignee"
            class="filter-inputs"
            :options="sortedFilterValues(assigneeFilter, customLabelAssignee , 'value')"
            :disabled="isBuildingCategory"
            :multiple="true"
            :placeholder="$t('message.tableColumnHeadings.assignee')"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            :custom-label="customLabelAssignee"
            :track-by="'value'"
            :preselect-first="false"
            :taggable="false"
            @select="filterDevicesList"
            @remove="filterDevicesList" />
        </div>
      </div>
      <div
        id="rowConfig"
        class="row align-items-center">
        <div
          class="col-md-2">
          <coozzy-dropdown
            design="green"
            size="sm"
            class="w-100 sort"
            :text="selectedEntriesText">
            <coozzy-dropdown-item
              v-if="isTicketingEnabled && (isEditor || isTicketEditor || isTicketCreator) && (isOwnerModule || isAssetModule)"
              :disabled="selectedEntriesGlobal.length === 0"
              @click="createTicket">
              {{ $t('message.ticketOverview.createTicket') }}
            </coozzy-dropdown-item>
            <coozzy-dropdown
              v-if="!isOwnerModule && !isAssetModule && isTicketingEnabled && (isEditor || isTicketEditor || isTicketCreator)"
              design="transparent"
              size="sm"
              class="w-100 ticket-dropdown-bulk-action"
              :class="selectedEntriesGlobal.length > 0 ? 'enabled' : ''"
              :disabled="true"
              :text="$t('message.ticketOverview.createTicket')"
              dropright
              @click.prevent>
              <coozzy-dropdown-item
                :disabled="selectedEntriesGlobal.length === 0"
                @click="navigateToCreateOrder()">
                {{ $t('message.ticketOverview.createOrder') }}
              </coozzy-dropdown-item>
              <coozzy-dropdown-item
                :disabled="selectedEntriesGlobal.length === 0"
                @click="createTicket">
                {{ $t('message.ticketOverview.createTask') }}
              </coozzy-dropdown-item>
            </coozzy-dropdown>
          </coozzy-dropdown>
        </div>
        <div class="col-md-2 col-sm-6 col-12">
          <template v-if="selectAllVisibleEntries && nbrTotalOfHits > nbrPerPage && nbrTotalOfHits <= 1000">
            <coozzy-form-checkbox
              v-model="selectAllEntries"
              :initial="selectAllEntries"
              :class="'p-0 w-100 btn-height-auto'"
              name="selectAllEntries"
              button>
              {{ selectAllEntries ? $t('message.generic.unselectHiddenEntries', { amount: nbrTotalOfHits }) : $t('message.generic.selectHiddenEntries', { amount: nbrTotalOfHits }) }}
            </coozzy-form-checkbox>
          </template>
        </div>
        <div
          v-if="isCompanyAdmin === true"
          class="offset-md-5 col-md-1 d-flex justify-content-end align-items-center">
          <div
            @click="openAssembleListModal()">
            <coozzy-filters-icon
              v-b-tooltip="{container: '#rowConfig', title: $t('message.generic.tooltipAssembleList')}" />
          </div>
        </div>
        <div
          class="col-md-2 col-sm-6 col-12 mt-2 mt-sm-0"
          :class="isCompanyAdmin !== true ? 'offset-md-8' : ''">
          <coozzy-form-select
            v-model="nbrPerPage"
            name="numberEntries"
            class="select-entries float-right">
            <option value="5">
              5 {{ $t('message.generic.entries') }}
            </option>
            <option value="10">
              10 {{ $t('message.generic.entries') }}
            </option>
            <option value="15">
              15 {{ $t('message.generic.entries') }}
            </option>
            <option value="20">
              20 {{ $t('message.generic.entries') }}
            </option>
            <option value="all">
              {{ $t('message.manageInterestedParties.filter.allEntries') }}
            </option>
          </coozzy-form-select>
        </div>
      </div>
      <b-table
        ref="deviceTable"
        bordered
        hover
        responsive="true"
        stacked="md"
        selectable
        select-mode="single"
        :fields="fields"
        :items="deviceList"
        :busy="isDeviceLoading"
        :table-class="elementSelected !== null && elementSelected !== '' ? 'b-table-selecting' : ''"
        class="overflow-auto shadow"
        @row-clicked="changeSelectedDevice">
        <template
          #head(checkbox)>
          <coozzy-form-checkbox
            ref="header_checkbox"
            @change="(value) => { handleCheckboxesInsideTable(value) }" />
        </template>
        <template #head(description)>
          {{ $t('message.tableColumnHeadings.description') }}
        </template>
        <template #head(room)>
          {{ $t('message.tableColumnHeadings.room') }}
        </template>
        <template #head(componentsDevices)>
          <coozzy-sort-dropdown
            ref="type_sort"
            sorting-field="type"
            initial-sorting="desc"
            :text="$t('message.tableColumnHeadings.componentsDevices')"
            @updateSorting="updateSorting" />
        </template>
        <template #head(brand)>
          {{ $t('message.tableColumnHeadings.brand') }}
        </template>
        <template #head(model)>
          {{ $t('message.tableColumnHeadings.model') }}
        </template>
        <template #head(acquisitionCosts)>
          <coozzy-sort-dropdown
            ref="acquisitionCosts_sort"
            sorting-field="acquisitionCosts"
            initial-sorting="desc"
            :text="$t('message.tableColumnHeadings.acquisitionCosts')"
            @updateSorting="updateSorting" />
        </template>
        <template #head(installation)>
          <coozzy-sort-dropdown
            ref="installation_sort"
            sorting-field="installation"
            initial-sorting="desc"
            :text="$t('message.tableColumnHeadings.installation')"
            @updateSorting="updateSorting" />
        </template>
        <template #head(uninstallation)>
          <coozzy-sort-dropdown
            ref="uninstallation_sort"
            sorting-field="uninstallation"
            initial-sorting="desc"
            :text="$t('message.tableColumnHeadings.uninstallation')"
            @updateSorting="updateSorting" />
        </template>
        <template #head(warrantyExpiration)>
          <coozzy-sort-dropdown
            ref="warrantyExpiration_sort"
            sorting-field="warrantyExpiration"
            initial-sorting="desc"
            :text="$t('message.tableColumnHeadings.warrantyExpiration')"
            @updateSorting="updateSorting" />
        </template>
        <template #head(manufacturer)>
          {{ $t('message.tableColumnHeadings.manufacturer') }}
        </template>
        <template #head(serialNumber)>
          {{ $t('message.tableColumnHeadings.serialNumber') }}
        </template>
        <template #head(floor)>
          {{ $t('message.tableColumnHeadings.floor') }}
        </template>
        <template #head(unit)>
          {{ $t('message.tableColumnHeadings.unit') }}
        </template>
        <template #head(amount)>
          {{ $t('message.tableColumnHeadings.amount') }}
        </template>
        <template #head(lifeSpan)>
          {{ $t('message.tableColumnHeadings.lifeSpan') }}
        </template>
        <template #head(calculatedReplacement)>
          {{ $t('message.tableColumnHeadings.calculatedReplacement') }}
        </template>
        <template #head(task)>
          {{ $t('message.tableColumnHeadings.task') }}
        </template>
        <template #head(order)>
          {{ $t('message.tableColumnHeadings.order') }}
        </template>
        <template #head(tickets)>
          {{ $t('message.tableColumnHeadings.tickets') }}
        </template>
        <!-- Body -->
        <template
          #cell(checkbox)="data">
          <coozzy-form-checkbox
            :id="'check_' + data.item.id"
            @change="(value) => { itemSelectedGlobal(data.item, value) }" />
        </template>
        <template #cell(description)="data">
          <template v-if="data.item.referenceId.includes('building_')">
            {{ $t('message.onBoardingTabs.building') }}
          </template>
          <template v-else-if="getObject(data.item.referenceId)">
            {{ $t('message.onBoarding.buildings.objects.titleObject') + ' ' + getObject(data.item.referenceId).name }}
          </template>
        </template>
        <template #cell(room)="data">
          {{ getRoomeName(data.item.roomId) }}
        </template>
        <template #cell(componentsDevices)="data">
          <template v-if="data.item.type && data.item.type !== 'DEVICE_TYPE_UNDEFINED'">
            {{ $t('message.onBoarding.devices.typeValues.' + data.item.type).length > 50 ? $t('message.onBoarding.devices.typeValues.' + data.item.type).slice(0,50)+ '...' : $t('message.onBoarding.devices.typeValues.' + data.item.type) }}
          </template>
          <template v-else>
            -
          </template>
        </template>
        <template #cell(brand)="data">
          {{ data.item.brand | displayOptionalValue }}
        </template>
        <template #cell(acquisitionCosts)="data">
          {{ data.item.acquisitionCosts | formatPrice }}
        </template>
        <template #cell(model)="data">
          {{ data.item.model | displayOptionalValue }}
        </template>
        <template #cell(installation)="data">
          {{ data.item.installation | objectToDate }}
        </template>
        <template #cell(uninstallation)="data">
          {{ data.item.uninstallation | objectToDate }}
        </template>
        <template #cell(warrantyExpiration)="data">
          {{ data.item.warrantyEnd | objectToDate }}
        </template>
        <template #cell(manufacturer)="data">
          {{ data.item.manufacturer }}
        </template>
        <template #cell(serialNumber)="data">
          {{ data.item.serialNumber }}
        </template>
        <template #cell(floor)="data">
          {{ data.item.referenceId.startsWith('obj') ? getFloor(data.item.referenceId) : '-' }}
        </template>
        <template #cell(unit)="data">
          {{ $t('message.onBoarding.devices.unitOptions.' + data.item.unit) }}
        </template>
        <template #cell(amount)="data">
          {{ data.item.amount | displayOptionalValue }}
        </template>
        <template #cell(lifeSpan)="data">
          {{ data.item.lifeSpan }}
        </template>
        <template #cell(calculatedReplacement)="data">
          {{ fillOutCalculatedReplacement(data.item) | displayOptionalValue }}
        </template>
        <template #cell(order)="data">
          <span
            @click="redirectToActivitiesView(data.item.id, 'TICKET_TYPE_ORDER')"
            @click.middle="redirectMiddleToActivitiesView(data.item.id, 'TICKET_TYPE_ORDER')">{{ amountTickets(data.item.id, 'orders') | displayOptionalValue }}</span>
        </template>
        <template #cell(task)="data">
          <span
            @click="redirectToActivitiesView(data.item.id, 'TICKET_TYPE_TASK')"
            @click.middle="redirectMiddleToActivitiesView(data.item.id, 'TICKET_TYPE_TASK')">{{ amountTickets(data.item.id, 'tasks') | displayOptionalValue }}</span>
        </template>
        <template #cell(tickets)="data">
          <span
            @click="redirectToActivitiesView(data.item.id)"
            @click.middle="redirectMiddleToActivitiesView(data.item.id)">
            {{ amountTickets(data.item.id, 'orders') + amountTickets(data.item.id, 'tasks') | displayOptionalValue }}
          </span>
        </template>
      </b-table>

      <b-pagination
        v-if="nbrPerPage !== 'all'"
        v-model="currentPage"
        align="center"
        class="mt-3 mb-3"
        :total-rows="listDevices ? listDevices.length : 0"
        :per-page="nbrPerPage" />
    </coozzy-card>
    <div
      v-if="selectedDevice">
      <coozzy-card
        id="device-global-card"
        class="global-card">
        <div class="row">
          <div class="col-6">
            <h5>
              <span
                v-if="getObjectDetailRoute(selectedDevice)">
                <router-link
                  :to="getObjectDetailRoute(selectedDevice)">
                  {{ (selectedDevice.referenceId.includes('building') ? $t('message.manageBuilding.building') : $t('message.onBoarding.buildings.objects.titleObject')) + objectName }}
                </router-link>
                - {{ deviceTitle }}
              </span>
              <span v-else>
                {{ (selectedDevice.referenceId.includes('building') ? $t('message.manageBuilding.building') : $t('message.onBoarding.buildings.objects.titleObject')) + objectName }} - {{ deviceTitle }}
              </span>
              <a
                tabindex="0"
                class="lh-1 link-activity "
                @click="openActivities(selectedDevice)"
                @click.middle="openActivitiesMiddle(selectedDevice)">
                <img
                  height="15px"
                  class="ml-3 mb-1"
                  :src="`/img/ticket/new-window.svg`"
                  alt="type">
                {{ $t('message.ticketOverview.activities.title') }}
              </a>
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <device
              ref="device"
              :key="`device-${selectedDevice.id}`"
              :index="-1"
              :target="selectedDevice.referenceId.includes('building') ? 'building' : 'object'"
              :device="selectedDevice"
              :read-only="readOnly"
              :duplicate-button="false"
              :add-new-document="selectedDevice.id && deviceIdNewDocument !== '' && deviceIdNewDocument === selectedDevice.id"
              @duplicate-device="duplicateDevice"
              @device-update="deviceUpdated"
              @removeConfirmed="removeDevice()" />
          </div>
        </div>
      </coozzy-card>
    </div>
    <b-modal
      :id="'createDeviceModal'"
      ref="createDeviceModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.onBoarding.devices.add')">
      <template>
        <div class="col p-0">
          <b-table
            id="table-groups"
            ref="table-groups"
            hover
            bordered
            responsive="true"
            stacked="md"
            class="mb-4"
            :busy="isBusy"
            :fields="fieldsAddDevice"
            :items="listBuildingWithObject">
            <!-- Busy state -->
            <div
              slot="table-busy"
              class="text-center text-danger my-2">
              <coozzy-spinner />
            </div>
            <template #head(category)>
              {{ $t('message.advertisement.objectCategory.name') }}
            </template>
            <template #head(name)>
              {{ $t('message.onBoarding.devices.description') }}
            </template>
            <template #head(tenant)>
              {{ $t('message.tableColumnHeadings.tenant') }}
            </template>
            <template #head(condominium)>
              {{ $t('message.onBoardingTabs.condominium') }}
            </template>
            <!-- Body -->
            <template #cell(checkbox)="data">
              <coozzy-form-checkbox
                :id="'check_' + data.item.id"
                :disabled="selectedEntries.length > 0 && !selectedEntries.includes(data.item.id)"
                @change="(value) => { itemSelected(data.item, value) }" />
            </template>
            <template #cell(category)="data">
              <template v-if="data.item.category && data.item.category === 'building'">
                {{ $t('message.generic.building') }}
              </template>
              <template v-else-if="data.item.category && data.item.category !== 'UNDEFINED_CATEGORY'">
                {{ $t('message.onBoarding.buildings.objects.category.' + data.item.category.toLowerCase()) }}
              </template>
              <template v-else>
                -
              </template>
            </template>
            <template #cell(name)="data">
              {{ data.item.name }}
            </template>
            <template #cell(tenant)="data">
              {{ getTenantName(data.item) }}
            </template>
            <template #cell(condominium)="data">
              {{ getCondominiumName(data.item) }}
            </template>
          </b-table>
        </div>
        <div class="col p-0">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('createDeviceModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            :disabled="selectedEntries.length < 1"
            @click="createDeviceClicked()">
            {{ $t('message.generic.add') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      ref="modal-confirmation"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.generic.duplicate')">
      <div class="row mb-2">
        <div class="col">
          <p class="mb-3">
            {{ $t('message.onBoarding.devices.duplicatePopup') }}
          </p>
          <coozzy-form-input
            v-model="duplicateCount"
            class="mb-3"
            :name="$t('message.generic.duplicate')" />
          <coozzy-button
            size="normal"
            design="green"
            @click="duplicateConfirmation">
            {{ $t('message.generic.duplicate') }}
          </coozzy-button>
          <coozzy-button
            size="normal"
            class="float-right mr-3"
            design="red"
            @click="closeModal">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
        </div>
      </div>
    </b-modal>
    <assemble-list-modal
      ref="assembleListModal"
      modal-id="assembleListModal"
      :fields="selectedFields"
      :default-fields="dataFields"
      @save-fields="saveFields" />
  </section>
</template>

<script>
import Device from './components/Device'
import { onboarding } from '@/mixins/onboarding'
import CoozzyCard from '../../framework/components/card/CoozzyCard'
import CoozzyFormInput from '../../framework/components/form/input/CoozzyFormInput'
import CoozzyButton from '../../framework/components/button/CoozzyButton'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect'
import CoozzySortDropdown from '@/framework/components/dropdown/CoozzySortDropdown'
import CoozzyMultiselect from '@/framework/components/multiselect/CoozzyMultiselect'
import { tables } from '@/mixins/tables'
import { obj } from '@/mixins/object'
import CoozzyFilterIcon from '@/framework/components/icons/CoozzyFilterIcon'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import CoozzyFormCheckbox from '@/framework/components/form/checkbox/CoozzyFormCheckbox'
import { dateUtils } from '@/mixins/dateUtils'
import { algolia } from '@/mixins/algolia'
import { formatting } from '@/mixins/formatting'
import { mapMutations } from 'vuex'
import { routeChecks } from '@/mixins/routeChecks'
import ContactApi from '../../misc/apis/ContactApi'
import { user } from '@/mixins/user'
import AssembleListModal from '@/contact/components/AssembleListModal'
import SearchApi from '@/misc/apis/SearchApi'
import TicketApi from '@/misc/apis/TicketApi'
import CoozzyDropdownItem from '@/framework/components/dropdown/CoozzyDropdownItem.vue'
import CoozzyDropdown from '@/framework/components/dropdown/CoozzyDropdown.vue'
import CoozzyFiltersIcon from '@/framework/components/icons/CoozzyFiltersIcon.vue'
import debounce from 'debounce'

export default {
  name: 'CreateDevicesTab',
  components: {
    CoozzyFiltersIcon,
    CoozzyDropdown,
    CoozzyDropdownItem,
    CoozzyFormCheckbox,
    CoozzySpinner,
    CoozzyFilterIcon,
    CoozzySortDropdown,
    CoozzyFormSelect,
    CoozzyMultiselect,
    CoozzyButton,
    CoozzyFormInput,
    CoozzyCard,
    Device,
    AssembleListModal
  },
  mixins: [onboarding, dateUtils, tables, routeChecks, obj, algolia, user, formatting],
  props: {
    elementSelected: {
      type: String,
      default: ''
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    addNewItemDevice: {
      type: Boolean,
      default: false
    },
    deviceFilters: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLoaded: false,
      nbrPerPage: 5,
      currentPage: 1,
      duplicateCount: 0,
      selectedDevice: null,
      deviceToDuplicate: null,
      isBusy: false,
      isDeviceLoading: false,
      sorting: null,
      sortValue: '',
      sortField: '',
      searchText: '',
      selectedEntries: [],
      selectedEntriesGlobal: [],
      fieldsAddDevice: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        },
        {
          key: 'category',
          label: this.$t('message.advertisement.objectCategory.name'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'name',
          label: this.$t('message.onBoarding.devices.description'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'tenant',
          label: this.$t('message.tableColumnHeadings.tenant'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        },
        {
          key: 'condominium',
          label: this.$t('message.onBoardingTabs.condominium'),
          thClass: 'align-middle',
          tdClass: 'align-middle'
        }
      ],
      fields: [
        {
          key: 'checkbox',
          label: '',
          thClass: 'th-checkbox',
          tdClass: 'align-middle border-right-0 checkbox-cell'
        }
      ],
      dataFields: [
        {
          key: 'description',
          label: this.$t('message.tableColumnHeadings.description'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'room',
          label: this.$t('message.tableColumnHeadings.room'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'componentsDevices',
          label: this.$t('message.tableColumnHeadings.componentsDevices'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'brand',
          label: this.$t('message.tableColumnHeadings.brand'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'model',
          label: this.$t('message.tableColumnHeadings.model'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'installation',
          label: this.$t('message.tableColumnHeadings.installation'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'uninstallation',
          label: this.$t('message.tableColumnHeadings.uninstallation'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'warrantyExpiration',
          label: this.$t('message.tableColumnHeadings.warrantyExpiration'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: true
        },
        {
          key: 'acquisitionCosts',
          label: this.$t('message.tableColumnHeadings.acquisitionCosts'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'manufacturer',
          label: this.$t('message.tableColumnHeadings.manufacturer'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'serialNumber',
          label: this.$t('message.tableColumnHeadings.serialNumber'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'floor',
          label: this.$t('message.tableColumnHeadings.floor'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'unit',
          label: this.$t('message.tableColumnHeadings.unit'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'amount',
          label: this.$t('message.tableColumnHeadings.amount'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'lifeSpan',
          label: this.$t('message.tableColumnHeadings.lifeSpan'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'calculatedReplacement',
          label: this.$t('message.tableColumnHeadings.calculatedReplacement'),
          thClass: 'align-middle text-right',
          tdClass: 'align-middle text-right',
          selected: false
        },
        {
          key: 'task',
          label: this.$t('message.tableColumnHeadings.task'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'order',
          label: this.$t('message.tableColumnHeadings.order'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        },
        {
          key: 'tickets',
          label: this.$t('message.tableColumnHeadings.tickets'),
          thClass: 'align-middle',
          tdClass: 'align-middle',
          selected: false
        }
      ],
      searchFloor: [],
      tickets: [],
      searchRooms: [],
      searchRoom: [],
      searchAssignee: [],
      searchTenant: [],
      searchOwner: [],
      searchCategories: [],
      searchInstallation: null,
      listDevices: [],
      selectedFields: [],
      deviceIdNewDocument: '',
      selectAllVisibleEntries: false,
      selectAllEntries: false,
      allEntries: [],
      debouncedFilterDevicesList: null
    }
  },
  computed: {
    nbrTotalOfHits() {
      return this.listDevices.length
    },
    selectedEntriesText() {
      const count = this.selectAllEntries && this.selectAllVisibleEntries ? this.nbrTotalOfHits : this.selectedEntriesGlobal.length
      return this.$t('message.generic.selectedEntries') + ':  ' + count
    },
    selectedEntriesGlobalIds() {
      return this.selectAllEntries ? this.allEntries.map(x => x.id) : this.selectedEntriesGlobal.map(x => x.id)
    },
    devicesCount() {
      return this.listDevices !== null ? this.listDevices.length : 0
    },
    isFiltered() {
      return this.searchText !== '' || this.searchInstallation !== null || this.searchCategories.length > 0 || this.searchRooms.length > 0 || this.searchRoom.length > 0 || this.searchFloor.length > 0 || this.searchAssignee.length > 0 || this.searchTenant.length > 0 || this.searchOwner.length > 0
    },
    isBuildingCategory() {
      return this.searchCategories.map(x => x.value).includes('building')
    },
    listBuildingWithObject() {
      const list = []
      list.push({ name: this.building.name, type: 'building', id: this.building.id, category: 'building' })
      this.objects.forEach(element => {
        const name = element.number + (element.name && element.number ? ' - ' + element.name : element.name ? element.name : '')
        list.push({ name: name, type: 'object', id: element.id, category: element.category })
      })
      return list
    },
    installationData() {
      return [
        { label: 'installed', value: this.$t('message.onBoarding.devices.filter.installed') },
        { label: 'uninstalled', value: this.$t('message.onBoarding.devices.filter.uninstalled') }
      ]
    },
    floorFilter() {
      const counts = []
      const current = this
      const allDevice = current.alldevice.filter(d => {
        const installed = d.uninstallation === null
        const uninstalled = d.uninstallation !== null
        if (this.searchInstallation && this.searchInstallation.label === 'installed') {
          return installed
        } else if (this.searchInstallation && this.searchInstallation.label === 'uninstalled') {
          return uninstalled
        }
        return true
      })
      allDevice.map(obj => current.getObject(obj.referenceId) && current.getObject(obj.referenceId).characteristics.floor).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== -10000 && x !== undefined && x !== 'undefined') {
          const sameNetwork = allDevice.map(obj => current.getObject(obj.referenceId) && current.getObject(obj.referenceId).characteristics.floor).filter(z => z === x && x !== undefined)
          if (x !== -10000) {
            if (existingCount.length === 0) {
              counts.push({
                count: sameNetwork.length,
                value: x
              })
            } else {
              counts.push({
                count: 1,
                value: x
              })
            }
          }
        }
      })
      return counts
    },
    roomsFilter() {
      const counts = []
      const current = this
      current.alldevice.map(obj => current.getObject(obj.referenceId) && current.getObject(obj.referenceId).characteristics.rooms).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== -1 && x !== undefined && x !== 'undefined') {
          const sameNetwork = current.alldevice.map(obj => current.getObject(obj.referenceId) && current.getObject(obj.referenceId).characteristics.rooms).filter(z => z === x)
          if (x !== -1) {
            if (existingCount.length === 0) {
              counts.push({
                count: sameNetwork.length,
                value: x
              })
            } else {
              counts.push({
                count: 1,
                value: x
              })
            }
          }
        }
      })
      return counts
    },
    roomFilter() {
      const counts = []
      const current = this
      current.alldevice.map(obj => current.objectRooms.concat(current.buildingRooms).find(r => obj.roomId === r.id)).forEach(function(x) {
        if (x !== -1 && x !== undefined && x !== 'undefined') {
          const existingCount = counts.filter(e => e.value === x.name)
          if (existingCount.length === 0) {
            const sameNetwork = current.alldevice.map(obj => current.objectRooms.concat(current.buildingRooms).find(r => obj.roomId === r.id)).filter(z => z && z.name === x.name)
            if (x !== -1) {
              if (existingCount.length === 0) {
                counts.push({
                  count: sameNetwork.length,
                  value: x.name
                })
              } else {
                counts.push({
                  count: 1,
                  value: x.name
                })
              }
            }
          }
        }
      })
      return counts
    },
    assigneeFilter() {
      let assigneeList = []
      const current = this
      let unassigned = 0
      const counts = []
      const allDevice = current.alldevice.filter(d => {
        const installed = d.uninstallation === null
        const uninstalled = d.uninstallation !== null
        if (this.searchInstallation && this.searchInstallation.label === 'installed') {
          return installed
        } else if (this.searchInstallation && this.searchInstallation.label === 'uninstalled') {
          return uninstalled
        }
        return true
      })
      allDevice.map(obj => current.getObject(obj.referenceId)).forEach(function(element) {
        if (element && element.contacts && element.contacts.contactIdAssigneeAdministration !== null && element.contacts.contactIdAssigneeAdministration !== '') {
          assigneeList.push(element)
        } else {
          unassigned += 1
        }
      })
      assigneeList = [].concat.apply([], assigneeList)
      assigneeList.forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x.contacts.contactIdAssigneeAdministration)
        if (existingCount.length === 0) {
          const sameAssignee = allDevice.map(obj => current.getObject(obj.referenceId)).filter(z => z && x.contacts && z.contacts && z.contacts.contactIdAssigneeAdministration === x.contacts.contactIdAssigneeAdministration)
          if (sameAssignee.length > 1) {
            counts.push({
              count: sameAssignee.length,
              value: x.contacts.contactIdAssigneeAdministration
            })
          } else {
            counts.push({
              count: 1,
              value: x.contacts.contactIdAssigneeAdministration
            })
          }
        }
      })
      if (unassigned !== 0) {
        counts.push({
          count: unassigned,
          value: ''
        })
      }
      return counts
    },
    ownerFilter() {
      let ownerList = []
      const current = this
      const counts = []
      current.alldevice.map(obj => obj.referenceId.includes('obj_') && current.getCurrentCondominium(current.getObject(obj.referenceId).id)).forEach(function(element) {
        if (element) {
          const currentCondominiumOwner = element.owner.contactIds.map((element) => current.getContact(element))
          if (currentCondominiumOwner) {
            ownerList.push(currentCondominiumOwner)
          }
        }
      })
      ownerList = [].concat.apply([], ownerList)
      ownerList.forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x?.id)
        if (existingCount.length === 0) {
          const sameAssignee = ownerList.filter(z => z?.id === x?.id)
          if (sameAssignee.length > 1) {
            counts.push({
              count: sameAssignee.length,
              value: x?.id
            })
          } else {
            counts.push({
              count: 1,
              value: x?.id
            })
          }
        }
      })
      return counts
    },
    tenantFilter() {
      const counts = []
      for (const device of this.alldevice) {
        if (device.referenceId.startsWith('obj_')) {
          const tenancy = this.getCurrentTenancy(device.referenceId)
          if (tenancy) {
            for (const contactId of tenancy.tenant.contactIds) {
              const contact = this.getContact(contactId)
              if (contact) {
                const existingCount = counts.find(c => c.value === contactId)
                if (existingCount) {
                  existingCount.count += 1
                } else {
                  counts.push({
                    count: 1,
                    value: contactId
                  })
                }
              }
            }
          }
        }
      }
      return counts
    },
    networkFilter() {
      let networksList = []
      const counts = []
      const listObject = []
      this.deviceList.forEach(element => {
        const list = this.getNetworkPartnersObject(element.referenceId)
        if (list.length < 2) {
          listObject.push(element.referenceId)
        }
        networksList.push(list)
      })
      networksList = [].concat.apply([], networksList)
      networksList.forEach(function(x) {
        const existingCount = counts.filter(e => e.id === x.id)
        if (existingCount.length === 0) {
          const sameNetwork = networksList.filter(n => n.id === x.id)
          if (sameNetwork.length > 1) {
            x.objectIds = sameNetwork.map(obj => obj.objectId)
          } else {
            x.objectIds = [x.objectId]
          }
          counts.push(x)
        }
      })
      const countOfUnassigned = parseInt(this.alldevice.length) * 2 - networksList.length
      if (countOfUnassigned > 0) {
        const obj = { name: 'unassigned nicht zugeordnet non assegnato', objectIds: listObject }
        counts.push(obj)
      }
      return counts
    },
    categoryFilter() {
      const counts = []
      const current = this
      counts.push({
        count: current.alldevice.filter(x => x.referenceId.includes('building_')).length,
        value: 'building'
      })
      current.alldevice.map(obj => current.getObject(obj.referenceId) && current.getObject(obj.referenceId).category).forEach(function(x) {
        const existingCount = counts.filter(e => e.value === x)
        if (existingCount.length === 0 && x !== 'UNDEFINED_CATEGORY' && x) {
          const sameNetwork = current.alldevice.map(obj => current.getObject(obj.referenceId) && current.getObject(obj.referenceId).category).filter(z => z === x)
          if (sameNetwork.length > 1) {
            counts.push({
              count: sameNetwork.length,
              value: x
            })
          } else {
            counts.push({
              count: 1,
              value: x
            })
          }
        }
      })
      return counts
    },
    alldevice() {
      const sortedObjectDevices = this.objectDevices
      const current = this
      sortedObjectDevices.sort(function(a, b) {
        const indexA = current.objects.findIndex(d => d.id === a.referenceId)
        const indexB = current.objects.findIndex(d => d.id === b.referenceId)
        if (indexA === indexB) {
          return a.id > b.id ? -1 : 1
        }
        return indexA > indexB ? 1 : -1
      })
      return this.buildingDevices.concat(sortedObjectDevices)
    },
    deviceList: {
      get() {
        let list = []
        if (this.listDevices && this.listDevices.length > 0) {
          list = this.listDevices
        }
        if (this.nbrPerPage !== 'all') {
          list = list.slice((this.currentPage - 1) * this.nbrPerPage, this.currentPage * this.nbrPerPage)
        }
        return list
      },
      set(value) {
        if (this.listDevices.length === 0) {
          this.listDevices = value
        }
        if (this.isLoaded === false) {
          const isNewCreatedDevice = JSON.parse(localStorage.getItem('isNewCreatedDevice'))
          if (this.elementSelected && !isNewCreatedDevice) {
            this.changeSelectedDevice(this.listDevices.find(dev => dev.id === this.elementSelected))
            const indexOfDevice = this.listDevices.map(function(e) { return e.id }).indexOf(this.elementSelected)
            if (indexOfDevice !== -1) {
              this.currentPage = (Math.floor((indexOfDevice) / this.nbrPerPage)) + 1
              this.listDevices[indexOfDevice]._rowVariant = 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary'
              this.isLoaded = true
            }
          }
        }
      }
    },
    objectName() {
      let str = ''
      if (this.getObject(this.selectedDevice.referenceId)) {
        str = ' ' + this.getObject(this.selectedDevice.referenceId).name
      }
      return str
    },
    deviceTitle() {
      let str = ''
      if (this.selectedDevice.type && this.selectedDevice.type !== 'DEVICE_TYPE_UNDEFINED') {
        str = this.$t('message.onBoarding.devices.typeValues.' + this.selectedDevice.type)
      }
      return str
    }
  },
  watch: {
    searchCategories: function () {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'deviceFilters')
      }
    },
    currentPage: function () {
      this.selectedEntries = []
      this.selectedEntriesGlobal = []
      this.handleCheckboxesInsideTable(false)
      this.$refs.header_checkbox.checked = false
    },
    searchRoom: function () {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'deviceFilters')
      }
    },
    searchText: function () {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'deviceFilters')
      }
    },
    searchRooms: function () {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'deviceFilters')
      }
    },
    searchInstallation: function () {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'deviceFilters')
      }
    },
    searchFloor: function () {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'deviceFilters')
      }
    },
    searchTenant: function () {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'deviceFilters')
      }
    },
    searchOwner: function () {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'deviceFilters')
      }
    },
    searchAssignee: function () {
      if (this.isLoaded) {
        this.$emit('change-filter', this.allFilters(), 'deviceFilters')
      }
    },
    alldevice: function (newVal) {
      if (newVal.length > 0) {
        this.filterDevicesList()
      }
    },
    nbrPerPage: function (newVal) {
      localStorage.setItem('createDevicesTab-nbrPerPage', newVal)
    },
    objectOwnerContacts: function () {
      if (this.alldevice.length > 0) {
        this.filterDevicesList()
      }
    },
    filterDevicesList: function (newVal) {
      let contactIds = []
      newVal.forEach(element => {
        const currentTenancy = this.getCurrentTenancy(this.getObject(element.referenceId)?.id) ? this.getCurrentTenancy(this.getObject(element.referenceId)?.id) : null
        if (currentTenancy) {
          contactIds = contactIds.concat(currentTenancy.tenant.contactIds)
        }

        const currentCondominium = this.getCurrentCondominium(this.getObject(element.referenceId).id) ? this.getCurrentCondominium(this.getObject(element.referenceId).id) : null
        if (currentCondominium) {
          contactIds = contactIds.concat(currentCondominium.owner.contactIds)
        }
      })
      this.$nextTick(function () {
        this.availableContact([...new Set(contactIds.filter(x => x !== null && x !== ''))])
      })
    },
    selectAllEntries: async function (newValue) {
      if (newValue) {
        this.handleCheckboxesInsideTable(newValue)
        this.allEntries = this.listDevices
      }
    }
  },
  created() {
    // Initialize the debounced function in the created lifecycle hook
    this.debouncedFilterDevicesList = debounce(() => {
      this.filterDevicesList()
    }, 300)
  },
  mounted() {
    if (localStorage.getItem('deviceIdNewDocument')) {
      this.deviceIdNewDocument = localStorage.getItem('deviceIdNewDocument')
    }
    this.$refs.searchText?.setFocusInput()
    this.loadInstantSearchInternal()
    if (this.properties[0] && this.properties[0].type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP') {
      this.fieldsAddDevice = this.fieldsAddDevice.filter(x => x.key !== 'tenant')
    } else {
      this.fieldsAddDevice = this.fieldsAddDevice.filter(x => x.key !== 'condominium')
    }
    let contactIds = []
    setTimeout(() => {
      if (this.objects.length > 0) {
        this.objects.forEach(x => {
          const currentTenancy = this.getCurrentTenancy(x.id) ? this.getCurrentTenancy(x.id) : null
          if (currentTenancy) {
            contactIds = contactIds.concat(currentTenancy.tenant.contactIds)
          }
        })
      }
      const listDevice = this.objectDevices.filter(x => x.referenceId.includes('obj_'))
      if (listDevice.length > 0) {
        listDevice.forEach(element => {
          const currentTenancy = this.getCurrentTenancy(this.getObject(element.referenceId).id) ? this.getCurrentTenancy(this.getObject(element.referenceId).id) : null
          if (currentTenancy) {
            contactIds = contactIds.concat(currentTenancy.tenant.contactIds)
          }

          const currentCondominium = this.getCurrentCondominium(this.getObject(element.referenceId).id) ? this.getCurrentCondominium(this.getObject(element.referenceId).id) : null
          if (currentCondominium) {
            contactIds = contactIds.concat(currentCondominium.owner.contactIds)
          }
        })
        this.listBuildingWithObject.forEach(element => {
          if (element.id.startsWith('obj_')) {
            const obj = this.getObject(element.id)
            const currentTenancy = this.getCurrentTenancy(obj.id) ? this.getCurrentTenancy(obj.id) : null
            if (currentTenancy) {
              contactIds = contactIds.concat(currentTenancy.tenant.contactIds)
            }
            const currentCondominium = this.getCurrentCondominium(obj.id) ? this.getCurrentCondominium(obj.id) : null
            if (currentCondominium) {
              contactIds = contactIds.concat(currentCondominium.owner.contactIds)
            }
          }
        })
      }
      this.$nextTick(function () {
        this.availableContact([...new Set(contactIds.filter(x => x !== null && x !== '' && !this.objectOwnerContacts.includes(x) && !this.contacts.includes(x)))])
      })
      this.isDeviceLoading = true
      this.$nextTick(function () {
        if (this.alldevice.length > 0) {
          this.filterDevicesList()
          this.loadAmountTickets()
        }
      })
      if (this.deviceFilters !== '') {
        const objFilter = JSON.parse(this.deviceFilters)
        this.searchCategories = objFilter.searchCategories
        this.searchRoom = objFilter.searchRoom
        this.searchText = objFilter.searchText
        this.searchRooms = objFilter.searchRooms
        this.searchInstallation = objFilter.searchInstallation
        this.searchTenant = objFilter.searchTenant
        this.searchOwner = objFilter.searchOwner
        this.searchAssignee = objFilter.searchAssignee
      }
      this.$nextTick(function () {
        const isNewCreatedDevice = JSON.parse(localStorage.getItem('isNewCreatedDevice'))
        if (this.elementSelected && !isNewCreatedDevice) {
          this.changeSelectedDevice(this.alldevice.find(dev => dev.id === this.elementSelected))
          const indexOfDevice = this.listDevices.map(function(e) { return e.id }).indexOf(this.elementSelected)
          if (indexOfDevice !== -1) {
            this.currentPage = (Math.floor((indexOfDevice) / this.nbrPerPage)) + 1
            this.listDevices[indexOfDevice]._rowVariant = 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary'
          }
        } else if (isNewCreatedDevice) {
          this.changeSelectedDevice(this.listDevices.find(dev => dev.type === isNewCreatedDevice.type && dev.serialNumber === isNewCreatedDevice.serialNumber && dev.referenceId === isNewCreatedDevice.referenceId))
          const indexOfDevice = this.listDevices.map(function(e) { return e.id }).indexOf(this.elementSelected)
          if (indexOfDevice !== -1) {
            this.currentPage = (Math.floor((indexOfDevice) / this.nbrPerPage)) + 1
            this.listDevices[indexOfDevice]._rowVariant = 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary'
          }
          localStorage.removeItem('isNewCreatedDevice')
        }
        if (this.addNewItemDevice === true) {
          setTimeout(() => {
            this.$bvModal.show('createDeviceModal')
          }, 1000)
        }
      })
      this.isLoaded = true
    }, 500)
    this.nbrPerPage = localStorage.getItem('createDevicesTab-nbrPerPage') ? localStorage.getItem('createDevicesTab-nbrPerPage') === 'all' ? 'all' : parseInt(localStorage.getItem('createDevicesTab-nbrPerPage')) : 5
    this.dataFields = this.dataFields.filter(x => x.selected).concat(this.dataFields.filter(x => !x.selected))
    SearchApi.loadSettings(this.accountId).then(response => {
      const settings = response.tableSettings
      let hasOldList = false
      if (settings.length > 0) {
        settings.forEach(setting => {
          if (setting.columns.length > 0 && setting.table === 'componentsDeviceBuilding') {
            hasOldList = true
            this.loadFields(setting)
          }
        })
      }
      if (hasOldList === false) {
        this.fields.push(...this.dataFields.filter(x => x.selected))
        this.selectedFields = JSON.parse(JSON.stringify(this.dataFields))
      }
    }).catch(e => {
      console.log(e)
    })
  },
  methods: {
    ...mapMutations('onboarding', ['setObjectOwnerContacts']),
    loadAmountTickets() {
      TicketApi.countTickets(this.alldevice.map(x => x.id)).then(response => {
        this.tickets = response.entries
      })
    },
    getFloor(referencId) {
      const obj = this.objects.find(x => x.id === referencId)
      if (obj && obj.characteristics) {
        return this.formatFloor(obj.characteristics.floor)
      }
      return '-'
    },
    fillOutCalculatedReplacement(item) {
      if (item.installation) {
        let date = new Date(this.$options.filters.objectToDateInput(item.installation))
        return date.getFullYear() + parseInt(item.lifeSpan)
      }
      return ''
    },
    saveFields(data) {
      const columns = data.filter(x => x.selected === true).map(y => y.key)
      SearchApi.saveSettings(this.accountId, columns, 'componentsDeviceBuilding').then(response => {
        const setting = response.tableSettings
        if (setting && setting.columns.length > 0) {
          this.loadFields(setting)
        }
        this.$bvModal.hide('assembleListModal')
      })
    },
    loadFields(setting) {
      const result = this.getFields(this.dataFields, setting)
      this.fields = result?.fields
      this.selectedFields = result?.selectedFields
      this.$refs.deviceTable?.refresh()
    },
    openAssembleListModal() {
      this.$bvModal.show('assembleListModal')
    },
    getObjectDetailRoute(device) {
      if (device !== null && device !== undefined) {
        if (device.referenceId.startsWith('obj')) {
          if (this.isOwnerModule) {
            return {
              name: 'OwnerBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'objects',
                elementSelected: device.referenceId
              }
            }
          } else if (this.isAdminModule) {
            return {
              name: 'AdminBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'objects',
                elementSelected: device.referenceId
              }
            }
          } else if (this.isAssetModule) {
            return {
              name: 'AssetBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'objects',
                elementSelected: device.referenceId
              }
            }
          } else if (this.isAccountingModule) {
            return {
              name: 'AccountingBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'building',
                elementSelected: device.referenceId
              }
            }
          }
        } else if (device.referenceId.startsWith('building')) {
          if (this.isOwnerModule) {
            return {
              name: 'OwnerBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'building'
              }
            }
          } else if (this.isAdminModule) {
            return {
              name: 'AdminBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'building'
              }
            }
          } else if (this.isAssetModule) {
            return {
              name: 'AssetBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'building'
              }
            }
          } else if (this.isAccountingModule) {
            return {
              name: 'AccountingBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'building'
              }
            }
          }
        }
      }
    },
    getTenantName(item) {
      if (item.id.startsWith('obj_')) {
        const obj = this.getObject(item.id)
        const currentTenancy = this.getCurrentTenancy(obj.id) ? this.getCurrentTenancy(obj.id) : null
        if (currentTenancy && currentTenancy.vacancy === true) {
          return this.$t('message.onBoarding.buildings.objects.tenancies.vacancy')
        } else if (currentTenancy && currentTenancy.vacancy === false && currentTenancy.tenant.contactIds.length > 0) {
          let names = ''
          currentTenancy.tenant.contactIds.forEach(element => {
            names += this.getContactName(element)
          })
          return names !== '' ? names.substring(1) : ''
        }
        return '-'
      }
      return '-'
    },
    getCondominiumName(item) {
      if (item.id.startsWith('obj_')) {
        const obj = this.getObject(item.id)
        const currentCondo = this.getCurrentCondominium(obj.id) ? this.getCurrentCondominium(obj.id) : null
        if (currentCondo && currentCondo.owner.contactIds.length > 0) {
          let names = ''
          currentCondo.owner.contactIds.forEach(element => {
            names += this.getContactName(element)
          })
          return names !== '' ? names.substring(1) : ''
        }
        return '-'
      }
      return '-'
    },
    createTicket() {
      const defaultAssignee = this.properties[0].ownerId
      const list = this.selectedEntriesGlobalIds
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerTicketCreation', query: { fromBulk: true, ids: list, defaultAssignee: defaultAssignee } })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminTicketCreation', query: { fromBulk: true, ids: list, defaultAssignee: defaultAssignee } })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetTicketCreation', query: { fromBulk: true, ids: list, defaultAssignee: defaultAssignee } })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingTicketCreation', query: { fromBulk: true, ids: list, defaultAssignee: defaultAssignee } })
      }
    },
    getRoomeName(roomId) {
      const rooms = this.objectRooms.concat(this.buildingRooms).find(x => x.id === roomId)
      if (rooms) {
        return rooms.name
      } else {
        return '-'
      }
    },
    allFilters() {
      return JSON.stringify({
        searchCategories: this.searchCategories,
        searchRoom: this.searchRoom,
        searchText: this.searchText,
        searchRooms: this.searchRooms,
        searchInstallation: this.searchInstallation,
        searchFloor: this.searchFloor,
        searchTenant: this.searchTenant,
        searchOwner: this.searchOwner,
        searchAssignee: this.searchAssignee
      })
    },
    customLabelCategory(item) {
      if (item.value === 'building') {
        return this.$t('message.generic.building') + ' (' + item.count + ')'
      }
      return this.$t('message.onBoarding.buildings.objects.category.' + item.value.toLowerCase()) + ' (' + item.count + ')'
    },
    customLabelRoom(item) {
      return item.value + ' (' + item.count + ')'
    },
    customLabel(item) {
      if (!item.value) {
        return this.$t('message.onBoarding.devices.undefined') + ' (' + item.count + ')'
      }
      return item.value + ' (' + item.count + ')'
    },
    customLabelInstallation(item) {
      if (!item.value) {
        return '-'
      }
      return this.$t('message.onBoarding.devices.filter.' + item.label.toLowerCase())
    },
    customLabelFloorRoom(item) {
      return item.value + ' (' + item.count + ')'
    },
    customLabelFloor(item) {
      return this.formatFloor(item.value) + ' (' + item.count + ')'
    },
    initFilter() {
      this.searchText = ''
      this.searchCategories = []
      this.searchRooms = []
      this.searchRoom = []
      this.searchFloor = []
      this.searchAssignee = []
      this.searchTenant = []
      this.searchOwner = []
      this.searchInstallation = null
      this.filterDevicesList()
    },
    customLabelAssignee(item) {
      if (item.value === '') {
        return `${this.$t('message.generic.unassigned')} (${item.count})`
      }
      const name = this.getContactName(item.value).substring(2)
      return `${name} (${item.count})`
    },
    getContactName(contactId) {
      const contact = this.objectOwnerContacts.concat(this.contacts).find(function (contact) {
        return contact.id === contactId || contact.objectID === contactId
      })
      return contact ? ', ' + contact.name : '-'
    },
    getContact(contactId) {
      return this.objectOwnerContacts.concat(this.contacts).find(function (contact) {
        return contact.id === contactId || contact.objectID === contactId
      })
    },
    navigateToCreateOrder() {
      const currentRoute = { name: this.$router.currentRoute.name, params: this.$router.currentRoute.params, query: this.$router.currentRoute.query }
      localStorage.setItem('LS_ROUTE_KEY', JSON.stringify(currentRoute))
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerCreateOrderView', query: { buildingIds: this.building.id, deviceIds: this.selectedEntriesGlobalIds.toString(), source: 'device' } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminCreateOrderView', query: { buildingIds: this.building.id, deviceIds: this.selectedEntriesGlobalIds.toString(), source: 'device' } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetCreateOrderView', query: { buildingIds: this.building.id, deviceIds: this.selectedEntriesGlobalIds.toString(), source: 'device' } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingCreateOrderView', query: { buildingIds: this.building.id, deviceIds: this.selectedEntriesGlobalIds.toString(), source: 'device' } }, () => {})
      }
    },
    amountTickets(id, type) {
      if (this.tickets.find(e => e.referenceId === id)) {
        return this.tickets.find(e => e.referenceId === id)[type] || 0
      }
      return 0
    },
    redirectToActivitiesView(id, type = '') {
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerActivitiesTicketView', params: { id: id }, query: { view: 'tickets', defaultType: type } }, () => {})
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminActivitiesTicketView', params: { id: id }, query: { view: 'tickets', defaultType: type } }, () => {})
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetActivitiesTicketView', params: { id: id }, query: { view: 'tickets', defaultType: type } }, () => {})
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingActivitiesTicketView', params: { id: id }, query: { view: 'tickets', defaultType: type } }, () => {})
      }
    },
    redirectMiddleToActivitiesView(id, type = '') {
      let routeData = ''
      if (this.isOwnerModule) {
        routeData = this.$router.resolve({ name: 'OwnerActivitiesTicketView', params: { id: id }, query: { view: 'tickets', defaultType: type } }, () => {})
      } else if (this.isAdminModule) {
        routeData = this.$router.resolve({ name: 'AdminActivitiesTicketView', params: { id: id }, query: { view: 'tickets', defaultType: type } }, () => {})
      } else if (this.isAssetModule) {
        routeData = this.$router.resolve({ name: 'AssetActivitiesTicketView', params: { id: id }, query: { view: 'tickets', defaultType: type } }, () => {})
      } else if (this.isAccountingModule) {
        routeData = this.$router.resolve({ name: 'AccountingActivitiesTicketView', params: { id: id }, query: { view: 'tickets', defaultType: type } }, () => {})
      }
      window.open(routeData.href, '_blank')
    },
    getNetworkPartnersObject(objectId) {
      const shares = this.shares.filter(share => {
        return share.resourceIds.includes(objectId)
      })

      if (shares.length > 0 && this.networkPartners.length !== 0) {
        const partners = this.networkPartners.filter(partner => {
          return shares.map(share => share.targetAccountId).includes(partner.id)
        })
        return partners.map(obj => ({ ...obj, objectId: objectId }))
      }
      return []
    },
    changeSelectedDevice(item) {
      for (let i = 0; i < this.deviceList.length; i++) {
        if (this.deviceList[i]._rowVariant === 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary') {
          this.deviceList[i]._rowVariant = ''
        }
      }
      localStorage.setItem('scrollpos', window.scrollY)
      if (item) {
        this.selectedDevice = item
        this.$emit('selected-device', item)

        this.$nextTick(function () {
          // const index = this.alldevice.findIndex(x => (x.id && item.id && x.id === item.id) || (x.internalId && item.internalId && x.internalId === item.internalId))
          // this.currentPage = (Math.floor(index / this.nbrPerPage)) + 1
          const i = this.deviceList.findIndex(x => (x.id && item.id && x.id === item.id) || (x.internalId && item.internalId && x.internalId === item.internalId))
          if (this.$refs.deviceTable) {
            if (i !== -1) {
              // we need setTimeout because the current is not yet changed
              setTimeout(() => {
                this.$refs.deviceTable.selectRow(i)
              }, 100)
            }
          }
        })
      }
    },
    closeModal() {
      this.$refs['modal-confirmation'].hide()
    },
    duplicateConfirmation() {
      if (!isNaN(this.duplicateCount)) {
        for (let index = 0; index < this.duplicateCount; index++) {
          const clone = Object.assign({}, this.deviceToDuplicate)
          clone.internalId = clone.internalId + index
          clone.serialNumber = ''
        }
      }
      this.$refs['modal-confirmation'].hide()
    },
    duplicateDevice() {
      // TODO
    },
    filterDevices(key, order = 'asc') {
      const current = this
      return function innerSort(a, b) {
        let varA
        let varB
        if (key === 'installation') {
          varA = current.dateObjectToTimeStamp(a[key])
          varB = current.dateObjectToTimeStamp(b[key])
        } else if (key === 'uninstallation') {
          varA = current.dateObjectToTimeStamp(a[key])
          varB = current.dateObjectToTimeStamp(b[key])
        } else if (key === 'warrantyExpiration') {
          varA = current.dateObjectToTimeStamp(a.warrantyEnd)
          varB = current.dateObjectToTimeStamp(b.warrantyEnd)
        } else if (key === 'type') {
          varA = current.$t('message.onBoarding.devices.typeValues.' + a[key])
          varB = current.$t('message.onBoarding.devices.typeValues.' + b[key])
        } else {
          varA = (typeof a[key] === 'string')
            ? a[key].toUpperCase()
: a[key]
          varB = (typeof b[key] === 'string')
            ? b[key].toUpperCase()
: b[key]
        }
        let comparison = 0
        if (varA > varB) {
          comparison = 1
        } else if (varA < varB) {
          comparison = -1
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        )
      }
    },
    filterDevicesList() {
      this.$nextTick(function () {
        this.listDevices = this.alldevice.filter(item => {
          let showData = true
          const typeMaped = this.$t('message.onBoarding.devices.typeValues.' + item.type)
          const brandSearch = item.brand ? item.brand.toLowerCase().includes(this.searchText.toLowerCase()) : null
          const modelSearch = item.model ? item.model.toLowerCase().includes(this.searchText.toLowerCase()) : null
          const systemSearch = item.system ? item.system.toLowerCase().includes(this.searchText.toLowerCase()) : null
          const cdtSearch = typeMaped ? typeMaped.toLowerCase().includes(this.searchText.toLowerCase()) : null
          const floorMapped = this.searchFloor.map(x => x.value)
          const roomMapped = this.searchRooms.map(x => x.value)
          const roomDeviceMapped = this.searchRoom.map(x => x.value)
          const assigneeMapped = [].concat.apply([], this.searchAssignee.map(x => x.value))
          const tenantMapped = [].concat.apply([], this.searchTenant.map(x => x.value))
          const ownerMapped = [].concat.apply([], this.searchOwner.map(x => x.value))
          if (floorMapped.length > 0 || roomMapped.length > 0 || assigneeMapped.length > 0) {
            showData = item.referenceId.startsWith('obj_')
          }
          if (item.referenceId.startsWith('obj_')) {
            const obj = this.getObject(item.referenceId)
            const objectNameSearch = obj.name ? obj.name.toLowerCase().includes(this.searchText.toLowerCase()) : null
            const numericIdSearch = obj.numericId ? obj.numericId.toLowerCase().includes(this.searchText.toLowerCase()) : null
            const currentTenancy = this.getCurrentTenancy(obj.id) ? this.getCurrentTenancy(obj.id) : null
            let cdtSearchTenant = this.searchTenant.length === 0
            if (currentTenancy) {
              const currentTenancyTenants = currentTenancy.tenant.contactIds
                .map(contactId => this.getContact(contactId))
                .filter(contact => contact)
              cdtSearchTenant = currentTenancyTenants
                .map(tenantContact => tenantMapped.includes(tenantContact.id))
                .some(element => element === true) || this.searchTenant.length === 0
            }

            const currentCondominium = this.getCurrentCondominium(this.getObject(item.referenceId).id) ? this.getCurrentCondominium(this.getObject(item.referenceId).id) : null
            let cdtSearchOwner = true
            if (currentCondominium) {
              const currentCondominiumOwner = currentCondominium.owner.contactIds.map((element) => this.getContact(element))
              cdtSearchOwner = currentCondominiumOwner.map((element) => ownerMapped.includes(element?.id)).some(function(element) { return element === true }) || this.searchOwner.length === 0
            }
            const cdtAssignee = (this.getObject(item.referenceId).contacts && assigneeMapped.includes(this.getObject(item.referenceId).contacts.contactIdAssigneeAdministration)) || this.searchAssignee.length === 0
            const cdtFloor = floorMapped.includes(this.getObject(item.referenceId).characteristics.floor) || this.searchFloor.length === 0
            const cdtRooms = roomMapped.includes(this.getObject(item.referenceId).characteristics.rooms) || this.searchRooms.length === 0
            const cdtRoom = roomDeviceMapped.some(r => this.getRoomeName(item.roomId) === r) || this.searchRoom.length === 0
            const categoryMapped = this.searchCategories.map(x => x.value)
            const cdtCategory = categoryMapped.includes(this.getObject(item.referenceId).category) || this.searchCategories.length === 0
            const installed = item.uninstallation === null
            const uninstalled = item.uninstallation !== null
            let searchTenantOrOwner = true
            if (this.searchInstallation && this.searchInstallation.label === 'installed') {
              if (this.properties[0] && this.properties[0].type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP') {
                searchTenantOrOwner = cdtSearchOwner
              } else if (this.properties[0] && this.properties[0].type === 'PROPERTY_TYPE_RENTAL') {
                searchTenantOrOwner = cdtSearchTenant
              }
              return (cdtSearch || brandSearch || objectNameSearch || numericIdSearch || modelSearch || systemSearch) && cdtFloor && cdtRoom && cdtRooms && cdtCategory && installed && cdtAssignee && showData && searchTenantOrOwner
            } else if (this.searchInstallation && this.searchInstallation.label === 'uninstalled') {
              if (this.properties[0] && this.properties[0].type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP') {
                searchTenantOrOwner = cdtSearchOwner
              } else if (this.properties[0] && this.properties[0].type === 'PROPERTY_TYPE_RENTAL') {
                searchTenantOrOwner = cdtSearchTenant
              }
              return (cdtSearch || brandSearch || objectNameSearch || numericIdSearch || modelSearch || systemSearch) && cdtFloor && cdtRoom && cdtRooms && cdtCategory && uninstalled && cdtAssignee && showData && searchTenantOrOwner
            } else {
              if (this.properties[0] && this.properties[0].type === 'PROPERTY_TYPE_CONDOMINIUM_OWNERSHIP') {
                searchTenantOrOwner = cdtSearchOwner
              } else if (this.properties[0] && this.properties[0].type === 'PROPERTY_TYPE_RENTAL') {
                searchTenantOrOwner = cdtSearchTenant
              }
              return (cdtSearch || brandSearch || objectNameSearch || numericIdSearch || modelSearch || systemSearch) && cdtFloor && cdtRoom && cdtRooms && cdtCategory && cdtAssignee && showData && searchTenantOrOwner
            }
          } else {
            const categoryMapped = this.searchCategories.map(x => x.value)
            let cdtCategory
            cdtCategory = categoryMapped.includes('building') || this.searchCategories.length === 0
            const installed = item.uninstallation === null
            const uninstalled = item.uninstallation !== null
            const cdtRoom = roomDeviceMapped.some(r => this.getRoomeName(item.roomId) === r) || this.searchRoom.length === 0
            if (this.searchInstallation && this.searchInstallation.label === 'installed') {
              return (cdtSearch || brandSearch || modelSearch || systemSearch) && cdtCategory && installed && showData && cdtRoom && tenantMapped.length === 0 && ownerMapped.length === 0
            } else if (this.searchInstallation && this.searchInstallation.label === 'uninstalled') {
              return (cdtSearch || brandSearch || modelSearch || systemSearch) && cdtCategory && uninstalled && showData && cdtRoom && tenantMapped.length === 0 && ownerMapped.length === 0
            } else {
              return (cdtSearch || brandSearch || modelSearch || systemSearch) && cdtCategory && showData && cdtRoom && tenantMapped.length === 0 && ownerMapped.length === 0
            }
          }
        })
        this.clearCheckbox()
        this.isDeviceLoading = false
      })
    },
    openActivities(device) {
      if (device !== null && device !== undefined) {
          if (this.isOwnerModule) {
            this.$router.push({
              name: 'OwnerBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAdminModule) {
            this.$router.push({
              name: 'AdminBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAssetModule) {
            this.$router.push({
              name: 'AssetBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAccountingModule) {
            this.$router.push({
              name: 'AccountingBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          }
        }
    },
    openActivitiesMiddle(device) {
      let routeData = ''
      if (device !== null && device !== undefined) {
          if (this.isOwnerModule) {
            routeData = this.$router.resolve({
              name: 'OwnerBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAdminModule) {
            routeData = this.$router.resolve({
              name: 'AdminBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAssetModule) {
            routeData = this.$router.resolve({
              name: 'AssetBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          } else if (this.isAccountingModule) {
            routeData = this.$router.resolve({
              name: 'AccountingBuildingDetailsView',
              params: { id: this.building.id },
              query: {
                view: 'history',
                elementSelected: device.id
              }
            })
          }
        }
      window.open(routeData.href, '_blank')
    },
    addNewDevice(isNew = false) {
      const query = { view: 'devices', elementSelected: this.elementSelected, addNewItemDevice: isNew }
      if (this.isOwnerModule) {
        this.$router.push({ name: 'OwnerOnboardingView', params: { id: this.building.id }, query: query })
      } else if (this.isAdminModule) {
        this.$router.push({ name: 'AdminOnboardingView', params: { id: this.building.id }, query: query })
      } else if (this.isAssetModule) {
        this.$router.push({ name: 'AssetOnboardingView', params: { id: this.building.id }, query: query })
      } else if (this.isAccountingModule) {
        this.$router.push({ name: 'AccountingOnboardingView', params: { id: this.building.id }, query: query })
      }
    },
    createDeviceClicked() {
      this.isBusy = true
      if (this.selectedEntries[0].includes('obj')) {
        this.addNewObjectDevice(this.selectedEntries[0])
      } else {
        this.addNewBuildingDevice(this.selectedEntries[0])
      }
      this.$nextTick(function () {
        const max = this.alldevice.filter(x => x.internalId).reduce(function(prev, current) {
          return prev.internalId > current.internalId ? prev : current
        })
        if (max) {
          this.selectedDevice = max
          max.id = max.internalId.toString()
          this.$emit('selected-device', max)

          this.$bvModal.hide('createDeviceModal')
          this.clearRowVariants()
          const i = this.alldevice.findIndex(x => x.internalId && x.internalId === max.internalId)
          this.currentPage = (Math.floor(i / this.nbrPerPage)) + 1
          // this.alldevice[i]._rowVariant = 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary'
          if (this.$refs['table-groups']) {
            this.$refs['table-groups'].refresh()
          }
          this.selectedEntries = []
          this.isBusy = false
          this.addNewDevice()
          this.$nextTick(function () {
            if (this.$refs.deviceTable) {
              const index = this.deviceList.findIndex(x => x.internalId && x.internalId === max.internalId)
              if (index !== -1) {
                this.$refs.deviceTable?.selectRow(index)
              }
            }
            setTimeout(() => {
              const element = document.getElementById('device-global-card')
              const y = element.getBoundingClientRect().top - 50
              window.scrollTo({ top: y, behavior: 'smooth' })
            }, 200)
          })
        }
      })
    },
    clearCheckbox() {
      this.selectedEntries = []
      this.selectedEntriesGlobal = []
      if (this.$refs.header_checkbox) {
        this.$refs.header_checkbox.checked = false
      }
      this.handleCheckboxesInsideTable(false)
    },
    clearRowVariants() {
      for (const device of this.deviceList) {
        if (device._rowVariant === 'table-dynamic-clicked table-b-table-row-selected b-table-row-selected table-primary') {
          device._rowVariant = ''
        }
      }
    },
    itemSelected(item, checked) {
      if (checked) {
        this.selectedEntries.push(item.id)
      } else {
        this.selectedEntries = this.selectedEntries.filter(entry => entry !== item.id)
      }
    },
    itemSelectedGlobal(item, checked) {
      if (checked) {
        this.selectedEntriesGlobal.push(item)
        if (this.selectedEntriesGlobal.length === this.nbrPerPage) {
          this.selectAllVisibleEntries = true
        }
      } else {
        this.selectedEntriesGlobal = this.selectedEntriesGlobal.filter(entry => entry.id !== item.id)
        this.selectAllEntries = false
        this.selectAllVisibleEntries = false
      }
    },
    handleCheckboxesInsideTable(value) {
      if (this.$refs.deviceTable) {
        const allCheckboxesComponents = this.$refs.deviceTable.$children[1].$children
        this.handleCheckbox(allCheckboxesComponents, value)
        this.selectAllVisibleEntries = value
        if (value === false) {
          this.selectAllEntries = false
        }
      }
    },
    searchInputText() {
      this.debouncedFilterDevicesList()
    },
    removeDevice() {
      this.selectedDevice = null
      this.filterDevicesList()
    },
    updateSorting(value, field) {
      if (field !== 'acquisitionCosts' && this.$refs.acquisitionCosts_sort) {
        this.$refs.acquisitionCosts_sort.direction = ''
      }
      if (field !== 'installation' && this.$refs.installation_sort) {
        this.$refs.installation_sort.direction = ''
      }
      if (field !== 'uninstallation' && this.$refs.uninstallation_sort) {
        this.$refs.uninstallation_sort.direction = ''
      }
      if (field !== 'warrantyExpiration' && this.$refs.warrantyExpiration_sort) {
        this.$refs.warrantyExpiration_sort.direction = ''
      }
      if (field !== 'type' && this.$refs.type_sort) {
        this.$refs.type_sort.direction = ''
      }
      this.sortValue = value
      this.sortField = field
      this.$nextTick(function () {
        if (this.sortValue !== '' && this.sortField !== '') {
          if (this.listDevices && this.listDevices.length > 0) {
            const listDevices = this.listDevices
            this.listDevices = listDevices.sort(this.filterDevices(this.sortField, this.sortValue))
          } else {
            this.listDevices = this.alldevice.sort(this.filterDevices(this.sortField, this.sortValue))
          }
          if (this.elementSelected) {
            const index = this.listDevices.findIndex(x => x.id && x.id === this.elementSelected)
            this.currentPage = (Math.floor(index / this.nbrPerPage)) + 1
            setTimeout(() => {
              const indexPerPage = index % this.nbrPerPage
              this.$refs.deviceTable?.selectRow(indexPerPage)
            }, 100)
          }
        }
      })
    },
    isInvalid() {
      let valid = true
      if (this.$refs.device && this.$refs.device.isInvalid()) {
        if (!this.$refs.device.isCollapseOpen()) {
          this.showManyToast([(this.$t('message.savingErrors.validationError') + this.$t('message.onBoarding.createBuilding.device') + this.$t('message.savingErrors.validationError2'))])
        }
        valid = false
      }
      return !valid
    },
    deviceUpdated() {
      const newDevices = this.alldevice.filter(x => x.internalId)
      const max = newDevices.length > 0
? newDevices.reduce(function(prev, current) {
        return prev.internalId > current.internalId ? prev : current
      })
: null
      if (max && max.internalId && this.selectedDevice.internalId && max.internalId === this.selectedDevice.internalId) {
        localStorage.setItem('isNewCreatedDevice', JSON.stringify({ type: max.type, serialNumber: max.serialNumber, referenceId: max.referenceId }))
      }
    },
    availableContact(search) {
      ContactApi.contactResolve(search)
        .then(response => {
          const contacts = response.persons.concat(response.companies)
          const usedContacts = this.objectOwnerContacts.concat(contacts)
          this.setObjectOwnerContacts(usedContacts)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }

}
</script>
<style lang="scss" scoped>

</style>
