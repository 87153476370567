<template>
  <div>
    <template v-if="loading">
      <coozzy-spinner />
    </template>
    <template v-else>
      <div
        v-if="devices.length === 0"
        class="row mt-3">
        <div class="col-12">
          <h6>
            {{ $t('message.nonExistent.device') }}
          </h6>
        </div>
      </div>
      <b-tabs
        v-else
        v-model="activeTab"
        class="mt-3">
        <!-- Room-specific tabs -->
        <b-tab
          v-for="room in visibleRooms"
          :key="room.id"
          :title="getRoomTitle(room)">
          <template>
            <div
              v-for="(device, deviceIndex) in getDevicesForRoom(room.id)"
              :id="`device-${target}-${room.id}-${device.id || device.internalId}`"
              :key="`device-${target}-${room.id}-${device.id || device.internalId}`"
              class="row">
              <device
                :key="`device-${target}-${room.id}-${device.id || device.internalId}`"
                :ref="`device-${target}-${room.id}-${device.id || device.internalId}`"
                :index="deviceIndex"
                :target="target"
                :render-devices="renderDevices"
                :read-only="readOnly"
                :device="device"
                :expand-by-default="'isNewItem' in device"
                :add-new-document="device.id && deviceIdNewDocument !== '' && deviceIdNewDocument === device.id"
                :owner-id="ownerId"
                @duplicateDevice="$emit('duplicateDevice', $event)"
                @roomChanged="handleRoomChange" />
            </div>
          </template>
        </b-tab>

        <!-- Unassigned Devices tab -->
        <b-tab
          v-if="getUnassignedDevices().length > 0"
          :title="$t('message.onBoarding.devices.unassignedDevices')">
          <template>
            <div
              v-for="(device, deviceIndex) in getUnassignedDevices()"
              :id="`device-${target}-unassigned-${device.id || device.internalId}`"
              :key="`device-${target}-unassigned-${device.id || device.internalId}`"
              class="row">
              <device
                :key="`device-${target}-unassigned-${device.id || device.internalId}`"
                :ref="`device-unassigned-${device.id || device.internalId}`"
                :index="deviceIndex"
                :target="target"
                :render-devices="renderDevices"
                :read-only="readOnly"
                :device="device"
                :expand-by-default="'isNewItem' in device"
                :add-new-document="device.id && deviceIdNewDocument !== '' && deviceIdNewDocument === device.id"
                :owner-id="ownerId"
                @duplicateDevice="$emit('duplicateDevice', $event)"
                @roomChanged="handleRoomChange" />
            </div>
          </template>
        </b-tab>
      </b-tabs>
    </template>

    <div class="row mt-3">
      <div class="col-2 mt-1">
        <coozzy-button
          class="w-100"
          design="prop-green"
          @click="$emit('addDevice')">
          {{ $t('message.onBoarding.devices.add') }}
        </coozzy-button>
      </div>
    </div>
  </div>
</template>

<script>
import { onboarding } from '@/mixins/onboarding'
import Device from '@/building/tabs/components/Device'
import CoozzyButton from '@/framework/components/button/CoozzyButton'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'

export default {
  name: 'DevicesByRoomTab',
  components: {
    Device,
    CoozzyButton,
    CoozzySpinner
  },
  mixins: [onboarding],
  props: {
    target: {
      type: String,
      required: true
    },
    devices: {
      type: Array,
      required: true
    },
    rooms: {
      type: Array,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    renderDevices: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    ownerId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      activeTab: 0,
      deviceIdNewDocument: '',
      prevDevicesLength: 0
    }
  },
  computed: {
    visibleRooms() {
      return this.rooms.filter(room => {
        const devicesInRoom = this.getDevicesForRoom(room.id)
        return devicesInRoom.length > 0 || devicesInRoom.some(device => device.isNewItem)
      })
    },
    sortedDevices() {
      return [...this.devices].sort((a, b) => {
        if (a.uninstallation !== null && b.uninstallation === null) return 1
        if (a.uninstallation === null && b.uninstallation !== null) return -1

        if (a.order && b.order) {
          return a.order - b.order
        }
        if (!a.order && b.order) return 1
        if (a.order && !b.order) return -1

        if (a.isNewItem && !b.isNewItem) return 1
        if (!a.isNewItem && b.isNewItem) return -1

        if (a.isNewItem && b.isNewItem) {
          return b.numericId - a.numericId
        }

        return a.numericId - b.numericId
      })
    }
  },
  watch: {
    loading: {
      handler(newValue, oldValue) {
        // Always switch to the first tab when loading is complete
        if (oldValue === true && newValue === false) {
          this.$nextTick(() => {
            this.activeTab = 0
          })
        }
      },
      immediate: true
    },
    devices: {
      handler(newDevices) {
        const previousLength = this.prevDevicesLength || 0
        this.prevDevicesLength = newDevices.length

        // Handle new device added
        if (newDevices.length > previousLength) {
          const newDevice = newDevices[newDevices.length - 1]
          if (newDevice && (!newDevice.roomId || newDevice.roomId === '')) {
            setTimeout(() => {
              this.activeTab = this.visibleRooms.length
              const indexDevice = this.getUnassignedDevices().length - 1
              this.$nextTick(() => {
                const domRect = this.$el.querySelector('#device-i-' + indexDevice)
                domRect?.scrollIntoView({ behavior: 'smooth', block: 'center' })
              })
            }, 0)
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    if (localStorage.getItem('deviceIdNewDocument')) {
      this.deviceIdNewDocument = localStorage.getItem('deviceIdNewDocument')
    }
  },
  methods: {
    getRoomTitle(room) {
      return `${room.name}`
    },
    getDevicesForRoom(roomId) {
      return this.sortedDevices.filter(device => device.roomId === roomId)
    },
    getUnassignedDevices() {
      return this.sortedDevices.filter(device => !device.roomId || device.roomId === '' || !this.rooms.some(room => room.id === device.roomId))
    },
    handleRoomChange({ device, newRoomId, wasOpen }) {
      setTimeout(() => {
        if (!newRoomId || newRoomId === '') {
          this.activeTab = this.visibleRooms.length
        } else {
          const newRoomIndex = this.visibleRooms.findIndex(room => room.id === newRoomId)
          if (newRoomIndex !== -1) {
            this.activeTab = newRoomIndex
          }
        }

        this.$nextTick(() => {
          const isNewDevice = !device.id && device.internalId
          if (isNewDevice) {
            this.$root.$once('bv::tab::shown', () => {
              setTimeout(() => {
                if (wasOpen) {
                  const collapseId = `collapse-device-${this.target}-${newRoomId || 'unassigned'}-${device.internalId}`
                  this.$root.$emit('bv::toggle::collapse', collapseId)
                }
              }, 50)
            })
          } else if (wasOpen) {
            const collapseId = `collapse-device-${this.target}-${newRoomId || 'unassigned'}-${device.id}`
            this.$root.$emit('bv::toggle::collapse', collapseId)
          }

          const domRect = this.$el.querySelector(`#device-${this.target}-${newRoomId || 'unassigned'}-${device.id || device.internalId}`)
          domRect?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        })
      }, 0)
    }
  }
}
</script>
