<template>
  <div v-if="isTicketingEnabled && tickets.length > 0">
    <coozzy-detail-card
      :title="$t('message.ticketOverview.title') + ' (' + totalTickets + ')'"
      :is-change-view="true"
      :is-column-view="isColumnView"
      @change-view="isColumnView = !isColumnView">
      <div class="col p-0">
        <div v-if="loading" class="text-center p-3">
          <coozzy-spinner />
        </div>
        <template v-else>
          <!-- Table View -->
          <div v-if="!isColumnView">
            <b-table
              id="ticketTable"
              ref="ticketTable"
              bordered
              hover
              responsive
              selectable
              select-mode="single"
              :fields="ticketFields"
              :items="tickets"
              @row-clicked="openTicket">
              <!-- Busy state -->
              <div
                slot="table-busy"
                class="text-center text-danger my-2">
                <coozzy-spinner />
              </div>

              <!-- Headings -->
              <template #head(type)>
                {{ $t('message.tableColumnHeadings.type') }}
              </template>
              <template #head(key)>
                {{ $t('message.tableColumnHeadings.ticketKey') }}
              </template>
              <template #head(title)>
                {{ $t('message.tableColumnHeadings.title') }}
              </template>
              <template #head(connection)>
                {{ $t('message.tableColumnHeadings.ticketConnections') }}
              </template>
              <template #head(assignee)>
                {{ $t('message.tableColumnHeadings.assignee') }}
              </template>
              <template #head(status)>
                {{ $t('message.tableColumnHeadings.status') }}
              </template>

              <!-- Columns -->
              <template #cell(type)="data">
                <img
                  height="20px"
                  class="mr-1 mb-1"
                  :src="`/img/ticket/${data.item.type.split('TICKET_TYPE_')[1].toLowerCase()}.svg`"
                  alt="type">
              </template>
              <template #cell(key)="data">
                {{ data.item.key }}
              </template>
              <template #cell(title)="data">
                {{ data.item.title }}
              </template>
              <template #cell(connection)="data">
                <template v-for="ref in data.item.references">
                  <p
                    v-if="getConnection(ref.id) !== undefined"
                    :key="ref.id"
                    class="mb-0">
                    <span v-if="findConnectionId(ref, 'prop') && allConnections.properties">
                      <template v-if="getConnection(findConnectionId(ref, 'prop'))">
                        <router-link
                          v-if="!isMarketingModule"
                          class="external-link"
                          :to="redirectToDetailPage(getConnection(findConnectionId(ref, 'prop')), 'prop')">
                          {{ getConnection(findConnectionId(ref, 'prop')).name || '-' }}
                        </router-link>
                        <span v-else>
                          {{ getConnection(findConnectionId(ref, 'prop')).name || '-' }}
                        </span>
                      </template>
                      <template v-else>
                        -
                      </template>
                      >
                    </span>
                    <span v-if="findConnectionId(ref, 'building') && allConnections.buildings">
                      <template v-if="getConnection(findConnectionId(ref, 'building'))">
                        <router-link
                          v-if="!isMarketingModule"
                          class="external-link"
                          :to="redirectToDetailPage(getConnection(findConnectionId(ref, 'building')), 'building')">
                          {{ getConnection(findConnectionId(ref, 'building')).name || '-' }}
                        </router-link>
                        <span v-else>
                          {{ getConnection(findConnectionId(ref, 'building')).name || '-' }}
                        </span>
                      </template>
                      <template v-else>
                        -
                      </template>
                      >
                    </span>
                    <span v-if="findConnectionId(ref, 'obj') && allConnections.objects">
                      <template v-if="getConnection(findConnectionId(ref, 'obj'))">
                        <router-link
                          v-if="!isMarketingModule"
                          class="external-link"
                          :to="redirectToDetailPage(getConnection(findConnectionId(ref, 'obj')), 'obj')">
                          {{ getConnection(findConnectionId(ref, 'obj')).name ||
                            getConnection(findConnectionId(ref, 'obj')).number ||
                            getConnection(findConnectionId(ref, 'obj')).numericId ||
                            '-' }}
                        </router-link>
                        <span v-else>
                          {{ getConnection(findConnectionId(ref, 'obj')).name ||
                            getConnection(findConnectionId(ref, 'obj')).number ||
                            getConnection(findConnectionId(ref, 'obj')).numericId ||
                            '-' }}
                        </span>
                      </template>
                      <template v-else>
                        -
                      </template>
                      >
                    </span>
                    <span
                      v-if="ref.id.startsWith('ins') && allConnections.insurances"
                      class="external-link">
                      <a
                        v-if="!isMarketingModule"
                        class="external-link link"
                        @click="setInsuranceId(getConnection(ref.id), false)"
                        @click.middle="setInsuranceId(getConnection(ref.id), true)">
                        <template v-if="getConnection(ref.id)">
                          {{ $t('message.onBoarding.buildings.insurances.type.' + getConnection(ref.id).type) }}
                          <template v-if="getReferenceName(ref.id) !== ''">
                            {{ ' - ' + getReferenceName(ref.id) }}
                          </template>
                        </template>
                        <template v-else>
                          -
                        </template>
                      </a>
                      <span v-else>
                        {{ $t('message.onBoarding.buildings.insurances.type.' + getConnection(ref.id).type) }}
                      </span>
                    </span>
                    <span
                      v-if="ref.id.startsWith('mortgage') && allConnections.mortgages"
                      class="external-link">
                      <a
                        v-if="!isMarketingModule"
                        class="external-link link"
                        @click="setMortgageId(getConnection(ref.id), false)"
                        @click.middle="setMortgageId(getConnection(ref.id), true)">
                        <template v-if="getConnection(ref.id)">
                          {{ getConnection(ref.id).bankName + ' - ' +
                            $t('message.onBoarding.mortgage.mortgageDesignation.' +
                              getConnection(ref.id).mortgageDesignation) }}
                          <template v-if="getReferenceName(ref.id) !== ''">
                            {{ ' - ' + getReferenceName(ref.id) }}
                          </template>
                        </template>
                        <template v-else>
                          -
                        </template>
                      </a>
                      <span v-else>
                        <template v-if="getConnection(ref.id)">
                          {{ getConnection(ref.id).bankName + ' - ' +
                            $t('message.onBoarding.mortgage.mortgageDesignation.' +
                              getConnection(ref.id).mortgageDesignation) }}
                        </template>
                        <template v-else>
                          -
                        </template>
                      </span>
                    </span>
                    <span v-if="ref.id.startsWith('device') && allConnections.devices">
                      <router-link
                        v-if="!isMarketingModule"
                        class="external-link"
                        :to="getConnection(ref.id) !== undefined ? redirectToDeviceDetail(getConnection(ref.id)) : ''">
                        <template
                          v-if="getConnection(ref.id).type && getConnection(ref.id).type !== 'DEVICE_TYPE_UNDEFINED'">
                          {{ $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).length > 50 ?
                            $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).slice(0,50)+ '...' :
                            $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type) }}
                        </template>
                        <template v-else>
                          -
                        </template>
                      </router-link>
                      <span v-else>
                        <template
                          v-if="getConnection(ref.id).type && getConnection(ref.id).type !== 'DEVICE_TYPE_UNDEFINED'">
                          {{ $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).length > 50 ?
                            $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type).slice(0,50)+ '...' :
                            $t('message.onBoarding.devices.typeValues.' + getConnection(ref.id).type) }}
                        </template>
                        <template v-else>
                          -
                        </template>
                      </span>
                    </span>
                    <span v-if="ref.id.startsWith('obj') && allConnections.objects">
                      <router-link
                        v-if="!isMarketingModule"
                        class="external-link"
                        :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'obj') : ''">
                        {{ getConnection(ref.id)?.name ||
                          getConnection(ref.id).number ||
                          getConnection(ref.id).numericId ||
                          '' }}
                      </router-link>
                      <span v-else>
                        {{ getConnection(ref.id)?.name ||
                          getConnection(ref.id)?.number ||
                          getConnection(ref.id)?.numericId ||
                          '' }}
                      </span>
                    </span>
                    <span v-else-if="ref.id.startsWith('building') && allConnections.buildings">
                      <router-link
                        v-if="!isMarketingModule"
                        :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'building') : ''">
                        {{ getConnection(ref.id)?.name || '' }}
                      </router-link>
                      <span v-else>
                        {{ getConnection(ref.id)?.name || '' }}
                      </span>
                    </span>
                    <span v-else-if="ref.id.startsWith('prop') && allConnections.properties">
                      <router-link
                        v-if="!isMarketingModule"
                        class="external-link"
                        :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'prop') : ''">
                        {{ getConnection(ref.id)?.name || '' }}
                      </router-link>
                      <span v-else>
                        {{ getConnection(ref.id)?.name || '' }}
                      </span>
                    </span>
                    <span v-else-if="ref.id.startsWith('contact') && allConnections.contacts">
                      <router-link
                        v-if="!isMarketingModule"
                        :to="getConnection(ref.id) !== undefined ? redirectToDetailPage(getConnection(ref.id), 'contact') : ''">
                        {{ getConnection(ref.id)?.name || '' }}
                      </router-link>
                      <span v-else>
                        {{ getConnection(ref.id)?.name || '' }}
                      </span>
                    </span>
                    <span v-if="ref.id.startsWith('request')">
                      <router-link
                        v-if="getConnection(ref.id).requesters.length > 0"
                        class="external-link"
                        :to="redirectToRequestDetail(ref.objectId, ref.id)">
                        {{ getContactName(getConnection(ref.id).requesters[0].contactId) }}
                      </router-link>
                    </span>
                    <span v-else-if="ref.id.startsWith('tenancy')">
                      <router-link
                        v-if="!isMarketingModule"
                        class="external-link"
                        :to="getConnection(ref.id) !== undefined ? redirectToDetail(getConnection(ref.id), ref.id, 'tenants') : ''">
                        <template v-for="contact in getConnection(ref.id).tenant.contactIds">
                          {{ getContactName(contact) }}
                        </template>
                      </router-link>
                      <span v-else>
                        <template v-for="contact in getConnection(ref.id).tenant.contactIds">
                          {{ getContactName(contact) }}
                        </template>
                      </span>
                    </span>
                    <span v-else-if="ref.id.startsWith('condo')">
                      <router-link
                        v-if="!isMarketingModule"
                        class="external-link"
                        :to="getConnection(ref.id) !== undefined ? redirectToDetail(getConnection(ref.id), ref.id, 'condominium') : ''">
                        <template v-for="contact in getConnection(ref.id).owner.contactIds">
                          {{ getContactName(contact) }}
                        </template>
                      </router-link>
                      <span v-else>
                        <template v-for="contact in getConnection(ref.id).owner.contactIds">
                          {{ getContactName(contact) }}
                        </template>
                      </span>
                    </span>
                    <span v-else-if="ref.id.startsWith('room')">
                      <router-link
                        v-if="!isMarketingModule"
                        class="external-link"
                        :to="getConnection(ref.id) !== undefined ? redirectToDetail(getConnection(ref.id), '', 'objects') : ''">
                        {{ getConnection(ref.id) !== null ? getConnection(ref.id).name : '-' }}
                      </router-link>
                      <span v-else>
                        {{ getConnection(ref.id) !== null ? getConnection(ref.id).name : '-' }}
                      </span>
                    </span>
                  </p>
                  <p
                    v-else
                    :key="'else-list-' + ref.id">
                    -
                  </p>
                </template>
              </template>
              <template #cell(assignee)="data">
                <template v-if="data.item.assignee">
                  {{ data.item.assignee.name }} - <b>{{ data.item.assignee.accountName }}</b>
                </template>
                <span v-else>-</span>
              </template>
              <template #cell(status)="data">
                <template v-if="data.item.status === 'TICKET_STATUS_OPEN'">
                  {{ $t('message.ticketOverview.status.TICKET_STATUS_OPEN') }}
                </template>
                <template v-else-if="data.item.status === 'TICKET_STATUS_IN_PROGRESS'">
                  {{ $t('message.ticketOverview.status.TICKET_STATUS_IN_PROGRESS') }}
                </template>
                <template v-else-if="data.item.status === 'TICKET_STATUS_DONE'">
                  {{ $t('message.ticketOverview.status.TICKET_STATUS_DONE') }}
                </template>
                <template v-else-if="data.item.status === 'TICKET_STATUS_CLOSED'">
                  {{ $t('message.ticketOverview.status.TICKET_STATUS_CLOSED') }}
                </template>
                <template v-else-if="data.item.status === 'TICKET_STATUS_DRAFT'">
                  {{ $t('message.ticketOverview.status.TICKET_STATUS_DRAFT') }}
                </template>
              </template>
            </b-table>
            <div v-if="tickets.length === 0" class="text-center p-3">
              {{ $t('message.ticketOverview.noTickets') }}
            </div>
          </div>

          <!-- Column View -->
          <div
            v-else
            id="ticketColumns"
            class="row m-2">
            <!-- Open column -->
            <div class="col-12 col-md-4 px-1">
              <h6 class="column-heading rounded p-2">
                {{ $t('message.ticketOverview.status.TICKET_STATUS_OPEN') }} ({{ openTickets.length }})
              </h6>
              <div class="column-container rounded p-2">
                <div
                  v-if="openTickets.length === 0"
                  class="text-center text-grey p-3">
                  {{ $t('message.ticketOverview.noTickets') }}
                </div>
                <ticket-card
                  v-for="ticket in openTickets"
                  :key="ticket.id"
                  :ticket="ticket"
                  @click.native="openTicket(ticket)" />
              </div>
            </div>

            <!-- In Progress column -->
            <div class="col-12 col-md-4 px-1">
              <h6 class="column-heading rounded p-2">
                {{ $t('message.ticketOverview.status.TICKET_STATUS_IN_PROGRESS') }} ({{ inProgressTickets.length }})
              </h6>
              <div class="column-container rounded p-2">
                <div
                  v-if="inProgressTickets.length === 0"
                  class="text-center text-grey p-3">
                  {{ $t('message.ticketOverview.noTickets') }}
                </div>
                <ticket-card
                  v-for="ticket in inProgressTickets"
                  :key="ticket.id"
                  :ticket="ticket"
                  @click.native="openTicket(ticket)" />
              </div>
            </div>

            <!-- Done column -->
            <div class="col-12 col-md-4 px-1">
              <h6 class="column-heading rounded p-2">
                {{ $t('message.ticketOverview.status.TICKET_STATUS_DONE') }} ({{ doneTickets.length }})
              </h6>
              <div class="column-container rounded p-2">
                <div
                  v-if="doneTickets.length === 0"
                  class="text-center text-grey p-3">
                  {{ $t('message.ticketOverview.noTickets') }}
                </div>
                <ticket-card
                  v-for="ticket in doneTickets"
                  :key="ticket.id"
                  :ticket="ticket"
                  @click.native="openTicket(ticket)" />
              </div>
            </div>
          </div>
        </template>
      </div>
    </coozzy-detail-card>
  </div>
</template>

<script>
import TicketApi from '@/misc/apis/TicketApi'
import { user } from '@/mixins/user'
import { ticket } from '@/mixins/ticket'
import CoozzyDetailCard from '@/framework/components/card/CoozzyDetailCard'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner'
import TicketCard from '@/management/components/ticket/TicketCard'
import ObjectApi from '@/misc/apis/ObjectApi'
import ContactApi from '@/misc/apis/ContactApi'
import RequestApi from '@/marketingBasic/api/RequestApi'
import UserApi from '@/misc/apis/UserApi'
import RoomApi from '@/misc/apis/RoomApi'
import { routeChecks } from '@/mixins/routeChecks'

export default {
  name: 'TicketContactCard',
  components: {
    CoozzyDetailCard,
    CoozzySpinner,
    TicketCard
  },
  mixins: [user, ticket, routeChecks],
  props: {
    contactId: {
      type: String,
      required: true,
      default: ''
    },
    referenceIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loading: false,
      tickets: [],
      allConnections: {
        contacts: [],
        properties: [],
        buildings: [],
        objects: [],
        tenancies: [],
        condominiums: [],
        devices: [],
        rooms: [],
        mortgages: [],
        insurances: [],
        requests: [],
        owners: []
      },
      listBuilding: [],
      listContacts: [],
      isColumnView: false,
      ticketFields: [
        { key: 'type', label: this.$t('message.ticketOverview.ticket.type.title'), tdClass: 'align-middle' },
        { key: 'key', label: this.$t('message.ticketOverview.ticket.key'), tdClass: 'align-middle' },
        { key: 'title', label: this.$t('message.ticketOverview.ticket.title'), tdClass: 'align-middle max-w-subject' },
        { key: 'connection', label: this.$t('message.ticketOverview.ticket.connection'), tdClass: 'align-middle' },
        { key: 'assignee', label: this.$t('message.tableColumnHeadings.assignee'), tdClass: 'align-middle' },
        { key: 'status', label: this.$t('message.ticketOverview.status.title'), tdClass: 'align-middle' }
      ]
    }
  },
  computed: {
    totalTickets() {
      return this.tickets.length
    },
    openTickets() {
      return this.tickets.filter(ticket => ticket.status === 'TICKET_STATUS_OPEN')
    },
    inProgressTickets() {
      return this.tickets.filter(ticket => ticket.status === 'TICKET_STATUS_IN_PROGRESS')
    },
    doneTickets() {
      return this.tickets.filter(ticket => ticket.status === 'TICKET_STATUS_DONE')
    },
    allReferences() {
      if (!this.contactId) return []
      return [...new Set([this.contactId, ...this.referenceIds])]
    }
  },
  watch: {
    contactId: {
      handler() {
        this.loadTickets()
      },
      immediate: false
    },
    referenceIds: {
      handler() {
        this.loadTickets()
      },
      immediate: false
    }
  },
  mounted() {
    this.loadTickets()
  },
  methods: {
    loadTickets() {
      this.loading = true

      if (!this.allReferences || this.allReferences.length === 0) {
        this.tickets = []
        this.loading = false
        return
      }

      const params = {
        referenceIds: this.allReferences,
        closedOnly: false,
        includeClosed: false,
        query: '',
        priorities: [],
        types: [],
        statuses: [],
        ownerIds: [],
        creatorUserIds: [],
        assigneeUserIds: [],
        involvedContactIds: [],
        overdueOnly: false,
        watchingOnly: false,
        page: 1,
        itemsPerPage: 'all',
        directReferenceIdsOnly: true
      }

      TicketApi.listMyTickets(params)
        .then(response => {
          const tickets = response.tickets || []
          this.tickets = tickets
          this.loadConnections(tickets)
        })
        .catch(error => {
          console.error('Error loading tickets:', error)
          this.tickets = []
        })
        .finally(() => {
          this.loading = false
        })
    },
    loadConnections(tickets) {
      const arrCondo = []
      const arrTenancy = []
      const arrObj = []
      const arrProp = []
      const arrBuilding = []
      const arrContact = []
      const arrDevice = []
      const arrRequest = []
      const arrRoom = []
      const arrOwner = []
      const arrMortgage = []
      const arrInsurance = []
      tickets.forEach(element => {
        for (let index = 0; index < element.references.length; index++) {
          const ref = element.references[index]
          if (ref.id.startsWith('condominium')) {
            arrCondo.push(ref.id)
          } else if (ref.id.startsWith('tenancy')) {
            arrTenancy.push(ref.id)
          } else if (ref.id.startsWith('obj')) {
            arrObj.push(ref.id)
          } else if (ref.id.startsWith('building')) {
            arrBuilding.push(ref.id)
          } else if (ref.id.startsWith('prop')) {
            arrProp.push(ref.id)
          } else if (ref.id.startsWith('contact')) {
            arrContact.push(ref.id)
          } else if (ref.id.startsWith('device')) {
            arrDevice.push(ref.id)
          } else if (ref.id.startsWith('request')) {
            arrRequest.push(ref.id)
          } else if (ref.id.startsWith('room')) {
            arrRoom.push(ref.id)
          } else if (ref.id.startsWith('mortgage')) {
            arrMortgage.push(ref.id)
          } else if (ref.id.startsWith('ins')) {
            arrInsurance.push(ref.id)
          }

          if (ref.relatedIds && ref.relatedIds.length > 0) {
            ref.relatedIds.forEach(relatedId => {
              if (relatedId.startsWith('obj')) {
                arrObj.push(relatedId)
              } else if (relatedId.startsWith('building')) {
                arrBuilding.push(relatedId)
              } else if (relatedId.startsWith('prop')) {
                arrProp.push(relatedId)
              } else if (relatedId.startsWith('room')) {
                arrRoom.push(relatedId)
              }
            })
          }
        }
        if (element.ownerId && !arrOwner.includes(element.ownerId)) {
          arrOwner.push(element.ownerId)
        }
      })
      const promises = []
      if (arrCondo.length > 0) {
        promises.push(ObjectApi.getCondominiumByIds(arrCondo))
      }
      if (arrTenancy.length > 0) {
        promises.push(ObjectApi.getTenanciesByIds(arrTenancy))
      }
      if (arrObj.length > 0) {
        promises.push(ObjectApi.listObjectsByIds(arrObj))
      }
      if (arrBuilding.length > 0) {
        promises.push(ObjectApi.getBuildingsListByIds(arrBuilding))
      }
      if (arrProp.length > 0) {
        promises.push(ObjectApi.getPropertyListByIds(arrProp))
      }
      if (arrContact.length > 0) {
        promises.push(ContactApi.contactResolve(arrContact))
      }
      if (arrDevice.length > 0) {
        promises.push(ObjectApi.listDevicesByIds(arrDevice))
      }
      if (arrRequest.length > 0) {
        promises.push(RequestApi.listByIds(arrRequest))
      }
      if (arrOwner.length > 0) {
        promises.push(UserApi.listAccountsByIds(arrOwner))
      }
      if (arrRoom.length > 0) {
        promises.push(RoomApi.listById(arrRoom))
      }
      if (arrMortgage.length > 0) {
        promises.push(ObjectApi.getMortgagesByIds(arrMortgage))
      }
      if (arrInsurance.length > 0) {
        promises.push(ObjectApi.getInsurancesByIds(arrInsurance))
      }
      Promise.all(promises)
        .then(values => {
          let contactIds = []
          values.forEach(element => {
            if (element.tenancies) {
              this.allConnections.tenancies = element.tenancies
              const objectIds = element.tenancies.map(x => x.objectId)
              ObjectApi.getBuildingByObjectIds(objectIds).then(responseBuild => {
                this.listBuilding = this.listBuilding.length > 0
                  ? this.listBuilding.concat(responseBuild.buildings)
                  : responseBuild.buildings
              })
              contactIds = contactIds.concat([...new Set([].concat(...element.tenancies.map((o) => o.tenant.contactIds)))])
            } else if (element.condominiums) {
              this.allConnections.condominiums = element.condominiums
              const objectIds = element.condominiums.map(x => x.objectId)
              ObjectApi.getBuildingByObjectIds(objectIds).then(responseBuild => {
                this.listBuilding = this.listBuilding.length > 0
                  ? this.listBuilding.concat(responseBuild.buildings)
                  : responseBuild.buildings
              })
              contactIds = contactIds.concat([...new Set([].concat(...element.condominiums.map((o) => o.owner.contactIds)))])
            } else if (element.devices) {
              this.allConnections.devices = element.devices
              const objectIds = []
              const roomIds = []
              element.devices.forEach(element => {
                if (element.referenceId.startsWith('obj')) {
                  objectIds.push(element.referenceId)
                }
                if (element.roomId !== '') {
                  roomIds.push(element.roomId)
                }
              })
              if (objectIds.length > 0) {
                ObjectApi.getBuildingByObjectIds(objectIds).then(responseBuild => {
                  this.listBuilding = this.listBuilding.length > 0
                    ? this.listBuilding.concat(responseBuild.buildings)
                    : responseBuild.buildings
                })
              }
              if (roomIds.length > 0) {
                RoomApi.listById(roomIds).then(responseRoom => {
                  this.allConnections.rooms = responseRoom.rooms
                })
              }
            } else if (element.objects) {
              this.allConnections.objects = element.objects
            } else if (element.buildings) {
              this.allConnections.buildings = element.buildings
            } else if (element.properties) {
              this.allConnections.properties = element.properties
            } else if (element.requests) {
              this.allConnections.requests = element.requests
              contactIds = contactIds.concat([...new Set([].concat(...element.requests.map((req) => req.requesters[0]?.contactId)))])
            } else if (element.accounts) {
              this.allConnections.owners = element.accounts
            } else if (element.rooms) {
              this.allConnections.rooms = element.rooms
            } else {
              this.allConnections.contacts = element.persons?.concat(element.companies)
            }
          })
          ContactApi.contactResolve(contactIds)
            .then(responseCon => {
              this.listContacts = this.listContacts.concat(responseCon.persons.concat(responseCon.companies)).filter(c => contactIds.includes(c.id))
            })
        })
    },
    openTicket(ticket) {
      let route = 'MarketingTicketDetailsView'
      if (this.isOwnerModule) {
        route = 'OwnerTicketDetailsView'
      } else if (this.isAdminModule) {
        route = 'AdminTicketDetailsView'
      } else if (this.isAssetModule) {
        route = 'AssetTicketDetailsView'
      } else if (this.isAccountingModule) {
        route = 'AccountingTicketDetailsView'
      }
      this.$router.push({
        name: route,
        params: { id: ticket.key }
      }, () => {})
    },
    findConnectionId(reference, type) {
      return reference.relatedIds.find(x => x.startsWith(type))
    },
    getConnection(id) {
      if (id.startsWith('condominium')) {
        return this.allConnections.condominiums?.find(x => x.id === id)
      } else if (id.startsWith('tenancy')) {
        return this.allConnections.tenancies?.find(x => x.id === id)
      } else if (id.startsWith('obj')) {
        return this.allConnections.objects?.find(x => x.id === id)
      } else if (id.startsWith('building')) {
        return this.allConnections.buildings?.find(x => x.id === id)
      } else if (id.startsWith('prop')) {
        return this.allConnections.properties?.find(x => x.id === id)
      } else if (id.startsWith('contact')) {
        return this.allConnections.contacts?.find(x => x.id === id)
      } else if (id.startsWith('device')) {
        return this.allConnections.devices?.find(x => x.id === id)
      } else if (id.startsWith('request')) {
        return this.allConnections.requests?.find(x => x.id === id)
      }
    },
    redirectToDetailPage(item, type) {
      if (type === 'contact') {
        const contactId = item.id
        if (contactId !== null) {
          if (this.isOwnerModule) {
            return {
              name: 'OwnerContactDetailsView',
              params: { id: contactId }
            }
          } else if (this.isAssetModule) {
            return {
              name: 'AssetContactDetailsView',
              params: { id: contactId }
            }
          } else if (this.isAccountingModule) {
            return {
              name: 'AccountingContactDetailsView',
              params: { id: contactId }
            }
          } else if (this.isMarketingModule) {
            return {
              name: 'MarketingContactDetailsView',
              params: { id: contactId }
            }
          } else {
            return {
              name: 'AdminContactDetailsView',
              params: { id: contactId }
            }
          }
        }
      } else if (type === 'obj') {
        const buildingId = item?.buildingId
        const objectId = item?.id
        const view = 'objects'
        if (this.isOwnerModule) {
          return {
            name: 'OwnerBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        } else if (this.isAdminModule) {
          return {
            name: 'AdminBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        } else if (this.isAssetModule) {
          return {
            name: 'AssetBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        } else if (this.isAccountingModule) {
          return {
            name: 'AccountingBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        }
      } else if (type === 'building') {
        const buildingId = item.id
        if (this.isOwnerModule) {
          return {
            name: 'OwnerBuildingDetailsView',
            params: { id: buildingId },
            query: { view: 'building' }
          }
        } else if (this.isAssetModule) {
          return {
            name: 'AssetBuildingDetailsView',
            params: { id: buildingId },
            query: { view: 'building' }
          }
        } else if (this.isAccountingModule) {
          return {
            name: 'AccountingBuildingDetailsView',
            params: { id: buildingId },
            query: { view: 'building' }
          }
        } else if (this.isAdminModule) {
          return {
            name: 'AdminBuildingDetailsView',
            params: { id: buildingId },
            query: { view: 'building' }
          }
        }
      } else if (type === 'prop') {
        const propertyId = item.id
        if (this.isOwnerModule) {
          return { name: 'OwnerPropertyDetailsView', params: { id: propertyId } }
        } else if (this.isAdminModule) {
          return { name: 'AdminPropertyDetailsView', params: { id: propertyId } }
        } else if (this.isAssetModule) {
          return { name: 'AssetPropertyDetailsView', params: { id: propertyId } }
        } else if (this.isAccountingModule) {
          return { name: 'AccountingPropertyDetailsView', params: { id: propertyId } }
        }
      }
      return ''
    },
    redirectToDeviceDetail(device) {
      const referenceId = device.referenceId
      const deviceId = device.id
      if (referenceId.startsWith('obj') || referenceId.startsWith('building')) {
        let id = null
        if (referenceId.startsWith('building')) {
          id = referenceId
        } else {
          const item = this.allConnections.buildings.filter(x => x.objectIds && x.objectIds.includes(referenceId))
          if (item.length > 0) {
            id = item[0].id
          }
        }
        if (id && deviceId) {
          if (this.isOwnerModule) {
            return { name: 'OwnerBuildingDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
          } else if (this.isAdminModule) {
            return { name: 'AdminBuildingDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
          } else if (this.isAssetModule) {
            return { name: 'AssetBuildingDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
          } else if (this.isAccountingModule) {
            return { name: 'AccountingBuildingDetailsView', params: { id: id }, query: { view: 'devices', elementSelected: deviceId } }
          }
        }
      } else {
        if (this.isOwnerModule) {
          return { name: 'OwnerPropertyDetailsView', params: { id: referenceId }, query: { view: 'devices', elementSelected: deviceId } }
        } else if (this.isAdminModule) {
          return { name: 'AdminPropertyDetailsView', params: { id: referenceId }, query: { view: 'devices', elementSelected: deviceId } }
        } else if (this.isAssetModule) {
          return { name: 'AssetPropertyDetailsView', params: { id: referenceId }, query: { view: 'devices', elementSelected: deviceId } }
        } else if (this.isAccountingModule) {
          return { name: 'AccountingPropertyDetailsView', params: { id: referenceId }, query: { view: 'devices', elementSelected: deviceId } }
        }
      }
      return ''
    },
    redirectToDetail(item, objectId, view) {
      const buildingId = this.listBuilding.find(y => y.objectIds.includes(item.objectId))?.id
      if (buildingId) {
        if (this.isOwnerModule) {
          return {
            name: 'OwnerBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        } else if (this.isAdminModule) {
          return {
            name: 'AdminBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        } else if (this.isAssetModule) {
          return {
            name: 'AssetBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        } else if (this.isAccountingModule) {
          return {
            name: 'AccountingBuildingDetailsView',
            params: { id: buildingId },
            query: {
              view: view,
              elementSelected: objectId
            }
          }
        }
      }
      return ''
    },
    getContactName(contact) {
      return this.listContacts.find(x => x.id === contact)?.name
    },
    redirectToRequestDetail(objectId, requestId) {
      return {
        name: 'MarketingObjectDetailsView',
        params: { id: objectId },
        query: {
          view: 0,
          elementSelected: requestId
        }
      }
    },
    setInsuranceId(insurance, middleClick = false) {
      const source = insurance.referenceId.startsWith('building') ? 'building' : 'property'
      if (source === 'building') {
        localStorage.setItem('expandBuildingInsuranceId', insurance.id)
      } else if (source === 'property') {
        localStorage.setItem('expandPropertyInsuranceId', insurance.id)
      }
      this.redirectToParentDetail(insurance, middleClick)
    },
    setMortgageId(mortgage, middleClick = false) {
      const source = mortgage.referenceId.startsWith('building') ? 'building' : 'property'
      if (source === 'building') {
        localStorage.setItem('expandBuildingMortgageId', mortgage.id)
      } else if (source === 'property') {
        localStorage.setItem('expandPropertyMortgageId', mortgage.id)
      }
      this.redirectToParentDetail(mortgage, middleClick)
    },
    getReferenceName(id) {
      if (id.startsWith('ins')) {
        const insurance = this.allConnections.insurances?.find(x => x.id === id)
        return insurance?.name || ''
      } else if (id.startsWith('mortgage')) {
        const mortgage = this.allConnections.mortgages?.find(x => x.id === id)
        return mortgage?.name || ''
      }
      return ''
    },
    redirectToParentDetail(item, middleClick) {
      if (item.referenceId.startsWith('building')) {
        if (!middleClick) {
          this.$router.push(this.redirectToDetailPage(item, 'building'))
        } else {
          const route = this.$router.resolve(this.redirectToDetailPage(item, 'building'))
          window.open(route.href, '_blank')
        }
      } else if (item.referenceId.startsWith('prop')) {
        if (!middleClick) {
          this.$router.push(this.redirectToDetailPage(item, 'prop'))
        } else {
          const route = this.$router.resolve(this.redirectToDetailPage(item, 'prop'))
          window.open(route.href, '_blank')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.text-grey {
  color: $color-text-grey-light;
}
:deep(#ticketTable) {
  overflow: unset !important;
}
:deep(.sticky-header) {
  background-color: #f8f8f8 !important;
}
:deep(.b-table-sticky-column) {
  background-image: none !important;
}
.column-heading {
  background-color: $color-bg-grey;
}
.column-container {
  background-color: $color-bg-grey;
}
:deep(#ticketColumns) {
  .card .card-body {
    padding: 0.5rem !important;
  }
}
</style>
