<template>
  <section>
    <div
      v-if="item === null || loading"
      class="col-12 text-center">
      <coozzy-spinner class="m-3" />
    </div>
    <template v-else>
      <div class="row">
        <div class="col-12">
          <coozzy-page-title
            :title="$t('message.onBoarding.buildings.title') + ': ' + buildingName"
            :sub-title="$t('message.onBoarding.buildings.address') + ': ' + item.address.street + ' ' + item.address.streetNumber + ', ' +item.address.zip + ' ' + item.address.city" />
          <h5>
            <span>{{ item.publicId }}</span>
            <span v-if="item.name"> | {{ item.name | displayOptionalValue }}</span>
            <span>
              | {{ $t('message.marketingObjects.status.title') }}:
              <template v-if="!isRented">
                <span :class="statusColor">{{ statusTitle }}</span>
                <span
                  v-if="statusFutureDate"
                  :class="statusColor">
                  {{ $t('message.generic.until') + ' ' + statusFutureDate }}
                </span>
                <span
                  v-else-if="statusDate"
                  :class="statusColor">
                  {{ $t('message.generic.since') + ' ' + statusDate }}
                </span>
                <span
                  v-if="loadedReservedRequest"
                  :class="statusColor">
                  - {{ $t('message.manageInterestedParties.reserved') }}
                </span>
              </template>
              <template v-else>
                <span
                  class="text-blue">
                  {{ $t('message.marketingObjects.status.rented') }}
                </span>
                <span
                  v-if="futureTenancy"
                  class="text-blue"> {{ $t('message.generic.fromDate') }}{{ futureTenancy.startingAt | objectToDate }}</span>
              </template>
            </span>
            <coozzy-alert
              v-if="item.marketingStatus && (item.marketingStatus.startMarketingAt || item.marketingStatus.marketingNote)"
              class="my-2"
              variant="blue"
              size="sm">
              <template v-if="!updateMarketingInfoEdit">
                <div>
                  <h6 class="d-inline-block alert-heading font-weight-bold mb-0">
                    {{ $t('message.marketingObjects.startMarketing') }}:
                  </h6>
                  {{ startMarketingAt | timestampToDate }}
                  <div
                    class="d-inline-flex float-right"
                    @mousedown="openEditNote">
                    <coozzy-button class="mr-3 mb-3">
                      <coozzy-edit-icon class="too-left" />
                      <span
                        class="ml-1">
                        {{ $t('message.marketingObjects.editNote') }}
                      </span>
                    </coozzy-button>
                  </div>
                </div>
                <h6 class="alert-heading font-weight-bold mb-0 mt-2">
                  {{ $t('message.marketingObjects.marketingNote') }}:
                </h6>
                <div
                  v-if="marketingNote.length < 60 ">
                  <!-- eslint-disable vue/no-v-html -->
                  <span
                    style="white-space: pre;"
                    v-html="marketingNote" />
                  <!-- eslint-enable vue/no-v-html -->
                </div>
                <div
                  v-else-if="!marketingNoteVisibility">
                  <!-- eslint-disable vue/no-v-html -->
                  <span
                    style="white-space: pre;"
                    v-html="marketingNote.substring(0, 20)" />...
                  <!-- eslint-enable vue/no-v-html -->
                  <b-link
                    @click="showMoreShowLess">
                    {{ $t('message.generic.showMore') }}
                  </b-link>
                </div>
                <div
                  v-else>
                  <b-collapse
                    id="collapse-1"
                    v-model="marketingNoteVisibility">
                    <!-- eslint-disable vue/no-v-html -->
                    <span
                      style="white-space: pre;"
                      v-html="marketingNote" />
                  <!-- eslint-enable vue/no-v-html -->
                  </b-collapse>
                  <b-link
                    @click="showMoreShowLess">
                    {{ $t('message.generic.showLess') }}
                  </b-link>
                </div>
              </template>
              <template v-else>
                <div class="row py-2">
                  <div class="col-sm-12 col-md-4">
                    <coozzy-form-input
                      v-model="startMarketing"
                      type="date"
                      :filter="'formatDate'"
                      :name="$t('message.marketingObjects.startMarketing')"
                      @blur="dateToTimestamp()" />
                  </div>
                  <div class="col">
                    <coozzy-button
                      design="primary"
                      class="float-right"
                      @click="updateMarketingInfo">
                      <coozzy-save-icon />
                      <span
                        class="ml-1">
                        {{ $t('message.generic.form.save') }}
                      </span>
                    </coozzy-button>
                  </div>
                </div>
                <div class="row py-2">
                  <div class="col-sm-12 col-md-6">
                    <coozzy-form-textarea
                      ref="description"
                      v-model="marketingNote"
                      class="marketingNote"
                      :initial="marketingNote"
                      :value-text-area="marketingNote"
                      :row="5"
                      name="marketingNote"
                      :placeholder="$t('message.marketingObjects.marketingNote')">
                      {{ $t('message.marketingObjects.marketingNote') }}
                    </coozzy-form-textarea>
                  </div>
                </div>
              </template>
            </coozzy-alert>
          </h5>
        </div>
      </div>
      <!-- Action buttons on mobile -->
      <div class="row d-md-none">
        <div class="col-12 mb-2">
          <coozzy-button
            design="prop-green"
            class="w-100"
            @click="$bvModal.show('create-contact-modal-marketing-details')">
            {{ $t('message.generic.newRequest') }}
          </coozzy-button>
        </div>
        <div class="col-12 mb-2">
          <coozzy-button
            design="primary"
            class="w-100"
            @click="editClicked"
            @click.middle="editClickedMiddle">
            {{ $t('message.generic.editAdvertActionButton') }}
          </coozzy-button>
        </div>
        <div class="col-12 mb-2">
          <coozzy-button
            design="primary"
            class="w-100"
            @click="$bvModal.show('openExposeModal')">
            {{ $t('message.advertisement.action.openExpose') }}
          </coozzy-button>
        </div>
        <div class="col-12 mb-2">
          <coozzy-button
            v-if="checkOnline || checkOnlineUntil !== null"
            id="unpublish-btn1"
            design="red"
            class="w-100"
            @click="$bvModal.show('confirmationModal')">
            {{ $t('message.marketingObjects.unPublish') }}
          </coozzy-button>
          <b-tooltip
            v-if="checkOnlineUntil !== null"
            target="unpublish-btn1"
            triggers="hover">
            {{ (new Date(parseInt(checkOnlineUntil))).getTime() > (new Date(Date.now() - 86400000)).getTime() / 1000 ? $t('message.marketingObjects.objectAutomaticallyUnpublishDate', { datetime: $options.filters.timestampToDateTime(parseInt(checkOnlineUntil)) }) : $t('message.marketingObjects.objectAutomaticallyUnpublishAmount', { amount: checkOnlineUntil }) }}
          </b-tooltip>
          <template v-else>
            <coozzy-button
              id="publish-btn1"
              :design="canBePublished ? 'red' : 'deactivated'"
              :disabled="isRented"
              class="w-100"
              @click="publishClicked">
              {{ $t('message.marketingObjects.publish') }}
            </coozzy-button>
            <b-tooltip
              v-if="!canBePublished"
              target="publish-btn1"
              triggers="hover">
              {{ $t('message.marketingObjects.impossibleToPublish') }}
            </b-tooltip>
          </template>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-3 custumeTabs">
          <b-tabs
            v-model="tabIndex">
            <b-tab
              class=""
              :title="$t('message.marketingObjects.details.tabs.interestedParties')">
              <template #title>
                <router-link
                  :to="getMarketingRoute(0)">
                  <span>{{ $t('message.marketingObjects.details.tabs.interestedParties') }}</span>
                </router-link>
              </template>
              <interested-parties-tab
                v-if="!requestSelected || elementSelected === ''"
                ref="interested-tab"
                :object="item"
                :is-rented="isRented"
                :request-selected="elementSelected"
                :filter="propsFilter"
                :filter-progress="propsFilterProgress"
                :filter-market-place-name="propsFilterMarketPlaceName"
                :link-mail="linkMail"
                :link-email="linkEmail"
                @loaded-reserved-request="onLoadedReservedRequest()"
                @loaded-request="onLoadedRequest"
                @set-request-selected="setRequestSelected" />
              <interested-party-details
                v-else
                ref="interested-detail"
                :key="requestSelected.id"
                :request="requestSelected"
                :list-request="listRequest"
                :is-tenancy-activated="isTenancyActivated"
                :object="item"
                :request-building="building"
                :is-reserved="loadedReservedRequest"
                @delete-tenancy="deleteTenancy"
                @tenancy-activated="tenancyActivated"
                @request-updated="setRequestSelected" />
            </b-tab>
            <b-tab
              :title="$t('message.marketingObjects.details.tabs.advert')"
              class="p-0">
              <template #title>
                <router-link
                  :to="getMarketingRoute(1)">
                  <span>{{ $t('message.marketingObjects.details.tabs.advert') }}</span>
                </router-link>
              </template>
              <marketing-object-advert-tab
                :object-selected="item"
                :read-only="true"
                :property="property" />
            </b-tab>
            <b-tab
              lazy
              :title="$t('message.marketingObjects.details.tabs.contacts')">
              <template #title>
                <router-link
                  :to="getMarketingRoute(2)">
                  <span>{{ $t('message.marketingObjects.details.tabs.contacts') }}</span>
                </router-link>
              </template>
              <create-contacts-tab
                :show-add-buttons="false"
                :item="item" />
            </b-tab>
            <template
              #tabs-end>
              <b-nav-item
                v-if="checkOnline || checkOnlineUntil !== null"
                class="d-none d-md-block item-button">
                <coozzy-button
                  id="unpublish-btn"
                  design="red"
                  class="nav-button-four-btn nav-button-right"
                  @click="$bvModal.show('confirmationModal')">
                  {{ $t('message.marketingObjects.unPublish') }}
                </coozzy-button>
                <b-tooltip
                  v-if="checkOnlineUntil !== null"
                  target="unpublish-btn"
                  triggers="hover">
                  {{ (new Date(parseInt(checkOnlineUntil))).getTime() > (new Date(Date.now() - 86400000)).getTime() / 1000 ? $t('message.marketingObjects.objectAutomaticallyUnpublishDate', {datetime: $options.filters.timestampToDateTime(parseInt(checkOnlineUntil)) }) : $t('message.marketingObjects.objectAutomaticallyUnpublishAmount', {amount: checkOnlineUntil }) }}
                </b-tooltip>
              </b-nav-item>
              <b-nav-item
                v-else
                class="d-none d-md-block item-button">
                <coozzy-button
                  id="publish-btn2"
                  :design="canBePublished ? 'red' : 'deactivated'"
                  :disabled="isRented"
                  class="nav-button-four-btn nav-button-right"
                  @click="publishClicked">
                  {{ $t('message.marketingObjects.publish') }}
                </coozzy-button>
                <b-tooltip
                  v-if="!canBePublished && statusTitle !== 'Online'"
                  target="publish-btn2"
                  triggers="hover">
                  {{ $t('message.marketingObjects.impossibleToPublish') }}
                </b-tooltip>
              </b-nav-item>
              <b-nav-item
                href="javascript:;"
                class="d-none d-md-block item-button">
                <coozzy-button
                  design="primary"
                  class="nav-button-four-btn nav-button-center"
                  @click.prevent="editClicked"
                  @click.middle="editClickedMiddle">
                  {{ $t('message.generic.editAdvertActionButton') }}
                </coozzy-button>
              </b-nav-item>
              <b-nav-item
                class="d-none d-md-block item-button">
                <coozzy-button
                  design="primary"
                  class="nav-button-four-btn nav-button-center2"
                  @click.prevent="$bvModal.show('openExposeModal')">
                  {{ $t('message.advertisement.action.openExpose') }}
                </coozzy-button>
              </b-nav-item>
              <b-nav-item
                class="d-none d-md-block item-button">
                <coozzy-button
                  design="prop-green"
                  class="nav-button-four-btn  nav-button-left"
                  @click.prevent="$bvModal.show('create-contact-modal-marketing-details')">
                  {{ $t('message.generic.newRequest') }}
                </coozzy-button>
              </b-nav-item>
            </template>
          </b-tabs>
        </div>
      </div>
    </template>
    <create-contact-modal
      v-if="item"
      :key="item.id"
      modal-id="create-contact-modal-marketing-details"
      :for-owner-id="parseInt(item.ownerId)"
      :show-search="true"
      :show-request-sources="true"
      :show-roles-input="false"
      :set-interested-party-role="true"
      :show-add-another="false"
      :enable-delete="false"
      @contact-created="contactCreated"
      @contact-updated="contactUpdated" />
    <b-modal
      :id="'confirmationModal'"
      ref="confirmationModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.marketingObjects.confirmationUnPublish.title')">
      <template>
        <div class="col">
          <p>{{ $t('message.marketingObjects.confirmationUnPublish.body') }}</p>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('confirmationModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="unPublishClicked">
            {{ $t('message.generic.confirm') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
    <b-modal
      :id="'openExposeModal'"
      ref="openExposeModal"
      no-close-on-backdrop
      hide-footer
      :title="$t('message.advertisement.action.openExpose')">
      <template>
        <div class="col-12">
          <p>{{ $t('message.advertisement.action.titleModal') }}</p>
        </div>
        <div class="col-12 mt-3 mb-3">
          <coozzy-form-select
            v-model="language">
            <option
              v-for="option in options"
              :key="option.key"
              :value="option.key">
              {{ option.value }}
            </option>
          </coozzy-form-select>
        </div>
        <div class="col">
          <coozzy-button
            size="small"
            class="mb-0 border"
            design="transparent"
            @click="$bvModal.hide('openExposeModal')">
            {{ $t('message.generic.cancel') }}
          </coozzy-button>
          <coozzy-button
            size="small"
            class="float-right mb-0"
            design="green"
            @click="openAdvertExpose">
            {{ $t('message.generic.confirm') }}
          </coozzy-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import ObjectApi from '@/misc/apis/ObjectApi'
import MessageApi from '@/misc/apis/MessageApi'
import ExportApi from '@/misc/apis/ExportApi'
import CoozzySpinner from '../framework/components/misc/CoozzySpinner'
import MarketingObjectAdvertTab from './MarketingObjectAdvertTab'
import InterestedPartiesTab from './InterestedPartiesTab'
import InterestedPartyDetails from './InterestedPartyDetails'
import CoozzyButton from '../framework/components/button/CoozzyButton'
import Vue from 'vue'
import { formatting } from '@/mixins/formatting'
import { obj } from '@/mixins/object'
import CoozzyAlert from '@/framework/components/alert/CoozzyAlert'
import RequestApi from '@/marketingBasic/api/RequestApi'
import CreateContactsTab from '@/building/tabs/CreateContactsTab'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle'
import CreateContactModal from '@/contact/components/CreateContactModal'
import { dateUtils } from '@/mixins/dateUtils'
import CoozzyEditIcon from '@/framework/components/icons/CoozzyEditIcon'
import CoozzyFormInput from '@/framework/components/form/input/CoozzyFormInput.vue'
import CoozzySaveIcon from '@/framework/components/icons/CoozzySaveIcon.vue'
import CoozzyFormTextarea from '@/framework/components/form/textarea/CoozzyFormTextarea.vue'
import CoozzyFormSelect from '@/framework/components/form/select/CoozzyFormSelect.vue'

export default {
  name: 'MarketingObjectDetailsView',
  components: {
    CoozzyFormSelect,
    CoozzyFormTextarea,
    CoozzySaveIcon,
    CoozzyFormInput,
    CreateContactModal,
    CoozzyPageTitle,
    CreateContactsTab,
    CoozzyAlert,
    InterestedPartiesTab,
    InterestedPartyDetails,
    CoozzyButton,
    MarketingObjectAdvertTab,
    CoozzySpinner,
    CoozzyEditIcon
  },
  mixins: [formatting, obj, dateUtils],
  props: {
    elementSelected: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    view: {
      type: [String, Number],
      default: 0
    },
    filter: {
      type: String,
      default: ''
    },
    filterProgress: {
      type: String,
      default: ''
    },
    filterMarketPlaceName: {
      type: String,
      default: ''
    },
    isTenancyActivated: {
      type: Boolean,
      default: false
    },
    linkMail: {
      type: String,
      default: ''
    },
    linkEmail: {
      type: String,
      default: ''
    }
  },
  metaInfo() {
    return {
      title: `${this.$t('message.navigation.marketing.title')} ${this.buildingName}`
    }
  },
  data() {
    return {
      marketingNote: '',
      startMarketing: '',
      startMarketingAt: '',
      tabIndex: 0,
      loading: false,
      item: null,
      futureTenancy: null,
      loadedReservedRequest: false,
      isRented: false,
      requestSelected: null,
      requestNoReject: null,
      hasPlatforms: false,
      marketingNoteVisibility: false,
      updateMarketingInfoEdit: false,
      listRequest: [],
      rejectOtherRequest: false,
      building: null,
      property: null,
      language: 'DE'
    }
  },
  computed: {
    options() {
      return [
        { key: 'EN', value: this.$t('message.language.english') },
        { key: 'DE', value: this.$t('message.language.german') },
        { key: 'IT', value: this.$t('message.language.italian') }]
    },
    getUserLang() {
      return this.$store.getters['user/getCurrentUser']['https://coozzy.ch/lang'].toUpperCase()
    },
    checkOnline() {
      if (this.item?.marketingStatus?.publications.length > 0) {
        const onlinePublications = this.item.marketingStatus.publications.filter(element => !element.unPublished || (parseInt(element.unPublished) < parseInt(element.published)))
        if (this.item.marketingStatus.publications.find(element => element.unPublishedByAccountId <= 0) && onlinePublications.length > 0) {
          return true
        }
      }
      return false
    },
    checkOnlineUntil() {
      if (this.item?.marketingStatus?.publications.length > 0) {
        const onlinePublications = this.item.marketingStatus.publications.filter(element => !element.unPublished || (parseInt(element.unPublished) < parseInt(element.published)))
        const publication = onlinePublications[onlinePublications.length - 1]
        return this.statusTitle === 'Online' && onlinePublications.length > 0 ? publication && publication.unPublished > 0 && parseInt(publication?.unPublished) * 1000 > new Date().getTime() ? publication.unPublished : publication.unPublishAtAmountOfRequests > 0 ? publication.unPublishAtAmountOfRequests : null : null
      }
      return null
    },
    statusTitle() {
      return this.calculateMarketingStatus(this.item.marketingStatus).label
    },
    statusColor() {
      return this.calculateMarketingStatus(this.item.marketingStatus).color
    },
    statusDate() {
      return this.calculateMarketingStatus(this.item.marketingStatus).date
    },
    statusFutureDate() {
      return this.calculateMarketingStatus(this.item.marketingStatus).dateFuture
    },
    canBePublished() {
      return !!(this.item && this.item.title !== '' && this.item.description !== '' && this.item.category !== 'UNDEFINED_CATEGORY' &&
        this.item.pricing.currency !== '' && (this.item.pricing.coldRent !== '' || this.item.pricing.unit !== 'ON_REQUEST') && this.hasPlatforms && this.statusTitle !== 'Online')
    },
    buildingName() {
      if (this.building) {
        return this.building.name
      } else {
        return '-'
      }
    },
    propsFilter() {
      return this.filter
    },
    propsFilterProgress() {
      return this.filterProgress
    },
    propsFilterMarketPlaceName() {
      return this.filterMarketPlaceName
    }
  },
  watch: {
    tabIndex: function (newVal) {
      this.requestSelected = null

      if (!this.$route.query || parseInt(this.$route.query.view || 0) !== newVal) {
        this.$router.replace({
          name: 'MarketingObjectDetailsView',
          params: { id: this.id },
          query: {
            view: newVal,
            elementSelected: newVal === 0 ? this.elementSelected : ''
          }
        })
      }
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.language = this.getUserLang || 'DE'
    })
    this.openSelectedTabs()
    this.loadObject()
  },
  methods: {
    updateMarketingInfo() {
      ObjectApi.updateMarketingInfo(this.item.id, this.marketingNote, this.startMarketingAt)
        .then(() => {
          this.updateMarketingInfoEdit = false
          Vue.toasted.show(this.$t('message.successMessages.marketingNoteAdded'), { type: 'success' })
        })
        .catch(e => {
          console.log(e)
        })
    },
    dateToTimestamp() {
      this.startMarketingAt = this.toTimestamp(this.startMarketing)
    },
    toTimestamp(strDate) {
      return Date.parse(strDate) / 1000
    },
    loadObject() {
      ObjectApi.getObject(this.id)
        .then(response => {
          this.item = response.object
          this.startMarketingAt = this.item.marketingStatus.startMarketingAt
          this.startMarketing = this.$options.filters.timestampToDateInput(this.startMarketingAt)
          this.marketingNote = this.item.marketingStatus.marketingNote
          this.loadBuilding(this.item.buildingId)
          this.loadProperty(this.item.buildingId)
          ExportApi.getExportPlatforms(this.item.id)
            .then(response => {
              this.hasPlatforms = response.platforms.length > 0
            })
            .catch(e => {
              console.log(e)
              Vue.toasted.show(this.$t('message.loadingErrors.exportPlatforms'), { type: 'error' })
            })
          this.loadCurrentStatus()
          this.loadRequests()
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
        })
    },
    loadBuilding(buildingId) {
      ObjectApi.getBuilding(buildingId)
        .then(response => {
          this.building = response.building
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
        })
    },
    loadProperty(buildingId) {
      ObjectApi.getPropertiesByBuildingIds([buildingId])
        .then(response => {
          this.property = response.properties[0]
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.loadingErrors.object'), { type: 'error' })
        })
    },
    loadCurrentStatus() {
      if (this.item.marketingStatus && this.item.marketingStatus.clearFromMarketingAt > 0) {
        this.$nextTick(function () {
          this.isRented = true
        })
      }
    },
    deleteTenancy() {
      this.loadRequests()
    },
    openSelectedTabs() {
      this.tabIndex = parseInt(this.view)
    },
    editClicked() {
      this.$router.push({
        name: 'MarketingEditAdvertView',
        params: { id: this.id }
      })
    },
    editClickedMiddle() {
      const routeData = this.$router.resolve({
        name: 'MarketingEditAdvertView',
        params: { id: this.id }
      })
      window.open(routeData.href, '_blank')
    },
    async openAdvertExpose() {
      this.loading = true
      this.$bvModal.hide('openExposeModal')
      const blob = new Blob([await ObjectApi.getMarketingExpose(this.item.id, this.language)], { type: 'application/pdf' })
      const blobURL = URL.createObjectURL(blob)
      window.open(blobURL)
      this.loading = false
    },
    unPublishClicked() {
      this.loading = true
      ObjectApi.unPublishObject([this.id])
        .then(() => {
          this.loadObject()
          this.loadRequests()
          this.loading = false
          this.$bvModal.hide('requestDeactivateAdvertModal')
          this.$bvModal.hide('confirmationModal')
          Vue.toasted.show(this.$t('message.successMessages.unPublishObject'), { type: 'success' })
        })
        .catch(e => {
          console.log(e)
          this.loading = false
          Vue.toasted.show(this.$t('message.savingErrors.unPublishObject'), { type: 'error' })
        })
    },
    publishClicked() {
      if (this.canBePublished) {
        this.loading = true
        ObjectApi.publishObject([this.id])
          .then(() => {
            this.loadObject()
            this.loadRequests()
            Vue.toasted.show(this.$t('message.successMessages.publishObject'), { type: 'success' })
          })
          .catch(e => {
            console.log(e)
            Vue.toasted.show(this.$t('message.savingErrors.publishObject'), { type: 'error' })
          })
          .finally(() => {
            this.loading = false
          })
      }
    },
    contactCreated(data) {
      this.createNewRequest(data.contact, data.requestData.source, data.requestData.marketplaceName)
    },
    contactUpdated(data) {
      this.createNewRequest(data.contact, data.requestData.source, data.requestData.marketplaceName)
    },
    createNewRequest(contact, source, marketplaceName) {
      // Check if we already got a request on this object with this contact
      for (const request of this.listRequest) {
        if (request.requesters.map(req => req.contactId).includes(contact.id)) {
          Vue.toasted.show(this.$t('message.savingErrors.contactAlreadyApplied'), { type: 'error' })
          this.setRequestSelected(request)
          return
        }
      }

      const request = {
        ownerId: this.item.ownerId,
        source: source,
        marketplaceName: marketplaceName,
        objectId: this.item.id,
        requesters: [{
          accountId: contact.accountId,
          userId: contact.userReference,
          contactId: contact.id
        }],
        checklist: null,
        createdByReceiver: false,
        favorite: false,
        initialMessage: ''
      }
      RequestApi.newRequest(request)
        .then(response => {
          Vue.toasted.show(this.$t('message.successMessages.requestAdded'), { type: 'success' })
          this.listRequest.push(response.request)
          if (this.linkEmail !== '') {
            this.loading = true
            const toLink = response.request.requesters[0].contactId
            MessageApi.setReferenceIds([this.linkMail], [toLink, response.request.id])
              .then(() => {
                this.loading = false
                Vue.toasted.show(this.$t('message.successMessages.contactLinked'), { type: 'success' })
                this.setRequestSelected(response.request)
              })
              .catch(e => {
                console.log(e)
                Vue.toasted.show(this.$t('message.savingErrors.reference'), { type: 'error' })
              })
          } else {
            this.setRequestSelected(response.request)
          }
        })
        .catch(e => {
          console.log(e)
          Vue.toasted.show(this.$t('message.savingErrors.request'), { type: 'error' })
        })
    },
    onLoadedReservedRequest() {
      this.loadedReservedRequest = true
    },
    setRequestSelected(request) {
      this.requestSelected = request
      this.loading = true
      this.loading = false
      if (!this.$route.query || parseInt(this.$route.query.view || 0) !== 0 || this.$route.query.elementSelected !== request.id) {
        this.$router.push({
          name: 'MarketingObjectDetailsView',
          params: { id: this.id },
          query: {
            view: 0,
            elementSelected: this.requestSelected ? this.requestSelected.id : ''
          }
        })
        this.$nextTick(function () {
          this.tabIndex = 0
        })
      }
    },
    // backAction() {
    //   if (!this.$route.query || (parseInt(this.$route.query.view || 0) === 0 && this.$route.query.elementSelected !== '')) {
    //     this.requestSelected = null
    //     this.$router.replace({
    //       name: 'MarketingObjectDetailsView',
    //       params: { id: this.id },
    //       query: {
    //         view: this.view,
    //         elementSelected: ''
    //       }
    //     })
    //   }
    // },
    getMarketingRoute(view) {
      return { name: 'MarketingObjectDetailsView', params: { id: this.id }, query: { view: view } }
    },
    showMoreShowLess() {
      this.marketingNoteVisibility = !this.marketingNoteVisibility
    },
    tenancyActivated(requestId) {
      this.requestSelected = null
      this.requestNoReject = requestId
      this.loadObject()
      this.loadRequests()
    },
    checkReject() {
      this.$bvModal.hide('requestDeactivateAdvertModal')
      if (this.isTenancyActivated === 'true') {
        this.$refs['interested-detail'].rejectOtherRequest()
      } else {
        this.$refs['interested-tab'].rejectOtherRequest(this.requestNoReject)
      }
    },
    loadRequests() {
      this.loading = true
      RequestApi.listReceivedByObjectIds([this.item.id], null, [], [])
        .then(response => {
          this.listRequest = response.requests
          for (const request of response.requests) {
            if (request.tenancyId) {
              ObjectApi.getTenancyById(request.tenancyId)
                .then(response => {
                  this.futureTenancy = response.tenancy
                })
            }
          }
          this.loading = false
        })
        .catch(e => {
          console.log(e)
          this.loading = false
          Vue.toasted.show(this.$t('message.loadingErrors.request'), { type: 'error' })
        })
    },
    onLoadedRequest(requestList) {
      if (requestList.length > 1 && this.rejectOtherRequest) {
        this.$refs['interested-tab'].rejectOtherRequest(this.requestNoReject)
        this.loadObject()
        this.rejectOtherRequest = false
      }
    },
    openEditNote() {
      this.updateMarketingInfoEdit = !this.updateMarketingInfoEdit
    }
  }
}
</script>

<style lang="scss" scoped>

.item {
  cursor: pointer;
}

:deep(.details-row-bg-1) {
  background-color: $color-table-row-2;
}

:deep(.details-row-bg-2) {
  background-color: $color-table-row-1;
}

:deep(label) {
  width: 100%;
}
:deep(.fa-save) {
  color: #fff !important;
}
.nav-button {

  button:not(.sort):not(.page-link) {
    display: none;
  }

  .multiselect, .custom-checkbox {
    pointer-events: none;

    .multiselect__select, .multiselect__placeholder {
      display: none;
    }
  }

  .basic {
    input {
      opacity: 1;
      border: thin solid #ced4da;
      -webkit-box-shadow: inherit;
      box-shadow: inherit;
      margin-bottom: 0;
      pointer-events: initial;
      padding-left: 0.2rem;
      background: none;
    }
  }

  .multi-select-value {
    line-height: 1.5;
  }
}
:deep(textarea.form-control) {
    height: 100px !important;
}
:deep(.tabs .nav-tabs .nav-link a.router-link-active), :deep(.tabs .nav-tabs .nav-link a) {
    color: inherit !important;
    text-decoration: none !important;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item[role=presentation]) {
    margin-right: 0.2rem;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item > a.nav-link) {
    margin-right: 0;
    padding: 0;
    box-shadow: none;
    border: 0;
    z-index: -1;
    height: 26.5px;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item[role=presentation] .nav-link.active > a) {
    font-weight: bold;
    background-color: #fff;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item[role=presentation] .nav-link:hover > a) {
    font-weight: bold;
    cursor: pointer;
  }
  :deep(.custumeTabs .tabs .nav-tabs .nav-item[role=presentation] .nav-link > a) {
    color: rgb(0, 68, 95) !important;
    border: 1px solid;
    border-color: #c8ced3 !important;
    border-radius: 0 !important;
    border-bottom: 0 !important;
    margin-right: 0;
    padding: 0.55rem 1.5rem;
    font-weight: 300;
    -webkit-box-shadow: inset 0 16px 20px -15px rgb(238 238 238), 3px 0 2px -2px #ccc;
    box-shadow: inset 0 16px 20px -15px rgb(238 238 238), 3px 0 2px -2px #ccc;
    cursor: default;
    height: 36.25px;
  }
  :deep(.card.shadow) {
    box-shadow: none !important;
  }
  .item-button button.small {
    padding: 0.2rem 0.5rem;
  }
  .tab-content>.active {
    background: #fff;
    position: relative;
  }
  .tab-content .tab-pane {
    padding: 1.25rem;
  }
</style>
